import { Box, Grid, Stack, Typography } from "@mui/material";
import moment from "moment/moment";
import React from "react";

const SingleFlighDetails = ({ segment, segments, index }) => {
  const {
    operatingcareer,
    // operatingCarrierName,
    operatingflight,
    bookingcode,
    // operatingCarrierName1,
    marketingcareerName,
    marketingcareer,
    departure,
    departureLocation,
    departureTime,
    departureAirport,
    flightduration,
    arrivalLocation,
    arrival,
    arrivalTime,
    arrivalAirport,
  } = segment;


  const transiteTimeDuration = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));
    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;
    const transit = `${Math.abs(hours)}h:${Math.abs(minutes)}min`;
    return transit;
  };



  return (
    <Box
      sx={{
        mt: "20PX",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },

        // border: "1px solid red",
      }}
    >
      <Grid
        container
        // sx={{ padding: "15px" }}
      >
        <Grid item lg={3}>
          <Box>
            <img
              style={{ width: "50px", height: "50px" }}
              src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${
                marketingcareer || segment?.marketingCareer
              }.png`}
              alt=""
            />
            <Typography
              variant="body2"
              sx={{ color: "var(--secondary-color)", fontWeight: 500 }}
            >
              {marketingcareerName}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#444542", fontWeight: 500 }}
            >
              {operatingcareer} {operatingflight}
            </Typography>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Operated by: {operatingcareer}
            </Typography>

            <Typography variant="body2" sx={{ color: "#444542" }}>
              Economy: {bookingcode}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3}>
          <Box>
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Depart
            </Typography>
            <Typography variant="title-lg">{departure}</Typography>
            <Typography
              variant="body2"
              sx={{ color: "var(--secondary-color)", fontWeight: 500 }}
            >
              <span style={{ color: "#444542", fontSize: "13px" }}>
                {departureLocation}
              </span>{" "}
              | {moment(departureTime).format("hh:mm a")}
              {/* moment(date).format("DD MMM YY hh:mm a) */}
            </Typography>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Terminal: {departureAirport}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {moment(departureTime).format("dddd, Do MMMM YYYY")}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="body2" sx={{ color: "#ACA6A6" }}>
              {flightduration}
            </Typography>
            <svg
                              width="122"
                              height="12"
                              viewBox="0 0 689 56"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                                stroke="#ED1A5C"
                                stroke-width="9"
                              />
                              <path
                                d="M689 46.6569L541.002 0V46.6569H689Z"
                                fill="#ED1A5C"
                              />
                            </svg>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Non Stop
            </Typography>
          </Stack>
        </Grid>

        <Grid item lg={3}>
          <Box>
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Arival
            </Typography>
            <Typography variant="title-lg">{arrival}</Typography>
            <Typography
              variant="body2"
              sx={{ color: "var(--secondary-color)", fontWeight: 500 }}
            >
              <span style={{ color: "#444542", fontSize: "13px" }}>
                {arrivalLocation}
              </span>{" "}
              | {moment(arrivalTime).format("h:mm a")}
            </Typography>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Terminal: {arrivalAirport}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {moment(arrivalTime).format("dddd, Do MMMM YYYY")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography
        sx={{
          display: `${index === segments.length - 1 ? "none" : "block"}`,
          borderRadius: "4px",
          background: "#DEE8FF",
          width: "85%",
          color: "var(--primary-color)",
          padding: "10px",
          fontSize: "10px",
          fontWeight: 500,
          margin: "0 auto",
          textAlign: "center",
          mt: "15px",
        }}
      >
        Change planes at {arrivalAirport} | {arrivalLocation} | Connecting Time:{" "}
        {transiteTimeDuration(
          segments[index + 1]?.departureTime,
          segments[index]?.arrivalTime
        )}
      </Typography>
    </Box>
  );
};

export default SingleFlighDetails;
