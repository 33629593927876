import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  IconButton,
  Tooltip,
  Pagination,
  Stack,
  Modal,
  CircularProgress,
} from "@mui/material";
import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import { Button } from "@mui/material";
import EventNoteIcon from "@mui/icons-material/EventNote";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import secureLocalStorage from "react-secure-storage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { format } from "date-fns";
import commaNumber from "comma-number";
import Swal from "sweetalert2";
import { Link, useNavigate } from "react-router-dom";
import PhoneIcon from "@mui/icons-material/Phone";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const PendingAgent = () => {
  const user = secureLocalStorage.getItem("admin-info");
  const userTrack = user?.user?.role === "staff" ? user?.user?.email : "Admin";

  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [bookingData, setBookingData] = useState([]);
  const [mainAgentData, setMainAgentData] = useState([]);
  const [isLoading, setIsloading] = useState(false);

  // todo: pagination handle
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);

  const handlePageChange = (event, value) => {
    setPage(value);
    // setMainAgentData(bookingData.slice((value - 1) * size, value * size));
  };

  //  todo: load agent data
  useEffect(() => {
    const url = `https://farhantt.api.flyfarint.com/v.1.0.0/Admin/Agent/allAgent.php?status=pending&pages=${page}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setPageCount(data?.number_of_page);
        setBookingData(data?.data);
        setMainAgentData(data?.data);
        setIsloading(true);
      });
  }, [page]);

  // todo:handle all action active, deactivate and reject
  const handleAction = (agentId, action) => {
    const url = `https://farhantt.api.flyfarint.com/v.1.0.0/Admin/Agent/${action}.php?agentId=${agentId}&actionBy=${userTrack}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success" || data.action === "success") {
          Swal.fire({
            icon: "success",
            title: "success",
            text: `${data.message}`,
            confirmButtonText: "ok",
          }).then(function () {
            navigate(0);
          });
        }
      });
    handleClose(false);
  };

  //  todo: handle search function
  const handelSearchItems = async (e) => {
    let searchInput = e.target.value;
    await fetch(
      `https://farhantt.api.flyfarint.com/v.1.0.0/Admin/Agent/allAgent.php?search=${searchInput}`
    )
      .then((res) => res.json())
      .then((data) => {
        if (searchInput !== "") {
          const filterData = data.filter((item) => {
            return Object.values(item)
              .join("")
              .toLowerCase()
              .includes(searchInput.toLowerCase());
          });
          setMainAgentData(filterData);
        } else if (searchInput === "") {
          setMainAgentData(bookingData);
        }
      });
  };

  return (
    <Box>
      <Box className="searchList1" my={2}>
        <input type="text" placeholder="search" onChange={handelSearchItems} />
      </Box>
      <Box className="balance-transaction agentModal" marginTop={"20px"}>
        {isLoading === true ? (
          <>
            <table>
              <thead>
                <tr>
                  <th> Agent Id </th>
                  <th>Status</th>
                  <th>Agent Name</th>
                  <th>Company Name</th>
                  <th>Address</th>
                  <th>Email</th>
                  <th>Password</th>
                  <th>Phone Number</th>
                  <th>Balance</th>
                  <th>Operation</th>
                  <th>Call</th>
                </tr>
              </thead>

              <tbody>
                {mainAgentData?.map((data) => (
                  <tr>
                    <td data-column="Agent Id">{data?.agentId}</td>
                    <td data-column="Status">{data?.status}</td>
                    <td data-column="Agent Name">{data?.name}</td>
                    <td data-column="Company Name">
                      <Tooltip
                        title={data?.company}
                        style={{ width: "50px" }}
                      >
                        <Typography>
                          {data?.company?.slice(0, 10)}
                          ...
                        </Typography>
                      </Tooltip>
                    </td>
                    <td data-column="Address">
                      <Tooltip
                        title={data?.companyadd}
                        style={{ width: "50px"}}
                      >
                        <Typography>
                          {data?.companyadd?.slice(0, 10)}
                          ...
                        </Typography>
                      </Tooltip>
                    </td>

                    <td data-column="Email">{data?.email}</td>
                    <td data-column="Password">{data?.password}</td>
                    <td data-column="Phone Number">{data?.phone}</td>

                    <td data-column="Balance">{data?.lastBalance} BDT</td>

                    <td data-column="Operation">
                      {data?.status === "active" ? (
                        <button
                          style={{
                            backgroundColor: "#E1241A",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "3px",
                            width: "73px",
                            height: "30px",
                            cursor: "pointer",
                            fontSize: "12px",
                          }}
                          onClick={() =>
                            handleAction(data?.agentId, "deactive")
                          }
                        >
                          Deactivate
                        </button>
                      ) : data?.status === "rejected" ? (
                        <button
                          disabled
                          style={{
                            backgroundColor: "var(--gray)",
                            color: "#FFFFFF",
                            border: "none",
                            borderRadius: "3px",
                            width: "73px",
                            height: "30px",
                            fontSize: "12px",
                          }}
                        >
                          Reject
                        </button>
                      ) : (
                        <Box>
                          <IconButton
                            sx={{ color: "#0E8749" }}
                            onClick={() =>
                              handleAction(data?.agentId, "approved")
                            }
                          >
                            <CheckCircleIcon />
                          </IconButton>
                          <IconButton
                            sx={{ color: "var(--remove-color)" }}
                            onClick={() =>
                              handleAction(data?.agentId, "rejected")
                            }
                          >
                            <CancelIcon />
                          </IconButton>
                        </Box>
                      )}
                    </td>
                    <td data-column="Call" style={{ display: "flex" }}>
                      <a
                        href={`tel:+${data?.phone}`}
                        style={{ marginRight: "5px" }}
                      >
                        <PhoneIcon
                          style={{ color: "#003566", fontSize: "18px" }}
                        />
                      </a>
                      <a
                        href={`https://wa.me/+${data?.phone}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <WhatsAppIcon
                          style={{ color: "green", fontSize: "18px" }}
                        />
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "38vh",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        <Grid container>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Box
              sx={{
                width: "100%",
                my: 3,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                borderTop: "1px solid var(--primary-color)",
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  my: 3,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",

                  marginTop: "8px",
                }}
              >
                <Typography style={{ fontSize: "15px", color: "#222222" }}>
                  Showing Results {page} - {pageCount}
                </Typography>
                <ThemeProvider theme={theme}>
                  <Stack spacing={2}>
                    <Pagination
                      size="small"
                      count={pageCount}
                      onChange={handlePageChange}
                      color="primary"
                    />
                  </Stack>
                </ThemeProvider>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default PendingAgent;
