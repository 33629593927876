import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
} from "@mui/material";

import { Container } from "@mui/system";
import React, { useState, useEffect } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import AccCreated from "../../images/undraw/undraw_happy_announcement_re_tsm0.svg";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import Invalid from "../../images/undraw/undraw_warning_re_eoyh.svg";
import Footer from "../Footer/Footer";
import axios from "axios";
import "./SingUp.css";
import Header from "../Header/Header";

const SignUp = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [agentId, setAgentId] = useState([]);

  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const [companyname, setCompanyName] = useState("");
  const [companyaddress, setCompanyAddress] = useState("");
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [state, setState] = useState({
    password: "",
    cPassword: "",
  });
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [checked, setChecked] = useState(false);
  const handleChange = (e) => {
    const { id, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const registerinput = JSON.stringify({
    fname: fname,
    lname: lname,
    contactpersonphonenumber: contactNumber,
    contactpersonemail: email,
    password: state.password,
    companyname: companyname,
    companyaddress: companyaddress,
  });

  const [siteConfig, setSiteConfig] = useState({});

  const handleSubmit = async (e) => {
    const url =
      "https://farhantt.api.flyfarint.com/v.1.0.0/Auth/registration.php";
    console.log(url, registerinput);

    e.preventDefault();
    //  console.log(JSON.stringify(registerinput));
    setIsLoading(true);
    fetch(url, {
      method: "POST",
      body: registerinput,
    })
      .then((res) => res.json())
      .then((registeredData) => {
        //console.log(registeredData);
        if (registeredData.status === "success") {
          setIsLoading(false);
          Swal.fire({
            title: "Account Created Successfully!",
            html: `Thank You <strong>${registerinput.fname} ${registerinput.lname}.</strong> Your Agent Account: <strong>${registerinput.companyname}</strong> is Successfully Created.Your Account will be Active within 24 Hours.If you do not receive any email, please contact us at <strong>support@rtctourtravel.com</strong> or <strong>01755-572099, 09606912912.</strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate("/");
          });
        } else {
          setIsLoading(false);
          fetch(
            "https://farhantt.api.flyfarint.com/v.1.0.0/Auth/RegistrationFailed.php",
            {
              method: "POST",
              body: JSON.stringify(registerinput),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              setIsLoading(false);
              Swal.fire({
                title: registeredData.message,
                html: "<strong>Travel Agency Registration Failed, Please contact us at support@rtctourtravel.com or 01755-572099, 09606912912.</strong>",
                confirmButtonText: "Please Try Again!",
                confirmButtonColor: "var(--primary-color)",
              }).then(function () {
                navigate("/");
              });
            });
        }
      })
      .catch((err) => {
        fetch(
          "https://farhantt.api.flyfarint.com/v.1.0.0/Auth/RegistrationFailed.php",
          {
            method: "POST",
            body: JSON.stringify(registerinput),
          }
        )
          .then((res) => res.json())
          .then((data) => {
            setIsLoading(false);
            Swal.fire({
              imageUrl: ServerDown,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Server Down",
              html: "<strong>Please contact us at fly far international or 01755-572099, 09606912912.</strong>",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Please Try Again!",
            }).then(function () {
              navigate("/");
            });
          });
      });
    e.target.reset();
  };

  return (
    <Box>
      <Container>
        <Header />
        <Box
          sx={{
            width: "100%",
            height: "fit-content",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            mb: "10vh",
          }}>
          <Box className="register-Box">
            <Box
              my={2}
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "20px",
                  fontWeight: "500",
                  textTransform: "uppercase",
                }}>
                Sign Up
              </Typography>
            </Box>
            <form onSubmit={handleSubmit} autocomplete="off">
              <Box>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}>
                    <Box className="signUp">
                      <input
                        required
                        type="text"
                        id="fname"
                        maxLength={20}
                        placeholder="First Name"
                        name="fname"
                        value={fname}
                        autocomplete="off"
                      
                        
                        style={{
                          borderRadius: "3px",
                          background: "var(--input-bgcolor)",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{ paddingTop: "15px !important" }}>
                    <Box className="signUp">
                      <input
                        required
                        type="text"
                        id="lname"
                        name="lname"
                        maxLength={20}
                        placeholder="Last Name"
                        autocomplete="off"
                        style={{
                          borderRadius: "3px",
                          background: "var(--input-bgcolor)",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}>
                    <Box className="signUp">
                      <input
                        required
                        type="text"
                        id="companyname"
                        placeholder="Company Name"
                        name="companyname"
                        autocomplete="off"
                        style={{
                          borderRadius: "3px",
                          background: "var(--input-bgcolor)",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}>
                    <Box className="signUp">
                      <input
                        required
                        id="companyaddress"
                        type="text"
                        placeholder="Company Address"
                        name="companyaddress"
                        autoComplete="off"
                        style={{
                          borderRadius: "3px",
                          background: "var(--input-bgcolor)",
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field"
                    sx={{ paddingTop: "15px !important" }}>
                    <Box className="signUp">
                      <input
                        required
                        type="email"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        placeholder="Email Address"
                        style={{
                          borderRadius: "3px",
                          background: "var(--input-bgcolor)",
                        }}
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    sx={{
                      paddingTop: "15px !important",
                    }}>
                    <Box
                      style={{
                        background: "var(--input-bgcolor)",
                        height: "38px",
                      }}>
                      <PhoneInput
                        required
                        country={"bd"}
                        placeholder="Contact Number"
                        id="contactNumber"
                        name="contactpersonphonenumber"
                      />
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field password-00"
                    sx={{ paddingTop: "15px !important" }}>
                    <Box
                      position={"relative"}
                      display="flex"
                      alignItems="center"
                      className="signUp">
                      <input
                        required
                        name="password"
                        label="Password"
                        placeholder="Password "
                        autocomplete="current-password"
                        id="password"
                        style={{
                          borderRadius: "3px",
                          background: "var(--input-bgcolor)",
                        }}
                      />
                      <Box position={"absolute"} mt="7px" right="0px">
                        <Visibility className="fonticon09" />
                      </Box>
                    </Box>
                    <Box
                      pl={1}
                      color="red"
                      style={{ fontSize: "14px" }}
                      margin="10px 0px"
                      sx={{ color: "var(--primary-color)" }}>
                      {state.password.length < 8
                        ? "*Password at least 8 characters"
                        : null}
                    </Box>
                  </Grid>

                  <Grid
                    item
                    xs={12}
                    sm={6}
                    md={6}
                    className="input-field password-00"
                    sx={{ paddingTop: "15px !important" }}>
                    <Box
                      position={"relative"}
                      display="flex"
                      alignItems="center"
                      className="signUp">
                      <input
                        required
                        name="confirmPassword"
                        
                        placeholder="Confirm Password "
                        id="cPassword"
                     
                        style={{
                          borderRadius: "3px",
                          background: "var(--input-bgcolor)",
                        }}
                      />
             
                    </Box>
                    <Box color="red">
                    sdf
                    </Box>
                  </Grid>

                  <br />
                </Grid>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                  margin="15px 5px"
                  fontSize={"10px"}>
                  <FormGroup>
                    <FormControlLabel
                      sx={{ color: "var(--primary-color)" }}
                      type="checkbox"
                      control={
                        <Checkbox
                          sx={{ color: "var(--primary-color)" }}
                          checked={checked}
                          onClick={() => setChecked((prev) => !prev)}
                        />
                      }
                      label="Agree Terms and Conditions"
                    />
                  </FormGroup>
                  <Link
                    style={{ color: "var(--primary-color)", fontSize: "15px" }}
                    href="/termsandcondition"
                    target="_blank"
                    variant="caption text">
                    Terms & Condition
                  </Link>
                </Box>
                {checked && state.password === state.cPassword ? (
                  <Box className="register-btnn">
                    <Button type="submit" disabled={!isLoading ? false : true}>
                      {!isLoading ? (
                        "Register"
                      ) : (
                        <CircularProgress
                          style={{ height: "10px", width: "10px" }}
                        />
                      )}
                    </Button>
                  </Box>
                ) : (
                  <Box className="register-btnn">
                    <Button type="submit" disabled>
                      {!isLoading ? (
                        "Register"
                      ) : (
                        <CircularProgress
                          style={{ height: "10px", width: "10px" }}
                        />
                      )}
                    </Button>
                  </Box>
                )}
              </Box>
            </form>

            <Box className="register-footer" margin="auto" mt={2}>
              <h5>
                Already have an account?
                <span
                  style={{ color: "var(--white)", paddingLeft: "5px" }}
                  onClick={() => {
                    navigate("/signin");
                  }}>
                  Login Now
                </span>
              </h5>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SignUp;
