import { Box, Container, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { json, Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import commaNumber from "comma-number";
const isMobile = window.innerWidth <= 768;

const AllHotelBooking = () => {
  const userInfo = secureLocalStorage.getItem("user-info");
  const agentId = userInfo?.agent_id || userInfo?.staff_id;
  const [bookingData, setBookingData] = useState([]);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const url = `https://api.rtc.flyfarint.com/v1.0/b2c/hotel/getBooking.php?all&userId=${agentId}`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setBookingData(data.reverse());
      });
  }, []);

  const styles = {
    root: {
      height: "calc(100vh - 200px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    dataGrid: {
      flexGrow: 1,
    },
    "@media (max-width: 600px)": {
      root: {
        height: "calc(100vh - 100px)",
        width: "50%",
      },
    },
    "@media (min-width: 600px) and (max-width: 900px)": {
      root: {
        height: "calc(100vh - 150px)",
      },
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      root: {
        height: "calc(100vh - 175px)",
      },
    },
    "@media (min-width: 1200px) and (max-width: 1536px)": {
      root: {
        height: "calc(100vh - 190px)",
      },
    },
  };

  const columns = [
    {
      field: "bookingId",
      headerName: "Reference",
      width: 150,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex" }} >
            <Button
              sx={{
                cursor: "pointer",
                backgroundColor: "var(--primary-color)",
                padding: "5px 10px",
                borderRadius: "3px",
                fontSize:"12px",
                color: "white",
                ":hover":{
                  backgroundColor: "var(--primary-color)",
                }
              }}
              disabled={params?.row?.status?.toLowerCase() === 'cancelled' ? true : false}
              onClick={() => {
                navigate(`/dashboard/hotelqueuedetails`, {
                  state: {
                    bookingId: params.row.bookingId,
                    pnr: params.row.pnr,
                    bookingData: params.row,
                  },
                });
              }}
            >

              {" "}
              {params.row.bookingId}

            </Button>
          </Box>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 120,
    },
    {
      field: "hotelName",
      headerName: "Hotel Name",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      width: 180,
    },
    {
      field: "pax",
      headerName: "PAX",
      width: 80,
    },

    {
      field: "checkIn",
      headerName: "Check-In Date",
      width: 150,
    },
    {
      field: "checkout",
      headerName: "Check-Out Date",
      width: 150,
    },
    {
      field: "rooms",
      headerName: "Rooms",
      width: 110,
    },
    {
      field: "refundable",
      headerName: "Refundable",
      width: 150,
    },
    {
      field: "adultCount",
      headerName: "Adult Count",
      width: 150,
    },
    {
      field: "childCount",
      headerName: "Child Count",
      width: 130,
    },
    {
      field: "netCost",
      headerName: "Net Cost",
      type: "number",
      width: 120,
      valueGetter: (params) => `${commaNumber(params.row.netCost)} BDT `,
    },
  ];

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "var(--white)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            p: "20px",
            m: "0 auto",
            width: "90%",
            mt: "-10vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "8px",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "18px !important",
                color: "var(--black)",
                fontFamily: "poppins",
              }}
            >
              All Hotel Booking
            </Typography>
          </Box>

          <Box
            style={styles.root}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize:"10px"
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid var(--input-bgcolor)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color)",
                borderBottom: "none",
                color: "var(--white)",
                fontSize: "10px",
                textTransform: "uppercase",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "var(--body-color)",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
              },
              "& .MuiDataGrid-toolbarContainer": {
                marginLeft: "auto",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "var(--primary-color)",
                background: "var(--secondary-color)",
                fontSize: "10px",
                mb: 2,
              },
              ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
                color: "var(--white)",
              },
              ".MuiTablePagination-selectLabel": {
                color: "var(--white)",
              },
              ".MuiSelect-select": {
                color: "var(--white)",
              },
              ".css-levciy-MuiTablePagination-displayedRows": {
                color: "var(--white)",
              },
              ".MuiDataGrid-cellContent": {
                color: "var(--text-color)",
              },
            }}
          >
            {isMobile ? (
              <>
                <Grid sx={{ mt: "20px" }} container gap={2}>
                  {bookingData?.map((sales) => (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={sales?.id}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "flex",
                          md: "flex",
                        },
                        transition: "all .5s ease-in-out",
                        background: "var(--gray)",
                        borderRadius: "5px",
                        width: "100%",
                        borderRadius: "10px",
                        px: "20px",
                        py: "25px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            Reference
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Button
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#5D6D7E",
                                  padding: "5px 10px",
                                  borderRadius: "3px",
                                  color: "white",
                                }}
                                disabled={
                                  sales?.status?.toLowerCase() === "cancelled"
                                    ? true
                                    : false
                                }
                                onClick={() => {
                                  navigate(`/hotelqueuedteails`, {
                                    state: {
                                      bookingId: sales?.bookingId,
                                      pnr: sales?.pnr,
                                      bookingData: sales,
                                    },
                                  });
                                }}
                              >
                                <Typography sx={{ fontSize: "13px" }}>
                                  {" "}
                                  {sales?.bookingId}
                                </Typography>
                              </Button>
                            </Box>
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "30px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Status
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.status}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Hotel Name
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.hotelName}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Pax
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.pax}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Check-In-Date
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.checkIn}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Check-Out-Date
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.checkout}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Rooms
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.rooms}
                          </Typography>
                        </Box>

                        <Grid
                          item
                          direction={{ xs: "row", sm: "row", md: "row" }}
                          spacing={1}
                          alignItems="flex-start"
                          sx={{
                            width: "100%",
                            height: "100%",
                            // padding: {
                            //   md: "8px",
                            //   xs: "0px",
                            // },
                            mt: "30px",
                            pb: "14px",
                            borderTop: "1px solid var(--primary-color)",
                            // borderTop: {
                            //   xs: "none",
                            //   sm: "1px solid var(--primary-color)",
                            // },
                            pl: { xs: 2, sm: 2 },
                          }}
                        ></Grid>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Refundable
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales?.refundable}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Adult Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales?.adultCount}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Child Count
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales?.childCount}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Net Cost
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {commaNumber(sales?.netCost)} BDT
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <DataGrid
                  rows={bookingData}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AllHotelBooking;
