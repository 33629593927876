/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid, Tab, Tabs, Container } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import triangle from "../../../../images/Icon/triangle.png";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--primary-color)",
    maxWidth: 300,
    padding: "10px",
  },
}));

const Transit = ({ flightData }) => {
  return (
    <Box textAlign={"center"} padding="0px 7px">
      <Typography>
        <HtmlTooltip
          title={
            <React.Fragment>
              <Box display="flex">
                <Box borderRight="2px solid var(--secondary-color)" px={1}>
                  <Typography
                    sx={{
                      color: "var(--white)",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    Transit: 1H 30Min <br />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "var(--secondary-color)",
                      }}
                    >
                      DEL
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--white)",
                      fontSize: "10px",
                    }}
                  >
                    Dellhi, IND <br />
                    BG 678
                    {" & "}
                    1h 35min
                    <br />
                    Sun, 3rd june 2022
                  </Typography>
                </Box>

                <Box px={1}>
                  <Typography
                    sx={{
                      color: "var(--white)",
                      fontSize: "10px",
                      textAlign: "center",
                    }}
                  >
                    Transit:5H 30Min
                    <br />
                    <span
                      style={{
                        fontSize: "16px",
                        fontWeight: "500",
                        color: "var(--secondary-color)",
                      }}
                    >
                      DXB
                    </span>
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--white)",
                      fontSize: "10px",
                    }}
                  >
                    Dellhi, IND <br />
                    BG 678
                    {" & "}
                    1h 35min
                    <br />
                    Sun, 3rd june 2022
                  </Typography>
                </Box>
              </Box>
            </React.Fragment>
          }
          followCursor
        >
          <Box>
            <Typography
              sx={{
                color: "var(--third-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "10px",
                  md: "12px",
                },
              }}
            >
              7H 30Min
            </Typography>
            <Box className="stop-bar-parent" mt={1}>
              <img
                src={triangle}
                alt="icon"
                style={{ position: "relative", top: "-7px" }}
              />

              <Box className="stop-bar-line"></Box>
            </Box>
            <Typography
              sx={{
                color: "var(--third-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "12px",
                  sm: "10px",
                  md: "12px",
                },
              }}
            >
              2 STOP
            </Typography>
          </Box>
        </HtmlTooltip>
      </Typography>
    </Box>
  );
};

export default Transit;
