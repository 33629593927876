import React, { useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Grid,
  Typography,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import FlightIcon from "@mui/icons-material/Flight";
import { PDFDownloadLink } from "@react-pdf/renderer";
import OnewayQuotation from "./OnewayQuotaion";
import ReturnQuotation from "./ReturnQuotation";
import MulticityQuotation from "./MulticityQuotation";
import QuotationPDF from "./QuotationPDF";

const MakeQuotation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { quotetionArr, tripType } = location?.state;

  const [newQuotetionArr, setNewQuotetionArr] = useState(quotetionArr);

  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [markupPrice, setMarkupPrice] = useState(0);
  const [expanded, setExpanded] = React.useState("panel1");
  const handleSubmit = (e) => {
    e.preventDefault();
    setUpdatedPrice(Math.abs(Number.parseInt(markupPrice || 0)));
  };
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  console.log(newQuotetionArr);
  return (
    <Box>
      <UserHeader />

      <Box
        sx={{
          padding: "10px 0px",

          mt: 3,
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: "-70px",
            background: "var(--white)",
            borderRadius: "5px",
            p: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              mb: 1,
              px: 3,
              py: 1,
              mt: 2,
            }}
          >
            <Typography
              sx={{
                color: "var(--primary-color)",
                fontSize: { md: "16px", sm: "16px", xs: "14px" },
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              {newQuotetionArr?.length}
              <FlightIcon
                sx={{
                  transform: "rotate(90deg)",
                  color: "var(--secondary-color)",
                }}
              />
              Itineraries Selected For Make Quotation
            </Typography>
          </Box>

          <Grid container spacing={2} sx={{ display: "flex" }}>
            <Grid item md={9} sm={12} xs={12}>
              {tripType === "oneway" ? (
                <Box>
                  {newQuotetionArr?.map((flightData, index) => (
                    <OnewayQuotation
                      key={index}
                      flightData={flightData}
                      newQuotetionArr={newQuotetionArr}
                      setNewQuotetionArr={setNewQuotetionArr}
                      updatedPrice={updatedPrice}
                    />
                  ))}
                </Box>
              ) : tripType === "return" ? (
                <Box>
                  {newQuotetionArr?.map((flightData, index) => (
                    <ReturnQuotation
                      key={index}
                      flightData={flightData}
                      newQuotetionArr={newQuotetionArr}
                      setNewQuotetionArr={setNewQuotetionArr}
                      updatedPrice={updatedPrice}
                    />
                  ))}
                </Box>
              ) : (
                <Box>
                  {" "}
                  {newQuotetionArr?.map((flightData, index) => (
                    <MulticityQuotation
                      key={index}
                      flightData={flightData}
                      newQuotetionArr={newQuotetionArr}
                      setNewQuotetionArr={setNewQuotetionArr}
                      updatedPrice={updatedPrice}
                    />
                  ))}
                </Box>
              )}
            </Grid>
            <Grid item md={3} sm={12} xs={12}>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                sx={{
                  mb: "10px",
                  boxShadow: "none",
                  background: "var(--white)",
                }}
              >
                <AccordionSummary
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{ background: "var(--primary-color)" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "var(--secondary-color)",
                        fontWeight: "500",
                      }}
                    >
                      Add Markup
                    </Typography>
                    <Box>
                      {expanded === "panel1" ? (
                        <RemoveIcon
                          style={{
                            color: "var(--secondary-color)",
                            fontSize: "25px",
                          }}
                        />
                      ) : (
                        <AddIcon
                          style={{
                            color: "var(--secondary-color)",
                            fontSize: "25px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: "8px 0", bgcolor: "#EBF0F4" }}>
                  <form onSubmit={handleSubmit} style={{ padding: "0px 16px" }}>
                    <Box
                      sx={{
                        mt: "10px",
                        display: "flex",
                        flexDirection: "column",
                        gap: "3px",
                        label: {
                          fontSize: "16px",
                          color: "var(--primary-color)",
                          fontWeight: "400",
                        },
                        input: {
                          width: "calc(100% - 10px)",
                          padding: "5px",
                          background: "var(--lightGray)",
                          outline: "none",
                          border: "none",
                        },
                      }}
                    >
                      <label for="markupPrice">Add Markup</label>
                      <Box sx={{ width: "100%", display: "flex", gap: "5px" }}>
                        <input
                          type="number"
                          id="markupPrice"
                          placeholder="Enter Markup Amount"
                          value={markupPrice}
                          onChange={(e) => {
                            setMarkupPrice(
                              Math.abs(Number.parseInt(e.target.value))
                            );
                          }}
                        />
                        <Button
                          type="submit"
                          style={{
                            color: "white",
                            background: "var(--primary-color)",
                            fontSize: "12px",
                            borderRadius: "0px",
                          }}
                        >
                          Add
                        </Button>
                      </Box>
                      <Typography sx={{ color: "red", fontSize: "10px" }}>
                        *Markup will be added with base price
                      </Typography>
                    </Box>
                  </form>
                </AccordionDetails>
              </Accordion>
              {/* //todo: download pdf */}
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
                sx={{ boxShadow: "none" }}
              >
                <AccordionSummary
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                  sx={{ background: "var(--primary-color)" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                      alignItems: "center",
                      height: "20px",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "14px",
                        color: "var(--secondary-color)",
                        fontWeight: "500",
                      }}
                    >
                      Download PDF
                    </Typography>
                    <Box>
                      {expanded === "panel2" ? (
                        <RemoveIcon
                          style={{
                            color: "var(--secondary-color)",
                            fontSize: "25px",
                          }}
                        />
                      ) : (
                        <AddIcon
                          style={{
                            color: "var(--secondary-color)",
                            fontSize: "25px",
                          }}
                        />
                      )}
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails sx={{ bgcolor: "#EBF0F4" }}>
                  {newQuotetionArr?.map((data, index) => (
                    <Box key={index}>
                      <PDFDownloadLink
                        document={
                          <QuotationPDF
                            tripType={tripType}
                            quotationData={data}
                            markupPrice={updatedPrice}
                          />
                        }
                        fileName={`${
                          data?.departure ||
                          data?.godeparture ||
                          data?.AllLegs[0]?.DepFrom
                        }-${
                          data?.arrival ||
                          data?.backdeparture ||
                          data?.AllLegs[data?.AllLegs.length - 1]?.ArrTo
                        } ${data?.careerName} PDF`}
                        style={{ textDecoration: "none", fontSize: "12px" }}
                      >
                        {({ fileName, loading }) =>
                          loading ? (
                            "Loading document..."
                          ) : (
                            <a
                              href={fileName}
                              target="_blank"
                              style={{
                                cursor: "pointer",
                                color: "var(--primary-color)",
                                textDecoration: "none",
                                fontSize: "13px",
                              }}
                              rel="noreferrer"
                            >
                              {`${data?.departure || data?.godeparture}-${
                                data?.arrival || data?.backdeparture
                              } ${data?.careerName} PDF`}
                            </a>
                          )
                        }
                      </PDFDownloadLink>
                    </Box>
                  ))}
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default MakeQuotation;
