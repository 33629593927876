import { Box, Tab } from "@mui/material";
import React, { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
const Baggage = ({ pricebreakdown, flightData }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <TabContext value={value}>
      <Box
        sx={{
          padding: "15px 0",
          ".MuiTableCell-root .MuiPaper-root": {
            border: "none",
          },
          ".MuiTable-root": {
            th: {
              color: "white",
              fontSize: "13px",
              fontWeight: "500",
            },
            td: {
              color: "var(--primary-color)",
              fontSize: "13px",
              fontWeight: "500",
            },
          },
        }}
      >
        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
          <Table aria-label="simple table">
            <TableHead sx={{ minWidth: "100%" }}>
              <TableRow sx={{ backgroundColor: "var(--primary-color)" }}>
                <TableCell align="center">Pax Type</TableCell>
                <TableCell align="center">Baggage</TableCell>
                <TableCell align="center">Hand Baggage</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {pricebreakdown.map((data, i) => {
                return (
                  <TableRow
                    key={data.id}
                    sx={{
                      backgroundColor: `${(i + 1) % 2 !== 0 ? "#DEE8FF" : ""}`,
                    }}
                  >
                    <TableCell align="center" sx={{pl:"50px"}}>{data.PaxType}</TableCell>
                    <TableCell align="center">
                      {/* {flightData?.bags}{" "}
                    {flightData?.bags > 2
                      ? "KG"
                      : flightData?.bags > 1
                      ? "Pieces"
                      : "Piece"} */}
                      {data.Bag[0]?.Allowance?.pieceCount
                        ? data.Bag[0]?.Allowance?.pieceCount * 23 + " KG"
                        : data.Bag[0]?.Allowance?.weight +
                          " " +
                          data.Bag[0]?.Allowance?.unit}
                      {/* {data.Bag[0]?.Allowance?.pieceCount || data.Bag[0]?.Allowance?.weight } */}
                    </TableCell>
                    <TableCell align="center">7 KG</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      
    </TabContext>
  );
};

export default Baggage;
