import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";

import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { useRef } from "react";

const OtpVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const signUpFormData = location.state?.agentSignUpData || "";
  const tinImageFile = location.state?.tinImageFile || "";
  const data = location.state?.data || "";
  //   "email otp code", email;

  console.log(signUpFormData);

  const [otp, setOtp] = useState(["", "", "", "", "", ""]);
  const inputRefs = useRef([0, 1, 2, 3, 4, 5].map(() => React.createRef()));

  const handleOtpChange = (e, index) => {
    const newOtp = [...otp];
    newOtp[index] = e.target.value;
    setOtp(newOtp);

    // Move focus to the next input field
    if (index < otp.length - 1 && e.target.value !== "") {
      inputRefs.current[index + 1].current.focus();
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    const fullOtp = otp.join("");

    let url;

    if (data.action === "forgetPass") {
      url = "https://api.rtc.flyfarint.com/v1.0/b2b/auth/password/verify.php";
    } else {
      url =
        "https://api.rtc.flyfarint.com/v1.0/b2b/auth/registration/verify.php";
    }

    try {
      const code = fullOtp;
      const userEmail = signUpFormData?.email || data?.email;

      const requestData = {
        code: code,
        email: userEmail,
      };

      console.log(requestData);

      const response = await axios.post(url, JSON.stringify(requestData), {
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response?.data?.status === "success") {
        Swal.fire({
          title: "Success",
          text: `${response.data.message}`,
          icon: "success",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "OK",
        }).then(() => {
          if (data.action === "forgetPass") {
            navigate("/updatedPassword", {
              state: {
                email: userEmail,
              },
            });
          } else {
            Swal.fire({
              title: "Success",
              text: `Your agent registration is pending. Please wait for approval. For any query contact us at +880 1911101253`,
              icon: "success",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "OK",
            }).then(() => {
              navigate("/");
            });
          }
        });
        // navigate("/updatedPassword", { state: { email } });
        // You can add further actions after a successful request
      } else {
        // Handle unsuccessful response, e.g., show an error message
        Swal.fire({
          title: "Error",
          text: `${response.data.message}`,
          icon: "error",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [loading, setLoading] = useState(false);
  //   resend otp
  // const handleResend = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);

  //   const userEmail = signUpFormData?.email;

  //   try {
  //     const url =
  //       "https://api.rtc.flyfarint.com/v1.0/b2b/auth/registration/registration.php";

  //     if (userEmail) {
  //       const requestData = {
  //         email: userEmail,
  //       };

  //       const formData = new FormData();
  //       formData.append("name", signUpFormData.name);
  //       formData.append("compName", signUpFormData.compName);
  //       formData.append("compAddress", signUpFormData.compAddress);
  //       formData.append("phone", signUpFormData.phone);
  //       formData.append("country", signUpFormData.country);
  //       formData.append("email", signUpFormData.email);
  //       formData.append("password", signUpFormData.password);
  //       formData.append("tinImg", tinImageFile);

  //       await axios
  //         .post(url, formData, {
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //         })
  //         .then((res) => {
  //           console.log(res);
  //           if (res?.data?.status === "success") {
  //             // Handle successful response, e.g., show a message to the user
  //             Swal.fire({
  //               title: res?.data?.status,
  //               text: `${res.data.message}`,
  //               icon: res?.data?.status,
  //               confirmButtonColor: "var(--primary-color)",
  //               confirmButtonText: "OK",
  //             });
  //             //   navigate("/forgetpasswordOtp", { state: { email } });
  //           } else {
  //             // Handle unsuccessful response, e.g., show an error message
  //             Swal.fire({
  //               title: "Error",
  //               text: `${res.data.message}`,
  //               icon: "error",
  //               confirmButtonColor: "var(--primary-color)",
  //               confirmButtonText: "Ok",
  //             });
  //           }
  //         });
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleResend = async (e) => {
    e.preventDefault();
    setLoading(true);

    let url = "https://api.rtc.flyfarint.com/v1.0/b2c/auth/registration/otp.php";
    let body = {
      email: signUpFormData.email,
    };

    try {
      const res = await axios.post(
        url,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.status === "success") {
        Swal.fire({
          title: "Success",
          text: `OTP resend to ${signUpFormData.email}`,
          icon: "success",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "OK",
        });
      } else {
        Swal.fire({
          title: "Error",
          text: `${res.data.message}`,
          icon: "error",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Box sx={{ bgcolor: "var(--primary-color)" }}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            background: "#ffff",
            width: "450px",
            px: "20px",
            borderRadius: "5px",
            py: "15px",
          }}
        >
          <Box sx={{ textAlign: "center", my: "10px" }}>
            <Typography
              style={{
                color: "var(--primary-color)",
                fontSize: "20px",
                fontWeight: "500",
                height: "22px",
              }}
            >
              RTC{" "}
              <span
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "14px",
                }}
              >
                Tours
              </span>
            </Typography>
            <Typography
              style={{
                color: "var(--primary-color)",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              & Travels
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: "var(--profile-boday-color)",
              textAlign: "center",
            }}
          >
            OTP Verification
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              py: "10px",
              color: "var(--profile-boday-color)",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            Enter the 6 digit ve code recived on your Email ID
          </Typography>
          <form onSubmit={handleOtpVerification}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "70%",
                margin: "0 auto",
              }}
            >
              {[0, 1, 2, 3, 4, 5].map((index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  style={{
                    color: "var( --primary-color)",
                    outline: "none",
                    background: "none",
                    width: "40px",
                    textAlign: "center",
                    fontSize: "18px",
                    borderRadius: "5px",
                    padding: "10px 5px",
                    border: "2px solid var( --primary-color)",
                  }}
                  name={`otp${index}`}
                  value={otp[index]}
                  onChange={(e) => handleOtpChange(e, index)}
                  ref={inputRefs.current[index]}
                />
              ))}
            </Box>

            <Button
              sx={{
                borderRadius: "6px",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
                width: "100%",
                height: "45px",
                textTransform: "capitalize",
                fontSize: "15px",
                mt: "20px",
                ":hover": {
                  backgroundColor: "var(--primary-color)",
                },
              }}
              type="submit"
            >
              Verify OTP
            </Button>
            <Typography
              onClick={handleResend}
              sx={{
                color: "var(--primary-color)",
                cursor: "pointer",
                fontSize: "15px",
                my: "10px",
                textAlign: "right",
              }}
            >
              {loading ? "Please Wait..." : "  Resend OTP ?"}
            </Typography>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default OtpVerification;
