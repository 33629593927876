import React from "react";
import {
  Box,
  Grid,
  Tooltip,
  Typography,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Tab,
  Collapse,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Transit from "../Transit/Transit";
import { format } from "date-fns";
import { useState } from "react";
import FlightIcon from "@mui/icons-material/Flight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AirLineName from "../../components/SingleFlight/AirLineName";
import commaNumber from "comma-number";
import FarePolicy from "../../components/FarePolicy/FarePolicy";
import triangle from "../../images/Icon/triangle.png";
import moment from "moment";
const isMobile = window.innerWidth <= 768;

const OnewayFlightDetails = ({ data }) => {
  const [flightDetails, setFlightDetails] = useState(true);
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const adultCount = data?.adultCount;
  const childCount = data?.childCount;
  const infantCount = data?.infantCount;

  return (
    <Box>
      {isMobile ? (
        <Grid container sx={{ mt: "20px" }}>
          <Grid
            item
            xs={12}
            container
            sx={{
              bgcolor: "#a3c7ff3b",
              borderRadius: "4px",
              px: "13px",
              py: "10px",
            }}
          >
            {/* Array mapping starts here */}
            {data?.segments.map((flight, i, arr) => {
              const datetime1 = moment(
                arr[i]?.arrival_time,
                "YYYY-MM-DD HH:mm:ss"
              );
              const datetime2 = moment(
                arr[i + 1]?.departure_time,
                "YYYY-MM-DD HH:mm:ss"
              );

              const diff = moment.duration(datetime2.diff(datetime1));

              const diffHours = diff.hours();
              const diffMinutes = diff.minutes();

              return (
                <Grid item xs={12}>
                  <Box
                    key={i}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      borderLeft: "1.5px dotted var(--secondary-color)",
                      mt: "20px",
                      pl: "15px",
                      pb: "10px",
                      position: "relative",
                      width: "100%",
                    }}
                  >
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "var(--primary-color)",
                        borderRadius: "50px",
                        position: "absolute",
                        left: "-5px",
                        top: "-5px",
                      }}
                    ></div>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          pb: "0px",
                        }}
                      >
                        {flight?.departure_code}{" "}
                        {new Date(flight?.departure_time).toLocaleTimeString(
                          [],
                          {
                            hour: "2-digit",
                            minute: "2-digit",
                          }
                        )}
                      </span>
                      <span style={{ fontSize: "12px", pt: "0px" }}>
                        {flight?.departure_airport}
                      </span>
                      <span style={{ fontSize: "12px", pt: "0px" }}>
                        {moment(flight?.departure_time).format("D MMMM YYYY")}
                        {/* {flightData?.departureDate} */}
                      </span>
                    </Box>
                    <Box sx={{ mt: "20px", display: "flex", gap: "10px" }}>
                      <img
                        src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.marketing_carier_code}.png`}
                        style={{
                          width: "40px",
                          height: "40px",
                          borderRadius: "50%",
                        }}
                        alt={`${flight?.marketing_carier_name}`}
                      />
                      <Box>
                        <span style={{ fontSize: "13px", pt: "0px" }}>
                          {flight?.marketing_carier_name}
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            fontSize: "12px",
                            pt: "0px",
                            color: "var(--primary-color)",
                            fontWeight: "600",
                          }}
                        >
                          {flight?.marketing_carier_code}{" "}
                          {flight?.marketing_flight_number}
                        </span>{" "}
                        <br />
                        <span
                          style={{
                            fontSize: "10px",
                            pt: "0px",
                            color: "var(--primary-color)",
                          }}
                        >
                          {flight?.air_craft_type_name}
                        </span>
                        <br />
                        <span
                          style={{
                            fontSize: "12px",
                            pt: "0px",
                            color: "var(--primary-color)",
                          }}
                        >
                          Airlines PNR:{flight?.airlines_pnr}
                        </span>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        mt: "15px",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "15px",
                          fontWeight: "500",
                          pb: "0px",
                        }}
                      >
                        {flight?.arrival_code}{" "}
                        {new Date(flight?.arrival_time).toLocaleTimeString([], {
                          hour: "2-digit",
                          minute: "2-digit",
                        })}
                      </span>
                      <span style={{ fontSize: "12px", pt: "0px" }}>
                        {flight?.arrival_airport}
                      </span>
                      <span style={{ fontSize: "12px", pt: "0px" }}>
                        {moment(flight?.arrival_time).format("D MMMM YYYY")}
                        {/* {flightData?.arrivalTime} */}
                      </span>
                    </Box>
                    <div
                      style={{
                        height: "10px",
                        width: "10px",
                        backgroundColor: "var(--primary-color)",
                        borderRadius: "50px",
                        position: "absolute",
                        left: "-5px",
                        bottom: "0px",
                      }}
                    ></div>
                  </Box>
                </Grid>
              );
            })}
            {/* Array mapping ends here */}
          </Grid>
        </Grid>
      ) : (
        <>
          {" "}
          <Grid
            container
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
              },
              transition: "all .5s ease-in-out",
              borderRadius: "5px",
            }}
          >
            <Grid item md={12} sx={{ height: "100%" }}>
              {/* //todo:one */}
              {data?.segments.map((flight, i, arr) => {
                const datetime1 = moment(
                  arr[i]?.arrival_time,
                  "YYYY-MM-DD HH:mm:ss"
                );
                const datetime2 = moment(
                  arr[i + 1]?.departure_time,
                  "YYYY-MM-DD HH:mm:ss"
                );

                const diff = moment.duration(datetime2.diff(datetime1));

                const diffHours = diff.hours();
                const diffMinutes = diff.minutes();

                return (
                  <Grid
                    key={i}
                    container
                    item
                    xs={12}
                    sx={{ mb: `${i === arr.length - 1 ? "" : "20px"}` }}
                  >
                    <Grid item xs={12} md={3}>
                      <Box>
                        {/* //todo: Image Part */}
                        <Box>
                          <img
                            src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.marketing_carier_code}.png`}
                            width="50px"
                            height="50px"
                            alt={`${data?.segments[0]?.marketing_carier_code}`}
                          />
                        </Box>

                        {/* //todo: Text Part */}
                        <Box width="90%">
                          <Tooltip>
                            <Typography
                              sx={{
                                color: "var(--primary-color)",
                                fontWeight: 400,
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "13px",
                                  cursor: "pointer",
                                },
                              }}
                              noWrap
                            >
                              {flight?.marketing_carier_name}{" "}
                            </Typography>
                          </Tooltip>
                          <Tooltip>
                            <Typography
                              sx={{
                                color: "var(--mateBlack)",
                                fontWeight: 400,
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "12px",
                                  cursor: "pointer",
                                },
                              }}
                              noWrap
                            >
                              {flight?.marketing_carier_code} -{" "}
                              {flight?.marketing_flight_number}
                            </Typography>
                          </Tooltip>
                          <Tooltip>
                            <Typography
                              sx={{
                                color: "var(--mateBlack)",
                                fontWeight: 400,
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "12px",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              {flight?.air_craft_type_name}
                            </Typography>
                          </Tooltip>
                          <Tooltip>
                            <Typography
                              sx={{
                                color: "var(--mateBlack)",
                                fontWeight: 400,
                                fontSize: {
                                  xs: "10px",
                                  sm: "12px",
                                  md: "12px",
                                  cursor: "pointer",
                                },
                              }}
                            >
                              Airlines PNR:{" "}
                              <span style={{ fontWeight: "500" }}>
                                {flight?.airlines_pnr}
                              </span>
                            </Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                    {/* //todo:two */}
                    <Grid item xs={4} md={3}>
                      <Box
                        style={{
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "start",
                            alignItems: "start",
                            gap: "1px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "var(--third-color)",
                              fontWeight: 400,
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "12px",
                              },
                            }}
                          >
                            Depart
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mateBlack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "15px",
                                sm: "18px",
                                md: "22px",
                              },
                            }}
                          >
                            {flight?.departure_code}
                          </Typography>

                          <Typography
                            sx={{
                              color: "var(--primary-color)",
                              fontWeight: 400,
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                              },
                              cursor: "pointer",
                              width: "97%",
                            }}
                          >
                            {flight.departure_location} |{" "}
                            {flight?.departure_airport}{" "}
                            {flight?.departure_terminal &&
                            flight?.departure_terminal.length > 4
                              ? "|" + flight?.departure_terminal
                              : flight?.departure_terminal &&
                                "| TERMINAL " + flight?.departure_terminal}
                          </Typography>

                          <Typography
                            sx={{
                              color: "var(--mateBlack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "10px",
                                sm: "12px",
                                md: "13px",
                              },
                            }}
                          >
                            {format(
                              new Date(flight?.departure_time.toString()),
                              "dd MMM yyyy hh:mm a"
                            )}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {/* //todo: three */}
                    <Grid item xs={4} md={3}>
                      <Transit flightData={flight} />
                    </Grid>
                    {/* //todo: four */}
                    <Grid item xs={4} md={3}>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                          alignItems: "end",
                          gap: "1px",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--third-color)",
                            fontWeight: 400,

                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "12px",
                            },
                          }}
                        >
                          Arrival
                        </Typography>
                        <Typography
                          sx={{
                            color: "var(--mateBlack)",
                            fontWeight: 500,
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "22px",
                            },
                          }}
                        >
                          {`${flight?.arrival_code}`}
                        </Typography>
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: 400,
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "13px",
                            },
                            cursor: "pointer",
                            width: "97%",
                            textAlign: "right",
                          }}
                        >
                          {flight.arrival_location} | {flight?.arrival_airport}{" "}
                          {flight?.arrival_terminal &&
                          flight?.arrival_terminal.length > 4
                            ? "| " + flight?.arrival_terminal
                            : flight?.arrival_terminal &&
                              "| TERMINAL " + flight?.arrival_terminal}
                        </Typography>

                        <Typography
                          sx={{
                            color: "var(--mateBlack)",
                            fontWeight: 500,
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "13px",
                            },
                          }}
                        >
                          {format(
                            new Date(flight?.arrival_time.toString()),
                            "dd MMM yyyy hh:mm a"
                          )}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item md={12}>
                      <Typography
                        sx={{
                          display: `${i === arr.length - 1 ? "none" : "block"}`,
                          borderRadius: "4px",
                          background: "#DEE8FF",
                          width: "85%",
                          color: "var(--primary-color)",
                          padding: "3px 10px",
                          fontSize: "10px",
                          fontWeight: 500,
                          margin: "0 auto",
                          textAlign: "center",
                          mt: "15px",
                          py: "8px",
                        }}
                      >
                        Change planes at{" "}
                        {moment(arr[i + 1]?.departure_time).format("hh:mm a")} |{" "}
                        {flight?.arrival_airport} | {flight?.arrival_airport} |
                        Connecting Time:{" "}
                        {diffHours + "H " + diffMinutes + "Min"}
                      </Typography>
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>

            {/* //todo:Flight Details start------------ */}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default OnewayFlightDetails;
