import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { useState } from "react";
import RoundwayUserInfo from "./RoundwayUserInfo";
import { addMonths, format } from "date-fns";
// import { AuthContext } from "../../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import bookingSuccess from "../../images/undraw/undraw_travel_booking_re_6umu.svg";
import noFareFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";

const RoundwayBooking = ({ searchData }) => {
  const { roundData, tripType, adultCount, childCount, infant } = searchData;
  const {
    pricebreakdown,
    godeparture,
    careerName,
    godepartureDate,
    price,
    clientPrice,
    basePrice,
    taxes,
    triptype,
    system,
    segment,
    backarrivalDate,
    backdeparture,
    career,
  } = roundData;
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const userInfo = secureLocalStorage.getItem("user-info");
  const userPhone = userInfo?.agent_phone_number;
  const agentId = userInfo?.agent_id;
  const staffId = userInfo?.staff_id;
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage2, setSelectedImage2] = useState([]);
  const [roundErr, setRoundErr] = useState(false);
  const [passCopyErr, setPassCopyErr] = useState(false);

  // todo: date validation
  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  let dateAfterSixMonths = addMonths(new Date(roundData?.goarrivalDate), 6);
  let dateBeforeTwelveYears = addMonths(
    new Date(roundData?.goarrivalDate),
    -144
  );
  let dateBeforeTwoYears = addMonths(new Date(roundData?.goarrivalDate), -24);
  let dateBeforeSixMonths = addMonths(new Date(roundData?.goarrivalDate), -6);

  const [flightPassengerData, setFlightPassengerData] = useState({
    passengers: [
      ...[...new Array(parseInt(searchData.adultCount))].map((item, index) => {
        return {
          type: "ADT",
          fName: "",
          lName: "",
          gender: "",
          dob: format(new Date(dateBeforeTwelveYears), "dd MMM yyyy"),
          passNation: "BD",
          passNo:
            triptype === "Inbound"
              ? Math.round(Math.random() * 100000000 + index)
              : "",
          passEx:
            triptype === "Inbound"
              ? new Date(dateAfterSixMonths).toLocaleDateString("sv")
              : format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
          openDate: false,
          openPassExDate: false,
          prefix: "MR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
      ...[...new Array(parseInt(searchData.childCount))].map((item, index) => {
        return {
          type: "CNN",
          fName: "",
          lName: "",
          gender: "",
          dob: format(new Date(dateBeforeTwoYears), "dd MMM yyyy"),
          passNation: "BD",
          passNo:
            triptype === "Inbound"
              ? Math.round(Math.random() * 100000000 + index)
              : "",
          passEx:
            triptype === "Inbound"
              ? new Date(dateAfterSixMonths).toLocaleDateString("sv")
              : format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
          openDate: false,
          openPassExDate: false,
          prefix: "MSTR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
      ...[...new Array(parseInt(searchData.infant))].map((item, index) => {
        return {
          type: "INF",
          fName: "",
          lName: "",
          gender: "",
          dob: format(new Date(dateBeforeSixMonths), "dd MMM yyyy"),
          passNation: "BD",
          passNo:
            triptype === "Inbound"
              ? Math.round(Math.random() * 100000000 + index)
              : "",
          passEx:
            triptype === "Inbound"
              ? new Date(dateAfterSixMonths).toLocaleDateString("sv")
              : format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
          openDate: false,
          openPassExDate: false,
          prefix: "MSTR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
    ],
    email: userInfo?.user_email,
    phone: userInfo?.user_phone_number,
    tripType: "return",
    segment: segment,
  });

  const handleOnChange = (e, type, index) => {
    let value = e.target.value;
    let field = e.target.name;


    if (e.target.files && e.target.files[0] && (field === "passCopy" || field === "visaCopy")) {
      value = e.target.files[0];
    }

    const tempFlightData = [...flightPassengerData.passengers];

    if (index !== -1 && field === 'gender' && tempFlightData[index].type === 'ADT' && value === 'Male') {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: 'MR'
      };
    } else if (index !== -1 && field === 'gender' && tempFlightData[index].type === 'ADT' && value === 'Female') {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: 'MRS'
      };
    } else if (index !== -1 && field === 'gender' && tempFlightData[index].type === 'CNN' && value === 'Male') {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: 'MSTR'
      };
    }
    else if (index !== -1 && field === 'gender' && tempFlightData[index].type === 'CNN' && value === 'Female') {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: 'MISS'
      };
    }
    else if (index !== -1 && field === 'gender' && tempFlightData[index].type === 'INF' && value === 'Male') {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: 'MSTR'
      };
    }
    else if (index !== -1 && field === 'gender' && tempFlightData[index].type === 'INF' && value === 'Female') {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: 'MISS'
      };
    }
    else if (index !== -1) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
      };
    }

    console.log(tempFlightData[index]);
    setFlightPassengerData({
      ...flightPassengerData,
      passengers: tempFlightData,
    });
  };

  const handleOpenDateState = (type, item, i) => {
    const tempFlightData = [...flightPassengerData.passengers];

    if (type === "ADT" || type === "CNN" || type === "INF") {
      const passengerType = type.toLowerCase();
      tempFlightData[i] = {
        ...tempFlightData[i],
        openDate: !item.openDate,
        openPassExDate: false,
      };

      setFlightPassengerData({
        ...flightPassengerData,
        passengers: tempFlightData,
      });
    }
  };

  const handleOpenPassDateState = (type, index, item) => {
    const tempFlightData = [...flightPassengerData.passengers];

    if (type === "ADT" || type === "CNN" || type === "INF") {
      const passengerType = type.toLowerCase(); // Map type to lowercase
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: false,
        openPassExDate: !tempFlightData[index].openPassExDate,
      };

      setFlightPassengerData({
        ...flightPassengerData,
        passengers: tempFlightData,
      });
    }
  };

  const handleImageChange = (event, i) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage([...selectedImage, file]);
    }
  };

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage2([...selectedImage2, file]);
    }
  };

  const getPrice = (type, property) => {
    const prices = pricebreakdown.find((item) => item.PaxType === type);
    if (prices) return parseFloat(prices[property]);
  };
  const [bookingRoundError, setBookingRoundError] = useState(false)

  const limitTime =
    system === "Sabre" ? new Date() : system === "Galileo" ? new Date() : "";

  const allPassNo = flightPassengerData?.passengers?.map(
    (passenger) => passenger.passNo
  );
  const passengerPassCopy = flightPassengerData?.passengers?.map(
    (passenger) => passenger.passCopy
  );
  const passengerVisaCopy = flightPassengerData?.passengers?.map(
    (passenger) => passenger.visaCopy
  );

  const bookingBody = {
    flightPassengerData: { passengers: [...flightPassengerData.passengers] },

    bookingInfo: {
      agentId: agentId || "",
      staffId: staffId || "",
      system: roundData.system === "Sabre" && "Sabre" || roundData.system === "Galileo" && "Galileo",
      from: godeparture,
      to: backdeparture,
      airlines: careerName,
      tripType: "return",
      travelDate: godepartureDate,
      name: userInfo?.user_name,
      email: userInfo?.user_email,
      phone: userInfo?.user_phone_number,
      pax: flightPassengerData?.passengers?.length,
      adultcount: flightPassengerData?.adultCount,
      childcount: flightPassengerData?.childCount,
      infantcount: flightPassengerData?.infantCount,
      subagentprice: Number.parseInt(price),
      agentprice: Number.parseInt(price),
      netcost: Number.parseInt(price),
      adultcostbase:
        Math.round(
          (getPrice("ADT", "BaseFare") + getPrice("ADT", "ServiceFee")) *
          adultCount
        ) || 0,
      childcostbase:
        Math.round(
          (getPrice("CNN", "BaseFare") + getPrice("CNN", "ServiceFee")) *
          childCount
        ) || 0,
      infantcostbase:
        Math.round(
          (getPrice("INF", "BaseFare") + getPrice("INF", "ServiceFee")) * infant
        ) || 0,
      adultcosttax: Math.round(getPrice("ADT", "Tax") * adultCount) || 0,
      childcosttax: Math.round(getPrice("CNN", "Tax") * childCount) || 0,
      infantcosttax: Math.round(getPrice("INF", "Tax") * infant) || 0,
      grosscost: Math.round(clientPrice) || 0,
      basefare: Math.round(basePrice) || 0,
      tax: Math.round(taxes) || 0,
      timelimit: limitTime,
      journeyType: triptype,
      coupon: "",
      airlinescode: career,
      adultbag: "go-20|back-20",
      childbag: "",
      infantbag: "",
      platform: "B2B",
      refundable: "yes",
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infant,
      segment: roundData?.segment,
    },
    saveBooking: {
      agentFare: price,
      clientFare: clientPrice,
      commission: parseFloat(clientPrice) - parseFloat(price),
      tripType: "return",
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infant,
      flightData: {
        ...roundData,
      },
    },
    system: roundData.system === "Sabre" && "Sabre" || roundData.system === "Galileo" && "Galileo",
    agentId: agentId || "",
    staffId: staffId || "",
    platform: "B2B",
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { passengers } = flightPassengerData;

    for (let i = 0; i < passengers.length; i++) {
      const passenger = passengers[i];
      console.log(passenger);

      const fieldNamesMap = {
        fName: "First Name",
        lName: "Last Name",
        dob: "Date of Birth",
        gender: "Gender",
        passNation: "Passport Nation",
        passNo: "Passport No",
        passEx: "Passport Validity",
        prefix: "Prefix",
        phone: "Phone",
        email: "Email",
        passCopy: "Passport Copy",
      };

      let fieldsToCheck;

      if (roundData?.triptype === "Inbound") {
        fieldsToCheck = ["fName", "lName", "gender", "phone", "email", "dob", "passNation", "passEx", "prefix"];
      } else {
        fieldsToCheck = ["fName", "lName", "gender", "phone", "email", "dob", "passNation", "passNo", "passEx", "prefix", "passCopy"];
      }

      const emptyFields = fieldsToCheck
        .filter(field => !passenger[field])
        .map(field => fieldNamesMap[field]);

      if (emptyFields.length > 0) {
        setPassCopyErr(true);
        const errorMessage = `<span style="color: black;font-weight: 500;">Please, Provide required information to book.</span>`;
        const errorTitle = `<span style="color: red;font-weight: 500;">${i === 0 ? '1st' : i === 1 ? '2nd' : i === 2 ? '3rd' : `${i + 1}th`} Passenger's Information Is Missing</span>`;

        Swal.fire({
          title: errorTitle,
          html: errorMessage,
          icon: "error",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });

        return;
      }
    }

    const formData = new FormData();
    formData.append("bookingBody", JSON.stringify(bookingBody));

    for (let i = 0; i < allPassNo.length; i++) {
      const passNo = allPassNo[i];
      const image = roundData.triptype === "Inbound" ? null : selectedImage[i];
      const image2 = roundData.triptype === "Inbound" ? null : selectedImage2[i];

      if (image !== null) {
        formData.append(`${passNo}passportCopy`, image);
      }

      if (image2 !== null) {
        formData.append(`${passNo}visaCopy`, image2);
      }
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const url = "https://api.rtc.flyfarint.com/v1.0/b2b/air/book/hold.php";

    setClicked(true);

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();

      setClicked(false);

      if (data.status === "success") {
        // Handle success
        Swal.fire({
          imageUrl: bookingSuccess,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Success",
          html: `<div>Thank you so much for booking a flight ticket with Fly Far International.</div><strong>Please issue your booking ticket within the time limit specified,</strong> <div>otherwise your booking request will be automatically cancelled.</div>`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/dashboard/airticketbooking");
        });
      } else {
        // Handle failure
        Swal.fire({
          imageUrl: noFareFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: data?.message,
          html: "<div>Booking Failed. If you have any queries please contact us at <strong>support@flyfarint.com or +09606-912912</strong></div>",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Another Flights.",
        }).then(() => {
          navigate(-1);
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setClicked(false);
      // Handle error
    }
  };


  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          mt: "20px",
        
        }}
      >
        <Grid container item xs={12}  mt={4}>
          <RoundwayUserInfo
            roundErr={roundErr}
            passCopyErr={passCopyErr}
            bookingRoundError={bookingRoundError}
            flightData={roundData}
            flightPassengerData={flightPassengerData}
            setFlightPassengerData={setFlightPassengerData}
            searchData={searchData}
            handleOnChange={handleOnChange}
            setSelectedImage={setSelectedImage}
            handleOpenDateState={handleOpenDateState}
            handleImageChange2={handleImageChange2}
            handleImageChange={handleImageChange}
            selectedImage={selectedImage}
            selectedImage2={selectedImage2}
            setSelectedImage2={selectedImage2}
            dateBeforeTwelveYears={dateBeforeTwelveYears}
            handleOpenPassDateState={handleOpenPassDateState}
            dateAfterSixMonths={dateAfterSixMonths}
            dateBeforeTwoYears={dateBeforeTwoYears}
            passengerPassCopy={passengerPassCopy}
            passengerVisaCopy={passengerVisaCopy}
          />
        </Grid>
      </Box>

      <Grid item xs={12}>
        <Button
          type="submit"
          disabled={clicked ? true : false}
          sx={{
            bgcolor: "var(--secondary-color)",
          
            width: "100%",
            fontSize: "17px",
            fontWeight: "500",
            color: "white",
            mt: "30px",
            ":hover": {
              bgcolor: "var(--secondary-color)",
            },
          }}
        >
          {clicked ? "Please Wait...." : " Book & Hold"}
        </Button>
      </Grid>
    </form>
  );
};

export default RoundwayBooking;
