import {
  Box,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import secureLocalStorage from "react-secure-storage";
import moment from "moment";
import UserHeader from "../UserPage/UserHeader/UserHeader";

const styles = {
  root: {
    height: "80vh", // Set a fixed height for the container
    width: "100%", // Set a width for the container
    display: "flex",
    flexDirection: "column",
  },
  dataGrid: {
    flexGrow: 1,
  },
};

const OthersBooking = () => {
  const users = secureLocalStorage.getItem("user-info");
 
  const [othersData, setOthersData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/b2b/others/index.php?agentId=${users?.agent_id}`
    )
      .then((res) => res.json())
      .then((data) => {
        // const cashFilter = data;
    
        setOthersData(data.others);
        // setIsLoading(true);
      });
  }, []);

  const columns = [
    { field: "others_id", headerName: "Ref Id", width: 120 },

    {
      field: "company_name",
      headerName: "Company Name",
      suppressSizeToFit: true,
      width: 140,
    },

    {
      field: "description",
      headerName: "Description",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "service_type",
      headerName: "Service Type",
      suppressSizeToFit: true,
      width: 200,
    },
    {
      field: "attachment",
      headerName: "Attachment",
      suppressSizeToFit: true,
      width: 200,
      renderCell: (params) => {
        return (
          <Box>
            <a
              style={{ color: "#000000de" }}
              href={params?.row?.attachment}
              target="_blank"
              rel="noreferrer"
            >
              View
            </a>
          </Box>
        );
      },
    },
    {
      field: "amount",
      headerName: "Cost",
      suppressSizeToFit: true,
      width: 200,
      renderCell: (params) => {
        return <Box>{params?.row?.amount} BDT</Box>;
      },
    },

    {
      field: "created_at",
      headerName: "Operation Time",
      suppressSizeToFit: true,
      width: 180,
      renderCell: (params) => {
        return params?.row?.reason === "" ? "N/A" : params?.row?.reason;
      },
    },
    {
      field: "created_by",
      headerName: "created By",
      suppressSizeToFit: true,
      width: 180,
    },
  ];
  return (
    <Box>
      <UserHeader />
      <Box
        sx={{
          backgroundColor: "var(--white)",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          py: "20px",
          m: "0 auto",
          width: "90%",
          mt: "-10vh",
          position: "relative",
        }}
      >
        <Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "absolute",
              }}
            >
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "19px",
                  color: "var(--section-title)",
                  pl: "12px",
                  pt: "0px",
                }}
              >
                Others Booking
              </Typography>
            </Box>
          </Box>

          <Box className="balance-transaction">
            {isLoading === false ? (
              <>
                <Box
                  style={styles.root}
                  sx={{
                    "& .MuiDataGrid-root": {
                      border: "none",
                      fontSize:"10px",
                    },
                    "& .MuiDataGrid-cell": {
                      borderBottom: "1px solid var(--input-bgcolor)",
                    },
                    "& .MuiDataGrid-columnHeaders": {
                      backgroundColor: "var(--primary-color)",
                      borderBottom: "none",
                      color: "var(--white)",
                      fontSize: "10px",
                      textTransform: "uppercase",
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      backgroundColor: "var(--body-color)",
                    },
                    "& .MuiDataGrid-footerContainer": {
                      borderTop: "none",
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "& .MuiDataGrid-toolbarContainer": {
                      marginLeft: "auto",
                    },
                    "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                      color: "var(--primary-color)",
                      background: "var(--secondary-color)",
                      fontSize: "10px",
                      mb: 2,
                    },
                    ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root":
                      {
                        color: "var(--white)",
                      },
                    ".MuiTablePagination-selectLabel": {
                      color: "var(--white)",
                    },
                    ".MuiSelect-select": {
                      color: "var(--white)",
                    },
                    ".css-levciy-MuiTablePagination-displayedRows": {
                      color: "var(--white)",
                    },
                    ".MuiDataGrid-cellContent": {
                      color: "var(--text-color)",
                    },
                  }}
                >
                  <DataGrid
                    rows={othersData}
                    columns={columns}
                    components={{ Toolbar: GridToolbar }}
                    scrollbarSize={5}
                    style={styles.dataGrid}
                  />
                </Box>
              </>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <CircularProgress />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default OthersBooking;
