
import { Grid, Typography, Box, Stack } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import commaNumber from "comma-number";
import { useEffect } from "react";
import { AiFillCaretDown } from "react-icons/ai";

export const MultiCityFlightInfoDetails = ({
  flightData,
  pricebreakdown
}) => {

  return (
    <>
      <Box sx={{ padding: "0 0 10px 0px", }}>
        <Typography variant="body2" sx={{ color: "#444542" }}>
          Total Payable
        </Typography>
        <Typography variant="title-lg" sx={{ color: "#444542" }}>
          ৳ {Number(flightData?.BasePrice).toLocaleString("en-IN")}
        </Typography>
      </Box>

      <Typography
        sx={{
          color: "var(--white)",
          fontSize: "12px",
          bgcolor: "var(--secondary-color)",
          padding: "4px 17px",
          fontWeight: 500,
        }}
      >
        Price Breakdown
      </Typography>

      {pricebreakdown.map((price, i) => {
        const { BaseFare, PaxCount, PaxType, Taxes } = price;
        return (
          <Box key={i} sx={{ mt: "5px", pr: "10px" }}>
            <Typography variant="h6">
              {PaxType === "ADT"
                ? "Adult"
                : PaxType === "CNN"
                  ? "Child"
                  : "Infant"}{" "}
              x{PaxCount}
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ my: "5px" }}>
              <Typography variant="caption">Base Fare x{PaxCount}</Typography>
              <Typography sx={{color: "var(--secondary-color)",fontSize:"12px"}}>
                {Number(BaseFare * PaxCount).toLocaleString("en-IN")} BDT
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"} sx={{ my: "5px" }}>
              <Typography variant="caption">Tax x{PaxCount}</Typography>
              <Typography sx={{color: "var(--secondary-color)",fontSize:"12px"}}>
                {Number(PaxCount * Taxes).toLocaleString("en-IN")} BDT
              </Typography>
            </Stack>
          </Box>
        );
      })}

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ pt: "3px", pr: "10px", my: "5px" }}
      >
        <Typography variant="caption">Total Base & Tax</Typography>
        <Typography sx={{color: "var(--secondary-color)",fontSize:"12px"}}>
          {(
            parseFloat(flightData?.BasePrice) + parseFloat(flightData?.Taxes)
          ).toLocaleString("en-IN")} BDT
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ pt: "3px", pr: "10px", my: "5px" }}
      >
        <Typography variant="caption">Customer Invoice Total</Typography>
        <Typography  sx={{color: "var(--secondary-color)",fontSize:"12px"}}>
          {Number(flightData?.netcost).toLocaleString("en-IN")} BDT
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ pt: "3px", pr: "10px" }}
      >
        <Typography variant="caption">Discount</Typography>
        <Typography  sx={{color: "var(--secondary-color)",fontSize:"12px"}}>
          {(flightData?.grosscost - flightData?.netcost).toLocaleString("en-IN")}
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ pt: "3px", pr: "10px",mt:"15px" }}
      >
        <Typography
          sx={{ fontSize: "14px",  fontWeight: "500" }}
        >
          Grand Total
        </Typography>
        <Typography sx={{ color: "var(--secondary-color)", fontSize: "14px" }}>
          ৳ {Number(flightData?.netcost).toLocaleString("en-IN")}
        </Typography>
      </Stack>
    </>
  );
};


