import { Box, Button, Container, Stack, Typography, Grid } from "@mui/material";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlightDetails from "../SingleFlight/FlightDetails";
// import OnewayFlightDetails from "./OnewayFlightDetails";
// import OnewayPrice from "./OnewayPrice";
// import OnewayBooking from "./OnewayBooking";
import { addMonths, format } from "date-fns";
// import { AuthContext } from "../../context/AuthProvider";
import { useId } from "react";
import Swal from "sweetalert2";
import bookingSuccess from "../../images/undraw/undraw_travel_booking_re_6umu.svg";
import noFareFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import OnewayFlightDetails from "./OnewayFlightDetails";
import OnewayPrice from "./OnewayPrice";
import OnewayBooking from "./OnewayBooking";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import secureLocalStorage from "react-secure-storage";
const isMobile = window.innerWidth <= 768;

const OnewayFlightInfo = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [coupon, setCoupon] = useState("");
  const [clicked, setClicked] = useState(false);
  const { data } = location.state;
  const { flightData } = data;
  const userInfo = secureLocalStorage.getItem("user-info");
  const userPhone = userInfo?.agent_phone_number;
  const agentId = userInfo?.agent_id;
  const staffId = userInfo?.staff_id;

  // todo: date validation
  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  let dateAfterSixMonths = addMonths(new Date(flightData.arrivalDate), 6);
  let dateBeforeTwelveYears = addMonths(new Date(flightData.arrivalDate), -144);
  let dateBeforeTwoYears = addMonths(new Date(flightData.arrivalDate), -24);
  let dateBeforeSixMonths = addMonths(new Date(flightData.arrivalDate), -6);

  // flight passengers data
  const [flightPassengerData, setFlightPassengerData] = useState({
    passengers: [
      ...[...new Array(parseInt(data?.adultCount))].map((item, index) => {
        return {
          type: "ADT",
          fName: "",
          lName: "",
          gender: "",
          phone: "",
          email: "",
          dob: format(new Date(dateBeforeTwelveYears), "dd MMM yyyy"),
          passNation: "BD",
          passNo:
            flightData.triptype === "Inbound"
              ? Math.round(Math.random() * 100000000 + index)
              : "",
          passEx:
            flightData.triptype === "Inbound"
              ? new Date(dateAfterSixMonths).toLocaleDateString("sv")
              : format(new Date(dateAfterSixMonths), "yyyy-MM-dd"),
          openDate: false,
          openPassExDate: false,
          prefix: "MR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
      ...[...new Array(parseInt(data?.childCount))].map((item, index) => {
        return {
          type: "CNN",
          fName: "",
          lName: "",
          gender: "",
          phone: "",
          email: "",
          dob: format(new Date(dateBeforeTwoYears), "dd MMM yyyy"),
          passNation: "BD",
          passNo:
            flightData.triptype === "Inbound"
              ? Math.round(Math.random() * 100000000 + index)
              : "",
          passEx:
            flightData.triptype === "Inbound"
              ? new Date(dateAfterSixMonths).toLocaleDateString("sv")
              : format(new Date(dateAfterSixMonths), "yyyy-MM-dd"),
          openDate: false,
          openPassExDate: false,
          prefix: "MSTR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
      ...[...new Array(parseInt(data?.infant))].map((item, index) => {
        return {
          type: "INF",
          fName: "",
          lName: "",
          gender: "",
          phone: "",
          email: "",
          dob: format(new Date(dateBeforeSixMonths), "dd MMM yyyy"),
          passNation: "BD",
          passNo:
            flightData.triptype === "Inbound"
              ? Math.round(Math.random() * 100000000 + index)
              : "",
          passEx:
            flightData.triptype === "Inbound"
              ? new Date(dateAfterSixMonths).toLocaleDateString("sv")
              : format(new Date(dateAfterSixMonths), "yyyy-MM-dd"),
          openDate: false,
          openPassExDate: false,
          prefix: "MSTR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
    ],
    adultCount: data?.adultCount,
    childCount: data?.childCount,
    infantCount: data?.infant,
    email: userInfo?.user_email,
    phone: userPhone,
    tripType: "oneway",
    segment: flightData?.segment,
  });

  const {
    careerName,
    segments,
    className,
    departure,
    career,
    departureTime,
    departureDate,
    refundable,
    seat,
    flightduration,
    segment,
    bags,
    arrival,
    arrivalTime,
    arrivalDate,
    pricebreakdown,
    price,
    clientPrice,
    BasePrice,
    Taxes,
    netCost,
  } = flightData;

  console.log(flightData);
  console.log(netCost);

  const { adultCount, childCount, infantCount } = flightPassengerData;
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage2, setSelectedImage2] = useState([]);

  const userDataCopy = {
    flightData: { ...flightData },
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infantCount,
    to: arrival,
    from: departure,
    tripType: "oneway",
    fromAddress: segments[0].departureLocation,
    toAddress: segments[segments.length - 1].arrivalLocation,
    dDate: departureDate,
    clientFare: clientPrice,
    agentFare: price,
    commission: parseFloat(clientPrice) - parseFloat(price),
    segment: segment,
    name: userInfo?.user_name,
    email: userInfo?.user_email,
  };

  const handleOnChange = (e, type, index) => {
    let value = e.target.value;
    let field = e.target.name;

    if (
      e.target.files &&
      e.target.files[0] &&
      (field === "passCopy" || field === "visaCopy")
    ) {
      value = e.target.files[0];
    }

    const tempFlightData = [...flightPassengerData.passengers];

    if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "ADT" &&
      value === "Male"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MR",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "ADT" &&
      value === "Female"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MRS",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "CNN" &&
      value === "Male"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MSTR",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "CNN" &&
      value === "Female"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MISS",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "INF" &&
      value === "Male"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MSTR",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "INF" &&
      value === "Female"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MISS",
      };
    } else if (index !== -1) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
      };
    }

    console.log(tempFlightData[index]);
    setFlightPassengerData({
      ...flightPassengerData,
      passengers: tempFlightData,
    });
  };

  const handleImageChange = (event, i) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage([...selectedImage, file]);
    }
  };

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage2([...selectedImage2, file]);
    }
  };

  const handleOpenDateState = (type, item, i) => {
    const tempFlightData = [...flightPassengerData.passengers];

    if (type === "ADT" || type === "CNN" || type === "INF") {
      const passengerType = type.toLowerCase();
      tempFlightData[i] = {
        ...tempFlightData[i],
        openDate: !item.openDate,
        openPassExDate: false,
      };

      setFlightPassengerData({
        ...flightPassengerData,
        passengers: tempFlightData,
      });
    }
  };

  const handleOpenPassDateState = (type, index, item) => {
    const tempFlightData = [...flightPassengerData.passengers];

    if (type === "ADT" || type === "CNN" || type === "INF") {
      const passengerType = type.toLowerCase(); // Map type to lowercase
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: false,
        openPassExDate: !tempFlightData[index].openPassExDate,
      };

      setFlightPassengerData({
        ...flightPassengerData,
        passengers: tempFlightData,
      });
    }
  };

  const getPrice = (type, property) => {
    const prices = pricebreakdown.find((item) => item.PaxType === type);
    if (prices) return parseFloat(prices[property]);
  };

  const limitTime =
    flightData?.system === "Sabre"
      ? new Date()
      : flightData?.system === "Galileo"
      ? new Date()
      : flightData?.LastTicketDate;

  const bookingBody = {
    flightPassengerData: { passengers: [...flightPassengerData.passengers] },
    bookingInfo: {
      agentId: agentId || "",
      staffId: staffId || "",
      subagentId: "",
      system: flightData.system,
      from: departure,
      to: arrival,
      airlines: careerName,
      tripType: "oneway",
      travelDate: moment(flightData?.segments[0]?.departureTime).format(
        "ddd D MMM YYYY"
      ),
      name: userInfo.agent_name || userInfo.staff_name,
      phone: userPhone,
      email: userInfo.agent_email || userInfo.staff_email,
      pax:
        flightPassengerData?.adultCount +
        flightPassengerData?.childCount +
        flightPassengerData?.infantCount,
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infantCount,
      subagentprice: price,
      netcost: netCost,
      adultcostbase:
        Math.round(
          (getPrice("ADT", "BaseFare") + getPrice("ADT", "ServiceFee")) *
            adultCount
        ) || 0,
      childcostbase:
        Math.round(
          (getPrice("CNN", "BaseFare") + getPrice("CNN", "ServiceFee")) *
            childCount
        ) || 0,
      infantcostbase:
        Math.round(
          (getPrice("INF", "BaseFare") + getPrice("INF", "ServiceFee")) *
            infantCount
        ) || 0,
      adultcosttax: Math.round(getPrice("ADT", "Tax") * adultCount) || 0,
      childcosttax: Math.round(getPrice("CNN", "Tax") * childCount) || 0,
      infantcosttax: Math.round(getPrice("INF", "Tax") * infantCount) || 0,
      grosscost: Math.round(clientPrice) || 0,
      basefare: Math.round(BasePrice) || 0,
      tax: Math.round(Taxes) || 0,
      timelimit: limitTime,
      SearchID: "",
      ResultID: "",
      journeyType: flightData?.triptype,
      coupon: coupon || "",
      adultbag: adultCount ? `go-${bags}` : "",
      childbag: childCount ? `go-${bags}` : "",
      infantbag: infantCount ? `go-${bags}` : "",
      refundable: flightData.refundable === "Refundable" ? "yes" : "no",
      platform: `B2B`,
      segment: segment,
    },
    saveBooking: { ...userDataCopy },
    system: flightData.system,
    agentId: agentId || "",
    staffId: staffId || "",
    platform: `B2B`,
  };

  console.log(bookingBody);

  const [bookError, setBookError] = useState(false);

  const allPassNo = flightPassengerData?.passengers?.map(
    (passenger) => passenger.passNo
  );
  const passengerPassCopy = flightPassengerData?.passengers?.map(
    (passenger) => passenger.passCopy
  );
  const passengerVisaCopy = flightPassengerData?.passengers?.map(
    (passenger) => passenger.visaCopy
  );

  const [passCopyErr, setPassCopyErr] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { passengers } = flightPassengerData;

    for (let i = 0; i < passengers.length; i++) {
      const passenger = passengers[i];
      console.log(passenger);

      const fieldNamesMap = {
        fName: "First Name",
        lName: "Last Name",
        dob: "Date of Birth",
        gender: "Gender",
        passNation: "Passport Nation",
        passNo: "Passport No",
        passEx: "Passport Validity",
        prefix: "Prefix",
        phone: "Phone",
        email: "Email",
        passCopy: "Passport Copy",
      };

      let fieldsToCheck;

      if (flightData.triptype === "Inbound") {
        fieldsToCheck = [
          "fName",
          "lName",
          "gender",
          "phone",
          "email",
          "dob",
          "passNation",
          "passEx",
          "prefix",
        ];
      } else {
        fieldsToCheck = [
          "fName",
          "lName",
          "gender",
          "phone",
          "email",
          "dob",
          "passNation",
          "passNo",
          "passEx",
          "prefix",
          "passCopy",
        ];
      }

      const emptyFields = fieldsToCheck
        .filter((field) => !passenger[field])
        .map((field) => fieldNamesMap[field]);

      if (emptyFields.length > 0) {
        setPassCopyErr(true);
        const errorMessage = `<span style="color: black;font-weight: 500;">Please, Provide required information to book.</span>`;
        const errorTitle = `<span style="color: red;font-weight: 500;">${
          i === 0 ? "1st" : i === 1 ? "2nd" : i === 2 ? "3rd" : `${i + 1}th`
        } Passenger's Information Is Missing</span>`;

        Swal.fire({
          title: errorTitle,
          html: errorMessage,
          icon: "error",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });

        return;
      }
    }

    const formData = new FormData();
    formData.append("bookingBody", JSON.stringify(bookingBody));

    for (let i = 0; i < allPassNo.length; i++) {
      const passNo = allPassNo[i];
      const image = flightData.triptype === "Inbound" ? null : selectedImage[i];
      const image2 =
        flightData.triptype === "Inbound" ? null : selectedImage2[i];

      if (image !== null) {
        formData.append(`${passNo}passportCopy`, image);
      }

      if (image2 !== null) {
        formData.append(`${passNo}visaCopy`, image2);
      }
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const url = "https://api.rtc.flyfarint.com/v1.0/b2b/air/book/hold.phppp";

    setClicked(true);

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();

      setClicked(false);

      if (data.status === "success") {
        // Handle success
        Swal.fire({
          imageUrl: bookingSuccess,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Success",
          html: `<div>Thank you so much for booking a flight ticket with Fly Far International.</div><strong>Please issue your booking ticket within the time limit specified,</strong> <div>otherwise your booking request will be automatically cancelled.</div>`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/dashboard/airticketbooking");
        });
      } else {
        // Handle failure
        Swal.fire({
          imageUrl: noFareFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: data?.message,
          html: "<div>Booking Failed. If you have any queries please contact us at <strong>support@flyfarint.com or +09606-912912</strong></div>",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Another Flights.",
        }).then(() => {
          navigate(-1);
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setClicked(false);
    }
  };

  return (
    <Box
      sx={{
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "3px",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        ".MuiTypography-h6": {
          fontSize: "16px",
          color: "#444542",
          fontWeight: "500",
        },
        ".MuiTypography-caption": {
          fontSize: "11px",
          color: "#ACA6A6",
        },
        ".MuiTypography-subtitle1": {
          fontSize: "11px",
          color: "#2156A6",
        },
      }}
    >
      <UserHeader />

      <form onSubmit={handleSubmit}>
        <Grid
          justifyContent={"space-between"}
          container
          gap={1}
          sx={{
            p: "15px",
            width: "90%",
            margin: "0 auto",
            mt: "-80px",
            bgcolor: "white",
            borderRadius: "5px",
          }}
        >
          <Grid item lg={8.75} gap={3} container>
            <Grid item xs={12}>
              <Box>
                <Typography
                  sx={{
                    color: "#2156A6",
                    p: "10px",
                    fontWeight: 500,
                    fontSize: "15px",
                    bgcolor: "#a3c7ff3b",
                  }}
                >
                  Flight Information
                </Typography>
              </Box>
            </Grid>

            {isMobile ? (
              <>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      bgcolor: "#a3c7ff3b",
                      borderRadius: "4px",
                      px: "13px",
                      py: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        gap: "15px",
                        alignItems: "center",
                      }}
                    >
                      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                        {flightData.departure} {flightData.departureTime}
                      </Typography>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        viewBox="0 0 116 57"
                        fill="none"
                      >
                        <path
                          d="M115.51 14.4039C114.711 12.0948 112.993 10.1857 110.732 9.09641C108.471 8.00716 105.854 7.82702 103.455 8.5956L76.971 17.1L29.6042 0L17.7625 5.7L53.2876 28.5L29.6042 39.9L5.92085 28.5L0 34.2L23.6834 57L109.814 25.9065C112.125 25.0713 114.009 23.4062 115.072 21.2611C116.134 19.1161 116.291 16.6581 115.51 14.4039Z"
                          fill="#ED1C24"
                        />
                      </svg>
                      <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                        {flightData.arrival} {flightData.arrivalTime}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{ color: "var(--secondary-color)", fontSize: "12px" }}
                    >
                      {flightData?.refundable}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontSize: "12px",
                        bgcolor: "#D6D7DD",
                        py: "1px",
                        borderRadius: "2px",
                        pl: "2px",
                        mt: "5px",
                        fontWeight: "500",
                      }}
                    >
                      Flight Details
                    </Typography>
                    {/* Array mapping starts here */}
                    {flightData?.segments?.map((data, index) => (
                      <>
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderLeft: "1.5px dotted var(--secondary-color)",
                            mt: "20px",
                            pl: "15px",
                            pb: "10px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "var(--primary-color)",
                              borderRadius: "50px",
                              position: "absolute",
                              left: "-5px",
                              top: "-5px",
                            }}
                          ></div>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                pb: "0px",
                              }}
                            >
                              {data.departure}{" "}
                              {new Date(data.departureTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {data.departureAirport}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {moment(data?.departureTime).format(
                                "D MMMM YYYY"
                              )}
                              {/* {flightData?.departureDate} */}
                            </span>
                          </Box>
                          <Box
                            sx={{ mt: "20px", display: "flex", gap: "10px" }}
                          >
                            <img
                              src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.marketingcareer}.png`}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                              }}
                              alt={`${data?.marketingcareer}`}
                            />
                            <Box>
                              <span style={{ fontSize: "13px", pt: "0px" }}>
                                {data?.marketingcareerName}
                              </span>{" "}
                              <br />
                              <span
                                style={{
                                  fontSize: "12px",
                                  pt: "0px",
                                  color: "var(--primary-color)",
                                  fontWeight: "600",
                                }}
                              >
                                {segments.map(
                                  (s, i) =>
                                    `${
                                      i === 0
                                        ? `${s.marketingcareer} ${s.marketingflight}`
                                        : ` & ${s.marketingcareer} ${s.marketingflight}`
                                    }`
                                )}
                              </span>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mt: "15px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                pb: "0px",
                              }}
                            >
                              {data.arrival}{" "}
                              {new Date(data.arrivalTime).toLocaleTimeString(
                                [],
                                {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {data.arrivalAirport}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {moment(data?.arrivalTime).format("D MMMM YYYY")}
                              {/* {flightData?.arrivalTime} */}
                            </span>
                          </Box>
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "var(--primary-color)",
                              borderRadius: "50px",
                              position: "absolute",
                              left: "-5px",
                              bottom: "0px",
                            }}
                          ></div>
                        </Box>
                        {/* 
                {flightData?.segments.length > 1 &&
                index <= flightData?.segment?.length - 2 ? (
                  <Box
                    sx={{
                      bgcolor: "#D9F1FF",
                      fontSize: "13px",
                      py: "4px",
                      my: "4px",
                      mt: "6px",
                      px: "17px",
                      fontWeight: "500",
                      color: "var(--primary-color )",
                    }}
                  >
                    {" "}
                    {data.arrival}, Connecting time{" "}
                    {index === 0
                      ? flightData?.transit?.transit1
                      : index === 1
                      ? flightData?.transit?.transit2
                      : index === 2
                      ? flightData?.transit?.transit3
                      : ""}
                  </Box>
                ) : (
                  ""
                )} */}
                      </>
                    ))}
                    {/* Array mapping ends here */}
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid item container>
                  <Box
                    sx={{
                      ".MuiTypography-body2": {
                        fontSize: "13px",
                      },
                      ".MuiTypography-title-lg": {
                        fontSize: "24px",
                        color: "#444542",
                        fontWeight: "500",
                      },
                      width: "100%",
                    }}
                  >
                    <Grid
                      justifyContent={"space-between"}
                      container
                      sx={{ p: "15px 10px" }}
                    >
                      <Grid item order={{ xs: 1, lg: 1 }} xs={6} lg={3}>
                        <Box>
                          <img
                            src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${career}.png`}
                            width="60px"
                            height="60px"
                            alt=""
                          />
                          <Typography variant="body2" sx={{ color: "var(--secondary-color)" }}>
                            {careerName}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#222" }}>
                            {segments.map(
                              (s, i) =>
                                `${
                                  i === 0
                                    ? `${s.marketingcareer} ${s.marketingflight}`
                                    : ` & ${s.marketingcareer} ${s.marketingflight}`
                                }`
                            )}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "#222", mt: "15px" }}
                          >
                            {className}
                          </Typography>
                        </Box>
                      </Grid>

                      <Grid item order={{ xs: 3, lg: 2 }} xs={3} lg={3}>
                        <Box>
                          <Typography variant="body2" sx={{ color: "#8C8080" }}>
                            Depart
                          </Typography>
                          <Typography variant="title-lg">
                            {departure}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: "var(--secondary-color)" }}
                          >
                            {segments[0].departureAirport}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#222" }}>
                            {moment(departureTime, "HH:mm").format("hh:mm A")}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "#8C8080" }}
                          >
                            {departureDate}
                          </Typography>
                          <Box
                            mt={1}
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{ fontSize: "12px", color: "#0B7102" }}
                            >
                              {refundable}
                            </Typography>
                            <Typography
                              sx={{ fontSize: "12px", color: "#222", mr: 1 }}
                            >
                              {seat} seat{seat > 1 ? "s" : ""}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid item order={{ xs: 4, lg: 3 }} xs={3} lg={3}>
                        <Stack
                          direction="column"
                          justifyContent="space-between"
                          alignItems="center"
                          height={"100%"}
                        >
                          <Stack
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#ACA6A6",
                                textAlign: "ceter",
                                mr: "10px",
                              }}
                            >
                              {flightduration}
                            </Typography>
                            <svg
                              width="122"
                              height="12"
                              viewBox="0 0 689 56"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                                stroke="#ED1A5C"
                                stroke-width="9"
                              />
                              <path
                                d="M689 46.6569L541.002 0V46.6569H689Z"
                                fill="#ED1A5C"
                              />
                            </svg>
                            <Typography
                              variant="body2"
                              sx={{
                                color: "#444542",
                                textAlign: "center",
                                m: "10px 10px 0 0",
                              }}
                            >
                              {segment >= 2
                                ? `${segment - 1} stop${
                                    segment - 1 > 1 ? "s" : ""
                                  }`
                                : "No stop"}
                            </Typography>
                          </Stack>
                          <Typography
                            variant="body2"
                            sx={{ color: "var(--secondary-color)", textAlign: "center" }}
                          >
                            Baggage{" "}
                            {bags > 3
                              ? `${bags} KG`
                              : `${bags} Bag${bags > 1 ? "s" : ""}`}
                          </Typography>{" "}
                        </Stack>
                      </Grid>

                      <Grid item order={{ xs: 5, lg: 4 }} xs={3} lg={3}>
                        <Box
                          sx={{
                            bgcolor: "blu",
                          }}
                        >
                          <Typography variant="body2" sx={{ color: "#8C8080" }}>
                            Arrival
                          </Typography>
                          <Typography variant="title-lg">{arrival}</Typography>
                          <Typography variant="body2" sx={{ color: "var(--secondary-color)" }}>
                            {segments[segments.length - 1].arrivalAirport}
                          </Typography>
                          <Typography variant="body2" sx={{ color: "#222" }}>
                            {moment(arrivalTime, "HH:mm").format("hh:mm A")}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "12px", color: "#8C8080" }}
                          >
                            {arrivalDate}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                    <OnewayFlightDetails
                      pricebreakdown={pricebreakdown}
                      segments={segments}
                      flightData={flightData}
                    />
                  </Box>
                </Grid>
              </>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            lg={3}
            bgcolor={"gren"}
            sx={{ height: "max-content" }}
          >
            <OnewayPrice roundData={flightData} />
          </Grid>
          {/* Passenger Information */}
          <Grid item xs={12} lg={8.75} gap={3}>
            <OnewayBooking
              flightData={data}
              passCopyErr={passCopyErr}
              flightPassengerData={flightPassengerData}
              handleOnChange={handleOnChange}
              setSelectedImage={setSelectedImage}
              handleImageChange2={handleImageChange2}
              handleImageChange={handleImageChange}
              selectedImage={selectedImage}
              selectedImage2={selectedImage2}
              setSelectedImage2={selectedImage2}
              dateBeforeTwelveYears={dateBeforeTwelveYears}
              dateBeforeTwoYears={dateBeforeTwoYears}
              handleOpenDateState={handleOpenDateState}
              setFlightPassengerData={setFlightPassengerData}
              handleOpenPassDateState={handleOpenPassDateState}
              dateAfterSixMonths={dateAfterSixMonths}
              passengerPassCopy={passengerPassCopy}
              passengerVisaCopy={passengerVisaCopy}
            />
          </Grid>
          <Grid item xs={12} lg={8.75} gap={3}>
            <Button
              type="submit"
              disabled={clicked ? true : false}
              sx={{
                bgcolor: "var(--secondary-color)",
                width: "100%",
                fontSize: "17px",
                fontWeight: "500",
                color: "white",
                mt: "30px",
                ":hover": {
                  bgcolor: "var(--secondary-color)",
                },
              }}
            >
              {clicked ? "Please Wait...." : " Book & Hold"}
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* </Container> */}
    </Box>
  );
};

export default OnewayFlightInfo;
