import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import commaNumber from "comma-number";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { ToWords } from "to-words";

const QuotationPDF = ({ quotationData, markupPrice, tripType }) => {
  const toWords = new ToWords();
  const table = {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };
  const tableRow = {
    margin: "auto",
    flexDirection: "row",
  };

  const tableCol = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  //Passenger Details
  const tableColName = {
    width: "35%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColGender = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColType = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColNumber = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableColfromto = {
    width: "22%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColtime = {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableColflight = {
    width: "17%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableCol2 = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  };

  const tableCell = {
    // margin: "auto",
    margin: "3px",
    fontSize: "8px",
  };
  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "8px",
  };

  const adress = {
    fontSize: "10px",
    color: "#8b8b8b",
  };

  const users = secureLocalStorage.getItem("user-info");

  let agentID = users?.user?.agentId;
  const subagentId = users?.user?.subagentId;
  const companyLogo = users?.user?.logo;

  console.log(quotationData);
//   console.log('hi',quotationData?.clientPrice);

  return (
    <Document>
      <Page size="A4" style={{ padding: "27px 20px" }}>
        <View style={{ padding: "15px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  fontSize: "10px",
                  display: "flex",
                  width: "200px",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                }}
              >
                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight: 400,
                    paddingBottom: "5px",
                  }}
                >
                  {users?.user?.company}
                </Text>
                <Text style={adress}>{users?.user?.address}</Text>
                <Text style={adress}>Email: {users?.user?.email}</Text>
                <Text style={adress}>Phone: {users?.user?.phone}</Text>
              </View>
            </View>
            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {companyLogo === "" || companyLogo === "undefined" ? null : (
                <Image
                  style={{ height: "50px" }}
                  src={`https://cdn.flyfarint.com/WL/${agentID}/${subagentId}/${companyLogo}`}
                />
              )}
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "11px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <Text>
              {quotationData?.refundable === "Refundable" ? (
                <Text style={{ color: "green" }}>Refundable </Text>
              ) : (
                <Text style={{ color: "red" }}>Non Refundable </Text>
              )}
            </Text>
          </View>
          <View>
            <Text
              style={{
                fontSize: "12px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              FLIGHT ITINERARIES
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableColflight}>
                  <Text style={tableCell}>Flight </Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Departure From</Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Arrival To</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Depart At</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Arrive At</Text>
                </View>
                <View style={tableColflight}>
                  <Text style={tableCell}>Info</Text>
                </View>
              </View>
              {tripType === "return" ? (
                <>
                  {quotationData?.segments?.go?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.marketingcareerName}
                        </Text>
                        <Text style={tableCell}>
                          {data?.marketingcareer}
                          {" | "}
                          {data?.marketingflight}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.departure}){data?.departureAirport}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.arrival}) {data?.arrivalAirport}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.departureTime
                            ? format(
                                new Date(data?.departureTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.arrivalTime
                            ? format(
                                new Date(data?.arrivalTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.bookingcode}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.flightduration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {`${quotationData?.bags} ${
                            quotationData?.bags?.length === 2 ? "KG" : "piece"
                          }`}
                        </Text>
                      </View>
                    </View>
                  ))}
                  {quotationData?.segments?.back?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.marketingcareerName}
                        </Text>
                        <Text style={tableCell}>
                          {data?.marketingcareer}
                          {" | "}
                          {data?.marketingflight}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.departure}){data?.departureAirport}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.arrival}) {data?.arrivalAirport}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.departureTime
                            ? format(
                                new Date(data?.departureTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.arrivalTime
                            ? format(
                                new Date(data?.arrivalTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.bookingcode}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.flightduration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {`${quotationData?.bags} ${
                            quotationData?.bags?.length === 2 ? "KG" : "piece"
                          }`}
                        </Text>
                      </View>
                    </View>
                  ))}
                </>
              ) : tripType === "oneway" ? (
                <>
                  {quotationData?.segments.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.marketingcareerName}
                        </Text>
                        <Text style={tableCell}>
                          {data?.marketingcareer}
                          {" | "}
                          {data?.marketingflight}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.departure}){data?.departureAirport}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.arrival}) {data?.arrivalAirport}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.departureTime
                            ? format(
                                new Date(data?.departureTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.arrivalTime
                            ? format(
                                new Date(data?.arrivalTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.bookingcode}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.flightDuration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {`${quotationData?.bags} ${
                            quotationData?.bags?.length === 2 ? "KG" : "piece"
                          }`}
                        </Text>
                      </View>
                    </View>
                  ))}
                </>
              ) : null}
            </View>
          </View>

          <View>
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                width: "70%",
                fontSize: "10px",
                alignItems: "center",
                justifyContent: "space-between",
                margin: "20px 0px",
                border: "1px solid gray",
                padding: "10px",
              }}
            >
              <View>
                <View>
                  <Text style={{ marginBottom: "5px" }}>
                    Base fare total amount
                  </Text>
                  <Text style={{ marginBottom: "5px" }}>Tax</Text>
                  <Text>
                    ---------------------------------------------------------------------------------------
                  </Text>
                  <Text>Customer Total Ticket Fare Amount</Text>
                </View>
              </View>
              <View>
                <Text style={{ marginBottom: "5px" }}>
                  {commaNumber(
                    Number.parseInt(quotationData?.BasePrice) +
                      Math.ceil(markupPrice / 2)
                  ) || ""}{" "}
                  BDT
                </Text>
                <Text style={{ marginBottom: "5px" }}>
                  {commaNumber(
                    Number.parseInt(quotationData?.Taxes) +
                      Math.ceil(markupPrice / 2)
                  )}{" "}
                  BDT
                </Text>
                <Text>------------------</Text>
                <Text>
                  {commaNumber(
                    Number.parseInt(quotationData?.clientPrice) +
                      Number.parseInt(markupPrice)
                  )}{" "}
                  BDT
                </Text>
              </View>
            </View>
            <View>
              <Text
                style={{
                  fontSize: "11px",
                  color: "#03437B",
                  padding: "10px 0px",
                }}
              >
                In Words:{" "}
                {toWords.convert(
                  Number.parseInt(quotationData?.clientPrice || 0) +
                    Number.parseInt(markupPrice || 0)
                ) || ""}
                Taka Only
              </Text>
              
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default QuotationPDF;
