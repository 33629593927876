import {
  AppBar,
  Badge,
  Box,
  Button,
  Grid,
  Typography,
  Dialog,
  Divider,
  Chip,
} from "@mui/material";
import React, { useState } from "react";
import Slide from "@mui/material/Slide";

import { useNavigate } from "react-router-dom";

import secureLocalStorage from "react-secure-storage";

import axios from "axios";
import Swal from "sweetalert2";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Testing = () => {
  const navigate = useNavigate();

  const admin = secureLocalStorage.getItem("admin-info");
  // console.log(admin);

  const [open, setOpen] = React.useState(false);
  const [signOpen, setSignOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
    setSignOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignInOpen = () => {
    setSignOpen(true);
    setOpen(false);
  };

  const handleSignClose = () => {
    setSignOpen(false);
  };

  const [signUpFormData, setSignUpFormData] = useState({
    name: "",
    compName: "",
    compAddress: "",
    phone: "",
    country: "",
    email: "",
    password: "",
  });

  const [tinImageFile, setTinImageFile] = useState();

  const handleSignUpFormChange = (e) => {
    const { name, value, files } = e.target;
    setSignUpFormData({
      ...signUpFormData,
      [name]: `${name === "tinImg" ? files[0] : value}`,
    });
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("name", signUpFormData.name);
    formData.append("compName", signUpFormData.compName);
    formData.append("compAddress", signUpFormData.compAddress);
    formData.append("phone", signUpFormData.phone);
    formData.append("country", signUpFormData.country);
    formData.append("email", signUpFormData.email);
    formData.append("password", signUpFormData.password);
    formData.append("tinImg", tinImageFile);

    try {
      await axios
        .post(
          "https://api.rtc.flyfarint.com/v1.0/b2b/auth/registration.php",
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
      
          if (res.data.status === "success") {
            secureLocalStorage.setItem(
              "agent-info",
              JSON.stringify(res.data.agent)
            );

            setSignOpen(false);
            setOpen(false);
            Swal.fire({
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Successfull Register",
              text: res.data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(async function (res) {
              navigate("/");
              e.target.reset();
            });
          } else {
            setSignOpen(false);
            setOpen(false);
            Swal.fire({
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Error",
              text: res.data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(async function (res) {
              navigate("/");
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const url = "https://api.rtc.flyfarint.com/v1.0/b2b/auth/index.php";
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      if (email && password) {
        await axios
          .post(url, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((res) => {
        
            if (res.data.status === "success" && res.data?.agent?.agent_id) {
              secureLocalStorage.setItem("user-info", res.data.agent);
              secureLocalStorage.setItem("show-ad", true);
              setSignOpen(false);
              navigate("/dashboard");
            } else if (
              res.data.status === "success" &&
              res.data?.staff?.staff_id
            ) {
              secureLocalStorage.setItem("user-info", res.data.staff);
              secureLocalStorage.setItem("show-ad", true);
              setSignOpen(false);
              navigate("/dashboard");
            } else {
              setSignOpen(false);
              Swal.fire({
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${res.data.status.toUpperCase()}`,
                text: `${res.data.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(async function (res) {
                setSignOpen(true);
              });
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box>
      <Dialog
        open={signOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleSignClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="xl"
        sx={{
          height: { xs: "100vh", sm: "100vh", md: "100vh" },
        }}
      >
        <Box
          sx={{
            mt: "20px",
            width: "660px",
            height: "442px",
            p: "7px 30px",
          }}
        >
          <form onSubmit={handleLogin}>
            <Typography
              sx={{
                fontSize: "26px",
                color: "var(--mate-black)",
                fontWeight: "500",
              }}
            >
              Sign In
            </Typography>
            <Grid container spacing={4} sx={{ mt: "10px" }}>
              <Grid item xs={12} sm={6} md={12}>
                <label className="label-style">Email</label> <br></br>
                <input
                  className="sign-input"
                  type="email"
                  name="email"
                  placeholder="Enter Your Email"
                />
              </Grid>
              <Grid item xs={12} sm={6} md={12}>
                <label className="label-style">Password</label> <br></br>
                <input
                  className="sign-input"
                  type="password"
                  name="password"
                  placeholder="Enter Your Password"
                />
                <Typography
                  sx={{
                    textAlign: "right",
                    cursor: "pointer",
                    fontSize: "12px",
                    mt: "5px",
                  }}
                >
                  Forget Password ?
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6} md={12}>
                <Button
                  type="submit"
                  sx={{
                    textTransform: "none",
                    textAlign: "center",
                    width: "100%",
                    bgcolor: "var(--primary-color)",
                    color: "var(--white)",
                    ":hover": {
                      bgcolor: "var(--primary-color)",
                    },
                  }}
                >
                  Sign In
                </Button>
              </Grid>
            </Grid>
            <Box
              sx={{
                ".MuiDivider-root::before": {
                  borderTop: "2px solid #B6B6CC",
                },
                ".MuiDivider-root::after": {
                  borderTop: "2px solid #B6B6CC",
                },
              }}
            >
              <Divider
                sx={{
                  mt: "20px",
                  color: "#B6B6CC",
                  // borderBottom: "2px solid", // Change the thickness here (2px in this example)
                }}
              >
                <Chip
                  sx={{
                    bgcolor: "var(--white)",
                    color: "var(--primary-color)",
                    fontSize: "16px",
                  }}
                  label="OR"
                />
              </Divider>
            </Box>
            <Typography
              sx={{ mt: "2px", fontSize: "14px", textAlign: "center" }}
            >
              Don't have an Account?
              <Button
                style={{
                  color: "var(--primary-color)",
                  cursor: "pointer",
                  fontWeight: "500",
                  textTransform: "none",
                }}
                onClick={handleClickOpen}
              >
                Sign Up
              </Button>
            </Typography>
          </form>
        </Box>
      </Dialog>
    </Box>
  );
};




export default Testing;
