import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import { useLocation } from "react-router-dom";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Swal from "sweetalert2";

const GroupFareDetails = () => {
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");

  const [paxCount, setPaxCount] = useState(1);
  const [bookingData, setBookingData] = useState({
    name: "",
    phone: "",
    email: "",
  });
  const { data } = location.state;

  const {
    id,
    group_fare_id,
    segment,
    dept_code1,
    dept_name1,
    dept_address1,
    dept_code2,
    dept_name2,
    dept_address2,
    dept_time1,
    dept_time2,
    arrive_code1,
    arrive_name1,
    arrive_address1,
    arrive_code2,
    arrive_name2,
    arrive_address2,
    arrive_time1,
    arrive_time2,
    carrier_name1,
    carrier_code1,
    carrier_name_bangla1,
    carrier_name2,
    carrier_code2,
    carrier_name_bangla2,
    flight_num1,
    flight_num2,
    flight_code1,
    flight_code2,
    flight_name1,
    flight_name2,
    cabin1,
    cabin2,
    class1,
    class2,
    baggage1,
    baggage2,
    travel_time1,
    travel_time2,
    transit_time,
    total_seat,
    available_seat,
    gross_fare,
    created_at,
    deactivated,
  } = data;

  const groupfareData = {
    gross_fare,
    deactivated,
    group_fare_id,
    id,
    segment,
    total_seat,
    transit_time,
    segments: [
      {
        arrive_address: arrive_address1,
        arrive_code: arrive_code1,
        arrive_name: arrive_name1,
        arrive_time: arrive_time1,
        baggage: baggage1,
        cabin: cabin1,
        carrier_code: carrier_code1,
        carrier_name: carrier_name1,
        carrier_name_bangla: carrier_name_bangla1,
        class: class1,
        dept_address: dept_address1,
        dept_code: dept_code1,
        dept_name: dept_name1,
        dept_time: dept_time1,
        flight_code: flight_code1,
        flight_name: flight_name1,
        flight_num: flight_num1,
        travel_time: travel_time1,
      },
      {
        arrive_address: arrive_address2,
        arrive_code: arrive_code2,
        arrive_name: arrive_name2,
        arrive_time: arrive_time2,
        baggage: baggage2,
        cabin: cabin2,
        carrier_code: carrier_code2,
        carrier_name: carrier_name2,
        carrier_name_bangla: carrier_name_bangla2,
        class: class2,
        dept_address: dept_address2,
        dept_code: dept_code2,
        dept_name: dept_name2,
        dept_time: dept_time2,
        flight_code: flight_code2,
        flight_name: flight_name2,
        flight_num: flight_num2,
        travel_time: travel_time2,
      },
    ],
  };

  

  const newSegment =
    groupfareData?.segment === "1"
      ? groupfareData.segments.slice(0, 1)
      : groupfareData.segments;

  // console.log(newSegment);

  const travelTimeToMinute = (time) => {
    const hourMatch = time.match(/\d+H/);
    const minuteMatch = time.match(/\d+M/);
    const hours = hourMatch ? parseInt(hourMatch[0]) : 0;
    const minutes = minuteMatch ? parseInt(minuteMatch[0]) : 0;
    const totalMinutes = parseFloat(hours * 60 + minutes);

    return totalMinutes;
  };

  const totalTravelTime = (time1, transite, time2) => {
    const segment1Min = travelTimeToMinute(time1);
    const transiteMin = travelTimeToMinute(transite);
    const segment2Min = travelTimeToMinute(time2);

    const totalTravelTime = `${parseInt(
      (segment1Min + transiteMin + segment2Min) / 60
    )}H ${(segment1Min + transiteMin + segment2Min) % 60}M`;

    return totalTravelTime;
  };

  const handleGroupFareBooking = async (e) => {
    e.preventDefault();
    console.log(bookingData);

    const body = {
      ...bookingData,
      groupFareId: data?.group_fare_id,
      agentId: user?.agent_id,
      pax: paxCount,
      grossCost: data?.gross_fare * paxCount,
    };
    console.log(body);

    try {
      const response = await axios.post(
        "https://api.rtc.flyfarint.com/v1.0/b2b/groupfare/book.php",
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response?.data?.status === "success") {
        Swal.fire({
          icon: "success",
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: `${response?.data?.message}`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          // navigate(0);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box>
      <UserHeader />

      <Box
        sx={{
          borderRadius: "3px",
          bgcolor: "white",
          mb: 20,
          width: "90%",
          margin: "0 auto",
          mt: "-8vh",

          ".MuiTypography-body2": {
            fontSize: "13px",
          },
          ".MuiTypography-title-lg": {
            fontSize: "24px",
            color: "#444542",
            fontWeight: "500",
          },
          ".MuiTypography-h6": {
            fontSize: "16px",
            color: "#444542",
            fontWeight: "500",
          },
          ".MuiTypography-caption": {
            fontSize: "11px",
            color: "#ACA6A6",
          },
          ".MuiTypography-subtitle1": {
            fontSize: "11px",
            color: "#2156A6",
          },
        }}
      >
        <Grid
          justifyContent={"space-between"}
          container
          // gap={1}
          sx={{ p: "15px", bgcolor: "re" }}
        >
          <Grid item lg={9}>
            <Typography
              sx={{
                color: "#2156A6",
                p: "10px",
                pl: "25px",
                fontWeight: 500,
                fontSize: "15px",
                bgcolor: "#a3c7ff3b",
                borderRadius: "7px",
                mb: "13px",
              }}
            >
              Flight Information
            </Typography>

            {newSegment.map((data, i) => (
              <Grid item container key={i}>
                <Box
                  bgcolor={"white"}
                  sx={{
                    ".MuiTypography-body2": {
                      fontSize: "13px",
                    },
                    ".MuiTypography-title-lg": {
                      fontSize: "24px",
                      color: "#444542",
                      fontWeight: "500",
                    },
                    width: "100%",
                    py: "25px",
                    borderRadius: "7px",
                  }}
                >
                  <Grid
                    justifyContent={"space-between"}
                    container
                    // sx={{ p: "15px 10px" }}
                  >
                    <Grid item order={{ xs: 1, lg: 1 }} xs={6} lg={3}>
                      <Box sx={{ bgcolor: "blu" }}>
                        <img
                          src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data?.carrier_code}.png`}
                          width="60px"
                          height="60px"
                          alt=""
                        />
                        <Typography variant="body2" sx={{ color: "#2156A6" }}>
                          {data?.carrier_name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#222" }}>
                          {data?.carrier_code} - {data?.flight_num}
                        </Typography>

                        <Typography
                          sx={{ fontSize: "12px", color: "#222", mt: "15px" }}
                        >
                          {data?.class || "Economy"}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item order={{ xs: 3, lg: 2 }} xs={3} lg={3}>
                      <Box>
                        <Typography variant="body2" sx={{ color: "#8C8080" }}>
                          Depart
                        </Typography>
                        <Typography variant="title-lg">
                          {data?.dept_code}
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "var(--primary-color)" }}
                        >
                          {data?.dept_name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#222" }}>
                          {moment(data?.dept_time.slice(0, 19)).format(
                            "hh:mm A"
                          )}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                          {moment(data?.dept_time).format("ddd D MMM YYYY")}
                        </Typography>
                      </Box>
                    </Grid>

                    <Grid item order={{ xs: 4, lg: 3 }} xs={3} lg={3}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        alignItems="center"
                        height={"100%"}
                      >
                        <Stack
                          direction="column"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#ACA6A6",
                              textAlign: "ceter",
                              mr: "10px",
                            }}
                          >
                            {data?.travel_time}
                          </Typography>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="122"
                            height="12"
                            viewBox="0 0 122 12"
                            fill="none"
                          >
                            <path
                              d="M120.682 11C120.4 11 104.145 11 96.0529 11H0"
                              stroke="#2156A6"
                              strokeWidth="2"
                            />
                            <path
                              d="M96.9551 2L120.682 11L96.9551 10.8623V2Z"
                              fill="#2156A6"
                              stroke="#2156A6"
                              strokeWidth="2"
                            />
                          </svg>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#444542",
                              textAlign: "center",
                              mr: "10px",
                            }}
                          >
                            {data?.segment === "1" ? "No Stop" : "1 Stop"}
                          </Typography>
                        </Stack>
                        <Typography
                          variant="body2"
                          sx={{ color: "#2156A6", textAlign: "center" }}
                        >
                          Baggage{" "}
                          {data?.baggage > 3
                            ? `${data?.baggage} KG`
                            : `${data?.baggage} Bag${
                                data?.baggage > 1 ? "s" : ""
                              }`}
                        </Typography>{" "}
                      </Stack>
                    </Grid>

                    <Grid item order={{ xs: 5, lg: 4 }} xs={3} lg={3}>
                      <Box
                        sx={{
                          bgcolor: "blu",
                        }}
                      >
                        <Typography variant="body2" sx={{ color: "#8C8080" }}>
                          Arrival
                        </Typography>
                        <Typography variant="title-lg">
                          {data?.arrive_code}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#2156A6" }}>
                          {data?.arrive_name}
                        </Typography>
                        <Typography variant="body2" sx={{ color: "#222" }}>
                          {moment(data?.arrive_time?.slice(0, 19)).format(
                            "hh:mm A"
                          )}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                          {moment(data?.arrive_time).format("ddd D MMM YYYY")}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>

                <Typography
                  sx={{
                    display: `${
                      i === newSegment.length - 1 ? "none" : "block"
                    }`,
                    borderRadius: "4px",
                    background: "#DEE8FF",
                    width: "65%",
                    color: "var(--primary-color)",
                    padding: "3px 10px",
                    fontSize: "10px",
                    fontWeight: 500,
                    margin: "20px auto",
                    textAlign: "center",
                  }}
                >
                  Change planes at {data?.arrive_name}. Transite Time:{" "}
                  {transit_time}
                </Typography>
              </Grid>
            ))}

            <form onSubmit={handleGroupFareBooking}>
              <Grid container sx={{ mt: "20px" }} spacing={3}>
                <Grid item md={6}>
                  <label style={{ fontSize: "13px" }} htmlFor="">
                    Customer Name
                  </label>
                  <input
                    name="name"
                    onChange={(e) => {
                      setBookingData({
                        ...bookingData,
                        name: e.target.value,
                      });
                    }}
                    style={{ width: "100%", fontSize: "14px" }}
                    type="text"
                  />
                </Grid>
                <Grid item md={6}>
                  <label style={{ fontSize: "13px" }} htmlFor="">
                    Customer Email
                  </label>
                  <input
                    name="email"
                    onChange={(e) => {
                      setBookingData({
                        ...bookingData,
                        email: e.target.value,
                      });
                    }}
                    style={{ width: "100%", fontSize: "14px" }}
                    type="email"
                  />
                </Grid>
                <Grid item md={6}>
                  <label style={{ fontSize: "13px" }} htmlFor="">
                    Customer Phone Number
                  </label>
                  <input
                    name="phone"
                    onChange={(e) => {
                      setBookingData({
                        ...bookingData,
                        phone: e.target.value,
                      });
                    }}
                    style={{ width: "100%", fontSize: "14px" }}
                    type="text"
                  />
                </Grid>
                <Grid item md={12}>
                  <Button
                    type="submit"
                    sx={{
                      bgcolor: "var(--primary-color)",
                      color: "white",
                      ":hover": {
                        bgcolor: "var(--primary-color)",
                      },
                      width: "100%",
                    }}
                  >
                    Pay {paxCount * data?.gross_fare} bdt to confirm this group
                    fare
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Grid>

          <Grid
            item
            xs={12}
            lg={2.75}
            bgcolor={"gren"}
            sx={{ height: "max-content" }}
          >
            <Box
              sx={{
                bgcolor: "white",
                mb: "13px",
                pl: "18px",
                p: "20px",
                pt: "0",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#2156A6",
                    fontWeight: "500",
                  }}
                >
                  {data?.status} <br />
                  {data?.group_fare_id}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#444542",
                    fontWeight: "500",
                    mt: "14px",
                  }}
                >
                  Booking Information
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#444542",
                  fontWeight: "500",
                  mt: "14px",
                }}
              >
                Per Paasenger
              </Typography>
              <Typography
                variant="title-lg"
                sx={{
                  color: "#444542",
                  fontWeight: "500",
                  mt: "14px",
                }}
              >
                {data?.gross_fare} BDT
              </Typography>

              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#444542",
                  fontWeight: "500",
                  mt: "14px",
                }}
              >
                Counter
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt={3}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#444542",
                    fontWeight: "500",
                  }}
                >
                  {paxCount}
                </Typography>
                <Typography sx={{ fontSize: "11px", color: "#2156A6" }}>
                  <button
                    onClick={() => {
                      setPaxCount(paxCount + 1);
                    }}
                  >
                    +
                  </button>
                  <button
                    onClick={() => {
                      if (paxCount > 1) {
                        setPaxCount(paxCount - 1);
                      }
                    }}
                  >
                    -
                  </button>
                </Typography>
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GroupFareDetails;
