import React, { useEffect, useState } from "react";
import PDFPageDesign from "./PDFPageDesign";
import { PDFDownloadLink } from "@react-pdf/renderer";

const AllPDFButtons = ({ allData, agentId, markupPrice, multicity }) => {
  console.log(multicity);
  const [userData, setUserData] = useState([]);
  const flattenedArray = Object.values(allData.segments).flat();

  const [multiCityAllData, setMultiCityAllData] = useState(flattenedArray);

  let url = `https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/index.php?agentId=${agentId}`;
  useEffect(() => {
    const fetchAccountData = async () => {
      try {
        const res = await fetch(url);
        const data = await res.json();
        setUserData(data?.agents);
      } catch (error) {
        console.log(error);
      }
    };

    fetchAccountData();
  }, [url]);

  const adultTotalPrice =
    parseInt(allData?.booking.adt_base) + parseInt(allData?.booking.adt_tax);

  const childTotalPrice =
    parseInt(allData?.booking.child_base) +
    parseInt(allData?.booking.child_tax);

  const infantTotalPrice =
    parseInt(allData?.booking.inf_base) + parseInt(allData?.booking.inf_tax);

  const totalPrice = adultTotalPrice + childTotalPrice + infantTotalPrice;

  const discount =
    parseInt(allData?.booking.gross_cost) - parseInt(allData?.booking.net_cost);

  let bookingwp = `Booking with price ${allData?.dept_from}-${allData?.arrive_to} ${allData?.travel_date} ${allData?.trip_type}`;
  let bookingwop = `Booking without price ${allData?.dept_from}-${allData?.arrive_to} ${allData?.travel_date} ${allData?.trip_type}`;
  let agent = `Agent invoice ${allData?.dept_from}-${allData?.arrive_to} ${allData?.travel_date} ${allData?.trip_type}`;
  let client = `Client invoice ${allData?.dept_from}-${allData?.arrive_to} ${allData?.travel_date} ${allData?.trip_type}`;
  let eticketp = `e-Ticket with price${allData?.dept_from}-${allData?.arrive_to} ${allData?.travel_date} ${allData?.trip_type}`;
  let eticketwop = `e-Ticket without price${allData?.dept_from}-${allData?.arrive_to} ${allData?.travel_date} ${allData?.trip_type}`;

  return (
    <div>
      {/* {allData?.status === "Issue In Processing" ||
        allData?.status === "Booking Failed" ||
        allData?.status === "Hold" ||
        allData?.status === "Cancelled" ||
        allData?.status === "Issue Rejected" ? ( */}
      {allData?.status ||
      allData?.status ||
      allData?.status ||
      allData?.status ||
      allData?.status ? (
        <>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="1"
                userData={userData}
                allData={allData}
                adultTotalPrice={adultTotalPrice}
                childTotalPrice={childTotalPrice}
                infantTotalPrice={infantTotalPrice}
                totalPrice={totalPrice}
                companyImage={userData?.agent_comp_img}
                multiCityAllData={multiCityAllData}
                multicity={multicity}
                markupPrice={markupPrice}
              />
            }
            fileName={"Booking with price"}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document...1"
              ) : (
                <a
                  href={url}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    fontSize: "14px",
                  }}
                >
                  Booking With Price
                </a>
              )
            }
          </PDFDownloadLink>
          <br />
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Booking Copy"
                check="2"
                userData={userData}
                allData={allData}
                adultTotalPrice={adultTotalPrice}
                childTotalPrice={childTotalPrice}
                infantTotalPrice={infantTotalPrice}
                totalPrice={totalPrice}
                //prices={prices}
                companyImage={userData?.agent_comp_img}
                multiCityAllData={multiCityAllData}
                multicity={multicity}
                markupPrice={markupPrice}
              />
            }
            fileName={bookingwop}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                "Loading document...2"
              ) : (
                <a
                  href={url}
                  style={{
                    cursor: "pointer",
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    fontSize: "14px",
                  }}
                >
                  Booking With Out Price
                </a>
              )
            }
          </PDFDownloadLink>
        </>
      ) : (
        <>
          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Invoice"
                check="1"
                userData={userData}
                allData={allData}
                adultTotalPrice={adultTotalPrice}
                childTotalPrice={childTotalPrice}
                infantTotalPrice={infantTotalPrice}
                totalPrice={totalPrice}
                //prices={prices}
                companyImage={userData?.agent_comp_img}
                multiCityAllData={multiCityAllData}
                markupPrice={markupPrice}
              />
            }
            fileName={client}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={url}
                  // href={fileName}
                  target="_blank"
                  style={{
                    cursor: "pointer",
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    fontSize: "14px",
                  }}
                  rel="noreferrer"
                >
                  Client Invoice
                </a>
              )
            }
          </PDFDownloadLink>
          <br />

          <PDFDownloadLink
            document={
              <PDFPageDesign
                copy="Agent Invoice"
                check="1"
                margin="20px"
                userData={userData}
                allData={allData}
                adultTotalPrice={adultTotalPrice}
                childTotalPrice={childTotalPrice}
                infantTotalPrice={infantTotalPrice}
                totalPrice={totalPrice}
                discount={discount}
                companyImage={userData?.agent_comp_img}
                multiCityAllData={multiCityAllData}
                setMultiCityAllData={setMultiCityAllData}
                markupPrice={markupPrice}
              />
            }
            fileName={agent}
          >
            {({ blob, url, fileName, loading, error }) =>
              loading ? (
                "Loading document..."
              ) : (
                <a
                  href={url}
                  style={{
                    cursor: "pointer",
                    color: "var(--primary-color)",
                    textDecoration: "none",
                    fontSize: "14px",
                  }}
                >
                  Agent Invoice
                </a>
              )
            }
          </PDFDownloadLink>

          <br />

          {allData.booking.status === "Ticketed" && (
            <PDFDownloadLink
              document={
                <PDFPageDesign
                  copy="e - Ticket"
                  check="1"
                  userData={userData}
                  allData={allData}
                  adultTotalPrice={adultTotalPrice}
                  childTotalPrice={childTotalPrice}
                  infantTotalPrice={infantTotalPrice}
                  totalPrice={totalPrice}
                  discount={discount}
                  // discountPrice={discountPrice}
                  //prices={prices}
                  companyImage={userData?.agent_comp_img}
                  multiCityAllData={multiCityAllData}
                  markupPrice={markupPrice}
                />
              }
              fileName={eticketp}
            >
              {({ blob, url, fileName, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <a
                    href={url}
                    style={{
                      cursor: "pointer",
                      color: "var(--primary-color)",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    e-Ticket With Price
                  </a>
                )
              }
            </PDFDownloadLink>
          )}
          <br />
          {allData.booking.status === "Ticketed" && (
            <PDFDownloadLink
              document={
                <PDFPageDesign
                  copy="e - Ticket"
                  check="2"
                  userData={userData}
                  allData={allData}
                  adultTotalPrice={adultTotalPrice}
                  childTotalPrice={childTotalPrice}
                  infantTotalPrice={infantTotalPrice}
                  totalPrice={totalPrice}
                  //prices={prices}
                  companyImage={userData?.agent_comp_img}
                  multiCityAllData={multiCityAllData}
                  markupPrice={markupPrice}
                />
              }
              fileName={eticketwop}
            >
              {({ blob, url, fileName, loading, error }) =>
                loading ? (
                  "Loading document..."
                ) : (
                  <a
                    href={url}
                    style={{
                      cursor: "pointer",
                      color: "var(--primary-color)",
                      textDecoration: "none",
                      fontSize: "14px",
                    }}
                  >
                    e-Ticket With Out Price
                  </a>
                )
              }
            </PDFDownloadLink>
          )}
        </>
      )}
    </div>
  );
};

export default AllPDFButtons;
