import { Box, Pagination, Stack, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import commaNumber from "comma-number";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#dc143c",
      darker: "#dc143c",
    },
  },
});

const OtherReissue = () => {
  const [reissueData, seReissueData] = useState([]);
  const [searchData, setSearchData] = useState([]);

  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 50;

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setSearchData(reissueData.slice((value - 1) * size, value * size));
  };

  useEffect(() => {
    fetch("https://api.flyfarint.com/v.1.0.0/Admin/Booking/all.php?allother")
      .then((res) => res.json())
      .then((data) => {
        const reissueDataFilter = data.filter((filterData) => {
          return filterData.serviceType === "Reissue";
        });

        const count = reissueDataFilter.length;
        const pageNumber = Math.ceil(count / size);
        setPageCount(pageNumber);
        setSearchData(reissueDataFilter);
        seReissueData(reissueDataFilter);
      });
  }, []);

  //  handle search
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = reissueData.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setSearchData(filterData);
    } else if (searchInput === "") {
      setSearchData(reissueData);
    }
  };
  return (
    <Box
      style={{ width: "100% " }}
      className="searchHistoryTable"
      marginTop={"20px"}
    >
      <Box className="searchList1" mb={2}>
        <input type="text" placeholder="search" onChange={handelSearchItems} />
      </Box>

      <table>
        <thead>
          <tr>
            <th>Ref Id</th>
            <th>Company Name</th>
            <th>Description</th>
            <th>Service Type</th>
            <th>Attachment</th>
            <th>Cost</th>
            <th>Operation Time</th>
            <th>Operation By</th>
          </tr>
        </thead>
        <tbody>
          {searchData?.slice(0, size).map((data) => (
            <tr>
              <td>{data?.agentId}</td>
              <td>
                <Tooltip
                  title={data?.companyname}
                  style={{ width: "50px", margin: "auto" }}
                >
                  <span>
                    {data?.companyname?.slice(0, 10)}
                    ...
                  </span>
                </Tooltip>
              </td>
              <td>{data?.description}</td>
              <td> {data?.serviceType}</td>
              <td>
                <a href={data?.attachment} target="_blank">
                  View
                </a>
              </td>
              <td>{commaNumber(Number(parseInt(data?.amount)))} ৳</td>
              <td>{data?.createdAt}</td>
              <td>{data?.createdBy}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <Box
        sx={{
          width: "100%",
          my: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ThemeProvider theme={theme}>
          <Stack spacing={2}>
            <Pagination
              count={pageCount}
              onChange={handlePageChange}
              color="primary"
            />
          </Stack>
        </ThemeProvider>
      </Box>
    </Box>
  );
};

export default OtherReissue;
