/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Grid, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaWhatsappSquare } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import "./Footer.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";
import { useState } from "react";
import ReactHtmlParser from "react-html-parser";
import footerLogo from "../../imagesv2/home/fotterlogo.svg";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

const Footer = () => {
  const date = new Date().getFullYear();
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [about, setAbout] = useState("");

  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&footerAddress`
    )
      .then((res) => res.json())
      .then((data) => setAddress(data?.content));
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&footerAboutUs`
    )
      .then((res) => res.json())
      .then((data) => setAbout(data?.content));
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&contactEmail`
    )
      .then((res) => res.json())
      .then((data) => setEmail(data?.content));
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&contactPhone`
    )
      .then((res) => res.json())
      .then((data) => setPhone(data?.content));
  }, []);

  return (
    <Box>
      <Box className="footer-bgs">
        <Container>
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={3}>
                <Box mb={2}>
                  <img src={footerLogo} style={{ width: "145px" }} />
                </Box>
                <Typography
                  style={{
                    fontSize: "13px",
                    color: "var(--white)",
                    width: "75%",
                    textAlign: "justify",
                  }}
                >
                  {/* {ReactHtmlParser(about)} */}
                  World Wide Tnt Travels opens doors to travel dreams. They
                  provide a 24/7 support team and personalized experiences,
                  making memorable journeys a reality.
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                    mb={2}
                  >
                    Information
                  </Typography>
                  <Box style={{ display: "flex", gap: "5px" }}>
                    <LocationOnIcon />
                    <Typography
                      style={{ fontSize: "13px", color: "var(--white)" }}
                    >
                     
                      Shop No: 272, Souq Al Haraj, Najma, Doha-Qatar, Unit 9,
                      Build 42, St. No: 850, Zone: 26
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: "5px" }}>
                    <MailOutlineIcon />
                    <Typography
                      style={{ fontSize: "13px", color: "var(--white)" }}
                    >
                      world.doh@gmail.com
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: "5px" }}>
                    <PhoneAndroidIcon />
                    <Typography
                      style={{ fontSize: "13px", color: "var(--white)" }}
                    >
                      +974-44340191
                    </Typography>
                  </Box>
                  <Box style={{ display: "flex", gap: "5px" }}>
                    <AddIcCallIcon />
                    <Typography
                      style={{ fontSize: "13px", color: "var(--white)" }}
                    >
                      +974-50006712
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                    justifyContent: "center",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                    mb={1}
                  >
                    Quick Links
                  </Typography>

                  <Box
                    style={{
                      color: "var(--white)",
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "start",
                      justifyContent: "space-evenly",
                    }}
                  >
                    <NavLink to="/aboutus" style={{ textDecoration: "none" }}>
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "var(--white)",
                        }}
                      >
                        About Us
                      </Typography>
                    </NavLink>
                    <NavLink to="/" style={{ textDecoration: "none" }}>
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "var(--white)",
                        }}
                      >
                        Payment Method
                      </Typography>
                    </NavLink>
                    <NavLink to="/terms" style={{ textDecoration: "none" }}>
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "var(--white)",
                        }}
                      >
                        Terms and Condition
                      </Typography>
                    </NavLink>
                    <NavLink to="/privacy" style={{ textDecoration: "none" }}>
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "var(--white)",
                        }}
                      >
                        Privacy Policy
                      </Typography>
                    </NavLink>
                    <NavLink to="/refund" style={{ textDecoration: "none" }}>
                      <Typography
                        style={{
                          fontSize: "13px",
                          color: "var(--white)",
                        }}
                      >
                        Refund Policy
                      </Typography>
                    </NavLink>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box
                  style={{
                    color: "var(--white)",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",

                    justifyContent: "start",
                    gap: "10px",
                  }}
                >
                  <Typography
                    style={{ fontSize: "20px", color: "var(--white)" }}
                    mb={2}
                  >
                    Our Newsletter
                  </Typography>
                  <Box>
                    <input
                      type="email"
                      placeholder="Your Email"
                      style={{
                        width: "100%",
                        padding: "5px",
                        background: "var(--white)",
                        color: "var(--black)",
                        boxSizing: "border-box",
                        border: "none",
                        borderRadius: "3px",
                      }}
                    />
                  </Box>
                  <Box>
                    <button
                      style={{
                        width: "100%",
                        height: "32px",
                        background: "var(--secondary-color)",
                        color: "var(--white)",
                        border: "none",
                        borderRadius: "3px",
                      }}
                    >
                      Subscribe
                    </button>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>

      <Box style={{ background: "var(--secondary-color)" }}>
        <Typography
          sx={{
            color: "var(--white)",
            textAlign: "center",
            fontSize: {
              xs: "9.7px",
              sm: "10px",
              md: "13px",
              lg: "13px",
            },
            padding: "8px 0px",
          }}
          noWrap
        >
          &copy; Copyright {date} by Fly Far International Develop by FlyFar
          Tech
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
