import React, { useCallback, useMemo, useRef, useState } from "react";
import Tab from "@mui/material/Tab";
import { Box } from "@mui/system";
import { format, addDays, subDays } from "date-fns";
import Oneway from "../Oneway/Oneway";
import Roundway from "../Roundway/Roundway";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Multicity from "../MultiCity/Multicity";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import {
  Button,
  ClickAwayListener,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import "./FlightSearchBox.css";
import { useLocation } from "react-router-dom";
const isMobile = window.innerWidth <= 768;

const FlightSearchBox = ({
  type,
  setType,
  click,
  setClick,
  value,
  setValue,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  from,
  setFrom,
  to,
  setTo,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  setClassName,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  multiSearchData,
  setMultiSearchData,
  agentId,
}) => {
  const handleClassName = (event) => {
    setClassName(event.target.value);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const location = useLocation();
  // passenger info

  const [open, setOpen] = useState(false);
  const [openClass, setOpenClass] = useState(false);

  const handleClose = () => {
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
    }
  }

  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
        }
      }
    }
  }

  function adult2Inclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
    }
  }

  function adult2Decrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
    }
  }

  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
      }
    }
  }

  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
    }
  }

  const handleClickAway = () => {
    // setOpenFrom(false);
    // setOpenTo(false);
    // setOpenDate(false);
    // setOpenReturnDate(false);
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box
        sx={{
          // maxWidth: "1117px",
          margin: "0 auto",
          bgcolor: "white",
          padding: "0px 0px 10px 0px",
          borderRadius: "4px",
          pb: {
            xs: value === "multicity" ? "20px" : "80px",
            sm: "50px",
            md: "40px",
            lg: "40px",
          },

          pl:
            location.pathname === "/dashboard/searchresult" ||
            location.pathname === "/dashboard/roundsearchresult" ||
            location.pathname === "/dashboard/multicityaftersearch"
              ? "30px"
              : "0px",
        }}
      >
        <TabContext value={value}>
          <Grid
            container
            spacing={{ xs: 3, sm: 3, md: 3, lg: 3 }}
            alignItems="center"
            mt={"-10px"}
          >
            <Grid item xs={12}>
              <Box
                sx={{
                  paddingLeft:
                    location?.pathname === "/"
                      ? isMobile
                        ? "0px"
                        : "30px"
                      : isMobile
                      ? "-10px"
                      : "0px",
                  ml: isMobile
                    ? location.pathname === "/"
                      ? "15px"
                      : "0px"
                    : "0px",
                  bgcolor: "transparent",
                  color: "var(--mateBlack)",
                  width: "100%",
                  height: "fit-content",
                  display: "flex",
                  justifyContent: "flex-start",
                  "& button.Mui-selected": {
                    color: "var(--secondary-color)",
                  },
                }}
              >
                <RadioGroup
                  row
                  value={value}
                  onChange={handleChange}
                  sx={{
                    flexDirection: {
                      xs: "row",
                      sm: "row",
                      md: "row",
                      lg: "row",
                    },

                    "&.Mui-checked": {
                      color: "var(--primary-color)",
                    },
                    "& .MuiFormControlLabel-label": {
                      color: "var(--light-gray)",
                      fontWeight: "400",
                      fontSize: {
                        lg: "15px",
                        md: "12px",
                        sm: "10px",
                        xs: "10px",
                      },
                    },
                  }}
                >
                  <FormControlLabel
                    value="oneway"
                    control={
                      <Radio
                        sx={{
                          color: "var(--gray)",
                          "&.Mui-checked": {
                            color: "var(--primary-color)",
                            fontWeight: "400",
                          },
                        }}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: `${
                          value === "oneway" ? "var(--primary-color)" : ""
                        }`,
                        fontWeight: "400",
                      },
                    }}
                    label="One Way"
                  />
                  <FormControlLabel
                    value="return"
                    control={
                      <Radio
                        sx={{
                          color: "var(--gray)",
                          "&.Mui-checked": {
                            color: "var(--primary-color)",
                            fontWeight: "400",
                          },
                        }}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: `${
                          value === "return" ? "var(--primary-color)" : ""
                        }`,
                        fontWeight: "400",
                      },
                    }}
                    label="Return"
                  />

                  <FormControlLabel
                    value="multicity"
                    control={
                      <Radio
                        sx={{
                          color: "var(--gray)",
                          "&.Mui-checked": {
                            color: "var(--primary-color)",
                            fontWeight: "400",
                          },
                        }}
                      />
                    }
                    sx={{
                      "& .MuiFormControlLabel-label": {
                        color: `${
                          value === "multicity" ? "var(--primary-color)" : ""
                        }`,
                        fontWeight: "400",
                      },
                    }}
                    label="Multi City"
                  />
                </RadioGroup>
              </Box>
            </Grid>
            <Grid
              item
              position="relative"
              sx={{
                ml: {
                  xs: location.pathname === "/" ? "26px" : "0px",
                  sm: "20px",
                  md: "50px",
                  lg: location.pathname === "/" ? "30px" : "0px",
                },
              }}
            >
              <Stack
                direction={{ xs: "row", sm: "row", md: "row", lg: "row" }}
                spacing={{ xs: 7, sm: 10, md: 10, lg: 10 }}
                justifyContent="flex-start"
              >
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontWeight: 400,
                    cursor: "pointer",
                    position: "relative",
                    fontSize: 13,
                  }}
                  onClick={() => {
                    setOpen((prev) => !prev);
                    setOpenClass(false);
                  }}
                >
                  For {adultCount + childCount + infant}&nbsp;Passenger
                  <ArrowDropDownSharpIcon
                    sx={{
                      padding: 0,
                      fontSize: 30,
                      position: "absolute",
                      color: "var(--primary-color)",
                      top: "-6px",
                    }}
                  />
                </Typography>
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontWeight: 400,
                    cursor: "pointer",
                    position: "relative",
                    fontSize: 13,
                    pr: 3,
                  }}
                  onClick={() => {
                    setOpenClass((prev) => !prev);
                    setOpen(false);
                  }}
                >
                  {className || "Economy"}
                  <ArrowDropDownSharpIcon
                    sx={{
                      padding: 0,
                      fontSize: 30,
                      position: "absolute",
                      color: "var(--primary-color)",
                      top: "-6px",
                    }}
                  />
                </Typography>
              </Stack>
              {open && (
                <Box
                  sx={{
                    position: "absolute",
                    top: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                    right: "0px",
                    zIndex: 1000,
                    borderRadius: "5px",
                    backgroundColor: "var(--white)",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  }}
                >
                  <Box width="250px" p={2}>
                    <Typography
                      style={{
                        textAlign: "left",
                        marginBottom: "5px",
                        color: "var(--black)",

                        fontWeight: 400,
                      }}
                    >
                      Passenger
                    </Typography>
                    <Stack
                      direction="row"
                      spacing={4}
                      justifyContent="space-between"
                      alignItems="center"
                      pb={1}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        width="40%"
                      >
                        <button
                          onClick={adultDecrement}
                          style={{
                            backgroundColor: "var(--black)",
                            color: "var(--white)",
                            border: "none",
                            width: "25px",
                            height: "25px",
                            fontSize: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        >
                          -
                        </button>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "var(--black)",
                          }}
                        >
                          {adultCount}
                        </Typography>
                        <button
                          onClick={adultInclement}
                          style={{
                            backgroundColor: "var(--black)",
                            color: "var(--white)",
                            border: "none",
                            width: "25px",
                            height: "25px",
                            fontSize: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        >
                          +
                        </button>
                      </Stack>
                      <Box width="60%">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "var(--black)",
                          }}
                        >
                          Adult
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "var(--black)",
                          }}
                        >
                          12+ yrs
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={4}
                      justifyContent="space-between"
                      alignItems="center"
                      pb={1}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        width="40%"
                      >
                        <button
                          onClick={adult2Decrement}
                          style={{
                            backgroundColor: "var(--black)",
                            color: "var(--white)",
                            border: "none",
                            width: "25px",
                            height: "25px",
                            fontSize: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        >
                          -
                        </button>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "var(--black)",
                          }}
                        >
                          {childCount}
                        </Typography>
                        <button
                          onClick={adult2Inclement}
                          style={{
                            backgroundColor: "var(--black)",
                            color: "var(--white)",
                            border: "none",
                            width: "25px",
                            height: "25px",
                            fontSize: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        >
                          +
                        </button>
                      </Stack>
                      <Box width="60%">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "var(--black)",
                          }}
                        >
                          Child
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "var(--black)",
                          }}
                        >
                          2- less than 12 yrs
                        </Typography>
                      </Box>
                    </Stack>
                    <Stack
                      direction="row"
                      spacing={4}
                      justifyContent="space-between"
                      alignItems="center"
                      pb={1}
                    >
                      <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        width="40%"
                      >
                        <button
                          onClick={infantDecrement}
                          style={{
                            backgroundColor: "var(--black)",
                            color: "var(--white)",
                            border: "none",
                            width: "25px",
                            height: "25px",
                            fontSize: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        >
                          -
                        </button>
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "var(--black)",
                          }}
                        >
                          {infant}
                        </Typography>
                        <button
                          onClick={infantIncrement}
                          style={{
                            backgroundColor: "var(--black)",
                            color: "var(--white)",
                            border: "none",
                            width: "25px",
                            height: "25px",
                            fontSize: "20px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            borderRadius: "2px",
                            cursor: "pointer",
                          }}
                        >
                          +
                        </button>
                      </Stack>
                      <Box width="60%">
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "var(--black)",
                          }}
                        >
                          Infant
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "12px",
                            color: "var(--black)",
                          }}
                        >
                          0 - 23 month
                        </Typography>
                      </Box>
                    </Stack>
                    <Box mt={2} style={{ textAlign: "right" }}>
                      <Button
                        size="small"
                        onClick={handleClose}
                        className="shine-effect"
                        style={{
                          backgroundColor: "var(--black)",
                          color: "var(--white)",
                        }}
                      >
                        DONE
                      </Button>
                    </Box>
                  </Box>
                </Box>
              )}
              {openClass && (
                <Box
                  sx={{
                    position: "absolute",
                    top: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                    right: "0px",
                    zIndex: 1000,
                    borderRadius: "5px",
                    backgroundColor: "var(--white)",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                  }}
                >
                  <Box width="250px" p={2}>
                    <Typography
                      style={{
                        marginBottom: "5px",
                        color: "var(--black)",
                        fontWeight: 400,
                      }}
                    >
                      Flight Class
                    </Typography>

                    <Box>
                      <FormControl>
                        <RadioGroup
                          value={className}
                          onChange={handleClassName}
                        >
                          <Grid
                            container
                            spacing={1}
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="First"
                                control={
                                  <Radio
                                    sx={{
                                      color: "var(--black)",
                                      "&.Mui-checked": {
                                        color: "var(--black)",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      color: "var(--black)",
                                      fontSize: 13,
                                    }}
                                  >
                                    First
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="PremiumEconomy"
                                control={
                                  <Radio
                                    sx={{
                                      color: "var(--black)",
                                      "&.Mui-checked": {
                                        color: "var(--black)",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      color: "var(--black)",
                                      fontSize: 12,
                                    }}
                                  >
                                    PremiumEconomy
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="PremiumFirst"
                                control={
                                  <Radio
                                    sx={{
                                      color: "var(--black)",
                                      "&.Mui-checked": {
                                        color: "var(--black)",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      color: "var(--black)",
                                      fontSize: 13,
                                    }}
                                  >
                                    PremiumFirst
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="Economy"
                                control={
                                  <Radio
                                    sx={{
                                      color: "var(--black)",
                                      "&.Mui-checked": {
                                        color: "var(--black)",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      color: "var(--black)",
                                      fontSize: 13,
                                    }}
                                  >
                                    Economy
                                  </Typography>
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <FormControlLabel
                                value="Business"
                                control={
                                  <Radio
                                    sx={{
                                      color: "var(--black)",
                                      "&.Mui-checked": {
                                        color: "var(--black)",
                                      },
                                    }}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{
                                      color: "var(--black)",
                                      fontSize: 13,
                                    }}
                                  >
                                    Business
                                  </Typography>
                                }
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>

                      <Box mt={2} style={{ textAlign: "right" }}>
                        <Button
                          size="small"
                          onClick={() => setOpenClass(false)}
                          className="shine-effect"
                          style={{
                            backgroundColor: "var(--black)",
                            color: "var(--white)",
                          }}
                        >
                          DONE
                        </Button>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              )}
            </Grid>
          </Grid>

          <TabPanel
            value="return"
            style={{
              padding:
                location.pathname === "/"
                  ? "0px 20px 0px 20px"
                  : "0px 20px 0px 0px",
            }}
          >
            <Roundway
              click={click}
              setClick={setClick}
              tripType={value}
              iconColor={"#DC143C"}
              bgColor={"#fff"}
              bordercolor={"#003566"}
              faddress={faddress}
              setfaddress={setfaddress}
              toAddress={toAddress}
              setToAddress={setToAddress}
              fromSendData={fromSendData}
              setFromSendData={setFromSendData}
              toSendData={toSendData}
              setToSendData={setToSendData}
              fromSearchText={fromSearchText}
              setFromSearchText={setFromSearchText}
              toSearchText={toSearchText}
              setToSearchText={setToSearchText}
              setValue={setValue}
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              childCount={childCount}
              setChildCount={setChildCount}
              infant={infant}
              setInfant={setInfant}
              result={result}
              setResult={setResult}
              className={className}
              handleClassName={handleClassName}
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
              setChangeState={setChangeState}
              changeState={changeState}
              changeFrom={changeFrom}
              setChangeFrom={setChangeFrom}
              agentId={agentId}
            />
          </TabPanel>
          <TabPanel
            value="oneway"
            style={{
              padding:
                location.pathname === "/"
                  ? "0px 20px 0px 20px"
                  : "0px 20px 0px 0px",
            }}
          >
            <Oneway
              click={click}
              setClick={setClick}
              tripType={value}
              iconColor={"#DC143C"}
              bgColor={"#fff"}
              bordercolor={"#003566"}
              faddress={faddress}
              fromSendData={fromSendData}
              setFromSendData={setFromSendData}
              toSendData={toSendData}
              setToSendData={setToSendData}
              setfaddress={setfaddress}
              toAddress={toAddress}
              setToAddress={setToAddress}
              fromSearchText={fromSearchText}
              setFromSearchText={setFromSearchText}
              toSearchText={toSearchText}
              setToSearchText={setToSearchText}
              setValue={setValue}
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              childCount={childCount}
              setChildCount={setChildCount}
              infant={infant}
              setInfant={setInfant}
              result={result}
              setResult={setResult}
              className={className}
              handleClassName={handleClassName}
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
              setChangeState={setChangeState}
              changeState={changeState}
              changeFrom={changeFrom}
              setChangeFrom={setChangeFrom}
              agentId={agentId}
            />
          </TabPanel>

          <TabPanel
            value="multicity"
            style={{
              padding:
                location.pathname === "/"
                  ? "0px 20px 0px 20px"
                  : "0px 20px 0px 0px",
            }}
          >
            <Multicity
              click={click}
              setClick={setClick}
              tripType={value}
              iconColor={"#DC143C"}
              bgColor={"#fff"}
              bordercolor={"#003566"}
              faddress={faddress}
              setfaddress={setfaddress}
              toAddress={toAddress}
              setToAddress={setToAddress}
              fromSendData={fromSendData}
              setFromSendData={setFromSendData}
              toSendData={toSendData}
              setToSendData={setToSendData}
              fromSearchText={fromSearchText}
              setFromSearchText={setFromSearchText}
              toSearchText={toSearchText}
              setToSearchText={setToSearchText}
              setValue={setValue}
              adultCount={adultCount}
              setAdultCount={setAdultCount}
              childCount={childCount}
              setChildCount={setChildCount}
              infant={infant}
              setInfant={setInfant}
              result={result}
              setResult={setResult}
              className={className}
              handleClassName={handleClassName}
              to={to}
              setTo={setTo}
              from={from}
              setFrom={setFrom}
              setChangeState={setChangeState}
              changeState={changeState}
              changeFrom={changeFrom}
              setChangeFrom={setChangeFrom}
              multiSearchData={multiSearchData}
              setMultiSearchData={setMultiSearchData}
              agentId={agentId}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </ClickAwayListener>
  );
};

export default FlightSearchBox;
