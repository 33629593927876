/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid, Tab, Tabs, Container } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import triangle from "../../images/Icon/triangle.png";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--secondary-color)",
    maxWidth: 300,
    padding: "10px",
  },
}));

const ReturnTransit = ({ duration, stop }) => {
  return (
    <Box textAlign={"center"} padding="0px 7px">
      <Typography>
        <Box sx={{ mt: 1 }}>
          <Typography
            sx={{
              color: "var(--third-color)",
              fontWeight: 400,
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "11px",
              },
              mb: "4px",
            }}
          >
            {duration}
          </Typography>

          <Box sx={{ position: "relative", pl: "20px" }}>
            <hr
              style={{
                backgroundcolor: "var(--primary-color)",
                fontWeight: 500,
                width: "80%",
              }}
            />
            <img
              src={triangle}
              alt="icon"
              style={{ position: "absolute", top: "-13px", right: "5%" }}
            />
          </Box>

          <Typography
            sx={{
              color: "var(--primary-color)",
              fontWeight: 500,
              fontSize: {
                xs: "10px",
                sm: "10px",
                md: "12px",
              },
            }}
          >
            {stop?.length === 1 ? "NO STOP" : <>{stop} Stop</>}
          </Typography>
        </Box>
      </Typography>
    </Box>
  );
};

export default ReturnTransit;
