import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const UpdatedPassword = () => {
  const location = useLocation();
  const email = location.state?.email || "";
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };

  const handleChangePassword = async (e) => {
    e.preventDefault();

    try {
      const url =
        "https://api.rtc.flyfarint.com/v1.0/b2b/auth/password/reset.php";

      if (newPassword && confirmPassword && newPassword === confirmPassword) {
        const requestData = {
          email: email,
          password: newPassword,
        };

        await axios
          .post(url, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res?.data?.status === "success") {
              Swal.fire({
                title: "Success",
                text: `${res.data.message}`,
                icon: "success",
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "OK",
              }).then(() => {
                navigate("/");
              });
            } else {
              Swal.fire({
                title: "Error",
                text: `${res.data.message}`,
                icon: "error",
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              });
            }
          });
      } else {
        Swal.fire({
          title: "Error",
          text: "New password and confirm password do not match",
          icon: "error",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };
  return (
    <Box sx={{ bgcolor: "var( --primary-color)" }}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            background: "#ffff",
            width: "450px",
            px: "20px",
            borderRadius: "5px",
            py: "15px",
          }}
        >
          <Box sx={{ textAlign: "center", my: "10px" }}>
            {/* Your branding here */}
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: "var(--profile-boday-color)",
              textAlign: "center",
            }}
          >
            Change Password
          </Typography>
          {/* New Password Input */}
          <form onSubmit={handleChangePassword}>
            <Box
              sx={{
                borderBottom: "1px solid #B6B6CC",
              }}
            >
              <Box sx={{ py: "20px", width: "250px" }}>
                <label
                  style={{
                    color: "var( --profile-boday-color)",
                    fontSize: "16px",
                  }}
                >
                  New Password
                </label>
              </Box>
              <input
                placeholder="Your New Password"
                type="password"
                style={{
                  border: "none",
                  color: "#B6B6CC",
                  outline: "none",
                  background: "none",
                }}
                value={newPassword}
                onChange={handleNewPasswordChange}
              />
            </Box>
            {/* Confirm Password Input */}
            <Box
              sx={{
                borderBottom: "1px solid #B6B6CC",
              }}
            >
              <Box sx={{ py: "20px", width: "250px" }}>
                <label
                  style={{
                    color: "var( --profile-boday-color)",
                    fontSize: "16px",
                  }}
                >
                  Confirm Password
                </label>
              </Box>
              <input
                placeholder="Confirm Password"
                type="password"
                style={{
                  border: "none",
                  color: "#B6B6CC",
                  outline: "none",
                  background: "none",
                }}
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
              />
            </Box>
            {/* Change Password Button */}
            <Button
              sx={{
                borderRadius: "6px",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
                width: "100%",
                height: "45px",
                textTransform: "capitalize",
                fontSize: "15px",
                mt: "20px",
                ":hover": {
                  backgroundColor: "var(--primary-color)",
                },
              }}
              type="submit"
            >
              Change Password
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default UpdatedPassword;
