import React from "react";
import { Grid, Box, Typography, Button } from "@mui/material";

import { useNavigate } from "react-router-dom";
import moment from "moment";

const GroupFare = ({ flightData }) => {
  // console.log(flightData);

  const navigate = useNavigate();

  const travelTimeToMinute = (time) => {
    const hourMatch = time.match(/\d+H/);
    const minuteMatch = time.match(/\d+M/);
    const hours = hourMatch ? parseInt(hourMatch[0]) : 0;
    const minutes = minuteMatch ? parseInt(minuteMatch[0]) : 0;
    const totalMinutes = parseFloat(hours * 60 + minutes);

    return totalMinutes;
  };

  const totalTravelTime = (time1, transite, time2) => {
    const segment1Min = travelTimeToMinute(time1);
    const transiteMin = travelTimeToMinute(transite);
    const segment2Min = travelTimeToMinute(time2);

    const totalTravelTime = `${parseInt(
      (segment1Min + transiteMin + segment2Min) / 60
    )}H ${(segment1Min + transiteMin + segment2Min) % 60}M`;

    return totalTravelTime;
  };

  return (
    <Box
      sx={{
        mb: "15px",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        transition: "all .5s ease-in-out",
        boxShadow: "rgba(0, 0, 0, 0.15) 0px 1px 13px",
        borderRadius: "5px",
      }}
    >
      <Grid
        justifyContent={"space-between"}
        container
        gap={2}
        sx={{
          p: "20px",
          borderRadius: "4px",
        }}
      >
        <Grid order={{ xs: 1, lg: 1 }} xs={6} lg={1.9}>
          <Box
            sx={{
              display: {
                xs: "flex",
                alignItems: "center",
                lg: "block",
              },
            }}
          >
            <img
              alt=""
              src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flightData?.flight_code1}.png`}
              width="60px"
              height="60px"
              className={`${flightData?.system
                ?.toLowerCase()
                ?.split(" ")
                ?.join("-")}-border`}
            />

            <Typography variant="body2" sx={{ color: "#2156A6" }}>
              {flightData?.carrier_name1}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#222",
                display: {
                  xs: "none",
                  md: "block",
                },
              }}
            >
              {flightData?.carrier_code1} - {flightData?.flight_num1}
            </Typography>
          </Box>
        </Grid>

        <Grid order={{ xs: 3, lg: 2 }} xs={3} lg={2.6}>
          <Box>
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Depart
            </Typography>
            <Typography variant="title-lg">{flightData?.dept_code1}</Typography>
            <Typography variant="body2" sx={{ color: "var(--primary-color)" }}>
              {flightData?.dept_name1}
            </Typography>
            <Typography variant="body2" sx={{ color: "#222" }}>
              {flightData?.segment === "1"
                ? moment(flightData?.dept_time1).format("DD MMM YYYY hh:mm A")
                : moment(flightData?.dept_time1).format("DD MMM YYYY hh:mm A")}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {/* {departureDate} */}
            </Typography>
          </Box>
        </Grid>

        <Grid order={{ xs: 4, lg: 3 }} xs={3} lg={1.75}>
          <Box sx={{ pt: "20px" }}>
            <Typography
              variant="body2"
              sx={{ color: "#ACA6A6", textAlign: "center", mr: "10px" }}
            >
              {totalTravelTime(
                flightData?.travel_time1,
                flightData?.transit_time,
                flightData?.travel_time2
              )}
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="122"
              height="12"
              viewBox="0 0 122 12"
              fill="none"
            >
              <path
                d="M120.682 11C120.4 11 104.145 11 96.0529 11H0"
                stroke="#2156A6"
                strokeWidth="2"
              />
              <path
                d="M96.9551 2L120.682 11L96.9551 10.8623V2Z"
                fill="#2156A6"
                stroke="#2156A6"
                strokeWidth="2"
              />
            </svg>
            <Typography
              variant="body2"
              sx={{ color: "#444542", textAlign: "center", mr: "10px" }}
            >
              {flightData?.segment === "1" ? "Non Stop" : "1 Stop"}
            </Typography>
          </Box>
        </Grid>

        <Grid order={{ xs: 5, lg: 4 }} xs={3} lg={2.6}>
          <Box
            sx={{
              bgcolor: "blu",
            }}
          >
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Arrival
            </Typography>
            <Typography variant="title-lg">
              {flightData?.arrive_code2 || flightData?.arrive_code1}
            </Typography>
            <Typography variant="body2" sx={{ color: "#2156A6" }}>
              {flightData?.arrive_name2 || flightData?.arrive_name1}
            </Typography>
            <Typography variant="body2" sx={{ color: "#222" }}>
              {flightData?.segment === "1"
                ? moment(flightData?.arrive_time1).format("DD MMM YYYY hh:mm A")
                : moment(flightData?.arrive_time2).format(
                    "DD MMM YYYY hh:mm A"
                  )}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {/* {arrivalDate} */}
            </Typography>
          </Box>
        </Grid>

        <Grid order={{ xs: 2, lg: 5 }} xs={6} lg={1.75}>
          <Box
            sx={{
              textAlign: "right",
              borderLeft: "5px solid var(--input-bgcolor)",
            }}
          >
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Agent fare
            </Typography>
            <Typography variant="title-lg">
              {/* {Number(price).toLocaleString("en-IN")}৳ */}
              {/* {flightData?.grossFare} BDT */}
              {Number(flightData?.gross_fare).toLocaleString("en-IN")}৳
            </Typography>
            <Typography variant="body2" sx={{ color: "#2156A6" }}>
              {Number(flightData?.gross_fare).toLocaleString("en-IN")}৳
            </Typography>
            <Button
              // onClick={handleOneWayBook}
              onClick={() => {
                navigate("/dashboard/groupfaredetails", {
                  state: {
                    data: flightData,
                  },
                });
              }}
              sx={{
                bgcolor: "#F7D800",
                color: "#444542",
                fontWeight: "500",
                ":hover": { bgcolor: "#F7D800" },
                mt: "10px",
              }}
            >
              BOOK NOW
            </Button>
          </Box>
        </Grid>

        {/* bottom option */}
        <Grid
          justifyContent={"space-between"}
          order={{ xs: 6, lg: 6 }}
          item
          container
          gap={2}
          xs={12}
        >
          <Grid order={{ xs: 1, lg: 1 }} xs={6} lg={1.9}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Typography sx={{ fontSize: "12px", color: "#0B7102" }}>
                {/* {refundable} */}
                Refundable
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 3, lg: 2 }} xs={3} lg={2.6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                height: "100%",
              }}
            >
              {/* <Typography sx={{ fontSize: "12px", color: "#0B7102" }}>
     
                Refundable
              </Typography> */}
              <Typography sx={{ fontSize: "12px", color: "#222", mr: 1 }}>
                {flightData?.available_seat} Seats
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 4, lg: 3 }} xs={3} lg={1.75}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <Typography
                sx={{
                  color: "#2156A6",
                  // textAlign: "center",
                  fontSize: "12px",
                }}
              >
                Baggage {flightData?.baggage1} Kg
              </Typography>{" "}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GroupFare;
