import { Box, Grid, Typography } from "@mui/material";
import "./CashTab.css";

const CashTab = ({ handleInputChange, setAttachment }) => {
  return (
    <Box>
      <Box className="passengerInput1">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography>Sender Name</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require
                type="text"
                name="sender"
                onChange={handleInputChange}
                placeholder="Sender Name"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Receiver Name</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require={true}
                type="text"
                name="receiver"
                placeholder="Receiver Name"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Reference</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require={true}
                type="text"
                name="ref"
                placeholder="Reference"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Enter Amount</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require={true}
                type="text"
                placeholder="Enter amount"
                name="amount"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography>Choose Passport Copy</Typography>
            <Box style={{ marginTop: "5px" }} className="input-File1">
              <input
                require={true}
                style={{
                  backgroundColor: "#2564B8",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px",
                  boxSizing: "border-box",
                }}
                className="customFileType"
                type="file"
                title="Choose a Image png and jpg please"
                accept="image/*"
                name="attachment"
                onChange={(e) => setAttachment(e.target.files[0])}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CashTab;
