import { Box } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const data = [
  {
    id: 1,
    type: "Adult",
  },
  {
    id: 2,
    type: "Child",
  },
  {
    id: 3,
    type: "Infant",
  },
];

const Baggage = ({ pricebreakdown, flightData }) => {
  return (
    <Box
      sx={{
        padding: "15px 0",
        ".MuiTableCell-root .MuiPaper-root": {
          border: "none",
        },
        ".MuiTable-root": {
          th: {
            color: "white",
            fontSize: "13px",
            fontWeight: "500",
          },
          td: {
            color: "var(--primary-color)",
            fontSize: "13px",
            fontWeight: "500",
          },
        },
      }}
    >
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead sx={{ minWidth: "100%" }}>
            <TableRow sx={{ backgroundColor: "var(--primary-color)" }}>
              <TableCell align="center">Baggage</TableCell>
              <TableCell align="center">Check-In</TableCell>
              <TableCell align="center">Cabin</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((data, i) => {
              return (
                <TableRow
                  key={data.id}
                  sx={{
                    backgroundColor: `${(i + 1) % 2 !== 0 ? "#DEE8FF" : ""}`,
                  }}
                >
                  <TableCell align="center">{data.type}</TableCell>
                  <TableCell align="center">  {flightData?.bags > 3 && flightData?.bags !== undefined
                    ? `${flightData?.bags} KG`
                    : `${flightData?.bags}${flightData?.bags > 1 ? "s" : ""}`}</TableCell>
                  <TableCell align="center">7 KG</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Baggage;
