import {
  Box,
  Button,
  Container,
  Grid,
  Modal,
  Skeleton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import UserHeader from "../UserHeader/UserHeader";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { UserInfoContext } from "../../Contexts/UserInfoProvider";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  height: "50vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "10px",
  outline: "none",
};

const Account = () => {
  const navigate = useNavigate();
  const { userData, setIsUpdated, isUpdated } = useContext(UserInfoContext);
  const [openModal, setOpenModal] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [profileInfo, setProfileInfo] = useState({});
  const [profileOpen, setProfileOpen] = React.useState(false);
  const [passwordOpen, setPasswordOpen] = React.useState(false);
  const [oldShow, setOldShow] = useState(false);
  const [newShow, setNewShow] = useState(false);
  const [confirmShow, setConfirmShow] = useState(false);


  const [userInfo, setUserInfo] = useState({
    name: "",
    phoneNumber: "",
    staffId: "",
    companyName: "",
    companyAddress: "",
    email: "",
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  useEffect(() => {
    setUserInfo({
      name: userData?.agent_name || userData?.staff_name || "",
      phoneNumber:
        userData?.staff_phone_number || userData?.agent_phone_number || "",
      staffId: userData?.staff_id || "",
      companyName: userData?.agent_comp_name || "",
      companyAddress: userData?.agent_comp_address,
      email: userData?.agent_email || userData?.staff_email,
      oldPassword: "",
      newPassword: "",
      confirmPassword: ""
    });
  }, [userData]);

  //*********************profile update operation************************ */
  const handleClickProfileOpen = () => {
    setProfileOpen(true);
  };

  const handleProfileClose = () => {
    setProfileOpen(false);
  };

  // const handleOpenModal = () => {
  //   setOpenModal(true);
  // };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  //*********************password update operation************************ */

  const handleClickPasswordOpen = () => {
    setPasswordOpen(true);
  };

  const handlePasswordClose = () => {
    setPasswordOpen(false);
  };

  const handleUpdateInfo = async (e) => {
    e.preventDefault();

    // Show confirmation dialog using SweetAlert
    const confirmation = await Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to update your information?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, update it!',
    });

    // Check if the user confirmed the update
    if (confirmation.isConfirmed) {
      const body = {
        agentId: userData.agent_id,
        agentName: userInfo?.name,
        companyName: userInfo?.companyName,
        email: userData?.agent_email,
        phone: userInfo?.phoneNumber,
        companyAddress: userInfo?.companyAddress,
        actionBy: userInfo?.name,
      };

      try {
        const response = await axios.post(
          "https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/edit.php",
          JSON.stringify(body),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response?.data);
        if (response.data?.status === 'success') {
          Swal.fire({
            icon: 'success',
            title: 'Update Successful',
            text: response?.data?.message,
          });
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Update Failed',
            text: 'There was an error updating your information. Please try again.',
          });
        }
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title: 'Update Failed',
          text: 'There was an error updating your information. Please try again.',
        });
      }
    }
  };

  const handleUpdateImage = async (name, value) => {
    console.log(name, value);
    const formData = new FormData();
    formData.append(name, value);
    formData.append("agentId", userData.agent_id);

    try {
      const response = await axios.post(
        "https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/image.php",
        formData,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.status === "success") {
        Swal.fire({
          // imageUrl: Success,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: `${response.data.message}`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          // navigate(0);
          setIsUpdated(!isUpdated);
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleChangePassword = async (e) => {
    e.preventDefault()
    const body = {
      agentId: userData?.agent_id,
      oldPassword: userInfo?.oldPassword,
      newPassword: userInfo?.newPassword,
    };

    if (userInfo?.confirmPassword !== userInfo?.newPassword) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Passoword doesn't match`,
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Ok",
      })
      handlePasswordClose();
      return
    }

    try {
      await axios
        .post(
          "https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/password.php",
          JSON.stringify(body),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          if (res.data.status === "success") {
            Swal.fire({
              icon: "success",
              imageAlt: "Custom image",
              title: "Password updates sucessfully",
              text: res.data.message,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            })
            handlePasswordClose();
          } else {
            Swal.fire({
              icon: "error",
              imageAlt: "Custom image",
              title: "Error",
              text: `${res.data.message}`,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            })
            handlePasswordClose();
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Box>
        <UserHeader />
        <Box sx={{ margin: "0 auto", mt: "-7vh", width: "90%" }}>
          <Box
            sx={{
              background: "var(--white)",
              py: "10px",
              px: "15px",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "18px !important",
                fontFamily: "poppins",
              }}
            >
              My Account
            </Typography>

            <Box>
              {/* personal information */}
              <Typography sx={{ my: "15px" }}>Personal Information</Typography>
              <Box >
                <Grid container spacing={3} className="passengerInput1">
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px" }}>
                      <span style={{ color: "black", fontSize: "14px" }}>Name:</span>
                      <span style={{ fontSize: "16px", fontWeight: "500", color: "var(--primary-color)" }}>{userInfo?.name}</span>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px" }}>
                      <span style={{ color: "black", fontSize: "14px" }}>Phone:</span>
                      <span style={{ fontSize: "16px", fontWeight: "500", color: "var(--primary-color)" }}>{userInfo?.phoneNumber}</span>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px" }}>
                      <span style={{ color: "black", fontSize: "14px" }}>Company Name:</span>
                      <span style={{ fontSize: "16px", fontWeight: "500", color: "var(--primary-color)" }}>{userInfo?.companyName}</span>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px" }}>
                      <span style={{ color: "black", fontSize: "14px" }}>Company Address:</span>
                      <span style={{ fontSize: "16px", fontWeight: "500", color: "var(--primary-color)" }}>{userInfo?.companyAddress}</span>
                    </Box>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <Box
                      sx={{ display: "flex", alignItems: "end", height: "100%" }}
                    >
                      <Button
                        type="submit"
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "400",
                          fontSize: "14px",
                          textTransform: "capitalize",
                          borderRadius: "3px",
                          background: "var(--mate-black)",
                          color: "#FFFFFF",
                          width: "100%",
                          height: "33px",
                          "&:hover": {
                            background: "var(--mate-black)",
                          },
                        }}
                        onClick={() => handleClickProfileOpen()}
                      >
                        Click To Update
                      </Button>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* Email and password information */}
              <Box sx={{ mt: "40px" }}>
                <Typography sx={{ my: "15px" }}>Login Information</Typography>
                <Grid container spacing={3} className="passengerInput1">
                  <Grid item xs={12} container spacing={3}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px" }}>
                        <span style={{ color: "black", fontSize: "14px" }}>Email:</span>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "var(--primary-color)" }}>{userInfo?.email}</span>
                      </Box>
                    </Grid>
                    {/* <Grid item xs={12} sm={6} md={6} lg={6}>
                      <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px" }}>
                        <span style={{ color: "black", fontSize: "14px" }}>Password:</span>
                        <span style={{ fontSize: "16px", fontWeight: "500", color: "var(--primary-color)" }}>{userInfo?.password}</span>
                      </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={6} md={6} lg={4}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "end",
                          height: "100%",
                        }}
                      >
                        <Button
                          onClick={handleClickPasswordOpen}
                          sx={{
                            fontFamily: "poppins",
                            fontWeight: "400",
                            fontSize: "14px",
                            textTransform: "capitalize",
                            borderRadius: "3px",
                            background: "var(--mate-black)",
                            color: "#FFFFFF",
                            width: "100%",
                            height: "33px",
                            "&:hover": {
                              background: "var(--mate-black)",
                            },
                          }}
                        >
                          Click To Update
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {/* Image information */}
              <Box sx={{ mt: "40px" }}>
                <Grid container spacing={3} className="passengerInput1" >
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {!userInfo.staffId && (
                      <Box>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px", mb: "10px", width: "100%" }}>
                          <span style={{ color: "black", fontSize: "14px" }}>Company Logo</span>
                        </Box>
                        <label
                          htmlFor="companylogo"
                          style={{
                            width: "100%",
                            height: "100%",
                            borderRadius: "10px",
                            overflow: "hidden",
                          }}
                        >
                          <input
                            id="companylogo"
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, image/webp"
                            onChange={(e) =>
                              handleUpdateImage(
                                "companyImage",
                                e.target.files[0]
                              )
                            }
                            style={{
                              height: "30%",
                              width: "70%",
                              display: "none",
                            }}
                          />
                          {userData?.agent_comp_img ? (
                            <Box sx={{ width: {
                              xs:"100%",
                              sm:"100%",
                              md:"450px"
                            } ,height: "200px", bgcolor: "#E1F5F0", borderRadius: "3px", }}>
                              <img
                                src={`${userData?.agent_comp_img
                                  }?t=${new Date().getTime()}`}
                                alt="companylogo"
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  objectFit: "contain",
                                  cursor: "pointer"
                                }}
                              />
                            </Box>

                          ) : (
                            <Skeleton
                              variant="rectangular"
                              sx={{
                                width: {
                                  md: "400px",
                                  sm: "200px",
                                  xs: "100%",
                                },
                                height: {
                                  md: "150px",
                                  sm: "150px",
                                  xs: "150px",
                                },
                              }}
                            />
                          )}
                        </label>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "10px",
                            left: "10px",
                            display: "flex",
                            gap: "5px",
                          }}
                        >
                          <Tooltip title="Click To Preview">
                            <RemoveRedEyeIcon
                              style={{
                                padding: "3px 4px",
                                borderRadius: "3px",
                                color: "var(--white)",
                                backgroundColor: "var(--green)",
                                // display: companyLogo ? "block" : "none",
                              }}
                            // onClick={() => {
                            //   handleOpenModal();
                            // }}
                            />
                          </Tooltip>
                        </Box>

                        <Typography
                          sx={{
                            marginTop: "10px",
                            color: "var(--primary-color)",
                            fontWeight: 400,
                            fontSize: "12px",
                          }}
                        >
                          *Logo must be below 2MB and (png or jpg) format
                        </Typography>

                      </Box>
                    )}
                    {/* </Box> */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    {!userInfo.staffId && (
                      <>
                        <Box sx={{ display: "flex", alignItems: "center", gap: "10px", bgcolor: "#FBEA78", pl: "10px", py: "5px", borderRadius: "3px", mb: "10px", width: "100%" }}>
                          <span style={{ color: "black", fontSize: "14px" }}>TIN Certificate</span>
                        </Box>
                        <Grid item>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              position: "relative",
                            }}
                          >
                            <label
                              htmlFor="NID"
                              style={{
                                width: "100%",
                                height: "100%",
                                // border: user?.agent_comp_img
                                //   ? "2px solid var(--secondary-color)"
                                //   : "none",
                                overflow: "hidden",
                              }}
                            >
                              <input
                                id="NID"
                                type="file"
                                accept="image/png, image/jpeg, image/jpg, image/webp"
                                onChange={(e) =>
                                  handleUpdateImage("tinImage", e.target.files[0])
                                }
                                style={{
                                  height: "100%",
                                  width: "100%",
                                  display: "none",
                                }}
                              />
                              {userData?.agent_tin_img ? (
                                <Box sx={{width: {
                                  xs:"100%",
                                  sm:"100%",
                                  md:"450px"
                                } , height: "200px", bgcolor: "#E1F5F0 ", borderRadius: "3px", }}>
                                  <img
                                    src={`${userData?.agent_tin_img
                                      }?t=${new Date().getTime()}`}
                                    alt="companylogo"
                                    style={{
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "contain",
                                      cursor: "pointer"
                                    }}
                                  />
                                </Box>

                              ) : (
                                <Skeleton
                                  variant="rectangular"
                                  sx={{
                                    width: {
                                      md: "400px",
                                      sm: "200px",
                                      xs: "100%",
                                    },
                                    height: {
                                      md: "400px",
                                      sm: "150px",
                                      xs: "150px",
                                    },
                                  }}
                                />
                              )}
                            </label>
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: "10px",
                                left: "10px",
                                display: "flex",
                                gap: "5px",
                              }}
                            >
                              <Tooltip title="Click To Preview">
                                <RemoveRedEyeIcon
                                  style={{
                                    padding: "3px 4px",
                                    borderRadius: "3px",
                                    color: "var(--white)",
                                    backgroundColor: "var(--green)",
                                    // display: companyLogo ? "block" : "none",
                                  }}
                                  onClick={() => {
                                    // handleOpenModal();
                                    // setPreviewImage(
                                    //   !user?.agent_comp_img !== ""
                                    //     ? `https://api.rtc.flyfarint.com/asset/Agents/RTCA1012/MyAccountInfo/tinImagRTCA1012.png`
                                    //     : ""
                                    // );
                                  }}
                                />
                              </Tooltip>
                            </Box>
                          </Box>
                        </Grid>


                      </>

                    )}
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Modal
              open={openModal}
              onClose={handleCloseModal}
              aria-labelledby="Image-Modal"
              aria-describedby="Image-Modal-Description"
            >
              <Box sx={modalStyle}>
                <img
                  src={previewImage}
                  alt="previewImage"
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Modal>
          </Box>
        </Box>
      </Box >

      <Dialog
        open={profileOpen}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleProfileClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="md"
        sx={{
          height: { xs: "100vh", sm: "100vh", md: "100vh" },
        }}
      >
        <DialogTitle sx={{ color: "var(--primary-color)" }}>
          Update Personal Information
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <form onSubmit={handleUpdateInfo}>
              <Grid container py={4} spacing={3}>
                <Grid item xs={12} md={4}>
                  <Box>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Name
                    </label>
                    <input
                      type="text"
                      name="name"
                      id=""
                      placeholder="Enter Your Name"
                      value={userInfo?.name}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          name: e.target.value,
                        });
                      }}
                      style={{
                        width: "100%",
                        paddingLeft: "0",
                        borderBottom: "1px solid #2156A6",
                        fontSize: "12px",
                        paddingBottom: "5px",
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Phone Number
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={userInfo?.phoneNumber}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          phoneNumber: e.target.value,
                        });
                      }}
                      id=""
                      placeholder="Phone Number"
                      style={{
                        width: "100%",
                        paddingLeft: "0",
                        borderBottom: "1px solid #2156A6",
                        fontSize: "12px",
                        paddingBottom: "5px",
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Company Name
                    </label>
                    <input
                      type="text"
                      name="phone"
                      value={userInfo?.companyName}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          companyName: e.target.value,
                        });
                      }}
                      id=""
                      placeholder="Compnay Name"
                      style={{
                        width: "100%",
                        paddingLeft: "0",
                        borderBottom: "1px solid #2156A6",
                        fontSize: "12px",
                        paddingBottom: "5px",
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Company Address
                    </label>
                    <input
                      type="text"
                      name="city"
                      value={userInfo?.companyAddress}
                      onChange={(e) => {
                        setUserInfo({
                          ...userInfo,
                          companyAddress: e.target.value,
                        });
                      }}
                      id=""
                      placeholder="Enter Your Address"
                      style={{
                        width: "100%",
                        paddingLeft: "0",
                        borderBottom: "1px solid #2156A6",
                        fontSize: "12px",
                        paddingBottom: "5px",
                        display: "inline-block",
                        backgroundColor: "transparent",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Button
                sx={{
                  textTransform: "none",
                  bgcolor: "#444542",
                  color: "white",
                  width: "200px",
                  ":hover": {
                    bgcolor: "#444542",
                  },
                }}
                type="submit"
                onClick={handleProfileClose}
              >
                Update
              </Button>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>

      <Dialog
        open={passwordOpen}
        // open={true}
        TransitionComponent={Transition}
        keepMounted
        onClose={handlePasswordClose}
        aria-describedby="alert-dialog-slide-description"
        maxWidth="lg"
        sx={{
          height: { xs: "100vh", sm: "100vh", md: "100vh" },
        }}
      >
        <DialogTitle sx={{ color: "var(--primary-color)" }}>
          Update Password{" "}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <form onSubmit={handleChangePassword}>
              <Grid container py={4} spacing={3}>
                <Grid item xs={12} md={6} lg={6}>
                  <Box sx={{ width: "500px" }}>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Old Password
                    </label>
                    <Box sx={{ display: "flex", position: "relative" }}>
                      <input
                        type={oldShow ? "text" : "password"}
                        name="oldPassword"
                        id=""
                        value={userInfo?.oldPassword}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            oldPassword: e.target.value,
                          });
                        }}
                        placeholder="Enter Old Password"
                        style={{
                          width: "100%",
                          paddingLeft: "0",
                          borderBottom: "1px solid #2156A6",
                          fontSize: "12px",
                          paddingBottom: "5px",
                          display: "inline-block",
                          backgroundColor: "transparent",
                        }}
                      />
                      {oldShow ? (
                        <VisibilityIcon
                          sx={{
                            position: "absolute",
                            right: "0px",
                            cursor: "pointer",
                            color: "#2156A6",
                          }}
                          onClick={() => setOldShow(false)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{
                            position: "absolute",
                            right: "0px",
                            cursor: "pointer",
                            color: "#2156A6",
                          }}
                          onClick={() => setOldShow(true)}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box sx={{ width: "500px" }}>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      New Password
                    </label>
                    <Box sx={{ display: "flex", position: "relative" }}>
                      <input
                        type={newShow ? "text" : "password"}
                        name="newPassword"
                        id=""
                        value={userInfo?.newPassword}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            newPassword: e.target.value,
                          });
                        }}
                        placeholder="Enter New Passowrd"
                        style={{
                          width: "100%",
                          paddingLeft: "0",
                          borderBottom: "1px solid #2156A6",
                          fontSize: "12px",
                          paddingBottom: "5px",
                          display: "inline-block",
                          backgroundColor: "transparent",
                        }}
                      />
                      {newShow ? (
                        <VisibilityIcon
                          sx={{
                            position: "absolute",
                            right: "0px",
                            cursor: "pointer",
                            color: "#2156A6",
                          }}
                          onClick={() => setNewShow(false)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{
                            position: "absolute",
                            right: "0px",
                            cursor: "pointer",
                            color: "#2156A6",
                          }}
                          onClick={() => setNewShow(true)}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={6}>
                  <Box sx={{ width: "500px" }}>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Confirm New Password
                    </label>
                    <Box sx={{ display: "flex", position: "relative" }}>
                      <input
                        type={confirmShow ? "text" : "password"}
                        name="confirmPassword"
                        id=""
                        value={userInfo?.confirmPassword}
                        onChange={(e) => {
                          setUserInfo({
                            ...userInfo,
                            confirmPassword: e.target.value,
                          });
                        }}
                        placeholder="Confirm New Password"
                        style={{
                          width: "100%",
                          paddingLeft: "0",
                          borderBottom: "1px solid #2156A6",
                          fontSize: "12px",
                          paddingBottom: "5px",
                          display: "inline-block",
                          backgroundColor: "transparent",
                        }}
                      />
                      {confirmShow ? (
                        <VisibilityIcon
                          sx={{
                            position: "absolute",
                            right: "0px",
                            cursor: "pointer",
                            color: "#2156A6",
                          }}
                          onClick={() => setConfirmShow(false)}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{
                            position: "absolute",
                            right: "0px",
                            cursor: "pointer",
                            color: "#2156A6",
                          }}
                          onClick={() => setConfirmShow(true)}
                        />
                      )}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Button
                sx={{
                  textTransform: "none",
                  bgcolor: "#444542",
                  color: "white",
                  width: "150px",
                  ":hover": {
                    bgcolor: "#444542",
                  },
                }}
                type="submit"
              // onClick={handlePasswordClose}
              >
                Update
              </Button>
            </form>
          </DialogContentText>
        </DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
};

export default Account;
