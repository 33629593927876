import { Box, Grid, Typography } from "@mui/material";
const allBanks = [
  "Select Bank Account",
  "AB Bank Ltd.",
  "Agrani Bank",
  "Al-Arafah Islami Bank Ltd.",
  "Ansar VDP Unnayan Bank",
  "BASIC Bank",
  "BRAC Bank Ltd.",
  "Bangladesh Commerce Bank Ltd.",
  "Bangladesh Development Bank",
  "Bangladesh Krishi Bank",
  "Bank Al-Falah",
  "Bank Asia Ltd.",
  "CITI Bank NA",
  "Commercial Bank of Ceylon",
  "Community Bank Bangladesh Limited",
  "Dhaka Bank Ltd.",
  "Dutch Bangla Bank Ltd.",
  "EXIM Bank Ltd.",
  "Eastern Bank Ltd.",
  "First Security Islami Bank Ltd.",
  "Global Islamic Bank Ltd.",
  "Grameen Bank",
  "HSBC",
  "Habib Bank Ltd.",
  "ICB Islamic Bank",
  "IFIC Bank Ltd.",
  "Islami Bank Bangladesh Ltd.",
  "Jamuna Bank Ltd.",
  "Janata Bank",
  "Jubilee Bank",
  "Karmashangosthan Bank",
  "Meghna Bank Ltd.",
  "Mercantile Bank Ltd.",
  "Midland Bank Ltd.",
  "Modhumoti Bank Ltd.",
  "Mutual Trust Bank Ltd.",
  "NCC Bank Ltd.",
  "NRB Bank Ltd.",
  "NRB Commercial Bank Ltd.",
  "National Bank Ltd.",
  "National Bank of Pakistan",
  "One Bank Ltd.",
  "Padma Bank Ltd.",
  "Palli Sanchay Bank",
  "Premier Bank Ltd.",
  "Prime Bank Ltd.",
];

const BankTab = ({ handleInputChange, setAttachment }) => {
  return (
    <Box>
      <Box className="passengerInput1">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography>Deposit From</Typography>
            <Box style={{ marginTop: "5px" }}>
              <select
                required={true}
                name="sender"
                onChange={handleInputChange}
              >
                {allBanks.map((bank, i) => (
                  <option key={i} value={bank}>
                    {bank}
                  </option>
                ))}
              </select>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Deposit To</Typography>
            <Box style={{ marginTop: "5px" }}>
              <select
                required={true}
                name="receiver"
                onChange={handleInputChange}
              >
                {allBanks?.map((bank, i) => (
                  <option key={i} value={bank}>
                    {bank}
                  </option>
                ))}
              </select>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Transaction Date</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                required={true}
                name="transactionDate"
                onChange={handleInputChange}
                type="date"
                placeholder="Amount"
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Transaction ID</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                type="text"
                placeholder="Transaction ID"
                required={true}
                name="transactionId"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Amount</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                type="text"
                placeholder="Amount"
                required={true}
                name="amount"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item md={4}>
            <Typography>Choose Passport Copy</Typography>
            <Box style={{ marginTop: "5px" }} className="input-File1">
              <input
                style={{
                  backgroundColor: "#2564B8",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px",
                  boxSizing: "border-box",
                }}
                className="customFileType"
                type="file"
                title="Choose a Image png and jpg please"
                accept="image/*"
                required={true}
                name="attachment"
                onChange={(e) => setAttachment(e.target.files[0])}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default BankTab;
