import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Calendar, DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import format from "date-fns/format";
import { styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { AiOutlineSwap } from "react-icons/ai";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import secureLocalStorage from "react-secure-storage";
import { addDays } from "date-fns";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import flightData from "../flightData";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import Popup from "../Header/Popup";

import InitialData from "../../InitialFlightData";
import moment from "moment";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #003566",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const Roundway = ({
  click,
  setClick,
  tripType,
  iconColor,
  bgColor,
  borderColor,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  fromSearchText,
  setFromSearchText,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  returningDate,
  setReturningDate,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
  from,
  setFrom,
  to,
  setTo,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  agentId,
}) => {
  // const data = flightData; // json data from flight Data

  //  show the form data when click input field

  const [fromSuggest, setFromSuggest] = useState(InitialData);
  const [toSuggest, setToSuggest] = useState(InitialData);
  let [book, setBook] = useState(false);

  const navigate = useNavigate();

  const location = useLocation();

  const [users, setUsers] = useState("");

  useEffect(() => {
    const users = secureLocalStorage.getItem("user-info");
    if (users) {
      setUsers(users);
    }
  }, []);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [openReturnDate, setOpenReturnDate] = useState(false);
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() + 1);

  const [data, setData] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    const url = `https://api.flyfarint.com/v.1.0.0/AirMaterials/airports.php?keyword=${searchKeyword}`;
    console.log(url);
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setData(data);
      });
  }, [searchKeyword]);

  const formOnChange = (e) => {
    setOpen(false);
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = flightData.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = flightData.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setFromSuggest(suggestion);
      }
    } else {
      setFromSuggest(InitialData);
    }
  };

  const fromSuggestedText = (name, code, address) => {
    setFromSendData(code);
    setFromSearchText(`${name} (${code})`);
    setFromSuggest([]);
    setfaddress(address);
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(true);
    setData(InitialData);
  };

  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    if (searchvalue.length > 2) {
      const suggestion = flightData.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setToSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = flightData.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setToSuggest(suggestion);
      }
    } else {
      setToSuggest(InitialData);
    }
  };

  const toSuggestedText = (name, code, address) => {
    setToSendData(code);
    setToSearchText(`${name} (${code})`);
    setToSuggest([]);
    setToAddress(address);
    setOpenTo(false);
    setTimeout(() => setOpenDate(true), 200);
    setData(InitialData);
  };

  const fromGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {data.length !== 0 ? (
            data.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "#fff",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "#D1E9FF",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.cityName}, ${item.countryName}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#003566",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.cityName}, {item.countryName}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "#DC143C",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const toGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {data.length !== 0 ? (
            data.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "5px",
                    backgroundColor: "#fff",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "#D1E9FF",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      toSuggestedText(
                        ` ${item.name}`,
                        `${item.code}`,
                        `${item.cityName}, ${item.countryName}`
                      )
                    } //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#003566",
                          display: "block",
                          textAlign: "left",
                        }}
                      >
                        {item.cityName}, {item.countryName}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          color: "#999",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          paddingRight: "10px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "#DC143C",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  // SearchingField End

  const [open, setOpen] = useState(false);

  const handleClickAway = () => {
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpenReturnDate(false);
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  async function handleSearch(e) {
    e.preventDefault();
    //todo: setChangeState for reTrigger useEffect
    setChangeState((prev) => !prev);
    // setClick(true);
    secureLocalStorage.setItem("search-data", {
      faddress,
      toAddress,
      departureDate: format(new Date(from), "dd MMM yy"),
      returningDate: format(new Date(to), "dd MMM yy"),
      adultCount,
      childCount,
      infant,
      tripType,
      fromSendData,
      toSendData,
      fromSearchText,
      toSearchText,
      className,
    });

    if (users) {
      navigate("/dashboard/roundsearchresult", {
        state: {
          faddress,
          toAddress,
          departureDate: format(new Date(from), "dd MMM yy"),
          returningDate: format(new Date(to), "dd MMM yy"),
          adultCount,
          childCount,
          infant,
          tripType,
          fromSendData,
          toSendData,
          fromSearchText,
          toSearchText,
          className,
          changeState,
          data: flightData,
        },
      });
    } else {
      Swal.fire({
        title: "Please, Log in first",
        text: "Please log in first to see all the flights.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          setBook(true);
        }
      });
      setClick(false);
    }
  }

  const handleSelect = (date) => {
    setFrom(date);
    setChangeFrom(true);
    setOpenDate(false);
    setTimeout(() => setOpen(true), 200);
    setOpenReturnDate(true);
  };
  const handleSelectReturn = (date) => {
    setTo(date);
    setChangeFrom(true);
    setOpenDate(false);
    setOpen(false);
    setOpenReturnDate(false);
  };

  const ranges = useMemo(() => {
    return [
      {
        startDate: from,
        endDate: to,
        key: "selection",
      },
    ];
  }, [from, to]);

 

  return (
    <>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box style={{ position: "relative", width: "100%",marginTop:"10px" }}>
          <form onSubmit={handleSearch}>
            {/* //todo: Search Button */}

            <Button
              type="submit"
              className="shine-effect"
              sx={{
                fontSize: "15px",
                height: { lg: "45%", md: "100%", sm: "100%", xs: "100%" },

                width: {
                  lg: "16%",
                  md: "90%",
                  sm: "100%",
                  xs: "100%",
                },
                position: "absolute",
                right: location.pathname === "/" ? "0px" : "50px",
                bottom: "95px",
                mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },
                backgroundColor: "var(--secondary-color)",
                color: "var(--white)",
                textTransform: "capitalize",
                gap: "5px",
                display: {
                  lg: "flex",
                  md: "flex",
                  sm: "none",
                  xs: "none",
                },
                "&:hover": {
                  backgroundColor: "var(--primary-color)",
                  cursor: "pointer",
                },
              }}
            >
              <Box>{click ? "Wait..." : "Search"}</Box>
              <AirplanemodeActiveIcon
                sx={{ fontSize: "23px", transform: "rotate(45deg)" }}
              />
            </Button>

            <Grid
              sx={{
                height: "fit-content",
                width: "100%",
                pt: "0px",
                mt: "0px",
              }}
              container
              rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 1 }}
              columnSpacing={0.1}
            >
              {/* //todo: Departure City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={2.8}
                lg={2.8}
                sx={{
                  position: "relative",
                  pl: "0px",
                  height: "82px",
                  mx: location?.pathname === "/" ? "5px" : "0px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                  onClick={() => {
                    setOpenFrom((prev) => !prev);
                    setOpenTo(false);
                    setOpenDate(false);
                    setOpen(false);
                    setOpenReturnDate(false);
                    setData(InitialData);
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "var(--light-gray)",
                        }}
                        mb={0.5}
                      >
                        Departure From
                      </Typography>
                      <Typography
                        style={{
                          color: "var(--mateBlack)",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                        mt={0.5}
                      >
                        {faddress.split(",")[0]}
                      </Typography>
                      {faddress?.split(",")[0] === toAddress?.split(",")[0] && (
                        <Stack
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          <Alert
                            icon={<ErrorOutlineIcon fontSize="inherit" />}
                            severity="error"
                            sx={{ fontSize: "11px" }}
                          >
                            Can't choose same place!
                          </Alert>
                        </Stack>
                      )}
                    </Box>
                    <Box style={{ width: "100%" }}>
                      <Tooltip title={fromSearchText} arrow>
                        <span
                          style={{
                            width: "100%",
                            color: "var(--light-gray)",
                            fontSize: "13px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {fromSearchText.length > 35
                            ? `${fromSearchText.substring(0, 35)}...`
                            : fromSearchText}
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>

                {openFrom && (
                  <Box
                    style={{
                      position: "absolute",
                      top: "105%",
                      left: "0",
                      right: "0",
                      width: "100%",
                      backgroundColor: "var(--white)",
                      height: "fit-content",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "5px",
                      zIndex: "999",
                      padding: "5px 5px 0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#003566",
                        zIndex: 10,
                      }}
                      backgroundColor="#fff"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        placeholder="Search a airport..."
                        className="customPlaceholder"
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{fromGetSuggetion()}</Box>
                  </Box>
                )}
              </Grid>

              {/* //todo: Arrival City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={2.8}
                lg={2.8}
                sx={{
                  position: "relative",
                  pl: "10px",
                  height: "82px",
                  mx: location?.pathname === "/" ? "10px" : "0px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                  onClick={() => {
                    setOpenFrom(false);
                    setOpenTo((prev) => !prev);
                    setOpenDate(false);
                    setOpen(false);
                    setOpenReturnDate(false);
                    setData(InitialData);
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "var(--light-gray)",
                        }}
                        mb={0.5}
                      >
                        Arrival To
                      </Typography>
                      <Typography
                        style={{
                          color: "var(--mateBlack)",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}
                        mt={0.5}
                      >
                        {toAddress.split(",")[0]}
                      </Typography>
                      <span>
                        {faddress?.split(",")[0] ===
                          toAddress?.split(",")[0] && (
                          <Stack
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "0",
                              width: "100%",
                            }}
                          >
                            <Alert
                              icon={<ErrorOutlineIcon fontSize="inherit" />}
                              severity="error"
                              sx={{ fontSize: "11px" }}
                            >
                              Can't choose same place!
                            </Alert>
                          </Stack>
                        )}
                      </span>
                    </Box>

                    <Box style={{ width: "100%" }}>
                      <Tooltip title={toSearchText} arrow>
                        <span
                          style={{
                            width: "100%",
                            color: "var(--light-gray)",
                            fontSize: "13px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {toSearchText.length > 35
                            ? `${toSearchText.substring(0, 35)}...`
                            : toSearchText}
                        </span>
                      </Tooltip>
                    </Box>
                  </Box>
                </Box>
                {openTo && (
                  <Box
                    style={{
                      position: "absolute",
                      top: "105%",
                      left: "0",
                      width: "100%",
                      backgroundColor: "var(--white)",
                      border: "1px solid var(--primary-color",
                      height: "fit-content",
                      borderRadius: "5px",
                      zIndex: "999",
                      padding: "5px 5px 0",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "var(--secondary-color)",
                        zIndex: 10,
                      }}
                      backgroundColor="var(--white)"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={(e) => setSearchKeyword(e.target.value)}
                        className="customPlaceholder"
                        placeholder="Search a airport..."
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{toGetSuggetion()}</Box>
                  </Box>
                )}
              </Grid>

              {/* //todo:Departure Date */}
              <Grid
                item
                xs={12}
                sm={12}
                md={2.8}
                lg={2.8}
                sx={{
                  position: "relative",
                  pl: "10px",
                  height: "82px",
                  mx: location?.pathname === "/" ? "10px" : "0px",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                  onClick={() => {
                    setTimeout(() => setOpenDate((prev) => !prev), 200);
                    setOpenFrom(false);
                    setOpenTo(false);
                    setOpen(false);
                    setOpenReturnDate(false);
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "var(--light-gray)",
                        }}
                        mb={0.5}
                      >
                        Departure Date
                      </Typography>

                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            color: "var(--mateBlack)",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >{`${format(new Date(from), "dd MMM yy")}`}</span>

                        <CalendarMonthIcon
                          style={{ color: "var(--secondary-color)" }}
                        />
                      </Box>
                      <span
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "13px",
                        }}
                      >
                        {moment(from).format("dddd")}
                      </span>
                    </Box>
                  </Box>
                </Box>
                {openDate && (
                  <Box>
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(from)}
                      onChange={handleSelect}
                      months={1}
                      direction="horizontal"
                      minDate={new Date()}
                      maxDate={maxDate}
                      className={"dashboard-calendar"}
                    />
                  </Box>
                )}
              </Grid>

              {/* //todo:return Date */}
              <Grid
                item
                xs={12}
                sm={12}
                md={2.7}
                lg={2.7}
                sx={{
                  position: "relative",
                  pl: "10px",
                  height: "82px",
                  mx: location?.pathname === "/" ? "10px" : "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                  onClick={() => {
                    // setTimeout(() => setToDate((prev) => !prev), 200);
                    setTimeout(() => setOpenReturnDate((prev) => !prev), 200);
                    setOpenFrom(false);
                    setOpenTo(false);
                    setOpen(false);
                    setOpenDate(false);
                  }}
                >
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          fontSize: "12px",
                          fontWeight: "400",
                          color: "var(--light-gray)",
                        }}
                        mb={0.5}
                      >
                        Return Date
                      </Typography>

                      <Box
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            color: "var(--mateBlack)",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                        >{`${format(new Date(to), "dd MMM yy")}`}</span>

                        <CalendarMonthIcon
                          style={{ color: "var(--secondary-color)" }}
                        />
                      </Box>
                      <span
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "13px",
                        }}
                      >
                        {moment(to).format("dddd")}
                      </span>
                    </Box>
                  </Box>
                </Box>
                {openReturnDate && (
                  <Box>
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(from.getTime())}
                      onChange={handleSelectReturn}
                      months={1}
                      direction="horizontal"
                      minDate={new Date(from.getTime())}
                      maxDate={maxDate}
                      className={"dashboard-calendar"}
                    />
                  </Box>
                )}

                <Button
                  type="submit"
                  className="shine-effect"
                  sx={{
                    fontSize: "15px",
                    width: {
                      lg: "16%",
                      md: "90%",
                      sm: "100%",
                      xs: "100%",
                    },

                    right: "0px",
                    bottom: "40px",
                    mt: { lg: "0px", md: "0px", sm: "10px", xs: "45px" },
                    backgroundColor: "var(--secondary-color)",
                    color: "white",
                    textTransform: "capitalize",
                    gap: "5px",
                    display: {
                      lg: "none",
                      md: "none",
                      sm: "flex",
                      xs: "flex",
                    },
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box>{click ? "Wait..." : "Search"}</Box>
                  <AirplanemodeActiveIcon
                    sx={{ fontSize: "23px", transform: "rotate(45deg)" }}
                  />
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </ClickAwayListener>
      {book ? <Popup book={true} setBook={setBook} /> : ""}
    </>
  );
};
export default Roundway;
