import { Box, Button, Stack } from "@mui/material";
import React from "react";
// import FareDetails from "../SingleFlight/FareDetails";
// import FarePolicy from "../FarePolicy/FarePolicy";
// import Baggage from "../SingleFlight/Baggage";
// import SingleFlighDetails from "../SingleFlight/SingleFlighDetails";
import { useState } from "react";
import FarePolicy from "../FarePolicy/FarePolicy";
import SingleFlighDetails from "../SingleFlight/SingleFlighDetails";
import FareDetails from "../SingleFlight/FareDetails";
import Baggage from "../SingleFlight/Baggage";

const OnewayFlightDetails = ({ pricebreakdown, segments, flightData }) => {
  const [isActive, setIsActive] = useState(1);
  return (
    <Box
      sx={{
        borderTop: "1px solid #ACA6A6",
        padding: "20px 0",
        mt: "20px",
      }}
    >
      <Stack direction="row" spacing={2}>
        <Box>
          <Button
            onClick={() => setIsActive(1)}
            sx={{
              padding: "10px 20px",
              bgcolor: `${isActive === 1 ? "var(--secondary-color)" : ""}`,
              color: `${isActive === 1 ? "var(--white)" : "var(--secondary-color)"}`,
            }}
          >
            Flight Details
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => setIsActive(2)}
            sx={{
              padding: "10px 20px",
              bgcolor: `${isActive === 2 ? "var(--secondary-color)" : ""}`,
              color: `${isActive === 2 ? "var(--white)" : "var(--secondary-color)"}`,
            }}
          >
            Fare Details
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => setIsActive(3)}
            sx={{
              padding: "10px 20px",
              bgcolor: `${isActive === 3 ? "var(--secondary-color)" : ""}`,
              color: `${isActive === 3? "var(--white)" : "var(--secondary-color)"}`,
            }}
          >
            Fare Policy
          </Button>
        </Box>
        <Box>
          <Button
            onClick={() => setIsActive(4)}
            sx={{
              padding: "10px 20px",
              bgcolor: `${isActive === 4 ? "var(--secondary-color)" : ""}`,
              color: `${isActive === 4 ? "var(--white)" : "var(--secondary-color)"}`,
            }}
          >
            Baggage
          </Button>
        </Box>
      </Stack>
      {/*
                Flight Details Navigation button area End 
           */}

      {/*
                Flight Details Area area Start 
           */}

      <Box>
        <Box
          sx={{
            display: `${isActive === 1 ? "block" : "none"}`,
          }}
        >
          {segments.map((segment, i) => (
            <SingleFlighDetails
              segments={segments}
              key={i}
              index={i}
              segment={segment}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: `${isActive === 2 ? "block" : "none"}`,
          }}
        >
          <FareDetails pricebreakdown={pricebreakdown} />
        </Box>
        <Box
          sx={{
            display: `${isActive === 3 ? "block" : "none"}`,
          }}
        >
          <FarePolicy />
        </Box>
        <Box
          sx={{
            display: `${isActive === 4 ? "block" : "none"}`,
          }}
        >
          <Baggage flightData={flightData} pricebreakdown={pricebreakdown} />
        </Box>
      </Box>
    </Box>
  );
};

export default OnewayFlightDetails;
