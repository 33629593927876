import { Box, Container } from "@mui/material";
import React from "react";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import HomeSearchBox from "../../components/HomeSearchBox/HomeSearchBox";
import bgImg from "../../images/landingPage/SearchBox.png";
import bgImg1 from "../../imagesv2/logo/banner.svg";
import "./home.css";
import AboutUs from "./AboutUs/AboutUs";
import OurService from "./OurService/OurService";
import Contact from "./Contact/Contact";
import Map from "./Map/Map/Map";
import HeaderTitle from "./HeaderTitle/HeaderTitle";

const Home = () => {

 

  return (
    <Box>
      <HeaderTitle/>
      <Header />
      <Box sx={{mt:"10px"}}>
        <Box sx={{width:"85%",m:"0 auto"}}>
        <Box
          sx={{
            background: `url(${bgImg1}), #F9E663`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            width: "100%",
            borderRadius: "5px",
            display: "flex",
            flexDirection: "center",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Container
            className="home-banner"
            sx={{
            
              display: "flex",
              flexDirection: "center",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "0px",
              padding: {
                md: "50px 0px",
                sm: "20px 0px",
                xs: "10px 15px",
              },
            }}
          >
            <HomeSearchBox />
          </Container>
        </Box>
        </Box>
    
        <AboutUs />
        <OurService />
        <Contact />
        <Map />
        <Footer />
      </Box>
    </Box>
  );
};

export default Home;
