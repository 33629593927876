import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import GroupFareCard from "./GroupFareCard";

const GroupFareBookingDetails = () => {
  const [bookingData, setBookingData] = useState({});
  const location = useLocation();
  const { bookingId } = location.state;
  const navigate = useNavigate();

  useEffect(() => {
    const url = `https://api.rtc.flyfarint.com/v1.0/b2b/groupfare/index.php?bookingId=${bookingId}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        const {
          id,
          group_fare_booking_id,
          agent_id,
          pax_email,
          pax_phone,
          pax_name,
          pax_count,
          airlines,
          arrive_to_code,
          arrive_to_address,
          segment,
          status,
          travel_date,
          booked_at,
          gross_cost,
          group_fare_id,
          dept_code1,
          dept_name1,
          dept_address1,
          dept_code2,
          dept_name2,
          dept_address2,
          dept_time1,
          dept_time2,
          arrive_code1,
          arrive_name1,
          arrive_address1,
          arrive_code2,
          arrive_name2,
          arrive_address2,
          arrive_time1,
          arrive_time2,
          carrier_name1,
          carrier_code1,
          carrier_name_bangla1,
          carrier_name2,
          carrier_code2,
          carrier_name_bangla2,
          flight_num1,
          flight_num2,
          flight_code1,
          flight_code2,
          flight_name1,
          flight_name2,
          cabin1,
          cabin2,
          class1,
          class2,
          baggage1,
          baggage2,
          travel_time1,
          travel_time2,
          transit_time,
          total_seat,
          available_seat,
          gross_fare,
          created_at,
          deactivated,
        } = data?.group_fares;

        const updatedData = {
          agent_id,
          airlines,
          arrive_to_address,
          arrive_to_code,
          available_seat,
          booked_at,
          created_at,
          deactivated,
          gross_cost,
          group_fare_booking_id,
          group_fare_id,
          id,
          pax_count,
          pax_email,
          pax_name,
          pax_phone,
          segment,
          status,
          total_seat,
          transit_time,
          travel_date,
          gross_fare,
          segments: [
            {
              arrive_address: arrive_address1,
              arrive_code: arrive_code1,
              arrive_name: arrive_name1,
              arrive_time: arrive_time1,
              baggage: baggage1,
              cabin: cabin1,
              carrier_code: carrier_code1,
              carrier_name: carrier_name1,
              carrier_name_bangla: carrier_name_bangla1,
              class: class1,
              dept_address: dept_address1,
              dept_code: dept_code1,
              dept_name: dept_name1,
              dept_time: dept_time1,
              flight_code: flight_code1,
              flight_name: flight_name1,
              flight_num: flight_num1,
              travel_time: travel_time1,
            },
            {
              arrive_address: arrive_address2,
              arrive_code: arrive_code2,
              arrive_name: arrive_name2,
              arrive_time: arrive_time2,
              baggage: baggage2,
              cabin: cabin2,
              carrier_code: carrier_code2,
              carrier_name: carrier_name2,
              carrier_name_bangla: carrier_name_bangla2,
              class: class2,
              dept_address: dept_address2,
              dept_code: dept_code2,
              dept_name: dept_name2,
              dept_time: dept_time2,
              flight_code: flight_code2,
              flight_name: flight_name2,
              flight_num: flight_num2,
              travel_time: travel_time2,
            },
          ],
        };

        setBookingData({
          ...updatedData,
          segments: updatedData.segments.filter(
            (item) => !!item.arrive_code === true
          ),
        });
      });
  }, []);

 

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "90%", margin: "0 auto", mt: "-7vh" }}>
        <Box
          sx={{
            background: "var(--white)",
            py: "10px",
            px: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <Grid
            justifyContent={"space-between"}
            container
            // gap={1}
            sx={{ p: "15px", bgcolor: "re" }}
          >
            <Grid item lg={9}>
              <Typography
                sx={{
                  color: "#2156A6",
                  p: "10px",
                  pl: "25px",
                  fontWeight: 500,
                  fontSize: "15px",
                  bgcolor: "#a3c7ff3b",
                  borderRadius: "7px",
                  mb: "13px",
                }}
              >
                Flight Information
              </Typography>
              {bookingData?.segments?.map((item, i) => {
                return (
                  <GroupFareCard
                    transit_time={bookingData?.transit_time}
                    bookingData={item}
                  />
                );
              })}

              <TableContainer component={Paper}>
                <Table aria-label="simple table">
                  <TableHead sx={{ minWidth: "100%" }}>
                    <TableRow
                      sx={{ backgroundColor: "var(--secondary-color)" }}
                    >
                      <TableCell align="center">Passenger Name</TableCell>
                      <TableCell align="center">Passenger Email</TableCell>
                      <TableCell align="center">
                        Passenger Phone Number
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      sx={
                        {
                          // backgroundColor: `${(i + 1) % 2 !== 0 ? "#DEE8FF" : ""}`,
                        }
                      }
                    >
                      <TableCell align="center">
                        {bookingData.pax_name}
                      </TableCell>
                      <TableCell align="center">
                        {bookingData.pax_email}
                      </TableCell>
                      <TableCell align="center">
                        {bookingData.pax_phone}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid
              item
              xs={12}
              lg={2.75}
              bgcolor={"gren"}
              sx={{ height: "max-content" }}
            >
              <Box
                sx={{
                  bgcolor: "white",
                  mb: "13px",
                  pl: "18px",
                  p: "20px",
                  pt: "0",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "#2156A6",
                      fontWeight: "500",
                    }}
                  >
                    {bookingData?.status} <br />
                    {bookingData?.group_fare_id}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "#444542", mt: "10px" }}
                  >
                    Total Payable
                  </Typography>
                  <Typography
                    sx={{
                      color: "#444542",
                      fontSize: "24px",
                      fontWeight: "500",
                    }}
                  >
                    ৳ {Number(bookingData?.gross_cost).toLocaleString("en-IN")}
                  </Typography>
                </Box>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#444542",
                      fontWeight: "500",
                      mt: "14px",
                    }}
                  >
                    Per Paasenger
                  </Typography>
                  <Typography
                    variant="title-lg"
                    sx={{
                      color: "#444542",
                      fontWeight: "500",
                      mt: "14px",
                    }}
                  >
                    {Number(bookingData?.gross_fare).toLocaleString("en-IN")}{" "}
                    BDT
                  </Typography>
                </Stack>
                <Stack direction={"row"} justifyContent={"space-between"}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#444542",
                      fontWeight: "500",
                      mt: "14px",
                    }}
                  >
                    Pax Count
                  </Typography>
                  <Typography
                    variant="title-lg"
                    sx={{
                      color: "#444542",
                      fontWeight: "500",
                      mt: "14px",
                    }}
                  >
                    {bookingData?.pax_count}
                  </Typography>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupFareBookingDetails;
