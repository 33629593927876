import { Container, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Marquee from "react-fast-marquee";

const MarqueeShow = () => {
  const navigate = useNavigate();
  const [marqueText, setMarqueText] = useState([]);
  useEffect(() => {
    let url = `https://api.rtc.flyfarint.com/v1.0/admin/marquee/index.php?b2b`;
    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setMarqueText(data?.marquees);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);

  // console.log(marqueText?.title);
  return (
    <Box
      sx={{
        background: "var(--primary-color)",
        position: "fixed",
        left: "0",
        bottom: "0",
        width: "100%",
      }}
    >
      {marqueText?.length !== 0 ? (
        <Marquee
          direction="left"
          pauseOnHover={true}
          speed={80}
          gradientColor={"no"}
        >
          <Stack direction="row">
            {marqueText?.map((data) => (
              <Typography
                onClick={() => {
                  navigate("/dashboard/notice", {
                    state: data,
                  });
                }}
                key={data?.id}
                sx={{
                  color: "#FFFFFF",
                  opacity: 1,
                  cursor: "pointer",
                  padding: "5px 20px",
                  fontSize: { md: "14px", sm: "13px", xs: "13px" },
                }}
              >
                {data?.title},
              </Typography>
            ))}
          </Stack>
        </Marquee>
      ) : null}
    </Box>
  );
};

export default MarqueeShow;
