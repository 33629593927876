import { Box, Container, Typography, Grid, Button } from "@mui/material";
import React, { useState } from "react";
import AdminHeader from "../AdminHeader/AdminHeader";
import { Calendar, DateRangePicker } from "react-date-range";
import { format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function SegmentReport() {
  const [startDate, setStartDate] = useState(format(new Date(), "dd MMM yyyy"));
  const [endDate, setEndDate] = useState(format(new Date(), "dd MMM yyyy"));
  return (
    <Box>
      <AdminHeader />
      <Container
        sx={{
          mt: "-8vh",
          background: "white",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
        }}
      >
        <Box>
          <Grid container spacing={1}>
            <Grid item md={4}>
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "18px !important",
                  color: "var(--black)",
                  fontFamily: "poppins",
                  px: "14px",
                  py: "12px",
                }}
              >
                Segment Report
              </Typography>
            </Grid>
            <Grid item md={3.5}>
              <Typography sx={{ fontSize: "12px" }}>From</Typography>
              <Box sx={{ marginTop: "5px" }}>
                <input
                  onChange={(e) => setStartDate(e.target.value)}
                  type="date"
                  required
                />
              </Box>
            </Grid>
            <Grid item md={3.5}>
              <Typography sx={{ fontSize: "12px" }}>To</Typography>
              <Box sx={{ marginTop: "5px" }}>
                <input
                  onChange={(e) => setEndDate(e.target.value)}
                  type="date"
                  required
                />
              </Box>
            </Grid>
            <Grid item md={1}>
              <Button
                sx={{
                  fontWeight: "400",
                  fontSize: "12px",
                  textTransform: "capitalize",
                  borderRadius: "5px",
                  background: "#222222",
                  color: "#FFFFFF",
                  width: "50%",
                  mt: "1rem",
                  "&:hover": {
                    backgroundColor: "#222222",
                  },
                }}
                type="submit"
              >
                Proceed
              </Button>
            </Grid>
          </Grid>

          <Box className="balance-transaction" marginTop={"10px"}>
            <table>
              <thead>
                <tr>
                  <th>System</th>
                  <th>Tickted Count</th>
                  <th>Tickted Amount</th>
                  <th>Segment Count</th>
                  <th>Segment Rate</th>
                  <th>Profit Amount</th>
                </tr>
              </thead>

              {/* {pegination?.map((data) => (
                <tr>
                  <td>hgviuyg</td>
                  <td>
                    <span
                      style={{
                        backgroundColor: "#ed1c24",
                        padding: "6px 24px",
                        color: "#fff",
                      }}
                    >
                      Purchase
                    </span>
                  </td>

                  <td>
                  546454
                  </td>
                  <td>{data?.details || "-"}</td>
                  <td>
             64558
                    Tk
                  </td>

                  <td>
                 654654  Tk
                  </td>
                  <td>12789 Tk</td>
                  <td>12789 Tk</td>
                </tr>
              ))} */}
              <tbody>
                <tr>
                  <td data-column='System'>Flyhub</td>
                  <td data-column='Tickted Count'>3</td>
                  <td data-column='Tickted Amount'>41654541/-</td>
                  <td data-column='Segment Count'>2</td>
                  <td data-column='Segment Rate'> 324154Tk</td>
                  <td data-column='Profit Amount'> 231354654Tk</td>
                </tr>
              </tbody>
            </table>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default SegmentReport;
