import {
  Box,
  InputLabel,
  MenuItem,
  Typography,
  TextField,
  Button,
  Grid,
  TextareaAutosize,
  FormControl,
  Select,
  AccordionSummary,
  Accordion,
  AccordionDetails,
  List,
  Modal,
  Container,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Collapse,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import ClearIcon from "@mui/icons-material/Clear";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Swal from "sweetalert2";
import { IoIosPaperPlane } from "react-icons/io";
import CountryList from "../VisaAdd/CountryList";
import visaType from "../VisaAdd/VisaType";
import axios from "axios";
import { useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import JoditEditor from "jodit-react";
import "./VisaEdit.css";
import { keys } from "@mui/system";
import DurationUpdate from "./DurationUpdate";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AdminHeader from "../../../Admin/AdminHeader/AdminHeader";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "#fff",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const useStyles = makeStyles({
  tableRow: {
    height: "20px",
  },
});

function Row({ row, i }) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [checkList, setCheckList] = useState("");
  const [edit, setEdit] = useState({});

  const handleChangeEdit = (e, index) => {
    const id = edit.id;
    const { value } = e.target;
    setEdit({ ...checkList, checkList: value, id });
  };

  const HandlechekcListUpdate = async () => {
    let url = `https://api.flyfarint.com/v.1.0.0/Admin/Visa/editVisa.php?ckId=${edit?.id}`;
    let body = JSON.stringify({
      data: edit?.checkList,
    });
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "success",
            text: "Visa Update Successfully",
            confirmButtonText: "ok",
          }).then(function () {
            navigate(0);
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Feiled",
            text: `${data?.message}`,
            confirmButtonText: "ok",
          });
        }
      });
  };

  const handleDelete = async (id) => {
    let url = `https://api.flyfarint.com/v.1.0.0/Admin/Visa/deleteVisa.php?id=${id}&ck`;
    await fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          navigate(0);
        } else {
          Swal.fire({
            title: "There is a problem",
            icon: "error",
            button: "Done!",
          });
        }
      });
  };
  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          bgcolor: "#fff",
          "&:nth-of-type(odd)": {
            backgroundColor: "#fff",
          },
          // hide last border
          "&:last-child td, &:last-child th": {
            backgroundColor: "#D9D9D9",
          },
          ".MuiTableCell-root": {
            padding: "5px 10px",
            fontWeight: 500,
          },
        }}
      >
        <TableCell
          align="left"
          component="th"
          scope="row"
          sx={{ fontSize: "12px", color: "#2156A6" }}
        >
          {i + 1}
        </TableCell>
        <TableCell
          align="left"
          sx={{ fontSize: "12px", color: "#2156A6", fontWeight: 500 }}
        >
          {row?.checkList}
        </TableCell>

        <TableCell align="center">
          <Box>
            <NoteAltIcon
              sx={{
                fontSize: "22px",
                bgcolor: "#2156A6",
                color: "#fff",
                p: 0.1,
                cursor: "pointer",
              }}
              onClick={() => {
                setOpen(!open);
                setEdit(row);
              }}
            />
            &nbsp;&nbsp;
            <DeleteForeverIcon
              sx={{ fontSize: "22px", bgcolor: "#FF2F2F", color: "#fff" }}
              onClick={() => handleDelete(row.id)}
            />
          </Box>
        </TableCell>
      </TableRow>
      {/* end */}
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#D1E9FF",
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="purchases">
                <Box>
                  <TextareaAutosize
                    aria-label="minimum height"
                    minRows={3}
                    placeholder="Minimum 3 rows"
                    value={edit.checkList}
                    style={{ width: "100%" }}
                    onChange={(e) => handleChangeEdit(e)}
                  />

                  <Box textAlign={"end"} mt={1}>
                    <Button
                      size="small"
                      style={{
                        backgroundColor: "#2156A6",
                        padding: "5px 20px",
                        color: "#fff",
                        borderRadius: "0px",
                      }}
                      onClick={() => HandlechekcListUpdate()}
                    >
                      UPDATE
                    </Button>
                  </Box>
                </Box>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const VisaEdit = () => {
  const location = useLocation();
  const {
    visaId,
    agencyFee,
    agentFee,
    embassyFee,
    entryType,
    maximumStay,
    processingTime,
    visaCategory,
    visaType,
    interview,
    country,
    duration,
    FFIServiceCharge,
  } = location.state.visaData.visainfo;

  const [agencyFeee, setagencyFee] = useState(agencyFee);
  const [agentFeee, setagentFeee] = useState(agentFee);
  const [embassyFeee, setembassyFee] = useState(embassyFee);
  const [entryTypee, setentryTypee] = useState(entryType);
  const [maximumStaye, setmaximumStay] = useState(maximumStay);
  const [durations, setDurations] = useState(duration);
  const [visaCategorye, setvisaCategory] = useState(visaCategory);
  const [visaIde, setvisaId] = useState(visaId);
  const [visaTypee, setvisaType] = useState(visaType);
  const [interviewe, setinterview] = useState(interview);
  const [countrye, setcountry] = useState(country);
  const [duratione, setduration] = useState(duration);
  const [ffiServiceCharge, setFFIServiceCharge] = useState(FFIServiceCharge);

  const navigate = useNavigate();
  // SearchingField End

  const editor = useRef(null);
  const [fetchVisaData, setFetchVisaData] = useState([]);
  const [fetchCheckList, setFetchCheckList] = useState([]);
  const [fetchpassengertype, setFetchPassengertype] = useState([]);
  const [EngContent, setEngContent] = useState("");
  const [BngContent, setBngContent] = useState("");

  useEffect(() => {
    fetch(
      `https://api.flyfarint.com/v.1.0.0/Admin/Visa/allVisa.php?visaId=${visaId}`
    )
      .then((res) => res.json())
      .then((data) => {
        setFetchPassengertype(data.checklist);
        setFetchCheckList(data.checklistall);
        setFetchVisaData(data.visainfo);
      });
  }, []);

  const handleSaveInput = async () => {
    let url = `https://api.flyfarint.com/v.1.0.0/Admin/Visa/addVisa.php?checklist`;
    let body = JSON.stringify({
      visaId: visaId,
      country: country,
      category: visaCategory,
      visatype: visaType,
      passengertype: passengertype,
      checklist: checklistField,
    });
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "success",
            text: "Visa added Successfully",
            confirmButtonText: "ok",
          }).then(function () {
            navigate(0);
          });
        }
      });
  };

  const [checkList, setCheckList] = useState("");
  const [passengertype, setPassengertype] = useState("");

  //  Checck List Update function start
  const [edit, setEdit] = useState({});
  const [openEdit, setOpenEdit] = useState(false);

  const handleOpen = async (data) => {
    setEdit(data);
    setOpenEdit(true);
  };

  const handleChangeEdit = (e, index) => {
    const id = edit.id;
    const { value } = e.target;
    setEdit({ ...checkList, checkList: value, id });
  };
  const HandlechekcListAdd = async () => {
    let url = `https://api.flyfarint.com/v.1.0.0/Admin/Visa/editVisa.php?ckId=${edit?.id}`;
    let body = JSON.stringify({
      data: edit?.checkList,
    });
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          setOpenEdit(false);
          Swal.fire({
            icon: "success",
            title: "success",
            text: "Visa added Successfully",
            confirmButtonText: "ok",
          }).then(function () {
            navigate(0);
          });
        }
      });
  };

  //  Checck List Update function end
  //  Checck List Delete function Start

  const [checklistField, setCheckListField] = useState([{ data: "" }]);
  const handleAddList = () => {
    setCheckListField([...checklistField, { data: "" }]);
  };

  const handleChangeList = (e, index) => {
    const newInputFields = checklistField.map((i, ind) => {
      if (ind === index) {
        i[e.target.name] = e.target.value;
      }
      return i;
    });
    setCheckListField(newInputFields);
  };

  const handleRemoveLists = (e, index) => {
    const values = [...checklistField];
    values.splice(index, 1);
    setCheckListField(values);
  };

  const [downExpanded, setDownExpanded] = useState("");
  const handleChangeDown = (panel) => (event, newExpanded) => {
    setDownExpanded(newExpanded ? panel : false);
  };

  // ------- Add Check list End
  const classes = useStyles();
  return (
    <Box>
      <AdminHeader />
      <Container>
        <Box className="search-body-trip">
          <Box my={4}>
            {fetchVisaData.length > 0 ? (
              <DurationUpdate
                fetchVisaData={fetchVisaData}
                visaId={visaId}
                country={country}
                category={visaCategory}
                visaType={visaType}
                entryType={entryType}
              />
            ) : (
              "Waiting for data or refresh this page..."
            )}
          </Box>

          <Box>
            <form>
              <Typography
                sx={{
                  color: "#2156A6",
                  fontSize: "16px",
                  fontWeight: "500",
                  margin: "10px 0",
                }}
              >
                Already this checklist added{" "}
                <span style={{ color: "#FF2F2F" }}>
                  {fetchpassengertype.map((data) => (
                    <>{`${data?.passengertype}, ` || ""}</>
                  ))}
                </span>
              </Typography>

              <Box mb="20px" width="100%">
                {fetchCheckList !== null ? (
                  <>
                    {Object.entries(fetchCheckList)?.map((check, i) => (
                      <Accordion
                        expanded={downExpanded === i}
                        onChange={handleChangeDown(i)}
                        style={{
                          boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px ",
                          background: "#2156A6",
                          color: "#fff",
                          padding: 0,
                          margin: 0,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel8bh-content"
                          id="panelbh-header"
                        >
                          <Typography
                            style={{
                              color: "#fff",
                              fontFamily: "poppins",
                              fontWeight: "500",
                              fontSize: "14px",
                            }}
                          >
                            {check[0]}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                          style={{
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <TableContainer>
                            <Table
                              style={{
                                width: "100%",
                              }}
                            >
                              <TableHead
                                style={{
                                  backgroundColor: "var(--secondary-color)",
                                  height: "0px !important",
                                }}
                              >
                                <TableRow className={classes.tableRow}>
                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "var(--black)",
                                      width: "10%",
                                      padding: "6px",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Sl No
                                  </TableCell>

                                  <TableCell
                                    align="left"
                                    style={{
                                      color: "var(--black)",
                                      width: "80%",
                                      padding: "6px",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Country
                                  </TableCell>

                                  <TableCell
                                    align="center"
                                    style={{
                                      color: "var(--black)",
                                      width: "10%",
                                      padding: "6px",
                                      fontWeight: 500,
                                      fontSize: "13px",
                                    }}
                                  >
                                    Action
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {check[1]?.map((data, index) => (
                                  <Row key={index} row={data} i={index} />
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </>
                ) : (
                  "No Data Available"
                )}
              </Box>

              <Typography
                sx={{
                  color: "#2156A6",
                  fontSize: "20px",
                  fontWeight: "500",
                  margin: "10px 0px",
                }}
              >
                Add another checklist
              </Typography>

              <>
                <FormControl
                  sx={{
                    minWidth: "250px",
                    mb: "10px",
                  }}
                  size="small"
                >
                  <InputLabel id="demo-controlled-open-select-label">
                    Choose Checklist
                  </InputLabel>
                  <Select
                    labelId="demo-controlled-open-select-label"
                    id="demo-controlled-open-select"
                    value={passengertype}
                    label=" Choose Checklist "
                    onChange={(e) => setPassengertype(e.target.value)}
                  >
                    <MenuItem value={"JobHolder"}>Job Holder</MenuItem>
                    <MenuItem value={"BusinessMan"}>Business Man</MenuItem>
                    <MenuItem value={"GovtJobHolder"}>
                      Govt. Job Holder{" "}
                    </MenuItem>
                    <MenuItem value={"Doctor"}>Doctor</MenuItem>
                    <MenuItem value={"AdvocateLawyer"}>
                      Advocate Lawyer
                    </MenuItem>
                    <MenuItem value={"Student"}>Student</MenuItem>
                    <MenuItem value={"NonStudentChild"}>
                      Non Student Child
                    </MenuItem>
                    <MenuItem value={"Housewife"}>Housewife</MenuItem>
                    <MenuItem value={"Unemployed"}>Unemployed</MenuItem>
                  </Select>
                </FormControl>
              </>

              <Typography
                sx={{
                  color: "#2156A6",
                  fontSize: "20px",
                  fontWeight: "500",
                  margin: "10px 0",
                }}
              >
                Add Checklist For
              </Typography>
              <form>
                {checklistField.map((checkList, index) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "5vw",
                      alignItems: "center",
                      justifyContent: "space-between",
                      margin: "10px 0",
                    }}
                  >
                    <TextareaAutosize
                      required
                      id="outlined-basic"
                      label="Entry Checklist"
                      name="data"
                      value={checklistField.data}
                      onChange={(e) => handleChangeList(e, index)}
                      style={{
                        width: "80%",
                        background: "#F4F4F4",
                        padding: "5px 10px",
                      }}
                      focused
                      placeholder="Check List"
                      minRows={2}
                    />

                    {checklistField.length - 1 === index && (
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#3C4258", width: "190px" }}
                        onClick={handleAddList}
                      >
                        Add Another
                      </Button>
                    )}

                    {checklistField.length > 1 && (
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ backgroundColor: "#FF2F2F", width: "190px" }}
                        onClick={() => handleRemoveLists()}
                      >
                        Remove
                      </Button>
                    )}
                  </Box>
                ))}
              </form>
              {/* <input type="submit">Save</input> */}
              <Button
                size="medium"
                variant="contained"
                sx={{ bgcolor: "#2156A6", color: "#fff", marginTop: "20px" }}
                onClick={handleSaveInput}
              >
                Update Data
              </Button>
            </form>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default VisaEdit;
