import React, { useState, useEffect, useRef } from "react";
import { Container } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
  Box,
  Grid,
  Button,
  Typography,
  Stack,
  Pagination,
  Skeleton,
  Drawer,
} from "@mui/material";
import Swal from "sweetalert2";
import { addDays } from "date-fns/esm";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";
import secureLocalStorage from "react-secure-storage";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AirlinesNameSlider from "../../components/AirlinesNameSlider/AirlinesNameSlider";
import FlightSearchBox from "../FlightSearchBox/FlightSearchBox";
import OneWayFilter from "../OnewayFilter/OneWayFilter";
import Search from "../../images/undraw/undraw_web_search_re_efla.svg";
import SingleMultiCityItem from "./SingleMultiCityItem";
import { MultiCityFakeData } from "../MultiCityFakeData";
import "./MultiCitySearchResult.css";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import ReturnSearchSkeleton from "../../pages/SearchReslut/ReturSearchSkeleton";
import MultiWayFilter from "../MultiCity/MultiWayFilter";
import AirlinesSlider from "../SingleFlight/AirlinesSlider";
import SessionTimer from "../Shared/SessionTimer/SessionTimer";
import MulticitySlider from "../MultiCity/MulticitySlider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const modalStyle = {
  position: "absolute",
  top: { lg: "34%", md: "34%", sm: "50%", xs: "50%" },
  left: { lg: "57%", md: "57%", sm: "50%", xs: "50%" },
  transform: {
    lg: "translate(-50%, -57%)",
    md: "translate(-50%, -57%)",
    sm: "translate(-50%, -50%)",
    xs: "translate(-50%, -50%)",
  },
  width: { lg: "80vw", md: "80vw", sm: "100vw", xs: "100vw" },
  height: "fit-content",
  padding: "20px",
  background: "#fff",
  borderRadius: "10px",
};

const itemsPerPage = 10;

const MultiCitySearchResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const commissionData = secureLocalStorage.getItem("commissionData");
  const [noData, setNoData] = useState("No Data");
  const requiredSearchData =
    location.state !== null
      ? location.state
      : secureLocalStorage.getItem("search-data");

  const {
    toSendData,
    adultCount,
    childCount,
    departureDate,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    fromSendData,
    className,
  } = requiredSearchData;
  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState(tripType);
  const [oneWayFromSearchText, setOneWayFromSearchText] =
    useState(fromSearchText);
  const [oneWayToSearchText, setOneWayToSearchText] = useState(toSearchText);
  const now = useRef(new Date(departureDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(addDays(now.current, 3));
  const [fromSearchDate, setFromSearchDate] = useState(new Date(departureDate));
  const [oneWayFaddress, setOneWayFaddress] = useState(faddress);
  const [oneWayToAddress, setOneWayToAddress] = useState(toAddress);
  const [oneWayFromSendData, setOneWayFromSendData] = useState(fromSendData);
  const [oneWayToSendData, setOneWayToSendData] = useState(toSendData);
  const [oneWayAdultCount, setOneWayAdultCount] = useState(adultCount);
  const [oneWayChildCount, setOneWayChildCount] = useState(childCount);
  const [oneWayInfant, setOneWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [oneWayClassName, setOneWayClassName] = useState(className);
  const [selectedAirLines, setSelectedAirLines] = useState([]);
  // const [multiSearchData, setMultiSearchData] = useState(
  //   location.state.multiSearchData

  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);
  const [customerFare, setCustomerFare] = useState(
    commissionData?.customerFare
  );
  const [click, setClick] = useState(false);

  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  const [isLoaded, setIsLoaded] = useState(true);
  const [searchDate, setSearchDate] = useState(
    new Date(from).toLocaleDateString("sv")
  );
  // const [results, setResults] = useState(MultiCityFakeData);
  // const [allResults, setAllResults] = useState(MultiCityFakeData);
  const [results, setResults] = useState([]);
  const [allResults, setAllResults] = useState([]);

  const multicity = secureLocalStorage.getItem("multi-city");
  const [multiCitySearchData, setMultiCitySearchData] = useState(
    multicity !== null
      ? multicity?.multiSearchData
      : {
          agentId,
          adultCount: oneWayAdultCount,
          childCount: oneWayChildCount,
          infantCount: oneWayInfant,
          CityCount: 2,
          tripType: 3,
          cabinclass: className,
          segments: [
            {
              id: 0,
              openFrom: false,
              depFrom: fromSendData.trim(),
              depAddress: faddress,
              depFromText: fromSearchText,
              arrTo: toSendData.trim(),
              arrAddress: toAddress,
              arrToText: toSearchText,
              openTo: false,
              Date: new Date().toLocaleDateString("sv"),
              openDate: false,
              open: false,
            },
            {
              id: 1,
              openFrom: false,
              depFrom: toSendData.trim(),
              depFromText: toSearchText,
              depAddress: toAddress,
              arrTo: "DXB",
              arrAddress: "Dubai,United Arab Emirates",
              arrToText: "Dubai Intl Airport (DXB)",
              openTo: false,
              Date: new Date().toLocaleDateString("sv"),
              openDate: false,
              open: false,
            },
          ],
        }
  );
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [sortedBy, setSortedBy] = useState("cheapest");
  const [initialFetchData, setInitialFetchData] = useState([]);
  const [quotetionArr, setQuotetionArr] = useState([]);

  // todo:next day previous day variables
  let tomorrow = new Date(fromSearchDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let yesterday = new Date(fromSearchDate);
  yesterday.setDate(yesterday.getDate() - 1);

  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);

  let size = 30;

  //todo Searches for flights today's date.
  useEffect(() => {
    let unSubscribed = false;
    setIsLoaded(false);
    setClick(true);
    modifyHandleClose();
    let url = "https://api.rtc.flyfarint.com/v1.0/b2b/air/search/multicity.php";
    let body = JSON.stringify({
      ...multiCitySearchData,
      cabinclass: className,
      adultCount: oneWayAdultCount,
      childCount: oneWayChildCount,
      infantCount: oneWayInfant,
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (!unSubscribed) {
          if (data.length !== 0) {
            setIsLoaded(true);
            let uniqueData;
            agentId === "FFA1926"
              ? (uniqueData = data)
              : (uniqueData = data?.filter(
                  (item) => item?.System?.toLowerCase() !== "galileo"
                ));
            const count = uniqueData.length;
            const pageNumber = Math.ceil(count / size);

            const result = uniqueData.map((item) => {
              const allCarriers = item.AllLegs.flatMap((leg) =>
                leg.segments.map((segment) => segment.marketingcareer)
              );

              const carriers = new Set(allCarriers);

              return {
                ...item,
                carriers: Array.from(carriers),
              };
            });

            setPageCount(pageNumber);
            setData(result);
            setData2(result);
            setInitialFetchData(result);
            setClick(false);
          } else {
            throw new Error();
          }
          if (
            data?.some(
              (item) => item?.AllLegs === null || item?.AllLegs.length === 0
            )
          ) {
            throw new Error();
          }
        }
      })
      .catch((err) => {
        //(err.message);
        Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Flights Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate("/dashboard");
        });
      });
    return () => {
      unSubscribed = true;
    };
  }, [
    location?.state?.multiSearchData,
    location?.state?.changeState,
    navigate,
    size,
  ]);

  const handleFilterByAirLine = (career) => {
    const filterByCareer = initialFetchData.filter(
      (item) => item?.career === career
    );
    setData2(filterByCareer);
  };
  const [isDrawerOpenMobileFilter, setDrawerOpenMobileFilter] = useState(false);

  const handleFilterIconClick = () => {
    setDrawerOpenMobileFilter(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpenMobileFilter(false);
  };

  return (
    <Box sx={{ bgcolor: "#EBF0F4" }}>
      <UserHeader />
      {/* <Container> */}
      <Box
        borderRadius="3px"
        sx={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Box sx={{ position: "relative", mt: {
          xs:"0px",
          sm:"0px",
          md:"-70px",
          lg:"-70px"
        } }}>
          <Grid container justifyContent="space-between">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Grid item md={12} sx={{display:{
                xs:"none",
                sm:"none",
                md:"block",
                lg:"block"
              }}}>
                <FlightSearchBox
                  click={click}
                  setClick={setClick}
                  type={type}
                  setType={setType}
                  value={value}
                  setValue={setValue}
                  fromSearchText={oneWayFromSearchText}
                  setFromSearchText={setOneWayFromSearchText}
                  toSearchText={oneWayToSearchText}
                  setToSearchText={setOneWayToSearchText}
                  from={from}
                  setFrom={setFrom}
                  to={to}
                  setTo={setTo}
                  faddress={oneWayFaddress}
                  setfaddress={setOneWayFaddress}
                  toAddress={oneWayToAddress}
                  setToAddress={setOneWayToAddress}
                  fromSendData={oneWayFromSendData}
                  setFromSendData={setOneWayFromSendData}
                  toSendData={oneWayToSendData}
                  setToSendData={setOneWayToSendData}
                  adultCount={oneWayAdultCount}
                  setAdultCount={setOneWayAdultCount}
                  childCount={oneWayChildCount}
                  setChildCount={setOneWayChildCount}
                  infant={oneWayInfant}
                  setInfant={setOneWayInfant}
                  result={result}
                  setResult={setResult}
                  className={oneWayClassName}
                  setClassName={setOneWayClassName}
                  changeState={changeState}
                  setChangeState={setChangeState}
                  changeFrom={changeFrom}
                  setChangeFrom={setChangeFrom}
                  multiSearchData={multiCitySearchData}
                  setMultiSearchData={setMultiCitySearchData}
                />


              </Grid>
              <Grid
            container
            spacing={2}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <Grid item xs={12} md={12}>
              <Box
                mt={1}
             
                sx={{
                  bgcolor: "var(--white)",
                  px: "8px",
                  py: "6px",
                  borderRadius: "5px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 600,
                      color: "var(--black)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "5px",
                    }}
                  >
                    {data2[0]?.AllLegs[0]?.DepFrom}
                    <Typography sx={{ pt: "5px" }}>
                      {" "}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 116 57"
                        fill="none"
                      >
                        <path
                          d="M115.51 14.4039C114.711 12.0948 112.993 10.1857 110.732 9.09641C108.471 8.00716 105.854 7.82702 103.455 8.5956L76.971 17.1L29.6042 0L17.7625 5.7L53.2876 28.5L29.6042 39.9L5.92085 28.5L0 34.2L23.6834 57L109.814 25.9065C112.125 25.0713 114.009 23.4062 115.072 21.2611C116.134 19.1161 116.291 16.6581 115.51 14.4039Z"
                          fill="var(--secondary-color)"
                        />
                      </svg>
                    </Typography>
                    {data2[0]?.AllLegs[data2[0]?.AllLegs.length - 1]?.ArrTo}
                  </Typography>

                  <Box
                      sx={{
                        display: "flex",
                        gap: "10px",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {" "}
                      <Link to="/dashboard">
                        <Box
                          sx={{
                            bgcolor: "var(--secondary-color)",
                            color: "var(--white)",
                            px: "10px",
                            borderRadius: "3px",
                            display: "flex",
                            alignItems: "center",

                            fontSize: "11px",
                            fontWeight: 500,
                            height: "25px",
                            cursor: "pointer",
                          }}
                        >
                          Modifiy Serch
                        </Box>
                      </Link>
                      <Box
                        sx={{ cursor: "pointer" }}
                        onClick={handleFilterIconClick}
                      >
                        <FilterAltIcon
                          sx={{
                            color: "var(--primary-color)",
                            cursor: "pointer",
                          }}
                        />
                      </Box>{" "}
                    </Box>
                </Box>
                <Drawer
                  anchor="right"
                  open={isDrawerOpenMobileFilter}
                  onClose={handleDrawerClose}
                >
                  <Box
                    sx={{
                      position: "sticky",
                      top: 0,
                      left: 0,
                      bgcolor: "var(--white)",
                      borderRadius: "5px",
                      mt: 2,
                    }}
                  >
                      <Box mt={2.1} mx={1}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      color: "white",
                      bgcolor: "var(--primary-color)",
                    }}
                  >
                    <SessionTimer />
                  </Button>
                  <Box
                    sx={{
                      bgcolor: "var(--secondary-color)",
                      py: "3px",
                      textAlign: "center",
                      borderRadius: "3px",
                      mt: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "var(--white)",
                        fontWeight: "500",
                      }}
                    >
                      Showing {data2?.length} Flight Results
                    </Typography>
                  </Box>
                </Box>
                {isLoaded ? (
                  <MultiWayFilter
                    setSortedBy={setSortedBy}
                    sortedBy={sortedBy}
                    initialFetchData={initialFetchData}
                    setfilteredData={setData2}
                    filteredData={data2}
                    selectedAirLines={selectedAirLines}
                    setSelectedAirLines={setSelectedAirLines}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      overFlow: "hidden",
                      bgcolor: "var(--white)",
                      padding: "10px",
                      mt: "25px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      my={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                    </Stack>
                  </Box>
                )}
                  </Box>
                </Drawer>
              </Box>
            </Grid>
          </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={9.6}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    height: "fit-content",
                  }}
                >
                  {/* //todo:airline slider */}
                  <Box
                    sx={{
                      mb: "2px",
                      bgcolor: "white",
                      mt: "15px",
                    }}
                  >
                    {isLoaded ? (
                      <MulticitySlider
                        data={initialFetchData}
                        isLoaded={isLoaded}
                        setData2={setData2}
                        handleFilterByAirLine={handleFilterByAirLine}
                        initialFetchData={initialFetchData}
                        selectedAirLines={selectedAirLines}
                        setSelectedAirLines={setSelectedAirLines}
                      />
                    ) : (
                      [...new Array(1)].map((data, index) => (
                        <Box
                          key={index}
                          style={{
                            width: "100%",
                            height: "50px",
                            margin: "10px 0px",
                            borderRadius: "5px",
                            overFlow: "hidden",
                            backgroundColor: "white",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width={"100%"}
                            height={"100%"}
                          />
                        </Box>
                      ))
                    )}
                  </Box>

                  {isLoaded
                    ? data2?.slice(startIndex, endIndex)?.map((data, index) => {
                        return (
                          <SingleMultiCityItem
                            key={index}
                            flightData={data}
                            tripType={tripType}
                            adultCount={adultCount}
                            childCount={childCount}
                            infant={infant}
                            from={fromSendData}
                            to={toSendData}
                            fromAddress={faddress}
                            toAddress={toAddress}
                            dDate={searchDate}
                            agentFarePrice={agentFarePrice}
                            setAgentFarePrice={setAgentFarePrice}
                            commisionFarePrice={commisionFarePrice}
                            setCommisionFarePrice={setCommisionFarePrice}
                            customerFare={customerFare}
                            setCustomerFare={setCustomerFare}
                            isLoaded={isLoaded}
                            quotetionArr={quotetionArr}
                            setQuotetionArr={setQuotetionArr}
                          />
                        );
                      })
                    : [...new Array(5)].map((data, index) => (
                        <>
                          <ReturnSearchSkeleton />
                        </>
                      ))}

                  <Box
                    sx={{
                      width: "100%",
                      my: 3,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(data2.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, page) => setCurrentPage(page)}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                lg={2.4}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "none",
                    lg: "block",
                  },

                  // ml: "10px"
                }}
              >
                <Box mt={2.1}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      color: "white",
                      bgcolor: "var(--primary-color)",
                    }}
                  >
                    <SessionTimer />
                  </Button>
                  <Box
                    sx={{
                      bgcolor: "var(--secondary-color)",
                      py: "3px",
                      textAlign: "center",
                      borderRadius: "3px",
                      mt: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "var(--white)",
                        fontWeight: "500",
                      }}
                    >
                      Showing {data2?.length} Flight Results
                    </Typography>
                  </Box>
                </Box>
                {isLoaded ? (
                  <MultiWayFilter
                    setSortedBy={setSortedBy}
                    sortedBy={sortedBy}
                    initialFetchData={initialFetchData}
                    setfilteredData={setData2}
                    filteredData={data2}
                    selectedAirLines={selectedAirLines}
                    setSelectedAirLines={setSelectedAirLines}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      overFlow: "hidden",
                      bgcolor: "var(--white)",
                      padding: "10px",
                      mt: "25px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      my={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                    </Stack>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          {quotetionArr?.length ? (
            <Box
              sx={{
                position: "fixed",
                bottom: "0",
                width: "75%",
                background: "var(--primary-color)",
                padding: "10px 0px",
                boxShadow: "0px -1px 5px 0px rgba(0,0,0,0.75)",
                zIndex: 100,
              }}
            >
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "white",
                      pl: 7,
                    }}
                  >{`${quotetionArr?.length} Flights Selected`}</Typography>
                  <Button
                    onClick={() => {
                      navigate("/dashboard/make-quotation", {
                        state: {
                          quotetionArr,
                          adultCount,
                          childCount,
                          infant,
                          to,
                          from,
                          tripType,
                          toAddress,
                        },
                      });
                    }}
                    style={{
                      background: "var(--secondary-color)",
                      color: "var(--primary-color)",
                      fontSize: "14px",
                      fontWeight: "500",
                      padding: "5px 10px",
                    }}
                  >
                    Make Quotation
                  </Button>
                </Box>
              </Container>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default MultiCitySearchResult;
