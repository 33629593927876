
import React, { useEffect, useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import ReactHtmlParser from "react-html-parser";


function About() {

  const [about, setAbout] = useState('');

  useEffect(() => {
    fetch(`https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&aboutUs`).then((res) => res.json()).then((data) => setAbout(data?.content))
  }, [])


  return (
    <Box>
      <Header />
      <Container sx={{ my: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", mb: "30px" }}>
          <Typography fontSize="40px" textAlign="center">
            About Us
          </Typography>
          <Box sx={{ width: "250px", height: "3px", backgroundColor: "var(--primary-color)" }}>
          </Box>
        </Box>

        <Typography
          sx={{
            marginTop: "10px",
            color: "var(--black) !important",
            textAlign: "justify",
          }}
        >
          {ReactHtmlParser(about)}
        </Typography>
      </Container>
      <Footer />
    </Box>
  );
}
export default About;
