import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";

import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import GoSearchCard from "../../components/SingleFlight/GoSearchCard";
import BackSearchCard from "../../components/SingleFlight/BackSearchCard";
import RoundwayFlightDetails from "../../components/Roundway/RoundSingleFlightDetails";
import FareDetails from "../../components/SingleFlight/FareDetails";
import FarePolicy from "../../components/FarePolicy/FarePolicy";
import Baggage from "../../components/SingleFlight/Baggage";
import PriceBreakDown from "../../components/Roundway/PriceBreakDown";
import UserHeader from "../../components/UserPage/UserHeader/UserHeader";
import Roundwaybooking from "../../components/Roundway/RoundwayBooking";
import moment from "moment";
const isMobile = window.innerWidth <= 768;

const FlightInformation = () => {
  const flightData = useLocation();
  const [isActive, setIsActive] = useState(1);
  const {
    segments,
    pricebreakdown,
    career,
    careerName,
    goarrival,
    goarrivalDate,
    goarrivalTime,
    godeparture,
    godepartureDate,
    godepartureTime,
    goflightduration,
    backarrivalDate,
    segment,
    refundable,
    price,
    clientPrice,
    backarrival,
    backdeparture,
    backdepartureDate,
    backdepartureTime,
    backflightduration,
    backarrivalTime,
  } = flightData.state.roundData;

  return (
    <Box
      sx={{
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "3px",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        ".MuiTypography-h6": {
          fontSize: "16px",
          color: "#444542",
          fontWeight: "500",
        },
        ".MuiTypography-caption": {
          fontSize: "11px",
          color: "#ACA6A6",
        },
        ".MuiTypography-subtitle1": {
          fontSize: "11px",
          color: "#2156A6",
        },
      }}
    >
      <UserHeader />
      <Grid
        justifyContent={"space-between"}
        container
        gap={1}
        sx={{
          p: "15px",
          width: "90%",
          margin: "0 auto",
          mt: "-80px",
          bgcolor: "white",
          borderRadius: "5px",
        }}
      >
        <Grid item xs={12} lg={8.75} container>
          <Grid item xs={12}>
            <Box sx={{ bgcolor: "#a3c7ff3b", borderRadius: "7px" }}>
              <Typography
                sx={{
                  color: "#2156A6",
                  p: "10px",
                  pl: "20px",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
              >
                Flight Information
              </Typography>
            </Box>
          </Grid>
          {isMobile ? (
            <>
              <Grid item xs={12}>
                <Box
                  sx={{
                    bgcolor: "#a3c7ff3b",
                    borderRadius: "4px",
                    px: "13px",
                    py: "10px",
                    mt: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                      {godeparture} {godepartureTime}
                    </Typography>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 116 57"
                      fill="none"
                    >
                      <path
                        d="M115.51 14.4039C114.711 12.0948 112.993 10.1857 110.732 9.09641C108.471 8.00716 105.854 7.82702 103.455 8.5956L76.971 17.1L29.6042 0L17.7625 5.7L53.2876 28.5L29.6042 39.9L5.92085 28.5L0 34.2L23.6834 57L109.814 25.9065C112.125 25.0713 114.009 23.4062 115.072 21.2611C116.134 19.1161 116.291 16.6581 115.51 14.4039Z"
                        fill="#ED1C24"
                      />
                    </svg>
                    <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                      {goarrival} {goarrivalTime}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "12px",
                      bgcolor: "#D6D7DD",
                      py: "1px",
                      borderRadius: "2px",
                      pl: "2px",
                      mt: "5px",
                      fontWeight: "500",
                    }}
                  >
                    Flight Details
                  </Typography>
                  {/* Array mapping starts here */}

                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1.5px dotted var(--secondary-color)",
                        mt: "20px",
                        pl: "15px",
                        pb: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          backgroundColor: "var(--primary-color)",
                          borderRadius: "50px",
                          position: "absolute",
                          left: "-5px",
                          top: "-5px",
                        }}
                      ></div>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                            pb: "0px",
                          }}
                        >
                          {godeparture}{" "}
                          {moment(godepartureTime, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </span>

                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {segments.go[0].departureAirport}
                        </span>
                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {godepartureDate}
                        </span>
                      </Box>
                      <Box sx={{ mt: "20px", display: "flex", gap: "10px" }}>
                        <img
                          src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${career}.png`}
                          width="40px"
                          height="40px"
                          alt=""
                        />
                        <Box>
                          <span style={{ fontSize: "13px", pt: "0px" }}>
                            {careerName}
                          </span>{" "}
                          <br />
                          <span
                            style={{
                              fontSize: "12px",
                              pt: "0px",
                              color: "var(--primary-color)",
                              fontWeight: "600",
                            }}
                          >
                            {segments?.go?.map(
                              (s, i) =>
                                `${
                                  i === 0
                                    ? `${s.marketingcareer} ${s.marketingflight}`
                                    : ` & ${s.marketingcareer} ${s.marketingflight}`
                                }`
                            )}
                          </span>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                            pb: "0px",
                          }}
                        >
                          {goarrival}{" "}
                          {moment(goarrivalTime, "HH:mm:ss").format("hh:mm A")}
                        </span>
                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {segments.go[segments.go.length - 1].arrivalAirport}
                        </span>
                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {goarrivalDate}
                        </span>
                      </Box>
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          backgroundColor: "var(--primary-color)",
                          borderRadius: "50px",
                          position: "absolute",
                          left: "-5px",
                          bottom: "0px",
                        }}
                      ></div>
                    </Box>
                    {/* 
          {flightData?.segments.length > 1 &&
          index <= flightData?.segment?.length - 2 ? (
            <Box
              sx={{
                bgcolor: "#D9F1FF",
                fontSize: "13px",
                py: "4px",
                my: "4px",
                mt: "6px",
                px: "17px",
                fontWeight: "500",
                color: "var(--primary-color )",
              }}
            >
              {" "}
              {data.arrival}, Connecting time{" "}
              {index === 0
                ? flightData?.transit?.transit1
                : index === 1
                ? flightData?.transit?.transit2
                : index === 2
                ? flightData?.transit?.transit3
                : ""}
            </Box>
          ) : (
            ""
          )} */}
                  </>

                  {/* Array mapping ends here */}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box
                  sx={{
                    bgcolor: "#a3c7ff3b",
                    borderRadius: "4px",
                    px: "13px",
                    py: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      gap: "15px",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                      {backdeparture}{" "}
                      {moment(backdepartureTime, "HH:mm:ss").format("hh:mm A")}
                    </Typography>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      viewBox="0 0 116 57"
                      fill="none"
                    >
                      <path
                        d="M115.51 14.4039C114.711 12.0948 112.993 10.1857 110.732 9.09641C108.471 8.00716 105.854 7.82702 103.455 8.5956L76.971 17.1L29.6042 0L17.7625 5.7L53.2876 28.5L29.6042 39.9L5.92085 28.5L0 34.2L23.6834 57L109.814 25.9065C112.125 25.0713 114.009 23.4062 115.072 21.2611C116.134 19.1161 116.291 16.6581 115.51 14.4039Z"
                        fill="#ED1C24"
                      />
                    </svg>
                    <Typography sx={{ fontSize: "15px", fontWeight: "500" }}>
                      {backarrival}{" "}
                      {moment(backarrivalTime, "HH:mm:ss").format("hh:mm A")}
                    </Typography>
                  </Box>

                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      fontSize: "12px",
                      bgcolor: "#D6D7DD",
                      py: "1px",
                      borderRadius: "2px",
                      pl: "2px",
                      mt: "5px",
                      fontWeight: "500",
                    }}
                  >
                    Flight Details
                  </Typography>
                  {/* Array mapping starts here */}

                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        borderLeft: "1.5px dotted var(--secondary-color)",
                        mt: "20px",
                        pl: "15px",
                        pb: "10px",
                        position: "relative",
                      }}
                    >
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          backgroundColor: "var(--primary-color)",
                          borderRadius: "50px",
                          position: "absolute",
                          left: "-5px",
                          top: "-5px",
                        }}
                      ></div>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                            pb: "0px",
                          }}
                        >
                          {backdeparture}{" "}
                          {moment(backdepartureTime, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </span>

                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {segments?.back[0].departureAirport}
                          {/* {flightData?.departureDate} */}
                        </span>
                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {backdepartureDate}
                        </span>
                      </Box>
                      <Box sx={{ mt: "20px", display: "flex", gap: "10px" }}>
                        <img
                          src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${career}.png`}
                          width="40px"
                          height="40px"
                          alt=""
                        />
                        <Box>
                          <span style={{ fontSize: "13px", pt: "0px" }}>
                            {careerName}
                          </span>{" "}
                          <br />
                          <span
                            style={{
                              fontSize: "12px",
                              pt: "0px",
                              color: "var(--primary-color)",
                              fontWeight: "600",
                            }}
                          >
                            {segments?.back?.map(
                              (s, i) =>
                                `${
                                  i === 0
                                    ? `${s.marketingcareer} ${s.marketingflight}`
                                    : ` & ${s.marketingcareer} ${s.marketingflight}`
                                }`
                            )}
                          </span>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          mt: "15px",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "15px",
                            fontWeight: "500",
                            pb: "0px",
                          }}
                        >
                          {backarrival}{" "}
                          {moment(backarrivalTime, "HH:mm:ss").format(
                            "hh:mm A"
                          )}
                        </span>
                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {
                            segments?.back[segments.back.length - 1]
                              .arrivalAirport
                          }
                        </span>
                        <span style={{ fontSize: "12px", pt: "0px" }}>
                          {backarrivalDate}
                          {/* {flightData?.arrivalTime} */}
                        </span>
                      </Box>
                      <div
                        style={{
                          height: "10px",
                          width: "10px",
                          backgroundColor: "var(--primary-color)",
                          borderRadius: "50px",
                          position: "absolute",
                          left: "-5px",
                          bottom: "0px",
                        }}
                      ></div>
                    </Box>
                    {/* 
          {flightData?.segments.length > 1 &&
          index <= flightData?.segment?.length - 2 ? (
            <Box
              sx={{
                bgcolor: "#D9F1FF",
                fontSize: "13px",
                py: "4px",
                my: "4px",
                mt: "6px",
                px: "17px",
                fontWeight: "500",
                color: "var(--primary-color )",
              }}
            >
              {" "}
              {data.arrival}, Connecting time{" "}
              {index === 0
                ? flightData?.transit?.transit1
                : index === 1
                ? flightData?.transit?.transit2
                : index === 2
                ? flightData?.transit?.transit3
                : ""}
            </Box>
          ) : (
            ""
          )} */}
                  </>

                  {/* Array mapping ends here */}
                </Box>
              </Grid>
            </>
          ) : (
            <>
              <Grid
                mt={4}
                item
                lg={12}
                container
                justifyContent={"space-between"}
                sx={{
                  bgcolor: "white",
                  padding: "20px 20px 0",
                  borderRadius: "7px",
                }}
              >
                <GoSearchCard roundFlightData={flightData.state.roundData} />
              </Grid>
              <Grid
                item
                lg={12}
                container
                justifyContent={"space-between"}
                sx={{ bgcolor: "white", padding: "20px" }}
              >
                <BackSearchCard roundFlightData={flightData.state.roundData} />
              </Grid>
              <Grid item lg={12} bgcolor={"white"}>
                <Box
                  sx={{
                    borderTop: "1px solid #ACA6A6",
                    padding: "20px",
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <Button
                        onClick={() => setIsActive(1)}
                        sx={{
                          padding: "10px 20px",
                          bgcolor: `${isActive === 1 ? "var(--secondary-color)" : ""}`,
                          color: `${isActive === 1 ? "var(--white)" : "var(--secondary-color)"}`,
                        }}
                      >
                        Flight Details
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => setIsActive(2)}
                        sx={{
                          padding: "10px 20px",
                          bgcolor: `${isActive === 2 ? "var(--secondary-color)" : ""}`,
                          color: `${isActive === 2 ? "var(--white)" : "var(--secondary-color)"}`,
                        }}
                      >
                        Fare Details
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => setIsActive(3)}
                        sx={{
                          padding: "10px 20px",
                          bgcolor: `${isActive === 3 ? "var(--secondary-color)" : ""}`,
                          color: `${isActive === 3 ? "var(--white)" : "var(--secondary-color)"}`,
                        }}
                      >
                        Fare Policy
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => setIsActive(4)}
                        sx={{
                          padding: "10px 20px",
                          bgcolor: `${isActive === 4 ? "var(--secondary-color)" : ""}`,
                          color: `${isActive === 4 ? "var(--white)" : "var(--secondary-color)"}`,
                          
                        }}
                      >
                        Baggage
                      </Button>
                    </Box>
                  </Stack>

                  <Box>
                    <Box
                      sx={{
                        display: `${isActive === 1 ? "block" : "none"}`,
                      }}
                    >
                      <RoundwayFlightDetails
                        roundData={flightData.state.roundData}
                        segments={segments}
                      />
                    </Box>

                    <Box
                      sx={{
                        display: `${isActive === 2 ? "block" : "none"}`,
                      }}
                    >
                      <FareDetails pricebreakdown={pricebreakdown} />
                    </Box>
                    <Box
                      sx={{
                        display: `${isActive === 3 ? "block" : "none"}`,
                      }}
                    >
                      <FarePolicy />
                    </Box>
                    <Box
                      sx={{
                        display: `${isActive === 4 ? "block" : "none"}`,
                      }}
                    >
                      <Baggage
                        flightData={flightData.state.roundData}
                        pricebreakdown={pricebreakdown}
                      />
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          lg={3}
          sx={{
            height: "max-content",
            padding: "18px 0",
            bgcolor: "#F6F6FF",
            borderRadius: "7px",
          }}
        >
          <PriceBreakDown roundData={flightData.state.roundData} />
        </Grid>

        {/* Passenger Information */}

        <Grid item xs={12} lg={8.75}>
          <Roundwaybooking searchData={flightData.state} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default FlightInformation;
