import { Box, Button, Container, Typography } from "@mui/material";
import React, { useState } from "react";
import { Grid } from "@mui/material";
import UserHeader from "../../UserHeader/UserHeader";
import secureLocalStorage from "react-secure-storage";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddStaff = () => {
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");

  const [staffInfo, setStaffInfo] = useState({
    name: "",
    email: "",
    designation: "",
    phone: "",
    role: "",
    password: "",
    agentId: user.agent_id,
  });

  const handleStaffInfoChange = (e) => {
    const { name, value } = e.target;
    setStaffInfo({ ...staffInfo, [name]: value });
  };

  const handleAddStaff = async (e) => {
    e.preventDefault();
    console.log(staffInfo);
    await axios
      .post(
        "https://api.rtc.flyfarint.com/v1.0/b2b/staff/add.php",
        JSON.stringify(staffInfo)
      )
      .then((res) => {
        console.log(res);

        if (res.data.status === "success") {
          Swal.fire({
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Congratulations",
            text: `Successfull Added`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(async function (res) {
            navigate("/dashboard/staff");
          });
        } else {
          Swal.fire({
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: "Error",
            text: `${res.data.message}`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(async function (res) {});
        }
      });
  };

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "90%", margin: "0 auto", mt: "-8vh" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            background: "var(--white)",
            gap: 1,
            px: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <Box sx={{ padding: "15px" }}>
            <Typography
              sx={{
                fontWeight: "500px",
                fontSize: "18px",
                color: "var(--black)",
              }}
            >
              Add Staff Account
            </Typography>
          </Box>
          <form style={{ width: "100%" }} onSubmit={handleAddStaff}>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Box sx={{ padding: "15px" }}>
                  <label
                    style={{
                      color: "#444542",
                      fontSize: "14px",
                      fontWeight: "500",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    htmlFor=""
                  >
                    Staff Name
                  </label>
                  <input
                    onChange={handleStaffInfoChange}
                    type="text"
                    name="name"
                    id=""
                    placeholder="Staff Name"
                    style={{
                      width: "100%",
                      paddingLeft: "0",
                      borderBottom: "1px solid #2156A6",
                      fontSize: "12px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ padding: "15px" }}>
                  <label
                    style={{
                      color: "#444542",
                      fontSize: "14px",
                      fontWeight: "500",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    htmlFor=""
                  >
                    Staff Phone
                  </label>
                  <input
                    onChange={handleStaffInfoChange}
                    type="text"
                    name="phone"
                    id=""
                    placeholder="Staff Phone"
                    style={{
                      width: "100%",
                      paddingLeft: "0",
                      borderBottom: "1px solid #2156A6",
                      fontSize: "12px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ padding: "15px" }}>
                  <label
                    style={{
                      color: "#444542",
                      fontSize: "14px",
                      fontWeight: "500",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                    htmlFor=""
                  >
                    Staff Designations
                  </label>
                  <input
                    onChange={handleStaffInfoChange}
                    type="text"
                    name="designation"
                    id=""
                    placeholder="Staff Designation"
                    style={{
                      width: "100%",
                      paddingLeft: "0",
                      borderBottom: "1px solid #2156A6",
                      fontSize: "12px",
                      paddingBottom: "5px",
                      display: "inline-block",
                    }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ padding: "15px" }}>
                  <label
                    style={{
                      color: "#444542",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                    htmlFor=""
                  >
                    Select Role
                  </label>
                  <br />
                  <select
                    onChange={handleStaffInfoChange}
                    name="role"
                    id=""
                    style={{
                      width: "100%",
                      padding: "5px",
                      paddingLeft: "0px",
                      border: "none",
                      borderBottom: "1px solid #2156A6",
                    }}
                  >
                    <option value="Reservation Officer">
                      Reservation Officer
                    </option>
                    <option value="Operation Executive">
                      Operation Executive
                    </option>
                    <option value="Staff">Staff</option>
                  </select>
                </Box>
              </Grid>

              <Grid container item xs={12}>
                <Grid item xs={12}>
                  <Box sx={{ padding: "15px" }}>
                    <Typography
                      sx={{
                        color: "#2156A6",
                        fontSize: "18px",
                        fontWeight: "500",
                      }}
                    >
                      Login Information
                    </Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box sx={{ padding: "15px" }}>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Email
                    </label>
                    <input
                      onChange={handleStaffInfoChange}
                      type="email"
                      name="email"
                      id=""
                      placeholder="Email"
                      style={{
                        width: "100%",
                        paddingLeft: "0",
                        borderBottom: "1px solid #2156A6",
                        fontSize: "12px",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                  <Box sx={{ padding: "15px" }}>
                    <label
                      style={{
                        color: "#444542",
                        fontSize: "14px",
                        fontWeight: "500",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                      htmlFor=""
                    >
                      Password
                    </label>
                    <input
                      onChange={handleStaffInfoChange}
                      type="text"
                      name="password"
                      id=""
                      placeholder="Password"
                      style={{
                        width: "100%",
                        paddingLeft: "0",
                        borderBottom: "1px solid #2156A6",
                        fontSize: "12px",
                        paddingBottom: "5px",
                        display: "inline-block",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid xs={12} sx={{ textAlign: "right" }}>
              <Button
                type="submit"
                sx={{
                  mt: 4,
                  background: "#222222",
                  color: "#FFFFFF",
                  width: "fit-content",
                  "&:hover": {
                    backgroundColor: "#2564B8",
                  },
                }}
              >
                Add this account
              </Button>
            </Grid>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddStaff;
