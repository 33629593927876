import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Typography, Paper, Button, Box, IconButton } from "@mui/material";
import Person4Icon from "@mui/icons-material/Person4";
import CoffeeIcon from "@mui/icons-material/Coffee";
import CheckIcon from "@mui/icons-material/Check";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { Calendar } from "react-date-range";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import { format } from "date-fns";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import commaNumber from "comma-number";

const HotelAvailability = ({ hotelDetails, searchInfo, hotelData, adultCount, childCount }) => {
  const navigate = useNavigate();
  const [roomCount, setRoomCount] = useState(searchInfo?.rooms || 0);


  return (
    <Box>
      <Typography
        sx={{ fontSize: "20px", color: "var(--primary-color)", fontWeight: "500" }}>
        Availability
      </Typography>

      <TableContainer
        component={Paper}
        sx={{
          mt: "20px",
          boxShadow: "none",
          borderBottom: "2px solid var(--secondary-color)",
        }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  bgcolor: "var(--primary-color)",
                  borderRight: "1px solid white",
                  color: "white"
                }}>
                Room Type
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: "var(--primary-color)",
                  borderRight: "1px solid white",
                  color: "white"
                }}>
                Number of Guests
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: "var(--primary-color)",
                  borderRight: "1px solid white",
                  color: "white"
                }}>
                Amenities
              </TableCell>
              <TableCell
                sx={{
                  bgcolor: "var(--primary-color)",
                  borderRight: "1px solid white",
                  color: "white"
                }}>
                Room Service Details
              </TableCell>
              {/* <TableCell
                sx={{
                  bgcolor: "var(--secondary-color)",
                  borderRight: "1px solid var(--primary-color)",
                }}>
                Select Rooms
              </TableCell> */}
              <TableCell
                sx={{
                  bgcolor: "var(--primary-color)",
                  color: "white"
                }}>
                Price
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {hotelDetails?.roomSet?.map((room, index) => (
              <TableRow key={index}>
                <TableCell
                  style={{ borderRight: "1px solid var(--primary-color)" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: "500",
                      color: "var(--primary-color)",
                    }}>
                    {room?.roomType}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      fontWeight: "normal",
                      color: "var(--black)",
                      textTransform: "capitalize",
                    }}>
                    {room?.roomName}
                  </Typography>

                </TableCell>
                <TableCell
                  style={{ borderRight: "1px solid var(--primary-color)", width: "9%" }}>
                  {[...Array(searchInfo?.adult)].map((_) => (
                    <Person4Icon sx={{ color: "var(--primary-color)" }} />
                  ))}
                </TableCell>
                <TableCell style={{ borderRight: "1px solid var(--primary-color)", width: "20%" }}>

                  {room?.amenities?.map((amenity) => (
                    <Typography
                      style={{
                        fontSize: "12px",
                        color: "#008009",
                        display: "flex",
                        gap: "10px",
                        marginBottom: "5px",
                        justifyContent: "flex-start",
                      }}
                    >
                      <CheckIcon sx={{ fontSize: "14px", pr: "0px" }} />
                      {amenity?.description}
                    </Typography>
                  ))}
                </TableCell>
                <TableCell
                  style={{ borderRight: "1px solid var(--primary-color)", width: "32%", color: "#000000", fontSize: "13px" }}>
                  {room?.roomNameText}
                </TableCell>
                <TableCell>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: "500",
                        color: "green",
                      }}>
                      {roomCount} Rooms for
                    </Typography>
                    <Typography sx={{ color: "red" }}>{`${room?.ratePlan?.currencyCode} ${commaNumber(Number.parseInt(room?.ratePlan?.amountBeforeTax))}`}</Typography>
                    <Typography
                      sx={{
                        color: "gray",
                        fontSize: "11px",
                        mt: "2px",
                      }}>{`+${room?.ratePlan?.currencyCode} ${commaNumber(
                        Number?.parseInt(room?.ratePlan?.amountAfterTax - room?.ratePlan?.amountBeforeTax)
                      )} taxes and charges`}</Typography>
                    <Button
                      sx={{
                        width: "100%",
                        bgcolor: "#28282B",
                        color: "var(--white)",
                        mt: "5px",
                        textTransform: "none",
                        fontSize: "12px",
                        "&:hover": {
                          bgcolor: "#28282B",
                        },
                      }}
                      onClick={() => navigate("/dashboard/hotelbooking", {
                        state: {
                          rateKey: room?.ratePlan?.rateKey,
                          hotelDetails: hotelDetails,
                          hotelData: hotelData,
                          room: room,
                          searchInfo,
                          adultCount: adultCount,
                          childCount: childCount
                        }
                      })}>
                      I'll Reserve
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default HotelAvailability;
