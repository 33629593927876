import {
  Button,
  Container,
  Grid,
  Icon,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import FlightIcon from "@mui/icons-material/Flight";
import GroupsIcon from "@mui/icons-material/Groups";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import { FaPassport } from "react-icons/fa";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import { useLocation, useNavigate } from "react-router-dom";
import GroupFareSearchBox from "../GroupFareSearchBox/GroupFareSearchBox";
import FlightSearchBox from "../FlightSearchBox/FlightSearchBox";
import Tab from "@mui/material/Tab";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import NightlightRoundIcon from "@mui/icons-material/NightlightRound";
import HotelIcon from "@mui/icons-material/Hotel";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { makeStyles } from "@material-ui/core";
import "./HomeSearchBox.css";
import TourSearchBox from "../TourSearchBox/TourSearchBox";
import UmrahSearchBox from "../UmrahSearchBox/UmrahSearchBox";
import HotelSearchBox from "../HotelSearchBox/HotelSearchBox";
import VisaSearch from "../VisaSearch/VisaSearch";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import secureLocalStorage from "react-secure-storage";
import FestivalIcon from "@mui/icons-material/Festival";
const isMobile = window.innerWidth <= 768;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const HomeSearchBox = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.agent_id;
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change

  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState("oneway");
  const [fromSearchText, setFromSearchText] = useState(
    "Hazrat Shahjalal Int Airport (DAC)"
  );
  const [toSearchText, setToSearchText] = useState("Cox's Bazar Airport(CXB)");

  const [departureDate, setDepartureDate] = useState(
    format(addDays(new Date(), 1), "dd MMM yy")
  );
  const [returningDate, setReturningDate] = useState(
    format(addDays(new Date(departureDate), 3), "dd MMM yy")
  );
  const [travelDate, setTravelDate] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 3),
      key: "selection",
    },
  ]);
  // for multiCity
  const now = useRef(new Date());
  const [from, setFrom] = useState(addDays(now.current, 0));
  const [to, setTo] = useState(addDays(now.current, 2));

  const [faddress, setfaddress] = useState("Dhaka,BANGLADESH");
  const [toAddress, setToAddress] = useState("Cox's Bazar,Bangladesh");
  const [fvisa, setfvisa] = useState("Select Country");
  const [typeVisa, setTypeVisa] = useState("Select Your Visa Type");
  const [fromSendData, setFromSendData] = useState("DAC");
  const [toSendData, setToSendData] = useState("CXB");
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [infant, setInfant] = useState(0);
  const [result, setResult] = useState(1);
  const [className, setClassName] = useState("Economy");

  const [click, setClick] = useState(false);

  const [multiSearchData, setMultiSearchData] = useState({
    // agentId: users?.user?.agentId || "",
    agentId: "",
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    CityCount: 2,
    tripType: 3,
    segments: [
      {
        id: 0,
        openFrom: false,
        depFrom: fromSendData.trim(),
        depAddress: faddress,
        depFromText: fromSearchText,
        arrTo: "JED",
        arrAddress: "Jeddah",
        arrToText: "Jeddah Intl (JED)",
        openTo: false,
        Date: new Date(from).toLocaleDateString("sv"),
        selectedDate: new Date(from),
        openDate: false,
        open: false,
      },
      {
        id: 1,
        openFrom: false,
        depFrom: "JED",
        depAddress: "Jeddah",
        depFromText: "Jeddah Intl (JED)",
        arrTo: "MED",
        arrAddress: "Madinah",
        arrToText: "Prince Mohammad Bin Abdulaziz Intl Airport(MED)",
        openTo: false,
        Date: new Date(to).toLocaleDateString("sv"),
        selectedDate: new Date(to),
        openDate: false,
        open: false,
      },
    ],
  });

  const handleTypeChange = (event, newValue) => {
    setType(newValue);
  };

  const handleTypeChanges = (event) => {
    setType(event.target.value);
  };

  return (
    <Box style={{ width: "100%", borderRadius: "5px", marginTop: "10px" }}>
      <Box>
        <Box
          sx={{
            padding: {
              lg: "20px 0px",
              md: "20px 0px",
              sm: "20px 0px",
              xs: "0px 0px",
            },
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
              },
              width: "100%",
              mb: "20px",
              // px:"20px",
            }}
          >
            {/* Inner Box component */}
            <Box
              sx={{
                background: "var(--white)",
                width: "100%",
                borderRadius: "5px",
                height: "37px",
                px: "10px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
                cursor: "pointer",
                justifyContent: "space-between",
                // zIndex: 9999,
                boxShadow: "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;",
              }}
            >
              <Box sx={{ gap: "5px", width: "100%" }}>
                <select
                  value={type}
                  onChange={handleTypeChanges}
                  style={{
                    outline: "none",
                    border: "none",
                    fontSize: "15px",
                    width: "100%",
                    background: "none",
                    color: "var(--black)",
                    borderRadius: "5px",
                  }}
                >
                  <option
                    value="flight"
                    style={{
                      color: "black",
                      fontSize: "12px",
                      fontWeight: "500",
                      borderRadius: "5px",
                    }}
                  >
                    Flight
                  </option>
                  <option
                    value="groupfare"
                    style={{
                      color: "black",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Group Fare
                  </option>

                  <option
                    value="hotel"
                    style={{
                      color: "black",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Hotel
                  </option>
                  <option
                    value="tour"
                    style={{
                      color: "black",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Tour
                  </option>
                  <option
                    value="visa"
                    style={{
                      color: "black",
                      fontSize: "12px",
                      fontWeight: "500",
                    }}
                  >
                    Visa
                  </option>
                </select>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }} className="home-search-box-2">
            <TabContext value={type}>
              <Box
                sx={{
                  width: "100%",
                  height: { lg: "50px", md: "50px", sm: "100%", xs: "100%" },
                  borderRadius: "0px",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: {
                    lg: "flex-start",
                    md: "flex-start",
                    sm: "center",
                    xs: "center",
                  },
                  opacity: "1",
                  ".MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                    padding: {
                      lg: "0px",
                      md: "0px",
                      sm: "0px 20px",
                      xs: "0px 20px",
                    },
                  },
                  "& button": {
                    borderRadius: "0px",
                    color: "var(--third-color)",
                    padding: {
                      lg: "0px",
                      md: "0px",
                      sm: "0px 10px",
                      // xs: "0px 20px",
                    },
                  },

                  "& button.Mui-selected,& button.Mui-selected >svg": {
                    color: "var(--white) !important",
                  },
                }}
              >
                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "5px 5px 0px 0px ",
                    overflow: "hidden",
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                  }}
                >
                  <TabList
                    value={type}
                    onChange={handleTypeChange}
                    TabIndicatorProps={{
                      style: { background: "primary", fontWeight: 600 },
                    }}
                    indicatorColor="none"
                  >
                    <Tab
                      icon={
                        <FlightIcon
                          sx={{
                            fontSize: {
                              xs: "15px",
                              sm: "20px",
                              color: "var(--secondary-color)",
                              transform: "rotate(45deg)",
                            },
                          }}
                        />
                      }
                      iconPosition="start"
                      label={
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          Flight
                        </span>
                      }
                      value="flight"
                      sx={{
                        width: "fit-content",
                        minHeight: "50px",
                        color: "var(--white)",
                        margin: { xs: "0px 0px", sm: "0px 12px" },
                        fontSize: { xs: "11px", sm: "14px" },
                        opacity: "1",
                        fontWeight: 400,
                      }}
                    />
                    <Tab
                      icon={
                        <GroupsIcon
                          sx={{
                            fontSize: {
                              xs: "15px",
                              sm: "20px",
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      iconPosition="start"
                      label={
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          Group Fare
                        </span>
                      }
                      value="groupfare"
                      sx={{
                        width: "fit-content",
                        minHeight: "50px",
                        margin: { xs: "0px 0px", sm: "0px 12px" },
                        fontSize: { xs: "11px", sm: "14px" },
                        opacity: "1",
                        color: "var(--primary-color)",
                        fontWeight: 400,
                      }}
                    />
                    <Tab
                      icon={
                        <HotelIcon
                          sx={{
                            fontSize: {
                              xs: "15px",
                              sm: "20px",
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      iconPosition="start"
                      label={
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          Hotel
                        </span>
                      }
                      value="hotel"
                      sx={{
                        width: "fit-content",
                        opacity: "1",
                        minHeight: "50px",
                        margin: { xs: "0px 0px", sm: "0px 12px" },
                        fontSize: { xs: "11px", sm: "14px" },
                        color: "var(--primary-color)",
                        fontWeight: 400,
                      }}
                    />

                    <Tab
                      icon={
                        <FestivalIcon
                          sx={{
                            fontSize: {
                              xs: "15px",
                              sm: "20px",
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      iconPosition="start"
                      label={
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          Tours
                        </span>
                      }
                      value="tour"
                      sx={{
                        width: "fit-content",
                        opacity: "1",
                        minHeight: "50px",
                        margin: { xs: "0px 0px", sm: "0px 12px" },
                        fontSize: { xs: "11px", sm: "14px" },
                        color: "var(--primary-color)",
                        fontWeight: 400,
                      }}
                    />
                    <Tab
                      icon={
                        <FestivalIcon
                          sx={{
                            fontSize: {
                              xs: "15px",
                              sm: "20px",
                              color: "var(--secondary-color)",
                            },
                          }}
                        />
                      }
                      iconPosition="start"
                      label={
                        <span style={{ fontSize: "13px", fontWeight: "500" }}>
                          Visa
                        </span>
                      }
                      value="visa"
                      sx={{
                        width: "fit-content",
                        opacity: "1",
                        minHeight: "50px",
                        margin: { xs: "0px 0px", sm: "0px 12px" },
                        fontSize: { xs: "11px", sm: "14px" },
                        color: "var(--primary-color)",
                        fontWeight: 400,
                      }}
                    />
                  </TabList>
                </Box>
              </Box>
              <Box
                style={{
                  background: "#fff",
                  width: "100%",
                  borderRadius: "0px 5px 5px 5px",
                }}
              >
                <TabPanel
                  value={"flight"}
                  style={{ padding: "20px 0px 0px 0px" }}
                >
                  <FlightSearchBox
                    setClick={setClick}
                    type={type}
                    setType={setType}
                    value={value}
                    setValue={setValue}
                    fromSearchText={fromSearchText}
                    setFromSearchText={setFromSearchText}
                    toSearchText={toSearchText}
                    setToSearchText={setToSearchText}
                    departureDate={departureDate}
                    setDepartureDate={setDepartureDate}
                    returningDate={returningDate}
                    setReturningDate={setReturningDate}
                    travelDate={travelDate}
                    setTravelDate={setTravelDate}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    faddress={faddress}
                    setfaddress={setfaddress}
                    toAddress={toAddress}
                    setToAddress={setToAddress}
                    fromSendData={fromSendData}
                    setFromSendData={setFromSendData}
                    toSendData={toSendData}
                    setToSendData={setToSendData}
                    adultCount={adultCount}
                    setAdultCount={setAdultCount}
                    childCount={childCount}
                    setChildCount={setChildCount}
                    infant={infant}
                    setInfant={setInfant}
                    result={result}
                    setResult={setResult}
                    className={className}
                    setClassName={setClassName}
                    setChangeState={setChangeState}
                    changeState={changeState}
                    changeFrom={changeFrom}
                    setChangeFrom={setChangeFrom}
                    multiSearchData={multiSearchData}
                    setMultiSearchData={setMultiSearchData}
                    agentId={agentId}
                  />
                </TabPanel>
                <TabPanel
                  value={"groupfare"}
                  style={{
                    padding:
                      location?.pathname === "/"
                        ? "20px 20px 20px 20px"
                        : "20px 20px 0px 0px",
                  }}
                >
                  <GroupFareSearchBox
                    type={type}
                    setType={setType}
                    value={value}
                    setValue={setValue}
                    fromSearchText={fromSearchText}
                    setFromSearchText={setFromSearchText}
                    toSearchText={toSearchText}
                    setToSearchText={setToSearchText}
                    departureDate={departureDate}
                    setDepartureDate={setDepartureDate}
                    returningDate={returningDate}
                    setReturningDate={setReturningDate}
                    travelDate={travelDate}
                    setTravelDate={setTravelDate}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    faddress={faddress}
                    setfaddress={setfaddress}
                    toAddress={toAddress}
                    setToAddress={setToAddress}
                    fromSendData={fromSendData}
                    setFromSendData={setFromSendData}
                    toSendData={toSendData}
                    setToSendData={setToSendData}
                    adultCount={adultCount}
                    setAdultCount={setAdultCount}
                    childCount={childCount}
                    setChildCount={setChildCount}
                    infant={infant}
                    setInfant={setInfant}
                    result={result}
                    setResult={setResult}
                    className={className}
                    setClassName={setClassName}
                    setChangeState={setChangeState}
                    changeState={changeState}
                    changeFrom={changeFrom}
                    setChangeFrom={setChangeFrom}
                    agentId={agentId}
                  />
                </TabPanel>
                <TabPanel
                  value={"tour"}
                  style={{
                    padding:
                      location?.pathname === "/"
                        ? isMobile? "0px 20px 0px 10px" : "20px 20px 0px 20px"
                        : "20px 20px 0px 0px",
                  }}
                >
                  <TourSearchBox
                    type={type}
                    setType={setType}
                    value={value}
                    setValue={setValue}
                    fromSearchText={fromSearchText}
                    setFromSearchText={setFromSearchText}
                    toSearchText={toSearchText}
                    setToSearchText={setToSearchText}
                    departureDate={departureDate}
                    setDepartureDate={setDepartureDate}
                    returningDate={returningDate}
                    setReturningDate={setReturningDate}
                    travelDate={travelDate}
                    setTravelDate={setTravelDate}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    faddress={faddress}
                    setfaddress={setfaddress}
                    toAddress={toAddress}
                    setToAddress={setToAddress}
                    fromSendData={fromSendData}
                    setFromSendData={setFromSendData}
                    toSendData={toSendData}
                    setToSendData={setToSendData}
                    adultCount={adultCount}
                    setAdultCount={setAdultCount}
                    childCount={childCount}
                    setChildCount={setChildCount}
                    infant={infant}
                    setInfant={setInfant}
                    result={result}
                    setResult={setResult}
                    className={className}
                    setClassName={setClassName}
                    setChangeState={setChangeState}
                    changeState={changeState}
                    changeFrom={changeFrom}
                    setChangeFrom={setChangeFrom}
                  />
                </TabPanel>

                <TabPanel
                  value={"umrah"}
                  style={{ padding: "20px 20px 0px 20px" }}
                >
                  <UmrahSearchBox
                    type={type}
                    setType={setType}
                    value={value}
                    setValue={setValue}
                    fromSearchText={fromSearchText}
                    setFromSearchText={setFromSearchText}
                    toSearchText={toSearchText}
                    setToSearchText={setToSearchText}
                    departureDate={departureDate}
                    setDepartureDate={setDepartureDate}
                    returningDate={returningDate}
                    setReturningDate={setReturningDate}
                    travelDate={travelDate}
                    setTravelDate={setTravelDate}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    faddress={faddress}
                    setfaddress={setfaddress}
                    toAddress={toAddress}
                    setToAddress={setToAddress}
                    fromSendData={fromSendData}
                    setFromSendData={setFromSendData}
                    toSendData={toSendData}
                    setToSendData={setToSendData}
                    adultCount={adultCount}
                    setAdultCount={setAdultCount}
                    childCount={childCount}
                    setChildCount={setChildCount}
                    infant={infant}
                    setInfant={setInfant}
                    result={result}
                    setResult={setResult}
                    className={className}
                    setClassName={setClassName}
                    setChangeState={setChangeState}
                    changeState={changeState}
                    changeFrom={changeFrom}
                    setChangeFrom={setChangeFrom}
                  />
                </TabPanel>

                <TabPanel
                  value={"hotel"}
                  style={{
                    padding:
                      location?.pathname === "/"
                        ? isMobile?"0px 0px 0px 0px" : "0px 20px 0px 20px"
                        : "0px 0px 0px 0px",
                  }}
                >
                  <HotelSearchBox
                    type={type}
                    setType={setType}
                    value={value}
                    setValue={setValue}
                    fromSearchText={fromSearchText}
                    setFromSearchText={setFromSearchText}
                    toSearchText={toSearchText}
                    setToSearchText={setToSearchText}
                    departureDate={departureDate}
                    setDepartureDate={setDepartureDate}
                    returningDate={returningDate}
                    setReturningDate={setReturningDate}
                    travelDate={travelDate}
                    setTravelDate={setTravelDate}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    faddress={faddress}
                    setfaddress={setfaddress}
                    toAddress={toAddress}
                    setToAddress={setToAddress}
                    fromSendData={fromSendData}
                    setFromSendData={setFromSendData}
                    toSendData={toSendData}
                    setToSendData={setToSendData}
                    adultCount={adultCount}
                    setAdultCount={setAdultCount}
                    childCount={childCount}
                    setChildCount={setChildCount}
                    infant={infant}
                    setInfant={setInfant}
                    result={result}
                    setResult={setResult}
                    className={className}
                    setClassName={setClassName}
                    setChangeState={setChangeState}
                    changeState={changeState}
                    changeFrom={changeFrom}
                    setChangeFrom={setChangeFrom}
                    agentId={agentId}
                  />
                </TabPanel>
                <TabPanel
                  value={"visa"}
                  style={{
                    padding:
                      location?.pathname === "/"
                        ? isMobile
                          ? "20px 20px 20px 10px"
                          : "20px 20px 0px 20px"
                        : "20px 20px 0px 0px",
                  }}
                >
                  <VisaSearch
                    type={type}
                    setType={setType}
                    value={value}
                    setValue={setValue}
                    fvisa={fvisa}
                    setfvisa={setfvisa}
                    typeVisa={typeVisa}
                    setTypeVisa={setTypeVisa}
                    fromSearchText={fromSearchText}
                    setFromSearchText={setFromSearchText}
                    toSearchText={toSearchText}
                    setToSearchText={setToSearchText}
                    departureDate={departureDate}
                    setDepartureDate={setDepartureDate}
                    returningDate={returningDate}
                    setReturningDate={setReturningDate}
                    travelDate={travelDate}
                    setTravelDate={setTravelDate}
                    from={from}
                    setFrom={setFrom}
                    to={to}
                    setTo={setTo}
                    faddress={faddress}
                    setfaddress={setfaddress}
                    toAddress={toAddress}
                    setToAddress={setToAddress}
                    fromSendData={fromSendData}
                    setFromSendData={setFromSendData}
                    toSendData={toSendData}
                    setToSendData={setToSendData}
                    adultCount={adultCount}
                    setAdultCount={setAdultCount}
                    childCount={childCount}
                    setChildCount={setChildCount}
                    infant={infant}
                    setInfant={setInfant}
                    result={result}
                    setResult={setResult}
                    className={className}
                    setClassName={setClassName}
                    setChangeState={setChangeState}
                    changeState={changeState}
                    changeFrom={changeFrom}
                    setChangeFrom={setChangeFrom}
                  />
                </TabPanel>
              </Box>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeSearchBox;
