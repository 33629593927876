import { Box, Typography } from "@mui/material";
import React from "react";
import { useLocation } from "react-router-dom";
import UserHeader from "../UserPage/UserHeader/UserHeader";

const Notice = () => {
  const location = useLocation();

  //   console.log(location);

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "100%", margin: "0 auto" }}>
        <Box
          sx={{
            backgroundColor: "var(--white)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            p: "20px",
            m: "0 auto",
            width: "90%",
            mt: "-10vh",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontWeight: 500,
              fontSize: "18px !important",
              color: "var(--black)",
              fontFamily: "poppins",
            }}
          >
            Notice
          </Typography>

          <Typography sx={{ mt: "20px", fontSize: "15px", color: "#444542" }}>
            {location?.state?.title}
          </Typography>
          <Typography sx={{ mt: "10px", fontSize: "13px" }}>
            {location?.state?.description}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Notice;
