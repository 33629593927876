import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";

const AirlinesSlider = ({
  initialFetchData,
  selectedAirLines,
  setSelectedAirLines,
}) => {
  const careerCounts = initialFetchData?.reduce((counts, flight) => {
    counts[flight?.career || flight?.Carrier] =
      (counts[flight?.career || flight?.Carrier] || 0) + 1;
    return counts;
  }, {});

  // Step 2: Generate an array of objects with career, careerName, and flightNumber
  const results = Object.entries(careerCounts).map(([career, flightNumber]) => {
    const flight = initialFetchData.find(
      (item) => item?.career || item?.Carrier === career
    );
    return {
      career,
      careerName: flight?.careerName || flight.CarrierName,
      flightNumber,
    };
  });

  // Do not remove below codes that are commented

  // useEffect(() => {
  //   const filter = () => {
  //     let copyFilteredData = [...initialFetchData];
  //     if (copyFilteredData.length > 0) {
  //       const updatedFlightes = copyFilteredData.filter((item) =>
  //         selectedAirLines.includes(item.career)
  //       );
  //       setData2(updatedFlightes);
  //       copyFilteredData = [...updatedFlightes];
  //       if (copyFilteredData.length < 1) {
  //         setData2(initialFetchData);
  //       }
  //     }
  //   };
  //   filter();
  // }, [selectedAirLines]);

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          backgroundColor: "var(--primary-color)",
          width: "25px",
          position: "absolute",
          height: "100%",
          right: "0",
          top: "0",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            fontSize: "40px",
            margin: "0",
            color: "var(--secondary-color)",
          }}
        >
          &#8250;
        </p>
      </div>
    );
  };
  const CustomPrevArrow = (props) => {
    const { onClick } = props;

    return (
      <div
        onClick={onClick}
        style={{
          backgroundColor: "var(--primary-color)",
          width: "25px",
          position: "absolute",
          height: "100%",
          left: "0",
          top: "0",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            fontSize: "40px",
            margin: "0",
            color: "var(--secondary-color)",
          }}
        >
          &#8249;
        </p>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      results.length > 4 ? 5 : results.length > 5 ? 6 : results.length,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    centerPadding: "60px",
  };

  return (
    <Slider {...settings}>
      {results.map((res, i) => (
        <Box
          key={i}
          onClick={() => {
            if (selectedAirLines.includes(res.career)) {
              setSelectedAirLines(
                selectedAirLines.filter((item) => item !== res.career)
              );
            } else {
              setSelectedAirLines([...selectedAirLines, res.career]);
            }
          }}
          sx={{
            height: {
              xs: "60px",
              sm: "60px",
              md: "48px",
              lg: "48px",
            },
          }}
        >
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            spacing={1}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "90%",
                lg: "90%",
              },
              px: "5px",
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
              },
              margin: "0 auto",
              bgcolor: selectedAirLines.includes(res.career)
                ? "var(--secondary-color)"
                : "",
              cursor: "pointer",
              ":hover": {
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                bgcolor: "rgba(99, 99, 99, 0.2)",
              },
              borderRadius: "5px",
              transition: "all .2s ease-in-out",
              height: "100%",
            }}
          >
            <Box sx={{ display: "flex", gap: "5px", alignItems: "center" }}>
              <img
                style={{ width: "28px" }}
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${res.career}.png`}
                alt=""
              />
            </Box>
            <Box
              sx={{
                ".MuiTypography-body1": {
                  fontSize: "13px",
                  color: "var(--dark-grey)",
                },
                ".MuiTypography-body2": {
                  fontSize: "5px",

                  color: "var(--primary-color)",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: {
                    xs: "4px",
                    sm: "5px",
                  },
                }}
              >
                <span
                  style={{ color: "var(--primary-color)", paddingRight: "5px" }}
                >
                  {res.career}
                </span>
                {res.flightNumber} Flights
              </Typography>
            </Box>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "90%",
                lg: "90%",
              },
              display: {
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
              },
              margin: "0 auto",
              bgcolor: selectedAirLines.includes(res.career)
                ? "var(--secondary-color)"
                : "",
              cursor: "pointer",
              ":hover": {
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                bgcolor: "rgba(99, 99, 99, 0.2)",
              },
              borderRadius: "5px",
              transition: "all .2s ease-in-out",
              height: "100%",
            }}
          >
            <Box>
              <img
                style={{ width: "30px" }}
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${res.career}.png`}
                alt=""
              />
            </Box>
            <Box
              sx={{
                ".MuiTypography-body1": {
                  fontSize: "13px",
                  color: "var(--dark-grey)",
                },
                ".MuiTypography-body2": {
                  fontSize: {
                    xs: "7px",
                    sm: "8px",
                    md: "13px",
                    lg: "13px",
                  },

                  color: "var(--primary-color)",
                },
              }}
            >
              <Typography variant="body2">{res.career}</Typography>
              <Typography
                sx={{
                  fontSize: {
                    xs: "5px",
                    sm: "8px",
                    md: "13px",
                    lg: "13px",
                  },
                }}
              >
                {res.flightNumber} Flights
              </Typography>
            </Box>
          </Stack>
        </Box>
      ))}
    </Slider>
  );
};

export default AirlinesSlider;
