import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import { Button } from "@mui/material";
import { Link, json, useNavigate } from "react-router-dom";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserHeader from "../../UserPage/UserHeader/UserHeader";
import moment from "moment";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Swal from "sweetalert2";
import secureLocalStorage from "react-secure-storage";
const isMobile = window.innerWidth <= 768;
const Staff = () => {
  const [allStaffs, setAllStaffs] = useState([]);

  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");

  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/b2b/staff/index.php?agentId=${user?.agent_id}`
    )
      .then((res) => res.json())
      .then((data) => {
        setAllStaffs(data?.staffs);
      });
  }, []);

  console.log(allStaffs);

  const styles = {
    root: {
      height: "calc(100vh - 200px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
     
    },
    dataGrid: {
      flexGrow: 1,
    },
    "@media (max-width: 600px)": {
      root: {
        height: "calc(100vh - 100px)",
        width: "50%",
      },
    },
    "@media (min-width: 600px) and (max-width: 900px)": {
      root: {
        height: "calc(100vh - 150px)",
      },
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      root: {
        height: "calc(100vh - 175px)",
      },
    },
    "@media (min-width: 1200px) and (max-width: 1536px)": {
      root: {
        height: "calc(100vh - 190px)",
      },
    },
  };

  const handleStaffDelete = async (staff) => {
    const body = {
      agentId: user.agent_id,
      staffId: staff.staff_id,
      email: staff.staff_email,
    };
    console.log(body);

    try {
      Swal.fire({
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
        title: "Confirmation",
        text: `Do you want to remove ${staff.agent_id}`,
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Ok",
      }).then(async function (res) {
        if (res.isConfirmed) {
          await axios
            .post(
              "https://api.rtc.flyfarint.com/v1.0/b2b/staff/delete.php",
              JSON.stringify(body),
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
            .then((res) => {
              console.log(res);
              if (res.data.status === "success") {
                Swal.fire({
                  imageWidth: 400,
                  imageHeight: 200,
                  imageAlt: "Custom image",
                  title: "Confirmation",
                  text: `${res.data.message}`,
                  confirmButtonColor: "var(--primary-color)",
                  confirmButtonText: "Ok",
                }).then(() => {
                  navigate(0);
                });
              }
            });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  // console.log(allStaffs);

  const columns = [
    {
      field: "staff_id",
      headerName: "Staff ID",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "staff_name",
      headerName: "Name",
      suppressSizeToFit: true,
      width: 200,
    },

    {
      field: "staff_email",
      headerName: "Email",
      suppressSizeToFit: true,
      width: 200,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.staff_email}</p>;
      },
    },

    {
      field: "staff_password",
      headerName: "Password",
      suppressSizeToFit: true,
      width: 170,
    },

    {
      field: "staff_phone_number",
      headerName: "Phone Number",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "staff_designation",
      headerName: "Designation",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "staff_role",
      headerName: "Role",
      suppressSizeToFit: true,
      width: 150,
    },

    {
      field: "staff_joined_at",
      headerName: "Joined At",
      suppressSizeToFit: true,
      width: 200,
      renderCell: (params) => {
        return (
          <p style={{fontSize:"10px"}}>
            {moment(params.row.staff_joined_at.slice(0, 19)).format(
              "hh:mm:ss A"
            )}{" "}
            {" || "}{" "}
            {moment(params.row.staff_joined_at.slice(0, 19)).format(
              "D MMM YYYY"
            )}
          </p>
        );
      },
    },
    {
      field: "staff_updated_at",
      headerName: "Updated At",
      suppressSizeToFit: true,
      width: 200,
    },

    {
      field: "",
      headerName: "Operation",
      suppressSizeToFit: true,
      width: 50,
      renderCell: (params) => {
        return (
          <>
            <Box sx={{ display: "flex" }}>
              <DeleteIcon
                style={{
                  color: "var(--red)",
                  fontSize: "18px",
                  marginLeft: "10px",
                  cursor: "pointer",
                }}
                onClick={() => handleStaffDelete(params.row)}
              />
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "100%", margin: "0 auto" }}>
        <Box
          sx={{
            backgroundColor: "var(--white)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            p: "20px",
            m: "0 auto",
            width: "90%",
            mt: "-10vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "18px !important",
                color: "var(--black)",
                fontFamily: "poppins",
              }}
            >
              All Staffs
            </Typography>

            <Box sx={{ display: "flex", gap: 5 }}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "var(--primary-color)",
                }}
                to={"/dashboard/addstaff"}
              >
                <Button
                  sx={{
                    width: "120px",
                    fontSize: "11px",
                    textTransform: "capitalize",
                    // height: "28px",
                    background: "black",
                    color: "white",
                    "&:hover": {
                      background: "black",
                    },
                  }}
                >
                  Add Staff
                </Button>
              </Link>
            </Box>
          </Box>

          <Box
            style={styles.root}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize:"10px"
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid var(--input-bgcolor)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color)",
                borderBottom: "none",
                color: "var(--white)",
                fontSize: "10px",
                textTransform: "uppercase",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "var(--body-color)",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
              },
              "& .MuiDataGrid-toolbarContainer": {
                marginLeft: "auto",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "var(--primary-color)",
                background: "var(--secondary-color)",
                fontSize: "10px",
                mb: 2,
              },
              ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
                color: "var(--white)",
              },
              ".MuiTablePagination-selectLabel": {
                color: "var(--white)",
              },
              ".MuiSelect-select": {
                color: "var(--white)",
              },
              ".css-levciy-MuiTablePagination-displayedRows": {
                color: "var(--white)",
              },
              ".MuiDataGrid-cellContent": {
                color: "var(--text-color)",
              },
            }}
          >
            {isMobile ? (
              <>
                <Grid sx={{ mt: "20px" }} container gap={2}>
                  {allStaffs?.map((sales) => (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={sales?.id}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "flex",
                          md: "flex",
                        },
                        transition: "all .5s ease-in-out",
                        background: "var(--gray)",
                        borderRadius: "5px",
                        width: "100%",
                        borderRadius: "10px",
                        px: "20px",
                        py: "25px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            Staff Id
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales.staff_id}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "30px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Name
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.staff_name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Email
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.staff_email}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Password
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.staff_password}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Phone Number
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.staff_phone_number}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Designation
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.staff_designation}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Role
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.staff_role}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Joined At
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {moment(sales?.staff_joined_at.slice(0, 19)).format(
                              "hh:mm:ss A"
                            )}{" "}
                            {" || "}{" "}
                            {moment(sales?.staff_joined_at.slice(0, 19)).format(
                              "D MMM YYYY"
                            )}
                          </Typography>
                        </Box>

                        <Grid
                          item
                          direction={{ xs: "row", sm: "row", md: "row" }}
                          spacing={1}
                          alignItems="flex-start"
                          sx={{
                            width: "100%",
                            height: "100%",
                            // padding: {
                            //   md: "8px",
                            //   xs: "0px",
                            // },
                            mt: "30px",
                            pb: "14px",
                            borderTop: "1px solid var(--primary-color)",
                            // borderTop: {
                            //   xs: "none",
                            //   sm: "1px solid var(--primary-color)",
                            // },
                            pl: { xs: 2, sm: 2 },
                          }}
                        ></Grid>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Operation
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <DeleteIcon
                                style={{
                                  color: "var(--red)",
                                  fontSize: "18px",
                                  marginLeft: "10px",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleStaffDelete(sales)}
                              />
                            </Box>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <DataGrid
                  rows={allStaffs}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Staff;
