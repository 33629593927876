import {
  AppBar,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Menu,
  ClickAwayListener,
  Dialog,
  Divider,
  Chip,
  CircularProgress,
  FormControlLabel,
} from "@mui/material";
import React, { useState } from "react";
import Slide from "@mui/material/Slide";
import PhoneInput from "react-phone-input-2";
import { NavLink, useNavigate } from "react-router-dom";
import { Container } from "@mui/system";
import { styled } from "@mui/material/styles";
import secureLocalStorage from "react-secure-storage";
import "./Header.css";
import axios from "axios";
import Swal from "sweetalert2";
import logo from "../../images/logo/rtclogo.png";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Checkbox from "@mui/material/Checkbox";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Popup = ({ book, setBook }) => {
 // const location = useLocation();
 const navigate = useNavigate();
 const [isClicked, setIsClicked] = useState(false);
 const label = { inputProps: { "aria-label": "Checkbox demo" } };
 // console.log(admin);
 const [signinColor, setSigninColor] = useState(false);

 const [showLoginPassword, setShowLoginPassword] = useState(false);
 const [showAgentSignUpPassword, setShowAgentSignUpPassword] = useState(false);
 const [open, setOpen] = React.useState(false);
 const [signOpen, setSignOpen] = useState(book);
 const [rememberMe, setRememberMe] = useState(false);
 const [showPassword, setShowPassword] = useState({
   signInpassword: false,
   userSignUpPassword: false,
   userSignUpConfirmPassword: false,
   agentSignUpPassword: false,
 });


console.log(book)

 const handleClickOpen = () => {
   setOpen(true);
   setSignOpen(false);

   setFormErrors({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    signInEmail: "",
    signInpassword: "",
    compName: "",
    compAddress: "",
    country: "",
    tinImg: "",
  });
 };

 const handleClose = () => {
   setOpen(false);
   setSigninColor(false);

   setFormErrors({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    signInEmail: "",
    signInpassword: "",
    compName: "",
    compAddress: "",
    country: "",
    tinImg: "",
  });
 };

 const handleSignInOpen = () => {
   setSignOpen(true);
   setOpen(false);
   setSigninColor(false);
   setFormErrors({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    signInEmail: "",
    signInpassword: "",
    compName: "",
    compAddress: "",
    country: "",
    tinImg: "",
  });
 };

 const handleSignClose = () => {
   setSignOpen(false);
   setSigninColor(false);
   setBook(false);
   setFormErrors({
    name: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
    signInEmail: "",
    signInpassword: "",
    compName: "",
    compAddress: "",
    country: "",
    tinImg: "",
  });
 };

 const [agentSignUpData, setAgentSignUpData] = useState({
   name: "",
   compName: "",
   compAddress: "",
   phone: "",
   country: "",
   email: "",
   password: "",
   tinImg: {},
 });

 

 const handleAgentFormChange = (e) => {
   const { name, value, files } = e.target;
   console.log(name, value);
   setAgentSignUpData({
     ...agentSignUpData,
     [name]: name === "tinImg" ? files[0] : value,
   });

   setFormErrors({
     ...formErrors,
     [name]: (!!value || !!files) === true && "",
   });
 };

 const [formErrors, setFormErrors] = useState({
   name: "",
   phone: "",
   email: "",
   password: "",
   confirmPassword: "",
   signInEmail: "",
   signInpassword: "",
   compName: "",
   compAddress: "",
   country: "",
   tinImg: "",
 });

 console.log(agentSignUpData);



 const handleSignUp = async (e) => {
   e.preventDefault();

   if (agentSignUpData.name.trim() === "") {
     setFormErrors({
       ...formErrors,
       name: "Name cannot be empty",
     });
     return;
   }

   if (agentSignUpData.compName.trim() === "") {
     setFormErrors({
       ...formErrors,
       compName: "Company Name cannot be empty",
     });
     return;
   }

   if (agentSignUpData.phone.trim() === "") {
     setFormErrors({
       ...formErrors,
       phone: "Phone number cannot be empty",
     });
     return;
   }

   if (
     !!agentSignUpData.tinImg?.name === false ||
     agentSignUpData.tinImg === undefined
   ) {
     setFormErrors({
       ...formErrors,
       tinImg: "Upload TIN image",
     });
     return;
   }

   if (agentSignUpData.country.trim() === "") {
     setFormErrors({
       ...formErrors,
       country: "Country name cannot be empty",
     });
     return;
   }

   if (agentSignUpData.compAddress.trim() === "") {
     setFormErrors({
       ...formErrors,
       compAddress: "Company address cannot be empty",
     });
     return;
   }

   if (agentSignUpData.email.trim() === "") {
     setFormErrors({
       ...formErrors,
       email: "Email cannot be empty",
     });

     return;
   }

   if (agentSignUpData.password.trim() === "") {
     setFormErrors({
       ...formErrors,
       password: "Password cannot be empty",
     });
     return;
   }

   if (agentSignUpData.password.length < 8) {
     setFormErrors({
       ...formErrors,
       password: "Password must be at least 8 characters long",
     });
     return;
   }

   setIsClicked(true);

   const formData = new FormData();

   formData.append("name", agentSignUpData.name);
   formData.append("compName", agentSignUpData.compName);
   formData.append("compAddress", agentSignUpData.compAddress);
   formData.append("phone", agentSignUpData.phone);
   formData.append("country", agentSignUpData.country);
   formData.append("email", agentSignUpData.email);
   formData.append("password", agentSignUpData.password);
   formData.append("tinImg", agentSignUpData.tinImg);

   try {
     await axios
       .post(
         "https://api.rtc.flyfarint.com/v1.0/b2b/auth/registration/registration.php",
         formData,
         {
           headers: {
             "Content-Type": "application/x-www-form-urlencoded",
           },
         }
       )
       .then((res) => {
         console.log(res);

         if (res.data.status === "success") {
           secureLocalStorage.setItem(
             "agent-info",
             JSON.stringify(res.data.agent)
           );

           setSignOpen(false);
           setOpen(false);
           setIsClicked(false);
           setAgentSignUpData({
             name: "",
             compName: "",
             compAddress: "",
             phone: "",
             country: "",
             email: "",
             password: "",
             tinImg: {},
           });
           Swal.fire({
             imageWidth: 400,
             imageHeight: 200,
             imageAlt: "Custom image",
             title: res.data.status,
             text: res.data.message,
             confirmButtonColor: "var(--primary-color)",
             confirmButtonText: "Ok",
           }).then(async function (res) {
             navigate("/otpVerification", {
               state: {
                 agentSignUpData: {
                   ...agentSignUpData,
                   tinImageFile: agentSignUpData.tinImgFile,
                 },
               },
             });
             // e.target.reset();
           });
         } else {
           setSignOpen(false);
           setOpen(false);
           setIsClicked(false);
           Swal.fire({
             imageWidth: 400,
             imageHeight: 200,
             imageAlt: "Custom image",
             title: "Error",
             text: res.data.message,
             confirmButtonColor: "var(--primary-color)",
             confirmButtonText: "Ok",
           }).then(async function (res) {
             navigate("/");
           });
         }
       });
   } catch (err) {
     console.log(err);
   }
 };

 const handleLogin = async (e) => {
   e.preventDefault();

   const email = e.target.email.value;
   const password = e.target.password.value;

   if (email.trim() === "") {
     setFormErrors({
       ...formErrors,
       email: "Email cannot be empty",
     });

     return;
   }

   if (password.trim() === "") {
     setFormErrors({
       ...formErrors,
       password: "Password cannot be empty",
     });

     return;
   }
   try {
     const url = "https://api.rtc.flyfarint.com/v1.0/b2b/auth/index.php";
     const formData = new FormData();
     formData.append("email", email);
     formData.append("password", password);

     if (email && password) {
       await axios
         .post(url, formData, {
           headers: {
             "Content-Type": "application/x-www-form-urlencoded",
           },
         })
         .then((res) => {
           if (res.data.status === "success" && res.data?.agent?.agent_id) {
             secureLocalStorage.setItem("user-info", res.data.agent);
             secureLocalStorage.setItem("show-ad", true);
             setSignOpen(false);
             navigate("/dashboard");
           } else if (
             res.data.status === "success" &&
             res.data?.staff?.staff_id
           ) {
             secureLocalStorage.setItem("user-info", res.data.staff);
             secureLocalStorage.setItem("show-ad", true);
             setSignOpen(false);
             navigate("/dashboard");
           } else {
             setSignOpen(false);
             Swal.fire({
               imageWidth: 400,
               imageHeight: 200,
               imageAlt: "Custom image",
               title: `${res.data.status.toUpperCase()}`,
               text: `${res.data.message}`,
               confirmButtonColor: "var(--primary-color)",
               confirmButtonText: "Ok",
             }).then(async function (res) {
               setSignOpen(true);
             });
           }
         });
     }
   } catch (err) {
     console.log(err);
   }
 };
  return (
    <ClickAwayListener>
      <Box sx={{ width: "76%", m: "0 auto" }}>
        {/* Sign Up Page */}
        <Dialog
          open={open}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="xl"
          sx={{
            height: { xs: "100vh", sm: "100vh", md: "100vh" },
          }}
        >
          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "1000px",
                lg: "1000px",
              },
              // height: "700px",
              p: "30px",
              borderRadius: "10px",
              scrollbarWidth: "thin",
              "&::-webkit-scrollbar": {
                width: "0.2em",
              },
              "&::-webkit-scrollbar": { width: "5px" },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "transparent",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "26px",
                  lg: "26px",
                },
                color: "var(--mate-black)",
                fontWeight: "500",
              }}
            >
              Sign Up As Travel Agent
            </Typography>
            <Typography
              sx={{
                mt: "2px",
                fontSize: {
                  xs: "10.5px",
                  sm: "12px",
                  md: "14px",
                  lg: "14px",
                },
              }}
            >
              Already have a agent Account?{" "}
              <span
                style={{
                  color: "var(--primary-color)",
                  cursor: "pointer",
                  fontWeight: "500",
                }}
                onClick={handleSignInOpen}
              >
                {" "}
                Sign In
              </span>
            </Typography>

            <form onSubmit={handleSignUp}>
              <Grid container mt={1}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Full Name</label> <br></br>
                  <input
                    onChange={handleAgentFormChange}
                    onFocus={handleAgentFormChange}
                    type="text"
                    name="name"
                    value={agentSignUpData.name}
                    placeholder="Full Name"
                    style={{
                      width: "100%",
                      borderBottom: formErrors.name ? "1px solid red" : "",
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.name}
                  </p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Company Name</label> <br></br>
                  <input
                    onChange={handleAgentFormChange}
                    onFocus={handleAgentFormChange}
                    type="text"
                    name="compName"
                    value={agentSignUpData.compName}
                    placeholder="Company Name"
                    style={{
                      width: "100%",
                      borderBottom: formErrors.compName ? "1px solid red" : "",
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.compName}
                  </p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Phone Number</label> <br></br>
                  <Box
                    style={{
                      background: "var(--input-bgcolor)",
                      width: "450px",
                      paddingBottom: "0px",
                      borderRadius: "none",
                      width: "100%",
                    }}
                  >
                    <PhoneInput
                      onChange={(phone) => {
                        setAgentSignUpData({
                          ...agentSignUpData,
                          phone: phone,
                        });
                      }}
                      onFocus={() => {
                        setFormErrors({
                          ...formErrors,
                          phone: "",
                        });
                      }}
                      style={{
                        width: "100%",
                        borderBottom: formErrors.phone
                          ? "1px solid red"
                          : "2px solid #b6b6cc",
                      }}
                      country={"bd"}
                      placeholder="Contact Number"
                      id="contactNumber"
                      // name="phone"
                      // defaultCountry="BD"
                      value={agentSignUpData.phone}
                      inputProps={{
                        name: "phone",
                        autoFocus: true,
                      }}
                    />
                  </Box>
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.phone}
                  </p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Tin File</label> <br />
                  <input
                    onChange={handleAgentFormChange}
                    onFocus={handleAgentFormChange}
                    style={{
                      borderBottom: "none",
                      display: "block",
                      outline: "none",
                      border: "none",
                      borderRadius: "2px",
                      height: "30px",
                      fontSize: "14px",
                      marginBottom: "1px",
                      width: "100%",
                      padding: "5px 10px",
                      backgroundColor: "var(--secondary-color)",
                      marginTop: "8px",
                    }}
                    type="file"
                    name="tinImg"
                    placeholder="Tin File"
                    className="customFileType"
                    accept=".jpg, .jpeg, .webp, .png, .pdf"
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.tinImg}
                  </p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Country</label> <br></br>
                  <input
                    onChange={handleAgentFormChange}
                    onFocus={handleAgentFormChange}
                    type="text"
                    name="country"
                    placeholder="Country"
                    style={{
                      width: "100%",
                      borderBottom: formErrors.country ? "1px solid red" : "",
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.country}
                  </p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Company Address</label>{" "}
                  <br></br>
                  <input
                    onChange={handleAgentFormChange}
                    onFocus={handleAgentFormChange}
                    type="text"
                    name="compAddress"
                    placeholder="Company Address"
                    style={{
                      width: "100%",
                      borderBottom: formErrors.compAddress
                        ? "1px solid red"
                        : "",
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.compAddress}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Email</label> <br></br>
                  <input
                    onChange={handleAgentFormChange}
                    onFocus={handleAgentFormChange}
                    type="email"
                    name="email"
                    placeholder="Enter Email"
                    style={{
                      width: "100%",
                      borderBottom: formErrors.email ? "1px solid red" : "",
                    }}
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.email}
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Password</label> <br></br>
                  <input
                    onChange={handleAgentFormChange}
                    onFocus={handleAgentFormChange}
                    type={showAgentSignUpPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    style={{
                      width: "100%",
                      borderBottom: formErrors.password ? "1px solid red" : "",
                    }}
                  />
                  <Box
                    style={{
                      position: "absolute",
                      bottom: "10px",
                      right: "20px",
                    }}
                  >
                    {showAgentSignUpPassword ? (
                      <VisibilityIcon
                        onClick={() => {
                          setShowAgentSignUpPassword(false);
                        }}
                        sx={{
                          color: "var(--primary-color)",
                          cursor: "pointer",
                          width: "18px",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => {
                          setShowAgentSignUpPassword(true);
                        }}
                        sx={{
                          color: "var(--primary-color)",
                          cursor: "pointer",
                          width: "18px",
                        }}
                      />
                    )}
                  </Box>
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.password}
                  </p>
                </Grid>
              </Grid>
              <Button
                type="submit"
                disabled={isClicked}
                sx={{
                  color: "var(--white)",
                  bgcolor: "var(--mate-black)",
                  fontSize: "14px",
                  height: "36px",
                  width: "",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "310px",
                    lg: "310px",
                  },
                  borderRadius: "4px",
                  mt: "30px",
                  ":hover": {
                    bgcolor: "var(--mate-black)",
                  },
                }}
              >
                {isClicked ? (
                  <>
                    <span style={{ color: "#adadad" }}>Please Wait</span>
                    <CircularProgress
                      size={20}
                      sx={{ color: "#adadad", ml: "10px" }}
                    />
                  </>
                ) : (
                  "Register As Agent"
                )}
              </Button>
            </form>
          </Box>
        </Dialog>

        {/* Sign in Page */}
        <Dialog
          open={signOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleSignClose}
          aria-describedby="alert-dialog-slide-description"
          maxWidth="xl"
          sx={{
            height: { xs: "100vh", sm: "100vh", md: "100vh" },
          }}
        >
          <Box
            sx={{
              mt: "0",
              width: {
                xs: "100%",
                sm: "100%",
                md: "660px",
                lg: "660px",
              },
              height: signinColor ? "500px" : "442px",
              p: "30px",
            }}
          >
            <form onSubmit={handleLogin}>
              <Typography
                sx={{
                  fontSize: "26px",
                  color: "var(--mate-black)",
                  fontWeight: "500",
                }}
              >
                Sign In
              </Typography>
              <Grid container sx={{ mt: "0" }}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  sx={{ position: "relative", p: { md: "0 15px 20px 0px" } }}
                >
                  <label className="label-style">Email</label> <br></br>
                  <input
                    onFocus={() => {
                      setFormErrors({
                        ...formErrors,
                        email: "",
                      });
                    }}
                    style={{
                      borderBottom: formErrors.email ? "1px solid red" : "",
                    }}
                    className="sign-input"
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                  />
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.email}
                  </p>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={12}
                  sx={{ position: "relative", p: { md: "0 15px 15px 0px" } }}
                >
                  <label className="label-style">Password</label> <br></br>
                  <input
                    onFocus={() => {
                      setFormErrors({
                        ...formErrors,
                        password: "",
                      });
                    }}
                    className="sign-input"
                    style={{ borderBottom: signinColor ? "1px solid red" : "" }}
                    type={showLoginPassword ? "text" : "password"}
                    name="password"
                    placeholder="Enter Your Password"
                  />
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: "10px",
                      right: "20px",
                    }}
                  >
                    {showLoginPassword ? (
                      <VisibilityIcon
                        onClick={() => {
                          setShowLoginPassword(false);
                        }}
                        sx={{
                          color: "var(--primary-color)",
                          cursor: "pointer",
                          width: "18px",
                        }}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => {
                          setShowLoginPassword(true);
                        }}
                        sx={{
                          color: "var(--primary-color)",
                          cursor: "pointer",
                          width: "18px",
                        }}
                      />
                    )}
                  </Box>
                  <p
                    style={{
                      color: "red",
                      fontSize: "10px",
                      position: "absolute",
                      bottom: "-13px",
                      left: "0",
                    }}
                  >
                    {formErrors.password}
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Checkbox
                        checked={rememberMe}
                        onChange={(e) => setRememberMe(e.target.checked)}
                        sx={{ pl: "0px" }}
                        {...label}
                        onClick={() => setRememberMe(!rememberMe)}
                      />
                      <label
                        style={{
                          color: "var(--mate-black)",
                          fontSize: "13px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                        onClick={() => setRememberMe(!rememberMe)}
                      >
                        Remember Me
                      </label>
                    </Box>
                    <Box>
                      <Typography
                        onClick={() => {
                          navigate("/forgetPassword");
                        }}
                        sx={{
                          textAlign: "right",
                          cursor: "pointer",
                          fontSize: {
                            xs: "10px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                          },
                          mt: "5px",
                        }}
                      >
                        Forget Password ?
                      </Typography>
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={12}>
                  <Button
                    type="submit"
                    sx={{
                      textTransform: "none",
                      textAlign: "center",
                      width: "100%",
                      bgcolor: "var(--primary-color)",
                      color: "var(--white)",
                      ":hover": {
                        bgcolor: "var(--primary-color)",
                      },
                    }}
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
              <Box
                sx={{
                  ".MuiDivider-root::before": {
                    borderTop: "2px solid #B6B6CC",
                  },
                  ".MuiDivider-root::after": {
                    borderTop: "2px solid #B6B6CC",
                  },
                }}
              >
                <Divider
                  sx={{
                    mt: "20px",
                    color: "#B6B6CC",
                    // borderBottom: "2px solid", // Change the thickness here (2px in this example)
                  }}
                >
                  <Chip
                    sx={{
                      bgcolor: "var(--white)",
                      color: "var(--primary-color)",
                      fontSize: "16px",
                    }}
                    label="OR"
                  />
                </Divider>
              </Box>
              <Typography
                sx={{
                  mt: "2px",
                  fontSize: {
                    xs: "12px",
                    sm: "12px",
                    md: "14px",
                    lg: "14px",
                  },
                  textAlign: "center",
                }}
              >
                Don't have an Account?
                <Button
                  style={{
                    color: "var(--primary-color)",
                    cursor: "pointer",
                    fontWeight: "500",
                    textTransform: "none",
                  }}
                  onClick={handleClickOpen}
                >
                  Sign Up
                </Button>
              </Typography>
            </form>
          </Box>
        </Dialog>
      </Box>
    </ClickAwayListener>
  );
};

export default Popup;
