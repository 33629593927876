import { Box, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import FlightCard from "./FlightCard";

// import FlightCard from "./FlightCard";

const RoundwayFlightDetails = ({ segments, roundData }) => {
  const { go, back } = segments;
  const [toogleSegments, setToogleSegments] = useState("go");

  const calculatteTransiteTime = (arrivalTime, departureTime) => {
    const segmentTwoDeparture = new Date(departureTime);
    const segmentOneArrival = new Date(arrivalTime);
  
    // Calculate the time difference in milliseconds
    const timeDifferenceMs = segmentTwoDeparture - segmentOneArrival;
  
    // Convert the time difference to hours and minutes
    const hours = Math.floor(timeDifferenceMs / (60 * 60 * 1000));
    const minutes = Math.floor(
      (timeDifferenceMs % (60 * 60 * 1000)) / (60 * 1000)
    );
  
    return `${hours}H ${minutes}Min`;
  };
  



  return (
    <Box
      sx={{
        mt: "20PX",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },

        // border: "1px solid red",
      }}
    >
      {toogleSegments === "go" &&
        go.map((segment, i) => {
          return (
            <FlightCard
              segmentPropName={"go"}
              key={i}
              segment={segment}
              roundData={roundData}
              segmentData={go}
              index={i}
              calculatteTransiteTime={calculatteTransiteTime}
            />
          );
        })}
      {toogleSegments === "back" &&
        back.map((segment, i) => {
          return (
            <FlightCard
              segmentPropName={"back"}
              key={i}
              roundData={roundData}
              segment={segment}
              segmentData={back}
              index={i}
              calculatteTransiteTime={calculatteTransiteTime}
            />
          );
        })}
      <Stack direction="row" justifyContent="flex-end" mt={5}>
        <Typography
          sx={{
            padding: "5px 10px",
            bgcolor: `${toogleSegments === "go" ? "var(--primary-color)" : "#D3E0F0"}`,
            width: "max-content",
            color: `${toogleSegments === "go" ? "white" : "#444542"}`,
            cursor: "pointer",
          }}
          onClick={() => {
            setToogleSegments("go");
          }}
        >
          {roundData.godeparture} - {roundData.goarrival}
        </Typography>
        <Typography
          sx={{
            padding: "5px 10px",
            bgcolor: `${toogleSegments === "back" ? "var(--primary-color)"  : "#D3E0F0"}`,
            width: "max-content",
            color: `${toogleSegments === "back" ? "white" : "#444542"}`,
            cursor: "pointer",
          }}
          onClick={() => {
            setToogleSegments("back");
          }}
        >
          {roundData.backdeparture} - {roundData.backarrival}
        </Typography>
      </Stack>
    </Box>
  );
};

export default RoundwayFlightDetails;
