import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Pagination,
  Tooltip,
  Typography,
} from "@mui/material";
import axios from "axios";
import commaNumber from "comma-number";
import Loader from "../../../images/loader/Render.gif";
import React, { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import secureLocalStorage from "react-secure-storage";
import { Stack } from "@mui/system";
import SearchIcon from "@mui/icons-material/Search";
import "./Queues.css";
import { format } from "date-fns";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UserHeader from "../../UserPage/UserHeader/UserHeader";
import { Puff } from "react-loader-spinner";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2564b8",
      darker: "#2564b8",
    },
  },
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

const Queues = () => {
  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;

  let staffId = users?.user?.staffId;
  // Modal
  const [open, setOpen] = useState(false);
  const [BookingId, setBookingId] = useState({});
  const [agentId, setAgentId] = useState({});
  const [staff, setStaff] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalDetails, setModalDetails] = useState([]);
  const handleClose = () => setOpen(false);
  const [userData, setUserData] = useState([]);
  const [bookingDetails, setBookingDetails] = useState([]);
  const [shortValues, setShortValue] = useState([]);
  const [allShortValues, setAllShortValues] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  // Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState("20");

  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  // data load from booking api
  useEffect(() => {
    setIsLoading(true);
    let url = `https://farhantt.api.flyfarint.com/v.1.0.0/Queues/BookingData.php?agentId=${agentID}&page=${page}`;
    console.log(url);

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (data?.data?.length > 0) {
          setPageCount(data?.number_of_page);
          setBookingDetails(data?.data);
          setShortValue(data?.data);
        }
      });
    //todo: end of fetch all data
  }, [agentID, page]);

  //  short functionality handle
  const handleChangeOption = (e) => {
    const optionValue = e.target.value;
    fetchData(optionValue);
  };

  async function fetchData(optionValue) {
    await fetch(
      `https://farhantt.api.flyfarint.com/v.1.0.0/Queues/BookingData.php?agentId=${agentID}&status=${optionValue}&pages=${page}`
    )
      .then((res) => res.json())
      .then((data) => {
        setShortValue(data.data);
        setAllShortValues(data.data);
      });
  }
  const handelSearchItems = (e) => {
    let searchInput = e.target.value;
    if (searchInput !== "") {
      const filterData = bookingDetails.filter((item) => {
        return Object.values(item)
          .join("")
          .toLowerCase()
          .includes(searchInput.toLowerCase());
      });

      setShortValue(filterData);
    } else if (searchInput === "") {
      setShortValue(bookingDetails);
    }
  };

  const navigate = useNavigate();

  const sendToQueuesDetails = (
    bookingId,
    pnr,
    gds,
    flightType,
    status,
    bookDate,
    deptFrom,
    arriveTo,
    airlines,
    netCost,
    staffId,
    issueTime,
    agentId,
    tripType,
    bookedby,
    bookingDetail,
    searchId,
    resultId
  ) => {
    navigate("/dashboard/queuesdetails", {
      state: {
        bookingId,
        pnr,
        gds,
        flightType,
        status,
        bookDate,
        deptFrom,
        arriveTo,
        airlines,
        netCost,
        staffId,
        issueTime,
        agentId,
        tripType,
        bookedby,
        bookingDetail,
        searchId,
        resultId,
        path: "queue",
      },
    });
  };

  return (
    <Box>
      <UserHeader />
      <Container sx={{ mt: "-7vh" }}>
        {isLoading ? (
          <Box className={"q-box"}>
            <Box
              sx={{
                background: "white",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
                py: "8px",
                px: "15px",
              }}
            >
              <Grid
                className="queues-search-box-flied"
                container
                alignItems={"center"}
                spacing={2}
              >
                <Grid item xs={6} sm={6} md={6}>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      color: "var(--black)",
                      fontFamily: "poppins",
                      fontSize: "18px",
                    }}
                  >
                    Queues Details{" "}
                    <span style={{ color: "var(--black)" }}>/Air Ticket</span>
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      justifyContent: "flex-end",
                      pr: "10px",
                    }}
                  >
                    <Box>
                      <Box class="search-box">
                        <input
                          class="search-text"
                          type="text"
                          placeholder="Search....."
                          onChange={(e) => handelSearchItems(e.target.value)}
                        />
                        <Button class="search-btn">
                          <SearchIcon />
                        </Button>
                      </Box>
                    </Box>
                    <Box>
                      <select
                        style={{
                          border: "1px solid #70a5d8",
                          borderRadius: " 2px",
                          height: " 34px",
                          padding: "0px 10px !important",
                          fontSize: " 14px",
                          color: " #2564b8 !important",
                          width: "100%",
                        }}
                        onChange={handleChangeOption}
                        className="queues-select-01"
                      >
                        <option value="all">&nbsp;Show All</option>
                        <option value="hold">&nbsp;Hold</option>
                        <option value="Issue In Processing">
                          &nbsp;Issue In Processing
                        </option>
                        <option value="Ticketed">&nbsp;Ticketed</option>

                        <option value="Void In Processing">
                          &nbsp;Void In Processing
                        </option>
                        <option value="Voided">&nbsp;Voided</option>
                        <option value="Void Rejected">
                          &nbsp;Void Rejected
                        </option>

                        <option value="Reissue In Processing">
                          &nbsp;Reissue In Processing
                        </option>
                        <option value="Reissued">&nbsp;Reissued</option>
                        <option value="Reissue Rejected">
                          &nbsp;Reissue Rejected
                        </option>

                        <option value="Refund In Processing">
                          &nbsp;Refund In Processing
                        </option>
                        <option value="Refunded">&nbsp;Refunded</option>
                        <option value="Refund Rejected">
                          &nbsp;Refund Rejected
                        </option>
                        <option value="Issue Rejected">
                          &nbsp;Issue Rejected
                        </option>
                        <option value="cancelled">&nbsp;Cancelled</option>
                      </select>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>

            <Box>
              <Box className="balance-transaction">
                <table>
                  <thead>
                    <tr>
                      <th>Reference&nbsp;No</th>
                      <th>Status</th>
                      <th>Airlines</th>

                      <th>Route</th>
                      <th>Flight&nbsp;Type</th>
                      <th colSpan={4}>Booking&nbsp;Information</th>
                      <th>Net&nbsp;Cost</th>
                      <th>Gross&nbsp;Cost</th>
                    </tr>
                    <tr>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th></th>

                      <th
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--white)",
                          fontWeight: "500",
                        }}
                      >
                        Total&nbsp;Pax
                      </th>
                      <th
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--white)",
                          fontWeight: "500",
                        }}
                      >
                        Booked&nbsp;By
                      </th>
                      <th
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--white)",
                          fontWeight: "500",
                        }}
                      >
                        Booked&nbsp;Date
                      </th>
                      <th
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--white)",
                          fontWeight: "500",
                        }}
                      >
                        Passenger&nbsp;Name
                      </th>
                      {/* <th
                        style={{
                          backgroundColor: "var(--primary-color)",
                          color: "var(--white)",
                          fontWeight: "500",
                        }}
                      >
                        Phone
                      </th> */}
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {shortValues?.slice(0, size)?.map((bookingDetail) => (
                      <tr>
                        <td data-column="Reference&nbsp;No">
                          <button
                            style={{
                              background: "transparent",
                              border: "none",
                              cursor: "pointer",
                              backgroundColor: "#d1e9ff",
                              padding: "5px 15px",
                              color: "#003566",
                              textDecoration: "underline",
                            }}
                            onClick={() =>
                              sendToQueuesDetails(
                                bookingDetail?.bookingId,
                                bookingDetail?.pnr,
                                bookingDetail?.gds,
                                bookingDetail?.tripType,
                                bookingDetail?.status,
                                bookingDetail?.dateTime,
                                bookingDetail?.deptFrom,
                                bookingDetail?.arriveTo,
                                bookingDetail?.airlines,
                                bookingDetail?.netCost,
                                bookingDetail?.staffId,
                                bookingDetail?.issueTime,
                                bookingDetail?.agentId,
                                bookingDetail?.tripType,
                                bookingDetail?.bookedby,
                                bookingDetail?.searchId,
                                bookingDetail?.resultId
                              )
                            }
                          >
                            {bookingDetail.bookingId ?? "Reference No"}
                          </button>
                        </td>
                        <td data-column="Status">
                          {bookingDetail.status ? (
                            <button
                              style={{
                                border: "none",
                                borderRadius: "5px",
                                width: "100%",
                              }}
                              className={`${bookingDetail.status
                                ?.toLowerCase()
                                ?.split(" ")
                                ?.join("-")}-btn`}
                            >
                              {bookingDetail.status}
                            </button>
                          ) : (
                            "Status"
                          )}
                        </td>
                        <td data-column="Airlines">
                          {bookingDetail.airlines ?? "Airlines"}
                        </td>

                        <td data-column="Route">
                          {bookingDetail.deptFrom ?? "From"} -{" "}
                          {bookingDetail.arriveTo ?? "To"}
                        </td>
                        <td data-column="Flight&nbsp;Type">
                          {bookingDetail.tripType ?? "Flight Type"}
                        </td>
                        <td data-column="Total&nbsp;Pax">
                          {bookingDetail.pax ?? "Total Pax"}
                        </td>
                        <td data-column=" Booked&nbsp;By">
                          {bookingDetail?.bookedby ?? "Booked By"}{" "}
                        </td>
                        <td data-column=" Booked&nbsp;Date">
                          {bookingDetail?.bookedAt !== "" || "undefined"
                            ? format(
                                new Date(bookingDetail?.bookedAt),
                                "dd MMM yy hh:mm a"
                              )
                            : "Booked Date"}
                        </td>

                        {/* <td>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              color: "var(--dark-text-color)",
                              fontWeight: "500",
                            }}
                          >
                            {bookingDetail?.flightdate === "undefined" ||
                            bookingDetail?.flightdate === "" ||
                            bookingDetail?.flightdate === "Data Missing"
                              ? "Travel Date"
                              : format(
                                  new Date(bookingDetail?.flightdate),
                                  "dd MMM yy hh:mm a"
                                )}
                          </Typography>
                        </td> */}

                        <td data-column="Passenger&nbsp;Name">
                          {bookingDetail?.name?.toUpperCase() ||
                            "Passenger Name"}
                        </td>

                        <td data-column="Net&nbsp;Cost">
                          {commaNumber(bookingDetail.netCost) ?? "Net Cost"}
                          &#2547;
                        </td>
                        <td data-column="Gross&nbsp;Cost">
                          {commaNumber(bookingDetail.grossCost) ?? "Gross Cost"}
                          &#2547;
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Box>

              <Grid container>
                <Grid item lg={12} md={12} sm={12} xs={12}>
                  <Box
                    sx={{
                      width: "100%",
                      my: 3,
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      borderTop: "1px solid var(--primary-color)",
                      marginTop: "30px",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        my: 3,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",

                        marginTop: "8px",
                      }}
                    >
                      <Typography
                        style={{ fontSize: "15px", color: "#222222" }}
                      >
                        Showing Results 1 - 10 of 20
                      </Typography>
                      <ThemeProvider theme={theme}>
                        <Stack spacing={2}>
                          <Pagination
                            size="small"
                            count={pageCount}
                            onChange={handlePageChange}
                            color="primary"
                          />
                        </Stack>
                      </ThemeProvider>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        ) : (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "70vh",
              width: "70vw",
              marginInline: "auto",
            }}
          >
            <Box
              style={{
                width: "50%",
                height: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Puff
                height="150"
                width="120"
                radius={1}
                color="var(--secondary-color)"
                ariaLabel="puff-loading"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
              />
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default Queues;
