import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useState } from "react";
const isMobile = window.innerWidth <= 768;

const BackSearchCard = ({
  roundFlightData,
  quotetionArr,
  setQuotetionArr,
  handleBox,
  checked,
}) => {
  const {
    backarrival,
    backdeparture,
    backdepartureDate,
    backdepartureTime,
    backflightduration,
    bags,
    career,
    careerName,
    backarrivalDate,
    godepartureTime,
    backarrivalTime,
    refundable,
    segments,
    segment,
    seat,
    system,
  } = roundFlightData;

  return (
    <>
      {isMobile ? (
        <>
          <Grid order={{ xs: 1, lg: 1 }} xs={12} lg={2.5}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${career}.png`}
                width="50px"
                height="50px"
                alt=""
              />

              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)", fontWeight: "500" }}
                >
                  {careerName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#222", fontWeight: "500" }}
                >
                  {segments?.back?.map(
                    (s, i) =>
                      `${
                        i === 0
                          ? `${s.marketingcareer} ${s.marketingflight}`
                          : ` & ${s.marketingcareer} ${s.marketingflight}`
                      }`
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid order={{ xs: 3, lg: 2 }} xs={4} lg={2.6}>
            <Box sx={{ mt: "10px" }}>
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Departure
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "19px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {backdeparture}{" "}
                <span
                  style={{
                    color: "#222",
                    fontSize: "10px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {moment(backdepartureTime, "HH:mm").format("hh:mm A")}
                </span>
              </Typography>

              <Typography sx={{ fontSize: "10px", color: "#8C8080" }}>
                {backdepartureDate}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 4, lg: 3 }} xs={4} lg={1.75}>
            <Box
              sx={{
                mt: "50px",
              }}
            >
              <Typography
                sx={{
                  color: "#444542",
                  textAlign: "center",
                  mr: "10px",
                  fontSize: "11px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {segments?.back?.length >= 2
                  ? `${segments?.back.length - 1} stop${
                      segments?.back.length - 1 > 1 ? "s" : ""
                    }`
                  : "No stop"}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 5, lg: 4 }} xs={4} lg={2.6}>
            <Box sx={{ mt: "10px",textAlign:"right" }}>
              <Typography
                variant="body2"
                sx={{ color: "#8C8080", textAlign: "right" }}
              >
                Arrival
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "19px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {backarrival}{" "}
                <span
                  style={{
                    color: "#222",
                    fontSize: "10px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {moment(backarrivalTime, "HH:mm").format("hh:mm A")}
                </span>
              </Typography>

              <Typography sx={{ fontSize: "10px", color: "#8C8080" }}>
                {backarrivalDate}
              </Typography>
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item order={{ xs: 9, lg: 6 }} xs={6} lg={2.5}>
            <Stack
              direction="column"
              justifyContent="space-between"
              spacing={2}
            >
              <Box sx={{ bgcolor: "" }}>
                <img
                  src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${career}.png`}
                  width="60px"
                  height="60px"
                  alt=""
                />

                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)" }}
                >
                  {careerName}
                </Typography>
                <Typography variant="body2" sx={{ color: "#222" }}>
                  {segments.back.map(
                    (s, i) =>
                      `${
                        i === 0
                          ? `${s.marketingcareer} ${s.marketingflight}`
                          : ` & ${s.marketingcareer} ${s.marketingflight}`
                      }`
                  )}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        p: 0,

                        ml: 1,
                        color: "var(--secondary-color)",
                      }}
                      checked={checked}
                      onChange={() => handleBox(roundFlightData)}
                    />
                  }
                />
                <Typography sx={{ fontSize: "12px", color: "#222" }}>
                  {roundFlightData?.class}
                </Typography>
              </Box>
            </Stack>
          </Grid>

          <Grid item order={{ xs: 6, lg: 7 }} xs={3} lg={2.6}>
            <Stack
              direction="column"
              justifyContent="space-between"
              // alignItems="center"
              spacing={2}
              // bgcolor={"red"}
            >
              <Box>
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Depart
                </Typography>
                <Typography variant="title-lg">{backdeparture}</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)" }}
                >
                  {segments.back[0].departureAirport}
                </Typography>
                <Typography variant="body2" sx={{ color: "#222" }}>
                  {moment(backdepartureTime, "HH:mm:ss").format("hh:mm A")}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                  {backdepartureDate}
                </Typography>
                <Typography
                  sx={{
                    fontSize: "12px",
                    color:
                      refundable.trim() === "Refundable" ? "#0B7102" : "red",
                    mt: "10px",
                  }}
                >
                  {refundable}
                </Typography>
              </Box>
              {/* <Typography sx={{ fontSize: "12px", color: "#0B7102" }}>
          {refundable}
        </Typography> */}
            </Stack>
          </Grid>

          <Grid item order={{ xs: 7, lg: 8 }} xs={3} lg={2}>
            <Stack
              direction="column"
              justifyContent="space-between"
              // alignItems="center"
              spacing={2}
              height={"100%"}
            >
              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: "#ACA6A6", textAlign: "center", mr: "10px" }}
                >
                  {(system === "Sabre" || system === "Galileo") &&
                  (backflightduration.includes("H") ||
                    backflightduration.includes("M"))
                    ? backflightduration
                    : `${parseInt(backflightduration / 60)}H ${
                        backflightduration % 60
                      }M`}
                </Typography>
                <svg
                  width="122"
                  height="12"
                  viewBox="0 0 689 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                    stroke="#ED1A5C"
                    stroke-width="9"
                  />
                  <path
                    d="M689 46.6569L541.002 0V46.6569H689Z"
                    fill="#ED1A5C"
                  />
                </svg>
                <Typography
                  variant="body2"
                  sx={{ color: "#444542", textAlign: "center", mr: "10px" }}
                >
                  {segments.back.length >= 2
                    ? `${segments?.back.length - 1} stop${
                        segments?.back.length - 1 > 1 ? "s" : ""
                      }`
                    : "No stop"}
                </Typography>
              </Box>
              <Typography
                sx={{ color: "#2156A6", textAlign: "center", fontSize: "12px" }}
              >
                Baggage{" "}
                {/* {roundFlightData?.system === "Sabre"
                  ? bags > 3 && bags !== undefined
                    ? `${bags} KG`
                    : `${bags} piece - ${bags * 23} KG`
                  : roundFlightData?.system === "Galileo" && bags !== undefined
                  ? bags === 1 || bags === 2
                    ? `${bags} piece - ${bags * 23} KG`
                    : `${bags} piece - ${bags * 23} KG`
                  : roundFlightData?.segments?.go[0]["bags"]} */}
                {roundFlightData?.system === "Sabre"
                  ? bags >= 3
                    ? `${bags} KG`
                    : `${bags} piece - ${bags * 23} KG`
                  : bags !== undefined
                  ? parseFloat(bags) >= 3
                    ? bags
                    : bags + " pieces - " + bags * 23 + " KG"
                  : parseFloat(segments.go[0].bags) + " KG"}
              </Typography>
            </Stack>
          </Grid>

          <Grid item order={{ xs: 8, lg: 9 }} xs={3} lg={2.6}>
            <Stack
              direction="column"
              justifyContent="space-between"
              // alignItems="center"
              spacing={2}
            >
              <Box>
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Arrival
                </Typography>
                <Typography variant="title-lg">{backarrival}</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)",  }}
                >
                  {segments.back[segments.back.length - 1].arrivalAirport}
                </Typography>
                <Typography variant="body2" sx={{ color: "#222" }}>
                  {moment(backarrivalTime, "HH:mm:ss").format("hh:mm A")}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                  {backarrivalDate}
                </Typography>
                <Typography
                  sx={{ fontSize: "12px", color: "#8C8080", mt: "10px" }}
                >
                  {seat || segments.go[0].seat} Seats
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </>
      )}
    </>
  );
};

export default BackSearchCard;
