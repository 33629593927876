import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ClickAwayListener,
  Container,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { Calendar } from "react-date-range";
import CancelIcon from "@mui/icons-material/Cancel";
import Loader from "../../images/loader/Render.gif";
import AddTraveler from "../../images/undraw/undraw_airport_re_oqk1.svg";
import ServerError from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import PhoneInput from "react-phone-input-2";
import CountryList from "../CountryList";
import Swal from "sweetalert2";
import "./AddTraveller.css";
import Header from "../Header/Header";
import { format } from "date-fns";
import UserHeader from "../UserPage/UserHeader/UserHeader";

const AddTraveller = () => {
  const navigate = useNavigate();
  const users = secureLocalStorage.getItem("user-info");
  const agentId = users?.user?.agentId;
  const [travelerData, setTravelerData] = useState({});
  const [userPhoneNumber, setUserPhoneNumber] = useState("880");
  const [openDob, setOpenDob] = useState(false);
  const [openPassEx, setOpenPassEx] = useState(false);
  const [loading, setLoading] = useState(false);

  // todo: date validation
  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }
  let dateAfterSixMonths = addMonths(new Date(), 6);
  let dateBeforeTwelveYears = addMonths(new Date(), -144);
  let dateBeforeTwoYears = addMonths(new Date(), -24);
  // todo:end

  //  form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(false);

    let body = JSON.stringify({
      agentId: agentId,
      fname: travelerData.fname,
      lname: travelerData.lname,
      dob: travelerData.dob,
      type: travelerData.type,
      nationality: travelerData.nationality,
      passportno: travelerData.passportno,
      passexpireDate: travelerData.passexpireDate,
      phone: travelerData.phone,
      email: travelerData.email,
      gender: travelerData.gender,
    });

    let url = `https://farhantt.api.flyfarint.com/v.1.0.0/AirMaterials/addTraveler.php`;
    await fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },

      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setLoading(false);
        if (data.status === "success") {
          Swal.fire({
            title: "Success",
            html: "<strong>A New Travelers Added</strong>",
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate("/dashboard/traveller");
            setLoading(false);
          });
        } else {
          throw new Error("error adding travelers");
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Server Error",
          html: "<strong>Failed to Add a New Traveler</strong>",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(function () {
          setLoading(false);
          navigate("/dashboard/addtraveller");
        });
      });
    e.target.reset();
  };

  const handleChange = (e) => {
    const field = e.target.name;
    console.log(field);
    let value;
    if (field === "passportCopy" || field === "visaCopy") {
      value = URL.createObjectURL(e.target.files[0]);
    } else if (
      field === "gender" ||
      field === "nationality" ||
      field === "type" ||
      field === "email"
    ) {
      value = e.target.value;
    } else {
      value = e.target.value.toUpperCase();
    }
    const newTravelerData = { ...travelerData };
    newTravelerData[field] = value;
    setTravelerData(newTravelerData);
  };

  const deleteImage = (field) => {
    setTravelerData({ ...travelerData, [field]: "" });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          sx={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={Loader}
            alt="loader"
            style={{
              width: "40%",
              objectFit: "center",
            }}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "90%", margin: "0 auto", mt: "-8vh" }}>
        <Box
          sx={{
            px: "15px",
            py: "12px",
            background: "var(--white)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              fontWeight: "600px",
              fontSize: "18px",
              color: "var(--black)",
              mb: "3vh",
              mt: "1vh",
            }}
          >
            Add Travelers
          </Typography>
          <form onSubmit={handleSubmit}>
            <Box className="passengerInput1">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Typography>First/Given Name</Typography>
                  <Box>
                    <input
                      required
                      type="text"
                      name="fname"
                      value={travelerData.fname}
                      placeholder="Given Name"
                      onFocus={() => {
                        setOpenDob(false);
                        setOpenPassEx(false);
                      }}
                      onChange={(e) => handleChange(e)}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Typography>Last/Surname</Typography>
                  <Box>
                    <input
                      required
                      type="text"
                      name="lname"
                      value={travelerData.lname}
                      placeholder="Surname"
                      onChange={(e) => handleChange(e)}
                      onFocus={() => {
                        setOpenDob(false);
                        setOpenPassEx(false);
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Typography>Gender</Typography>
                  <Box>
                    <select
                      required
                      name="gender"
                      value={travelerData.gender}
                      onChange={(e) => handleChange(e)}
                      onFocus={() => {
                        setOpenDob(false);
                        setOpenPassEx(false);
                      }}
                    >
                      <option value="">Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Typography>Nationality</Typography>
                  <Box>
                    <select
                      required
                      type="text"
                      name="nationality"
                      value={travelerData.nationality}
                      onChange={(e) => handleChange(e)}
                      onFocus={() => {
                        setOpenDob(false);
                        setOpenPassEx(false);
                      }}
                    >
                      <option value="">Select Nationality</option>
                      {CountryList.map((country) => {
                        return (
                          <option value={country.code}>{country.name}</option>
                        );
                      })}
                    </select>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Typography>Passenger Type</Typography>
                  <Box>
                    <select
                      required
                      name="type"
                      value={travelerData.type}
                      onChange={(e) => handleChange(e)}
                      onFocus={() => {
                        setOpenDob(false);
                        setOpenPassEx(false);
                      }}
                    >
                      <option value="">Passenger Type</option>
                      <option value="ADT">Adult</option>
                      <option value="CNN">Child</option>
                      <option value="INF">Infant</option>
                    </select>
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ position: "relative" }}
                >
                  <Typography>Date of birth</Typography>
                  <Box>
                    <Tooltip
                      title={
                        travelerData.type ? "" : "Please Select Passenger first"
                      }
                    >
                      <input
                        disabled={travelerData.type ? false : true}
                        required
                        readOnly
                        type="text"
                        name="dob"
                        value={
                          travelerData.dob
                            ? format(new Date(travelerData.dob), "dd MMM yyyy")
                            : ""
                        }
                        placeholder="Date Of Birth"
                        onClick={() => {
                          setOpenDob((prev) => !prev);
                          setOpenPassEx(false);
                        }}
                      />
                    </Tooltip>
                  </Box>
                  {openDob ? (
                    <Calendar
                      color="#003566"
                      months={1}
                      className="new-dashboard-calendar"
                      onChange={(data) => {
                        setTravelerData({
                          ...travelerData,
                          dob: new Date(data).toLocaleDateString("sv"),
                        });
                        setOpenPassEx(false);
                        setOpenDob(false);
                      }}
                      minDate={
                        travelerData.type === "ADT"
                          ? new Date("1800-01-01")
                          : travelerData.type === "CNN"
                          ? new Date(dateBeforeTwelveYears)
                          : new Date(dateBeforeTwoYears)
                      }
                      maxDate={
                        travelerData.type === "ADT"
                          ? new Date(dateBeforeTwelveYears)
                          : travelerData.type === "CNN"
                          ? new Date(dateBeforeTwoYears)
                          : new Date()
                      }
                    />
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Typography>Passport Number</Typography>
                  <Box>
                    <input
                      required
                      type="text"
                      name="passportno"
                      value={travelerData.passportno}
                      placeholder="Passport Number"
                      onChange={(e) => handleChange(e)}
                      onFocus={() => {
                        setOpenDob(false);
                        setOpenPassEx(false);
                      }}
                    />
                  </Box>
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={4}
                  style={{ position: "relative" }}
                >
                  <Typography>Passport Expire Date</Typography>
                  <Box>
                    <input
                      required
                      type="text"
                      name="passexpireDate"
                      value={
                        travelerData.passexpireDate
                          ? format(
                              new Date(travelerData.passexpireDate),
                              "dd MMM yyy"
                            )
                          : ""
                      }
                      placeholder="Passport Expire Date"
                      onClick={() => {
                        setOpenPassEx((prev) => !prev);
                        setOpenDob(false);
                      }}
                    />
                  </Box>
                  {openPassEx ? (
                    <Calendar
                      color="#003566"
                      months={1}
                      className="new-dashboard-calendar"
                      onChange={(data) => {
                        setTravelerData({
                          ...travelerData,
                          passexpireDate: new Date(data).toLocaleDateString(
                            "sv"
                          ),
                        });
                        setOpenPassEx(false);
                        setOpenDob(false);
                      }}
                      minDate={new Date()}
                    />
                  ) : null}
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={4}>
                  <Typography>Email</Typography>
                  <input
                    required
                    type="email"
                    name="email"
                    value={travelerData.email}
                    placeholder="Enter Email"
                    onChange={(e) => handleChange(e)}
                    onFocus={() => {
                      setOpenDob(false);
                      setOpenPassEx(false);
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={6}
                  lg={12}
                  style={{ marginBottom: "20px" }}
                >
                  <Box style={{ width: "32%" }}>
                    <Typography>Contact Number</Typography>
                    <Box className="passengerInput1">
                      <PhoneInput
                        style={{
                          borderBottom: "1px solid var(--primary-color)",
                        }}
                        className="phoneIn"
                        sx={{
                          width: "100%",
                        }}
                        required
                        country={"bd"}
                        name="phone"
                        value={userPhoneNumber}
                        onFocus={() => {
                          setOpenDob(false);
                          setOpenPassEx(false);
                        }}
                        onChange={(phone) => {
                          setTravelerData({
                            ...travelerData,
                            phone: phone,
                          });
                          setUserPhoneNumber(phone);
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={6} lg={12}>
                  <Box>
                    <Button
                      type="submit"
                      sx={{
                        fontFamily: "poppins",
                        fontWeight: "400",
                        fontSize: "14px",
                        textTransform: "capitalize",
                        borderRadius: "2px",
                        background: "#3C4258",
                        color: "#FFFFFF",
                        width: "fit-content",
                        "&:hover": {
                          backgroundColor: "#3C4258",
                        },
                      }}
                    >
                      Add This Traveler
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddTraveller;
