/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import "./SingleFlight.css";
import moment from "moment/moment";
import FlightDetails from "./FlightDetails";
const isMobile = window.innerWidth <= 768;

const SingleFlight = ({
  flightData,
  adultCount,
  childCount,
  infant,
  sortedBy,
  index,
  quotetionArr,
  setQuotetionArr,
}) => {
  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);

  const navigate = useNavigate();

  const data = {
    flightData,
    adultCount,
    childCount,
    infant,
    to: flightData?.arrival,
    from: flightData?.departure,
    tripType: "oneway",
    fromAddress: flightData.segments[0]?.departureLocation,
    toAddress:
      flightData.segments[flightData.segments.length - 1]?.arrivalLocation,
    dDate: moment(flightData.departureDate, "ddd DD MMM YYYY").format(
      "YYYY-MM-DD"
    ),
    clientFare: flightData.customerPrice,
    agentFare: flightData.agentprice,
    commission:
      parseFloat(flightData.customerPrice) - parseFloat(flightData.agentFare),
  };

  // Unique Flight Code filter
  const marcketingCareerCode = flightData.segments
    .map((data) => data?.marketingcareer)
    .filter((value, index, arr) => arr.indexOf(value) === index);

  const {
    careerName,
    segments,

    //localhost:3050/dashboard
 departureTime,
    class: className,
    departure,
    departureDate,
    refundable,
    seat,
    flightduration,
    segment,
    bags,
    arrivalTime,
    arrivalDate,
    arrival,
    price,
    clientPrice: customerPrice,
    pricebreakdown,
  } = flightData;

  const handleOneWayBook = () => {
    navigate("/dashboard/oneway_flight_information", {
      state: { data },
    });
  };
  const handleBox = (data) => {
    const event = window.event;
    setChecked(event.target.checked);
    if (event.target.checked) {
      setQuotetionArr([...quotetionArr, flightData]);
    } else {
      const filter = quotetionArr?.filter((item) => item?.uId !== data.uId);
      setQuotetionArr(filter);
    }
  };

  console.log(flightData);
  return (
    <Box
      sx={{
        mb: "10px",
        bgcolor: "white",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        transition: "all .5s ease-in-out",
        borderRadius: "5px",

        // bgcolor:"#F3ECEB"
      }}
    >
      {isMobile ? (
        <>
          <Grid
            onClick={handleOneWayBook}
            justifyContent={"space-between"}
            container
            sx={{
              p: "20px 20px",
              borderRadius: "4px",
              cursor: "pointer",
              transition: "all .5s ease-in-out",
              background: "white",
            }}
          >
            <Grid order={{ xs: 1, lg: 1 }} xs={12} lg={2.5}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                {marcketingCareerCode.map((data) => (
                  <img
                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data}.png`}
                    width="50px"
                    height="50px"
                    alt={`${flightData.segments[0]?.marketingcareer}`}
                  />
                ))}

                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: "var(--secondary-color)", fontWeight: "500" }}
                  >
                    {careerName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#222", fontWeight: "500" }}
                  >
                    {segments.map(
                      (s, i) =>
                        `${
                          i === 0
                            ? `${s.marketingcareer} ${s.marketingflight}`
                            : ` & ${s.marketingcareer} ${s.marketingflight}`
                        }`
                    )}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid order={{ xs: 3, lg: 2 }} xs={4} lg={2.6}>
              <Box sx={{mt:"5px"}}>
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Departure
                </Typography>
                <Typography
                  sx={{
                    color: "#222",
                    fontSize: "19px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {departure}{" "}
                  <span
                    style={{
                      color: "#222",
                      fontSize: "10px",
                      py: "4px",
                      fontWeight: "500",
                    }}
                  >
                    {moment(departureTime, "HH:mm").format("hh:mm A")}
                  </span>
                </Typography>

                <Typography sx={{ fontSize: "10px", color: "#8C8080" }}>
                  {departureDate}
                </Typography>
              </Box>
            </Grid>

            <Grid order={{ xs: 4, lg: 3 }} xs={4} lg={1.75}>
              <Box sx={{ mt: "30px" }}>
                <Typography
                  sx={{
                    color: "#444542",
                    textAlign: "center",
                    mr: "10px",
                    fontSize: "11px",
                  }}
                >
                  {segment >= 2
                    ? `${segment - 1} stop${segment - 1 > 1 ? "s" : ""}`
                    : "No stop"}
                </Typography>
              </Box>
            </Grid>

            <Grid order={{ xs: 5, lg: 4 }} xs={4} lg={2.6}>
              <Box sx={{mt:"5px",textAlign:"right"}}>
                <Typography
                  variant="body2"
                  sx={{ color: "#8C8080", textAlign: "right" }}
                >
                  Arrival
                </Typography>
                <Typography
                  sx={{
                    color: "#222",
                    fontSize: "19px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {arrival}{" "}
                  <span
                    style={{
                      color: "#222",
                      fontSize: "10px",
                      py: "4px",
                      fontWeight: "500",
                    }}
                  >
                    {moment(arrivalTime, "HH:mm").format("hh:mm A")}
                  </span>
                </Typography>

                <Typography sx={{ fontSize: "10px", color: "#8C8080" }}>
                  {arrivalDate}
                </Typography>
              </Box>
            </Grid>
            <Grid order={{ xs: 5, lg: 4 }} mt={3} xs={12}>
              <Box sx={{ borderBottom: "1px solid grey" }}></Box>
            </Grid>
            <Grid order={{ xs: 5, lg: 4 }} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mt: "5px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: refundable === "Refundable" ? "#0B7102" : "red",
                  }}
                >
                  {refundable}
                </Typography>

                <Stack
                  direction="column"
                  justifyContent="space-between"
                  alignItems="end"
                  height="100%"
                  width="100%"
                  sx={{
                   
                    textAlign: "right",
                  }}
                >
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "var(--secondary-color)",

                        textDecoration: "line-through",
                      }}
                    >
                      {Number(customerPrice).toLocaleString("en-IN")}৳
                    </Typography>
                    <Typography sx={{ fontSize: "16px", color: "#444542" }}>
                      {Number(price).toLocaleString("en-IN")}৳
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>{" "}
        </>
      ) : (
        <>
          <Grid
            justifyContent={"space-between"}
            container
            sx={{
              p: "20px 20px",
              borderRadius: "4px",
              transition: "all .5s ease-in-out",
              bgcolor: "white",
            }}
          >
            <Grid order={{ xs: 1, lg: 1 }} xs={6} lg={2.5}>
              <Box sx={{ bgcolor: "" }}>
                {marcketingCareerCode.map((data) => (
                  <img
                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data}.png`}
                    width="50px"
                    height="50px"
                    alt={`${flightData.segments[0]?.marketingcareer}`}
                  />
                ))}

                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)", fontWeight: "500" }}
                >
                  {careerName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#222", fontWeight: "500" }}
                >
                  {segments.map(
                    (s, i) =>
                      `${
                        i === 0
                          ? `${s.marketingcareer} ${s.marketingflight}`
                          : ` & ${s.marketingcareer} ${s.marketingflight}`
                      }`
                  )}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "flex-start",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        sx={{
                          p: 0,
                          m: 0,
                          ml: 1,
                          color: "var(--secondary-color)",
                        }}
                        checked={checked}
                        onChange={() => handleBox(flightData)}
                      />
                    }
                  />
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#222",
                      pt: "50px",
                    }}
                  >
                    {className}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid order={{ xs: 3, lg: 2 }} xs={3} lg={2.6}>
              <Box>
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Departure
                </Typography>
                <Typography variant="title-lg">{departure}</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)" }}
                >
                  {segments[0].departureAirport}
                </Typography>
                <Typography
                  sx={{
                    color: "#222",
                    fontSize: "11px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {moment(flightData?.departureTime, "HH:mm").format("hh:mm A")}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                  {departureDate}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mt: "28px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: refundable === "Refundable" ? "#0B7102" : "red",
                    }}
                  >
                    {refundable}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#222", mr: 1 }}>
                    {seat} seat{seat > 1 ? "s" : ""}
                  </Typography>
                </Box>
              </Box>
            </Grid>

            <Grid order={{ xs: 4, lg: 3 }} xs={3} lg={1.75}>
              <Box sx={{ mt: "30px" }}>
                <Typography
                  sx={{
                    color: "#ACA6A6",
                    textAlign: "center",
                    mr: "10px",
                    fontSize: "10px",
                  }}
                >
                  {flightduration}
                </Typography>
                <svg
                  width="122"
                  height="12"
                  viewBox="0 0 689 56"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                    stroke="#ED1A5C"
                    stroke-width="9"
                  />
                  <path
                    d="M689 46.6569L541.002 0V46.6569H689Z"
                    fill="#ED1A5C"
                  />
                </svg>
                <Typography
                  sx={{
                    color: "#444542",
                    textAlign: "center",
                    mr: "10px",
                    fontSize: "11px",
                  }}
                >
                  {segment >= 2
                    ? `${segment - 1} stop${segment - 1 > 1 ? "s" : ""}`
                    : "No stop"}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                sx={{ color: "#2156A6", textAlign: "center", mt: 7.1 }}
              >
                Baggage{" "}
                {bags > 3 ? `${bags} KG` : `${bags} piece - ${bags * 23} KG`}
              </Typography>
            </Grid>

            <Grid order={{ xs: 5, lg: 4 }} xs={3} lg={2.6}>
              <Box
                sx={{
                  bgcolor: "blu",
                }}
              >
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Arrival
                </Typography>
                <Typography variant="title-lg">{arrival}</Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)" }}
                >
                  {segments[segments.length - 1].arrivalAirport}
                </Typography>
                <Typography
                  sx={{
                    color: "#222",
                    fontSize: "11px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {moment(arrivalTime, "HH:mm").format("hh:mm A")}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                  {arrivalDate}
                </Typography>
              </Box>
            </Grid>

            <Grid order={{ xs: 2, lg: 5 }} xs={6} lg={1.75}>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="end"
                height="100%"
                width="100%"
                sx={{
                  borderLeft: "5px solid #F0F6FF",
                  textAlign: "right",
                }}
              >
                <Box>
                  <Typography variant="title-lg">
                    {Number(price).toLocaleString("en-IN")}৳
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#2156A6",
                      pb: "10px",
                      textDecoration: "line-through",
                    }}
                  >
                    {Number(customerPrice).toLocaleString("en-IN")}৳
                  </Typography>
                </Box>
                <Box>
                  <Button
                    onClick={handleOneWayBook}
                    sx={{
                      bgcolor: "var(--primary-color)",
                      color: "var(--white)",
                      mb: "10px",
                      fontSize: "12px",
                      px: "15px",
                      fontWeight: "500",
                      ":hover": { bgcolor: "var(--primary-color)" },
                    }}
                  >
                    BOOK NOW
                  </Button>
                  <Typography
                    onClick={() => setExpanded(!expanded)}
                    sx={{
                      fontSize: "12px",
                      color: "#222",
                      cursor: "pointer",
                    }}
                  >
                    {expanded ? "Hide" : "View"} details
                  </Typography>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <FlightDetails
            pricebreakdown={pricebreakdown}
            segments={segments}
            expanded={expanded}
            flightData={flightData}
          />
        </>
      )}

      {/* <Modal open={openModal} onClose={handleClickAway}>
        <UserSigningPopUp setOpenModal={setOpenModal} />
      </Modal> */}
    </Box>
  );
};

export default SingleFlight;
