import React, { Fragment } from "react";
import { GoogleMap, useJsApiLoader } from "@react-google-maps/api";
import { Box } from "@mui/material";
const containerStyle = {
  width: "1440px",
  height: "720px",
};

const center = {
  lat: 25.27043415885282,
  lng: 51.539763246028805,
};

// 25.27043415885282, 51.539763246028805

const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "Your Google API Key", //need to define your google api key
  });

  const [map, setMap] = React.useState(null);

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return (
    <Box mt={10}>
      <div style={{ position: "relative" }}>
        <iframe
          width="100%"
          height="400"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          style={{ pointerEvents: "none" }}
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14432.103357768607!2d51.5396345!3d25.2697162!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45c5a4d6306899%3A0x51b4e281eb8152a!2sWorldwide%20Tours%20%26%20Travels!5e0!3m2!1sen!2sbd!4v1720353645129!5m2!1sen!2sbd"
        ></iframe>
      </div>
    </Box>
  );
};

export default Map;
