import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import commaNumber from "comma-number";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useQuery } from "react-query";
// import logo from "../../images/logo/rtclogo.png";
import moment from "moment";
const companyInfo = {
    name: "Fly Far International",
    email: "support@flyfarint.com",
    phone: "09606-912912",
    address:
      "1229, Hazi Abdul Latif Mansion, 2nd Floor, Ka-9, Bashundhara Rd, Dhaka",
  };

var converter = require("number-to-words");

const HotelPdfDesign = ({
    userData,
    allData,
    adultTotalPrice,
    childTotalPrice,
    infantTotalPrice,
    totalPrice,
    prices,
    copy,
    check,
    companyImage,
    margin,
    setMultiCityAllData,
    // discount,
    discountPrice,
    multiCityAllData,
    markupPrice,
    bookingInfo,
    retriveInfo
}) => {

    const user = secureLocalStorage.getItem('user-info');
    console.log(user);

    const companyName = user?.agent_comp_name;
    const companyAddress = user?.agent_comp_address || '';
    const country = user?.agent_country || '';
    const phoneNo = user?.agent_phone_number || user?.agent_phone_number;

    const table = {
        display: "table",
        width: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderRightWidth: 0,
        borderBottomWidth: 0,
    };
    const tableRow = {
        margin: "auto",
        flexDirection: "row",
    };

    const tableCol = {
        width: "25%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };
    const tableHalfCol = {
        width: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };
    //Passenger Details
    const tableColName = {
        width: "35%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };
    const tableColGender = {
        width: "20%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };
    const tableColType = {
        width: "20%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };
    const tableColNumber = {
        width: "25%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };

    const tableColfromto = {
        width: "22%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };
    const tableColtime = {
        width: "11%",
        borderStyle: "solid",
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };

    const tableColflight = {
        width: "17%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        padding: "2px",
    };
    const tableCol2 = {
        width: "100%",
        borderStyle: "solid",
        borderWidth: "1px",
        borderLeftWidth: 0,
        borderTopWidth: 0,
        fontWeight: "bold",
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    };

    const tableCell = {
        // margin: "auto",
        margin: "3px",
        fontSize: "8px",
    };
    const tableCellInfo = {
        margin: "1px 3px",
        fontSize: "8px",
    };

    const adress = {
        fontSize: "10px",
        color: "#8b8b8b",
    };

    // const users = secureLocalStorage.getItem("user-info");
    // let agentID = users?.agentId;
    const [siteConfig, setSiteConfig] = useState({});
    const gCost = parseInt(allData?.booking.gross_cost);
    const tCost = parseInt(allData?.booking.tax);
    const bCost = parseInt(allData?.booking.base_fare);
    const otherPrice = gCost - (tCost + bCost);
    const baseFareTotal = parseInt(otherPrice) + parseInt(allData?.booking.base_fare);
    const gross = parseInt(allData?.booking.gross_cost);
    const mark = parseInt(markupPrice);
    let word, capitalize;
    //console.log(multiCityAllData)

    const total = bookingInfo?.netCost;

    if (isFinite(total)) {
        word = converter.toWords(total);
        const arr = word.split(" ");
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
        }
        capitalize = arr.join(" ");
    } else {
        console.error("Total is not a finite number.");
    }

    return (
        <Document>
            <Page size="A4" style={{ padding: "27px 20px" }}>
                <View style={{ padding: "15px" }}>
                    
                    <View
                        style={{
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        
                        <View>
                            <Text
                                style={{
                                    fontSize: "13px",
                                    fontWeight: 600,
                                    color: "#2156a6",
                                    margin: "0px 0px 6px 0px",
                                }}
                            >
                                {companyName}
                            </Text>
                            <Text
                                style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    color: "#7d7b7b",
                                }}
                            >
                                {companyAddress},
                            </Text>
                            <Text
                                style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    color: "#7d7b7b",
                                    marginTop: "3px",
                                }}
                            >
                                {country}
                            </Text>
                            <Text
                                style={{
                                    fontSize: "10px",
                                    fontWeight: 600,
                                    color: "#7d7b7b",
                                    marginTop: "4px",
                                }}
                            >
                                {phoneNo}
                            </Text>
                        </View>

                        <View
                            style={{
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                fontSize:"15px"
                            }}
                        >
                             <Text>Booking ID: {bookingInfo?.bookingId}</Text>
                        </View>
                    </View>

                    <View>
                        <Text
                            style={{
                                fontSize: "12px",
                                color: "#03437B",
                                padding: "20px 0 10px 0",
                            }}
                        >
                            Booking Details
                        </Text>
                        <View style={table}>
                            <View style={{ margin: "auto", flexDirection: "row" }}>
                                <View style={tableColflight}>
                                    <Text style={tableCell}>Booking Id</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}>Check In</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}>Check Out</Text>
                                </View>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>Check Out</Text>
                                </View>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>Refundable</Text>
                                </View>
                                <View style={tableColflight}>
                                    <Text style={tableCell}>Confirmation ID</Text>
                                </View>
                            </View>

                            <View style={{ margin: "auto", flexDirection: "row" }}>
                                <View style={tableColflight}>
                                    <Text style={tableCell}>{bookingInfo?.bookingId}</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}>{moment(bookingInfo?.checkIn).format("D MMMM, yyyy")}</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}>{moment(bookingInfo?.checkout).format("D MMMM, yyyy")}</Text>
                                </View>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>{moment(bookingInfo?.bookedAt).format('YYYY-MM-DD HH:mm:ss')}</Text>
                                </View>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>{bookingInfo?.refundable}</Text>
                                </View>
                                <View style={tableColflight}>
                                    <Text style={tableCell}>{retriveInfo?.request?.confirmationId}</Text>
                                </View>
                            </View>


                        </View>
                    </View>

                    <View>
                        <Text
                            style={{
                                fontSize: "12px",
                                color: "#03437B",
                                padding: "20px 0 10px 0",
                            }}
                        >
                            Hotel Details
                        </Text>
                        <View style={table}>
                            <View style={{ margin: "auto", flexDirection: "row" }}>
                                <View style={tableColNumber}>
                                    <Text style={tableCell}> Hotel Name </Text>
                                </View>
                                <View style={tableColGender}>
                                    <Text style={tableCell}> Hotel Code</Text>
                                </View>
                                <View style={tableColType}>
                                    <Text style={tableCell}>Room Type</Text>
                                </View>
                                <View style={tableColName}>
                                    <Text style={tableCell}>
                                        Address
                                    </Text>
                                </View>
                            </View>
                            <View>
                                <View style={tableRow} >
                                    <View style={tableColNumber}>
                                        <Text style={tableCell}>
                                            {bookingInfo?.hotelName}
                                        </Text>
                                    </View>
                                    <View style={tableColGender}>
                                        <Text style={tableCell}>
                                            {bookingInfo?.hotelCode}
                                        </Text>
                                    </View>
                                    <View style={tableColType}>
                                        <Text style={tableCell}>
                                            {retriveInfo?.hotels && retriveInfo?.hotels[0]?.room?.roomType.split('-')[0]}
                                        </Text>
                                    </View>
                                    <View style={tableColName}>
                                        <Text style={tableCell}>
                                            {retriveInfo?.hotels && retriveInfo?.hotels[0]?.address?.street || ""} ,  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.address?.city || ""} ,  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.address?.countryCode || ""}
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View>
                        <Text
                            style={{
                                fontSize: "12px",
                                color: "#03437B",
                                padding: "20px 0 10px 0",
                            }}
                        >
                            Passengers Info
                        </Text>
                        <View style={table}>
                            <View style={{ margin: "auto", flexDirection: "row" }}>
                                <View style={tableColName}>
                                    <Text style={tableCell}>Passenger Name </Text>
                                </View>
                                <View style={tableColGender}>
                                    <Text style={tableCell}>Passenger Type</Text>
                                </View>
                                <View style={tableColNumber}>
                                    <Text style={tableCell}>Phone Number</Text>
                                </View>
                                <View style={tableColType}>
                                    <Text style={tableCell}>
                                        Lead Guest
                                    </Text>
                                </View>
                            </View>
                            <View>
                                {bookingInfo?.passengerInfo?.map((data, index) => (
                                    <View style={tableRow} key={index}>
                                        <View style={tableColName}>
                                            <Text style={tableCell}>
                                                {data?.fName} {data?.lName}
                                            </Text>
                                        </View>
                                        <View style={tableColGender}>
                                            <Text style={tableCell}>
                                                {data?.type === "ADT"
                                                    ? "ADULT"
                                                    : data.type === "INF"
                                                        ? "INFANT"
                                                        : "CHILD"}
                                            </Text>
                                        </View>
                                        <View style={tableColNumber}>
                                            <Text style={tableCell}>
                                                {data?.phone}
                                            </Text>
                                        </View>
                                        <View style={tableColType}>
                                            <Text style={tableCell}>
                                                {data?.leadGuest === true ? 'True' : "False"}
                                            </Text>
                                        </View>
                                    </View>
                                ))}
                            </View>

                        </View>
                    </View>


                    <View>
                        <Text
                            style={{
                                fontSize: "12px",
                                color: "#03437B",
                                padding: "20px 0 10px 0",
                            }}
                        >
                            Payment Details
                        </Text>
                        <View style={table}>
                            <View style={{ margin: "auto", flexDirection: "row" }}>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>Type</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}>Holder's Name</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}>Email</Text>
                                </View>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>Phone No.</Text>
                                </View>
                                <View style={tableColflight}>
                                    <Text style={tableCell}>Country Code</Text>
                                </View>
                                <View style={tableColflight}>
                                    <Text style={tableCell}>Card Number</Text>
                                </View>
                            </View>

                            <View style={{ margin: "auto", flexDirection: "row" }}>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>{bookingInfo?.paymentInfo?.paymentType}</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}>{bookingInfo?.paymentInfo?.holderFName} {bookingInfo?.paymentInfo?.holderLName}</Text>
                                </View>
                                <View style={tableColfromto}>
                                    <Text style={tableCell}> {bookingInfo?.paymentInfo?.holderEmail}</Text>

                                </View>
                                <View style={tableColtime}>
                                    <Text style={tableCell}>{bookingInfo?.paymentInfo?.holderPhone}</Text>
                                </View>
                                <View style={tableColflight}>
                                    <Text style={tableCell}> {bookingInfo?.paymentInfo?.stateCode}</Text>

                                </View>
                                <View style={tableColflight}>
                                    <Text style={tableCell}>    {bookingInfo?.paymentInfo?.cardNumber}</Text>
                                </View>
                            </View>

                        </View>
                    </View>

                    <View>
                        <View>
                            <Text
                                style={{
                                    fontSize: "12px",
                                    color: "#03437B",
                                    padding: "20px 0 10px 0",
                                }}
                            >
                                Price
                            </Text>
                            <View style={table}>
                                <View style={{ margin: "auto", flexDirection: "row" }}>
                                    <View style={tableHalfCol}>
                                        <Text
                                            style={{
                                                fontSize: "10px",
                                                fontWeight: "bold",
                                                margin: "3px",
                                            }}
                                        >
                                            Total Price : {commaNumber(bookingInfo?.netCost)} BDT
                                        </Text>
                                    </View>
                                </View>
                            </View>
                        </View>

                        <View>
                            <Text
                                style={{
                                    fontSize: "11px",
                                    color: "#03437B",
                                    padding: "10px 0px",
                                    marginTop: "30px"
                                }}
                            >
                                In Words: {capitalize} Taka Only
                            </Text>
                        </View>
                    </View>

                </View>
            </Page>
        </Document>
    );
};

export default HotelPdfDesign;
