import { Box, Stack, Typography } from "@mui/material";
import React from "react";

const OnewayPrice = ({ roundData }) => {
  const { BasePrice, Taxes, clientPrice, pricebreakdown, price } = roundData;

  return (
    <Box sx={{ bgcolor: "#F6F6FF", py: "20px", borderRadius: "7px" }}>
      <Box sx={{ padding: "0 0 18px 17px" }}>
        <Typography
          variant="body2"
          sx={{ color: "#444542", fontWeight: "500" }}
        >
          Total Payable
        </Typography>
        <Typography variant="title-lg" sx={{ color: "#444542" }}>
          ৳ {Number(price).toLocaleString("en-IN")}
        </Typography>
      </Box>

      <Typography
        sx={{
          color: "white",
          fontSize: "12px",
          bgcolor: "var(--secondary-color)",
          padding: "4px 17px",
          fontWeight: 500,
        }}
      >
        Price Breakdown
      </Typography>

      {pricebreakdown.map((price, i) => {
        const { BaseFare, PaxCount, PaxType, Tax } = price;
        console.log(BaseFare)
        return (
          <Box key={i} sx={{ padding: "20px 17px 0" }}>
            <Typography variant="h6">
              {PaxType === "ADT"
                ? "Adult"
                : PaxType === "CNN"
                  ? "Child"
                  : "Infant"}{" "}
              x{PaxCount}
            </Typography>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="caption">Base Fare x{PaxCount}</Typography>
              <Typography variant="subtitle1" style={{color:"var(--secondary-color)"}}>
                {Number(parseFloat(BaseFare.match(/\d+/)[0], 10) * PaxCount).toLocaleString("en-IN")}
              </Typography>
            </Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="caption">Tax x{PaxCount}</Typography>
              <Typography variant="subtitle1" style={{color:"var(--secondary-color)"}}>
                {Number(PaxCount * Tax).toLocaleString("en-IN")}
              </Typography>
            </Stack>
          </Box>
        );
      })}

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ padding: "20px 17px 0" }}
      >
        <Typography variant="caption">Total Base & Tax</Typography>
        <Typography variant="subtitle1" style={{color:"var(--secondary-color)"}}>
          {(parseFloat(BasePrice?.match(/\d+/)[0], 10) + parseFloat(Taxes)).toLocaleString("en-IN")}
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ padding: "18px 17px 0" }}
      >
        <Typography variant="caption">Customer Invoice Total</Typography>
        <Typography variant="subtitle1" style={{color:"var(--secondary-color)"}}>
          {Number(price).toLocaleString("en-IN")}
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ padding: "18px 17px 0" }}
      >
        <Typography variant="caption">Discount</Typography>
        <Typography variant="subtitle1" style={{color:"var(--secondary-color)"}}>
          {(clientPrice - price).toLocaleString("en-IN")}
        </Typography>
      </Stack>

      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        sx={{ padding: "18px 17px 0" }}
      >
        <Typography
          sx={{ fontSize: "14px", color: "#ACA6A6", fontWeight: "500" }}
        >
          Grand Total
        </Typography>
        <Typography sx={{ color: "var(--secondary-color)", fontSize: "14px" }}>
          ৳ {Number(price).toLocaleString("en-IN")}
        </Typography>
      </Stack>
    </Box>
  );
};

export default OnewayPrice;
