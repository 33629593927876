import { Box, Grid, Typography, Stack } from "@mui/material";
import moment from "moment";
import React from "react";
const isMobile = window.innerWidth <= 768;

const GoSearchCard = ({ roundFlightData }) => {
  const {
    career,
    careerName,
    goarrival,
    goarrivalDate,
    goarrivalTime,
    godeparture,
    godepartureDate,
    godepartureTime,
    goflightduration,
    segments,
    segment,
    refundable,
    price,
    clientPrice,
    system,
  } = roundFlightData;
  return (
    <>
      {isMobile ? (
        <>
          <Grid order={{ xs: 1, lg: 1 }} xs={12} lg={2.5}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <img
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${career}.png`}
                width="40px"
                height="40px"
                alt=""
              />

              <Box>
                <Typography
                  variant="body2"
                  sx={{ color: "var(--secondary-color)", fontWeight: "500" }}
                >
                  {careerName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#222", fontWeight: "500" }}
                >
                  {segments?.go?.map(
                    (s, i) =>
                      `${
                        i === 0
                          ? `${s.marketingcareer} ${s.marketingflight}`
                          : ` & ${s.marketingcareer} ${s.marketingflight}`
                      }`
                  )}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid order={{ xs: 3, lg: 2 }} xs={4} lg={2.6}>
            <Box sx={{ mt: "10px" }}>
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Departure
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "19px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {godeparture}{" "}
                <span
                  style={{
                    color: "#222",
                    fontSize: "10px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {moment(godepartureTime, "HH:mm").format("hh:mm A")}
                </span>
              </Typography>

              <Typography sx={{ fontSize: "10px", color: "#8C8080" }}>
                {godepartureDate}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 4, lg: 3 }} xs={4} lg={1.75}>
            <Box sx={{ mt: "50px" }}>
              <Typography
                sx={{
                  color: "#444542",
                  textAlign: "center",
                  mr: "10px",
                  fontSize: "11px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {segments?.go?.length >= 2
                  ? `${segments?.go?.length - 1} stop${
                      segments?.go?.length - 1 > 1 ? "s" : ""
                    }`
                  : "No stop"}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 5, lg: 4 }} xs={4} lg={2.6}>
            <Box sx={{ mt: "10px" ,textAlign:"right"}}>
              <Typography
                variant="body2"
                sx={{ color: "#8C8080", textAlign: "right" }}
              >
                Arrival
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "19px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {goarrival}{" "}
                <span
                  style={{
                    color: "#222",
                    fontSize: "10px",
                    py: "4px",
                    fontWeight: "500",
                  }}
                >
                  {moment(goarrivalTime, "HH:mm").format("hh:mm A")}
                </span>
              </Typography>

              <Typography sx={{ fontSize: "10px", color: "#8C8080" }}>
                {goarrivalDate}
              </Typography>
            </Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item order={{ xs: 4, lg: 1 }} xs={6} lg={2.5}>
            <Box>
              <img
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${career}.png`}
                width="60px"
                height="60px"
                alt=""
              />

              <Typography
                variant="body2"
                sx={{ color: "var(--secondary-color)" }}
              >
                {careerName}
              </Typography>
              <Typography variant="body2" sx={{ color: "#222" }}>
                {segments.go.map(
                  (s, i) =>
                    `${
                      i === 0
                        ? `${s.marketingcareer} ${s.marketingflight}`
                        : ` & ${s.marketingcareer} ${s.marketingflight}`
                    }`
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid item order={{ xs: 1, lg: 2 }} xs={3} lg={2.6}>
            <Box>
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Depart
              </Typography>
              <Typography variant="title-lg">{godeparture}</Typography>
              <Typography
                variant="body2"
                sx={{ color: "var(--secondary-color)" }}
              >
                {segments.go[0].departureAirport}
              </Typography>
              <Typography variant="body2" sx={{ color: "#222" }}>
                {moment(godepartureTime, "HH:mm:ss").format("hh:mm A")}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                {godepartureDate}
              </Typography>
            </Box>
          </Grid>

          <Grid item order={{ xs: 2, lg: 3 }} xs={3} lg={2}>
            <Box>
              <Typography
                variant="body2"
                sx={{ color: "#ACA6A6", textAlign: "center", mr: "10px" }}
              >
                {(system === "Sabre" || system === "Galileo") &&
                goflightduration.includes("H")
                  ? goflightduration
                  : `${parseInt(goflightduration / 60)}H ${
                      goflightduration % 60
                    }M`}
              </Typography>
              <svg
                width="125"
                height="12"
                viewBox="0 0 689 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                  stroke="#ED1A5C"
                  stroke-width="9"
                />
                <path d="M689 46.6569L541.002 0V46.6569H689Z" fill="#ED1A5C" />
              </svg>
              <Typography
                variant="body2"
                sx={{ color: "#444542", textAlign: "center", mr: "10px" }}
              >
                {segments.go.length >= 2
                  ? `${segments?.go.length - 1} stop${
                      segments?.go.length - 1 > 1 ? "s" : ""
                    }`
                  : "No stop"}
              </Typography>
            </Box>
          </Grid>

          <Grid item order={{ xs: 3, lg: 4 }} xs={3} lg={2.6}>
            <Box>
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Arrival
              </Typography>
              <Typography variant="title-lg">{goarrival}</Typography>
              <Typography
                variant="body2"
                sx={{ color: "var(--secondary-color)",  }}
              >
                {segments.go[segments.go.length - 1].arrivalAirport}
              </Typography>
              <Typography variant="body2" sx={{ color: "#222" }}>
                {moment(goarrivalTime, "HH:mm:ss").format("hh:mm A")}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                {goarrivalDate}
              </Typography>
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default GoSearchCard;
