import { Box } from "@mui/material";
import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const FareDetails = ({ pricebreakdown }) => {
  return (
    <Box
      sx={{
        padding: "15px 0 0 0",
        ".MuiTableCell-root .MuiPaper-root": {
          border: "none",
        },
        ".MuiTable-root": {
          th: {
            color: "white",
            fontSize: "13px",
            fontWeight: "500",
          },
          td: {
            color: "var(--primary-color)",
            fontSize: "13px",
            fontWeight: "500",
          },
          ".MuiTableCell-root": {
            padding: "10px",
          },
        },
      }}
    >
      <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
        <Table aria-label="simple table">
          <TableHead sx={{ minWidth: "100%" }}>
            <TableRow sx={{ backgroundColor: "var(--primary-color)" }}>
              <TableCell align="left">Pax Type</TableCell>
              <TableCell align="left">Base Fee</TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: "none",
                    lg: "table-cell",
                  },
                }}
                align="left"
              >
                Tax
              </TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: "table-cell",
                    lg: "none",
                  },
                }}
                align="left"
              >
                Tax + Service
              </TableCell>
              <TableCell align="center">Total</TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: "none",
                    lg: "table-cell",
                  },
                }}
                align="center"
              >
                Pax Count
              </TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: "none",
                    lg: "table-cell",
                  },
                }}
                align="center"
              >
                Service Fee
              </TableCell>
              <TableCell
                sx={{
                  display: {
                    xs: "none",
                    lg: "table-cell",
                  },
                }}
                align="center"
              >
                Sub Total
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {pricebreakdown?.map((data, i) => {
              const baseFare = data.BaseFare.replace(/[^0-9]/g, "");
              const tax = data.Tax.replace(/[^0-9]/g, "");
              const serviceFee = data.ServiceFee.replace(/[^0-9]/g, "");
              const total = Number(baseFare) + Number(tax);
              const subTotal =
                (Number(baseFare) + Number(tax) + Number(serviceFee)) *
                Number(data.PaxCount);
              return (
                <TableRow
                  key={i}
                  sx={{
                    backgroundColor: `${(i + 1) % 2 !== 0 ? "#DEE8FF" : ""}`,
                  }}
                >
                  <TableCell
                    sx={{
                      display: {
                        xs: "table-cell",
                        lg: "none",
                      },
                    }}
                    align="left"
                  >
                    {data.PaxType}{" "}
                    <sup style={{ fontSize: "12px" }}>x{data.PaxCount}</sup>
                  </TableCell>
                  <TableCell
                    sx={{
                      display: {
                        xs: "none",
                        lg: "table-cell",
                      },
                    }}
                    align="left"
                  >
                    {data.PaxType}
                  </TableCell>

                  <TableCell align="left">
                    {Number(baseFare).toLocaleString("en-IN")}
                  </TableCell>

                  <TableCell
                    sx={{
                      display: {
                        xs: "table-cell",
                        lg: "none",
                      },
                    }}
                    align="left"
                  >
                    {Number(tax).toLocaleString("en-IN")} + {data.ServiceFee}
                  </TableCell>

                  <TableCell
                    sx={{
                      display: {
                        xs: "none",
                        lg: "table-cell",
                      },
                    }}
                    align="left"
                  >
                    {Number(tax).toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell align="center">
                    {Number(total).toLocaleString("en-IN")}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      display: {
                        xs: "none",
                        lg: "table-cell",
                      },
                    }}
                  >
                    {data.PaxCount}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: {
                        xs: "none",
                        lg: "table-cell",
                      },
                    }}
                    align="center"
                  >
                    {data.ServiceFee}
                  </TableCell>
                  <TableCell
                    sx={{
                      display: {
                        xs: "none",
                        lg: "table-cell",
                      },
                    }}
                    align="center"
                  >
                    {Number(subTotal).toLocaleString("en-IN")}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FareDetails;
