/* eslint-disable react/jsx-no-comment-textnodes */
import React, { useState } from "react";
import { Box, Button, Grid, Tab, Tabs, Container } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { format } from "date-fns";
import ChangeHistoryIcon from "@mui/icons-material/ChangeHistory";
import triangle from "../../images/Icon/triangle.png";
// import "./SingleFlight.css";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "var(--secondary-color)",
    maxWidth: 300,
    padding: "10px",
  },
}));

const Transit = ({ flightData }) => {
  console.log("one way flights details transti", flightData.segments);

  return (
    <Box textAlign={"center"} padding="0px 7px" sx={{ mt: "40px" }}>
      <Typography>
        {flightData?.segments === "3" ? (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Box display="flex">
                  <Box borderRight="2px solid var(--third-color)" px={1}>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        fontSize: "11px",
                        textAlign: "center",
                      }}
                    >
                      Transit: {flightData?.transit?.transit1} <br />
                      <span
                        style={{
                          fontSize: "17px",
                          color: "#222222",
                          fontWeight: 500,
                        }}
                      >
                        {flightData?.segments[1].departure}{" "}
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        fontSize: "11px",
                      }}
                    >
                      {flightData?.segments[1]?.departureLocation} <br />
                      {flightData?.segments[1]?.marketingcareer}{" "}
                      {flightData?.segments[1]?.marketingflight}
                      {" & "}
                      {flightData?.segments[1]?.flightduration}
                      <br />
                      {format(
                        new Date(
                          flightData?.segments[1]?.departureTime?.toString()
                        ),
                        "dd MMM yyyy hh:mm a"
                      )}
                    </Typography>
                  </Box>

                  <Box px={1}>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        fontSize: "11px",
                        textAlign: "center",
                      }}
                    >
                      Transit: {flightData?.transit?.transit2} <br />
                      <span
                        style={{
                          fontSize: "17px",
                          fontWeight: "500",
                          color: "#222222",
                        }}
                      >
                        {flightData?.segments[2].departure}{" "}
                      </span>
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 500,
                        fontSize: "11px",
                      }}
                    >
                      {flightData?.segments[2]?.departureLocation} <br />
                      {flightData?.segments[2]?.marketingcareer}{" "}
                      {flightData?.segments[2]?.marketingflight}
                      {" & "}
                      {flightData?.segments[2]?.flightduration}
                      <br />
                      {format(
                        new Date(
                          flightData?.segments[2]?.departureTime?.toString()
                        ),
                        "dd MMM yyyy hh:mm a"
                      )}
                    </Typography>
                  </Box>
                </Box>
              </React.Fragment>
            }
            followCursor
          >
            <Box sx={{ mt: 1 }}>
              <Typography
                sx={{
                  color: "var(--third-color)",
                  fontWeight: 400,
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "11px",
                  },
                  mb: "4px",
                }}
              >
                {flightData?.flightduration}
              </Typography>
              <Box sx={{ position: "relative", pl: "20px" }}>
                <hr
                  style={{
                    backgroundcolor: "var(--primary-color)",
                    fontWeight: 500,
                    width: "80%",
                  }}
                />
                <img
                  src={triangle}
                  alt="icon"
                  style={{ position: "absolute", top: "-13px", right: "5%" }}
                />
              </Box>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontWeight: 500,
                  fontSize: {
                    xs: "10px",
                    sm: "10px",
                    md: "12px",
                  },
                }}
              >
                2 STOP
              </Typography>
            </Box>
          </HtmlTooltip>
        ) : flightData?.segment === "2" ? (
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontWeight: 500,
                    fontSize: "11px",
                    textAlign: "center",
                  }}
                >
                  Transit: {flightData?.transit?.transit1} <br />
                  <span
                    style={{
                      fontSize: "17px",
                      fontWeight: "500",
                      color: "#222222",
                    }}
                  >
                    {flightData?.segments[1].departure}{" "}
                  </span>
                </Typography>

                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontWeight: 500,
                    fontSize: "11px",
                  }}
                >
                  {flightData?.segments[1]?.departureLocation} <br />
                  {flightData?.segments[1]?.marketingcareer}{" "}
                  {flightData?.segments[1]?.marketingflight}
                  {" & "}
                  {flightData?.segments[1]?.flightduration}
                  <br />
                  {format(
                    new Date(
                      flightData?.segments[1]?.departureTime?.toString()
                    ),
                    "dd MMM yyyy hh:mm a"
                  )}
                  <br />
                </Typography>
              </React.Fragment>
            }
            followCursor
          >
            <Box sx={{ mt: 1 }}>
              <Typography
                sx={{
                  color: "var(--third-color)",
                  fontWeight: 400,
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "11px",
                  },
                  mb: "4px",
                }}
              >
                {flightData?.flightduration}
              </Typography>

              <Box sx={{ position: "relative", pl: "20px" }}>
                <hr
                  style={{
                    backgroundcolor: "#222222",
                    fontWeight: 500,
                    width: "80%",
                  }}
                />
                <img
                  src={triangle}
                  alt="icon"
                  style={{ position: "absolute", top: "-13px", right: "5%" }}
                />
              </Box>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontWeight: 500,
                  fontSize: {
                    xs: "10px",
                    sm: "10px",
                    md: "12px",
                  },
                }}
              >
                1 STOP
              </Typography>
            </Box>
          </HtmlTooltip>
        ) : (
          <Box sx={{ mt: 1 }}>
            <Typography
              sx={{
                color: "var(--third-color)",
                fontWeight: 400,
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "11px",
                },
                mb: "4px",
              }}
            >
              {flightData.flight_duration}
            </Typography>

            <Box sx={{ position: "relative", pl: "20px" }}>
              <hr
                style={{
                  backgroundcolor: "var(--primary-color)",
                  fontWeight: 500,
                  width: "80%",
                }}
              />
              <img
                src={triangle}
                alt="icon"
                style={{ position: "absolute", top: "-13px", right: "5%" }}
              />
            </Box>

            <Typography
              sx={{
                color: "var(--primary-color)",
                fontWeight: 500,
                fontSize: {
                  xs: "10px",
                  sm: "10px",
                  md: "12px",
                },
              }}
            >
              NON STOP
            </Typography>
          </Box>
        )}
      </Typography>
    </Box>
  );
};

export default Transit;
