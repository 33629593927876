import React from "react";
import { Box, Container, Typography } from "@mui/material";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import ReactHtmlParser from "react-html-parser";
import { useState } from "react";
import { useEffect } from "react";

const Privacy = () => {

  const [privacy, setPrivacy] = useState('');

  useEffect(() => {
    fetch(`https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&privacyPolicy`).then((res) => res.json()).then((data) => setPrivacy(data?.content))
  }, [])

  return (
    <Box>
      <Header />
      <Container sx={{ my: 5 }}>
        <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", mb: "30px" }}>
          <Typography fontSize="40px" textAlign="center">
            Privacy Policy
          </Typography>
          <Box sx={{ width: "350px", height: "3px", backgroundColor: "var(--primary-color)" }}>
          </Box>
        </Box>

        <Typography
          sx={{
            marginTop: "10px",
            color: "var(--black) !important",
            textAlign: "justify",
          }}
        >
          {ReactHtmlParser(privacy)}
        </Typography>
      </Container>
      <Footer />
    </Box>
  );
};


export default Privacy;
