import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import SubjectIcon from "@mui/icons-material/Subject";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

const Contact = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [subject, setSubject] = useState("");
  const [description, setDescription] = useState("");
  const [touchText, setTouchText] = useState("");

  const handleContact = (e) => {
    e.preventDefault();
    const url = `https://api.rtc.flyfarint.com/v1.0/support/message.php`;
    let body = JSON.stringify({
      name: name,
      email: email,
      contactNumber: phone,
      subject: subject,
      message: description,
    });

    console.log(url, body);

    fetch(url, {
      method: "POST",
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        if (data?.status === "success") {
          Swal.fire({
            title: "Success",
            html: data?.message,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          }).then(function () {
            navigate(0);
          });
        }
      });
  };

  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&getInTouch`
    )
      .then((res) => res.json())
      .then((data) => setTouchText(data?.content));
  }, []);

  return (
    <Box>
      <Box mt={8}>
        <Container>
          <Grid
            container
            spacing={6}
            display="flex"
            alignItems="center"
            justifyContent={"center"}
          >
            <Grid item xs={12} sm={6} md={6}>
              <Box style={{ display: "flex" }}>
                <HorizontalRuleIcon style={{ color: "var(--primary-color)" }} />
                <Typography
                  style={{
                    color: "var(--mateBlack)",
                    textAlign: "center",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Get in Touch
                </Typography>
              </Box>

              <Typography
                style={{
                  color: "var(--mateBlack)",
                  fontSize: "22px",
                  fontWeight: "500",
                }}
                mt={1}
              >
                Get A Free Quote to Create your Desired{" "}
                <span style={{ color: "var(--primary-color)" }}>Business</span>{" "}
              </Typography>

              <Typography
                mt={3}
                style={{
                  color: "var(--mateBlack)",
                  fontSize: "14px",
                  textAlign: "justify",
                }}
              >
                {ReactHtmlParser(touchText)}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <form onSubmit={handleContact}>
                <Box
                  style={{
                    backgroundColor: "#F1F2F6",
                    borderRadius: "5px",
                    padding: "25px 20px",
                  }}
                >
                  <Box style={{ position: "relative" }} mb={2}>
                    <input
                      required
                      style={{
                        width: "100%",
                        height: "32px",
                        outline: "none",
                        border: "none",
                        padding: "0px 10px",
                        boxSizing: "border-box",
                        color: "var(--mateBlack)",
                      }}
                      type="text"
                      placeholder="Full Name"
                      onChange={(e) => setName(e.target.value)}
                    />

                    <PersonIcon
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "3px",
                        color: "#9C9797",
                      }}
                    />
                  </Box>
                  <Box style={{ position: "relative" }} mb={2}>
                    <input
                      required
                      style={{
                        width: "100%",
                        height: "32px",
                        outline: "none",
                        border: "none",
                        padding: "0px 10px",
                        boxSizing: "border-box",
                        color: "var(--mateBlack)",
                      }}
                      type="email"
                      placeholder="Your Email"
                      onChange={(e) => setEmail(e.target.value)}
                    />

                    <MarkunreadIcon
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "3px",
                        color: "#9C9797",
                      }}
                    />
                  </Box>
                  <Box style={{ position: "relative" }} mb={2}>
                    <input
                      required
                      style={{
                        width: "100%",
                        height: "32px",
                        outline: "none",
                        border: "none",
                        padding: "0px 10px",
                        boxSizing: "border-box",
                        color: "var(--mateBlack)",
                      }}
                      type="number"
                      placeholder="Your Contact Number"
                      onChange={(e) => setPhone(e.target.value)}
                    />
                    <PermPhoneMsgIcon
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "3px",
                        color: "#9C9797",
                      }}
                    />
                  </Box>
                  <Box style={{ position: "relative" }} mb={2}>
                    <input
                      required
                      style={{
                        width: "100%",
                        height: "32px",
                        outline: "none",
                        border: "none",
                        padding: "0px 10px",
                        boxSizing: "border-box",
                        color: "var(--mateBlack)",
                      }}
                      type="text"
                      placeholder="Subject"
                      onChange={(e) => setSubject(e.target.value)}
                    />
                    <SubjectIcon
                      style={{
                        position: "absolute",
                        right: "8px",
                        top: "3px",
                        color: "#9C9797",
                      }}
                    />
                  </Box>
                  <Box>
                    <textarea
                      required
                      style={{
                        width: "100%",
                        height: "100px",
                        border: "none",
                        resize: " none",
                        padding: "6px 10px",
                        boxSizing: "border-box",
                        color: "var(--mateBlack)",
                      }}
                      type="text"
                      placeholder="Message"
                      onChange={(e) => setDescription(e.target.value)}
                    ></textarea>
                  </Box>
                  <Box
                    style={{ display: "flex", justifyContent: "flex-end" }}
                    mt={1.5}
                  >
                    <button
                      style={{
                        width: "125px",
                        height: "32px",
                        background: "var(--secondary-color)",
                        color:"white",
                        border: "none",
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                      type="submit"
                    >
                      Send Message
                    </button>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default Contact;
