import { useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { useNavigate, Outlet, NavLink } from "react-router-dom";
import TollIcon from "@mui/icons-material/Toll";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import "./AdminDashboardSideBar.css";
import AdminAuthentication from "../Admin/AdminAuthentication/AdminAuthentication";
import secureLocalStorage from "react-secure-storage";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import SummarizeIcon from "@mui/icons-material/Summarize";

// Active color ListItem start
const useStyles = makeStyles((theme) => ({
  root: {
    width: "90%",
  },
}));

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "#222222",
      color: "white",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
    "&$selected:hover": {
      backgroundColor: "#222222",
      color: "white",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "white",
      },
    },
  },
  selected: {},
})(MuiListItem);

const SubListItem = withStyles({
  subselected: {},
})(MuiListItem);

const drawerWidth = 200;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 20px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const AdminDashboardSideBar = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);

  const [dashBoard, setDashBoard] = useState(false);
  const [manage, setManage] = useState(false);
  const [search, setSearch] = useState(false);
  const [agent, setAgent] = useState(false);
  const [booking, setBooking] = useState(false);
  const [payment, setPayment] = useState(false);
  const [account, setAccount] = useState(false);

  const navigate = useNavigate();

  const { logout } = AdminAuthentication();

  //   -------------------submenu start here ----------------

  const classes = useStyles();

  const [selectedIndex, setSelectedIndex] = useState();
  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 0) {
      setOpen(true);
      setDashBoard(true);
      setManage(false);
      setSearch(false);
      setAgent(false);
      setBooking(false);
      setPayment(false);
    } else if (index === 1) {
      setOpen(true);
      setDashBoard(false);
      setManage(true);
      setSearch(false);
      setAgent(false);
      setBooking(false);
      setPayment(false);
    } else if (index === 2) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setSearch(true);
      setAgent(false);
      setBooking(false);
      setPayment(false);
    } else if (index === 3) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setSearch(false);
      setAgent(true);
      setBooking(false);
      setPayment(false);
    } else if (index === 4) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setSearch(false);
      setAgent(false);
      setBooking(true);
      setPayment(false);
    } else if (index === 5) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setSearch(false);
      setAgent(false);
      setBooking(false);
      setPayment(true);
    } else if (index === 6) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setSearch(false);
      setAgent(false);
      setBooking(false);
      setPayment(false);
      setAccount(true);
    } else if (index === 7) {
      setOpen(true);
      setDashBoard(false);
      setManage(false);
      setSearch(false);
      setAgent(false);
      setBooking(false);
      setPayment(false);
      setAccount(true);
    }
  };

  const [subSelectedIndex, setSubSelectedIndex] = useState();
  const handleSubListItemClick = (event, index) => {
    // setSubSelectedIndex(index);
  };

  //   -------------------

  const user = secureLocalStorage.getItem("admin-info");
 

  return (
    <Box
      sx={{ display: "flex", "&:MuiTypography-root": { fontSize: "5px" } }}
      className="admin-sideBar-user"
    >
      <CssBaseline />

      <Drawer variant="permanent" open={open}>
        <DrawerHeader
          style={{
            display: "block",
            backgroundColor: "var(--primary-color)",
          }}
        >
          <Box onClick={() => setOpen(!open)}>
            <MenuIcon
              style={{
                color: "var(--white)",
                fontSize: "22px",
                margin: "20px 15px 10px 15px",
                cursor: "pointer",
              }}
            />
          </Box>
        </DrawerHeader>

        <Box backgroundColor="var(--primary-color)" height="100vh">
          <List>
            {/* //todo: dashboard */}
            <NavLink
              to="/admin/dashboard"
              style={{ display: "block", marginBottom: "10px" }}
            >
              <ListItem
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
                style={{ padding: "0px" }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    p: "5px 3px 5px 20px",
                  }}
                >
                  <DashboardIcon
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                  <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                    Dashboard
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            {/* //todo: Manage */}
            <NavLink
              to="/admin/controlpanel"
              style={{ display: "block", marginBottom: "10px" }}
            >
              <ListItem
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
                style={{ padding: "0px" }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    p: "5px 3px 5px 20px",
                  }}
                >
                  <AssessmentIcon sx={{ fontSize: "16px" }} />
                  <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                    Manage
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            {selectedIndex === 1 && manage && (
              <Box className="admin-dash-sub-menu">
                <Box
                  sx={{
                    opacity: open ? 1 : 0,
                    display: open === true ? "" : "none",
                  }}
                >
                  <NavLink
                    to="/admin/controlpanel"
                    style={{ display: "block" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Home Content
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink to="/admin/groupfare" style={{ display: "block" }}>
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Group Fare
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  {/* <NavLink to="/admin/other" style={{ display: "block" }}>
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Others
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink to="/admin/control" style={{ display: "block" }}>
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Control
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink> */}
                  <NavLink to="/admin/visa" style={{ display: "block" }}>
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Visa
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink to="/admin/markup" style={{ display: "block" }}>
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Markup
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                </Box>
              </Box>
            )}

            {/* //todo: Agent */}
            <NavLink
              to="/admin/agentmanagement"
              style={{ display: "block", marginBottom: "10px" }}
            >
              <ListItem
                selected={selectedIndex === 3}
                onClick={(event) => handleListItemClick(event, 3)}
                style={{ padding: "0px" }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    p: "5px 3px 5px 20px",
                  }}
                >
                  <ManageAccountsIcon
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      ml: 1,
                    }}
                  >
                    Agent
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            {/* //todo: Booking */}
            <NavLink
              to="/admin/bookingroute"
              style={{ display: "block", marginBottom: "10px" }}
            >
              <ListItem
                selected={selectedIndex === 4}
                onClick={(event) => handleListItemClick(event, 4)}
                style={{ padding: "0px" }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    p: "5px 3px 5px 20px",
                  }}
                >
                  <TollIcon sx={{ fontSize: "16px" }} />
                  <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                    Booking
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            {/* //todo: Payment */}
            <NavLink
              to="/admin/paymentmanagement"
              style={{ display: "block", marginBottom: "10px" }}
            >
              <ListItem
                selected={selectedIndex === 5}
                onClick={(event) => handleListItemClick(event, 5)}
                style={{ padding: "0px" }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    p: "5px 3px 5px 20px",
                  }}
                >
                  <PriceCheckIcon
                    sx={{
                      fontSize: "16px",
                    }}
                  />
                  <ListItemText
                    sx={{
                      opacity: open ? 1 : 0,
                      ml: 1,
                    }}
                  >
                    Payment
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>

            {/* Sub Menu Account Section   */}

            {user?.user[0]?.role === "staff" ? (
              ""
            ) : (
              <>
                <NavLink
                  to="/admin/account"
                  style={{ display: "block", marginBottom: "10px" }}
                >
                  <ListItem
                    selected={selectedIndex === 6}
                    onClick={(event) => handleListItemClick(event, 6)}
                    style={{ padding: "0px" }}
                  >
                    <ListItemButton
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        p: "5px 3px 5px 20px",
                      }}
                    >
                      <AccountBalanceWalletIcon sx={{ fontSize: "16px" }} />
                      <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                        Account
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </NavLink>
                {selectedIndex === 6 && account && (
                  <Box className="admin-dash-sub-menu">
                    <Box
                      sx={{
                        opacity: open ? 1 : 0,
                        display: open === true ? "" : "none",
                      }}
                    >
                      <NavLink to="/admin/account" style={{ display: "block" }}>
                        <SubListItem style={{ padding: "0px" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              p: "0px 3px 0px 18px",
                            }}
                          >
                            <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                ml: 1,
                              }}
                            >
                              My Account
                            </ListItemText>
                          </ListItemButton>
                        </SubListItem>
                      </NavLink>
                      <NavLink
                        to="/admin/bankaccount"
                        style={{ display: "block" }}
                      >
                        <SubListItem style={{ padding: "0px" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              p: "0px 3px 0px 18px",
                            }}
                          >
                            <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                ml: 1,
                              }}
                            >
                              Bank Account
                            </ListItemText>
                          </ListItemButton>
                        </SubListItem>
                      </NavLink>
                    </Box>
                  </Box>
                )}
              </>
            )}

            <NavLink
              to="/admin/generalLedger"
              style={{ display: "block", marginBottom: "10px" }}
            >
              <ListItem
                selected={selectedIndex === 7}
                onClick={(event) => handleListItemClick(event, 7)}
                style={{ padding: "0px" }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    p: "5px 3px 5px 20px",
                  }}
                >
                  <SummarizeIcon sx={{ fontSize: "16px" }} />
                  <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                    Report
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
            {selectedIndex === 7 && account && (
              <Box className="admin-dash-sub-menu">
                <Box
                  sx={{
                    opacity: open ? 1 : 0,
                    display: open === true ? "" : "none",
                  }}
                >
                  <NavLink
                    to="/admin/generalLedger"
                    style={{ display: "block" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Ledger Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink to="/admin/salesReport" style={{ display: "block" }}>
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Sales Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>

                  <NavLink
                    to="/admin/vendorReport"
                    style={{ display: "block" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Vendor Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>

                  <NavLink
                    to="/admin/segmentReport"
                    style={{ display: "block" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Segment Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink
                    to="/admin/searchcount"
                    style={{ display: "block", marginBottom: "10px" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                        }}
                      >
                        {/* <ContentPasteSearchIcon
                    sx={{
                      fontSize: "16px",
                    }}
                  /> */}
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Search Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                </Box>
              </Box>
            )}

            {/* Sub Menu Account Section end  */}

            <NavLink to="/" style={{ display: "block", marginTop: "55%" }}>
              <ListItem
                selected={selectedIndex === 8}
                onClick={logout}
                style={{ padding: "0px" }}
              >
                <ListItemButton
                  sx={{
                    justifyContent: open ? "initial" : "center",
                    p: "5px 3px 5px 20px",
                  }}
                >
                  <ExitToAppIcon />
                  <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                    Log Out
                  </ListItemText>
                </ListItemButton>
              </ListItem>
            </NavLink>
          </List>
        </Box>
      </Drawer>

      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};

export default AdminDashboardSideBar;
