import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import commaNumber from "comma-number";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useQuery } from "react-query";
import logo from "../../images/logo/rtclogo.png";
const companyInfo = {
  name: "Fly Far International",
  email: "support@flyfarint.com",
  phone: "09606-912912",
  address:
    "1229, Hazi Abdul Latif Mansion, 2nd Floor, Ka-9, Bashundhara Rd, Dhaka",
};

var converter = require("number-to-words");

const PDFPageDesign = ({
  userData,
  allData,
  adultTotalPrice,
  childTotalPrice,
  infantTotalPrice,
  totalPrice,
  prices,
  copy,
  check,
  companyImage,
  margin,
  setMultiCityAllData,
  // discount,
  discountPrice,
  multiCityAllData,
  markupPrice,
}) => {
  const table = {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };
  const tableRow = {
    margin: "auto",
    flexDirection: "row",
  };

  const tableCol = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  //Passenger Details
  const tableColName = {
    width: "35%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColGender = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColType = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColNumber = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColfromto = {
    width: "22%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColtime = {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColflight = {
    width: "17%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableCol2 = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  };

  const tableCell = {
    // margin: "auto",
    margin: "3px",
    fontSize: "8px",
  };
  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "8px",
  };

  const adress = {
    fontSize: "10px",
    color: "#8b8b8b",
  };

  const user = secureLocalStorage.getItem("user-info");

  // const users = secureLocalStorage.getItem("user-info");
  // let agentID = users?.agentId;
  const [companyName, setCompanyName] = useState("");
  const [siteConfig, setSiteConfig] = useState({});
  const gCost = parseInt(allData?.booking.gross_cost);
  const tCost = parseInt(allData?.booking.tax);
  const bCost = parseInt(allData?.booking.base_fare);
  const otherPrice = gCost - (tCost + bCost);
  const baseFareTotal =
    parseInt(otherPrice) + parseInt(allData?.booking.base_fare);
  const gross = parseInt(allData?.booking.gross_cost);
  const mark = parseInt(markupPrice);
  let word, capitalize;

  if (isNaN(gross) || isNaN(mark)) {
    console.error("Invalid numeric values for grossCost or markupPrice.");
  } else {
    const total = gross + mark;

    if (isFinite(total)) {
      word = converter.toWords(total);
      const arr = word.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
      }
      capitalize = arr.join(" ");
    } else {
      console.error("Total is not a finite number.");
    }
  }

  const displayBaggageAllowances = (baggageList) => {
    const baggageInfo = baggageList.map((baggage) => {
      const { pax_type, checked_total_weight } = baggage;

      switch (pax_type) {
        case "A":
          return `ADT - ${checked_total_weight} Kg`;
        case "C":
          return `CNN - ${checked_total_weight} Kg`;
        case "I":
          return `INF - ${checked_total_weight} Kg`;
        default:
          return "";
      }
    });

    return baggageInfo.join(", ");
  };

  return (
    <Document>
      <Page size="A4" style={{ padding: "27px 20px" }}>
        <View style={{ padding: "15px" }}>
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            ></View>
            <View>
              <Text
                style={{
                  fontSize: "13px",
                  fontWeight: 600,
                  color: "#2156a6",
                  margin: "0px 0px 6px 0px",
                }}
              >
                Fly Far International
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: 600,
                  color: "#7d7b7b",
                }}
              >
                1229, Hazi Abdul Latif Mansion, 2nd Floor,
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: 600,
                  color: "#7d7b7b",
                  marginTop: "3px",
                }}
              >
                Ka-9, Bashundhara Rd, Dhaka
              </Text>
              <Text
                style={{
                  fontSize: "10px",
                  fontWeight: 600,
                  color: "#7d7b7b",
                  marginTop: "4px",
                }}
              >
                +09606-912912
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: `${margin || 0}`,
            }}
          >
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                width: "200px",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingBottom: "5px",
                }}
              >
                {userData?.agent_comp_name}
              </Text>
            </View>

            <View
              style={{
                color: "#D3D3D3",
                fontSize: "35px",
                fontWeight: 800,
                fontStyle: "Poppins",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  color: "#003566",
                  opacity: "0.4",
                  fontWeight: "bold",
                }}
              >
                {/* {copy} */}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "11px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <Text>Reference: {allData?.booking?.booking_id}</Text>
          </View>

          <View>
            <Text
              style={{
                fontSize: "12px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              PASSENGER
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableColName}>
                  <Text style={tableCell}>Passenger Name </Text>
                </View>
                <View style={tableColGender}>
                  <Text style={tableCell}>Gender</Text>
                </View>
                <View style={tableColType}>
                  <Text style={tableCell}>Passenger Type</Text>
                </View>
                <View style={tableColNumber}>
                  <Text style={tableCell}>
                    {copy === "e - Ticket"
                      ? "Ticket Number"
                      : "Passport Number"}
                  </Text>
                </View>
              </View>
              {copy === "e - Ticket" ? (
                <View>
                  {allData?.tickets &&
                    allData?.tickets?.map((data, index) => (
                      <View style={tableRow} key={index}>
                        <View style={tableColName}>
                          <Text style={tableCell}>
                            {data?.prefix} {data?.passenger_name?.toUpperCase()}
                          </Text>
                        </View>
                        <View style={tableColGender}>
                          <Text style={tableCell}>
                            {data?.gender?.toUpperCase()}
                          </Text>
                        </View>
                        <View style={tableColType}>
                          <Text style={tableCell}>
                            {data?.pax_type === "ADT"
                              ? "ADULT"
                              : data.pax_type === "INF"
                              ? "INFANT"
                              : "CHILD"}
                          </Text>
                        </View>
                        <View style={tableColNumber}>
                          <Text style={tableCell}>{data?.ticket_no}</Text>
                        </View>
                      </View>
                    ))}
                </View>
              ) : (
                <View>
                  {allData?.passengers?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColName}>
                        <Text style={tableCell}>
                          {data?.gender === "Male" ? (
                            <Text>
                              {data?.gender === "Male" &&
                              data?.type === "ADT" ? (
                                <Text>
                                  MR {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              ) : (
                                <Text>
                                  MSTR {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              )}
                            </Text>
                          ) : (
                            <Text>
                              {data?.gender === "Female" &&
                              data?.type === "ADT" ? (
                                <Text>
                                  MS {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              ) : (
                                <Text>
                                  MISS {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              )}
                            </Text>
                          )}
                        </Text>
                      </View>
                      <View style={tableColGender}>
                        <Text style={tableCell}>
                          {data?.gender?.toUpperCase()}
                        </Text>
                      </View>
                      <View style={tableColType}>
                        <Text style={tableCell}>
                          {data?.type === "ADT"
                            ? "ADULT"
                            : data.type === "INF"
                            ? "INFANT"
                            : "CHILD"}
                        </Text>
                      </View>
                      <View style={tableColNumber}>
                        <Text style={tableCell}>{data?.passport_no}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              )}
            </View>
          </View>

          <View>
            <Text
              style={{
                fontSize: "12px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              FLIGHT ITINERARIES
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableColflight}>
                  <Text style={tableCell}>Flight </Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Departure From</Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Arrival To</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Depart At</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Arrive At</Text>
                </View>
                <View style={tableColflight}>
                  <Text style={tableCell}>Info</Text>
                </View>
              </View>

              {allData?.booking.trip_type === "return" ? (
                <>
                  {allData?.segments.go?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.marketing_carier_name}
                        </Text>
                        <Text style={tableCell}>
                          {data?.operating_carier_code}
                          {" | "}
                          {data?.marketing_flight_number}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.departure_code}){data?.departure_airport}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.arrival_code}) {data?.arrival_airport}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.departure_time
                            ? format(
                                new Date(data?.departure_time?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.arrival_time
                            ? format(
                                new Date(data?.arrival_time?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.booking_code}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.flight_duration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {displayBaggageAllowances(
                            allData?.baggage_allowances
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
                  {allData?.segments.back?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.marketing_carier_name}
                        </Text>
                        <Text style={tableCell}>
                          {data?.operating_carier_code}
                          {" | "}
                          {data?.marketing_flight_number}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.departure_location}){data?.departure_airport}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.arrival_location}) {data?.arrival_airport}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.departure_time
                            ? format(
                                new Date(data?.departure_time?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.arrival_time
                            ? format(
                                new Date(data?.arrival_time?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.booking_code}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.flight_duration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {displayBaggageAllowances(
                            allData?.baggage_allowances
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
                </>
              ) : allData?.booking.trip_type === "oneway" ? (
                <>
                  {allData?.segments.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.marketing_carier_name}
                        </Text>
                        <Text style={tableCell}>
                          {data?.marketing_carier_code}
                          {" | "}
                          {data?.marketing_flight_number}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.departure_location}){data?.departure_airport}
                          {data?.departure_terminal}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.arrival_location}) {data?.arrival_airport}
                          {data?.arrival_terminal}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.departure_time
                            ? format(
                                new Date(data?.departure_time?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.arrival_time
                            ? format(
                                new Date(data?.arrival_time?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.booking_code}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.flight_duration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {displayBaggageAllowances(
                            allData?.baggage_allowances
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
                </>
              ) : (
                <>
                  {multiCityAllData
                    ? multiCityAllData?.map((data, index) => {
                        return (
                          <View style={tableRow} key={index}>
                            <View style={tableColflight}>
                              <Text style={tableCell}>
                                {data?.marketing_carier_name}
                              </Text>
                              <Text style={tableCell}>
                                {allData?.airlines_code}
                                {" | "}
                                {data?.marketing_carier_code}
                              </Text>
                            </View>
                            <View style={tableColfromto}>
                              <Text style={tableCell}>
                                ({data?.departure_location})
                                {data?.departure_airport}
                              </Text>
                            </View>
                            <View style={tableColfromto}>
                              <Text style={tableCell}>
                                ({data?.arrival_location}){" "}
                                {data?.arrival_airport}
                              </Text>
                            </View>
                            <View style={tableColtime}>
                              <Text style={tableCell}>
                                {data?.departure_time
                                  ? format(
                                      new Date(
                                        data?.departure_time?.toString()
                                      ),
                                      "dd MMM yyyy hh:mm a"
                                    )
                                  : ""}
                              </Text>
                            </View>
                            <View style={tableColtime}>
                              <Text style={tableCell}>
                                {data?.arrival_time
                                  ? format(
                                      new Date(data?.arrival_time.toString()),
                                      "dd MMM yyyy hh:mm a"
                                    )
                                  : ""}
                              </Text>
                            </View>
                            <View style={tableColflight}>
                              <Text style={tableCellInfo}>
                                Cabin: 7Kg, Class: {data?.booking_code}
                              </Text>
                              <Text style={tableCellInfo}>
                                Duration: {data?.flight_duration}
                              </Text>
                              <Text style={tableCellInfo}>
                                Baggage:{" "}
                                {displayBaggageAllowances(
                                  allData?.baggage_allowances
                                )}
                              </Text>
                            </View>
                          </View>
                        );
                      })
                    : null}
                </>
              )}
            </View>
          </View>
          {check === "1" ? (
            <View>
              <View>
                <Text
                  style={{
                    fontSize: "12px",
                    color: "#03437B",
                    padding: "20px 0 10px 0",
                  }}
                >
                  PRICE BREAKDOWN
                </Text>
                <View style={table}>
                  <View style={{ margin: "auto", flexDirection: "row" }}>
                    <View style={tableCol}>
                      <Text style={tableCell}>Passenger</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Base Fare</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Tax</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Total Fare</Text>
                    </View>
                  </View>
                  {allData?.booking.adult_count > 0 && (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          Adult X {allData?.adult_count}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.booking.adt_base)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.booking.adt_tax)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(adultTotalPrice)} BDT
                        </Text>
                      </View>
                    </View>
                  )}
                  {allData?.booking.child_count > 0 && (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          Child X {allData?.booking.child_count}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.booking.child_base)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.booking.child_tax)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(childTotalPrice)} BDT
                        </Text>
                      </View>
                    </View>
                  )}
                  {allData?.booking.infant_count > 0 && (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          Infant X {allData?.booking.infant_count}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.booking.inf_base)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.booking.inf_tax)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(infantTotalPrice)} BDT
                        </Text>
                      </View>
                    </View>
                  )}

                  <View style={tableRow}>
                    <View style={tableCol2}>
                      <Text
                        style={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          margin: "3px",
                        }}
                      >
                        Grand Total: {commaNumber(totalPrice)} BDT
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "70%",
                  fontSize: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "20px 0px",
                  border: "1px solid gray",
                  padding: "10px",
                }}
              >
                <View>
                  {copy === "Agent Invoice" ? (
                    <>
                      <View>
                        <Text style={{ marginBottom: "5px" }}>
                          Grand total amount
                        </Text>
                        <Text style={{ marginBottom: "5px" }}>Discount</Text>
                        <Text>
                          ---------------------------------------------------------------------------------------
                        </Text>

                        <Text>Agent Total Ticket Fare Amount</Text>
                      </View>
                    </>
                  ) : (
                    <View>
                      <Text style={{ marginBottom: "5px" }}>
                        Grand total amount
                      </Text>
                      <Text style={{ marginBottom: "5px" }}>Discount</Text>
                      <Text>
                        ---------------------------------------------------------------------------------------
                      </Text>

                      <Text>Customer Total Ticket Fare Amount</Text>
                    </View>
                  )}
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                  }}
                >
                  {copy === "Agent Invoice" ? (
                    <>
                      <>
                        <Text style={{ marginBottom: "5px" }}>
                          {commaNumber(totalPrice || "")} BDT
                        </Text>
                        <Text style={{ marginBottom: "5px" }}>
                          {commaNumber(
                            allData?.booking.gross_cost -
                              allData?.booking.net_cost
                          )}{" "}
                          BDT
                        </Text>
                        <Text>------------------</Text>

                        <Text>
                          {commaNumber(
                            parseInt(
                              totalPrice -
                                allData?.booking.gross_cost +
                                allData?.booking.net_cost
                            ) + parseInt("10")
                          )}{" "}
                          BDT
                        </Text>
                      </>
                    </>
                  ) : (
                    <>
                      <Text style={{ marginBottom: "5px" }}>
                        {commaNumber(totalPrice || "")} BDT
                      </Text>
                      <Text style={{ marginBottom: "5px" }}>
                        {commaNumber(
                          allData?.booking.gross_cost -
                            allData?.booking.net_cost
                        )}{" "}
                        BDT
                      </Text>
                      <Text>------------------</Text>

                      <Text>
                        {commaNumber(
                          parseInt(
                            totalPrice -
                              allData?.booking.gross_cost +
                              allData?.booking.net_cost
                          ) + parseInt("10")
                        )}{" "}
                        BDT
                      </Text>
                    </>
                  )}
                </View>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: "11px",
                    color: "#03437B",
                    padding: "10px 0px",
                  }}
                >
                  {/* In Words: {capitalize} Taka Only */}
                </Text>
              </View>
            </View>
          ) : (
            ""
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PDFPageDesign;
