import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import moment from "moment";
import React from "react";

const GroupFareCard = ({ bookingData, transit_time, }) => {
  const travelTimeToMinute = (time) => {
    const hourMatch = time.match(/\d+H/);
    const minuteMatch = time.match(/\d+M/);
    const hours = hourMatch ? parseInt(hourMatch[0]) : 0;
    const minutes = minuteMatch ? parseInt(minuteMatch[0]) : 0;
    const totalMinutes = parseFloat(hours * 60 + minutes);
    return totalMinutes;
  };

  const totalTravelTime = (time1, transite, time2) => {
    const segment1Min = travelTimeToMinute(time1);
    const transiteMin = travelTimeToMinute(transite);
    const segment2Min = travelTimeToMinute(time2);
    const totalTravelTime = `${parseInt(
      (segment1Min + transiteMin + segment2Min) / 60
    )}H ${(segment1Min + transiteMin + segment2Min) % 60}M`;

    return totalTravelTime;
  };

  return (
    <>
     

      <Grid item container sx={{ bgcolor: "blu" }}>
        <Box
          bgcolor={"white"}
          sx={{
            ".MuiTypography-body2": {
              fontSize: "13px",
            },
            ".MuiTypography-title-lg": {
              fontSize: "24px",
              color: "#444542",
              fontWeight: "500",
            },
            width: "100%",
            p: "25px",
            borderRadius: "7px",
          }}
        >
          <Grid
            justifyContent={"space-between"}
            container
            // sx={{ p: "15px 10px" }}
          >
            <Grid item order={{ xs: 1, lg: 1 }} xs={6} lg={3}>
              <Box sx={{ bgcolor: "blu" }}>
                <img
                  src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${bookingData?.carrier_code}.png`}
                  width="60px"
                  height="60px"
                  alt=""
                />
                <Typography variant="body2" sx={{ color: "#2156A6" }}>
                  {bookingData?.carrier_name}
                </Typography>
                <Typography variant="body2" sx={{ color: "#222" }}>
                  {bookingData?.carrier_code} - {bookingData?.flight_num}
                </Typography>

                <Typography
                  sx={{ fontSize: "12px", color: "#222", mt: "15px" }}
                >
                  {bookingData?.class || "Economy"}
                </Typography>
              </Box>
            </Grid>

            <Grid item order={{ xs: 3, lg: 2 }} xs={3} lg={3}>
              <Box>
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Depart
                </Typography>
                <Typography variant="title-lg">
                  {bookingData?.dept_code}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "var(--primary-color)" }}
                >
                  {bookingData?.dept_name}
                </Typography>
                <Typography variant="body2" sx={{ color: "#222" }}>
                  {moment(bookingData?.dept_time.slice(0, 19)).format(
                    "hh:mm A"
                  )}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                  {moment(bookingData?.dept_time).format("ddd D MMM YYYY")}
                </Typography>
              </Box>
            </Grid>

            <Grid item order={{ xs: 4, lg: 3 }} xs={3} lg={3}>
              <Stack
                direction="column"
                justifyContent="space-between"
                alignItems="center"
                height={"100%"}
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#ACA6A6",
                      textAlign: "ceter",
                      mr: "10px",
                    }}
                  >
                    {totalTravelTime(
                      bookingData?.travel_time,
                      transit_time,
                      bookingData?.travel_time
                    )}
                  </Typography>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="122"
                    height="12"
                    viewBox="0 0 122 12"
                    fill="none"
                  >
                    <path
                      d="M120.682 11C120.4 11 104.145 11 96.0529 11H0"
                      stroke="#2156A6"
                      strokeWidth="2"
                    />
                    <path
                      d="M96.9551 2L120.682 11L96.9551 10.8623V2Z"
                      fill="#2156A6"
                      stroke="#2156A6"
                      strokeWidth="2"
                    />
                  </svg>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#444542",
                      textAlign: "center",
                      mr: "10px",
                      mt: "5px",
                    }}
                  >
                    {bookingData?.segment === "1" ? "No Stop" : "1 Stopss"}
                  </Typography>
                </Stack>
                <Typography
                  variant="body2"
                  sx={{ color: "#2156A6", textAlign: "center" }}
                >
                  Baggage{" "}
                  {bookingData?.baggage > 3
                    ? `${bookingData?.baggage} KG`
                    : `${bookingData?.baggage} Bag${
                        bookingData?.baggage > 1 ? "s" : ""
                      }`}
                </Typography>{" "}
              </Stack>
            </Grid>

            <Grid item order={{ xs: 5, lg: 4 }} xs={3} lg={3}>
              <Box
                sx={{
                  bgcolor: "blu",
                }}
              >
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Arrival
                </Typography>
                <Typography variant="title-lg">
                  {bookingData?.arrive_code}
                </Typography>
                <Typography variant="body2" sx={{ color: "#2156A6" }}>
                  {bookingData?.arrive_name}
                </Typography>
                <Typography variant="body2" sx={{ color: "#222" }}>
                  {moment(bookingData?.arrive_time?.slice(0, 19)).format(
                    "hh:mm A"
                  )}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                  {moment(bookingData?.arrive_time).format("ddd D MMM YYYY")}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default GroupFareCard;
