import React, { useState, useEffect, useRef } from "react";
import { Container } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Slider from "@mui/material/Slider";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import WbTwilightOutlinedIcon from "@mui/icons-material/WbTwilightOutlined";
import { AiFillCaretDown } from "react-icons/ai";
import LocalFloristSharpIcon from "@mui/icons-material/LocalFloristSharp";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";

import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  SwipeableDrawer,
  Typography,
} from "@mui/material";

const HotelFilter = ({ dataFromDb, filteredData, setFilteredData }) => {

  let prices = [];
  const [selectedRating, setSelectedRating] = useState('');
  const [selectedRefundable, setSelectedRefundable] = useState('');
  const [selectedSystemn, setSelectedSystem] = useState('');
  const [seletedDistance, setSelectedDistance] = useState(null);
  const [selectedAmenities, setSelectedAmenities] = useState([]);
  let hotelPrice = dataFromDb;

  hotelPrice.map((data) => {
    prices.push(data?.priceInfo?.amountAfterTax);
    return prices;
  });

  const maxPrice = Math.max(...prices);
  const minPrice = Math.min(...prices);
  const [range, setRange] = useState([minPrice, maxPrice]);

  // Price range filter
  const handlePriceRange = (event, newValue) => {
    setRange(newValue);
    handleFilter();
  };

  const handleFilter = () => {
    let filteredData = dataFromDb;

    // Apply rating filter
    if (selectedRating) {
      filteredData = filteredData.filter((data) => data?.rating === selectedRating);
    }

    // Apply refundable filter
    if (selectedRefundable) {
      filteredData = filteredData.filter((data) => data?.refundable === selectedRefundable);
    }

    // Apply system filter
    if (selectedSystemn) {
      filteredData = filteredData.filter((data) => {
        switch (selectedSystemn) {
          case 'Sabre':
            return data?.priceInfo?.rateSource === '100';
          case 'Booking.com':
            return data?.priceInfo?.rateSource === '113';
          case 'Expedia':
            return data?.priceInfo?.rateSource === '112';
          case 'Bedsonline.com':
            return data?.priceInfo?.rateSource === '110';
          default:
            return true;
        }
      });
    }

    // Apply distance filter
    if (seletedDistance) {
      const distance = parseFloat(seletedDistance);
      if (!isNaN(distance)) {
        filteredData = filteredData.filter((data) => {
          switch (distance) {
            case 1.00:
              return data?.distance >= 0.00 && data?.distance <= 1.00;
            case 2.00:
              return data?.distance >= 1.00 && data?.distance <= 2.00;
            case 5.00:
              return data?.distance >= 2.00 && data?.distance <= 5.00;
            case 10.00:
              return data?.distance >= 5.00 && data?.distance <= 10.00;
            case 20.00:
              return data?.distance >= 10.00 && data?.distance <= 20.00;
            default:
              return true;
          }
        });
      }
    }

    // Apply price range filter
    filteredData = filteredData.filter(
      (hotel) =>
        hotel?.priceInfo?.amountAfterTax >= range[0] &&
        hotel?.priceInfo?.amountAfterTax <= range[1]
    );

    // Apply amenities filter
    if (selectedAmenities?.length > 0) {
      filteredData = filteredData.filter((hotel) =>
        selectedAmenities.every((code) =>
          hotel.amenities.some((amenity) => amenity.code === code)
        )
      );
    }

    setFilteredData(filteredData);
  };

  useEffect(() => {
    handleFilter();
  }, [selectedRating, selectedRefundable, selectedSystemn, seletedDistance, selectedAmenities, range]);

  // Use handleFilter in your existing functions
  const handleRatingChange = (event) => {
    const newRating = event.target.value;
    console.log(newRating);
    if (newRating === selectedRating) {
      setSelectedRating('');
    } else {
      setSelectedRating((prevSelectedRating) => (prevSelectedRating === newRating ? '' : newRating));
    }

    handleFilter();
  };


  const handleRefundable = (event) => {
    const newValue = event.target.value;
    setSelectedRefundable((prevValue) => (prevValue === newValue ? '' : newValue));
    handleFilter();
  };


  const handleSystem = (event) => {
    const newSystem = event.target.value;

    if (newSystem === selectedSystemn) {
      setSelectedSystem('');
    } else {
      setSelectedSystem((prevSelectedSystem) => (prevSelectedSystem === newSystem ? '' : newSystem));
    }

    handleFilter();
  };

  const handleDistance = (event) => {
    const newValue = parseFloat(event.target.value);
    setSelectedDistance((prevValue) => (prevValue === newValue ? null : newValue));
    handleFilter();
  };

  //////  distance filter  //////
  const handleResetBtn = () => {
    setRange([minPrice, maxPrice]);
    setSelectedRating('');
    setSelectedRefundable('');
    setSelectedSystem('');
    setSelectedDistance(null);
    setFilteredData(dataFromDb)
  }

  //////  cheapest filter  //////
  const handleLowestToHighest = () => {
    const lowestToHigh = dataFromDb.slice();
    lowestToHigh.sort((a, b) => a.priceInfo?.amountAfterTax - b.priceInfo?.amountAfterTax);
    setSelectedRating('');
    setSelectedRefundable('');
    setSelectedSystem('');
    setSelectedDistance(null);
    setFilteredData(lowestToHigh);
  };

  const handleHighestToLowest = () => {
    const highestToLow = dataFromDb.slice();
    highestToLow.sort((a, b) => b.priceInfo?.amountAfterTax - a.priceInfo?.amountAfterTax);
    setSelectedRating('');
    setSelectedRefundable('');
    setSelectedSystem('');
    setSelectedDistance(null);
    setFilteredData(highestToLow);
  };

  //////  amenities filter  //////

  const handleAmenityChange = (value) => {
    setSelectedAmenities((prevSelectedAmenities) => {
      if (prevSelectedAmenities.includes(value)) {
        return prevSelectedAmenities.filter((option) => option !== value);
      } else {
        return [...prevSelectedAmenities, value];
      }
    });
  };

  console.log(selectedRating);

  return (
    <>
      <Box sx={{ bgcolor: "#FBEA78", py: "3px", textAlign: "center", borderRadius: "3px", ml: '19px', mb: "10px" }}>
        <Typography sx={{ fontSize: "13px", color: "black", fontWeight: "500" }}>Showing {filteredData.length} Hotel Results</Typography>
      </Box>
      <Box
        sx={{
          pt: "12px",
          background: "var(--white)",
          borderRadius: "4px",
          px: "11px",
          ml: "20px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              color: "var(--primary-color)",
              fontSize: "14px",
              fontWeight: "500",
            }}
          >
            FILTER
          </Typography>

          <Box>
            <Typography
              onClick={handleResetBtn}
              sx={{
                cursor: "pointer",
                color: "#fff",
                backgroundColor: "var(--primary-color)",
                borderRadius: "4px",
                padding: "5px 10px",
                fontSize: "12px",
              }}
            >
              Reset
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "10px"
          }}
        >
          <Typography
            sx={{
              cursor: "pointer",
              color: "var(--mateblack)",
              fontWeight: "500",
              backgroundColor: "var(--secondary-color)",
              borderRadius: "4px",
              padding: "5px 10px",
              width: "100%",
              fontSize: "12px",
            }}
            onClick={() => handleLowestToHighest()}
          >
            Lowest to Highest (Price)
          </Typography>

        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "10px"
          }}
        >
          <Typography
            sx={{
              cursor: "pointer",
              color: "var(--mateblack)",
              fontWeight: "500",
              backgroundColor: "var(--secondary-color)",
              borderRadius: "4px",
              padding: "5px 10px",
              width: "100%",
              fontSize: "12px",
            }}
            onClick={() => handleHighestToLowest()}
          >
            Highest to Lowest (Price)
          </Typography>

        </Box>

        <Box
          sx={{
            ".MuiAccordionSummary-root": {
              m: "5px 0px",
              borderRadius: "4px",
              padding: "0px 10px",
              minHeight: "30px",
            },
            ".MuiAccordionDetails-root": {
              padding: "5px 10px",
            },
            ".MuiAccordion-root.Mui-expanded": {
              m: "5px 0px",
            },
            ".MuiAccordionSummary-content.Mui-expanded": {
              m: "5px 0px",
            },
            ".accor-title": {
              color: "var(--primary-color)",
              fontSize: "14px",
              fontWeight: "500",
            },
            ".MuiSlider-thumb": {
              height: "15px",
              width: "15px",
            },
            span: {
              fontSize: "12px",
            },
            ".MuiCheckbox-root": {
              padding: "0px 5px",
            },
          }}
        >
          {/* //todo: price range section */}
          <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
            <AccordionSummary expandIcon={<AiFillCaretDown color="#003566" />}>
              <Typography
                className="accor-title"
                style={{ color: "var(--primary-color)" }}
              >
                Price Range
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <Slider
                  value={range}
                  onChange={handlePriceRange}
                  valueLabelDisplay="auto"
                  aria-labelledby="range-slider"
                  min={minPrice}
                  max={maxPrice}
                />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    ৳ {range[0]}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#000",
                      fontWeight: "500",
                      fontSize: "12px",
                    }}
                  >
                    ৳ {range[1]}
                  </Typography>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* //todo: sort by Rating */}
          <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<AiFillCaretDown color="#003566" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="accor-title">Star Rating</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    value="5.0"
                    control={<Checkbox className="box-0" />}
                    label="⭐⭐⭐⭐⭐"
                    checked={selectedRating === '5.0'}
                    onChange={handleRatingChange}
                  />
                  <FormControlLabel
                    value="4.0"
                    control={<Checkbox className="box-0" />}
                    label="⭐⭐⭐⭐"
                    checked={selectedRating === '4.0'}
                    onChange={handleRatingChange}
                  />
                  <FormControlLabel
                    value="3.0"
                    control={<Checkbox className="box-0" />}
                    label="⭐⭐⭐"
                    checked={selectedRating === '3.0'}
                    onChange={handleRatingChange}
                  />
                  <FormControlLabel
                    value="2.0"
                    control={<Checkbox className="box-0" />}
                    label="⭐⭐"
                    checked={selectedRating === '2.0'}
                    onChange={handleRatingChange}
                  />
                  <FormControlLabel
                    value="1.0"
                    control={<Checkbox className="box-0" />}
                    label="⭐"
                    checked={selectedRating === '1.0'}
                    onChange={handleRatingChange}
                  />
                  <FormControlLabel
                    value="NA"
                    control={<Checkbox className="box-0" />}
                    label="N/A"
                    checked={selectedRating === 'NA'}
                    onChange={handleRatingChange}
                  />
                </FormGroup>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* //todo: sort by payment and booking */}
          <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<AiFillCaretDown color="#003566" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="accor-title">Fare Type</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    value={"Refundable"}
                    control={<Checkbox className="box-0" />}
                    checked={selectedRefundable === 'Refundable'}
                    onChange={handleRefundable}
                    label={<span style={{ fontSize: "12px" }}>Refundable</span>}
                  />
                  <FormControlLabel
                    value={"Non Refundable"}
                    control={<Checkbox className="box-0" />}
                    checked={selectedRefundable === 'Non Refundable'}
                    onChange={handleRefundable}
                    label={<span style={{ fontSize: "12px" }}>Non Refundable</span>}
                  />
                </FormGroup>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* //todo: sort by Distance */}
          <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
            <AccordionSummary expandIcon={<AiFillCaretDown color="#003566" />}>
              <Typography className="accor-title">Distance</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    value={1.00}
                    control={<Checkbox className="box-0" />}
                    checked={seletedDistance > 0 && seletedDistance <= 1.00}
                    onChange={handleDistance}
                    label={<span style={{ fontSize: "12px" }}>0.00 To 1.00 Mile</span>}
                  />
                  <FormControlLabel
                    value={2.00}
                    control={<Checkbox className="box-0" />}
                    checked={seletedDistance > 1.00 && seletedDistance <= 2.00}
                    onChange={handleDistance}
                    label={<span style={{ fontSize: "12px" }}>1.00 To 2.00 Mile</span>}
                  />
                  <FormControlLabel
                    value={5.00}
                    control={<Checkbox className="box-0" />}
                    checked={seletedDistance > 2.00 && seletedDistance <= 5.00}
                    onChange={handleDistance}
                    label={<span style={{ fontSize: "12px" }}>2.00 To 5.00 Mile</span>}
                  />
                  <FormControlLabel
                    value={10.00}
                    control={<Checkbox className="box-0" />}
                    checked={seletedDistance > 5.00 && seletedDistance <= 10.00}
                    onChange={handleDistance}
                    label={<span style={{ fontSize: "12px" }}>5.00 To 10.00 Mile</span>}
                  />
                  <FormControlLabel
                    value={20.00}
                    control={<Checkbox className="box-0" />}
                    checked={seletedDistance > 10.00 && seletedDistance <= 20.00}
                    onChange={handleDistance}
                    label={<span style={{ fontSize: "12px" }}>10.00 To 20.00 Mile</span>}
                  />
                </FormGroup>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* //todo: sort by Review Rating */}
          <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<AiFillCaretDown color="#003566" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="accor-title">System</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    value={"Booking.com"}
                    control={<Checkbox className="box-0" />}
                    checked={selectedSystemn === 'Booking.com'}
                    onChange={handleSystem}
                    label={<span style={{ fontSize: "12px" }}>Booking.com</span>}
                  />
                  <FormControlLabel
                    value={"Sabre"}
                    control={<Checkbox className="box-0" />}
                    checked={selectedSystemn === 'Sabre'}
                    onChange={handleSystem}
                    label={<span style={{ fontSize: "12px" }}>Sabre</span>}
                  />
                  <FormControlLabel
                    value={"Expedia"}
                    control={<Checkbox className="box-0" />}
                    checked={selectedSystemn === 'Expedia'}
                    onChange={handleSystem}
                    label={<span style={{ fontSize: "12px" }}>Expedia</span>}
                  />
                  <FormControlLabel
                    value={"Bedsonline.com"}
                    control={<Checkbox className="box-0" />}
                    checked={selectedSystemn === 'Bedsonline.com'}
                    onChange={handleSystem}
                    label={<span style={{ fontSize: "12px" }}>Bedsonline.com</span>}
                  />
                </FormGroup>
              </Box>
            </AccordionDetails>
          </Accordion>
          {/* //todo: sort by Facilities and Service(Hotel) */}
          <Accordion defaultExpanded={true} sx={{ boxShadow: "none" }}>
            <AccordionSummary
              expandIcon={<AiFillCaretDown color="#003566" />}
              aria-controls="panel2a-content"
              id="panel2a-header"
            >
              <Typography className="accor-title">Facilities</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ width: "100%" }}>
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(68)} onChange={() => handleAmenityChange(68)} />}
                    label="Parking"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(76)} onChange={() => handleAmenityChange(76)} />}
                    label="Restaurant"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(77)} onChange={() => handleAmenityChange(77)} />}
                    label="Room Service"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(48)} onChange={() => handleAmenityChange(48)} />}
                    label="Gym"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(2005)} onChange={() => handleAmenityChange(2005)} />}
                    label="Bar"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(84)} onChange={() => handleAmenityChange(84)} />}
                    label="Spa"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(269)} onChange={() => handleAmenityChange(269)} />}
                    label="Conference Room"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(2002)} onChange={() => handleAmenityChange(2002)} />}
                    label="Stay Safe"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(71)} onChange={() => handleAmenityChange(71)} />}
                    label="Pool"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(179)} onChange={() => handleAmenityChange(179)} />}
                    label="Wi-Fi"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(101)} onChange={() => handleAmenityChange(101)} />}
                    label="Wheelchair Access"
                  />
                  <FormControlLabel
                    control={<Checkbox checked={selectedAmenities.includes(2008)} onChange={() => handleAmenityChange(2008)} />}
                    label="Smoking Room"
                  />
                </FormGroup>
              </Box>
            </AccordionDetails>
            {/* //todo: sort by Facilities and Service(Room) */}
          </Accordion>
        </Box>
      </Box>
    </>

  );
};

export default HotelFilter;
