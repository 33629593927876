import React from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import secureLocalStorage from "react-secure-storage";
import companyInfo from "./../../../companyInfo";

const PdfVisa = ({ checkList, optionValue, tableValue, companyInfo }) => {
  const table = {
    display: "table",
    width: "100%",
  };
  const tableRow = {
    margin: "auto",
    flexDirection: "row",
    borderLeft: "1px solid #DEDEDE",
  };

  const tableCol = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  //Passenger Details
  const tableHead = {
    width: "12.5%",
    padding: "2px",
    backgroundColor: "#D1E9FF",
    color: "#003566",
  };

  const tableData = {
    width: "12.5%",
    border: "1px solid #DEDEDE",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableCell = {
    margin: "3px",
    fontSize: "7px",
  };
  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "8px",
  };

  const adress = {
    fontSize: "10px",
    color: "#8b8b8b",
  };

  const users = secureLocalStorage.getItem("user-info");

  // let agentID = siteConfig?.agentId;

  const filterData = checkList?.filter((item) =>
    item?.passengertype.includes(optionValue)
  );

  return (
    <Document>
      <Page size="A4" style={{ padding: "27px 20px" }}>
        <View style={{ padding: "15px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <View
                style={{
                  fontSize: "10px",
                  width: "180px",
                }}
              >
                <Text
                  style={{
                    fontSize: "16px",
                    fontWeight: "900",
                    paddingBottom: "10px",
                  }}
                >
                  {companyInfo?.name}
                </Text>
                <Text style={adress}>{companyInfo.address}</Text>
                <Text style={adress}>Email: {companyInfo.email}</Text>
                <Text style={adress}>Phone: {companyInfo.phone}</Text>
              </View>
            </View>

            {/* <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {siteConfig?.companyImage === "" ? null : (
                <Image
                  style={{ height: "50px" }}
                  src={`https://cdn.flyfarint.com/WL/B2C/${agentID}/${
                    siteConfig?.companyImage
                  }?t=${new Date().getTime()}`}
                />
              )}
            </View> */}
          </View>

          <View>
            <Text
              style={{
                fontSize: "10px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              Duration and Cost Details
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableHead}>
                  <Text style={tableCell}>Entry </Text>
                </View>
                <View style={tableHead}>
                  <Text style={tableCell}>Duration</Text>
                </View>
                <View style={tableHead}>
                  <Text style={tableCell}>Maximum Stay</Text>
                </View>
                <View style={tableHead}>
                  <Text style={tableCell}>Processing Time</Text>
                </View>
                <View style={tableHead}>
                  <Text style={tableCell}>Interview</Text>
                </View>
                <View style={tableHead}>
                  <Text style={tableCell}>Embassy Fee</Text>
                </View>
                <View style={tableHead}>
                  <Text style={tableCell}>Service Fee</Text>
                </View>
                <View style={tableHead}>
                  <Text style={tableCell}>Cost</Text>
                </View>
              </View>
              {tableValue?.map((data, index) => (
                <View style={tableRow} key={index}>
                  <View style={tableData}>
                    <Text style={tableCell}>{data?.entryType || ""}</Text>
                  </View>
                  <View style={tableData}>
                    <Text style={tableCell}>{data?.duration || ""}</Text>
                  </View>
                  <View style={tableData}>
                    <Text style={tableCell}>{data?.maximumStay || ""}</Text>
                  </View>
                  <View style={tableData}>
                    <Text style={tableCell}>{data?.processingTime || ""}</Text>
                  </View>
                  <View style={tableData}>
                    <Text style={tableCell}>{data?.interview || ""}</Text>
                  </View>
                  <View style={tableData}>
                    <Text style={tableCell}>{data?.embassyFee || ""}</Text>
                  </View>
                  <View style={tableData}>
                    <Text style={tableCell}>
                      {data?.RTCServiceCharge || ""}
                    </Text>
                  </View>
                  <View style={tableData}>
                    <Text style={tableCell}>
                      {parseInt(data?.total)}
                      &nbsp;BDT
                    </Text>
                  </View>
                </View>
              ))}
            </View>
          </View>

          <View>
            <View
              style={{
                fontSize: "10px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              <Text>Visa Checklist For {optionValue}</Text>
            </View>
          </View>

          {filterData?.map((check, index) => (
            <View key={index}>
              <Text
                style={{
                  fontSize: "10px",
                  color: "#000",
                  padding: "4px 0px",
                }}
              >
                {index + 1}.&nbsp;
                {check?.checkList || null}
              </Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};

export default PdfVisa;
