import React, { useEffect, useState } from "react";
import { Box, ClickAwayListener, Grid, Typography } from "@mui/material";
import StarRatings from "react-star-ratings";
import img1 from "../../images/SliderImg/blog2.jpg";
import img2 from "../../images/SliderImg/blog3.jpg";
import img3 from "../../images/SliderImg/image1.jpg";
import img4 from "../../images/SliderImg/image2.jpg";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PoolIcon from "@mui/icons-material/Pool";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import HotelAvailability from "./HotelAvaiability";
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { useLocation } from "react-router-dom";
import moment from "moment";
import { Triangle } from "react-loader-spinner";
import Amenity from "./Amenity";

const HotelDetails = () => {
  const location = useLocation();
  const { hcode, priceInfo, searchInfo, hotelSearchData, hotelData } = location?.state;
  const [hotelDetails, setHotelDetails] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const [checkOutDate, setCheckOutDate] = useState(new Date());
  const [totalGuest, setTotalGuest] = useState(1);
  const [adultCount, setAdultCount] = useState(1);
  const [childCount, setChildCount] = useState(0);
  const [room, setRoom] = useState(1);
  const [openGuests, setOpenGuests] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    const url = `https://api.rtc.flyfarint.com/v1.0/b2c/hotel/details.php?code=${hcode}&location=${hotelSearchData?.destinationCode?.toUpperCase()}&checkin=${moment(
      hotelSearchData?.checkInDate
    ).format("YYYY-MM-DD")}&checkout=${moment(hotelSearchData?.checkOutDate).format(
      "YYYY-MM-DD"
    )}&adult=${hotelSearchData?.adultCount}&child=${hotelSearchData?.childCount}&rooms=1`;
    console.log(url);
    fetch(url)
      .then((res) => res.json())
      .then((data) => setHotelDetails(data))
      .finally(() => {
        setIsLoading(false);
      });
  }, [hcode]);



  return (
    <>
      {
        !isLoading ?

          <Box sx={{ background: "var(--body-color)" }}>
            <UserHeader />
            <Box
              className="flightSearchParent"
              mt="-8vh"
              borderRadius="3px"
              px="25px"
              sx={{
                margin: "0 auto",
                bgcolor: "white",
                width: "90%",
              }}>

              <Box
                sx={{
                  backgroundColor: "var(--white)",
                  display: "flex",
                  gap: "8px",
                  p: 2,
                  mt: 1,
                  borderRadius: "5px",
                }}>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}>
                  Hotels in {hotelDetails?.locationInfo?.value}
                </Typography>
                <Typography sx={{ color: "#686767", fontSize: "14px" }}>
                  {`${moment(priceInfo?.startDate).format(
                    "DD MMM YY (ddd)"
                  )} - ${moment(priceInfo?.endDate).format("DD MMM YY (ddd)")}`}
                </Typography>
                <Typography
                  sx={{
                    color: "var(--mateBlack)",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}>
                  {hotelDetails?.hotelInfo?.hotelName}
                </Typography>
              </Box>

              <Grid container sx={{ backgroundColor: "var(--white)", my: 1, p: 2 }}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    py: 0.5,
                  }}>
                  {" "}
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "20px",
                        fontWeight: 500,
                        color: "var(--mateblack)",
                      }}>
                      {hotelDetails?.hotelInfo?.hotelName}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        fontWeight: 500,
                        color: "var(--primary-color)",
                      }}>
                      {`${hotelDetails?.locationInfo?.addressLine1},${hotelDetails?.locationInfo?.value}, ${hotelDetails?.locationInfo?.countryName}`}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}>

                    <Box
                      sx={{
                        background: "var(--green)",
                        p: 2,
                        clipPath:
                          "polygon(25% 25%, 75% 25%, 75% 75%, 50% 66.75%, 25% 75%)",
                      }}>
                      <Typography
                        sx={{
                          color: "white",
                          fontSize: "12px",
                          width: "20px",
                          height: "30px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}>
                        {hotelDetails?.hotelInfo?.sabreRating || 0}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        py: "7px",
                        px: "20px",
                        background: "var(--primary-color)",
                        color: "var(--white)",
                        fontSize: "12px",
                        fontWeight: "500"
                      }}>
                      {
                        hotelDetails?.roomSet?.length ? `Total ${hotelDetails?.roomSet?.length} Available Rooms` : `No Room Available`
                      }
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6} md={12} sx={{ display: "flex" }}>
                  {/* first image section */}
                  <Box sx={{ width: "30%", height: "300px" }}>
                    {" "}
                    <img
                      height="100%"
                      width="100%"
                      src={hotelData?.imageInfo?.imageUrl}
                      style={{ borderRadius: "5px", objectFit: "cover" }}
                    />
                  </Box>
                  {/* second image section */}
                  <Box sx={{ width: "30%", height: "300px", ml: "10px" }}>
                    {" "}
                    <img
                      height="100%"
                      width="100%"
                      src={hotelData?.imageInfo?.imageUrl}
                      style={{ borderRadius: "5px", objectFit: "cover" }}
                    />
                  </Box>
                  <Box sx={{ width: "20%", height: "300px", ml: "10px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    {" "}
                    <img
                      height="48.5%"
                      width="100%"
                      src={hotelData?.imageInfo?.imageUrl}
                      style={{ borderRadius: "5px", objectFit: "cover" }}
                    />
                    <img
                      height="48.5%"
                      width="100%"
                      src={hotelData?.imageInfo?.imageUrl}
                      style={{ borderRadius: "5px", objectFit: "cover" }}
                    />
                  </Box>
                  <Box sx={{ width: "20%", height: "300px", ml: "10px", display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    {" "}
                    <img
                      height="48.5%"
                      width="100%"
                      src={hotelData?.imageInfo?.imageUrl}
                      style={{ borderRadius: "5px", objectFit: "cover" }}
                    />
                    <img
                      height="48.5%"
                      width="100%"
                      src={hotelData?.imageInfo?.imageUrl}
                      style={{ borderRadius: "5px", objectFit: "cover" }}
                    />
                  </Box>
                </Grid>

                <Grid item md={11} sx={{ mt: "20px" }}>
                  <Typography
                    sx={{ fontSize: "20px", color: "var(--primary-color)", fontWeight: "500" }}>
                    Description
                  </Typography>
                  {hotelDetails?.shortDescription?.map((item, index) => (
                    <Box key={index}>
                      <Typography
                        sx={{
                          color: "var(--black)",
                          fontSize: "16px",
                          fontWeight: "500",
                        }}>
                        <Typography
                          variant="span"
                          sx={{
                            fontSize: "14px",
                            color: "var(--black)",
                            fontWeight: "400",
                            mt: "10px",
                          }}>
                          {item?.value}
                        </Typography>
                      </Typography>
                    </Box>
                  ))}
                </Grid>
                <Grid item md={11} sx={{ mt: "30px" }}>
                  <Typography
                    sx={{ fontSize: "20px", color: "var(--primary-color)", fontWeight: "500" }}>
                    Most popular facilities
                  </Typography>

                  <Box sx={{ display: "flex", gap: "25px", mt: "15px", flexWrap: "wrap" }}>
                    {hotelDetails?.amenities?.find((item) => item?.code === 331) ? (
                      <Typography>
                        <Amenity
                          icon={<SmokeFreeIcon sx={{ color: "#148F77" }} />}
                          text={"Smoke Free"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find((item) => item?.code === 179) ? (
                      <Typography>
                        <Amenity
                          icon={<NetworkWifiIcon sx={{ color: "#148F77" }} />}
                          text={"Free WiFi"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find((item) => item?.code === 76) ? (
                      <Typography>
                        <Amenity
                          icon={<RestaurantMenuIcon sx={{ color: "#148F77 " }} />}
                          text={"Restaurant"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find((item) => item?.code === 71) ? (
                      <Typography>
                        <Amenity
                          icon={<PoolIcon sx={{ color: "#148F77 " }} />}
                          text={"Pool"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find(
                      (item) => item?.code === 2002
                    ) ? (
                      <Typography>
                        <Amenity
                          icon={<HealthAndSafetyIcon sx={{ color: "#148F77 " }} />}
                          text={"Room Service"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find(
                      (item) => item?.code === 101
                    ) ? (
                      <Typography>
                        <Amenity
                          icon={<WheelchairPickupIcon sx={{ color: "#148F77 " }} />}
                          text={"Wheelchair Access"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find(
                      (item) => item?.code === 42
                    ) ? (
                      <Typography>
                        <Amenity
                          icon={<LocalParkingIcon sx={{ color: "#148F77 " }} />}
                          text={"Free Parking"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find(
                      (item) => item?.code === 42
                    ) ? (
                      <Typography>
                        <Amenity
                          icon={<CarRentalIcon sx={{ color: "#148F77 " }} />}
                          text={"Car Rental"}
                        />
                      </Typography>
                    ) : null}
                    {hotelDetails?.amenities?.find(
                      (item) => item?.code === 42
                    ) ? (
                      <Typography>
                        <Amenity
                          icon={<SmokeFreeIcon sx={{ color: "#148F77 " }} />}
                          text={"Non Smoking Rooms"}
                        />
                      </Typography>
                    ) : null}
                  </Box>
                </Grid>

                <Grid item md={12} sx={{ mt: "30px" }}>
                  <HotelAvailability
                    hotelDetails={hotelDetails}
                    searchInfo={searchInfo}
                    hotelData={hotelData}
                    adultCount={adultCount}
                    childCount={childCount}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{
                  backgroundColor: "var(--white)",
                  mt: 3,
                  p: 4,
                  borderRadius: "5px",
                }}>
                <Typography sx={{ fontSize: "20px", color: "var(--primary-color)", fontWeight: "500" }}>What other amenities are there?</Typography>
                <Box
                  sx={{ display: "flex", mt: 2, justifyContent: "space-between" }}>
                  <Box>
                    <Typography sx={{ textAlign: "start" }}>General</Typography>
                    <ul>
                      <li>Garden</li>
                      <li> Accessible Bathroom</li>
                      <li>Free Shopping center</li>
                      <li>Terrace</li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ textAlign: "start" }}>Room</Typography>
                    <ul>
                      <li>Garden</li>
                      <li> Accessible Bathroom</li>
                      <li>Free Shopping center</li>
                      <li>Terrace</li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ textAlign: "start" }}>Internet</Typography>
                    <ul>
                      <li>Garden</li>
                      <li> Accessible Bathroom</li>
                      <li>Free Shopping center</li>
                      <li>Terrace</li>
                    </ul>
                  </Box>
                  <Box>
                    <Typography sx={{ textAlign: "start" }}>Parking</Typography>
                    <ul>
                      <li>Garden</li>
                      <li> Accessible Bathroom</li>
                      <li>Free Shopping center</li>
                      <li>Terrace</li>
                    </ul>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          : <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: { xs: "80px" },
              marginInline: "auto",
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#2156a6"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </Box>
      }


    </>
  );
};

export default HotelDetails;
