import { Box, Typography } from "@mui/material";
import React from "react";

const Amenity = ({ icon, text }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
      {icon}
      <Typography sx={{ color: "#2C2321", fontSize: "14px" }}>
        {text}
      </Typography>
    </Box>
  );
};

export default Amenity;
