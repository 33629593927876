import { Box, Grid, Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import moment from "moment";
import React from "react";

const FlightCard = ({
  segment,
  index,
  segmentData,
  roundData,
  segmentPropName,
}) => {
  const {
    marketingcareer,
    marketingcareerName,
    operatingcareer,
    operatingflight,
    bookingcode,
    departure,
    departureLocation,
    departureAirport,
    departureTime,
    flightduration,
    arrival,
    arrivalLocation,
    arrivalTime,
    arrivalAirport,
  } = segment;
  const transiteTimeDuration = (date1, date2) => {
    const duration = moment.duration(moment(date1).diff(moment(date2)));
    const hours = parseFloat(Math.floor(duration.asHours()));
    const minutes = parseFloat(Math.floor(duration.asMinutes()) - hours * 60);
    const transit = `${Math.abs(hours)}H : ${Math.abs(minutes)}Min`;
    return transit;
  };

  const calculatteTransiteTime = (arrivalTime, departureTime, index) => {
    const segmentTwoDeparture = new Date(departureTime?.slice(0, 19));
    const segmentOneArrival = new Date(arrivalTime?.slice(0, 19));

    // Calculate the time difference in milliseconds
    const timeDifferenceMs = segmentTwoDeparture - segmentOneArrival;

    // Convert the time difference to hours and minutes
    const hours = Math.floor(timeDifferenceMs / (60 * 60 * 1000));
    const minutes = Math.floor(
      (timeDifferenceMs % (60 * 60 * 1000)) / (60 * 1000)
    );

    return `${hours}H ${minutes}Min`;
  };

  return (
    <>
      <Grid container>
        <Grid item lg={3}>
          <Box>
            <img
              style={{ width: "50px", height: "50px" }}
              src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${marketingcareer}.png`}
              alt=""
            />
            <Typography
              variant="body2"
              sx={{ color: "var(--secondary-color)", fontWeight: 500 }}
            >
              {marketingcareerName}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#444542", fontWeight: 500 }}
            >
              {operatingcareer} {operatingflight}
            </Typography>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Operated by: {operatingcareer}
            </Typography>

            <Typography variant="body2" sx={{ color: "#444542" }}>
              Economy: {bookingcode}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3}>
          <Box>
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Depart
            </Typography>
            <Typography variant="title-lg">{departure}</Typography>
            <Typography
              variant="body2"
              sx={{ color: "var(--secondary-color)", fontWeight: 500 }}
            >
              <span style={{ color: "#444542", fontSize: "13px" }}>
                {departureLocation}
              </span>{" "}
              | {moment(departureTime).format("hh:mm a")}
              {/* moment(date).format("DD MMM YY hh:mm a) */}
            </Typography>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Terminal: {departureAirport}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {moment(departureTime).format("dddd, Do MMMM YYYY")}
            </Typography>
          </Box>
        </Grid>

        <Grid item lg={3}>
          <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Typography variant="body2" sx={{ color: "#ACA6A6" }}>
              {flightduration}
            </Typography>
            <svg
              width="125"
              height="12"
              viewBox="0 0 689 56"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                stroke="#ED1A5C"
                stroke-width="9"
              />
              <path d="M689 46.6569L541.002 0V46.6569H689Z" fill="#ED1A5C" />
            </svg>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Non Stop
            </Typography>
          </Stack>
        </Grid>

        <Grid item lg={3}>
          <Box>
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Arivals
            </Typography>
            <Typography variant="title-lg">{arrival}</Typography>
            <Typography
              variant="body2"
              sx={{ color: "var(--secondary-color)", fontWeight: 500 }}
            >
              <span style={{ color: "#444542", fontSize: "13px" }}>
                {arrivalLocation}
              </span>{" "}
              | {moment(arrivalTime).format("hh:mm a")}
            </Typography>
            <Typography variant="body2" sx={{ color: "#444542" }}>
              Terminal: {arrivalAirport}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {moment(arrivalTime).format("dddd, Do MMMM YYYY")}
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Typography
        sx={{
          display: `${index === segmentData?.length - 1 ? "none" : "block"}`,
          borderRadius: "4px",
          background: "#DEE8FF",
          width: "65%",
          color: "var(--primary-color)",
          padding: "3px 10px",
          fontSize: "10px",
          fontWeight: 500,
          margin: "20px auto",
          textAlign: "center",
        }}
      >
        Change planes at{" "}
        {moment(segmentData[index + 1]?.departureTime).format("hh:mm a")}{" "}
        {arrivalAirport} | {arrivalLocation} | Connecting Time:{" "}
        {transiteTimeDuration(
          segmentData[index + 1]?.departureTime,
          segmentData[index]?.arrivalTime
        )}
      </Typography>
    </>
  );
};

export default FlightCard;
