import {
  Box,
  ClickAwayListener,
  Grid,
  Paper,
  Typography,
  Button,
  Tooltip,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { addDays, format } from "date-fns";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import flightData from "../flightData";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import moment from "moment";

import InitialData from "../../InitialFlightData";
import Popup from "../Header/Popup";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto var(--secondary-color)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "radial-gradient(var(--white),var(--white) 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

const Multicity = ({
  tripType,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSearchText,
  setFromSearchText,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  setValue,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
  from,
  setFrom,
  to,
  setTo,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  setMultiSearchData,
  multiSearchData,
  click,
  setClick,
}) => {
  const navigate = useNavigate();
  const [data2, setData2] = useState([]);

  const [searchKeyword, setSearchKeyword] = useState("");

  useEffect(() => {
    const url = `https://api.flyfarint.com/v.1.0.0/AirMaterials/airports.php?keyword=${searchKeyword}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setData2(data);
      });
  }, [searchKeyword]);
  // todo: multiCity search Body
  const addCity = () => {
    const tempSearchData = [...multiSearchData.segments];
    const prevDate = new Date(tempSearchData[tempSearchData.length - 1]?.Date);
    const newDate = new Date(prevDate);
    newDate.setDate(prevDate.getDate() + 1);

    tempSearchData.push({
      id: tempSearchData.length,
      openFrom: false,
      depFrom: tempSearchData[tempSearchData.length - 1].arrTo,
      depAddress: tempSearchData[tempSearchData.length - 1].arrAddress,
      depFromText: tempSearchData[tempSearchData.length - 1].arrToText,
      arrTo: "DXB",
      arrAddress: "Dubai",
      arrToText: "Dubai Intl Airport (DXB)",
      openTo: false,
      Date: newDate.toLocaleDateString("sv"),
      selectedDate: new Date(newDate),
      openDate: false,
      open: false,
    });
    setMultiSearchData({
      ...multiSearchData,
      segments: tempSearchData,
      CityCount: tempSearchData.length,
    });
  };

  const removeCity = (id) => {
    const tempSearchData = multiSearchData.segments.filter(
      (item) => item.id !== id
    );
    setMultiSearchData({
      ...multiSearchData,
      segments: tempSearchData,
      CityCount: tempSearchData.length,
    });
  };
  // todo: end multiCity search Body

  //todo: users section
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.agentId;

  // todo: end of users section
  //todo: is Click state

  //todo: end of click state
  const [fromSuggest, setFromSuggest] = useState(InitialData);
  const [toSuggest, setToSuggest] = useState(InitialData);

  const [open, setOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() + 1);

  // Opens the dialog when the user clicks.
  const handleClickOpen = (index, segment) => {
    const tempSearchData = [...multiSearchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: !segment.open,
    };
    setMultiSearchData({ ...multiSearchData, segments: tempSearchData });
    setOpen((prev) => !prev);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
  };

  // Closes the child process.
  const handleClose = (index) => {
    const tempSearchData = [...multiSearchData.segments];
    tempSearchData[index] = {
      ...tempSearchData[index],
      openTo: false,
      openDate: false,
      open: false,
    };
    setMultiSearchData({ ...multiSearchData, segments: tempSearchData });
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setResult(adultCount + childCount + infant);
  };

  // Sets the number of children.
  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
      setMultiSearchData({ ...multiSearchData, adultCount: adultCount + 1 });
    }
  }

  // Decrement the count of children.
  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      setMultiSearchData({ ...multiSearchData, adultCount: adultCount - 1 });
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
          setMultiSearchData({ ...multiSearchData, infant: infant - 1 });
        }
      }
    }
  }

  function childInclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
      setMultiSearchData({ ...multiSearchData, childCount: childCount + 1 });
    }
  }

  function childDecrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
      setMultiSearchData({ ...multiSearchData, childCount: childCount - 1 });
    }
  }

  // Increment the default value if the value is not a child.
  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
        setMultiSearchData({ ...multiSearchData, infantCount: infant + 1 });
      }
    }
  }

  // Decrement the infant by 1.
  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
      setMultiSearchData({ ...multiSearchData, infantCount: infant - 1 });
    }
  }

  const formOnChange = (e) => {
    setOpen(false);
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = data2.filter(
        (item) =>
          item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
          item.countryName.toLowerCase().includes(searchvalue.toLowerCase()) ||
          item.cityName.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
    } else {
      setFromSuggest(InitialData);
    }
  };

  const fromGetSuggetion = (index) => {
    const fromSuggestedText = (name, code, address) => {
      console.log(address);
      const tempSearchData = [...multiSearchData.segments];
      tempSearchData[index] = {
        ...tempSearchData[index],
        depFrom: code.trim(),
        depFromText: `${name} (${code.trim()})`,
        depAddress: address,
        openFrom: false,
        openTo: true,
      };
      setMultiSearchData({ ...multiSearchData, segments: tempSearchData });
      setFromSendData(code);
      setFromSearchText(`${name} (${code})`);
      setFromSuggest([]);
      setfaddress(address);
      setOpen(false);
      setOpenFrom(false);
      setOpenTo(true);
      setData2(InitialData);
    };

    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          className="box-index-oneway"
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "var(--white)",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {data2.length !== 0 ? (
            data2.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "#fff",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "#D1E9FF",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.cityName}, ${item.countryName}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#003566",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.cityName}, {item.countryName}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    if (searchvalue.length > 2) {
      const suggestion = data2.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setToSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data2.filter(
          (item) =>
            item?.code?.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item?.address?.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setToSuggest(suggestion);
      }
    } else {
      setToSuggest(InitialData);
    }
  };

  const toGetSuggetion = (index) => {
    const toSuggestedText = (name, code, address) => {
      const tempSearchData = [...multiSearchData.segments];
      if (index === tempSearchData.length - 1) {
        tempSearchData[index] = {
          ...tempSearchData[index],
          arrTo: code.trim(),
          arrToText: `${name} (${code.trim()})`,
          arrAddress: address,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
      } else {
        tempSearchData[index] = {
          ...tempSearchData[index],
          arrTo: code.trim(),
          arrAddress: address,
          arrToText: `${name} (${code.trim()})`,
          openFrom: false,
          openTo: false,
          openDate: true,
        };
        tempSearchData[index + 1] = {
          ...tempSearchData[index + 1],
          depFrom: code.trim(),
          depFromText: `${name} (${code.trim()})`,
          depAddress: address,
        };
      }
      setMultiSearchData({ ...multiSearchData, segments: tempSearchData });
      setToSendData(code);
      setToSearchText(`${name} (${code})`);
      setToSuggest([]);
      setToAddress(address);
      setOpenTo(false);
      setTimeout(() => setOpenDate(true), 200);
    };

    console.log(multiSearchData);

    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {data2.length !== 0 ? (
            data2.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "#fff",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "#D1E9FF",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      toSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.cityName}, ${item.countryName}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#003566",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.cityName}, {item.countryName}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const [users, setUsers] = useState("");
  useEffect(() => {
    const users = secureLocalStorage.getItem("user-info");
    if (users) {
      setUsers(users);
    }
  }, []);

  let [book, setBook] = useState(false);

  async function handleSearch(e) {
    e.preventDefault();
    //todo: setChangeState for reTrigger useEffect
    setChangeState((prev) => !prev);
    //todo: setChangeState for reTrigger useEffect end
    setClick(true);
    // todo: setClick to true to start loader
    secureLocalStorage.setItem("multi-city", {
      faddress,
      toAddress,
      fromSearchText,
      toSearchText,
      departureDate: format(new Date(from), "dd MMM yy"),
      adultCount,
      childCount,
      infant,
      tripType,
      fromSendData,
      toSendData,
      className,
      multiSearchData,
      changeState,
    });

    if (users) {
      navigate("/dashboard/multicityaftersearch", {
        state: {
          faddress,
          toAddress,
          fromSearchText,
          toSearchText,
          departureDate: format(new Date(from), "dd MMM yy"),
          adultCount,
          childCount,
          infant,
          tripType,
          fromSendData,
          toSendData,
          className,
          multiSearchData,
          changeState,
        },
      });
    } else {
      Swal.fire({
        title: "Please, Log in first",
        text: "Please log in first to see all the flights.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          setBook(true);
        }
      });
    }
  }

  const handleSelect = (date, index) => {
    const tempSearchData = [...multiSearchData.segments];
    if (index === tempSearchData.length - 1) {
      tempSearchData[index] = {
        ...tempSearchData[index],
        Date: new Date(date).toLocaleDateString("sv"),
        selectedDate: date,
        openDate: false,
        openFrom: false,
        openTo: false,
      };
    } else {
      const prevDate = new Date(date);
      const newDate = new Date(prevDate);
      newDate.setDate(prevDate.getDate() + 2);
      tempSearchData[index] = {
        ...tempSearchData[index],
        Date: new Date(date).toLocaleDateString("sv"),
        selectedDate: date,
        openDate: false,
        openFrom: false,
        openTo: false,
      };

      tempSearchData[index + 1] = {
        ...tempSearchData[index + 1],
        Date: newDate.toLocaleDateString("sv"),
        selectedDate: new Date(newDate),
        openDate: false,
        openFrom: false,
        openTo: false,
      };
    }

    setMultiSearchData({ ...multiSearchData, segments: tempSearchData });
    setFrom(date);
    setTo(addDays(date, 0));
    setOpenDate(false);
    index === 0 && setOpen(true);
    setData2(InitialData);
  };

  const handleClickAway = (index) => {
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpen(false);
    setData2(InitialData);
    setResult(adultCount + childCount + infant);
    const tempSegment = [...multiSearchData.segments];
    tempSegment[index] = {
      ...tempSegment[index],
      openFrom: false,
      openTo: false,
      openDate: false,
      open: false,
    };
    setMultiSearchData({
      ...multiSearchData,
      segments: tempSegment,
    });
  };

  const location = useLocation();

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <form onSubmit={handleSearch}>
        <Grid container mt={1}>
          {/* Serch button for big screen  */}
          <Grid
            lg={2}
            md={2}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              mt: "-30px",
              ml: "8px",
              display: { xs: "none", sm: "none", md: "block" },
            }}
          >
            <Box>
              <Button
                type="submit"
                disabled={click}
                className="shine-effect"
                sx={{
                  fontSize: "15px",
                  // height: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                  width: {
                    lg: "16%",
                    md: "90%",
                    sm: "100%",
                    xs: "fit-content",
                  },
                  height: "40px",
                  position: "absolute",
                  left: {
                    md: "900px",
                    lg: `${location.pathname === "/" ? "980px" : "83%"}`,
                    xs: "0",
                  },
                  bottom: {
                    md: "25px",
                    xs: "0",
                  },
                  top: {
                    lg: `${location.pathname === "/" ? "-50px" : "-100px"}`,
                  },
                  mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                    cursor: "pointer",
                  },
                  display: {
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "flex",
                  },
                  m: "20px 0 12px 0",
                }}
              >
                <Box>{click ? "Wait..." : "Search"}</Box>
                <AirplanemodeActiveIcon
                  sx={{ fontSize: "23px", transform: "rotate(45deg)" }}
                />
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            {" "}
            {multiSearchData.segments.map((segment, index, arr) => {
              // Get the date from arr[index - 1]?.Date
              const previousDate = new Date(arr[index - 1]?.Date);

              // Create a new date for the next day
              const nextDay = new Date(previousDate);
              nextDay.setDate(previousDate.getDate() + 2);
              // console.log(segment)
              // console.log(nextDay)
              return (
                <Box key={index}>
                  <ClickAwayListener
                    key={index}
                    onClickAway={() => handleClickAway(index)}
                  >
                    <Grid
                      key={index}
                      sx={{
                        height: "fit-content",
                        width: "100%",
                        mt: { lg: "5px", md: "0px", sm: "5px", xs: "5px" },
                        px: location.pathname === "/" ? "11px" : "0px",
                        pb: "30px",
                      }}
                      container
                      alignItems="center"
                      rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 1 }}
                      columnSpacing={0.1}
                    >
                      {/* //todo: Departure City section */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        pr={2}
                        sx={{ position: "relative" }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "var(--light-gray)",
                          }}
                          mb={0.5}
                        >
                          Departure From
                        </Typography>
                        <Typography
                          style={{
                            color: "var(--mateBlack)",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          mt={0.5}
                        >
                          {segment.depAddress.split(",")[0]}
                        </Typography>
                        <Box
                          style={{
                            cursor: "pointer",
                            height: "35px",
                            borderBottom: "1px solid #ACA6A6",
                            width: "100%",
                          }}
                          onClick={() => {
                            const tempSegment = [...multiSearchData.segments];
                            tempSegment[index] = {
                              ...tempSegment[index],
                              openFrom: !segment.openFrom,
                              openTo: false,
                              openDate: false,
                            };
                            setMultiSearchData({
                              ...multiSearchData,
                              segments: tempSegment,
                            });
                            setOpenFrom((prev) => !prev);
                            setOpenTo(false);
                            setOpenDate(false);
                            setOpen(false);
                            setData2(InitialData);
                          }}
                        >
                          <Box>
                            <Tooltip title={fromSearchText} arrow>
                              <span
                                style={{
                                  width: "100%",
                                  color: "var(--light-gray)",
                                  fontSize: "13px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {segment.depFromText.length > 35
                                  ? `${segment.depFromText.substring(0, 35)}...`
                                  : segment.depFromText}
                              </span>
                            </Tooltip>
                          </Box>
                        </Box>
                        {segment.openFrom ? (
                          <Box
                            sx={{
                              top: {
                                xs: "37px",
                                md: "37px",
                              },
                            }}
                            style={{
                              position: "absolute",
                              top: "105%",
                              left: "0",
                              width: "100%",
                              backgroundColor: "var(--white)",
                              border: "1px solid var(--primary-color",
                              height: "fit-content",
                              borderRadius: "5px",
                              zIndex: "999",
                              padding: "5px 5px 0",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "var(--secondary-color)",
                                zIndex: 10,
                              }}
                              backgroundColor="var(--white)"
                            >
                              <input
                                autoComplete="off"
                                autoFocus
                                onChange={(e) =>
                                  setSearchKeyword(e.target.value)
                                }
                                placeholder="Search a airport..."
                                className="customPlaceholder"
                                style={{
                                  color: "var(--light-gray)",
                                  fontWeight: 500,
                                  paddingLeft: "20px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  outline: "none",
                                  borderBottom: "1px solid #dbdde0",
                                }}
                              />
                            </Box>
                            <Box>{fromGetSuggetion(index)}</Box>
                          </Box>
                        ) : null}
                        {/* </Grid> */}
                      </Grid>

                      {/* //todo: Arrival City section */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        pr={2}
                        sx={{ position: "relative" }}
                      >
                        <Typography
                          style={{
                            fontSize: "12px",
                            fontWeight: "400",
                            color: "var(--light-gray)",
                          }}
                          mb={0.5}
                        >
                          Arrival To
                        </Typography>
                        <Typography
                          style={{
                            color: "var(--mateBlack)",
                            fontSize: "16px",
                            fontWeight: "500",
                          }}
                          mt={0.5}
                        >
                          {/* {segment?.depAddress.split(",")[0]} */}
                          {segment.arrAddress.split(",")[0]}
                        </Typography>
                        <Box
                          style={{
                            cursor: "pointer",
                            height: "35px",
                            borderBottom: "1px solid #ACA6A6",
                            width: "100%",
                          }}
                          onClick={() => {
                            const tempSegment = [...multiSearchData.segments];
                            tempSegment[index] = {
                              ...tempSegment[index],
                              openFrom: false,
                              openTo: !segment.openTo,
                              openDate: false,
                            };
                            setMultiSearchData({
                              ...multiSearchData,
                              segments: tempSegment,
                            });
                            setOpenFrom(false);
                            setOpenTo((prev) => !prev);
                            setOpenDate(false);
                            setOpen(false);
                          }}
                        >
                          <Box>
                            <Tooltip title={segment.arrToText} arrow>
                              <span
                                style={{
                                  width: "100%",
                                  color: "var(--light-gray)",
                                  fontSize: "13px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {segment.arrToText.length > 28
                                  ? `${segment.arrToText.substring(0, 28)}...`
                                  : segment.arrToText}
                              </span>
                            </Tooltip>
                          </Box>
                        </Box>
                        {segment.openTo && (
                          <Box
                            style={{
                              position: "absolute",
                              top: "105%",
                              left: "0",
                              width: "100%",
                              backgroundColor: "var(--white)",
                              border: "1px solid var(--primary-color",
                              height: "fit-content",
                              borderRadius: "5px",
                              zIndex: "999",
                              padding: "5px 5px 0",
                            }}
                          >
                            <Box
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                color: "var(--secondary-color)",
                                zIndex: 10,
                              }}
                              backgroundColor="var(--white)"
                            >
                              <input
                                autoComplete="off"
                                autoFocus
                                onChange={(e) =>
                                  setSearchKeyword(e.target.value)
                                }
                                placeholder="Search a airport..."
                                className="customPlaceholder"
                                style={{
                                  color: "var(--light-gray)",
                                  fontWeight: 500,
                                  paddingLeft: "20px",
                                  width: "100%",
                                  height: "40px",
                                  backgroundColor: "transparent",
                                  border: "none",
                                  outline: "none",
                                  borderBottom: "1px solid #dbdde0",
                                }}
                              />
                            </Box>
                            <Box>{toGetSuggetion(index)}</Box>
                          </Box>
                        )}
                      </Grid>

                      {/* //todo:Travel Date */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={3}
                        lg={3}
                        pr={2}
                        sx={{ position: "relative" }}
                      >
                        <Box
                          sx={{
                            borderBottom: "1px solid #ACA6A6",
                            display: "flex",
                            flexDirection: "column",
                            pb: "10px",
                          }}
                        >
                          <Typography
                            style={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "var(--light-gray)",
                            }}
                            mb={0.5}
                          >
                            Departure Date
                          </Typography>
                          <Box
                            style={{
                              cursor: "pointer",
                              width: "100%",
                            }}
                            onClick={() => {
                              const tempSearchData = [
                                ...multiSearchData.segments,
                              ];
                              tempSearchData[index] = {
                                ...tempSearchData[index],
                                openFrom: false,
                                openTo: false,
                                openDate: !segment.openDate,
                              };
                              setMultiSearchData({
                                ...multiSearchData,
                                segments: tempSearchData,
                              });
                              setTimeout(
                                () => setOpenDate((prev) => !prev),
                                200
                              );
                              setOpenFrom(false);
                              setOpenTo(false);
                              setOpen(false);
                              // setOpenPassenger((prev) => !prev);
                            }}
                          >
                            <Box>
                              <span
                                style={{
                                  color: "var(--mateBlack)",
                                  fontSize: "16px",
                                  fontWeight: "500",
                                  marginBottom: {
                                    xs: "2px",
                                    sm: "8px",
                                    md: "8px",
                                  },
                                  display: "inline-block",
                                }}
                              >
                                {`${format(
                                  addDays(new Date(segment.Date), 0),
                                  "dd MMM yy"
                                )}`}
                              </span>
                            </Box>
                            <span
                              style={{
                                color: "var(--primary-color)",
                                fontSize: "13px",
                                paddingBottom: "4px",
                              }}
                            >
                              {moment(segment.Date).format("dddd")}
                            </span>
                          </Box>
                        </Box>

                        {segment.openDate && (
                          <Box>
                            <Calendar
                              color="var(--primary-color)"
                              date={segment?.selectedDate}
                              onChange={(date) => {
                                handleSelect(date, index);
                                setOpenFrom(true);
                              }}
                              months={1}
                              direction="horizontal"
                              maxDate={maxDate}
                              minDate={index === 0 ? new Date() : nextDay}
                              className={"dashboard-calendar"}
                            />
                          </Box>
                        )}
                      </Grid>

                      {/* //todo: Passenger Box section */}
                      {index === 0 ? (
                        <Grid item xs={12} sm={12} md={3} lg={3}>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              fontWeight: "400",
                              color: "var(--light-gray)",
                              mt: { xs: 1, sm: 0, md: 0 },
                            }}
                            mb={0.5}
                          >
                            Passenger
                          </Typography>
                          <Box
                            sx={{
                              cursor: "pointer",
                              height: { xs: "35px", sm: "35px", md: "57px" },
                              borderBottom: "1px solid #ACA6A6",
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                            onClick={() => handleClickOpen(index, segment)}
                          >
                            <span
                              style={{
                                color: "var(--mateBlack)",
                                fontSize: "16px",
                                fontWeight: "500",
                                display: "inline-block",
                                // paddingTop: "23px",
                              }}
                            >
                              {result} Traveler
                            </span>

                            {/* </Grow> */}
                          </Box>{" "}
                          {segment.open && (
                            <Box
                              style={{
                                position: "absolute",
                                top: "105%",
                                left: "0",
                                zIndex: "999",
                                // backgroundColor: "blue",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor: "#FFF",
                                  padding: "10px",
                                  overflow: "hidden",
                                  width: "92%",
                                  border: "1px solid var(--primary-color)",
                                  borderRadius: "5px",
                                  display: "flex",
                                  flexDirection: "column",
                                  gap: "5px",
                                }}
                              >
                                <Box
                                  style={{
                                    textAlign: "center",
                                    marginBottom: "5px",
                                    color: "var(--mateBlack)",
                                  }}
                                >
                                  <h3>Passenger</h3>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    // bgcolor: "red",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "10px",
                                      width: "50%",
                                    }}
                                  >
                                    <button
                                      onClick={adultDecrement}
                                      style={{
                                        backgroundColor: "var(--primary-color)",
                                        color: "var(--white)",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                        fontSize: "14px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      -
                                    </button>
                                    <h5
                                      style={{
                                        color: "var(--secondary-color)",
                                      }}
                                    >
                                      {adultCount}
                                    </h5>
                                    <button
                                      onClick={adultInclement}
                                      style={{
                                        backgroundColor: "var(--primary-color)",
                                        color: "var(--white)",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                        fontSize: "14px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      +
                                    </button>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      width: "50%",
                                      flexDirection: "column",
                                      color: "var(--secondary-color)",
                                    }}
                                  >
                                    <h5>Adult</h5>
                                    <span style={{ fontSize: "13px" }}>
                                      12+ yrs
                                    </span>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      gap: "10px",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      width: "50%",
                                    }}
                                  >
                                    <button
                                      onClick={childDecrement}
                                      style={{
                                        backgroundColor: "var(--primary-color)",
                                        color: "var(--white)",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                        fontSize: "14px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      -
                                    </button>
                                    <h5
                                      style={{
                                        color: "var(--secondary-color)",
                                      }}
                                    >
                                      {childCount}
                                    </h5>
                                    <button
                                      onClick={childInclement}
                                      style={{
                                        backgroundColor: "var(--primary-color)",
                                        color: "var(--white)",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                        fontSize: "14px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      +
                                    </button>
                                  </Box>

                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      width: "50%",
                                      flexDirection: "column",
                                      color: "var(--secondary-color)",
                                    }}
                                  >
                                    <h5>Children</h5>
                                    <span style={{ fontSize: "13px" }}>
                                      2- less than 12 yrs
                                    </span>
                                  </Box>
                                </Box>

                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    width: "100%",
                                  }}
                                >
                                  <Box
                                    style={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      alignItems: "center",
                                      gap: "10px",
                                      width: "50%",
                                    }}
                                  >
                                    <button
                                      onClick={infantDecrement}
                                      style={{
                                        backgroundColor: "var(--primary-color)",
                                        color: "var(--white)",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                        fontSize: "14px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // borderRadius: "5px",
                                        borderColor: "red",
                                      }}
                                    >
                                      -
                                    </button>
                                    <h5
                                      style={{
                                        color: "var(--secondary-color)",
                                      }}
                                    >
                                      {infant}
                                    </h5>
                                    <button
                                      onClick={infantIncrement}
                                      style={{
                                        backgroundColor: "var(--primary-color)",
                                        color: "var(--white)",
                                        border: "none",
                                        width: "20px",
                                        height: "20px",
                                        fontSize: "14px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        borderRadius: "5px",
                                      }}
                                    >
                                      +
                                    </button>
                                  </Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "flex-start",
                                      width: "50%",
                                      flexDirection: "column",
                                      color: "var(--secondary-color)",
                                    }}
                                  >
                                    <h5>Infant</h5>
                                    <span style={{ fontSize: "13px" }}>
                                      0 - 24 month{" "}
                                    </span>
                                  </Box>
                                </Box>
                                <hr />
                                <Box>
                                  <Box>
                                    <FormControl>
                                      <RadioGroup
                                        value={className}
                                        row
                                        onChange={handleClassName}
                                      >
                                        <FormControlLabel
                                          value="Economy"
                                          control={<BpRadio />}
                                          label="Economy"
                                          sx={{
                                            mr: "21px",
                                          }}
                                        />
                                        <FormControlLabel
                                          value="Business"
                                          control={<BpRadio />}
                                          label="Business"
                                        />
                                        <FormControlLabel
                                          value="First Class"
                                          control={<BpRadio />}
                                          label="First Class"
                                        />
                                        <FormControlLabel
                                          value="Premium Economy "
                                          control={<BpRadio />}
                                          label="Premium Economy"
                                        />
                                      </RadioGroup>
                                    </FormControl>
                                  </Box>
                                  <Button
                                    size="small"
                                    onClick={() => handleClose(index)}
                                    className="shine-effect"
                                    style={{
                                      backgroundColor: "var(--primary-color)",
                                      color: "var(--mateBlack)",
                                    }}
                                  >
                                    DONE
                                  </Button>
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      ) : (
                        <Grid lg={3} md={3} sm={6} xs={12} mt={2}>
                          <Box
                            sx={{
                              display: { xs: "flex", sm: "flex", md: "block" },
                              justifyContent: {
                                xs: "space-between",
                                sm: "flex-end",
                              },
                              // alignItems: "flex-start",
                              gap: 2,
                            }}
                          >
                            <Button
                              disabled={arr.length > 4 ? true : false}
                              sx={{
                                fontSize: "14px",

                                width: "93%",
                                backgroundColor: "#28282B",
                                color: "var(--white)",
                                textTransform: "capitalize",

                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                display: "inline-block",
                                "&:hover": {
                                  backgroundColor: "#28282B",
                                  cursor: "pointer",
                                },
                              }}
                              onClick={addCity}
                            >
                              Add City
                            </Button>
                            <Button
                              disabled={arr.length === 2 ? true : false}
                              sx={{
                                fontSize: "14px",

                                width: "93%",
                                backgroundColor: "var(--secondary-color)",
                                color: "var(--white)",
                                textTransform: "capitalize",
                                display: "inline-block",
                                "&:hover": {
                                  backgroundColor: "var(--primary-color)",
                                  cursor: "pointer",
                                },
                                mt: { xs: 0, sm: 0, md: 1 },
                              }}
                              onClick={() => removeCity(segment.id)}
                            >
                              Remove City
                            </Button>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </ClickAwayListener>
                </Box>
              );
            })}
          </Grid>

          {/* Serch button for small screen  */}
          <Grid
            lg={2}
            md={2}
            sm={12}
            xs={12}
            justifyContent="center"
            alignItems={"center"}
            sx={{
              mt: "0px",
              ml: "8px",
              display: { xs: "block", sm: "block", md: "none" },
            }}
          >
            <Box>
              <Button
                disabled={click}
                type="submit"
                className="shine-effect"
                sx={{
                  fontSize: "15px",
                  height: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                  width: {
                    lg: "80%",
                    md: "90%",
                    sm: "100%",
                    xs: "100%",
                  },

                  left: {
                    md: "950px",
                    xs: "0",
                  },
                  bottom: {
                    md: "295px",
                    xs: "0",
                  },
                  mt: { lg: "0px", md: "0px", sm: "10px", xs: "0px" },
                  backgroundColor: "var(--secondary-color)",
                  color: "white",
                  textTransform: "capitalize",
                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                    cursor: "pointer",
                  },
                  display: {
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "flex",
                  },
                  m: "0px 0 12px 0",
                }}
              >
                <Box>{click ? "Wait..." : "Search"}</Box>
                <AirplanemodeActiveIcon
                  sx={{ fontSize: "23px", transform: "rotate(45deg)" }}
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>

      {book ? <Popup book={true} setBook={setBook} /> : ""}
    </Box>
  );
};

export default Multicity;
