import React, { useState } from "react";
import { Box, Grid, Typography, Divider, Chip } from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import { format } from "date-fns";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tab from "@mui/material/Tab";
import moment from "moment/moment";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { IoAirplaneSharp } from "react-icons/io5";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const MultiCityFlightDetails = ({ flightData }) => {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calDuration = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let seconds = Math.round((totalMinutes - (hours * 60 + minutes)) * 60);

    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }

    return `${hours.toString().padStart(2, "0")}h:${minutes
      .toString()
      .padStart(2, "0")}m`;
  };

  return (
    <Box sx={{ width: "100%", p: 2 }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",
              height: { md: "fit-content", sm: "100%", xs: "100%" },
              display: "flex",
              justifyContent: {
                md: "space-between",
                sm: "center",
                xs: "center",
              },
              alignItems: "center",
              opacity: "1",
              "& .MuiTabs-flexContainer": { flexWrap: "wrap", gap: "5px" },
              ".MuiTabs-root": {
                minHeight: "fit-content",
              },
              "& button": {
                opacity: "1",
                background: "transparent",
                color: "var(--primary-color)",
                border: "1px solid var(--primary-color)",
                width: "fit-content",
                minHeight: "fit-content",
                padding: " 8px 10px",
                fontSize: "12px",
                borderRadius: "4px",
              },
              "& button.Mui-selected,& button.Mui-selected >svg": {
                background: "var(--primary-color)",
                color: "var(--white) !important",
              },
            }}
          >
            <TabList
              variant="scrollable"
              scrollButtons
              allowScrollButtonsMobile
              value={value}
              onChange={handleChange}
              aria-label="agent-tabs"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
            >
              {flightData?.AllLegs?.map((segment, index) => (
                <Tab
                  label={`${segment?.Segments[0]?.DepFrom} - ${
                    segment?.Segments[segment?.Segments?.length - 1]?.ArrTo
                  }`}
                  value={index}
                />
              ))}
            </TabList>
          </Box>
          {flightData?.AllLegs?.map((segment, index) => (
            <TabPanel value={index} style={{ padding: "20px 0px" }}>
              {segment?.Segments?.map((singleSegment, i) => {
                return (
                  <Grid
                    container
                    height="fit-content"
                    key={i}
                    sx={{ mb: 1, pt: "10px", mt: "10px" }}
                    columnSpacing={2}
                  >
                    {/* //todo: Image section*/}
                    <Grid item md={3} sm={4} xs={4}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "start",
                          gap: "10px",
                          height: "100%",
                        }}
                      >
                        {/* //todo: Image Part */}
                        <Box sx={{ width: "45px", height: "45px" }}>
                          <img
                            src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${singleSegment?.MarketingCarrier}.png`}
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              borderRadius: "50%",
                            }}
                            alt={`${singleSegment?.MarketingCarrier}`}
                          />
                        </Box>
                        {/* //todo:Text Part */}
                        <Box sx={{ width: "fit-content" }}>
                          <Tooltip
                            title={`${singleSegment?.MarketingCarrierName}`}
                          >
                            <Typography
                              sx={{
                                color: "var(--mattblack)",
                                fontWeight: 600,
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "13px",
                                  cursor: "pointer",
                                },
                              }}
                              noWrap
                            >{`${singleSegment?.MarketingCarrierName}`}</Typography>
                          </Tooltip>
                          {/* //todo: Career Name and class part */}
                          <Tooltip
                            title={`${singleSegment?.MarketingCarrier} ${
                              singleSegment?.MarketingFlightNumber
                            } & ${
                              singleSegment?.SegmentCode?.bookingcode ||
                              singleSegment?.SegmentCode?.BookingCode
                            }`}
                          >
                            <Typography
                              sx={{
                                color: "var(--primary-color)",
                                fontWeight: 500,
                                fontSize: {
                                  xs: "12px",
                                  sm: "12px",
                                  md: "12px",
                                  cursor: "pointer",
                                },
                                mt: "2px",
                              }}
                              noWrap
                            >{`${singleSegment?.MarketingCarrier} ${
                              singleSegment?.MarketingFlightNumber
                            } & ${
                              singleSegment?.SegmentCode?.bookingCode ||
                              singleSegment?.SegmentCode?.BookingCode
                            }`}</Typography>
                          </Tooltip>
                        </Box>
                      </Box>
                    </Grid>
                    {/* //todo: Departure Section */}
                    <Grid item md={2.6} sm={7} xs={7}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--mattblack)",
                            fontWeight: 700,
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "16px",
                            },
                          }}
                        >{`${singleSegment?.DepFrom}, ${singleSegment?.DepLocation}`}</Typography>

                        <Typography
                          sx={{
                            width: "100%",
                            cursor: "pointer",
                            color: "var(--primary-color)",
                            fontWeight: "600",
                            pt: "2px",
                            fontSize: {
                              xs: "12px",
                              sm: "11px",
                              md: "12px",
                            },
                          }}
                          noWrap
                        >
                          {singleSegment?.DepAirPort}
                        </Typography>

                        <Typography
                          sx={{
                            color: "var(--gray)",
                            fontWeight: 400,
                            fontFamily: "Poppins !important",
                            fontSize: {
                              xs: "10px",
                              sm: "11px",
                              md: "12px",
                            },
                            pt: "2px",
                          }}
                        >{`${moment(singleSegment?.DepTime).format(
                          "DD MMM YYYY hh:mm:A"
                        )}`}</Typography>
                      </Box>
                    </Grid>
                    {/* //todo: Annimation section */}
                    <Grid item md={3.2} sm={6} xs={6}>
                      <Box px={1} width="70%" margin="auto" sx={{ pt: "10px" }}>
                        <Typography
                          sx={{
                            color: "var(--gray)",
                            fontWeight: "normal",
                            fontFamily: "Poppins !important",
                            fontSize: {
                              xs: "12px",
                              sm: "10px",
                              md: "11px",
                            },
                            textAlign: "center",
                          }}
                        >
                          {calDuration(
                            segment?.Segments?.map((item) =>
                              Number.parseInt(item?.Duration)
                            )?.reduce((a, b) => a + b, 0)
                          )}
                        </Typography>
                        <div className="segment-1">
                          <div className="segment-circle">
                            <div className="circle-1">
                              <HtmlTooltip
                                title={
                                  <React.Fragment>
                                    <Typography
                                      sx={{
                                        color: "#fff",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {flightData?.arrival}
                                    </Typography>
                                  </React.Fragment>
                                }
                                followCursor
                              >
                                <span>
                                  <CircleIcon
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontSize: "11px",
                                      cursor: "pointer",
                                    }}
                                  />
                                </span>
                              </HtmlTooltip>
                            </div>
                          </div>
                          <div className="segment-flight1">
                            <IoAirplaneSharp />
                          </div>
                        </div>

                        <Typography
                          sx={{
                            color: "var(--gray)",
                            fontWeight: "normal",
                            fontSize: {
                              xs: "12px",
                              sm: "10px",
                              md: "11px",
                            },
                            textAlign: "center",
                            mt: 1,
                          }}
                        >
                          {segment?.Segments?.length > 0
                            ? `${segment?.Segments?.length} Stop`
                            : "Non Stop"}
                        </Typography>
                      </Box>
                    </Grid>
                    {/* //todo: Arrival section */}
                    <Grid item md={3} sm={6} xs={6}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "start",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--mattblack)",
                            fontWeight: 700,
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "16px",
                            },
                          }}
                        >
                          {`${singleSegment?.ArrTo}, ${
                            singleSegment?.ArrLocation || "empty"
                          }`}
                        </Typography>

                        <Typography
                          sx={{
                            width: "100%",
                            cursor: "pointer",
                            color: "var(--primary-color)",
                            fontWeight: "600",
                            pt: "2px",
                            fontSize: {
                              xs: "12px",
                              sm: "11px",
                              md: "12px",
                            },
                          }}
                          noWrap
                        >{`${
                          singleSegment?.ArrAirPort || "empty"
                        }`}</Typography>
                        <Typography
                          sx={{
                            color: "var(--gray)",
                            fontWeight: 400,
                            fontFamily: "Poppins !important",
                            fontSize: {
                              xs: "10px",
                              sm: "11px",
                              md: "12px",
                            },
                            pt: "2px",
                          }}
                        >{`${moment(singleSegment?.ArrTime).format(
                          "DD MMM YYYY hh:mm:A"
                        )}`}</Typography>
                      </Box>
                    </Grid>

                  
                  </Grid>
                );
              })}
            </TabPanel>
          ))}
        </TabContext>
      </Box>
    </Box>
  );
};

export default MultiCityFlightDetails;
