import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import SingleFlighDetails from "./SingleFlighDetails";
import FareDetails from "./FareDetails";
// import FarePolicy from "../FarePolicy/FarePolicy";
import Baggage from "./Baggage";
import FarePolicy from "../FarePolicy/FarePolicy";

const FlightDetails = ({ expanded, pricebreakdown, segments, flightData }) => {
  const [isActive, setIsActive] = useState(1);

  return (
    <Accordion
      expanded={expanded === true}
      sx={{
        boxShadow: "none",
        bgcolor: "white",
        mt:"0px"
      }}
    >
      <AccordionSummary
        sx={{ display: "none" }}
        aria-controls="panel1d-content"
        id="panel1d-header"
      ></AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: "1px solid #F0F6FF",
          padding: "20px",
          // pb: "1000px",
        }}
      >
        {/*
                Flight Details Navigation button area Start 
           */}

        <Stack direction="row">
          <Box>
            <Typography
              onClick={() => setIsActive(1)}
              sx={{
                cursor: "pointer",
                fontSize: "12px",
                padding: "10px 20px",
                borderRadius: "2px",
                color: isActive === 1 ?"var(--white)":"#444542",
                fontWeight:"500",
                bgcolor: {
                  lg: `${isActive === 1 ? "var(--secondary-color)" : ""}`,
                  xs: "none",
                },
                borderBottom: {
                  xs: `${isActive === 1 ? "1px solid #F7D800" : ""}`,
                  lg: "none",
                },
              }}
            >
              Flight Details
            </Typography>
          </Box>
          <Box>
            <Typography
              onClick={() => setIsActive(2)}
              sx={{
                cursor: "pointer",
                fontSize: "12px",
                padding: "10px 20px",
                borderRadius: "2px",
                color: isActive === 2?"var(--white)":"#444542",
                fontWeight:"500",
                bgcolor: {
                  lg: `${isActive === 2 ? "var(--secondary-color)" : ""}`,
                  xs: "none",
                },
                borderBottom: {
                  xs: `${isActive === 2 ? "1px solid #F7D800" : ""}`,
                  lg: "none",
                },
              }}
            >
              Fare Details
            </Typography>
          </Box>
          <Box>
            <Typography
              onClick={() => setIsActive(3)}
              sx={{
                cursor: "pointer",
                fontSize: "12px",
                padding: "10px 20px",
                borderRadius: "2px",
                color: isActive === 3 ?"var(--white)":"#444542",
                fontWeight:"500",
                bgcolor: {
                  lg: `${isActive === 3 ? "var(--secondary-color)" : ""}`,
                  xs: "none",
                },
                borderBottom: {
                  xs: `${isActive === 3 ? "1px solid #F7D800" : ""}`,
                  lg: "none",
                },
              }}
            >
              Fare Policy
            </Typography>
          </Box>
          <Box>
            <Typography
              onClick={() => setIsActive(4)}
              sx={{
                cursor: "pointer",
                fontSize: "12px",
                padding: "10px 20px",
                borderRadius: "2px",
                color: isActive === 4 ?"var(--white)":"#444542",
                fontWeight:"500",
                bgcolor: {
                  lg: `${isActive === 4 ? "var(--secondary-color)" : ""}`,
                  xs: "none",
                },
                borderBottom: {
                  xs: `${isActive === 4 ? "1px solid #F7D800" : ""}`,
                  lg: "none",
                },
              }}
            >
              Baggage
            </Typography>
          </Box>
        </Stack>
        {/*
                Flight Details Navigation button area End 
           */}

        {/*
                Flight Details Area area Start 
           */}

        <Box>
          <Box
            sx={{
              display: `${isActive === 1 ? "block" : "none"}`,
            }}
          >
            {segments.map((segment, i) => (
              <SingleFlighDetails
                segments={segments}
                key={i}
                index={i}
                segment={segment}
              />
            ))}
          </Box>

          <Box
            sx={{
              display: `${isActive === 2 ? "block" : "none"}`,
            }}
          >
            <FareDetails pricebreakdown={pricebreakdown} />
          </Box>
          <Box
            sx={{
              display: `${isActive === 3 ? "block" : "none"}`,
            }}
          >
            <FarePolicy flightData={flightData} />
          </Box>
          <Box
            sx={{
              display: `${isActive === 4 ? "block" : "none"}`,
            }}
          >
            <Baggage flightData={flightData} pricebreakdown={pricebreakdown} />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default FlightDetails;
