import {
  Button,
  Checkbox,
  ClickAwayListener,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Calendar } from "react-date-range";
import { format } from "date-fns";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import secureLocalStorage from "react-secure-storage";
import noFareFound from "../../images/undraw/undraw_not_found_re_bh2e.svg";
import DriveFolderUploadOutlinedIcon from "@mui/icons-material/DriveFolderUploadOutlined";
import CountryList from "../CountryList";
import "react-phone-input-2/lib/style.css";
import MultiCityFlightDetails from "./MultiCityFlightDetails";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import SearchableDropDown from "./../Shared/SearchableDropDown/SearchableDropDown";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import moment from "moment";
import bookingSuccess from "../../images/undraw/undraw_travel_booking_re_6umu.svg";
import Select from "react-select";
import CancelIcon from "@mui/icons-material/Cancel";

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const MultiCityUserInfo = ({ flightData, userData }) => {
  // const users = secureLocalStorage.getItem("user-info");
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.agent_id;
  const userPhone = user?.agent_phone_number;
  const staffId = user?.staff_id;
  const [email, setEmail] = useState(user?.agent_email || "");
  const location = useLocation();
  const navigate = useNavigate();
  const [clicked, setClicked] = useState(false);
  const [optionPassengers, setOptionPassengers] = useState([]);
  const [userPhoneNumber, setUserPhoneNumber] = useState(
    user?.user_phone_number || "8801512121265"
  );
  const { adultCount, childCount, infant } = userData;
  const [selectedImage, setSelectedImage] = useState([]);
  const [selectedImage2, setSelectedImage2] = useState([]);
  const [mexpanded, setMexpanded] = useState("");
  const mealhandleChange = (panel) => (event, newExpanded) => {
    setMexpanded(newExpanded ? panel : false);
  };
  const [activeMeal, setActiveMeal] = useState("");
  const [activeWheel, setActiveWheel] = useState("");
  const [expanded, setExpanded] = useState("panel1");
  const [travelers, setTravelers] = useState([]);
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  console.log("User Info", user);

  //todo: select traveler section
  const [travellers, setTravellers] = useState([]);
  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/b2b/air/passengers/index.php?agentId=${user?.agent_id}`
    )
      .then((res) => res.json())
      .then((data) => {
        setTravelers(data?.passengers);
      });
  }, []);

  // todo: date validation
  function addMonths(date, months) {
    date.setMonth(date.getMonth() + months);
    return date;
  }

  let dateAfterSixMonths = addMonths(new Date(), 1);
  let dateBeforeTwelveYears = addMonths(new Date(), -144);
  let dateBeforeTwoYears = addMonths(new Date(), -24);
  // todo:end

  const [focused, setFocused] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };

  let allLegs = flightData?.AllLegs;

  const [flightPassengerData, setFlightPassengerData] = useState({
    passengers: [
      ...[...new Array(adultCount)].map((item, index) => {
        return {
          type: "ADT",
          fName: "",
          lName: "",
          gender: "",
          phone: "",
          email: "",
          dob: format(new Date(), "dd MMM yyyy"),
          passNation: "BD",
          passNo: "",
          passEx: format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
          openDate: false,
          openPassExDate: false,
          prefix: "MR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
      ...[...new Array(childCount)].map((item, index) => {
        return {
          type: "CNN",
          fName: "",
          lName: "",
          gender: "",
          phone: "",
          email: "",
          dob: format(new Date(), "dd MMM yyyy"),
          passNation: "BD",
          passNo: "",
          passEx: format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
          openDate: false,
          openPassExDate: false,
          prefix: "MR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
      ...[...new Array(infant)].map((item, index) => {
        return {
          type: "INF",
          fName: "",
          lName: "",
          gender: "",
          phone: "",
          email: "",
          dob: format(new Date(), "dd MMM yyyy"),
          passNation: "BD",
          passNo: "",
          passEx: format(new Date(dateAfterSixMonths), "dd MMM yyyy"),
          openDate: false,
          openPassExDate: false,
          prefix: "MR",
          meal: "none",
          wheelChair: "no",
          vipMessage: "",
          passCopy: "",
          visaCopy: "",
        };
      }),
    ],
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    email: email,
    phone: userPhoneNumber,
    tripType: "multicity",
    agentId: "",
    segments: flightData?.AllLegs?.map((data) => data?.segments),
  });

  const handleOnChange = (e, type, index) => {
    let value = e.target.value;
    let field = e.target.name;

    if (
      e.target.files &&
      e.target.files[0] &&
      (field === "passCopy" || field === "visaCopy")
    ) {
      value = e.target.files[0];
    }

    const tempFlightData = [...flightPassengerData.passengers];

    if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "ADT" &&
      value === "Male"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MR",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "ADT" &&
      value === "Female"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MRS",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "CNN" &&
      value === "Male"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MSTR",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "CNN" &&
      value === "Female"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MISS",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "INF" &&
      value === "Male"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MSTR",
      };
    } else if (
      index !== -1 &&
      field === "gender" &&
      tempFlightData[index].type === "INF" &&
      value === "Female"
    ) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
        prefix: "MISS",
      };
    } else if (index !== -1) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        [field]: value,
      };
    }

    console.log(tempFlightData[index]);
    setFlightPassengerData({
      ...flightPassengerData,
      passengers: tempFlightData,
    });
  };

  const handleImageChange = (event, i) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage([...selectedImage, file]);
    }
  };

  const handleImageChange2 = (event) => {
    const file = event.target.files[0];
    if (file) {
      setSelectedImage2([...selectedImage2, file]);
    }
  };

  const newFlightData = flightData?.AllLegs?.map(
    (data) => data?.segments
  ).flat();
  const filterdData = { ...flightData, segments: newFlightData };

  if (filterdData.hasOwnProperty("AllLegs")) {
    delete filterdData?.AllLegs;
  }

  const allPassNo = flightPassengerData?.passengers?.map(
    (passenger) => passenger.passNo
  );
  const passengerPassCopy = flightPassengerData?.passengers?.map(
    (passenger) => passenger.passCopy
  );
  const passengerVisaCopy = flightPassengerData?.passengers?.map(
    (passenger) => passenger.visaCopy
  );

  const [passCopyErr, setPassCopyErr] = useState(false);

  // user data copy
  const userDataCopy = {
    flightData: { ...filterdData },
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    from: `${flightPassengerData?.segments[0][0]?.departure},${flightPassengerData?.segments[0][0]?.arrival}`,
    to: `${flightPassengerData?.segments[1][0]?.departure},${flightPassengerData?.segments[1][0]?.arrival}`,
    tripType: "multicity",
    fromAddress: "",
    toAddress: "",
    dDate: "",
    clientFare: "",
    agentFare: "",
    commission: "",
    segment: flightPassengerData?.segments?.length,
    name: "",
    email: "",
  };

  console.log("User Data", userData);

  // Initialize variables for total fares
  let totalAdultFare = 0;
  let totalChildFare = 0;
  let totalInfantFare = 0;
  let adultTax = 0;
  let childTax = 0;
  let infantTax = 0;

  userData?.flightData?.PriceBreakDown?.forEach((passenger) => {
    switch (passenger.PaxType) {
      case "ADT":
        totalAdultFare += passenger.TotalFare;
        break;
      case "CNN":
        totalChildFare += passenger.TotalFare;
        break;
      case "INF":
        totalInfantFare += passenger.TotalFare;
        break;
    }
  });

  userData?.flightData?.PriceBreakDown?.forEach((passenger) => {
    switch (passenger.PaxType) {
      case "ADT":
        adultTax += passenger.Taxes;
        break;
      case "CNN":
        childTax += passenger.Taxes;
        break;
      case "INF":
        infantTax += passenger.Taxes;
        break;
    }
  });

  // Output the results

  const bookingJson = {
    flightPassengerData: { passengers: [...flightPassengerData.passengers] },
    bookingInfo: {
      agentId: agentId || "",
      staffId: staffId || "",
      system: "Sabre",
      from: `${allLegs[0]?.DepFrom},${allLegs[0]?.ArrTo}`,
      to: `${allLegs[1]?.DepFrom},${allLegs[1]?.ArrTo}`,
      airlines: userData?.flightData?.CarrierName,
      tripType: userData.tripType,
      travelDate: moment(allLegs[0]?.DepDate).format("ddd D MMM YYYY"),
      name: user?.agent_name,
      phone: userPhone,
      email: flightPassengerData.email,
      pax: adultCount + childCount + infant,
      adultCount: adultCount,
      childCount: childCount,
      infantCount: infant,
      agentprice: Number.parseInt(userData?.flightData?.AgentFare || 0),
      netcost: Number.parseInt(userData?.flightData?.netcost || 0),
      adultcostbase: Math.round(totalAdultFare) || 0,
      childcostbase: Math.round(totalChildFare) || 0,
      infantcostbase: Math.round(totalInfantFare) || 0,
      adultcosttax: Math.round(adultTax) || 0,
      childcosttax: Math.round(childTax) || 0,
      infantcosttax: Math.round(infantTax) || 0,
      grosscost: Math.round(userData?.flightData?.grosscost) || 0,
      basefare: Math.round(userData?.flightData?.BasePrice) || 0,
      tax: Math.round(userData?.flightData?.Taxes) || 0,
      timelimit: userData?.timelimit,
      refundable: userData?.flightData?.nonRefundable ? "yes" : "no",
      platform: `B2B`,
      via: "Web",
      segment: flightPassengerData?.segments?.length,
    },
    saveBooking: { ...userDataCopy },
    system: "Sabre",
    agentId: agentId || "",
    staffId: staffId || "",
    platform: `B2B`,
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { passengers } = flightPassengerData;

    for (let i = 0; i < passengers.length; i++) {
      const passenger = passengers[i];
      console.log(passenger);

      const fieldNamesMap = {
        fName: "First Name",
        lName: "Last Name",
        dob: "Date of Birth",
        gender: "Gender",
        passNation: "Passport Nation",
        passNo: "Passport No",
        passEx: "Passport Validity",
        prefix: "Prefix",
        phone: "Phone",
        email: "Email",
        passCopy: "Passport Copy",
      };

      const fieldsToCheck = [
        "fName",
        "lName",
        "gender",
        "phone",
        "email",
        "dob",
        "passNation",
        "passNo",
        "passEx",
        "prefix",
        "passCopy",
      ];

      const emptyFields = fieldsToCheck
        .filter((field) => !passenger[field])
        .map((field) => fieldNamesMap[field]);

      if (emptyFields.length > 0) {
        setPassCopyErr(true);
        // const errorMessage = `<span style="color: #2156A6;font-weight: 500;">Passenger-${i + 1}'s</span>, <span style="color:red ;font-weight: 500;">${emptyFields.join(',')}</span>  ${emptyFields.length > 1 ? 'are' : 'is'} empty`;
        const errorMessage = `<span style="color: black;font-weight: 500;">Please, Provide required information to book.</span>`;
        const errorTitle = `<span style="color: red;font-weight: 500;">${
          i === 0 ? "1st" : i === 1 ? "2nd" : i === 2 ? "3rd" : `${i + 1}th`
        } Passenger's Information Is Missing</span>`;

        Swal.fire({
          title: errorTitle,
          html: errorMessage,
          icon: "error",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        });

        return;
      }
    }

    const formData = new FormData();
    formData.append("bookingBody", JSON.stringify(bookingJson));

    for (let i = 0; i < allPassNo.length; i++) {
      const passNo = allPassNo[i];
      const image = selectedImage[i];
      const image2 = selectedImage2[i];

      formData.append(`${passNo}passportCopy`, image);
      formData.append(`${passNo}visaCopy`, image2);
    }

    const requestOptions = {
      method: "POST",
      body: formData,
    };

    const url = "https://api.rtc.flyfarint.com/v1.0/b2b/air/book/hold.php";

    setClicked(true);

    try {
      const response = await fetch(url, requestOptions);
      const data = await response.json();

      if (data.status === "success") {
        setClicked(false);
        // Handle success
        Swal.fire({
          imageUrl: bookingSuccess,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "Success",
          html: `<div>Thank you so much for booking a flight ticket with support@flyfarint.com.</div><strong>Please issue your booking ticket within the time limit specified,</strong> <div>otherwise your booking request will be automatically cancelled.</div>`,
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Ok",
        }).then(() => {
          navigate("/dashboard/airticketbooking");
        });
      } else {
        setClicked(false);
        Swal.fire({
          imageUrl: noFareFound,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: data?.message,
          html: "<div>Booking Failed. If you have any queries please contact us at <strong>support@rtctours.com or 01911101253</strong></div>",
          confirmButtonColor: "var(--primary-color)",
          confirmButtonText: "Please Try Another Flights.",
        }).then(() => {
          navigate(-1);
        });
      }
    } catch (error) {
      console.error("Error submitting the form:", error);
      setClicked(false);
    }
  };

  // todo:Validate a Users Phone number.
  const handleOpenDateState = (type, item, i) => {
    console.log(type, item, i);
    const tempFlightData = [...flightPassengerData.passengers];

    if (type === "ADT" || type === "CNN" || type === "INF") {
      const passengerType = type.toLowerCase();
      tempFlightData[i] = {
        ...tempFlightData[i],
        openDate: !item.openDate,
        openPassExDate: false,
      };

      setFlightPassengerData({
        ...flightPassengerData,
        passengers: tempFlightData,
      });
    }

    console.log(tempFlightData);
  };

  const handleOpenPassDateState = (type, index, item) => {
    const tempFlightData = [...flightPassengerData.passengers];

    if (type === "ADT" || type === "CNN" || type === "INF") {
      const passengerType = type.toLowerCase();
      tempFlightData[index] = {
        ...tempFlightData[index],
        openDate: false,
        openPassExDate: !tempFlightData[index].openPassExDate,
      };

      setFlightPassengerData({
        ...flightPassengerData,
        passengers: tempFlightData,
      });
    }
  };

  useEffect(() => {
    const mappedOptionPassengers = travelers.map((x, index) => {
      return {
        value: x,
        label: `Name:${x.f_name} ${x.l_name} Type:${x.type} Gender:${x.gender} Nation:${x.pass_nation} Dob:${x.dob} PassNo:${x.passport_no} PassEx${x.passport_expire}`,
      };
    });
    setOptionPassengers(mappedOptionPassengers);
  }, [travelers]);

  const handleAutoFill = (obj, index) => {
    const {
      dob,
      f_name,
      gender,
      id,
      l_name,
      passport_expire,
      pass_nation,
      passport_no,
      passport_copy,
      pax_id,
      type,
      visa_copy,
      prefix,
    } = obj;

    console.log(obj);

    const tempFlightData = [...flightPassengerData.passengers];

    if (index !== -1) {
      tempFlightData[index] = {
        ...tempFlightData[index],
        type,
        fName: f_name,
        lName: l_name,
        gender: gender,
        dob: new Date(dob).toLocaleDateString("sv"),
        passNation: pass_nation,
        passNo: passport_no,
        passEx: new Date(passport_expire).toLocaleDateString("sv"),
        prefix: prefix,
      };
    }

    setFlightPassengerData({
      ...flightPassengerData,
      passengers: tempFlightData,
    });
  };

  //todo: for remove autofill
  const removeAutoFill = (type, index) => {
    const tempFlightData = [...flightPassengerData.passengers];

    if (index !== -1) {
      tempFlightData[index] = {
        type,
        fName: "",
        lName: "",
        gender: "",
        phone: "",
        email: "",
        dob: "",
        passNation: "BD",
        passNo:
          flightData.triptype === "Inbound"
            ? Math.round(Math.random() * 100000000 + index)
            : "",
        passEx:
          flightData.triptype === "Inbound"
            ? format(new Date(dateAfterSixMonths), "yyyy-MM-dd")
            : format(new Date(dateAfterSixMonths), "yyyy-MM-dd"),
        openDate: false,
        openPassExDate: false,
        prefix: "",
      };
    }

    console.log(tempFlightData);

    setFlightPassengerData({
      ...flightPassengerData,
      passengers: tempFlightData,
    });
  };

  const handleMealChange = (e, type, index) => {
    setActiveMeal(e.target.value);
  };

  const handleWheelChange = (e, type, index) => {
    setActiveWheel(e.target.value);
  };

  let aCount = 0;
  let cCount = 0;
  let iCount = 0;

  return (
    <>
      {/**** adult Information ****/}
      <form onSubmit={handleSubmit}>
        <Accordion
          sx={{
            bgcolor: "var(--secondary-color)",
            border: "none",
            borderRadius: "5px 5px 0px 0px",
          }}
          expanded={expanded === "adultpanel"}
          onChange={handleChange("adultpanel")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography
              sx={{
                color: "var(--white)",
                fontSize: "14px",
                fontWeight: "500",
              }}
            >
              Passengers Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ bgcolor: "white" }}>
            {flightPassengerData.passengers.map((item, i) => {
              switch (item.type) {
                case "ADT":
                  aCount++;
                  break;
                case "CNN":
                  cCount++;
                  break;
                case "INF":
                  iCount++;
                  break;
                default:
                  break;
              }

              return (
                <>
                  <Box
                    key={i}
                    sx={{
                      bgcolor: "white",
                      p: {
                        xs: "10px",
                        sm: "10px",
                        md: "20px",
                        lg: "20px",
                      },
                    
                      borderRadius: "5px",
                      borderTop: "2px solid var(--primary-color)",
                      boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                    }}
                  >
                    <Typography
                      sx={{
                        bgcolor: "#D3E0F0",
                        fontWeight: "500",
                        borderRadius: "2px",
                        py: "4px",
                        textAlign: "center",
                        color: "var(--primary-color)",
                      }}
                    >
                      Passenger - {i + 1}
                    </Typography>
                    <Box sx={{ mt: "10px" }}>
                      <Stack
                        direction={{
                          xs: "column",
                          sm: "column",
                          md: "row",
                          lg: "row",
                        }}
                        alignItems={"center"}
                        justifyContent={"space-between"}
                      >
                        <Box
                          sx={{
                            width: {
                              xs: "100%",
                              sm: "100%",
                              md: "60%",
                              lg: "60%",
                            },
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: {
                                xs: "12px",
                                sm: "12px",
                                md: "14px",
                                lg: "14px",
                              },
                              color: "#148F77",
                              mb: {
                                xs: "10px",
                                sm: "10px",
                                md: "2px",
                                lg: "2px",
                              },
                              fontWeight: "500",
                            }}
                          >
                            Search From your Favorite Traveler List
                          </Typography>
                          <Select
                            placeholder="Select Traveler From Your List..."
                            onChange={(item) => {
                              handleAutoFill(item.value, i);
                            }}
                            options={optionPassengers.filter(
                              (passenger) => passenger.value.type === item.type
                            )}
                            noOptionsMessage={() => "No Travelers"}
                            styles={{ height: "25px" }}
                          />
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: {
                              xs: "140px",
                              sm: "140px",
                              md: "160px",
                              lg: "160px",
                            },
                            pt: "20px",
                          }}
                        >
                          <CancelIcon
                            sx={{
                              fontSize: "27px",
                              color: "red",
                              cursor: "pointer",
                            }}
                            onClick={() => removeAutoFill(item?.type, i)}
                          />
                          <Typography
                            sx={{
                              bgcolor: "#28282B",
                              color: "white",
                              p: "5px 20px",
                              borderRadius: "3px",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.type === "ADT"
                              ? `Adult - ${aCount}`
                              : item.type === "CNN"
                              ? `Child - ${cCount}`
                              : item.type === "INF"
                              ? `Infant - ${iCount}`
                              : ""}
                          </Typography>
                        </Box>
                      </Stack>
                    </Box>

                    <Grid key={i} container mt={2}>
                      <Grid item xs={12} md={12} className="commonClass">
                        <Box>
                          <RadioGroup
                            aria-label="prefix"
                            name="prefix"
                            value={item.prefix}
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            row
                          >
                            {item.type === "ADT" ? (
                              <>
                                <Box>
                                  <FormControlLabel
                                    value="MR"
                                    control={<Radio />}
                                    label="MR."
                                  />
                                </Box>

                                <FormControlLabel
                                  value="MRS"
                                  control={<Radio />}
                                  label="MRS."
                                />
                                <FormControlLabel
                                  value="MS"
                                  control={<Radio />}
                                  label="MS."
                                />
                              </>
                            ) : item.type === "CNN" ? (
                              <>
                                <FormControlLabel
                                  value="MSTR"
                                  control={<Radio />}
                                  label="MSTR."
                                />
                                <FormControlLabel
                                  value="MISS"
                                  control={<Radio />}
                                  label="MISS."
                                />
                              </>
                            ) : (
                              <>
                                <FormControlLabel
                                  value="MSTR"
                                  control={<Radio />}
                                  label="MSTR."
                                />
                                <FormControlLabel
                                  value="MISS"
                                  control={<Radio />}
                                  label="MISS."
                                />
                              </>
                            )}
                          </RadioGroup>
                          {item?.prefix === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Title must be required!
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box mt={3} pr={3}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Enter First / Given Name
                          </Typography>
                          <input
                            name="fName"
                            id="fName"
                            value={item.fName}
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              textTransform: "uppercase",
                              fontSize: "12px",
                            }}
                            placeholder="First & middle name"
                          />
                          {item?.fName === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *First Name must be required!
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box mt={3} pr={3}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Enter Last / Surname
                          </Typography>
                          <input
                            name="lName"
                            id="lName"
                            value={item.lName}
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              textTransform: "uppercase",
                              fontSize: "12px",
                            }}
                            placeholder="Last name"
                          />
                          {item?.lName === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Last Name must be required!
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box mt={3} pr={3} sx={{ position: "relative" }}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Enter Date of Birth
                          </Typography>
                          <input
                            // required
                            value={
                              item.dob
                                ? format(new Date(item.dob), "yyyy-MM-dd")
                                : ""
                            }
                            onClick={() =>
                              handleOpenDateState(item.type, item, i)
                            }
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              fontSize: "12px",
                            }}
                            placeholder="dd/mm/yyyy"
                          />
                          {item.openDate && (
                            <Box
                              sx={{
                                position: "absolute",
                                bgcolor: "white",
                                width: "100%",
                              }}
                            >
                              <Calendar
                                color={"#003566"}
                                onChange={(date) => {
                                  const tempFlightData = [
                                    ...flightPassengerData.passengers,
                                  ];
                                  const selectedDate = new Date(date);
                                  tempFlightData[i] = {
                                    ...tempFlightData[i],
                                    dob: moment(selectedDate).format(
                                      "YYYY-MM-DD"
                                    ),
                                    openDate: false,
                                  };
                                  setFlightPassengerData({
                                    ...flightPassengerData,
                                    passengers: tempFlightData,
                                  });
                                }}
                                months={1}
                                maxDate={
                                  item.type === "ADT"
                                    ? new Date(dateBeforeTwelveYears)
                                    : item.type === "CNN"
                                    ? new Date(dateBeforeTwoYears)
                                    : new Date()
                                }
                                minDate={
                                  item.type === "CNN"
                                    ? new Date(dateBeforeTwelveYears)
                                    : item.type === "INF"
                                    ? new Date(dateBeforeTwoYears)
                                    : undefined
                                }
                                className="user-info-calendar"
                              />
                            </Box>
                          )}

                          {item?.dob === "" && item?.type === "ADT" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Age must be more than 12+ years!
                            </Typography>
                          )}
                          {item?.dob === "" && item?.type === "CNN" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Age must be more than 2+ years!
                            </Typography>
                          )}
                          {item?.dob === "" && item?.type === "INF" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Age must be less than 2 years!
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box mt={3} pr={3}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Select Gender
                          </Typography>
                          <select
                            className="user-info-select"
                            // required
                            name="gender"
                            id="gender"
                            value={item.gender}
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              textTransform: "uppercase",
                              paddingBottom: "5px",
                              borderBottom: "2px solid #B6B6CC",
                            }}
                          >
                            <option value="">Gender</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                          </select>
                          {item?.gender === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Gender must be required !
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box mt={3} pr={3}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Frequent Flyer
                          </Typography>
                          <input
                            name="flyer"
                            id="flyer"
                            // value={item.lName}
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            style={{
                              marginTop: "5px",
                              width: "100%",
                              color: "#2156A6",
                              textTransform: "uppercase",
                            }}
                            placeholder="Frequent Flyer"
                          />
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box mt={3} pr={3}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Select Nationality
                          </Typography>
                          <select
                            name="passNation"
                            id="passNation"
                            // required
                            value={item?.passNation}
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            className="user-info-select"
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              textTransform: "uppercase",
                              paddingBottom: "5px",
                              borderBottom: "2px solid #B6B6CC",
                            }}
                          >
                            <option value="">Select Nationality</option>
                            {CountryList.map((country, i) => (
                              <option key={i} value={country.code}>
                                {country.name}
                              </option>
                            ))}
                          </select>
                          {item?.passNation === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Nationality must be required !
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4}>
                        <Box mt={3} pr={3}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Enter Passport Number
                          </Typography>
                          <input
                            name="passNo"
                            value={item?.passNo}
                            placeholder="xx-xxxxxxx"
                            pattern="^[a-zA-Z0-9]*$"
                            // required
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              fontSize: "12px",
                              textTransform: "uppercase",
                            }}
                          />
                          {item?.passNo === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Passport Number must be required !
                            </Typography>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} md={4} sx={{ position: "relative" }}>
                        <Box mt={3} pr={3}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Enter Passport Expire Date
                          </Typography>
                          <input
                            name="passEx"
                            required
                            value={format(new Date(item.passEx), "yyyy-MM-dd")}
                            onClick={() =>
                              handleOpenPassDateState(item.type, i, item)
                            }
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              padding: "9px 0",
                              fontSize: "12px",
                            }}
                            placeholder="dd/mm/yyyy"
                          />
                          {item?.passEx ===
                            `${format(
                              new Date(dateAfterSixMonths),
                              "yyyy-MM-dd"
                            )}` && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              *Passport Expire Date must be required!
                            </Typography>
                          )}

                          {item.openPassExDate && (
                            <Calendar
                              color={"#003566"}
                              onChange={(date) => {
                                const tempFlightData = [
                                  ...flightPassengerData.passengers,
                                ];
                                tempFlightData[i] = {
                                  ...tempFlightData[i],
                                  passEx: new Date(date).toLocaleDateString(
                                    "sv"
                                  ),
                                  openPassExDate: false,
                                };
                                setFlightPassengerData({
                                  ...flightPassengerData,
                                  passengers: tempFlightData,
                                });
                              }}
                              months={1}
                              minDate={new Date(dateAfterSixMonths)}
                              className="user-info-calendar"
                            />
                          )}
                        </Box>
                      </Grid>

                      <Grid
                        item
                        mt={2}
                        md={12}
                        sx={{
                          bgcolor: "white",
                          height: "auto",
                          borderRadius: "3px",
                          mt: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            color: "#148F77",
                            mb: "2px",
                            fontWeight: "500",
                            mt: "10px",
                          }}
                        >
                          Passport Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box mt={1} pr={3} sx={{ position: "relative" }}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Passport Copy
                          </Typography>
                          <Box
                            sx={{
                              border: "1px dotted #BCC9DC",
                              borderRadius: "3px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              mt: "10px",
                              pb: "15px",
                            }}
                          >
                            {/* image preview */}
                            <Box
                              sx={{
                                height: "118px",
                                width: "200px",
                                bgcolor: "#EBF0F4",
                                borderRadius: "5px",
                                mt: "20px",
                              }}
                            >
                              {passengerPassCopy[i] &&
                              passengerPassCopy[i] instanceof File ? (
                                <img
                                  src={URL.createObjectURL(
                                    passengerPassCopy[i]
                                  )}
                                  alt="Selected"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "5px",
                                  }}
                                />
                              ) : (
                                <DriveFolderUploadOutlinedIcon
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    color: "#148F77",
                                  }}
                                />
                              )}
                            </Box>

                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                my: "15px",
                                color: "var(--primary-color)",
                                fontWeight: "500",
                                px: "10px",
                              }}
                            >
                              Upload a photo of the front page of your passport
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                textAlign: "center",
                                my: "5px",
                                color: "",
                                fontWeight: "500",
                                px: "10px",
                              }}
                            >
                              Select Here
                            </Typography>

                            <input
                              type="file"
                              id="passCopy"
                              name="passCopy"
                              accept="image/*"
                              className="customFileType"
                              style={{
                                backgroundColor: "#2564B8",
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                                padding: "3.5px 11px",
                                boxSizing: "border-box",
                                borderRadius: "3px",
                                width: "50%",
                                fontSize: "13px",
                                display: "flex",
                              }}
                              onChange={(e) => {
                                handleOnChange(e, item.type, i);
                                handleImageChange(e);
                              }}
                            />
                            {item?.passCopy ? (
                              ""
                            ) : passCopyErr ? (
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "red",
                                  mt: "2px",
                                }}
                              >
                                {" "}
                                Passport Copy must be required!
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  fontSize: "12px",
                                  color: "red",
                                  mt: "2px",
                                }}
                              >
                                {" "}
                                Passport Copy must be required!
                              </Typography>
                            )}
                            <Typography sx={{ fontSize: "10px", mt: "6px" }}>
                              JPG/JPEG/PNG
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box mt={1} pr={3} sx={{ position: "relative" }}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Visa Copy
                          </Typography>
                          <Box
                            sx={{
                              border: "1px dotted #BCC9DC",
                              borderRadius: "3px",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              mt: "10px",
                              pb: "15px",
                            }}
                          >
                            {/* image preview */}
                            <Box
                              sx={{
                                height: "118px",
                                width: "200px",
                                bgcolor: "#EBF0F4",
                                borderRadius: "5px",
                                mt: "20px",
                              }}
                            >
                              {passengerVisaCopy[i] &&
                              passengerVisaCopy[i] instanceof File ? (
                                <img
                                  src={URL.createObjectURL(
                                    passengerVisaCopy[i]
                                  )}
                                  alt="Selected"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    borderRadius: "5px",
                                  }}
                                />
                              ) : (
                                <DriveFolderUploadOutlinedIcon
                                  sx={{
                                    width: "100%",
                                    height: "100px",
                                    color: "#148F77",
                                  }}
                                />
                              )}
                            </Box>
                            <Typography
                              sx={{
                                fontSize: "12px",
                                textAlign: "center",
                                my: "15px",
                                color: "var(--primary-color)",
                                fontWeight: "500",
                                px: "10px",
                              }}
                            >
                              Upload a photo of the front page of your visa
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                textAlign: "center",
                                my: "5px",
                                color: "",
                                fontWeight: "500",
                                px: "10px",
                              }}
                            >
                              Select Here
                            </Typography>
                            <input
                              type="file"
                              id="visaCopy"
                              name="visaCopy"
                              accept="image/*"
                              // required
                              className="customFileType"
                              style={{
                                backgroundColor: "#2564B8",
                                color: "red",
                                display: "flex",
                                alignItems: "center",
                                padding: "3.5px 11px",
                                boxSizing: "border-box",
                                borderRadius: "3px",
                                width: "50%",
                                fontSize: "13px",
                                display: "flex",
                              }}
                              onChange={(e) => {
                                handleOnChange(e, item.type, i);
                                handleImageChange2(e);
                              }}
                            />
                            <Typography sx={{ fontSize: "10px", mt: "6px" }}>
                              JPG/JPEG/PNG
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>

                      <Grid
                        item
                        mt={2}
                        md={12}
                        sx={{
                          bgcolor: "white",
                          height: "auto",
                          borderRadius: "3px",
                          mt: "25px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "16px",
                            color: "#148F77",
                            mb: "2px",
                            fontWeight: "500",
                            mt: "10px",
                          }}
                        >
                          Emergency Contact Information
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box mt={1} pr={3} sx={{ position: "relative" }}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Email
                          </Typography>
                          <input
                            name="email"
                            id="email"
                            value={item.email}
                            // required
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              fontSize: "12px",
                            }}
                            placeholder="Write your email..."
                          />
                          {item.email === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              Email must be required !
                            </Typography>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Box mt={1} pr={3} sx={{ position: "relative" }}>
                          <Typography
                            sx={{ fontSize: "14px", color: "#444542" }}
                          >
                            Phone Number
                          </Typography>
                          <input
                            name="phone"
                            id="phone"
                            value={item.phone}
                            // required
                            onChange={(e) => handleOnChange(e, item.type, i)}
                            style={{
                              marginTop: "10px",
                              width: "100%",
                              color: "#2156A6",
                              fontSize: "12px",
                            }}
                            placeholder="Write your phone..."
                          />
                        </Box>
                        {item.phone === "" && (
                          <Typography sx={{ fontSize: "12px", color: "red" }}>
                            Phone Number must be required !
                          </Typography>
                        )}
                      </Grid>

                      <Grid
                        item
                        md={12}
                        sx={{
                          bgcolor: "white",
                          height: "auto",
                          borderRadius: "3px",
                          mt: "25px",
                        }}
                      >
                        <Accordion
                          expanded={mexpanded === "panel1"}
                          onChange={mealhandleChange("panel1")}
                          sx={{ borderRadius: "4px" }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography
                              sx={{ color: "green", fontSize: "14px" }}
                            >
                              Meal Request Option (GDS)
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RadioGroup
                              aria-label="meal"
                              name="meal"
                              value={activeMeal}
                              sx={{ pl: "20px" }}
                            >
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "none"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "none"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="none"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "none" ? "500" : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    None
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "asian veg meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "asian veg meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="asian veg meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "asian veg meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Asian Veg Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "baby meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "baby meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="baby meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "baby meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Baby Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "child meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "child meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="child meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "child meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Child Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "diabetic meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "diabetic meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="muslim meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "diabetic meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Diabetic Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "fruit platter"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "fruit platter"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="fruit platter"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "fruit platter"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Fruit Platter
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "glutent intolerance"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "glutent intolerance"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="glutent intolerance"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "glutent intolerance"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Glutent Intolerance
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "hindu meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "hindu meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="hindu meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "hindu meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Hindu Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "kosher"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "kosher"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="kosher"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "kosher" ? "500" : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Kosher
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "low calorie"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "low calorie"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="low calorie"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "low calorie"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Low Calorie
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "low fat meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "low fat meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="low fat meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "low fat meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Low Fat Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "low salt meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "low salt meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="low salt meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "low salt meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Low Salt Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "muslim meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "muslim meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="muslim meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "muslim meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Muslim Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "low lactose meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "low lactose meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="low lactose meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "low lactose meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Low Lactose Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "veg raw meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "veg raw meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="veg raw meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "veg raw meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Veg Raw Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "seafood meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "seafood meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="seafood meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "seafood meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Seafood Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "special meal request"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "special meal request"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="special meal request"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "special meal request"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Special Meal Request
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "veg vegan meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "veg vegan meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="veg vegan meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "veg vegan meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Veg Vegan Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "veg jain meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "veg jain meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="veg jain meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "veg jain meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Veg Jain Meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "veg lacto-ovo"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "veg lacto-ovo"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="veg lacto-ovo"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "veg lacto-ovo"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Veg Lacto- Ovo
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal === "veg oriental meal"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal === "veg oriental meal"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="veg oriental meal"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal === "veg oriental meal"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    veg oriental meal
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleMealChange(e, item.type, i);
                                }}
                              />
                            </RadioGroup>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>

                      <Grid
                        item
                        md={12}
                        sx={{
                          bgcolor: "white",
                          height: "auto",
                          borderRadius: "3px",
                          mt: "15px",
                        }}
                      >
                        <Accordion
                          expanded={mexpanded === "panel2"}
                          onChange={mealhandleChange("panel2")}
                          sx={{ borderRadius: "4px" }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography
                              sx={{ color: "green", fontSize: "14px" }}
                            >
                              Request Wheel Chair
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <RadioGroup
                              aria-label="wheelChair"
                              name="wheelChair"
                              value={activeWheel}
                              sx={{ pl: "20px" }}
                            >
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeWheel === "no"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeWheel === "no"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="no"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeWheel === "none" ? "500" : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    No
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeWheel ===
                                    "passenger can not walk short distance up or down stairs"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeWheel ===
                                    "passenger can not walk short distance up or down stairs"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="passenger can not walk short distance up or down stairs"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeWheel ===
                                        "passenger can not walk short distance up or down stairs"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Passenger can not walk short distance up or
                                    down stairs
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeWheel ===
                                    "passenger can not walk short distance, but not up or down stairs"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeWheel ===
                                    "passenger can not walk short distance, but not up or down stairs"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="passenger can not walk short distance, but not up or down stairs"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeWheel ===
                                        "passenger can not walk short distance, but not up or down stairs"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Passenger can not walk short distance, but
                                    not up or down stairs
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeWheel ===
                                    "passenger cannot walk any distance and will require the aisle chair to board"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeWheel ===
                                    "passenger cannot walk any distance and will require the aisle chair to board"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="passenger cannot walk any distance and will require the aisle chair to board"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeWheel ===
                                        "passenger cannot walk any distance and will require the aisle chair to board"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Passenger cannot walk any distance and will
                                    require the aisle chair to boardl
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeWheel ===
                                    "on board aisle wheelchair requested"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeWheel ===
                                    "on board aisle wheelchair requested"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="on board aisle wheelchair requested"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeWheel ===
                                        "on board aisle wheelchair requested"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    On board aisle wheelchair requested
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeWheel ===
                                    "passenger is traveling with a manual wheelchair"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeWheel ===
                                    "passenger is traveling with a manual wheelchair"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="passenger is traveling with a manual wheelchair"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeWheel ===
                                        "passenger is traveling with a manual wheelchair"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Passenger is traveling with a manual
                                    wheelchair
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />
                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeWheel ===
                                    "passenger is traveling with a dry cell battery-powered wheelchair"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeWheel ===
                                    "passenger is traveling with a dry cell battery-powered wheelchair"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="passenger is traveling with a dry cell battery-powered wheelchair"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeWheel ===
                                        "passenger is traveling with a dry cell battery-powered wheelchair"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Passenger is traveling with a dry cell
                                    battery-powered wheelchair
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />

                              <FormControlLabel
                                sx={{
                                  bgcolor:
                                    activeMeal ===
                                    "passenger is traveling with a wet cell battery powered wheelchair"
                                      ? "#EDF5FF"
                                      : "#F5F7FA",
                                  borderRadius: "2px",
                                  mb: "10px",
                                  border:
                                    activeMeal ===
                                    "passenger is traveling with a wet cell battery powered wheelchair"
                                      ? "1px solid #D4E8FF"
                                      : "1px solid #F5F7FA",
                                }}
                                value="passenger is traveling with a wet cell battery powered wheelchair"
                                control={<Radio />}
                                label={
                                  <span
                                    style={{
                                      fontWeight:
                                        activeMeal ===
                                        "passenger is traveling with a wet cell battery powered wheelchair"
                                          ? "500"
                                          : "400",
                                      fontSize: "15px",
                                    }}
                                  >
                                    Passenger is traveling with a wet cell
                                    battery powered wheelchair
                                  </span>
                                }
                                onChange={(e) => {
                                  handleOnChange(e, item.type, i);
                                  handleWheelChange(e, item.type, i);
                                }}
                              />
                            </RadioGroup>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                      <Grid
                        item
                        md={12}
                        sx={{
                          bgcolor: "white",
                          height: "auto",
                          borderRadius: "3px",
                          mt: "15px",
                        }}
                      >
                        <Accordion
                          expanded={mexpanded === "panel3"}
                          onChange={mealhandleChange("panel3")}
                          sx={{ borderRadius: "4px" }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                          >
                            <Typography
                              sx={{ color: "green", fontSize: "14px" }}
                            >
                              VIP Message
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <textarea
                              onChange={(e) => handleOnChange(e, item.type, i)}
                              name="vipMessage"
                              value={item.vipMessage}
                              style={{
                                width: "100%",
                                resize: "none",
                                height: "100px",
                                borderRadius: "3px",
                                border: "1px solid #D4E8FF",
                                padding: "15px",
                              }}
                              rows="4"
                              cols="50"
                              placeholder="Write Message (max 120 characters)"
                              maxLength={120}
                            ></textarea>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box
                    sx={{
                      height: "2px",
                      width: "100%",
                      border: "1px dashed #148F77",
                      my: "20px",
                    }}
                  ></Box>
                </>
              );
            })}
          </AccordionDetails>
        </Accordion>

        <Button
          type="submit"
          disabled={clicked ? true : false}
          sx={{
            bgcolor: "var(--secondary-color)",
            width: "100%",
            fontSize: "17px",
            fontWeight: "500",
            color: "white",
            mt: "25px",
            ":hover": {
              bgcolor: "var(--secondary-color)",
            },
          }}
        >
          {/* {clicked ? "Please Wait...." : " Book & Hold"} */}
          Book & Hold
        </Button>
      </form>
      <Box sx={{ bgcolor: "#A4A8A7 ", height: "1px", width: "100%" }}></Box>
    </>
  );
};

export default MultiCityUserInfo;
