import {
  Box,
  Container,
  Typography,
  Grid,
  Stack,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  Table,
  TableContainer,
  Paper,
  Button,
  Modal,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import FlightCards from "../FlightCards/FlightCards";
import FlightDetails from "./FlightDetails";
import moment from "moment";
import secureLocalStorage from "react-secure-storage";
import { format } from "date-fns";
import FileUploadSection from "../Shared/FileUploadSection/FileUploadSection";
import Swal from "sweetalert2";
import axios from "axios";
import OnewayFlightDetails from "../OnewayFlightDetails/OnewayFlightDetails";
import ReturnFlightDetails from "./ReturnFlightDetails";
import TaskIcon from "@mui/icons-material/Task";
import AllPDFButtons from "../AllPDFButtons/AllPDFButtons";
import MultiCityFlightDetails from "../MultiCityFlightDetails/MultiCityFlightDetails";
import LocalDiningIcon from "@mui/icons-material/LocalDining";
import AccessibleIcon from "@mui/icons-material/Accessible";
import QuickreplyIcon from "@mui/icons-material/Quickreply";
import { Triangle } from "react-loader-spinner";
import UserHeader from "../UserPage/UserHeader/UserHeader";
const isMobile = window.innerWidth <= 768;

const companyInfo = {
  name: "Fly Far International",
  email: "support@flyfarint.com",
  phone: "09606-912912",
  address:
    "1229, Hazi Abdul Latif Mansion, 2nd Floor, Ka-9, Bashundhara Rd, Dhaka",
};

const styles = {
  button: {
    padding: "6px 20px",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "var(--primary-color)",
    border: "none",
    cursor: "pointer",
  },
  buttonDisabled: {
    padding: "6px 20px",
    marginRight: "20px",
    color: "#fff",
    backgroundColor: "var(--primary-color)",
    border: "none",
    cursor: "not-allowed",
  },
};

const updateModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", sm: "70%", md: "50%" },
  bgcolor: "#fff",
  p: 4,
};

const Congratulation = () => {
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const userId = user?.agent_id;
  const [data, setData] = useState({});
  const navigate = useNavigate();
  const [invoiceId, setInvoiceId] = useState([]);
  const [date, setDate] = useState(format(new Date(), "dd-MMM-yyyy"));
  const [isDisabled, setIsDisabled] = useState(false);
  const [state, setState] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [refetch, setRefetch] = useState(false);
  const todaydate = new Date().getDate();
  const [isLoading, setIsLoading] = useState(true);
  const [openModalVoid, setOpenModalVoid] = useState(false);
  const [openModalReIssue, setOpenModalReIssue] = useState(false);
  const [openModalRefund, setOpenModalRefund] = useState(false);
  const [stateChange, setStateChange] = useState(false);
  const handleCloseUpdateModal = () => {
    setState((prev) => !prev);
    setOpenUpdateModal(false);
  };
  const handleOpenUpdateModal = () => setOpenUpdateModal(true);
  const [expanded, setExpanded] = useState("panel1");
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const users = secureLocalStorage.getItem("user-info");

  const agentId = users?.agent_id;
  const staffId = users?.staff_id;
  const bookingId = location.state.bookingId;

 

  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/b2b/air/queues/index.php?agentId=${agentId}&bookingId=${bookingId}`
    )
      .then((res) => res.json())
      .then((data) => {
   
        setData(data);
        setInvoiceId(data?.tickets);
      });
  }, [refetch]);

 

  const voideDate = new Date(data?.booking?.last_updated);

 

  const [requestModal, setRequestModal] = useState({
    modal: false,
    value: "",
  });

  let refundVoidBody = JSON.stringify({
    agentId: agentId || "",
    userId: "",
    bookingId: bookingId || "bookingId",
    passengerData: data?.tickets,
    actionBy: agentId ? "Agent" : "Staff",
  });

  let reissueBody = JSON.stringify({
    agentId: agentId || "",
    bookingId: bookingId || "bookingData",
  });

  const bookingInfo = [
    {
      text: "Destination",
      value: `${data?.booking?.dept_from}-${data?.booking?.arrive_to}`,
    },
    {
      text: "Travel Type",
      value: data?.booking?.trip_type,
    },
    {
      text: "Booked By",
      value: "User",
    },
    {
      text: "Booked At",
      value: `${moment(data?.booking?.booked_at).format(
        "DD MMM YYYY hh:mm:ss A"
      )}`,
    },
  ];
 
  const pricebreakdown = [
    {
      type: "ADT",
      name: "Adult",
      paxCount: data?.booking?.adult_count,
      baseCost: data?.booking?.adt_base,
      tax: data?.booking?.adt_tax,
    },
    {
      type: "CNN",
      name: "Child",
      paxCount: data?.booking?.child_count,
      baseCost: data?.booking?.child_base,
      tax: data?.booking?.child_tax,
    },
    {
      type: "INF",
      name: "Infant",
      paxCount: data?.booking?.infant_count,
      baseCost: data?.booking?.inf_base,
      tax: data?.booking?.inf_tax,
    },
  ];

  const handleRefundReissueVoid = async (e) => {
    setIsLoading(false);
    e.preventDefault();

    await fetch(
      // `https://api.rtc.flyfarint.com/v1.0/b2b/air/book/${requestModal?.value}.php`,
      `https://api.rtc.flyfarint.com/v1.0/b2b/air/book/${requestModal?.value}.php`,
      {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
        },

        body: `${
          requestModal?.value === "Reissue" ? reissueBody : refundVoidBody
        }`,
      }
    )
      .then((res) => res.json())
      .then((data) => {
        setOpenModalVoid(false);
        setOpenModalReIssue(false);
        setOpenModalRefund(false);
        setRequestModal({ modal: false });

        if (data?.status === "success") {
          setIsLoading(false);
          Swal.fire({
            icon: "success",
            title: `${data?.message}`,
            html: `For any query.Please contact us at <strong>reservation@rtctourtravels.com</strong> or <strong>${companyInfo?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            setStateChange(true);
            setRequestModal({ modal: false });
            setRefetch(!refetch);
          });
        } else {
          setIsLoading(false);
          Swal.fire({
            icon: "error",
            title: `${data?.message}`,
            html: `For any query.Please contact us at <strong>reservation@rtctourtravels.com</strong> or <strong>${companyInfo?.phone}</strong>`,
            confirmButtonText: "OK",
          }).then(function () {
            setStateChange(true);
            setRequestModal({ modal: false });
          });
        }
      })
      .catch((err) => {
        console.log(err?.message);
      });
    // e.target.reset();
  };

 

  const handleCancleBooking = () => {
    const body = {
      bookingId: data?.booking?.booking_id,
      platform: "B2C",
      cancelBy: agentId,
    };
    console.log(body);
    Swal.fire({
      imageWidth: 400,
      imageHeight: 200,
      imageAlt: "Custom image",
      title: `Confiramation`,
      text: `Do you want to cancel?`,
      confirmButtonColor: "var(--primary-color)",
      confirmButtonText: "Ok",
    }).then((res) => {
      if (res.isConfirmed) {
        axios
          .post(
            "https://api.rtc.flyfarint.com/v1.0/b2b/air/book/cancel.php",
            JSON.stringify(body),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((res) => {
           
            if (res?.data?.status === "success") {
              Swal.fire({
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${res?.data?.status}`,
                text: `${res?.data?.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(() => {
                navigate(0);
              });
            }
          });
      }
    });
  };

  async function handleIssueTicket() {
    let issueData;

    try {
      const willIssue = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to issue this ticket?",
        showCancelButton: true,
        confirmButtonColor: "var(--primary-color)",
        confirmButtonText: "Yes, Issue it!",
        cancelButtonColor: "red",
        cancelButtonText: "Don't Issue it!",
      });

      if (willIssue.isConfirmed) {
        // Issue with bonus
        issueData = {
          agentId: agentId || "",
          bookingId: bookingId || "BookingId",
          staffId: "",
          via: "web",
          bonus: "false",
          userId: "",
          platform: "B2B",
        };

        const url = "https://api.rtc.flyfarint.com/v1.0/b2b/air/book/issue.php";
        const body = JSON.stringify(issueData);

        const response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: body,
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        if (data.status === "success") {
          await Swal.fire({
            title: "Issue Ticket Request In Process",
            html: `Your issue ticket request has been submitted successfully. Please wait for a response. If you do not receive any email, please contact us at <strong>${companyInfo?.email}</strong> or call <strong>${companyInfo?.phone}</strong>`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          });

          // Reload the page
          window.location.reload();
        } else {
          await Swal.fire({
            title: `${data?.booking?.status}`,
            html: `${data?.message}`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
          });
        }
      } else {
        // Handle the case where the user cancels the action
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error with Swal.fire() if needed
    }
  }

  return (
    <Box>
      <UserHeader />
      <Box
        sx={{
          borderRadius: "3px",
          bgcolor: "white",
          width: "90%",
          m: "0 auto",
          mt: "-8vh",
          ".MuiTypography-body2": {
            fontSize: "13px",
          },
          ".MuiTypography-title-lg": {
            fontSize: "24px",
            color: "#444542",
            fontWeight: "500",
          },
          ".MuiTypography-h6": {
            fontSize: "16px",
            color: "#444542",
            fontWeight: "500",
          },
          ".MuiTypography-caption": {
            fontSize: "11px",
            color: "#ACA6A6",
          },
          ".MuiTypography-subtitle1": {
            fontSize: "11px",
            color: "#2156A6",
          },
          ".MuiAccordion-root:before": {
            bgcolor: "white",
          },
          p: "25px",
        }}
      >
        {data?.booking?.trip_type ? (
          <Box
            sx={{
              // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              borderRadius: "3px",
              bgcolor: "re",
              mb: 20,
              mt: "20px",
              ".MuiTypography-body2": {
                fontSize: "13px",
              },
              ".MuiTypography-title-lg": {
                fontSize: "24px",
                color: "#444542",
                fontWeight: "500",
              },
              ".MuiTypography-h6": {
                fontSize: "16px",
                color: "#444542",
                fontWeight: "500",
              },
              ".MuiTypography-caption": {
                fontSize: "11px",
                color: "#ACA6A6",
              },
              ".MuiTypography-subtitle1": {
                fontSize: "11px",
                color: "#2156A6",
              },
              ".MuiTableRow-root": {
                borderBottom: "1px solid #e0e0e0",
              },
              ".MuiTableCell-root": {
                border: "none",
                height: "100%",
                padding: "14px",
                fontSize: "13px",
              },
              ".MuiAccordionSummary-root": {
                minHeight: "30px",
              },
            }}
          >
            <Grid
              justifyContent={"space-between"}
              container
              // gap={1}
              sx={{ p: "0px", bgcolor: "re" }}
            >
              <Grid item xs={12} lg={9}>
                {/* Booking details start */}

                <Typography
                  sx={{
                    color: "#2156A6",
                    p: "10px",
                    pl: "17px",
                    fontWeight: 500,
                    fontSize: "15px",
                    bgcolor: "#a3c7ff3b",
                    borderRadius: "7px 7px 0px 0px",
                  }}
                >
                  Booking Details
                </Typography>
                {isMobile ? (
                  <>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        sx={{
                          display: {
                            xs: "block",
                            sm: "flex",
                            md: "flex",
                          },
                          mt: "20px",
                          mb: "20px",
                          transition: "all .5s ease-in-out",
                          background: "#a3c7ff3b",
                          borderRadius: "5px",
                          width: "100%",
                          borderRadius: "5px",
                          px: "20px",
                          py: "25px",
                        }}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "11px",
                                display: "flex",
                                alignItems: "center",
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              Booking Id
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              {bookingId}
                            </Typography>
                          </Box>

                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mt: "30px",
                            }}
                            gap={1}
                          >
                            <Typography
                              sx={{
                                color: "var(--mattblack)",
                                fontWeight: 500,
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "14px",
                                },
                              }}
                              noWrap
                            >
                              Status
                            </Typography>
                            <Typography
                              sx={{
                                color: "var(--mattblack)",
                                fontWeight: 500,
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "14px",
                                },
                              }}
                              noWrap
                            >
                              {data?.booking?.status}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              mt: "10px",
                            }}
                            gap={1}
                          >
                            <Typography
                              sx={{
                                color: "var(--mattblack)",
                                fontWeight: 500,
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "14px",
                                },
                              }}
                              noWrap
                            >
                              Route
                            </Typography>
                            <Typography
                              sx={{
                                color: "var(--mattblack)",
                                fontWeight: 500,
                                fontSize: {
                                  xs: "12px",
                                  sm: "13px",
                                  md: "14px",
                                },
                              }}
                              noWrap
                            >
                              {data?.booking?.trip_type === "oneway"
                                ? `${data?.booking?.dept_from} - ${data?.booking?.arrive_to}`
                                : data?.booking?.trip_type === "multicity"
                                ? [
                                    ...new Array(
                                      data?.booking?.dept_from?.split(
                                        ","
                                      )?.length
                                    ),
                                  ]
                                    .map(
                                      (item, i) =>
                                        `${
                                          data?.booking?.dept_from?.split(",")[
                                            i
                                          ]
                                        },${
                                          data?.booking?.arrive_to?.split(",")[
                                            i
                                          ]
                                        }`
                                    )
                                    .join("-")
                                : `${data?.booking?.dept_from},${data?.booking?.arrive_to}-${data?.booking?.dept_from}`}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: "var(--mattblack)",
                                fontWeight: 500,
                              }}
                            >
                              Type
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: "var(--mattblack)",
                                fontWeight: 500,
                              }}
                            >
                              {data?.booking?.trip_type?.toUpperCase()}
                            </Typography>
                          </Box>

                          <Grid
                            item
                            direction={{ xs: "row", sm: "row", md: "row" }}
                            spacing={1}
                            alignItems="flex-start"
                            sx={{
                              width: "100%",
                              height: "100%",
                              // padding: {
                              //   md: "8px",
                              //   xs: "0px",
                              // },
                              mt: "30px",
                              pb: "14px",
                              borderTop: "1px solid var(--primary-color)",
                              // borderTop: {
                              //   xs: "none",
                              //   sm: "1px solid var(--primary-color)",
                              // },
                              pl: { xs: 2, sm: 2 },
                            }}
                          ></Grid>

                          <Box
                            sx={{
                              display: "flex",
                              pt: "5px",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Fare Type
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              {data?.booking?.refundable === "yes" ? (
                                <Typography
                                  style={{
                                    color: "green",
                                    fontSize: "13px",
                                  }}
                                >
                                  Refundable
                                </Typography>
                              ) : (
                                <Typography
                                  style={{
                                    color: "red",
                                    fontSize: "13px",
                                  }}
                                >
                                  Non Refundable
                                </Typography>
                              )}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              pt: "5px",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography sx={{ fontSize: "13px" }}>
                              Passengers
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "11px",
                                fontWeight: "bold",
                                color: "var(--primary-color)",
                              }}
                            >
                              {data?.passengers?.length}
                            </Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Box sx={{ mb: "20px" }}>
                      <TableContainer
                        component={Paper}
                        sx={{ boxShadow: "none" }}
                      >
                        <Table>
                          <TableHead sx={{ bgcolor: "var(--secondary-color)" }}>
                            <TableRow>
                              <TableCell style={{ paddingLeft: "17px" }}>
                                Booking ID
                              </TableCell>
                              <TableCell>Status</TableCell>
                              <TableCell>Route</TableCell>
                              <TableCell>Type</TableCell>
                              <TableCell>Fare Type</TableCell>
                              <TableCell>Passengers</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell style={{ paddingLeft: "17px" }}>
                                {bookingId}
                              </TableCell>
                              <TableCell>{data?.booking?.status}</TableCell>

                              <TableCell>
                                {data?.booking?.trip_type === "oneway"
                                  ? `${data?.booking?.dept_from} - ${data?.booking?.arrive_to}`
                                  : data?.booking?.trip_type === "multicity"
                                  ? [
                                      ...new Array(
                                        data?.booking?.dept_from?.split(
                                          ","
                                        )?.length
                                      ),
                                    ]
                                      .map(
                                        (item, i) =>
                                          `${
                                            data?.booking?.dept_from?.split(
                                              ","
                                            )[i]
                                          },${
                                            data?.booking?.arrive_to?.split(
                                              ","
                                            )[i]
                                          }`
                                      )
                                      .join("-")
                                  : `${data?.booking?.dept_from},${data?.booking?.arrive_to}-${data?.booking?.dept_from}`}
                              </TableCell>
                              <TableCell>
                                {data?.booking?.trip_type?.toUpperCase()}
                              </TableCell>
                              <TableCell>
                                {data?.booking?.refundable === "yes" ? (
                                  <Typography
                                    style={{
                                      color: "green",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Refundable
                                  </Typography>
                                ) : (
                                  <Typography
                                    style={{
                                      color: "red",
                                      fontSize: "13px",
                                    }}
                                  >
                                    Non Refundable
                                  </Typography>
                                )}
                              </TableCell>
                              <TableCell>{data?.passengers?.length}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Box>
                  </>
                )}

                {/* Booking details ends */}

                {/* Flight details start */}
                <Typography
                  sx={{
                    color: "#2156A6",
                    p: "10px",
                    pl: "17px",
                    fontWeight: 500,
                    fontSize: "15px",
                    bgcolor: "#a3c7ff3b",
                    borderRadius: "7px 7px 0px 0px",
                  }}
                >
                  Flight Details
                </Typography>

                <Grid item xs={12} container>
                  <Box
                    bgcolor={"white"}
                    sx={{
                      ".MuiTypography-body2": {
                        fontSize: "13px",
                      },
                      ".MuiTypography-title-lg": {
                        fontSize: "24px",
                        color: "#444542",
                        fontWeight: "500",
                      },
                      width: "100%",

                      p: { xs: "0px", sm: "0px", md: "25px", lg: "25px" },
                      borderRadius: "7px",
                    }}
                  >
                    {data?.booking?.trip_type === "oneway" ? (
                      <Box sx={{ width: "100%" }}>
                        <OnewayFlightDetails
                          data={data}
                          // flightData={data?.flightData}
                        />
                      </Box>
                    ) : data?.booking?.trip_type === "multicity" ? (
                      <Box>
                        <MultiCityFlightDetails data={data} />
                      </Box>
                    ) : (
                      <Box>
                        <ReturnFlightDetails
                          data={data}
                          // flightData={data?.flightData}
                        />
                      </Box>
                    )}

                    {/*  flight information End  */}
                  </Box>
                </Grid>

                {/* Flight details end*/}

                <Grid item>
                  <Typography
                    sx={{
                      color: "#2156A6",
                      p: "10px",
                      fontWeight: 500,
                      fontSize: "15px",
                      bgcolor: "#a3c7ff3b",
                      m: "30px 0 0px 0",
                      // width: "100%",
                    }}
                  >
                    Passenger Information
                  </Typography>

                  {isMobile ? (
                    <>
                      {data?.passengers?.map((data, i) => {
                        const {
                          f_name,
                          l_name,
                          dob,
                          type,
                          passport_no,
                          passEx,
                          passport_copy,
                          visa_copy,
                          gender,
                        } = data;

                        return (
                          <Grid
                            container
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            sx={{
                              display: {
                                xs: "block",
                                sm: "flex",
                                md: "flex",
                              },
                              mt: "20px",
                              mb: "20px",
                              transition: "all .5s ease-in-out",
                              background: "#a3c7ff3b",
                              borderRadius: "5px",
                              width: "100%",

                              borderRadius: "5px",
                              px: "20px",
                              py: "25px",
                            }}
                          >
                            <Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    display: "flex",
                                    alignItems: "center",
                                    fontWeight: "bold",
                                    color: "var(--primary-color)",
                                  }}
                                >
                                  Name
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    color: "var(--primary-color)",
                                  }}
                                >
                                  {f_name + " " + l_name}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  mt: "30px",
                                }}
                                gap={1}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  Gender
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  {gender}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  mt: "10px",
                                }}
                                gap={1}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  Dob
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  {moment(dob).format("DD MMM YYYY")}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  mt: "10px",
                                }}
                                gap={1}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  Pax
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  {type === "ADT"
                                    ? "Adult"
                                    : type === "CNN"
                                    ? "Child"
                                    : type === "INF"
                                    ? "Infant"
                                    : ""}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  mt: "10px",
                                }}
                                gap={1}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  Passport No
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  {passport_no}
                                </Typography>
                              </Box>

                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  mt: "10px",
                                }}
                                gap={1}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  Expire Date
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mattblack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "14px",
                                    },
                                  }}
                                  noWrap
                                >
                                  {moment(passEx).format("DD MMM YYYY")}
                                </Typography>
                              </Box>

                              <Grid
                                item
                                direction={{ xs: "row", sm: "row", md: "row" }}
                                spacing={1}
                                alignItems="flex-start"
                                sx={{
                                  width: "100%",
                                  height: "100%",
                                  // padding: {
                                  //   md: "8px",
                                  //   xs: "0px",
                                  // },
                                  mt: "30px",
                                  pb: "14px",
                                  borderTop: "1px solid var(--primary-color)",
                                  // borderTop: {
                                  //   xs: "none",
                                  //   sm: "1px solid var(--primary-color)",
                                  // },
                                  pl: { xs: 2, sm: 2 },
                                }}
                              ></Grid>

                              <Box
                                sx={{
                                  display: "flex",

                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography sx={{ fontSize: "13px" }}>
                                  Passport
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    color: "var(--primary-color)",
                                  }}
                                >
                                  {passport_copy === "" ? (
                                    "N/A"
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={passport_copy}
                                      style={{ color: "#444542" }}
                                    >
                                      view
                                    </a>
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",
                                  pt: "5px",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography sx={{ fontSize: "13px" }}>
                                  Visa
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    color: "var(--primary-color)",
                                  }}
                                >
                                  {visa_copy === "" ? (
                                    "N/A"
                                  ) : (
                                    <a
                                      target="_blank"
                                      rel="noreferrer"
                                      href={visa_copy}
                                      style={{ color: "#444542" }}
                                    >
                                      view
                                    </a>
                                  )}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  display: "flex",

                                  alignItems: "center",
                                  justifyContent: "space-between",
                                }}
                              >
                                <Typography sx={{ fontSize: "13px" }}>
                                  Ancillaries
                                </Typography>
                                <Typography
                                  sx={{
                                    fontSize: "11px",
                                    fontWeight: "bold",
                                    color: "var(--primary-color)",
                                  }}
                                >
                                  {data?.meal ||
                                  data?.wheel_chair ||
                                  data?.vip_message ? (
                                    <p
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      {data?.meal && (
                                        <Tooltip title={data?.meal}>
                                          <LocalDiningIcon
                                            sx={{
                                              fontSize: "22px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                      {data?.wheel_chair && (
                                        <Tooltip title={data?.wheel_chair}>
                                          <AccessibleIcon
                                            sx={{
                                              fontSize: "23px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                      {data?.vip_message && (
                                        <Tooltip title={data?.vip_message}>
                                          <QuickreplyIcon
                                            sx={{
                                              fontSize: "20px",
                                              cursor: "pointer",
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      <Box>
                        <TableContainer
                          component={Paper}
                          sx={{ boxShadow: "none" }}
                        >
                          <Table aria-label="simple table">
                            <TableHead sx={{ minWidth: "100%" }}>
                              <TableRow sx={{ backgroundColor: "#F7D800" }}>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="left"
                                >
                                  Name
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="left"
                                >
                                  Gender
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="left"
                                >
                                  DOB
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="center"
                                >
                                  PAX
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="center"
                                >
                                  Passport No
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="center"
                                >
                                  Expire Date
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="center"
                                >
                                  Passport
                                </TableCell>
                                <TableCell
                                  sx={{ color: "#2156A6", fontSize: "13px" }}
                                  align="center"
                                >
                                  Visa
                                </TableCell>
                                {data.passengers[0]?.meal ||
                                data.passengers[0]?.wheel_chair ||
                                data.passengers[0]?.vip_message ? (
                                  <TableCell
                                    sx={{ color: "#2156A6", fontSize: "13px" }}
                                    align="center"
                                  >
                                    Ancillaries
                                  </TableCell>
                                ) : (
                                  ""
                                )}
                              </TableRow>
                            </TableHead>
                            <TableBody style={{ boxShadow: "none" }}>
                              {data?.passengers?.map((data, i) => {
                                const {
                                  f_name,
                                  l_name,
                                  dob,
                                  type,
                                  passport_no,
                                  passEx,
                                  passport_copy,
                                  visa_copy,
                                  gender,
                                } = data;

                                return (
                                  <TableRow
                                    key={i}
                                    sx={{
                                      backgroundColor: "white",
                                      boxShadow: "none",
                                    }}
                                  >
                                    <TableCell
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                        boxShadow: "none",
                                      }}
                                      align="left"
                                    >
                                      {f_name + " " + l_name}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                      }}
                                      align="left"
                                    >
                                      {gender}
                                    </TableCell>

                                    <TableCell
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                      }}
                                      align="left"
                                    >
                                      {moment(dob).format("DD MMM YYYY")}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                      }}
                                      align="center"
                                    >
                                      {type === "ADT"
                                        ? "Adult"
                                        : type === "CNN"
                                        ? "Child"
                                        : type === "INF"
                                        ? "Infant"
                                        : ""}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                      }}
                                      align="center"
                                    >
                                      {passport_no}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                      }}
                                      align="center"
                                    >
                                      {moment(passEx).format("DD MMM YYYY")}
                                    </TableCell>
                                    <TableCell
                                      sx={{
                                        fontSize: "13px",
                                        color: "#444542",
                                      }}
                                      align="center"
                                    >
                                      {passport_copy === "" ? (
                                        "N/A"
                                      ) : (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={passport_copy}
                                          style={{ color: "#444542" }}
                                        >
                                          view
                                        </a>
                                      )}
                                    </TableCell>
                                    <TableCell
                                      sx={{ fontSize: "13px" }}
                                      align="center"
                                    >
                                      {visa_copy === "" ? (
                                        "N/A"
                                      ) : (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={visa_copy}
                                          style={{ color: "#444542" }}
                                        >
                                          view
                                        </a>
                                      )}
                                    </TableCell>

                                    {data?.meal ||
                                    data?.wheel_chair ||
                                    data?.vip_message ? (
                                      <TableCell
                                        sx={{
                                          fontSize: "13px",
                                          color: "#444542",
                                          display: "flex",
                                          justifyContent: "space-between",
                                          alignItems: "center",
                                        }}
                                        align="center"
                                      >
                                        {data?.meal && (
                                          <Tooltip title={data?.meal}>
                                            <LocalDiningIcon
                                              sx={{
                                                fontSize: "22px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                        {data.wheel_chair && (
                                          <Tooltip title={data.wheel_chair}>
                                            <AccessibleIcon
                                              sx={{
                                                fontSize: "23px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                        {data.vip_message && (
                                          <Tooltip title={data.vip_message}>
                                            <QuickreplyIcon
                                              sx={{
                                                fontSize: "20px",
                                                cursor: "pointer",
                                              }}
                                            />
                                          </Tooltip>
                                        )}
                                      </TableCell>
                                    ) : (
                                      ""
                                    )}
                                  </TableRow>
                                );
                              })}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Box>
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                lg={2.75}
                bgcolor={"gren"}
                sx={{ height: "max-content" }}
              >
                <Box
                  sx={{
                    borderRadius: "7px",
                    bgcolor: "#a3c7ff3b",
                    mb: "13px",
                    pl: "18px",
                    p: "20px",
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "#2156A6",
                        fontWeight: "500",
                        bgcolor: "var(--secondary-color)",
                        py: "3px",
                        pl: "5px",
                        borderRadius: "2px",
                        textAlign: "center",
                      }}
                    >
                      {data?.booking?.status} <br />
                      {/* {bookingId} */}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "var(--white)",
                        fontWeight: "500",
                        bgcolor: "var(--primary-color)",
                        py: "3px",
                        pl: "5px",
                        borderRadius: "2px",
                        textAlign: "center",
                      }}
                    >
                      Booking Id : {bookingId}
                    </Typography>

                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#444542",
                        fontWeight: "500",
                        mt: "14px",
                      }}
                    >
                      Booking Information
                    </Typography>
                  </Box>
                  <Box>
                    {bookingInfo.map((info, i) => (
                      <Stack
                        key={i}
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        my={1}
                      >
                        <Typography sx={{ fontSize: "11px", color: "#ACA6A6" }}>
                          {info?.text}
                        </Typography>
                        <Typography sx={{ fontSize: "11px", color: "#2156A6" }}>
                          {info?.value}
                        </Typography>
                      </Stack>
                    ))}
                  </Box>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={3}
                  >
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#444542",
                        fontWeight: "500",
                      }}
                    >
                      Time Limit
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "#2156A6",
                        fontWeight: "500",
                      }}
                    >
                      {`${
                        data?.booking?.time_limit === "0"
                          ? "Immediate Issue"
                          : data?.booking?.time_limit
                      }`}
                    </Typography>
                  </Stack>
                </Box>

                <Accordion
                  expanded={expanded === "panel4"}
                  onChange={handleChange("panel4")}
                  style={{
                    marginBottom: "13px",
                    boxShadow: "none",
                    borderRadius: "7px",
                    backgroundColor: "#a3c7ff3b",
                  }}
                >
                  <AccordionSummary aria-controls="panel2bh-content">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#2564B8",
                          fontWeight: "500",
                        }}
                      >
                        Price Breakedown
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails sx={{ padding: "0" }}>
                    <Box>
                      <Box px={2}>
                        <Typography
                          variant="body2"
                          sx={{ color: "#444542", fontWeight: "500" }}
                        >
                          Total Payable
                        </Typography>
                        <Typography
                          variant="title-lg"
                          sx={{ color: "#444542" }}
                        >
                          ৳{" "}
                          {Number(data?.booking?.net_cost).toLocaleString(
                            "en-IN"
                          )}
                        </Typography>
                      </Box>

                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontSize: "12px",
                          bgcolor: "#FBEA78",
                          padding: "4px 17px",
                          fontWeight: 500,
                        }}
                      >
                        Price Breakdown
                      </Typography>

                      {pricebreakdown
                        .filter((p) => parseFloat(p.paxCount) !== 0)
                        .map((price, i) => {
                          const { type, name, paxCount, baseCost, tax } = price;
                          return (
                            <Box key={i} sx={{ padding: "15px 20px 0" }}>
                              <Typography variant="h6">
                                {name} x{paxCount}
                              </Typography>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <Typography variant="caption">
                                  Base Fare x{paxCount}
                                </Typography>
                                <Typography variant="subtitle1">
                                  {Number(baseCost).toLocaleString("en-IN")}
                                </Typography>
                              </Stack>
                              <Stack
                                direction={"row"}
                                justifyContent={"space-between"}
                              >
                                <Typography variant="caption">
                                  Tax x{paxCount}
                                </Typography>
                                <Typography variant="subtitle1">
                                  {Number(tax).toLocaleString("en-IN")}
                                </Typography>
                              </Stack>
                            </Box>
                          );
                        })}

                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{ padding: "15px 20px 0" }}
                      >
                        <Typography variant="caption">
                          Total Base & Tax
                        </Typography>
                        <Typography variant="subtitle1">
                          {Number(data?.booking?.gross_cost).toLocaleString(
                            "en-IN"
                          )}
                        </Typography>
                      </Stack>

                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{ padding: "18px 20px 0" }}
                      >
                        <Typography variant="caption">Discount</Typography>
                        <Typography variant="subtitle1">
                          {(
                            data?.booking?.gross_cost - data?.booking?.net_cost
                          ).toLocaleString("en-IN")}
                        </Typography>
                      </Stack>

                      <Stack
                        direction={"row"}
                        justifyContent={"space-between"}
                        sx={{ padding: "18px 20px" }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#ACA6A6",
                            fontWeight: "500",
                          }}
                        >
                          Grand Total
                        </Typography>
                        <Typography sx={{ color: "#2156A6", fontSize: "14px" }}>
                          ৳{" "}
                          {Number(data?.booking?.net_cost).toLocaleString(
                            "en-IN"
                          )}
                        </Typography>
                      </Stack>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                {/* <OnewayPrice roundData={flightData} /> */}

                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                  style={{
                    marginBottom: "13px",
                    boxShadow: "none",
                    borderRadius: "7px",
                    backgroundColor: "#a3c7ff3b",
                  }}
                >
                  <AccordionSummary aria-controls="panel2bh-content">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "#2564B8",
                          fontWeight: "500",
                        }}
                      >
                        Baggage
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data?.baggage_allowances?.map((bag, i) => (
                      <Box key={i}>
                        <Typography variant="h6" mt={i > 0 ? 1 : 0}>
                          {bag?.pax_type === "A"
                            ? "Adult"
                            : bag?.pax_type === "C"
                            ? "Child"
                            : "Infant"}
                        </Typography>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="caption">Baggae</Typography>
                          <Typography variant="subtitle1">
                            {bag?.checked_total_weight} KG
                          </Typography>
                        </Stack>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography variant="caption">
                            Cabin Baggae
                          </Typography>
                          <Typography variant="subtitle1">
                            {bag?.cabin_total_weight > 0
                              ? bag?.cabin_total_weight + " KG"
                              : bag?.cabin_max_pieces > 0
                              ? data?.baggage_pieces.length > 0
                                ? data?.baggage_pieces[i]?.max_weight_kg + " KG"
                                : bag?.cabin_max_pieces
                              : ""}
                          </Typography>
                        </Stack>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>

                {data?.fare_rules[0]?.refund_fee_before_dept > 0 ||
                data?.fare_rules[0]?.refund_fee_after_dept > 0 ||
                data?.fare_rules[0]?.reissue_fee_before_dept > 0 ||
                data?.fare_rules[0]?.reissue_fee_after_dept > 0 ? (
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    style={{
                      marginBottom: "5px",
                      boxShadow: "none",
                      backgroundColor: "#a3c7ff3b",
                      borderRadius: "7px",
                    }}
                  >
                    <AccordionSummary aria-controls="panel2bh-content">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#2564B8",
                            fontWeight: "500",
                          }}
                        >
                          Fare Rules
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ padding: "" }}>
                        {data?.fare_rules[0]?.refund_fee_before_dept > 0 ||
                        data?.fare_rules[0]?.refund_fee_after_dept > 0 ? (
                          <Typography variant="h6">Refund Penalties</Typography>
                        ) : (
                          ""
                        )}
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          {data?.fare_rules[0]?.refund_fee_before_dept > 0 ? (
                            <>
                              <Typography variant="caption">
                                Before Departure
                              </Typography>
                              <Typography variant="subtitle1">
                                {parseFloat(
                                  data?.fare_rules[0]?.refund_fee_before_dept
                                ).toLocaleString("en-In")}{" "}
                                {
                                  data?.fare_rules[0]
                                    ?.refund_fee_before_dept_currency
                                }
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </Stack>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          {data?.fare_rules[0]?.refund_fee_after_dept > 0 ? (
                            <>
                              <Typography variant="caption">
                                After Departure
                              </Typography>
                              <Typography variant="subtitle1">
                                {parseFloat(
                                  data?.fare_rules[0]?.refund_fee_after_dept
                                ).toLocaleString("en-In")}{" "}
                                {data?.fare_rules[0]
                                  ?.refund_fee_after_dept_currency
                                  ? data?.fare_rules[0]
                                      ?.refund_fee_after_dept_currency
                                  : ""}
                              </Typography>
                            </>
                          ) : (
                            ""
                          )}
                        </Stack>

                        {data?.fare_rules[0]?.reissue_fee_before_dept > 0 ||
                        data?.fare_rules[0]?.reissue_fee_after_dept > 0 ? (
                          <Typography variant="h6" mt={1}>
                            Reissue Penalties
                          </Typography>
                        ) : (
                          ""
                        )}

                        {data?.fare_rules[0]?.reissue_fee_before_dept > 0 ? (
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="caption">
                              Before Departure
                            </Typography>
                            <Typography variant="subtitle1">
                              {parseFloat(
                                data?.fare_rules[0]?.reissue_fee_before_dept
                              ).toLocaleString("en-In")}{" "}
                              {
                                data?.fare_rules[0]
                                  ?.refund_fee_before_dept_currency
                              }
                            </Typography>
                          </Stack>
                        ) : (
                          ""
                        )}

                        {data?.fare_rules[0]?.reissue_fee_after_dept > 0 ? (
                          <Stack
                            direction={"row"}
                            justifyContent={"space-between"}
                          >
                            <Typography variant="caption">
                              After Departure
                            </Typography>
                            <Typography variant="subtitle1">
                              {parseFloat(
                                data?.fare_rules[0]?.reissue_fee_after_dept
                              ).toLocaleString("en-In")}{" "}
                              {
                                data?.fare_rules[0]
                                  ?.reissue_fee_after_dept_currency
                              }
                            </Typography>
                          </Stack>
                        ) : (
                          ""
                        )}
                      </Box>

                      <Typography sx={{ fontSize: "11px", pt: "20px" }}>
                      Fly Far International Convenience fee is non-refundable.
                        We can not guarantee the accuracy of airline refund/date
                        change fees as they are subject to change without prior
                        notice.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                ) : (
                  ""
                )}

                <Accordion
                  expanded={expanded === "panel5"}
                  onChange={handleChange("panel5")}
                  style={{
                    marginBottom: "5px",
                    boxShadow: "none",
                    backgroundColor: "#a3c7ff3b",
                    marginTop: "13px",
                    borderRadius: "4px",
                  }}
                >
                  <AccordionSummary aria-controls="panel2bh-content">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "var(--primary-color)",
                          fontWeight: "500",
                        }}
                      >
                        PNR History
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data?.history?.map((data, index) => (
                      <Box className="lineParentBox" key={index}>
                        <Grid container spacing={4}>
                          <Grid item xs={1} sx={{ mt: "3px" }}>
                            <Box className="note-line">
                              <Box
                                sx={{
                                  width: "15px",
                                  height: "15px",
                                  backgroundColor: "#F7D800",
                                  position: "absolute",
                                  left: "-9px",
                                  borderRadius: "50%",
                                }}
                              />
                            </Box>
                          </Grid>
                          <Grid item mt="-3px" xs={10}>
                            <Typography
                              style={{
                                color: "#2564B8",
                                fontSize: "15px",
                                fontWeight: 400,
                              }}
                            >
                              {data?.status}
                            </Typography>
                            <Box py={1}>
                              <Typography
                                sx={{
                                  color: "var(--primary-color)",
                                  fontSize: "11px",
                                  fontWeight: 400,
                                }}
                              >
                                {data?.status === "Hold" ||
                                data?.status === "Issue In Processing" ||
                                data?.status === "Reissue In Processing" ||
                                data?.status === "Refund In Processing" ||
                                data?.status === "Void In Processing" ||
                                data?.status === "Cancelled" ? (
                                  <>
                                    {data?.action_by} {user[0]?.company}
                                  </>
                                ) : (
                                  // <>{CompanyInfo.companyName}</>
                                  <>qawerfwe</>
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#767676",
                                  fontSize: "11px",
                                  fontWeight: 400,
                                }}
                              >
                                {new Date(data?.action_at).toLocaleString(
                                  "en-uk",
                                  {
                                    day: "numeric",
                                    month: "short",
                                    year: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                    hour12: true,
                                  }
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "red",
                                  fontSize: "12px",
                                  fontWeight: 400,
                                  mt: "4px",
                                }}
                              >
                                {data?.remarks === "" ||
                                data?.remarks === " " ? (
                                  ""
                                ) : (
                                  <>Remarks: {data?.remarks}</>
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    ))}
                  </AccordionDetails>
                </Accordion>

                {/* status operation starts here */}
                {data?.booking?.status.toLowerCase() !== "cancelle" && (
                  <Accordion
                    style={{
                      boxShadow: "none",
                      backgroundColor: "#a3c7ff3b",
                      margin: "13px 0",
                    }}
                  >
                    <AccordionSummary aria-controls="panel2bh-content">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: "500",
                            fontSize: "15px",
                          }}
                        >
                          Download PDF
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails style={{ paddingTop: "0" }}>
                      <Box>
                        <AllPDFButtons
                          markupPrice={"200"}
                          agentId={user?.agent_id}
                          bookingId={bookingId}
                          allData={data}
                        />
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                )}

                <Box>
                  {/* Document Upload and Issue request 1st section */}
                  {data?.booking?.status === "Hold" &&
                  data?.journey_type === "Outbound" ? (
                    <Box>
                      {data?.passenger[0]?.passport_copy === "" &&
                      data?.passenger[0]?.visa_copy === "" ? (
                        <Box width="100%" mt={2}>
                          <button
                            style={{
                              border: "0px",
                              borderRadius: "5px",
                              color: "#ffff",
                              height: "35px",
                              background: "#5BB17D",
                              width: "100%",
                              cursor: "pointer",
                            }}
                            onClick={() => handleOpenUpdateModal()}
                          >
                            Upload Document
                          </button>
                          <button
                            style={{
                              marginTop: "10px",
                              border: "0px",
                              borderRadius: "5px",
                              color: "#ffff",
                              height: "35px",
                              background: "red",
                              width: "100%",
                              cursor: "pointer",
                            }}
                            onClick={handleCancleBooking}
                          >
                            Cancel Ticket
                          </button>
                          {/* ----inter */}
                        </Box>
                      ) : (
                        <Box>
                          <Box
                            sx={{
                              bgcolor: "#4DB670",
                              py: "8px",
                              color: "#fff",
                              textAlign: "center",
                              cursor: "pointer",
                              fontSize: "12px",
                              borderRadius: "3px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              gap: "5px",
                            }}
                            my={2}
                            onClick={() => handleOpenUpdateModal()}
                          >
                            Document Uploaded <TaskIcon />
                          </Box>
                          <Box mt={2}>
                            <button
                              style={{
                                border: "0px",
                                color: "#ffff",
                                height: "35px",
                                background: "var(--primary-color)",
                                width: "100%",
                                cursor: "pointer",
                                borderRadius: "3px",
                              }}
                              onClick={handleIssueTicket}
                            >
                              Issue Ticket
                            </button>
                          </Box>
                          <Box mt={2}>
                            <button
                              style={{
                                border: "0px",
                                color: "#ffff",
                                borderRadius: "5px",
                                height: "35px",
                                background: "var(--red)",
                                width: "100%",
                                cursor: "pointer",
                              }}
                              onClick={handleCancleBooking}
                            >
                              Cancel Ticket
                            </button>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ) : data?.booking?.status === "Hold" ? (
                    <Box>
                      <Box className="issueTicket" mt={2}>
                        <button
                          style={{
                            border: "0px",
                            color: "#ffff",
                            height: "35px",
                            background: "var(--primary-color)",
                            width: "100%",
                            cursor: "pointer",
                            borderRadius: "3px",
                          }}
                          onClick={handleIssueTicket}
                        >
                          Issue Ticket
                        </button>
                      </Box>
                      <Box mt={2}>
                        <button
                          style={{
                            border: "0px",
                            color: "#ffff",
                            borderRadius: "5px",
                            height: "35px",
                            background: "var(--red)",
                            width: "100%",
                            cursor: "pointer",
                          }}
                          onClick={handleCancleBooking}
                        >
                          Cancel Ticket
                        </button>
                      </Box>
                    </Box>
                  ) : null}

                  {/* Document Upload and Issue request 2nd section */}

                  {data?.booking?.status === "Ticketed" ? (
                    <Box>
                      {/* Refundable start */}
                      {data?.booking?.refundable === "yes" ? (
                        <Box className="queues-detail-calcel-btn">
                          <Box className="issueTicket" mt={2}>
                            <button
                              onClick={() => {
                                setRequestModal({
                                  modal: true,
                                  value: "refund",
                                });
                              }}
                            >
                              <span style={{ color: "var(--white)" }}>
                                Refund
                              </span>
                            </button>
                          </Box>
                        </Box>
                      ) : null}
                      {/* Refundable end */}
                      {/* Reissue start */}

                      {data?.booking?.status === "Ticketed" ? (
                        <Box className="queues-detail-calcel-btn">
                          <Box className="issueTicket" mt={2}>
                            <button
                              onClick={() => {
                                setRequestModal({
                                  modal: true,
                                  value: "reissue",
                                });
                              }}
                            >
                              <span style={{ color: "var(--white)" }}>
                                Reissue
                              </span>
                            </button>
                          </Box>
                        </Box>
                      ) : null}

                      {/* Reissue end */}

                      {/* Void start */}
                      {data?.booking?.status === "Ticketed" &&
                      todaydate <= voideDate ? (
                        <Box className="queues-detail-calcel-btn">
                          <Box className="issueTicket" mt={2}>
                            <button
                              onClick={() => {
                                setRequestModal({
                                  modal: true,
                                  value: "void",
                                });
                              }}
                            >
                              <span style={{ color: "var(--white)" }}>
                                Void
                              </span>
                            </button>
                          </Box>
                        </Box>
                      ) : null}
                      {/* Void end */}
                    </Box>
                  ) : data?.booking?.status === "Issue In Processing" ? (
                    <Box
                      sx={{
                        bgcolor: "var(--primary-color)",
                        py: 1,
                        textAlign: "center",
                        color: "#fff",
                        my: 1,
                      }}
                    >
                      <span style={{ color: "var(--white)" }}>
                        Wait For Ticketed
                      </span>
                    </Box>
                  ) : data?.booking?.status === "Refund In Processing" ? (
                    <Box
                      sx={{
                        bgcolor: "var(--primary-color)",
                        py: 1,
                        textAlign: "center",
                        color: "#fff",
                        my: 1,
                      }}
                    >
                      <span style={{ color: "var(--white)" }}>
                        Wait For Refunded
                      </span>
                    </Box>
                  ) : data?.booking?.status === "Reissue In Processing" ? (
                    <Box
                      sx={{
                        bgcolor: "var(--primary-color)",
                        py: 1,
                        textAlign: "center",
                        color: "#fff",
                        my: 1,
                      }}
                    >
                      <span style={{ color: "var(--white)" }}>
                        Wait For Reissued
                      </span>
                    </Box>
                  ) : data?.booking?.status === "Void In Processing" ? (
                    <Box
                      sx={{
                        bgcolor: "var(--primary-color)",
                        py: 1,
                        textAlign: "center",
                        color: "#fff",
                        my: 1,
                      }}
                    >
                      <span style={{ color: "var(--white)" }}>
                        Wait For Voided
                      </span>{" "}
                    </Box>
                  ) : null}
                </Box>
              </Grid>
            </Grid>

            {/* ------------------------------Modall Refund Reissue and Void---------------------------------- */}
            <Modal
              open={requestModal.modal}
              onClose={() =>
                setRequestModal({
                  modal: false,
                })
              }
              className="custom-modal-r modal-table-0"
            >
              <Box
                className="modalStyle"
                bgcolor="#fff"
                p="25px"
                sx={{ m: "0 auto", width: "70%", mt: "150px" }}
              >
                <Box className="modal-table">
                  <Typography
                    sx={{
                      color: "#222222",
                      fontSize: "20px",
                      fontWeight: 500,
                      mb: "10px",
                    }}
                  >
                    Make {requestModal?.value} Request
                  </Typography>
                  <Box>
                    <table className="responsive-table">
                      <tr>
                        <th width="35%">Passenger Name</th>
                        <th width="20%">Gender</th>
                        <th width="20%">Passenger Type</th>
                        <th width="30%">Ticket No</th>
                      </tr>
                      {invoiceId?.map((ticket, index) => (
                        <tr key={index}>
                          <td>
                            {ticket?.gender === "Female" ? (
                              <>
                                {ticket?.gender === "Female" &&
                                ticket?.pType === "ADT" ? (
                                  <>MS {ticket?.passenger_name}</>
                                ) : (
                                  <>MISS {ticket?.passenger_name}</>
                                )}
                              </>
                            ) : (
                              <>
                                {ticket?.gender === "Male" &&
                                ticket?.pax_type === "ADT" ? (
                                  <>MR {ticket?.passenger_name}</>
                                ) : (
                                  <>MSTR {ticket?.passenger_name}</>
                                )}
                              </>
                            )}
                          </td>
                          <td>{ticket?.gender}</td>
                          <td>
                            {ticket?.pax_type === "ADT" ? (
                              <>Adult</>
                            ) : ticket?.pax_type === "CNN" ? (
                              <>Child</>
                            ) : (
                              <>Infant</>
                            )}
                          </td>
                          <td>{ticket?.ticket_no}</td>
                        </tr>
                      ))}
                    </table>
                  </Box>
                  <Grid
                    container
                    justifyContent={"space-between"}
                    alignItems="center"
                  >
                    <Grid item mt={2}>
                      {requestModal?.value === "Reissue" && (
                        <Box>
                          <label htmlFor="date">Go-Departure Date</label> <br />
                          <input
                            style={{
                              border: "2px solid #C4C4C4",
                              padding: "5px",
                              fontSize: "14px",
                              cursor: "pointer",
                            }}
                            required
                            type="text"
                            name="date"
                            readOnly
                            value={format(new Date(date), "dd MMM yyyy")}
                            // onClick={() => {
                            //   setOpenDate((prev) => !prev);
                            // }}
                          />
                        </Box>
                      )}
                    </Grid>
                    <Grid item mt={4}>
                      <>
                        <button
                          disabled={!date === new Date()}
                          style={
                            isDisabled ? styles.buttonDisabled : styles.button
                          }
                          onClick={handleRefundReissueVoid}
                        >
                          {isDisabled ? "Wait for reissue" : "Submit"}
                        </button>
                        <button
                          style={{
                            padding: "6px 20px",
                            color: "#fff",
                            backgroundColor: "crimson",
                            border: "none",
                            cursor: "pointer",
                          }}
                          type="reset"
                          onClick={() => {
                            setRequestModal({
                              modal: false,
                            });
                          }}
                        >
                          Cancel
                        </button>
                      </>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
              {/* </from> */}
            </Modal>
            {/* passport and visa copy upload modal */}
            {/* <Modal open={openUpdateModal} onClose={handleCloseUpdateModal}>
            <Box
              sx={{
                ...updateModalStyle,
                width: { xs: "100%", sm: "80%", md: "50%" },
                height: "300px",
                p: { xs: 1, sm: 3 },
              }}
            >
              <FileUploadSection
                setState={setState}
                passengerData={data?.passenger}
                handleIssueTicket={handleIssueTicket}
                handleCloseUpdateModal={handleCloseUpdateModal}
              />
            </Box>
          </Modal> */}
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: { xs: "80px" },
              marginInline: "auto",
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#2156a6"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Congratulation;
