import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import FlightIcon from "@mui/icons-material/Flight";
import WorkIcon from "@mui/icons-material/Work";
import commaNumber from "comma-number";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import { IoAirplaneSharp } from "react-icons/io5";
import { format } from "date-fns";
import moment from "moment";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const OnewayQuotation = ({
  newQuotetionArr,
  setNewQuotetionArr,
  updatedPrice,
  flightData,
}) => {
  const [checked, setChecked] = useState(true);
  const handleBox = (data) => {
    const event = window.event;

    if (newQuotetionArr?.length > 1) {
      setChecked(event.target.checked);
    }
    if (!event.target.checked) {
      const filter = newQuotetionArr?.filter((item) => item?.uId !== data.uId);
      setNewQuotetionArr(filter);
    }
  };

  console.log(flightData);
  const allData = flightData?.segments;

  return (
    <Box
      sx={{
        mb: "10PX",
     
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        transition: "all .5s ease-in-out",
        borderRadius: "5px",

      
      }}
    >
      <Grid
        justifyContent={"space-between"}
        container
        sx={{
          p: "20px 20px",
          borderRadius: "4px",
          transition: "all .5s ease-in-out",
          bgcolor: "#EBF0F4"
        }}
      >
        <Grid order={{ xs: 1, lg: 1 }} xs={6} lg={2.5}>
          <Box>
            <img
              src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${allData[0]?.marketingcareer}.png`}
              width="50px"
              height="50px"
              alt={`${flightData.segments[0]?.marketingcareer}`}
            />

            <Typography
              variant="body2"
              sx={{ color: "#2156A6", fontWeight: "500" }}
            >
              {allData[0]?.marketingcareerName}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "#222", fontWeight: "500" }}
            >
              {allData.map(
                (s, i) =>
                  `${
                    i === 0
                      ? `${s.marketingcareer} ${s.marketingflight}`
                      : ` & ${s.marketingcareer} ${s.marketingflight}`
                  }`
              )}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-start",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      p: 0,
                      m: 0,
                      ml: 1,
                      color: "var(--secondary-color)",
                    }}
                    checked={checked}
                    onChange={() => handleBox(flightData)}
                  />
                }
              />
              <Typography
                sx={{
                  fontSize: "12px",
                  color: "#222",
                  pt: "50px",
                }}
              >
                {allData[0]?.CabinClass}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid order={{ xs: 3, lg: 2 }} xs={3} lg={2.6}>
          <Box>
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Departure
            </Typography>
            <Typography variant="title-lg">{flightData?.departure}</Typography>
            <Typography variant="body2" sx={{ color: "var(--primary-color)" }}>
              {allData[0].departureAirport}
            </Typography>
            <Typography
              sx={{
                color: "#222",
                fontSize: "11px",
                py: "4px",
                fontWeight: "500",
              }}
            >
              {moment(allData[0]?.departureTime, "HH:mm").format("hh:mm A")}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {flightData?.departureDate}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "28px",
              }}
            >
              <Typography
                sx={{
                  fontSize: "12px",
                  color:
                    flightData?.refundable === "Refundable" ? "#0B7102" : "red",
                }}
              >
                {flightData?.refundable}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#222", mr: 1 }}>
                {flightData?.seat} seat{flightData?.seat > 1 ? "s" : ""}
              </Typography>
            </Box>
          </Box>
        </Grid>

        <Grid order={{ xs: 4, lg: 3 }} xs={3} lg={1.75}>
          <Box sx={{ mt: "30px" }}>
            <Typography
              sx={{
                color: "#ACA6A6",
                textAlign: "center",
                mr: "10px",
                fontSize: "10px",
              }}
            >
              {flightData?.flightduration}
            </Typography>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="122"
              height="12"
              viewBox="0 0 122 12"
              fill="none"
            >
              <path
                d="M120.682 11C120.4 11 104.145 11 96.0529 11H0"
                stroke="#2156A6"
                strokeWidth="2"
              />
              <path
                d="M96.9551 2L120.682 11L96.9551 10.8623V2Z"
                fill="#2156A6"
                stroke="#2156A6"
                strokeWidth="2"
              />
            </svg>
            <Typography
              sx={{
                color: "#444542",
                textAlign: "center",
                mr: "10px",
                fontSize: "11px",
              }}
            >
              {flightData?.segment >= 2
                ? `${flightData?.segment - 1} stop${
                    flightData?.segment - 1 > 1 ? "s" : ""
                  }`
                : "No stop"}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            sx={{ color: "#2156A6", textAlign: "center", mt: 7.1 }}
          >
            Baggage{" "}
            {flightData?.bags > 3
              ? `${flightData?.bags} KG`
              : `${flightData?.bags} Bag${flightData?.bags > 1 ? "s" : ""}`}
          </Typography>
        </Grid>

        <Grid order={{ xs: 5, lg: 4 }} xs={3} lg={2.6}>
          <Box
            sx={{
              bgcolor: "blu",
            }}
          >
            <Typography variant="body2" sx={{ color: "#8C8080" }}>
              Arrival
            </Typography>
            <Typography variant="title-lg">{flightData?.arrival}</Typography>
            <Typography variant="body2" sx={{ color: "#2156A6" }}>
              {
                flightData?.segments[flightData?.segments.length - 1]
                  .arrivalAirport
              }
            </Typography>
            <Typography
              sx={{
                color: "#222",
                fontSize: "11px",
                py: "4px",
                fontWeight: "500",
              }}
            >
              {moment(flightData?.arrivalTime, "HH:mm").format("hh:mm A")}
            </Typography>
            <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
              {flightData?.arrivalDate}
            </Typography>
          </Box>
        </Grid>

        <Grid order={{ xs: 2, lg: 5 }} xs={6} lg={1.75}>
          <Stack
            direction="column"
            justifyContent="space-between"
            alignItems="end"
            height="100%"
            width="100%"
            sx={{
              borderLeft: "5px solid #F0F6FF",
              textAlign: "right",
            }}
          >
            <Box>
              <Typography variant="title-lg">
                {Number(flightData?.price).toLocaleString("en-IN")}৳
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "#2156A6",
                  pb: "10px",
                  textDecoration: "line-through",
                }}
              >
                {Number(flightData?.clientPrice).toLocaleString("en-IN")}৳
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OnewayQuotation;
