import {
  Box,
  ClickAwayListener,
  Container,
  Drawer,
  Pagination,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import { Grid, Button } from "@mui/material";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import Swal from "sweetalert2";
import Loader from "../../images/loader/Render.gif";
import { format } from "date-fns";
import secureLocalStorage from "react-secure-storage";
import Search from "../../images/undraw/undraw_web_search_re_efla.svg";
import Commission from "../../components/Commission";
import AirlinesNameSlider from "../../components/AirlinesNameSlider/AirlinesNameSlider";
import RoundSingleFlight from "../../components/SingleFlight/RoundSingleFlight";
import RoundFilterDrawer from "../../components/RoundFilterDrawer";
import RoundWayFilter from "../../components/RoundWayFilter";
import styled from "@emotion/styled";
import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
import SessionTimer from "../../components/Shared/SessionTimer/SessionTimer";
import RoundPreloader from "../../components/Preloader/RoundPreloader";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import { RoundWayFakeData } from "../../components/RoundWayFakeData";
import Header from "../../components/Header/Header";
import UserHeader from "../../components/UserPage/UserHeader/UserHeader";
import moment from "moment";
import ReturnSearchSkeleton from "./ReturSearchSkeleton";
import AirlinesSlider from "../../components/SingleFlight/AirlinesSlider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const HtmlTooltip = styled(({ className, ...propss }) => (
  <Tooltip {...propss} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#dc143c",
    maxWidth: 220,
    fontSize: "20px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const itemsPerPage = 10;

const RoundSearchResult = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const requiredSearchData =
    location.state !== null
      ? location.state
      : secureLocalStorage.getItem("search-data");
  // console.log(requiredSearchData);

  const {
    fromSendData,
    toSendData,
    departureDate,
    returningDate,
    adultCount,
    childCount,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    className,
  } = requiredSearchData;

  const [currentPage, setCurrentPage] = useState(1);

  // Calculate the start and end index for the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  //fetch data form localStorage
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const subagentId = user?.user?.subagentId;
  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState(tripType);
  const [roundWayFromSearchText, setRoundWayFromSearchText] =
    useState(fromSearchText);
  const [roundWayToSearchText, setRoundWayToSearchText] =
    useState(toSearchText);
  const now = useRef(new Date(departureDate));
  const returnNow = useRef(new Date(returningDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(returnNow.current);
  const [fromSearchDate, setFromSearchDate] = useState(departureDate);
  const [toSearchDate, setToSearchDate] = useState(returningDate);
  const [selectedAirLines, setSelectedAirLines] = useState([]);
  const [roundWayFaddress, setRoundWayFaddress] = useState(faddress);
  const [roundWayToAddress, setRoundWayToAddress] = useState(toAddress);
  const [roundWayFromSendData, setRoundWayFromSendData] =
    useState(fromSendData);
  const [roundWayToSendData, setRoundWayToSendData] = useState(toSendData);
  const [roundWayAdultCount, setRoundWayAdultCount] = useState(adultCount);
  const [roundWayChildCount, setRoundWayChildCount] = useState(childCount);
  const [roundWayInfant, setRoundWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [roundWayClassName, setRoundWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  const [initialFetchData, setInitialFetchData] = useState([]);
  const [quotetionArr, setQuotetionArr] = useState([]);
  //end

  //CM Box States
  const [customerFare, setCustomerFare] = useState(true);
  const [agentFarePrice, setAgentFarePrice] = useState(true);
  const [commisionFarePrice, setCommisionFarePrice] = useState(true);

  //todo:all flight and nxt and previous day data states
  const [isLoaded, setIsLoaded] = useState(true);
  const [datas, setDatas] = useState([]);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  console.log(data2);
  const [noData, setNoData] = useState("No Data");
  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect
  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change
  // todo:Sets the state of the const for the given page and state.
  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 30;
  // todo: previous day and next day date variables
  let tomorrowDepartureDate = new Date(fromSearchDate);
  let tomorrowReturnDate = new Date(toSearchDate);
  tomorrowDepartureDate.setDate(tomorrowDepartureDate.getDate() + 1);
  tomorrowReturnDate.setDate(tomorrowReturnDate.getDate() + 1);
  let yesterdayDepartureDate = new Date(fromSearchDate);
  let yesterdayReturnDate = new Date(toSearchDate);
  yesterdayDepartureDate.setDate(yesterdayDepartureDate.getDate() - 1);
  yesterdayReturnDate.setDate(yesterdayReturnDate.getDate() - 1);
  // Handle a page change.
  // const handlePageChange = (event, value) => {
  //   setPage(value);
  //   setData2(datas?.slice((value - 1) * size, value * size));
  //   window.scroll({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };
  const [sortedBy, setSortedBy] = useState("cheapest");
  // get the target element to toggle
  const [multiSearchData, setMultiSearchData] = useState({
    // agentId: users?.user?.agentId || "",
    agentId: "",
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    CityCount: 2,
    tripType: 3,
    segments: [
      {
        id: 0,
        openFrom: false,
        depFrom: fromSendData.trim(),
        depAddress: faddress,
        depFromText: fromSearchText,
        arrTo: "JED",
        arrAddress: "Jeddah",
        arrToText: "Jeddah Intl (JED)",
        openTo: false,
        Date: new Date(from).toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
      {
        id: 1,
        openFrom: false,
        depFrom: "JED",
        depAddress: "Jeddah",
        depFromText: "Jeddah Intl (JED)",
        arrTo: "MED",
        arrAddress: "Madinah",
        arrToText: "Prince Mohammad Bin Abdulaziz Intl Airport(MED)",
        openTo: false,
        Date: new Date(to).toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
    ],
  });

  const userInfo = secureLocalStorage.getItem("user-info");

  const modalStyle = {
    position: "absolute",
    top: "30%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: "70vw", md: "90vw", sm: "90vw", xs: "90vw" },
    height: "fit-content",
    padding: "20px",
    background: "#fff",
    borderRadius: "10px",
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
  };

  const [click, setClick] = useState(false);

  //todo All Flight Data for Today
  useEffect(() => {
    modifyHandleClose();
    setIsPrevCliked(false);
    setIsNextCliked(false);
    setIsLoaded(false);
    setClick(true);
    let subscription = true;

    const url = `https://api.rtc.flyfarint.com/v1.0/b2b/air/search/roundway.php?journeyfrom=${fromSendData?.trim()}&journeyto=${toSendData}&departuredate=${new Date(
      from
    ).toLocaleDateString("sv")}&returndate=${moment(to, "DD MMM YY").format(
      "YYYY-MM-DD"
    )}&adult=${adultCount}&child=${childCount}&infant=${infant}&class=${className}&agentId=${
      userInfo?.agent_id
    }`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        if (subscription) {
          if (data.length !== 0) {
            const uniqueData = data;
            const count = uniqueData.length;
            const pageNumber = Math.ceil(count / size);
            setPageCount(pageNumber);
            setData(uniqueData);
            setData2(uniqueData);
            setDatas(uniqueData);
            setInitialFetchData(uniqueData);
            setIsLoaded(true);
            setClick(false);
          } else {
            Swal.fire({
              imageUrl: Search,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "No Flights Found",
              confirmButtonText: "Search Again...",
              confirmButtonColor: "var(--primary-color)",
            }).then(function () {
              navigate("/dashboard");
            });
          }
        }
      })
      .catch((err) => {
        //console.log(err);
        Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Flights Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate("/dashboard");
        });
      });
    return () => {
      subscription = false;
    };
  }, [
    changeState,
    departureDate,
    returningDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    navigate,
    size,
    tripType,
  ]);

  const handleFilterByAirLine = (career) => {
    const filterByCareer = datas.filter((item) => item.career === career);
    setData2(filterByCareer);
  };

  const [isDrawerOpenMobileFilter, setDrawerOpenMobileFilter] = useState(false);

  const handleFilterIconClick = () => {
    setDrawerOpenMobileFilter(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpenMobileFilter(false);
  };

  return (
    <Box sx={{ bgcolor: "#EBF0F4" }}>
      <UserHeader />
      {/* <Container> */}
      <Box
        borderRadius="3px"
        sx={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: {
              xs: "0px",
              sm: "0px",
              md: "-70px",
              lg: "-70px",
            },
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Grid
                item
                md={12}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                }}
              >
                <FlightSearchBox
                  click={click}
                  setClick={setClick}
                  type={type}
                  setType={setType}
                  value={value}
                  setValue={setValue}
                  fromSearchText={roundWayFromSearchText}
                  setFromSearchText={setRoundWayFromSearchText}
                  toSearchText={roundWayToSearchText}
                  setToSearchText={setRoundWayToSearchText}
                  from={from}
                  setFrom={setFrom}
                  to={to}
                  setTo={setTo}
                  faddress={roundWayFaddress}
                  setfaddress={setRoundWayFaddress}
                  toAddress={roundWayToAddress}
                  setToAddress={setRoundWayToAddress}
                  fromSendData={roundWayFromSendData}
                  setFromSendData={setRoundWayFromSendData}
                  toSendData={roundWayToSendData}
                  setToSendData={setRoundWayToSendData}
                  adultCount={roundWayAdultCount}
                  setAdultCount={setRoundWayAdultCount}
                  childCount={roundWayChildCount}
                  setChildCount={setRoundWayChildCount}
                  infant={roundWayInfant}
                  setInfant={setRoundWayInfant}
                  result={result}
                  setResult={setResult}
                  className={roundWayClassName}
                  setClassName={setRoundWayClassName}
                  changeState={changeState}
                  setChangeState={setChangeState}
                  changeFrom={changeFrom}
                  setChangeFrom={setChangeFrom}
                  multiSearchData={multiSearchData}
                  setMultiSearchData={setMultiSearchData}
                />
              </Grid>
              <Grid
                container
                item={12}
                spacing={2}
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                <Grid item xs={12} md={12}>
                  <Box
                    mt={1}
                    // mx={1.5}
                    sx={{
                      bgcolor: "var(--white)",
                      px: "8px",
                      py: "6px",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "var(--black)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        {" "}
                        {fromSendData}
                        <Typography sx={{ pt: "5px" }}>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 116 57"
                            fill="var(--secondary-color)"
                          >
                            <path
                              d="M115.51 14.4039C114.711 12.0948 112.993 10.1857 110.732 9.09641C108.471 8.00716 105.854 7.82702 103.455 8.5956L76.971 17.1L29.6042 0L17.7625 5.7L53.2876 28.5L29.6042 39.9L5.92085 28.5L0 34.2L23.6834 57L109.814 25.9065C112.125 25.0713 114.009 23.4062 115.072 21.2611C116.134 19.1161 116.291 16.6581 115.51 14.4039Z"
                              fill="var(--secondary-color)"
                            />
                          </svg>
                        </Typography>
                        {toSendData}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <Link to="/dashboard">
                          <Box
                            sx={{
                              bgcolor: "var(--secondary-color)",
                              color: "var(--white)",
                              px: "10px",
                              borderRadius: "3px",
                              display: "flex",
                              alignItems: "center",

                              fontSize: "11px",
                              fontWeight: 600,
                              height: "25px",
                              cursor: "pointer",
                            }}
                          >
                            Modifiy Serch
                          </Box>
                        </Link>
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={handleFilterIconClick}
                        >
                          <FilterAltIcon
                            sx={{
                              color: "var(--primary-color)",
                              cursor: "pointer",
                            }}
                          />
                        </Box>{" "}
                      </Box>
                    </Box>
                    <Drawer
                      anchor="right"
                      open={isDrawerOpenMobileFilter}
                      onClose={handleDrawerClose}
                    >
                      <Box
                        sx={{
                          position: "sticky",
                          top: 0,
                          left: 0,
                          bgcolor: "var(--white)=",
                          borderRadius: "5px",

                          mt: 2,
                        }}
                      >
                        <Box mt={3.1} mb={1.8}>
                          <Button
                            variant="contained"
                            sx={{
                              width: "100%",
                              color: "white",
                              bgcolor: "var(--primary-color)",
                            }}
                          >
                            <SessionTimer />
                          </Button>
                          <Box
                            sx={{
                              bgcolor: "var(--secondary-color)",
                              py: "3px",
                              textAlign: "center",
                              borderRadius: "3px",

                              mt: "15px",
                            }}
                          >
                            <Typography
                              sx={{
                                fontSize: "13px",
                                color: "white",
                                fontWeight: "500",
                              }}
                            >
                              Showing {data2?.length} Flight Results
                            </Typography>
                          </Box>
                        </Box>
                        {isLoaded ? (
                          <RoundWayFilter
                            selectedAirLines={selectedAirLines}
                            setSelectedAirLines={setSelectedAirLines}
                            data={data}
                            data2={data2}
                            sortedBy={sortedBy}
                            setSortedBy={setSortedBy}
                            initialFetchData={initialFetchData}
                            filteredData={data2}
                            setfilteredData={setData2}
                          />
                        ) : (
                          <Box
                            sx={{
                              width: "100%",
                              borderRadius: "5px",
                              overFlow: "hidden",
                              bgcolor: "var(--white)",
                              padding: "10px",
                              mt: "25px",
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              my={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"50%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"50%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"100%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                            </Stack>
                          </Box>
                        )}
                      </Box>
                    </Drawer>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={9.6}>
                {/* //todo:airline slider */}
                {/* <Grid
                  my={2}
                  mx={"auto"}
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                ></Grid> */}

                {/* //todo:main search result */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    height: "fit-content",
                    mt: "25px",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "white",
                      borderRadius: "5px",
                      mb: "12px",
                    }}
                  >
                    {isLoaded ? (
                      <AirlinesSlider
                        setData2={setData2}
                        handleFilterByAirLine={handleFilterByAirLine}
                        initialFetchData={initialFetchData}
                        selectedAirLines={selectedAirLines}
                        setSelectedAirLines={setSelectedAirLines}
                        data={datas}
                        isLoaded={isLoaded}
                        // handleFilterByAirLine={handleFilterByAirLine}
                      />
                    ) : (
                      [...new Array(1)].map((data, index) => (
                        <Box
                          key={index}
                          style={{
                            width: "100%",
                            height: "50px",
                            // margin: "0 10px 0 0",
                            borderRadius: "5px",
                            overFlow: "hidden",
                            backgroundColor: "var(--white)",
                            width: "100%",
                            borderRadius: "5px",
                            overFlow: "hidden",
                          }}
                        >
                          {/* <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"100%"}
                      /> */}
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{
                              height: "100%",
                              px: "20px",
                            }}
                          >
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                          </Stack>
                        </Box>
                      ))
                    )}
                  </Box>
                  {isLoaded
                    ? data2
                        ?.slice(startIndex, endIndex)
                        ?.map((roundTrip, index) => {
                          return (
                            <RoundSingleFlight
                              data={requiredSearchData}
                              key={index}
                              roundData={roundTrip}
                              adultCount={adultCount}
                              childCount={childCount}
                              infant={infant}
                              fromSendData={fromSendData}
                              toSendData={toSendData}
                              tripType={tripType}
                              departureDate={departureDate}
                              returingDate={returningDate}
                              faddress={faddress}
                              toAddress={toAddress}
                              agentFarePrice={agentFarePrice}
                              setAgentFarePrice={setAgentFarePrice}
                              commisionFarePrice={commisionFarePrice}
                              setCommisionFarePrice={setCommisionFarePrice}
                              customerFare={customerFare}
                              setCustomerFare={setCustomerFare}
                              quotetionArr={quotetionArr}
                              setQuotetionArr={setQuotetionArr}
                            />
                          );
                        })
                    : [...new Array(5)].map((data, index) => (
                        <>
                          <ReturnSearchSkeleton />
                        </>
                      ))}
                  <Box
                    sx={{
                      width: "100%",
                      my: 3,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(data2.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, page) => setCurrentPage(page)}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                lg={2.4}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },

                  // ml: "10px"
                }}
              >
                <Box mt={3.1} mb={1.8}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      color: "white",
                      bgcolor: "var(--primary-color)",
                    }}
                  >
                    <SessionTimer />
                  </Button>
                  <Box
                    sx={{
                      bgcolor: "var(--secondary-color)",
                      py: "3px",
                      textAlign: "center",
                      borderRadius: "3px",

                      mt: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "13px",
                        color: "white",
                        fontWeight: "500",
                      }}
                    >
                      Showing {data2?.length} Flight Results
                    </Typography>
                  </Box>
                </Box>
                {isLoaded ? (
                  <RoundWayFilter
                    selectedAirLines={selectedAirLines}
                    setSelectedAirLines={setSelectedAirLines}
                    data={data}
                    data2={data2}
                    sortedBy={sortedBy}
                    setSortedBy={setSortedBy}
                    initialFetchData={initialFetchData}
                    filteredData={data2}
                    setfilteredData={setData2}
                  />
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      overFlow: "hidden",
                      bgcolor: "var(--white)",
                      padding: "10px",
                      mt: "25px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      my={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                    </Stack>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          {quotetionArr?.length ? (
            <Box
              sx={{
                position: "fixed",
                bottom: "0",
                width: "75%",
                background: "var(--primary-color)",
                padding: "10px 0px",
                boxShadow: "0px -1px 5px 0px rgba(0,0,0,0.75)",
                zIndex: 100,
              }}
            >
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "white",
                      pl: 7,
                    }}
                  >{`${quotetionArr?.length} Flights Selected`}</Typography>
                  <Button
                    onClick={() => {
                      navigate("/dashboard/make-quotation", {
                        state: {
                          quotetionArr,
                          adultCount,
                          childCount,
                          infant,
                          to,
                          from,
                          tripType,
                          toAddress,
                        },
                      });
                    }}
                    style={{
                      background: "var(--secondary-color)",
                      color: "var(--primary-color)",
                      fontSize: "14px",
                      fontWeight: "500",
                      padding: "5px 10px",
                    }}
                  >
                    Make Quotation
                  </Button>
                </Box>
              </Container>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default RoundSearchResult;
