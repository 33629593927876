import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AiOutlineSwap } from "react-icons/ai";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import flightData from "../flightData";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import moment from "moment";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #003566",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const GroupFareSearchBox = ({
  tripType,
  iconColor,
  bgColor,
  borderColor,
  faddress,
  setfaddress,
  toAddress,
  setToAddress,
  fromSearchText,
  setFromSearchText,
  fromSendData,
  setFromSendData,
  toSendData,
  setToSendData,
  toSearchText,
  setToSearchText,
  departureDate,
  setDepartureDate,
  setValue,
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  result,
  setResult,
  className,
  handleClassName,
  travelDate,
  setTravelDate,
  to,
  setTo,
  from,
  setFrom,
  setChangeState,
  changeState,
  changeFrom,
  setChangeFrom,
  agentId,
}) => {
  const data = flightData; // json data from flight Data

  const initialData = [
    {
      code: "DAC",
      name: "Hazrat Shahjalal Intl Airport",
      Address: "Dhaka,BANGLADESH",
    },
    {
      code: "DXB",
      name: "Dubai Intl Airport",
      Address: "Dubai,UNITED ARAB EMIRATES",
    },
    {
      code: "CXB",
      name: "Cox's Bazar Airport",
      Address: "Cox's Bazar,Bangladesh",
    },
    {
      code: "JSR",
      name: "Jashore Airport",
      Address: "Jashore,Bangladesh",
    },
    {
      code: "BZL",
      name: "Barishal Airport",
      Address: "Barishal,Bangladesh",
    },
    {
      code: "RJH",
      name: "Shah Makhdum Airport",
      Address: "Rajshahi,Bangladesh",
    },
    {
      code: "SPD",
      name: "Saidpur Airport",
      Address: "Saidpur,Bangladesh",
    },
  ];
  //todo: users section
  const [users, setUsers] = useState("");

  const location = useLocation();
  useEffect(() => {
    const users = secureLocalStorage.getItem("user-info");
    if (users) {
      setUsers(users);
    }
  }, []);
  // todo: end of users section
  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state
  const [fromSuggest, setFromSuggest] = useState(initialData);
  const [toSuggest, setToSuggest] = useState(initialData);

  const [open, setOpen] = useState(false);
  const [openDate, setOpenDate] = useState(false);

  const navigate = useNavigate();

  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);

  const handleClickAway = () => {
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setOpen(false);
    setResult(adultCount + childCount + infant);
  };

  const handleSwapBtn = () => {
    setfaddress(toAddress);
    setToAddress(faddress);
    setFromSendData(toSendData);
    setToSendData(fromSendData);
    setFromSearchText(toSearchText);
    setToSearchText(fromSearchText);
  };

  const formOnChange = (e) => {
    setOpen(false);
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setFromSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setFromSuggest(suggestion);
      }
    } else {
      setFromSuggest(initialData);
    }
  };

  const fromSuggestedText = (name, code, address) => {
    setFromSendData(code);
    setFromSearchText(`${name} (${code})`);
    setFromSuggest([]);
    setfaddress(address);
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(true);
  };

  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    if (searchvalue.length > 2) {
      const suggestion = data.filter((item) =>
        item.code.toLowerCase().includes(searchvalue.toLowerCase())
      );
      setToSuggest(suggestion);
      if (suggestion.length === 0) {
        const suggestion = data.filter(
          (item) =>
            item.code.toLowerCase().includes(searchvalue.toLowerCase()) ||
            item.Address.toLowerCase().includes(searchvalue.toLowerCase())
        );
        setToSuggest(suggestion);
      }
    } else {
      setToSuggest(initialData);
    }
  };
  const toSuggestedText = (name, code, address) => {
    setToSendData(code);
    setToSearchText(`${name} (${code})`);
    setToSuggest([]);
    setToAddress(address);
    setOpenTo(false);
    setTimeout(() => setOpenDate(true), 200);
  };

  const fromGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {fromSuggest.length !== 0 ? (
            fromSuggest.map((item, index) => {
              return (
                <Box
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "10px",
                    backgroundColor: "#fff",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "#D1E9FF",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(
                        ` ${item.name}`,
                        ` ${item.code} `,
                        `${item.Address}`
                      );
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#003566",
                          display: "block",
                          textAlign: "left",
                          fontWeight: "500",
                        }}
                      >
                        {item.Address}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "#DC143C",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const toGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "5px" },
          }}
        >
          {toSuggest.length !== 0 ? (
            toSuggest.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "20px",
                    paddingRight: "5px",
                    backgroundColor: "#fff",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "#D1E9FF",
                    },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() =>
                      toSuggestedText(
                        ` ${item.name}`,
                        `${item.code}`,
                        `${item.Address}`
                      )
                    } //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <span
                        style={{
                          fontSize: "12px",
                          color: "#003566",
                          display: "block",
                          textAlign: "left",
                        }}
                      >
                        {item.Address}
                      </span>
                      <span
                        style={{
                          fontSize: "11px",
                          display: "block",
                          color: "#999",
                          textAlign: "left",
                        }}
                      >
                        {item.name}
                      </span>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          fontSize: "13px",
                          paddingRight: "10px",
                          display: "block",
                          textAlign: "left",
                          color: "#999",
                          fontWeight: "600",
                        }}
                      >
                        {item.code}
                      </span>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "#DC143C",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };
  // SearchingField End

  // Opens the dialog when the user clicks.
  const handleClickOpen = () => {
    setOpen((prev) => !prev);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
  };

  // passenger info

  const [openClass, setOpenClass] = useState(false);

  // Closes the child process.
  const handleClose = () => {
    setOpen(false);
    setOpenFrom(false);
    setOpenTo(false);
    setOpenDate(false);
    setResult(adultCount + childCount + infant);
  };

  // Sets the number of children.
  function adultInclement(e) {
    e.preventDefault();
    if (adultCount < 9 - (childCount + infant)) {
      setAdultCount(adultCount + 1);
    }
  }

  // Decrement the count of children.
  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
        }
      }
    }
  }

  function adult2Inclement(e) {
    e.preventDefault();
    if (childCount < 9 - (adultCount + infant)) {
      setChildCount(childCount + 1);
    }
  }

  function adult2Decrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
    }
  }

  // Increment the default value if the value is not a child.
  function infantIncrement(e) {
    e.preventDefault();
    if (infant < 9 - (adultCount + childCount)) {
      if (infant < adultCount) {
        setInfant(infant + 1);
      }
    }
  }

  // Decrement the infant by 1.
  function infantDecrement(e) {
    e.preventDefault();
    if (infant > 0) {
      setInfant(infant - 1);
    }
  }

  //todo: form Submit function
  // async function handleSearch(e) {
  //   e.preventDefault();
  //   navigate("/dashboard/groupfareresult");
  // }

  const handleSearchGroupFare = (name) => {
    navigate("/dashboard/groupfareresult", {
      state: {
        name: name,
        fromSendData,
        toSendData,
        departureDate: moment(from, "DD MMM YY").format("YY-MM-DD"),
      },
    });
  };

  //todo: end of form Submit section
  const handleSelect = (date) => {
    setFrom(date);
    setChangeFrom(true);
    setOpenDate(false);
    setTimeout(() => setOpen(true), 200);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Grid item position="relative" sx={{ ml: "10px" }}>
          <Stack
            direction="row"
            spacing={{ xs: 4, sm: 4, md: 5, lg: 5 }}
            justifyContent="flex-start"
          >
            <Typography
              sx={{
                color: "var(--light-gray)",
                fontWeight: 400,
                cursor: "pointer",
                position: "relative",
                fontSize: 13,
              }}
              onClick={() => {
                setOpen((prev) => !prev);
                setOpenClass(false);
              }}
            >
              For {adultCount + childCount + infant}&nbsp;Passenger
              <ArrowDropDownSharpIcon
                sx={{
                  padding: 0,
                  fontSize: 30,
                  position: "absolute",
                  color: "var(--light-gray)",
                  top: "-5px",
                }}
              />
            </Typography>
            <Typography
              sx={{
                color: "var(--light-gray)",
                fontWeight: 400,
                cursor: "pointer",
                position: "relative",
                fontSize: 13,
                pr: 3,
              }}
              onClick={() => {
                setOpenClass((prev) => !prev);
                setOpen(false);
              }}
            >
              {className || "Economy"}
              <ArrowDropDownSharpIcon
                sx={{
                  padding: 0,
                  fontSize: 30,
                  position: "absolute",
                  color: "var(--light-gray)",
                  top: "-5px",
                }}
              />
            </Typography>
          </Stack>
          {open && (
            <Box
              sx={{
                position: "absolute",
                top: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                left: "0px",
                zIndex: 1000,
                borderRadius: "5px",
                backgroundColor: "var(--white)",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
            >
              <Box width="250px" p={2}>
                <Typography
                  style={{
                    textAlign: "left",
                    marginBottom: "5px",
                    color: "var(--black)",

                    fontWeight: 400,
                  }}
                >
                  Passenger
                </Typography>
                <Stack
                  direction="row"
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="center"
                  pb={1}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    width="40%"
                  >
                    <button
                      onClick={adultDecrement}
                      style={{
                        backgroundColor: "var(--black)",
                        color: "var(--white)",
                        border: "none",
                        width: "25px",
                        height: "25px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "2px",
                      }}
                    >
                      -
                    </button>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "var(--black)",
                      }}
                    >
                      {adultCount}
                    </Typography>
                    <button
                      onClick={adultInclement}
                      style={{
                        backgroundColor: "var(--black)",
                        color: "var(--white)",
                        border: "none",
                        width: "25px",
                        height: "25px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "2px",
                      }}
                    >
                      +
                    </button>
                  </Stack>
                  <Box width="60%">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "var(--black)",
                      }}
                    >
                      Adult
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "var(--black)",
                      }}
                    >
                      12+ yrs
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="center"
                  pb={1}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    width="40%"
                  >
                    <button
                      onClick={adult2Decrement}
                      style={{
                        backgroundColor: "var(--black)",
                        color: "var(--white)",
                        border: "none",
                        width: "25px",
                        height: "25px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "2px",
                      }}
                    >
                      -
                    </button>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "var(--black)",
                      }}
                    >
                      {childCount}
                    </Typography>
                    <button
                      onClick={adult2Inclement}
                      style={{
                        backgroundColor: "var(--black)",
                        color: "var(--white)",
                        border: "none",
                        width: "25px",
                        height: "25px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "2px",
                      }}
                    >
                      +
                    </button>
                  </Stack>
                  <Box width="60%">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "var(--black)",
                      }}
                    >
                      Child
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "var(--black)",
                      }}
                    >
                      2- less than 12 yrs
                    </Typography>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={4}
                  justifyContent="space-between"
                  alignItems="center"
                  pb={1}
                >
                  <Stack
                    direction="row"
                    spacing={1}
                    justifyContent="space-between"
                    width="40%"
                  >
                    <button
                      onClick={infantDecrement}
                      style={{
                        backgroundColor: "var(--black)",
                        color: "var(--white)",
                        border: "none",
                        width: "25px",
                        height: "25px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "2px",
                      }}
                    >
                      -
                    </button>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "var(--black)",
                      }}
                    >
                      {infant}
                    </Typography>
                    <button
                      onClick={infantIncrement}
                      style={{
                        backgroundColor: "var(--black)",
                        color: "var(--white)",
                        border: "none",
                        width: "25px",
                        height: "25px",
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "2px",
                      }}
                    >
                      +
                    </button>
                  </Stack>
                  <Box width="60%">
                    <Typography
                      sx={{
                        fontSize: "14px",
                        color: "var(--black)",
                      }}
                    >
                      Infant
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px",
                        color: "var(--black)",
                      }}
                    >
                      0 - 23 month
                    </Typography>
                  </Box>
                </Stack>
                <Box mt={2} style={{ textAlign: "right" }}>
                  <Button
                    size="small"
                    onClick={handleClose}
                    className="shine-effect"
                    style={{
                      backgroundColor: "var(--black)",
                      color: "var(--white)",
                    }}
                  >
                    DONE
                  </Button>
                </Box>
              </Box>

              {/* -----------new passenger- */}
            </Box>
          )}
          {openClass && (
            <Box
              sx={{
                position: "absolute",
                top: { lg: "100%", md: "100%", sm: "100%", xs: "100%" },
                left: "0px",
                zIndex: 1000,
                borderRadius: "5px",
                backgroundColor: "var(--white)",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
              }}
            >
              <Box width="250px" p={2}>
                <Typography
                  style={{
                    marginBottom: "5px",
                    color: "var(--black)",
                    fontWeight: 400,
                  }}
                >
                  Flight Class
                </Typography>
                {/* ------- */}
                <Box>
                  <FormControl>
                    <RadioGroup value={className} onChange={handleClassName}>
                      <Grid
                        container
                        spaceing={1}
                        sx={{ display: "flex", flexDirection: "column" }}
                      >
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="First"
                            control={
                              <Radio
                                sx={{
                                  color: "var(--black)",
                                  "&.Mui-checked": {
                                    color: "var(--black)",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  color: "var(--black)",
                                  fontSize: 13,
                                }}
                              >
                                First
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="PremiumEconomy"
                            control={
                              <Radio
                                sx={{
                                  color: "var(--black)",
                                  "&.Mui-checked": {
                                    color: "var(--black)",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  color: "var(--black)",
                                  fontSize: 13,
                                }}
                              >
                                PremiumEconomy
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="  PremiumFirst"
                            control={
                              <Radio
                                sx={{
                                  color: "var(--black)",
                                  "&.Mui-checked": {
                                    color: "var(--black)",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  color: "var(--black)",
                                  fontSize: 13,
                                }}
                              >
                                PremiumFirst
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="Economy"
                            control={
                              <Radio
                                sx={{
                                  color: "var(--black)",
                                  "&.Mui-checked": {
                                    color: "var(--black)",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  color: "var(--black)",
                                  fontSize: 13,
                                }}
                              >
                                Economy
                              </Typography>
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <FormControlLabel
                            value="Business"
                            control={
                              <Radio
                                sx={{
                                  color: "var(--black)",
                                  "&.Mui-checked": {
                                    color: "var(--black)",
                                  },
                                }}
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  color: "var(--black)",
                                  fontSize: 13,
                                }}
                              >
                                Business
                              </Typography>
                            }
                          />
                        </Grid>
                      </Grid>
                    </RadioGroup>
                  </FormControl>

                  <Box mt={2} style={{ textAlign: "right" }}>
                    <Button
                      size="small"
                      onClick={() => setOpenClass(false)}
                      className="shine-effect"
                      style={{
                        backgroundColor: "var(--black)",
                        color: "var(--white)",
                      }}
                    >
                      DONE
                    </Button>
                  </Box>
                </Box>
              </Box>
              {/* -----------new passenger- */}
            </Box>
          )}
        </Grid>

        <Box sx={{ position: "relative", mt: "1vh" }}>
          <form>
            <Box sx={{ display: "flex", gap: "15px" }}>
              <Button
                onClick={() => handleSearchGroupFare("search-all")}
                disabled={
                  !agentId ||
                  !agentId.trim() ||
                  (faddress?.split(",")[0] === toAddress?.split(",")[0] &&
                    !click) ||
                  (faddress?.split(",")[0] !== toAddress?.split(",")[0] &&
                    click)
                }
                className="shine-effect"
                sx={{
                  fontSize: "15px",
                  height: { lg: "36%", md: "100%", sm: "100%", xs: "100%" },
                  width: {
                    lg: "20%",
                    md: "90%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },
                  mb: "3vh",
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                  display: {
                    lg: "flex",
                    md: "flex",
                    sm: "none",
                    xs: "none",
                  },
                  position: "absolute",
                  right: location?.pathname === "/" ? "150px" : "210px",
                  bottom: "77px",
                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "#FBEA78",
                    cursor: "pointer",
                  },
                }}
              >
                <Box>See All Group Fares</Box>
              </Button>
              <Button
                onClick={() => handleSearchGroupFare("search-by-route")}
                className="shine-effect"
                sx={{
                  fontSize: "15px",
                  height: { lg: "36%", md: "100%", sm: "100%", xs: "100%" },
                  width: {
                    lg: "12%",
                    md: "90%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },
                  mb: "3vh",
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                  display: {
                    lg: "flex",
                    md: "flex",
                    sm: "none",
                    xs: "none",
                  },
                  gap: "5px",
                  position: "absolute",
                  right: location.pathname === "/" ? "0px" : "30px",
                  bottom: "77px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                    cursor: "pointer",
                  },
                }}
              >
                <Box>{click ? "Wait..." : "Search"}</Box>
                <AirplanemodeActiveIcon
                  sx={{ fontSize: "23px", transform: "rotate(45deg)" }}
                />
              </Button>
            </Box>
            <Grid
              sx={{
                height: "fit-content",
                width: "100%",
                pt: "20px",
              }}
              container
              rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 1 }}
              columnSpacing={0}
            >
              {/* //todo: Departure City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3.9}
                lg={3.9}
                sx={{
                  position: "relative",
                  pl: "10px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenFrom((prev) => !prev);
                    setOpenTo(false);
                    setOpenDate(false);
                    setOpen(false);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={0.5}
                      >
                        FROM
                      </Typography>
                      {/* <span>{faddress?.split(",")[0]}</span> */}
                      {faddress?.split(",")[0] === toAddress?.split(",")[0] && (
                        <Stack
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          <Alert
                            icon={<ErrorOutlineIcon fontSize="inherit" />}
                            severity="error"
                            sx={{ fontSize: "11px" }}
                          >
                            Can't choose same place!
                          </Alert>
                        </Stack>
                      )}
                    </Box>

                    <Box sx={{ width: "90%" }}>
                      <span
                        style={{
                          width: "100%",
                          color: "var(--light-gray)",
                          fontSize: "13px",
                        }}
                      >
                        {fromSearchText}
                      </span>
                    </Box>
                  </Box>
                </Box>

                {openFrom && (
                  <Box
                    style={{
                      position: "absolute",
                      top: "105%",
                      left: "0",
                      right: "0",
                      width: "100%",
                      backgroundColor: "var(--white)",
                      height: "fit-content",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "5px",
                      zIndex: "999",
                      padding: "5px 5px 0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#003566",
                        zIndex: 10,
                      }}
                      backgroundColor="#fff"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={formOnChange}
                        placeholder="Search a airport..."
                        className="customPlaceholder"
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{fromGetSuggetion()}</Box>
                  </Box>
                )}
              </Grid>
              {/* //todo: Arrival City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3.9}
                lg={3.9}
                sx={{
                  position: "relative",
                  padding: "5px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenFrom(false);
                    setOpenTo((prev) => !prev);
                    setOpenDate(false);
                    setOpen(false);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box sx={{ position: "relative" }}>
                      <Typography
                        style={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={1}
                      >
                        TO
                      </Typography>
                      <span>
                        {faddress?.split(",")[0] ===
                          toAddress?.split(",")[0] && (
                          <Stack
                            style={{
                              position: "absolute",
                              top: "100%",
                              left: "0",
                              width: "100%",
                            }}
                          >
                            <Alert
                              icon={<ErrorOutlineIcon fontSize="inherit" />}
                              severity="error"
                              sx={{ fontSize: "11px" }}
                            >
                              Can't choose same place!
                            </Alert>
                          </Stack>
                        )}
                      </span>
                    </Box>
                    <Box
                      style={{
                        width: "90%",
                      }}
                    >
                      <span
                        style={{
                          width: "100%",
                          color: "var(--light-gray)",
                          fontSize: "13px",
                        }}
                      >
                        {toSearchText}
                      </span>
                    </Box>
                  </Box>
                </Box>
                {openTo && (
                  <Box
                    style={{
                      position: "absolute",
                      top: "105%",
                      left: "0",
                      width: "100%",
                      backgroundColor: "var(--white)",
                      border: "1px solid var(--primary-color",
                      height: "fit-content",
                      borderRadius: "5px",
                      zIndex: "999",
                      padding: "5px 5px 0",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "var(--secondary-color)",
                        zIndex: 10,
                      }}
                      backgroundColor="var(--white)"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={toOnChange}
                        className="customPlaceholder"
                        placeholder="Search a airport..."
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{toGetSuggetion()}</Box>
                  </Box>
                )}
              </Grid>
              {/* //todo:Departure Date */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3.9}
                lg={3.9}
                sx={{
                  position: "relative",
                  padding: "5px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setTimeout(() => setOpenDate((prev) => !prev), 200);
                    setOpenFrom(false);
                    setOpenTo(false);
                    setOpen(false);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={1}
                      >
                        Departure Date
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "var(--light-gray)",
                          }}
                        >{`${format(new Date(from), "dd MMM yy")}`}</span>

                        <CalendarMonthIcon
                          sx={{ color: "var(--secondary-color)" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {openDate && (
                  <Box>
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(from)}
                      onChange={handleSelect}
                      months={1}
                      direction="horizontal"
                      minDate={new Date()}
                      className={"dashboard-calendar"}
                    />
                  </Box>
                )}
              </Grid>

              {/* //todo: Search Button */}
            </Grid>
            <Box
              sx={{
                mt: {
                  xs: "10px",
                  sm: "10px",
                  md: "0",
                  lg: "0",
                },
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                },
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                },
              }}
            >
              <Button
                onClick={() => handleSearchGroupFare("search-all")}
                disabled={
                  !agentId ||
                  !agentId.trim() ||
                  (faddress?.split(",")[0] === toAddress?.split(",")[0] &&
                    !click) ||
                  (faddress?.split(",")[0] !== toAddress?.split(",")[0] &&
                    click)
                }
                className="shine-effect"
                sx={{
                  fontSize: "15px",
                  height: { lg: "36%", md: "100%", sm: "100%", xs: "100%" },
                  width: {
                    lg: "20%",
                    md: "90%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },

                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                  display: {
                    lg: "none",
                    md: "none",
                    sm: "flex",
                    xs: "flex",
                  },

                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                    cursor: "pointer",
                  },
                }}
              >
                <Box>See All Group Fares</Box>
              </Button>
              <Button
                onClick={() => handleSearchGroupFare("search-by-route")}
                className="shine-effect"
                sx={{
                  fontSize: "15px",
                  height: { lg: "36%", md: "100%", sm: "100%", xs: "100%" },
                  width: {
                    lg: "12%",
                    md: "90%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },

                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                  display: {
                    lg: "flex",
                    md: "flex",
                    sm: "flex",
                    xs: "flex",
                  },
                  gap: "5px",

                  right: "0px",

                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                    cursor: "pointer",
                  },
                }}
              >
                <Box>{click ? "Wait..." : "Search"}</Box>
                <AirplanemodeActiveIcon
                  sx={{ fontSize: "23px", transform: "rotate(45deg)" }}
                />
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default GroupFareSearchBox;
