import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
// import FlightCards from "./FlightCards";
import { Segment } from "@mui/icons-material";
import moment from "moment";
// import MultiFlightDetails from "./MultiFlightDetails";
import { useNavigate } from "react-router-dom";
import MultiCityQueueDetails from "../MultiCity/MultiCityQueueDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import secureLocalStorage from "react-secure-storage";
import Swal from "sweetalert2";
const isMobile = window.innerWidth <= 768;

const SingleMultiCityItem = ({
  click,
  setClick,
  flightData,
  adultCount,
  childCount,
  infant,
  to,
  from,
  tripType,
  fromAddress,
  toAddress,
  dDate,
  agentFarePrice,
  setAgentFarePrice,
  commisionFarePrice,
  setCommisionFarePrice,
  customerFare,
  setCustomerFare,
  isLoaded,
  quotetionArr,
  setQuotetionArr,
}) => {
  const navigate = useNavigate();

  const data = {
    flightData,
    adultCount,
    childCount,
    infant,
    to: flightData?.arrival,
    from: flightData?.departure,
    tripType: "multicity",
    // fromAddress: flightData.segments[0]?.departureLocation,
    // toAddress:
    //   flightData.segments[flightData.segments?.length - 1]?.arrivalLocation,
    // dDate: moment(flightData.departureDate, "ddd DD MMM YYYY").format(
    //   "YYYY-MM-DD"
    // ),
    clientFare: flightData.customerPrice,
    agentFare: flightData.agentprice,
    // commission:
    //   parseFloat(flightData.customerPrice) - parseFloat(flightData.agentFare),
  };

  const loggedIn = secureLocalStorage.getItem("user-info");

  const flightInformation = () => {
    if (loggedIn) {
      navigate("/dashboard/multicityflightinfo", {
        state: {
          flightData,
          adultCount,
          childCount,
          infant,
          to,
          from,
          tripType,
          fromAddress,
          toAddress,
          dDate,
          // clientFare,
          // agentFare,
          // commission,
        },
      });
    } else {
      Swal.fire({
        title: "Please, Log in first",
        text: "Please log in first to book the flight.",
        icon: "warning",
        confirmButtonText: "OK",
      });
    }
  };

  const [expanded, setExpanded] = useState(false);
  const [checked, setChecked] = useState(false);

  const calDuration = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let seconds = Math.round((totalMinutes - (hours * 60 + minutes)) * 60);

    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }

    return `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}min`;
  };

  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const bag = flightData?.PriceBreakDown[0]?.Bag[0]?.Allowance?.weight;

  const handleBox = (data) => {
    const event = window.event;
    setChecked(event.target.checked);
    if (event.target.checked) {
      setQuotetionArr([...quotetionArr, flightData]);
    } else {
      const filter = quotetionArr?.filter((item) => item?.uId !== data.uId);
      setQuotetionArr(filter);
    }
  };

  return (
    <Box
      sx={{
        bgcolor: "white",
        borderRadius: "3px",
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        px: "24px",
      }}
    >
      <Box
        sx={{
          border: "1px solid re",
          mt: "15px",
          ".MuiTypography-body2": {
            fontSize: "13px",
          },
          ".MuiTypography-title-lg": {
            fontSize: "24px",
            color: "#444542",
            fontWeight: "500",
          },
        }}
      >
        {isMobile ? (
       
            <Box onClick={flightInformation} sx={{ cursor: "pointer" }}>
              <Grid
                container
                sx={{
                  transition: "all .5s ease-in-out",
                  bgcolor: "var(--white)",
                  marginBottom: "10px",
                  borderRadius: "5px",
                  overflow: "hidden",
                }}
              >
                <Grid container xs={12} sx={{pt:"10px"}}>
                  <Grid item xs={12} md={12} sm={12}>
                    {flightData.AllLegs.map((segment, i, arr) => {
                      const { segments, DepFrom, ArrTo } = segment;
                      return (
                        <Grid
                          container
                          item
                          xs={12}
                          height="fit-content"
                          width="100%"
                          key={i}
                         spacing={0.5}
                          alignItems="start"
                          mb="5px"
                        >
                          {/* //todo: first section Flight Image */}
                          <Grid
                            item
                            sm={12}
                            xs={12}
                            sx={{ display: { xs: "block", md: "flex" } }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                                height: "100%",
                              }}
                            >
                              <Box sx={{ width: "30%" }}>
                                <Box sx={{ width: "50px", height: "50px" }}>
                                  <img
                                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${segments[0]?.marketingcareer}.png`}
                                    width="50px"
                                    height="50px"
                                    alt=""
                                  />
                                </Box>
                              </Box>

                              <Box sx={{ width: "100%" }}>
                                <Typography
                                  sx={{
                                    color: "var(--secondary-color)",
                                    fontWeight: "normal",
                                    fontSize: {
                                      xs: "14px",
                                      sm: "14px",
                                      md: "14px",
                                    },
                                    cursor: "pointer",
                                    width: "100%",
                                  }}
                                  noWrap
                                >
                                  {segments[0]?.marketingcareerName}
                                </Typography>

                                <Box sx={{ display: "flex" }}>
                                  {segments?.map((seg, i) => (
                                    <Typography
                                      key={i}
                                      sx={{
                                        color: "#222",
                                        fontWeight: "500",
                                        pt: "2px",
                                        fontSize: "10px",
                                      }}
                                    >
                                      {i >= 1 && " & "} {seg.marketingcareer}
                                      {seg.marketingflight} {i === 0 && ""}
                                    </Typography>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          </Grid>
                          {/* //todo: Departure Section */}
                          <Grid item sm={5} xs={4.5} sx={{ my: "10px" }}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: { xs: "block", md: "flex" },
                                justifyContent: "start",
                                alignItems: "start",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ color: "#8C8080" }}
                              >
                                Departure
                              </Typography>
                              <Typography
                                sx={{
                                  mt: "5px",
                                  width: "100%",
                                  color: "var(--mateBlack)",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "12px",
                                    sm: "13px",
                                    md: "18px",
                                  },
                                }}
                                noWrap
                              >
                                {DepFrom}{" "}
                                {moment(segments[0]?.departureTime).format(
                                  "hh:mm a"
                                )}
                              </Typography>
                              <Typography
                                sx={{
                                  width: "100%",
                                  fontSize: "10px",
                                  fontWeight: "normal",
                                }}
                              >
                                {moment(
                                  segments[0]?.departureTime?.slice(0, 19)
                                ).format("ddd, Do MMMM YYYY")}
                              </Typography>
                            </Box>
                          </Grid>
                          {/* //todo: Animation Part */}
                          <Grid item sm={2} xs={3} sx={{ my: "10px" }}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                              }}
                            >
                              <Box>
                                <Typography
                                  sx={{
                                    color: "var(--light-gray)",
                                    fontWeight: "normal",
                                    mt: "25px",
                                    fontSize: {
                                      xs: "10px",
                                      sm: "10px",
                                      md: "11px",
                                    },
                                    textAlign: "center",
                                  }}
                                >
                                  {segment?.segments?.length === 1
                                    ? `Non Stop`
                                    : segment?.segments?.length === 2
                                    ? `1 Stop`
                                    : segment?.segments?.length === 3
                                    ? `2 Stops`
                                    : ""}{" "}
                                  {segment?.segments
                                    ?.slice(0, segment?.segments?.length - 1)
                                    .map((item, i) => (
                                      <span key={i}> via {item.ArrTo}</span>
                                    ))}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          {/* //todo: Arrival section */}
                          <Grid item sm={5} xs={4.5} sx={{ my: "10px" }}>
                            <Box
                              sx={{
                                width: "100%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "end",
                              }}
                            >
                              <Typography
                                variant="body2"
                                sx={{ color: "#8C8080" }}
                              >
                                Arrival
                              </Typography>
                              <Typography
                                sx={{
                                  width: "100%",
                                  mt: "5px",
                                  color: "var(--mateBlack)",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "12px",
                                    sm: "13px",
                                    md: "18px",
                                  },
                                  textAlign: "right",
                                }}
                                noWrap
                              >
                                {ArrTo}{" "}
                                {moment(
                                  segments[segments?.length - 1].arrivalTime
                                ).format("hh:mm a")}
                              </Typography>

                              <Typography
                                sx={{
                                  width: "100%",
                                  fontSize: "10px",
                                  textAlign: "right",
                                }}
                              >
                                {moment(
                                  segments[
                                    segments?.length - 1
                                  ].arrivalTime?.slice(0, 19)
                                ).format("ddd, Do MMMM YYYY")}
                              </Typography>
                            </Box>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <Grid
                      container
                      direction={{ xs: "row", sm: "row", md: "row" }}
                      sx={{
                        width: "100%",
                        height: "100%",
                        pt: "2px",
                        borderTop: "1px solid var(--light-gray)",
                      }}
                    ></Grid>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    style={{
                      width: "100%",
                      height: "70px",
                    }}
                  >
                    <Grid
                      container
                      alignItems="center"
                      sx={{
                        width: "100%",
                        height: "100%",
                        background: "transparent",
                        padding: "10px 0",
                      }}
                    >
                      <Grid item sm={6} xs={6}>
                        <Typography
                          sx={{
                            color: !flightData?.nonRefundable ? "green" : "red",
                            fontWeight: 500,
                            fontSize: {
                              xs: "14px",
                              sm: "12px",
                              md: "14px",
                              lg: "16px",
                            },
                            width: "100%",
                            height: "fit-content",
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "center",
                          }}
                        >
                          {!flightData?.nonRefundable
                            ? "Refundable"
                            : "Non Refundable"}
                        </Typography>
                      </Grid>
                      <Grid item sm={6} xs={6}>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "start",
                            alignItems: "end",
                            flexDirection: "column",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "14px",   color: "var(--secondary-color)", }}
                          >
                            <del>
                              {Number(flightData?.grosscost).toLocaleString(
                                "en-IN"
                              )}{" "}
                              ৳
                            </del>
                          </Typography>
                          <Typography sx={{ fontSize: "14px" }}>
                            {Number(flightData?.netcost).toLocaleString(
                              "en-IN"
                            )}{" "}
                            ৳
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
         
        ) : (
          <>
            <Grid container>
              <Grid item lg={10} sx={{ pb: "15px" }}>
                {flightData.AllLegs.map((segment, i, arr) => {
                  const { segments, DepFrom, ArrTo } = segment;

                  return (
                    <Grid
                      container
                      justifyContent={"space-between"}
                      bgcolor={"ble"}
                      my={2}
                      sx={{
                        mb: "18px",
                      }}
                      rowGap={4}
                    >
                      <Grid order={{ xs: 1, lg: 1 }} xs={5.8} lg={2}>
                        <img
                          src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${segments[0]?.marketingcareer}.png`}
                          width="50px"
                          height="50px"
                          alt=""
                        />

                        <Typography
                          variant="body2"
                          sx={{
                            color: "var(--secondary-color)",
                            fontWeight: "500",
                          }}
                        >
                          {segments[0]?.marketingcareerName}
                        </Typography>
                        <>
                          {segments?.map((seg, i) => (
                            <Typography
                              key={i}
                              variant="body2"
                              sx={{
                                color: "#222",
                                fontWeight: "500",
                                pt: "2px",
                              }}
                            >
                              {i >= 1 && " & "} {seg.marketingcareer}
                              {seg.marketingflight} {i === 0 && ""}
                            </Typography>
                          ))}
                        </>
                      </Grid>

                      <Grid order={{ xs: 3, lg: 2 }} xs={5.8} lg={3.5}>
                        <Typography variant="body2" sx={{ color: "#8C8080" }}>
                          Departure
                        </Typography>
                        <Typography variant="title-lg">{DepFrom}</Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "var(--secondary-color)" }}
                        >
                          {segments[0]?.departureAirport
                            ? `${segments[0]?.departureAirport}`
                            : segments[0]?.departureLocation ||
                              "Airpot Name Not Found"}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#222",
                            py: "3px",
                            fontSize: "11px",
                            fontWeight: "500",
                          }}
                        >
                          {moment(segments[0]?.departureTime).format("hh:mm a")}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                          {moment(
                            segments[0]?.departureTime?.slice(0, 19)
                          ).format("ddd, Do MMMM YYYY")}
                        </Typography>
                      </Grid>

                      <Grid order={{ xs: 2, lg: 3 }} xs={5.8} lg={2}>
                        <Stack
                          direction={"column"}
                          alignItems={"center"}
                          sx={{
                            bgcolor: "re",
                            height: "100%",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#ACA6A6",
                              textAlign: "center",
                              mb: "10px",
                              fontSize: "12px",
                            }}
                          >
                            {/* {flightduration} */}
                            {calDuration(
                              segments
                                ?.map((item) =>
                                  Number.parseInt(item?.flightduration)
                                )
                                ?.reduce((a, b) => a + b, 0)
                            )}
                          </Typography>

                          <svg
                            width="122"
                            height="12"
                            viewBox="0 0 689 56"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                              stroke="#ED1A5C"
                              stroke-width="9"
                            />
                            <path
                              d="M689 46.6569L541.002 0V46.6569H689Z"
                              fill="#ED1A5C"
                            />
                          </svg>

                          <Typography
                            variant="body2"
                            sx={{
                              color: "#444542",
                              textAlign: "center",
                              mt: "10px",
                            }}
                          >
                            {segments?.length >= 2
                              ? `${segments?.length - 1} stop${
                                  segments?.length - 1 > 1 ? "s" : ""
                                }`
                              : "Non Stop"}
                          </Typography>
                        </Stack>
                      </Grid>

                      <Grid
                        order={{ xs: 4, lg: 4 }}
                        xs={5.8}
                        lg={3.5}
                        sx={{ pl: "15px" }}
                      >
                        <Typography variant="body2" sx={{ color: "#8C8080" }}>
                          Arrival
                        </Typography>
                        <Typography variant="title-lg">{ArrTo}</Typography>
                        <Typography
                          variant="body2"
                          sx={{ color: "var(--secondary-color)" }}
                        >
                          {segments[segments?.length - 1].arrivalAirport
                            ? `${segments[segments?.length - 1].arrivalAirport}`
                            : segments[segments?.length - 1].arrivalLocation ||
                              "Airpot Name Not Found"}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#222",
                            fontSize: "11px",
                            fontWeight: "500",
                            py: "3px",
                          }}
                        >
                          {moment(
                            segments[segments?.length - 1].arrivalTime
                          ).format("hh:mm a")}
                        </Typography>
                        <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                          {moment(
                            segments[segments?.length - 1].arrivalTime?.slice(
                              0,
                              19
                            )
                          ).format("ddd, Do MMMM YYYY")}
                        </Typography>
                      </Grid>
                    </Grid>
                  );
                })}

                <Grid
                  container
                  justifyContent={"space-between"}
                  mt={2}
                  rowGap={4}
                >
                  <Grid order={{ xs: 1, lg: 1 }} xs={5.8} lg={2}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-start",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            sx={{
                              p: 0,
                              m: 0,
                              ml: 1,
                              color: "var(--secondary-color)",
                            }}
                            checked={checked}
                            onChange={() => handleBox(flightData)}
                          />
                        }
                      />
                      <Typography sx={{ fontSize: "12PX", color: "black" }}>
                        {flightData?.Class}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid order={{ xs: 3, lg: 2 }} xs={5.8} lg={3.5}>
                    <Typography
                      sx={{
                        fontSize: "12PX",
                        color:
                          flightData?.refundable === "false" ? "red" : "green",
                        // bgcolor: "red",
                      }}
                    >
                      {flightData?.refundable === "false"
                        ? "Non Refundable"
                        : "Refundable"}
                    </Typography>{" "}
                  </Grid>
                  <Grid order={{ xs: 2, lg: 3 }} xs={5.8} lg={2}>
                    <Typography
                      sx={{
                        fontSize: "12PX",
                        color: "black",
                        textAlign: "center",
                      }}
                    >
                      {
                        flightData?.AllLegs[0]?.segments[0]?.SegmentCode
                          ?.seatsAvailable
                      }{" "}
                      Seats
                    </Typography>
                  </Grid>
                  <Grid
                    order={{ xs: 4, lg: 4 }}
                    xs={5.8}
                    lg={3.5}
                    sx={{ pl: "15px" }}
                  >
                    <Typography sx={{ fontSize: "12PX", color: "black" }}>
                      Baggage:{" "}
                      {flightData.PriceBreakDown[0]?.Bag[0]?.Allowance
                        .pieceCount
                        ? flightData.PriceBreakDown[0]?.Bag[0]?.Allowance
                            .pieceCount *
                            23 +
                          " KG"
                        : flightData.PriceBreakDown[0]?.Bag[0]?.Allowance
                            .weight +
                          " " +
                          flightData.PriceBreakDown[0]?.Bag[0]?.Allowance.unit}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                xs={12}
                lg={2}
                sx={{
                  borderLeft: "5px solid #F0F6FF",
                  my: "14px",
                }}
              >
                <Stack
                  justifyContent={"space-between"}
                  sx={{
                    height: "100%",

                    flexDirection: {
                      xs: "row",
                      md: "column",
                    },
                  }}
                >
                  <Box
                    sx={{
                      textAlign: {
                        xs: "left",
                        md: "right",
                      },
                    }}
                  >
                    <Typography variant="title-lg">
                      {Number(flightData?.netcost).toLocaleString("en-IN")} ৳
                    </Typography>
                    <Typography variant="body2" sx={{ color: "#2156A6" }}>
                      <del>
                        {Number(flightData?.grosscost).toLocaleString("en-IN")}{" "}
                        ৳
                      </del>
                    </Typography>
                  </Box>
                  <Box sx={{ textAlign: "end" }}>
                    <Button
                      onClick={flightInformation}
                      sx={{
                        bgcolor: "var(--primary-color)",
                        color: "var(--white)",
                        fontWeight: "500",
                        ":hover": {
                          bgcolor: "var(--primary-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      BOOK NOW
                    </Button>
                    <Typography
                      onClick={() => setExpanded(!expanded)}
                      sx={{
                        fontSize: "12px",
                        color: "#222",
                        mt: 1,
                        cursor: "pointer",
                        fontWeight: "500",
                      }}
                    >
                      {expanded ? "Hide " : "View "}
                      Details
                    </Typography>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </>
        )}
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            },
          }}
        >
          <MultiCityQueueDetails
            flightData={flightData}
            expanded={expanded}
            setExpanded={setExpanded}
            handleChangeAcc={handleChangeAcc}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default SingleMultiCityItem;
