import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import img1 from "../../images/SliderImg/blog2.jpg";

import img3 from "../../images/SliderImg/image1.jpg";
import img4 from "../../images/SliderImg/image2.jpg";

const HotelRoom = () => {
  return (
    <Box
      sx={{ backgroundColor: "var(--white)", p: 2, mt: 2, borderRadius: "5px" }}
    >
      <Grid container>
        <Grid item xs={12} sm={4}>
          <Box sx={{ display: "flex", justifyContent: "space-between", mt: 1 }}>
            <Box>
              {" "}
              <img
                height="100%"
                width="90%"
                src={img1}
                style={{ borderRadius: "10px" }}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
              <img width="50%" src={img3} style={{ borderRadius: "10px" }} />
              <img width="50%" src={img4} style={{ borderRadius: "10px" }} />
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} sm={8}>
          <Box sx={{ borderBottom: "1px solid var(--light-gray)", mb: 1 }}>
            {" "}
            <Typography
              sx={{ color: "var(--black)", fontWeight: 500, fontSize: "14px" }}
            >
              Standard Double AC Room
            </Typography>
            <Typography
              sx={{ color: "var(--primary-color)", fontSize: "14px", mb: 1 }}
            >
              Single Bed
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              borderBottom: "1px solid var(--light-gray)",
              mb: 1,
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "var(--secondary-color)",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                Room Policy
              </Typography>
              <Typography
                sx={{
                  color: "var(--black)",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                Non-Smoking Room
              </Typography>
              <Typography
                sx={{
                  color: "var(--black)",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                Breakfast for 2 guests
              </Typography>
              <Typography
                sx={{
                  color: "var(--green-color)",
                  fontWeight: 500,
                  fontSize: "12px",
                }}
              >
                Refundable
              </Typography>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "var( --light-gray)",
                  fontWeight: 500,
                  fontSize: "15px",
                }}
              >
                Agent Fare: BDT 40,000
              </Typography>
              <Typography
                sx={{
                  color: "var(--black)",
                  fontWeight: 500,
                  fontSize: "18px",
                  textAlign: "right",
                }}
              >
                BDT 45,500
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box>Icon</Box>
            <Box>
              <Button
                // component={Link}
                // to="/dashboard/hotelDetails"
                sx={{
                  bgcolor: "var(--primary-color)",
                  color: "var(--white)",
                  fontSize: "12px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                  },
                }}
              >
                Book Now
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HotelRoom;
