import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  Modal,
  Paper,
  Typography,
  Tab,
  Tabs,
  Collapse,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import CircleIcon from "@mui/icons-material/Circle";
import FlightIcon from "@mui/icons-material/Flight";
import styled from "styled-components";
import commaNumber from "comma-number";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import bag from "../../../../images/Icon/bag.svg";
import seat from "../../../../images/Icon/seat.svg";
import triangle from "../../../../images/Icon/triangle.png";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import flight from "../../../../images/BookingManagement/flight.png";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

// import Loader from "../../Loader/Loader";
import { Link } from "react-router-dom";
// import { DarkmodeEnable } from "../../../App";
import AddGroupFare from "./AddGroupFare";
import "./GroupFare.css";
import Swal from "sweetalert2";
import Flags from "./Flags";
import { format } from "date-fns";
import flightData from "./flightData";
import { useNavigate } from "react-router-dom";
import Transit from "./Transit";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "crimson",
    maxWidth: 220,
    fontSize: "5px",
    borderRadius: "8px 0px 8px 0px",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const Address = ({ addresscode }) => {
  const address = flightData;
  let locationName;
  {
    address.map((add) => {
      if (add?.code === addresscode?.replace(/ /g, "")) {
        locationName = add?.Address;
      }
    });
  }
  return locationName?.split(",")[0];
};

const GroupFare = ({ isLoading, data, groupId, refetch }) => {
  const [value, setValue] = useState("1");
  const [flightDetails, setFlightDetails] = useState(false);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const navigate = useNavigate();
  // const { isDark } = React.useContext(DarkmodeEnable);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [checked, setChecked] = useState(true);

  const handleSoldout = (id) => {
    console.log(id);
    fetch(
      `https://api.flyfarint.com/v.1.0.0/Admin/GroupFare/soldout.php?id=${id}`
    );
  };

  const handleDelete = async (id) => {
    console.log("id", id);
    Swal.fire({
      title: "Are you sure?",
      text: "You Want to Delete ?",
      icon: "warning",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#003566",
      cancelButtonColor: "red",
      confirmButtonText: "Yes, Delete it!",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        Swal.fire(" Delete Successfully !", {
          icon: "success",
        }).then(async function () {
          await fetch(
            `https://api.flyfarint.com/v.1.0.0/Admin/GroupFare/deleteFare.php?groupId=${id}`,
            {
              method: "DELETE",
              headers: {
                "Content-type": "application/json; charset=UTF-8",
              },
            }
          )
            .then((res) => res.json())
            .then((data) => {
              if (data.status === "success") {
                refetch();
              } else {
                Swal.fire({
                  title: "There is a problem",
                  icon: "error",
                  button: "Done!",
                });
              }
            });
        });
      }
    });
  };
  // const loadData = data.data;
  // console.log(loadData);

  return (
    <Box sx={{ marginBottom: "15px", width: "100%", height: "100%" }}>
      <Grid
        container
        sx={{
          display: {
            xs: "none",
            sm: "flex",
            md: "flex",
          },
          transition: "all .5s ease-in-out",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
          paddingBottom: "5px",
          borderRadius: "5px",
        }}
      >
        <Grid item md={10} sx={{ height: "100%", padding: "15px 25px " }}>
          <Grid container>
            {/* //todo:one */}
            <Grid item md={3}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                }}
              >
                {/* //todo: Image Part */}
                <Box>
                  <img
                    src={flight}
                    width="60px"
                    height="60px"
                    // className={`${flightData?.system
                    //   ?.toLowerCase()
                    //   ?.split(" ")
                    //   ?.join("-")}-border`}
                    // alt={`${flightData.segments[0]?.marketingcareer}`}
                    alt="flight"
                  />
                </Box>
                {/* //todo: Text Part */}
                <Box width="90%">
                  <Tooltip title={`US-Bangla Airlines`}>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "16px",
                          cursor: "pointer",
                        },
                        // width: "80%",
                      }}
                      noWrap
                    >
                      US-Bangla Airlines
                    </Typography>
                  </Tooltip>
                  <Tooltip title={`BS-141`}>
                    <Typography
                      sx={{
                        color: "var(--mateBlack)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "16px",
                          cursor: "pointer",
                        },
                      }}
                      noWrap
                    >
                      BS-141
                    </Typography>
                  </Tooltip>
                </Box>
              </Box>
            </Grid>
            {/* //todo:second */}
            <Grid item md={3}>
              <Box
                style={{
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "start",
                    alignItems: "start",
                    gap: "6px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--third-color)",
                      fontWeight: 400,
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "14px",
                      },
                    }}
                  >
                    Depart
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--mateBlack)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "22px",
                      },
                    }}
                  >
                    DAC
                  </Typography>

                  <Tooltip title={` Hazrat Shahjalal International Airport`}>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "12px",
                          sm: "11px",
                          md: "16px",
                        },
                        cursor: "pointer",
                        width: "97%",
                      }}
                      noWrap
                    >
                      Hazrat Shahjalal International Airport
                    </Typography>
                  </Tooltip>

                  <Typography
                    sx={{
                      color: "var(--mateBlack)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "14px",
                      },
                    }}
                  >
                    07:30
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--third-color)",
                      fontWeight: 400,
                      fontSize: {
                        xs: "14px",
                        sm: "14px",
                        md: "14px",
                      },
                    }}
                  >
                    Thu 23 Feb 2023
                  </Typography>
                </Box>
              </Box>
            </Grid>

            {/* //todo:transit */}
            <Grid item md={2.9}>
              <Transit />
            </Grid>

            {/* //todo:four */}
            <Grid item md={3}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "end",
                  alignItems: "end",
                  gap: "6px",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--third-color)",
                    fontWeight: 400,

                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                    },
                  }}
                >
                  Arrival
                </Typography>
                <Typography
                  sx={{
                    color: "var(--mateBlack)",
                    fontWeight: 500,
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "22px",
                    },
                  }}
                >
                  CXB
                </Typography>
                <Tooltip title={`Coxs Bazar Airport`}>
                  <Typography
                    sx={{
                      color: "var(--primary-color)",
                      textAlign: "right",
                      fontWeight: 400,
                      fontSize: {
                        xs: "12px",
                        sm: "11px",
                        md: "16px",
                      },
                      width: "100%",
                      cursor: "pointer",
                    }}
                    noWrap
                  >
                    Coxs Bazar Airport
                  </Typography>
                </Tooltip>

                <Typography
                  sx={{
                    color: "var(--mateBlack)",
                    fontWeight: 500,
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "14px",
                    },
                  }}
                >
                  08:35
                </Typography>

                <Typography
                  sx={{
                    color: "var(--third-color)",
                    fontWeight: 400,
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "14px",
                    },
                  }}
                >
                  Thu 23 Feb 2023
                </Typography>
              </Box>
            </Grid>

            {/* //todo:five  */}
            <Grid
              item
              md={12}
              style={{
                width: "100%",
                height: "100%",
                marginTop: "15px",
                marginLeft: "-5px",
              }}
            >
              <Grid
                container
                style={{
                  width: "100%",
                  height: "100%",
                  // backgroundColor: "var(--input-bgcolor)",
                  padding: "10px 0px",
                  paddingLeft: "6px",
                }}
              >
                <Grid md={3}>
                  <Typography
                    sx={{
                      color: "var(--green-color)",
                      fontWeight: 400,

                      fontSize: {
                        xs: "14px",
                        sm: "12px",
                        md: "14px",
                        lg: "16px",
                      },
                    }}
                  >
                    Refundable
                  </Typography>
                </Grid>
                <Grid md={3}>
                  <Box className="img-text-bag-0">
                    {/* <WorkIcon style={{ color: "var(--primary-color)" }} /> */}

                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                          lg: "16px",
                        },
                      }}
                    >
                      {parseInt(flightData.bags) > 4 ? (
                        <> Baggage {flightData?.bags?.slice(0, 2) || 0} Kg</>
                      ) : flightData.bags === "2 N" ? (
                        <>2 N</>
                      ) : flightData.bags === "2 P" ? (
                        <>2 P</>
                      ) : parseInt(flightData.bags) < 4 ? (
                        <>{parseInt(flightData.bags)} Piece</>
                      ) : parseInt(flightData.bags) === NaN ? (
                        <>{flightData.bags}</>
                      ) : (
                        <>Baggage 0 Kg</>
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid md={6}>
                  <Box
                    className="img-text-bag-0"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "12px",
                          sm: "12px",
                          md: "14px",
                          lg: "16px",
                        },
                      }}
                    >
                      {flightData?.seat || 9} Seat
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {/* //todo: price section */}
        <Grid item md={2}>
          <Box
            sx={{
              display: "flex",
              borderLeft: "3px solid var(--input-bgcolor)",
              justifyContent: "space-between",
              padding: "10px 15px 0px 30px",
            }}
          >
            <Box>
              <Box>
                <Typography
                  style={{
                    fontSize: "22px",
                    color: "var(--mateBlack)",
                    fontWeight: "500",
                    textAlign: "right",
                  }}
                >
                  42, 400 BDT
                </Typography>
                <Typography
                  style={{
                    fontSize: "16px",
                    color: "var(--primary-color)",
                    fontWeight: "normal",
                    textAlign: "right",
                    marginTop: "5px",
                  }}
                >
                  43, 400 BDT
                </Typography>
                <Box
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "10px",
                  }}
                  mt={14}
                >
                  <Typography
                    style={{
                      background: "var(--primary-color)",
                      padding: "2px",
                      display: "flex",
                    }}
                  >
                    <EditIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "22px",
                        color: "var(--white)",
                      }}
                    />
                  </Typography>
                  <Typography
                    style={{
                      background: "var(--tomato-color)",
                      padding: "2px",
                      display: "flex",
                    }}
                  >
                    <DeleteIcon
                      style={{
                        cursor: "pointer",
                        fontSize: "22px",
                        color: "var(--white)",
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default GroupFare;
