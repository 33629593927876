import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { json, Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import UserHeader from "../../components/UserPage/UserHeader/UserHeader";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

const BankAccount = () => {
  const users = secureLocalStorage.getItem("user-info");
  let agentID = users?.user?.agentId;
  const [bankData, setBankData] = useState([]);

  useEffect(() => {
    fetch(
      `https://farhantt.api.flyfarint.com/v.1.0.0/Deposit/allBank.php?agentId=${agentID}`
    )
      .then((res) => res.json())
      .then((data) => setBankData(data));
  });

  const styles = {
    root: {
      height: "calc(100vh - 200px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    dataGrid: {
      flexGrow: 1,
    },
    "@media (max-width: 600px)": {
      root: {
        height: "calc(100vh - 100px)",
        width: "50%",
      },
    },
    "@media (min-width: 600px) and (max-width: 900px)": {
      root: {
        height: "calc(100vh - 150px)",
      },
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      root: {
        height: "calc(100vh - 175px)",
      },
    },
    "@media (min-width: 1200px) and (max-width: 1536px)": {
      root: {
        height: "calc(100vh - 190px)",
      },
    },
  };

  const columns = [
    {
      field: "status",
      headerName: "Status",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "tripType",
      headerName: "Type",
      suppressSizeToFit: true,
      width: 150,
    },

    {
      field: "route",
      headerName: "Route",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return <p>{`${params.row.deptFrom} - ${params.row.arriveTo}`}</p>;
      },
    },

    {
      field: "airlines",
      headerName: "Airlines",
      suppressSizeToFit: true,
      width: 170,
    },

    {
      field: "pnr",
      headerName: "Airlines PNR",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "pax",
      headerName: "PAX",
      suppressSizeToFit: true,
      width: 100,
    },
    {
      field: "travelDate",
      headerName: "Flight Date",
      suppressSizeToFit: true,
      width: 150,
    },

    {
      field: "netCost",
      headerName: "Net Cost",
      suppressSizeToFit: true,
      width: 150,
    },
  ];

  const rows = [
    { id: 1, lastName: "Snow", firstName: "Jon", age: 35 },
    { id: 2, lastName: "Lannister", firstName: "Cersei", age: 42 },
    { id: 3, lastName: "Lannister", firstName: "Jaime", age: 45 },
    { id: 4, lastName: "Stark", firstName: "Arya", age: 16 },
    { id: 5, lastName: "Targaryen", firstName: "Daenerys", age: null },
  ];

  return (
    <Box>
      {/* <Box sx={{ml:"15px",mt:"5px"}}>
        <Link
          style={{
            textDecoration: "none",
            color: "var(--primary-color)",
          }}
          to={"/dashboard/addbankaccount"}>
          <Button
            sx={{
              width: "120px",
              fontSize: "11px",
              textTransform: "capitalize",
              // height: "28px",
              background: "black",
              color: "white",
              "&:hover": {
                background: "black",
              },
            }}>
            Add Bank
          </Button>
        </Link>
      </Box> */}

      <Box
        style={styles.root}
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "1px solid var(--input-bgcolor)",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "var(--primary-color)",
            borderBottom: "none",
            color: "var(--white)",
            fontSize: "12px",
            textTransform: "uppercase",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: "var(--body-color)",
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: "var(--primary-color)",
            color: "var(--white)",
          },
          "& .MuiDataGrid-toolbarContainer": {
            marginLeft: "auto",
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "var(--primary-color)",
            background: "var(--secondary-color)",
            fontSize: "12px",
            mb: 2,
          },
          ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
            color: "var(--white)",
          },
          ".MuiTablePagination-selectLabel": {
            color: "var(--white)",
          },
          ".MuiSelect-select": {
            color: "var(--white)",
          },
          ".css-levciy-MuiTablePagination-displayedRows": {
            color: "var(--white)",
          },
          ".MuiDataGrid-cellContent": {
            color: "var(--text-color)",
          },
        }}>
        <DataGrid
          rows={rows}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          scrollbarSize={5}
          style={styles.dataGrid}
        />
      </Box>
    </Box>
  );
};

export default BankAccount;
