import React, { createContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import secureLocalStorage from "react-secure-storage";

export const UserInfoContext = createContext(null);

const UserInfoProvider = ({ children }) => {
  const user = secureLocalStorage.getItem("user-info");
  // console.log(user);

  const [userData, setUserData] = useState([]);
  const [isUpdated, setIsUpdated] = useState(false);
  
  useEffect(() => {
    let url;
    if (user?.staff_id) {
      url = `https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/index.php?staffId=${user?.staff_id}`;
    } else {
      url = `https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/index.php?agentId=${user?.agent_id}`;
    }
    // console.log(url);

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setUserData(data?.agents || data?.staffs);
      });
  }, [isUpdated, user?.agent_id]);

  return (
    <UserInfoContext.Provider value={{ userData, setIsUpdated, isUpdated }}>
      {children}
    </UserInfoContext.Provider>
  );
};

export default UserInfoProvider;
