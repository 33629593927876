import React, { useEffect, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, ClickAwayListener, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import HotelAvailability from "./HotelAvaiability";
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import WheelchairPickupIcon from '@mui/icons-material/WheelchairPickup';
import CarRentalIcon from '@mui/icons-material/CarRental';
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Triangle } from "react-loader-spinner";
import Amenity from "./Amenity";
import Swal from "sweetalert2";
import axios from "axios";
import commaNumber from "comma-number";
import { PDFDownloadLink } from "@react-pdf/renderer";
import HotelPdfDesign from "./HotelPdfDesign";
import secureLocalStorage from "react-secure-storage";

const HotelQueueDetails = () => {
    const [expanded, setExpanded] = useState("panel1");
    const [loading, setLoading] = useState(false);
    const [room, setRoom] = useState("")
    const navigate = useNavigate();
    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    const userInfo = secureLocalStorage.getItem("user-info");
    const agentId = userInfo?.agent_id || userInfo?.staff_id;
    const location = useLocation();
    const bookingId = location?.state?.bookingId;
    const [bookingInfo, setBookingInfo] = useState('');
    const [retriveInfo, setRetriveInfo] = useState('');

    useEffect(() => {
        const bookingUrl = `https://api.rtc.flyfarint.com/v1.0/b2b/hotel/getBooking.php?agentId=${agentId}&bookingId=${bookingId}`;
        const retrieveUrl = `https://api.rtc.flyfarint.com/v1.0/b2b/hotel/airRetrieve.php?bookingId=${bookingId}`;

        setLoading(true);

        fetch(bookingUrl)
            .then((res) => res.json())
            .then((bookingData) => {
                setBookingInfo(bookingData[0]);
                return fetch(retrieveUrl);
            })
            .then((res) => res.json())
            .then((retrieveData) => {
                setRetriveInfo(retrieveData);
                setLoading(false)
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, [bookingId]);

    const handleCancleBooking = () => {
        setLoading(true)

        Swal.fire({
            imageWidth: 400,
            imageHeight: 200,
            imageAlt: "Custom image",
            title: `Confiramation`,
            text: `Do you want to cancel?`,
            confirmButtonColor: "var(--primary-color)",
            confirmButtonText: "Ok",
        }).then((res) => {
            if (res.isConfirmed) {
                axios
                    .get(
                        `https://api.rtc.flyfarint.com/v1.0/b2c/hotel/cancel.php?bookingId=${bookingInfo?.uid}`,
                    )
                    .then((res) => {
                        if (res?.data?.status === "success") {
                            Swal.fire({
                                imageWidth: 400,
                                imageHeight: 200,
                                imageAlt: "Custom image",
                                title: `${res?.data?.status}`,
                                text: `${res?.data?.message}`,
                                confirmButtonColor: "var(--primary-color)",
                                confirmButtonText: "Ok",
                            }).then(() => {
                                setLoading(false)
                                navigate('/dashboard/allhotelbooking');
                            });
                        }
                    });
            }
        });
    };

    console.log(retriveInfo);
    console.log(bookingInfo);

    if (loading) {
        return (
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "80vh",
                    width: { xs: "80px" },
                    marginInline: "auto",
                }}
            >
                <Triangle
                    height="80"
                    width="80"
                    color="#2156a6"
                    ariaLabel="triangle-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
            </Box>
        );
    }

    return (
        <>
            <Box sx={{ background: "var(--body-color)", }}>
                <UserHeader />
                <Box
                    className="flightSearchParent"
                    mt="-8vh"
                    borderRadius="3px"
                    px="25px"
                    sx={{
                        borderRadius: "3px",
                        bgcolor: "white",
                        width: "90%",
                        m: "0 auto",

                    }}>

                    <Box sx={{ mt: "-8vh", }}>
                        <Grid
                            justifyContent={"space-between"}
                            container
                            // gap={1}
                            sx={{ p: "0px", bgcolor: "re", }}
                        >
                            <Grid item lg={9} sx={{ mt: "40px" }}>
                                {/* Booking details start */}

                                <Typography
                                    sx={{
                                        color: "#2156A6",
                                        p: "10px",
                                        pl: "17px",
                                        fontWeight: 500,
                                        fontSize: "15px",
                                        bgcolor: "#a3c7ff3b",
                                        borderRadius: "7px 7px 0px 0px",
                                    }}
                                >
                                    Booking Details
                                </Typography>
                                <Box sx={{ mb: "20px" }}>
                                    <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                                        <Table>
                                            <TableHead sx={{ bgcolor: "var(--secondary-color)" }}>
                                                <TableRow>
                                                    <TableCell style={{ paddingLeft: "17px", fontSize: "11px", color: "var(--primary-color)" }}>
                                                        Booking ID
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: "11px", color: "var(--primary-color)", width: "130px" }}>Confirmation ID</TableCell>
                                                    <TableCell style={{ fontSize: "11px", color: "var(--primary-color)" }}>Check In</TableCell>
                                                    <TableCell style={{ fontSize: "11px", color: "var(--primary-color)" }}>Check Out</TableCell>
                                                    <TableCell style={{ fontSize: "11px", color: "var(--primary-color)" }}>Refundable</TableCell>
                                                    <TableCell style={{ fontSize: "11px", color: "var(--primary-color)" }}>Booked At</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell style={{ paddingLeft: "17px", fontSize: "11px" }}>
                                                        {bookingId}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: "11px" }}>{retriveInfo?.request?.confirmationId}</TableCell>
                                                    <TableCell style={{ fontSize: "11px" }}>
                                                        {moment(bookingInfo?.checkIn).format("D MMMM, yyyy")}
                                                    </TableCell>
                                                    <TableCell style={{ fontSize: "11px" }}>
                                                        {moment(bookingInfo?.checkout).format("D MMMM, yyyy")}
                                                    </TableCell>
                                                    <TableCell style={{ color: bookingInfo?.refundable === 'Refundable' ? 'green' : "red", fontSize: "11px" }}> {bookingInfo?.refundable}</TableCell>
                                                    <TableCell style={{ fontSize: "11px" }}> {moment(bookingInfo?.bookedAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>

                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>

                                {/* Booking details ends */}

                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: "#2156A6",
                                            p: "10px",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            bgcolor: "#a3c7ff3b",
                                            m: "15px 0 0px 0",
                                            // width: "100%",
                                        }}
                                    >
                                        Hotel Details
                                    </Typography>
                                    <Box>
                                        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                                            <Table aria-label="simple table">
                                                <TableHead sx={{ minWidth: "100%" }}>
                                                    <TableRow sx={{ backgroundColor: "#F7D800" }}>
                                                        <TableCell
                                                            style={{ fontSize: "11px", color: "var(--primary-color)" }}
                                                            align="left"
                                                        >
                                                            Hotel Code
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ fontSize: "11px", color: "var(--primary-color)" }}
                                                            align="left"
                                                        >
                                                            Hotel Name
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ fontSize: "11px", color: "var(--primary-color)" }}
                                                            align="left"
                                                        >
                                                            Room Type
                                                        </TableCell>
                                                        <TableCell
                                                            style={{ fontSize: "11px", color: "var(--primary-color)" }}
                                                            align="left"
                                                        >
                                                            Address
                                                        </TableCell>
                                                        <TableCell style={{ fontSize: "11px", color: "var(--primary-color)" }}>Source</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ boxShadow: "none" }}>
                                                    <TableRow

                                                        sx={{
                                                            backgroundColor: "white",
                                                            boxShadow: "none",
                                                        }}
                                                    >
                                                        <TableCell
                                                            style={{
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.hotelCode}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.hotelName}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            {retriveInfo?.hotels && retriveInfo?.hotels[0]?.room?.roomType.split('-')[0]}

                                                        </TableCell>

                                                        <TableCell
                                                            style={{
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            {retriveInfo?.hotels && retriveInfo?.hotels[0]?.address?.street || ""} ,  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.address?.city || ""} ,  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.address?.countryCode || ""}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            {retriveInfo?.hotels && retriveInfo?.hotels[0]?.sourceTypeName}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>
                                </Grid>

                                {/* Passenger details */}

                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: "#2156A6",
                                            p: "10px",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            bgcolor: "#a3c7ff3b",
                                            m: "15px 0 0px 0",
                                            // width: "100%",
                                        }}
                                    >
                                        Passenger Details
                                    </Typography>

                                    <Box>
                                        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                                            <Table aria-label="simple table">
                                                <TableHead sx={{ minWidth: "100%" }}>
                                                    <TableRow sx={{ backgroundColor: "#F7D800" }}>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Name
                                                        </TableCell>

                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Passenger Type
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Phone Number
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Lead Guest
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                {
                                                    bookingInfo?.passengerInfo?.map((guest) => (<TableBody style={{ boxShadow: "none" }}>
                                                        <TableRow

                                                            sx={{
                                                                backgroundColor: "white",
                                                                boxShadow: "none",
                                                            }}
                                                        >
                                                            <TableCell
                                                                style={{
                                                                    fontSize: "11px",
                                                                    color: "#444542",
                                                                    boxShadow: "none",
                                                                }}
                                                                align="left"
                                                            >
                                                                {guest?.fName} {guest?.lName}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    fontSize: "11px",
                                                                    color: "#444542",
                                                                    boxShadow: "none",
                                                                }}
                                                                align="left"
                                                            >
                                                                {guest?.type === 'ADT' ? 'Adult' : 'Child'}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    fontSize: "11px",
                                                                    color: "#444542",
                                                                    boxShadow: "none",
                                                                }}
                                                                align="left"
                                                            >
                                                                {guest?.phone}
                                                            </TableCell>
                                                            <TableCell
                                                                style={{
                                                                    fontSize: "11px",
                                                                    color: "#444542",
                                                                    boxShadow: "none",
                                                                }}
                                                                align="left"
                                                            >
                                                                {guest?.leadGuest === true ? 'True' : "False"}
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>))
                                                }
                                            </Table>
                                        </TableContainer>
                                    </Box>

                                </Grid>

                                {/* Payment details */}

                                <Grid item>
                                    <Typography
                                        sx={{
                                            color: "#2156A6",
                                            p: "10px",
                                            fontWeight: 500,
                                            fontSize: "15px",
                                            bgcolor: "#a3c7ff3b",
                                            m: "15px 0 0px 0",
                                            // width: "100%",
                                        }}
                                    >
                                        Payment Details
                                    </Typography>

                                    <Box>
                                        <TableContainer component={Paper} sx={{ boxShadow: "none" }}>
                                            <Table aria-label="simple table">
                                                <TableHead sx={{ minWidth: "100%" }}>
                                                    <TableRow sx={{ backgroundColor: "#F7D800" }}>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Payment Type
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Holder's Name
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Card Number
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Holder's Phone No
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Email
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                color: "var(--primary-color)",
                                                                boxShadow: "none",
                                                                fontSize: "11px"
                                                            }}
                                                            align="left"
                                                        >
                                                            Country Code
                                                        </TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody style={{ boxShadow: "none" }}>
                                                    <TableRow
                                                        sx={{
                                                            backgroundColor: "white",
                                                            boxShadow: "none",
                                                        }}
                                                    >
                                                        <TableCell
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.paymentInfo?.paymentType}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.paymentInfo?.holderFName} {bookingInfo?.paymentInfo?.holderLName}
                                                        </TableCell>

                                                        <TableCell
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.paymentInfo?.cardNumber}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.paymentInfo?.holderPhone}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.paymentInfo?.holderEmail}
                                                        </TableCell>
                                                        <TableCell
                                                            style={{
                                                                fontSize: "11px",
                                                                color: "#444542",
                                                                boxShadow: "none",
                                                            }}
                                                            align="left"
                                                        >
                                                            {bookingInfo?.paymentInfo?.stateCode}
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            </Table>
                                        </TableContainer>
                                    </Box>

                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                lg={2.75}
                                sx={{ height: "max-content", mt: "40px", }}
                            >
                                <Box sx={{ mb: "13px", pl: "18px", p: "20px", bgcolor: "#EAF2FF", borderTopLeftRadius: "5px", borderTopRightRadius: "5px" }}>
                                    <Box>
                                        <Typography
                                            sx={{
                                                fontSize: "16px",
                                                color: "#2156A6",
                                                fontWeight: "500",
                                                bgcolor: "var(--secondary-color)",
                                                py: "3px",
                                                pl: "5px",
                                                borderRadius: "2px",
                                                textAlign: "center",
                                            }}
                                        >
                                            {bookingInfo?.status}

                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "13px",
                                                color: "var(--white)",
                                                fontWeight: "500",
                                                bgcolor: "var(--primary-color)",
                                                py: "3px",
                                                pl: "5px",
                                                borderRadius: "2px",
                                                textAlign: "center",
                                            }}
                                        >
                                            Booking Id : {bookingId}
                                        </Typography>


                                    </Box>
                                    <Box sx={{ mt: "5px" }}>
                                        <Typography
                                            sx={{
                                                color: "var(--primary-color)",
                                                fontSize: "12px",
                                                bgcolor: "#FBEA78",
                                                padding: "4px 17px",
                                                fontWeight: 500,
                                            }}
                                        >
                                            Price Breakdown
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                            my={1}
                                        >
                                            <Typography sx={{ fontSize: "12px", color: "black", fontWeight: "500" }}>
                                                Payable Amount :
                                            </Typography>
                                            <Typography sx={{ fontSize: "15px", color: "var(--primary-color)", fontWeight: "500" }}>
                                                {commaNumber(bookingInfo?.netCost)} BDT
                                            </Typography>
                                        </Stack>
                                    </Box>
                                </Box>

                                <Accordion
                                    expanded={expanded === "panel2"}
                                    onChange={handleChange("panel2")}
                                    style={{
                                        marginBottom: "13px",
                                        boxShadow: "none",

                                    }}
                                >
                                    <AccordionSummary aria-controls="panel2bh-content" sx={{ bgcolor: "#EAF2FF" }}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                alignItems: "center",

                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: "14px",
                                                    color: "#2564B8",
                                                    fontWeight: "500",
                                                }}
                                            >
                                                View Confiramation
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ paddingTop: "0", backgroundColor: "#EAF2FF" }}>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "var(--primary-color)",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Confiramation Id: <span style={{ color: "black" }}>{retriveInfo?.request?.confirmationId}</span>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "var(--primary-color)",
                                                fontWeight: "500",
                                                mt: "3px"
                                            }}
                                        >
                                            Chain Name: <span style={{ color: "black" }}>  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.chainName} </span>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "var(--primary-color)",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Chain Code: <span style={{ color: "black" }}>  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.chainCode} </span>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "var(--primary-color)",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Guarantee Type : <span style={{ color: "black" }}>  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.guaranteeTypeName} </span>
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: "12px",
                                                color: "var(--primary-color)",
                                                fontWeight: "500",
                                            }}
                                        >
                                            Source : <span style={{ color: "black" }}>  {retriveInfo?.hotels && retriveInfo?.hotels[0]?.sourceTypeName} </span>
                                        </Typography>
                                    </AccordionDetails>
                                </Accordion>
                                <Accordion
                                    style={{
                                        boxShadow: "none",
                                        backgroundColor: "#EAF2FF",
                                        margin: "13px 0",
                                    }}
                                >
                                    <AccordionSummary aria-controls="panel2bh-content" sx={{ bgcolor: "#EAF2FF" }}>
                                        <Box
                                            sx={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                width: "100%",
                                                alignItems: "center",
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    color: "var(--primary-color)",
                                                    fontWeight: "500",
                                                    fontSize: "15px",
                                                }}
                                            >
                                                Download PDF
                                            </Typography>
                                        </Box>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ paddingTop: "0" }}>
                                        <Box>
                                            <PDFDownloadLink
                                                document={
                                                    <HotelPdfDesign
                                                        copy="Booking Copy"
                                                        check="1"
                                                        bookingInfo={bookingInfo}
                                                        retriveInfo={retriveInfo}
                                                    />
                                                }
                                                fileName={"Booking with price"}
                                            >
                                                {({ blob, url, loading, error }) =>
                                                    loading ? (
                                                        "Loading document...1"
                                                    ) : (
                                                        <a
                                                            href={url}
                                                            target="_blank"
                                                            style={{
                                                                cursor: "pointer",
                                                                color: "var(--primary-color)",
                                                                textDecoration: "none",
                                                                fontSize: "14px",
                                                            }}
                                                        >
                                                            Booking With Price
                                                        </a>
                                                    )
                                                }
                                            </PDFDownloadLink>
                                        </Box>
                                    </AccordionDetails>
                                </Accordion>
                                {
                                    bookingInfo?.status !== 'Cancelled' ? <Button style={{ background: "red", color: "white", width: "100%", margin: "10px 0px" }} onClick={() => handleCancleBooking()}>Cancel Booking</Button> : ""
                                }
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default HotelQueueDetails;
