import { Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { format } from "date-fns";
import React, { useState } from "react";
import Transit from "../SingleFlight/Transit";
import moment from "moment";

const MultiCityFlightDetails = ({ data }) => {
  const [toogleSegments, setToogleSegments] = useState(1);

  const newSegment = Object.entries(data.segments).map(([key, value]) => ({
    [key]: value,
  }));

  return (
    <Box>
      <Grid
        container
        sx={{
          display: {
            xs: "none",
            sm: "flex",
            md: "flex",
          },
          transition: "all .5s ease-in-out",
          borderRadius: "5px",
        }}
      >
        <Grid item md={12} sx={{ height: "100%" }}>
          {data.segments[toogleSegments]?.map((flight, i, arr) => {
            const datetime1 = moment(
              arr[i]?.arrival_time,
              "YYYY-MM-DD HH:mm:ss"
            );
            const datetime2 = moment(
              arr[i + 1]?.departure_time,
              "YYYY-MM-DD HH:mm:ss"
            );

            const diff = moment.duration(datetime2.diff(datetime1));

            const diffHours = diff.hours();
            const diffMinutes = diff.minutes();
            return (
              <Grid
                key={i}
                container
                sx={{ mb: `${i === arr.length - 1 ? "" : "20px"}` }}
              >
                <Grid item md={3}>
                  <Box>
                    {/* //todo: Image Part */}
                    <Box>
                      <img
                        src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.marketing_carier_code}.png`}
                        width="50px"
                        height="50px"
                        alt={`${data?.segments[0]?.marketing_carier_code}`}
                      />
                    </Box>

                    {/* //todo: Text Part */}
                    <Box width="90%">
                      <Tooltip>
                        <Typography
                          sx={{
                            color: "var(--primary-color)",
                            fontWeight: 400,
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "13px",
                              cursor: "pointer",
                            },
                          }}
                          noWrap
                        >
                          {flight?.marketing_carier_name}{" "}
                        </Typography>
                      </Tooltip>
                      <Tooltip>
                        <Typography
                          sx={{
                            color: "var(--mateBlack)",
                            fontWeight: 400,
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "12px",
                              cursor: "pointer",
                            },
                          }}
                          noWrap
                        >
                          {flight?.marketing_carier_code} -{" "}
                          {flight?.marketing_flight_number}
                        </Typography>
                      </Tooltip>
                      <Tooltip>
                        <Typography
                          sx={{
                            color: "var(--mateBlack)",
                            fontWeight: 400,
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "12px",
                              cursor: "pointer",
                            },
                          }}
                        >
                          {flight?.air_craft_type_name}
                        </Typography>
                      </Tooltip>
                      <Tooltip>
                        <Typography
                          sx={{
                            color: "var(--mateBlack)",
                            fontWeight: 400,
                            fontSize: {
                              xs: "10px",
                              sm: "12px",
                              md: "12px",
                              cursor: "pointer",
                            },
                          }}
                        >
                          Airlines PNR:{" "}
                          <span style={{ fontWeight: "500" }}>
                            {flight?.airlines_pnr}
                          </span>
                        </Typography>
                      </Tooltip>
                    </Box>
                  </Box>
                </Grid>
                {/* //todo:two */}
                <Grid item md={3}>
                  <Box
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "start",
                        alignItems: "start",
                        gap: "1px",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--third-color)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "12px",
                          },
                        }}
                      >
                        Depart
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontWeight: 500,
                          fontSize: {
                            xs: "15px",
                            sm: "18px",
                            md: "22px",
                          },
                        }}
                      >
                        {flight?.departure_code}
                      </Typography>

                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "13px",
                          },
                          cursor: "pointer",
                          width: "97%",
                        }}
                      >
                        {flight.departure_location} |{" "}
                        {flight?.departure_airport}{" "}
                        {flight?.departure_terminal &&
                        flight?.departure_terminal.length > 4
                          ? "|" + flight?.departure_terminal
                          : flight?.departure_terminal &&
                            "| TERMINAL " + flight?.departure_terminal}
                      </Typography>

                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontWeight: 500,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "13px",
                          },
                        }}
                      >
                        {format(
                          new Date(flight?.departure_time.toString()),
                          "dd MMM yyyy hh:mm a"
                        )}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                {/* //todo: three */}
                <Grid item md={3}>
                  <Transit flightData={flight} />
                </Grid>
                {/* //todo: four */}
                <Grid item md={3}>
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "end",
                      alignItems: "end",
                      gap: "1px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--third-color)",
                        fontWeight: 400,

                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "12px",
                        },
                      }}
                    >
                      Arrival
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--mateBlack)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "12px",
                          sm: "13px",
                          md: "22px",
                        },
                      }}
                    >
                      {`${flight?.arrival_code}`}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                        cursor: "pointer",
                        width: "97%",
                        textAlign: "right",
                      }}
                    >
                      {flight.arrival_location} | {flight?.arrival_airport}{" "}
                      {flight?.arrival_terminal &&
                      flight?.arrival_terminal.length > 4
                        ? "| " + flight?.arrival_terminal
                        : flight?.arrival_terminal &&
                          "| TERMINAL " + flight?.arrival_terminal}
                    </Typography>

                    <Typography
                      sx={{
                        color: "var(--mateBlack)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                      }}
                    >
                      {format(
                        new Date(flight?.arrival_time.toString()),
                        "dd MMM yyyy hh:mm a"
                      )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12}>
                  <Typography
                    sx={{
                      display: `${i === arr.length - 1 ? "none" : "block"}`,
                      borderRadius: "4px",
                      background: "#DEE8FF",
                      width: "85%",
                      color: "var(--primary-color)",
                      padding: "3px 10px",
                      fontSize: "10px",
                      fontWeight: 500,
                      margin: "0 auto",
                      textAlign: "center",
                      mt: "15px",
                      py: "8px",
                    }}
                  >
                    Change planes at{" "}
                    {moment(arr[i + 1]?.departure_time).format("hh:mm a")} |{" "}
                    {flight?.arrival_airport} | {flight?.arrival_airport} |
                    Connecting Time: {diffHours + "H " + diffMinutes + "Min"}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}

          <Stack direction="row" justifyContent="flex-end" mt={1}>
            {newSegment?.map((item, i) => {
              return (
                <Box key={i}>
                  <Typography
                    sx={{
                      padding: "5px 10px",
                      bgcolor: `${
                        toogleSegments === i + 1 ? "#2156A6" : "#D3E0F0"
                      }`,
                      width: "max-content",
                      color: `${
                        toogleSegments === i + 1 ? "white" : "#444542"
                      }`,
                      cursor: "pointer",
                      fontSize: "14px",
                    }}
                    onClick={() => {
                      setToogleSegments(i + 1);
                    }}
                  >
                    {" "}
                    {item[i + 1][0]?.departure_code}-{" "}
                    {item[i + 1][item[i + 1].length - 1].arrival_code}{" "}
                  </Typography>
                </Box>
              );
            })}
          </Stack>
        </Grid>

        {/* //todo:Flight Details start------------ */}
      </Grid>
    </Box>
  );
};

export default MultiCityFlightDetails;
