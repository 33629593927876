import {
  Box,
  Grid,
  Stack,
  Tooltip,
  Typography,
  Tab,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import { format } from "date-fns";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import React, { useState } from "react";
import moment, { duration } from "moment";
import FarePolicy from "../FarePolicy/FarePolicy";
import MultiCityFareDetails from "./MultiCityFareDetails";
import Baggage from "./Baggage";
const isMobile = window.innerWidth <= 768;

const MultiCityQueueDetails = ({
  flightData,
  expanded,
  setExpanded,
  handleChangeAcc,
}) => {
  const system = flightData.System;
  const [isActive, setIsActive] = useState(1);
  const allLegs = flightData?.AllLegs;
  const segments = allLegs?.segments;
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const calDuration = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let seconds = Math.round((totalMinutes - (hours * 60 + minutes)) * 60);

    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }

    return `${hours.toString().padStart(2, "0")}h:${minutes
      .toString()
      .padStart(2, "0")}m`;
  };

  return (
    <Box
      sx={{
        "& .MuiAccordion-root::before": {
          height: "0px !important",
        },
      }}
    >
      {isMobile ? (
        <>
          <TabContext value={value}>
            {flightData?.AllLegs?.map((segment, index) => (
              <TabPanel value={index} style={{ padding: "20px 0px" }}>
                {segment?.segments?.map((singleSegment, i) => {
                  return (
                    <Box
                      sx={{
                        bgcolor: "var(--white)",
                        borderRadius: "4px",
                        px: "13px",
                        py: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          gap: "15px",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{ fontSize: "13px", fontWeight: "500" }}
                        >
                          {singleSegment?.departure} {""}
                          {moment(singleSegment?.departureTime).format(
                            "hh:mm a"
                          )}
                        </Typography>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          viewBox="0 0 116 57"
                          fill="none"
                        >
                          <path
                            d="M115.51 14.4039C114.711 12.0948 112.993 10.1857 110.732 9.09641C108.471 8.00716 105.854 7.82702 103.455 8.5956L76.971 17.1L29.6042 0L17.7625 5.7L53.2876 28.5L29.6042 39.9L5.92085 28.5L0 34.2L23.6834 57L109.814 25.9065C112.125 25.0713 114.009 23.4062 115.072 21.2611C116.134 19.1161 116.291 16.6581 115.51 14.4039Z"
                            fill="#ED1C24"
                          />
                        </svg>
                        <Typography
                          sx={{ fontSize: "14px", fontWeight: "500" }}
                        >
                          {singleSegment?.arrival} {""}
                          {moment(singleSegment?.arrivalTime).format("hh:mm a")}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          color: "var(--secondary-color)",
                          fontSize: "12px",
                        }}
                      >
                        {flightData?.refundable === true
                          ? "Non Refundable"
                          : "Refundable"}
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontSize: "12px",
                          bgcolor: "#D6D7DD",
                          py: "1px",
                          borderRadius: "2px",
                          pl: "2px",
                          mt: "5px",
                          fontWeight: "500",
                        }}
                      >
                        Flight Details
                      </Typography>
                      {/* Array mapping starts here */}

                      <>
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            borderLeft: "1.5px dotted var(--secondary-color)",
                            mt: "20px",
                            pl: "15px",
                            pb: "10px",
                            position: "relative",
                          }}
                        >
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "var(--primary-color)",
                              borderRadius: "50px",
                              position: "absolute",
                              left: "-5px",
                              top: "-5px",
                            }}
                          ></div>
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                pb: "0px",
                              }}
                            >
                              {singleSegment?.departure} {""}
                              {moment(singleSegment?.departureTime).format(
                                "hh:mm a"
                              )}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {singleSegment?.departureAirport}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {moment(singleSegment?.departureTime).format(
                                "dddd, Do MMMM YYYY"
                              )}
                            </span>
                          </Box>
                          <Box
                            sx={{ mt: "20px", display: "flex", gap: "10px" }}
                          >
                            <img
                              src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${singleSegment?.marketingcareer}.png`}
                              style={{
                                width: "40px",
                                height: "40px",
                                borderRadius: "50%",
                              }}
                              alt={`${singleSegment?.MarketingCarrier}`}
                            />
                            <Box>
                              <span
                                style={{
                                  fontSize: "14px",
                                  pt: "0px",
                                  color: "var(--secondary-color)",
                                }}
                              >
                                {singleSegment?.marketingcareerName}
                              </span>{" "}
                              <br />
                              <span
                                style={{
                                  fontSize: "12px",
                                  pt: "0px",
                                  color: "#938A88",
                                  fontWeight: "600",
                                }}
                              >
                                {singleSegment.marketingcareer ===
                                singleSegment.operatingcareer
                                  ? `${singleSegment.marketingcareer}-${singleSegment.marketingflight}`
                                  : `${singleSegment.operatingcareer}-${singleSegment.operatingflight}`}
                              </span>
                              <Typography
                                sx={{
                                  color: "#938A88",
                                  fontSize: "12px",
                                  pt: "2px",
                                }}
                              >
                                Operating Carrier:{" "}
                                {singleSegment?.operatingcareer}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "#938A88",
                                  fontSize: "12px",
                                  pt: "2px",
                                }}
                              >
                                Class: {singleSegment?.SegmentCode?.bookingCode}
                              </Typography>
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              mt: "15px",
                            }}
                          >
                            <span
                              style={{
                                fontSize: "15px",
                                fontWeight: "500",
                                pb: "0px",
                              }}
                            >
                              {singleSegment?.arrival} {""}
                              {moment(singleSegment?.arrivalTime).format(
                                "hh:mm a"
                              )}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {singleSegment?.arrivalAirport}
                            </span>
                            <span style={{ fontSize: "12px", pt: "0px" }}>
                              {moment(singleSegment?.arrivalTime).format(
                                "dddd, Do MMMM YYYY"
                              )}
                            </span>
                          </Box>
                          <div
                            style={{
                              height: "10px",
                              width: "10px",
                              backgroundColor: "var(--primary-color)",
                              borderRadius: "50px",
                              position: "absolute",
                              left: "-5px",
                              bottom: "0px",
                            }}
                          ></div>
                        </Box>
                      </>
                    </Box>
                  );
                })}
              </TabPanel>
            ))}
            <Box
              sx={{
                width: "100%",
                height: {
                  md: "fit-content",
                  sm: "100%",
                  xs: "100%",
                },
                display: "flex",
                justifyContent: {
                  md: "space-between",
                  sm: "center",
                  xs: "start",
                },
                alignItems: "center",
                opacity: "1",
                "& .MuiTabs-flexContainer": {
                  flexWrap: "wrap",
                  gap: "5px",
                },
                ".MuiTabs-root": {
                  minHeight: "fit-content",
                },
                "& button": {
                  opacity: "1",
                  background: "transparent",
                  color: "var(--primary-color)",
                  border: "1px solid var(--primary-color)",
                  width: "fit-content",
                  minHeight: "fit-content",
                  padding: " 8px 10px",
                  fontSize: "12px",
                  borderRadius: "3px",
                },
                "& button.Mui-selected,& button.Mui-selected >svg": {
                  background: "var(--primary-color)",
                  color: "var(--white) !important",
                },
              }}
            >
              <TabList
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                value={value}
                onChange={handleChange}
                aria-label="agent-tabs"
                TabIndicatorProps={{
                  style: { display: "none" },
                }}
              >
                {flightData?.AllLegs?.map((Segment, index) => (
                  <Tab
                    sx={{ mb: "10px" }}
                    label={`${Segment?.segments[0]?.departure} - ${
                      Segment?.segments[Segment?.segments?.length - 1]?.arrival
                    }`}
                    value={index}
                  />
                ))}
              </TabList>
            </Box>
          </TabContext>
        </>
      ) : (
        <>
          <Accordion
            expanded={expanded}
            // onChange={handleChangeAcc("panel1")}
            sx={{
              boxShadow: "none",
              bgcolor: "white",
              ".MuiButton-root:hover": {
                backgroundColor: "#F7D800",
              },
              ".MuiBox-root": {
                height: "max-content",
              },
            }}
          >
            <AccordionSummary
              sx={{ display: "none" }}
              aria-controls="panel1d-content"
              id="panel1d-header"
            ></AccordionSummary>
            <AccordionDetails
              sx={{
                borderTop: "1px solid #F0F6FF",
                px: "0px",
              }}
            >
              <Grid sx={{ bgcolor: "#fff", borderRadius: "3px" }}>
                <Box
                  className="table-wrapper"
                  sx={{
                    pt: "10px",
                  }}
                >
                  <Stack direction="row" spacing={2}>
                    <Box>
                      <Button
                        onClick={() => setIsActive(1)}
                        sx={{
                          padding: "7px 20px",
                          bgcolor: `${
                            isActive === 1 ? "var(--secondary-color)" : ""
                          }`,
                          color: `${
                            isActive === 1 ? "var(--white)" : "var(--meteBlack)"
                          }`,
                          textTransform: "capitalize",
                          fontSize: "12px",
                          textTransform: "uppercase",
                        }}
                      >
                        Flight Details
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => setIsActive(2)}
                        sx={{
                          padding: "7px 20px",
                          bgcolor: `${
                            isActive === 2 ? "var(--secondary-color)" : ""
                          }`,
                          color: `${
                            isActive === 2 ? "var(--white)" : "var(--meteBlack)"
                          }`,
                          fontSize: "12px",
                          textTransform: "uppercase",
                        }}
                      >
                        Fare Details
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => setIsActive(3)}
                        sx={{
                          padding: "7px 20px",
                          bgcolor: `${
                            isActive === 3 ? "var(--secondary-color)" : ""
                          }`,
                          color: `${
                            isActive === 3 ? "var(--white)" : "var(--meteBlack)"
                          }`,
                          fontSize: "12px",
                          textTransform: "uppercase",
                        }}
                      >
                        Fare Policy
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        onClick={() => setIsActive(4)}
                        sx={{
                          padding: "7px 20px",
                          bgcolor: `${
                            isActive === 4 ? "var(--secondary-color)" : ""
                          }`,
                          color: `${
                            isActive === 4 ? "var(--white)" : "var(--meteBlack)"
                          }`,
                          fontSize: "12px",
                          textTransform: "uppercase",
                        }}
                      >
                        Baggage
                      </Button>
                    </Box>
                  </Stack>

                  <Box
                    sx={{
                      display: `${isActive === 1 ? "block" : "none"}`,
                    }}
                  >
                    <TabContext value={value}>
                      {flightData?.AllLegs?.map((segment, index) => (
                        <TabPanel value={index} style={{ padding: "20px 0px" }}>
                          {segment?.segments?.map((singleSegment, i) => {
                            return (
                              <Grid container>
                                <Grid
                                  item
                                  order={{ xs: 1, lg: 1 }}
                                  xs={6}
                                  lg={3}
                                  key={i}
                                >
                                  <Box sx={{ bgcolor: "blu" }}>
                                    <img
                                      src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${singleSegment?.marketingcareer}.png`}
                                      style={{
                                        width: "50px",
                                        height: "50px",
                                        borderRadius: "50%",
                                      }}
                                      alt={singleSegment?.marketingcareer}
                                    />
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "var(--secondary-color)",
                                        fontWeight: "500",
                                      }}
                                    >
                                      {singleSegment?.marketingcareerName}
                                    </Typography>

                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "#222",
                                        fontWeight: "500",
                                        pt: "2px",
                                      }}
                                    >
                                      {singleSegment.marketingcareer ===
                                      singleSegment.operatingcareer
                                        ? `${singleSegment.marketingcareer}-${singleSegment.marketingflight}`
                                        : `${singleSegment.operatingcareer}-${singleSegment.operatingflight}`}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#938A88",
                                        fontSize: "12px",
                                        pt: "2px",
                                      }}
                                    >
                                      Operating Carrier:{" "}
                                      {singleSegment?.operatingcareer}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        color: "#938A88",
                                        fontSize: "12px",
                                        pt: "2px",
                                      }}
                                    >
                                      Class:{" "}
                                      {singleSegment?.SegmentCode?.bookingCode}
                                    </Typography>
                                  </Box>
                                </Grid>

                                <Grid
                                  item
                                  order={{ xs: 3, lg: 2 }}
                                  xs={3}
                                  lg={3}
                                >
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "#8C8080" }}
                                    >
                                      Depart
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "25px",
                                        fontWeight: "500",
                                        color: "#444542",
                                      }}
                                    >
                                      {singleSegment?.departure}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "var(--primary-color)",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#444542",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {singleSegment?.departureLocation}
                                      </span>{" "}
                                      |{" "}
                                      {moment(
                                        singleSegment?.departureTime
                                      ).format("hh:mm a")}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "var(--primary-color)",
                                        pt: "2px",
                                      }}
                                    >
                                      {singleSegment?.departureAirport}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#8C8080",
                                        pt: "2px",
                                      }}
                                    >
                                      {moment(
                                        singleSegment?.departureTime
                                      ).format("dddd, Do MMMM YYYY")}
                                    </Typography>
                                  </Box>
                                </Grid>

                                <Grid
                                  item
                                  order={{ xs: 4, lg: 3 }}
                                  xs={3}
                                  lg={3}
                                >
                                  <Stack
                                    direction="column"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    height={"100%"}
                                  >
                                    <Stack
                                      direction="column"
                                      justifyContent="center"
                                      alignItems="center"
                                    >
                                      <Typography
                                        sx={{
                                          color: "red",
                                          textAlign: "ceter",
                                          mr: "10px",
                                          fontSize: "11px",
                                        }}
                                      >
                                        {singleSegment?.flightduration && (
                                          <p>{`${Math.floor(
                                            singleSegment?.flightduration / 60
                                          )}H ${
                                            singleSegment?.flightduration % 60
                                          }M`}</p>
                                        )}
                                      </Typography>
                                      <svg
                                        width="125"
                                        height="12"
                                        viewBox="0 0 689 56"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M689 51C687.393 51 594.741 51.0006 548.528 51.0006H0"
                                          stroke="#ED1A5C"
                                          stroke-width="9"
                                        />
                                        <path
                                          d="M689 46.6569L541.002 0V46.6569H689Z"
                                          fill="#ED1A5C"
                                        />
                                      </svg>
                                      <Typography
                                        variant="body2"
                                        sx={{
                                          color: "#444542",
                                          textAlign: "center",
                                          mr: "10px",
                                        }}
                                      ></Typography>
                                    </Stack>
                                  </Stack>
                                </Grid>

                                <Grid
                                  item
                                  order={{ xs: 3, lg: 4 }}
                                  xs={3}
                                  lg={3}
                                  sx={{ pl: "10px" }}
                                >
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "#8C8080" }}
                                    >
                                      Arrival
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "25px",
                                        fontWeight: "500",
                                        color: "#444542",
                                      }}
                                    >
                                      {singleSegment?.arrival}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        color: "var(--primary-color)",
                                        fontWeight: 500,
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "#444542",
                                          fontSize: "13px",
                                        }}
                                      >
                                        {singleSegment?.arrivalLocation}
                                      </span>{" "}
                                      |{" "}
                                      {moment(
                                        singleSegment?.arrivalTime
                                      ).format("hh:mm a")}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      sx={{ color: "var(--primary-color)" }}
                                    >
                                      {singleSegment?.arrivalAirport}
                                    </Typography>
                                    <Typography
                                      sx={{
                                        fontSize: "12px",
                                        color: "#8C8080",
                                        pt: "2px",
                                      }}
                                    >
                                      {moment(
                                        singleSegment?.arrivalTime
                                      ).format("dddd, Do MMMM YYYY")}
                                    </Typography>
                                  </Box>
                                </Grid>
                              </Grid>
                            );
                          })}
                        </TabPanel>
                      ))}
                      <Box
                        sx={{
                          width: "100%",
                          height: {
                            md: "fit-content",
                            sm: "100%",
                            xs: "100%",
                          },
                          display: "flex",
                          justifyContent: {
                            md: "flex-end",
                            sm: "center",
                            xs: "center",
                          },
                          alignItems: "center",
                          opacity: "1",
                          "& .MuiTabs-flexContainer": {
                            flexWrap: "wrap",
                            gap: "0px",
                          },
                          ".MuiTabs-root": {
                            minHeight: "fit-content",
                          },
                          "& button": {
                            opacity: "1",
                            background: "#D3E0F0",
                            color: "#444542",
                            width: "fit-content",
                            minHeight: "fit-content",
                            padding: "7px 10px",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                          "& button.Mui-selected,& button.Mui-selected >svg": {
                            background: "var(--primary-color)",
                            color: "var(--white) !important",
                            fontSize: "14px",
                            fontWeight: "400",
                          },
                        }}
                      >
                        <TabList
                          variant="scrollable"
                          scrollButtons
                          allowScrollButtonsMobile
                          value={value}
                          onChange={handleChange}
                          aria-label="agent-tabs"
                          TabIndicatorProps={{
                            style: { display: "none" },
                          }}
                        >
                          {flightData?.AllLegs?.map((Segment, index) => (
                            <Tab
                              sx={{ mb: "10px" }}
                              label={`${Segment?.segments[0]?.departure} - ${
                                Segment?.segments[Segment?.segments?.length - 1]
                                  ?.arrival
                              }`}
                              value={index}
                            />
                          ))}
                        </TabList>
                      </Box>
                    </TabContext>
                  </Box>

                  {/* Fare details starts here */}
                  <Box
                    sx={{
                      display: `${isActive === 2 ? "block" : "none"}`,
                    }}
                  >
                    <MultiCityFareDetails
                      pricebreakdown={flightData?.PriceBreakDown}
                    />
                  </Box>

                  {/* Fare policy starts here */}
                  <Box
                    sx={{
                      display: `${isActive === 3 ? "block" : "none"}`,
                    }}
                  >
                    <FarePolicy />
                  </Box>

                  {/* Baggage details */}
                  <Box
                    sx={{
                      display: `${isActive === 4 ? "block" : "none"}`,
                    }}
                  >
                    <Baggage
                      flightData={flightData}
                      pricebreakdown={flightData?.PriceBreakDown}
                    />
                  </Box>
                </Box>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </>
      )}
    </Box>
  );
};

export default MultiCityQueueDetails;
