import { Box, Typography } from "@mui/material";
import React from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddIcCallIcon from "@mui/icons-material/AddIcCall";

const HeaderTitle = () => {
  return (
    <Box sx={{ mt: "10px" }}>
      <Box
        sx={{
          width: "85%",
          m: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
            },
            justifyContent: "space-between",
            pb: "10px",
            borderBottom: "1px solid #CAF9DE",
            gap: {
              xs: 0,
              sm: 0,
              md: 12,
              lg: 12,
            },
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "5px",
              width: {
                xs: "100%",
                sm: "100%",
                md: "70%",
                lg: "70%",
              },
            }}
          >
            <ErrorOutlineIcon
              sx={{ color: "var(--primary-color)", fontSize: "18px" }}
            />

            <marquee
              behavior="scroll"
              direction="left"
              style={{ fontSize: "13px", color: "var(--light-gray)" }}
            >
              Ramadan Offer: Special Price start from BDT 2200 on all Domestic
              Routes (*Condition Apply) Tickets for UMRAH Visa are not allowed
              to purchase from Website and Mobile app. From 29 March 2023 Biman
              Flight will operate from Heathrow Airport Terminal-04 ENJOY 05%
              SPECIAL DISCOUNT ON BIMAN ONLINE TICKETING (WEBSITE & MOBILE APP)
              USING PROMO CODE "NEWYEAR23". THIS PROMO CODE IS APPLICABLE FOR
              PAYMENTS THROUGH BKASH, NAGAD, CREDIT/DEBIT CARD (VISA, MASTER,
              AMEX) ON ALL BIMAN SECTOR/ROUTE up to 31st December, 2023.
            </marquee>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: {
                xs: "100%",
                sm: "100%",
                md: "30%",
                lg: "30%",
              },
              gap: {
                xs: "10px",
                sm: "10px",
                md: "35px",
                lg: "35px",
              },
              pt: {
                xs: "10px",
                sm: "0px",
                md: "0px",
                lg: "0px",
              },
            }}
          >
            <Box
              style={{
                display: "flex",
                gap: "5px",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <MailOutlineIcon
                sx={{ color: "var(--primary-color)", fontSize: "15px" }}
              />
              <Typography
                style={{ fontSize: "13px", color: "var(--light-gray)" }}
              >
                world.doh@gmail.com
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "5px",
                width: "100%",
                alignItems: "center",
              }}
            >
              <AddIcCallIcon
                sx={{ color: "var(--primary-color)", fontSize: "15px" }}
              />
              <Typography
                style={{ fontSize: "13px", color: "var(--light-gray)" }}
              >
                +974-44340191
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderTitle;
