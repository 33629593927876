import { Box, Container, Modal, Tooltip, Typography } from "@mui/material";
import React from "react";
import FullScreenSlider from "../FullScreenSlider/FullScreenSlider";
import HomeSearchBox from "../HomeSearchBox/HomeSearchBox";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import "./Dashboard.css";
import MarqueeShow from "../MarqueeShow/MarqueeShow";
import secureLocalStorage from "react-secure-storage";
import { useQuery } from "react-query";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  height: 600,
  bgcolor: "background.paper",
  borderRadius: "4px",
  boxShadow: 24,
  outline: "none",
  borderRadius: "5px",
};

const Dashboard = () => {
  const user = secureLocalStorage.getItem("user-info");
  const showAd = secureLocalStorage.getItem("show-ad");
  const agentId = user?.user?.agentId;

  const [openAd, setOpenAd] = useState(showAd);
  const [openAdData, setOpenAdData] = useState({});

  useEffect(() => {
    fetch("https://api.rtc.flyfarint.com/v1.0/admin/popup/index.php?b2b")
      .then((res) => res.json())
      .then((data) => {
        setOpenAdData(data);
      });
  }, []);

  const handleCloseAd = () => {
    setOpenAd(false);
    secureLocalStorage.setItem("show-ad", false);
  };


  return (
    <Box sx={{ height: "65vh", }}>
      {/* <Header /> */}
      <UserHeader />

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "var(--white)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            p: "20px",
            m: "0 auto",
            width: "90%",
            mt: "-10vh",
            // position: "relative",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontWeight: 500,
              fontSize: "18px !important",
              fontFamily: "poppins",
            }}
          >
            Search Pad
          </Typography>
          <Box sx={{ mt: "50px", borderRadius:"3px" }}>
            <HomeSearchBox />
          </Box>

        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between", px: "15px" }}>
          <Box
            sx={{
              width: "100%",
              bgcolor: "red",
              textAlign: "center",

            }}
          >
            <MarqueeShow />
          </Box>
        </Box>
      </Box>

    </Box>

  );
};

export default Dashboard;
