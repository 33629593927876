import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Slider from "@mui/material/Slider";
import { AiFillCaretDown } from "react-icons/ai";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import "../../pages/SearchReslut/SearchResult.css";

import midnight from "../../images/svg/midnight.svg";
import morning from "../../images/svg/morning.svg";
import noon from "../../images/svg/noon.svg";
import night from "../../images/svg/night.svg";

import {
  filterByBaggage,
  filterFlightsByLayoverTime,
  filterFlightsByLayoverAirport,
  handleFilterByTime,
} from "./index";

import {
  Box,
  Checkbox,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";

import moment from "moment";

const times = [
  {
    image: midnight,
    value: "00:00 - 05:59",
  },
  {
    image: morning,
    value: "06:00 - 11:59",
  },
  {
    image: noon,
    value: "12:00 - 17:59",
  },
  {
    image: night,
    value: "18:00 - 23:59",
  },
];

const layoverTimes = [
  { minute: 300, text: "0h - 5h" },
  { minute: 600, text: "5h - 10h" },
  { minute: 900, text: "10h - 15h" },
  { minute: 1200, text: "15h - 20h+" },
];

const MultiWayFilter = ({
  filteredData,
  setfilteredData,
  initialFetchData,
  sortedBy,
  setSortedBy,
  selectedAirLines,
  setSelectedAirLines,
}) => {
  console.log("selectedAirLines", selectedAirLines);

  const location = useLocation();
  let arr = [];
  let time = [];
  let allDeptTimes = [];
  let allArrTimes = [];
  let allAirports = [];
  let uniqueAirports = [];
  // Layover Airport filter states
  const [isShowAll, setIsShowAll] = useState(false);
  const maxAirportsToShow = 5;
  let visibleAirports = [];
  const [activeLayoverAirport, setActiveLayoverAirport] = useState("");

  let flightprice = initialFetchData;
  flightprice.map((dat) => {
    arr.push(dat.PriceBreakDown[0].TotalFare);
    return arr;
  });

  let flightTime = initialFetchData;
  flightTime.forEach((element, index) => {
    if (element.AllLegs) {
      const totalDuration = element.AllLegs.reduce((total, leg) => {
        if (leg.segments) {
          return (
            total +
            leg.segments.reduce(
              (legTotal, segment) => legTotal + segment.flightduration,
              0
            )
          );
        }
        return total;
      }, 0);

      time.push({
        flight: element,
        duration: totalDuration,
      });
    }
  });

  let deptTimes = initialFetchData;

  deptTimes.forEach((element) => {
    if (element.AllLegs && element.AllLegs.length > 0) {
      const firstDeptTime = new Date(
        element.AllLegs[0].segments[0]?.departureTime
      );
      const options = { hour: "2-digit", minute: "2-digit", hour12: false };
      const formattedTime = firstDeptTime.toLocaleTimeString("en-US", options);

      if (formattedTime) {
        allDeptTimes.push({
          flight: element,
          deptTime: formattedTime,
        });
      }
    }
  });

  let arrTimes = initialFetchData;

  arrTimes.forEach((element) => {
    if (element.AllLegs && element.AllLegs.length > 0) {
      const lastSegment =
        element.AllLegs[element.AllLegs.length - 1].segments[
          element.AllLegs[element.AllLegs.length - 1].segments.length - 1
        ];
      const firstDeptTime = new Date(lastSegment?.arrivalTime);
      const options = { hour: "2-digit", minute: "2-digit", hour12: false };
      const formattedTime = firstDeptTime.toLocaleTimeString("en-US", options);

      if (formattedTime) {
        allArrTimes.push({
          flight: element,
          arrTime: formattedTime,
        });
      }
    }
  });

  let airports = initialFetchData;

  airports.forEach((element) => {
    if (element.AllLegs && element.AllLegs.length > 0) {
      const firstAirport = element.AllLegs[0].segments[0]?.arrivalAirport;
      allAirports.push({
        flight: element,
        layover: firstAirport,
      });
    }

    let uniqueAirportSet = new Set(
      allAirports.map((airport) => airport.layover)
    );
    uniqueAirports = [...uniqueAirportSet];
    visibleAirports = isShowAll
      ? uniqueAirports
      : uniqueAirports.slice(0, maxAirportsToShow);
  });

  const maxPrice = Math.max(...arr);
  const minPrice = Math.min(...arr);
  const minTime = Math.min(...time);

  const [range, setRange] = useState([minPrice, maxPrice]);
  const [selectPrice, setSelectPrice] = useState([minPrice, maxPrice]);
  // fare type states
  const [refundable, setRefundable] = useState(false);
  const [nonRefundable, setNonRefundable] = useState(false);

  // stops states
  const [directFlight, setDirectFlight] = useState(false);
  const [oneStopFlight, setOneStopFlight] = useState(false);
  const [multiStopFlight, setMultiStopFlight] = useState(false);

  // departure and arrival time filter states
  const [departureTime, setDepartureTime] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");

  // Layover time filter states
  const [activeLayoverTime, setActiveLayoverTime] = useState("");
  const [activeDepartureTime, setActiveDepartureTime] = useState("");
  const [activeArrivalTime, setActiveArrivalTime] = useState("");

  // Baggage filter states
  const [baggageValue, setBaggageValue] = useState("");
  const allLegsArray = initialFetchData.flatMap((flight) => flight.AllLegs);
  useEffect(() => {
    const handleFilterData = () => {
      let copyFilteredData = [...initialFetchData];

      if (refundable) {
        const updatedData = copyFilteredData.filter((item) => {
          return item.refundable === "true" ? item : "";
        });

        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (nonRefundable) {
        const updatedData = copyFilteredData.filter((item) => {
          return item.refundable === "false" ? item : "";
        });
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (directFlight) {
        const updatedData = copyFilteredData.filter((item) =>
          item.AllLegs.some((leg) => leg.segments.length === 1)
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (oneStopFlight) {
        const updatedData = copyFilteredData.filter((item) =>
          item.AllLegs.some((leg) => leg.segments.length === 2)
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (multiStopFlight) {
        const updatedData = copyFilteredData.filter((item) =>
          item.AllLegs.some((leg) => leg.segments.length > 2)
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (multiStopFlight) {
        const updatedData = copyFilteredData.filter((item) =>
          item.AllLegs.every((leg) => leg.segments.length >= 1)
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (departureTime) {
        const updatedData = handleFilterByTime(
          "departureTime",
          departureTime,
          copyFilteredData
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (arrivalTime) {
        const updatedData = handleFilterByTime(
          "arrivalTime",
          arrivalTime,
          copyFilteredData
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (baggageValue) {
        // const updatedData = filterByBaggage(baggageValue, copyFilteredData);
        const updatedData = copyFilteredData.filter(
          (item) =>
            item?.PriceBreakDown[0]?.Bag[0]?.Allowance?.weight === baggageValue
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (selectedAirLines.length > 0) {
        const updatedData = copyFilteredData.filter((item) =>
          selectedAirLines.includes(item.career)
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (
        !refundable &&
        !nonRefundable &&
        !directFlight &&
        !oneStopFlight &&
        !multiStopFlight &&
        !departureTime &&
        !arrivalTime &&
        !activeLayoverTime &&
        !baggageValue &&
        selectedAirLines.length < 1
      ) {
        setfilteredData(initialFetchData);
      }
    };

    handleFilterData();
  }, [
    refundable,
    nonRefundable,
    directFlight,
    oneStopFlight,
    multiStopFlight,
    departureTime,
    arrivalTime,
    activeLayoverTime,
    baggageValue,
    selectedAirLines,
  ]);

  //////  Reset filter   //////
  const handleResetBtn = () => {
    setSortedBy("cheapest");
    setRange([minPrice, maxPrice]);
    setSelectPrice([minPrice, maxPrice]);
    setRefundable(false);
    setNonRefundable(false);
    setDirectFlight(false);
    setOneStopFlight(false);
    setMultiStopFlight(false);
    setDepartureTime("");
    setArrivalTime("");
    setActiveLayoverTime("");
    setActiveLayoverAirport("");
    setBaggageValue("");
    setSelectedAirLines([]);
    setfilteredData(initialFetchData);
  };

  //////  cheapest filter   //////
  const handleFilterByCheapest = () => {
    const sortedByCheapest = [...filteredData].sort(
      (a, b) => parseFloat(a.price) - parseFloat(b.price)
    );
    setSortedBy("cheapest");
    setfilteredData(sortedByCheapest);
  };

  //////  cheapest filter   //////
  const convertFlightDurationToMinute = (hour, minute) => {
    const hours = parseInt(hour.replace("H", ""), 10);
    const minutes = parseInt(minute.replace("Min", ""), 10);
    const total = hours * 60 + minutes;
    return total;
  };

  const handleFilterByFastest = () => {
    const sortedByFastest = [...filteredData].sort((a, b) => {
      return a - b;
    });

    setSortedBy("fastest");
    setfilteredData(sortedByFastest);
  };

  //////  price range filter   //////
  const handleChange = (event, newValue) => {
    setRange(newValue);

    const filteredFlights = initialFetchData.filter((flight) => {
      const isWithinPriceRange =
        flight.PriceBreakDown[0].TotalFare >= newValue[0] &&
        flight.PriceBreakDown[0].TotalFare <= newValue[1];

      // Check refundable status only if either refundable or non-refundable is selected
      const isRefundable =
        (refundable && flight.refundable === "true") ||
        (nonRefundable && flight.refundable === "false");

      // Add additional conditions for other filters (direct, one-stop, multi-stop)
      const isDirectFlight =
        directFlight && flight.AllLegs.some((leg) => leg.segments.length === 1);
      const isOneStopFlight =
        oneStopFlight &&
        flight.AllLegs.some((leg) => leg.segments.length === 2);
      const isMultiStopFlight =
        multiStopFlight &&
        flight.AllLegs.some((leg) => leg.segments.length > 2);

      // console.log(isDirectFlight);

      // Combine conditions based on selected filters
      return (
        (isWithinPriceRange &&
          (isRefundable || (!refundable && !nonRefundable)) &&
          (isDirectFlight || isOneStopFlight || isMultiStopFlight)) ||
        (isWithinPriceRange &&
          (isRefundable || (!refundable && !nonRefundable))) ||
        (isWithinPriceRange &&
          (isDirectFlight || isOneStopFlight || isMultiStopFlight))
      );
    });

    setfilteredData(filteredFlights);
  };

  //////  Refundable on change   //////
  const handleRefundable = (e) => {
    setRefundable(e.target.checked);
    setNonRefundable(false);
  };

  //////  Nonrefundable on change    //////
  const handleNonRefundable = (e) => {
    setRefundable(false);
    setNonRefundable(e.target.checked);
  };

  //////  Non Stop on change    //////
  const handleDirectFlight = (e) => {
    setOneStopFlight(false);
    setMultiStopFlight(false);
    setDirectFlight(e.target.checked);
  };

  //////  One Stop on change    //////
  const handleOneStopFlight = (e) => {
    setDirectFlight(false);
    setOneStopFlight(e.target.checked);
    setMultiStopFlight(false);
  };

  //////  One plus Stop on change    //////
  const handleMultiStopFlight = (e) => {
    setMultiStopFlight(e.target.checked);
    setDirectFlight(false);
    setOneStopFlight(false);
  };

  //////  Departure on change    //////
  const onDepartureTimeChange = (value) => {
    const getFilterData = (startHour, endHour) => {
      return allDeptTimes
        ?.filter((airline) => {
          const deptHour = parseInt(airline?.deptTime.split(":")[0], 10);
          const deptMinute = parseInt(airline?.deptTime.split(":")[1], 10);
          return deptHour >= startHour && deptHour < endHour;
        })
        ?.map((airline) => airline?.flight);
    };

    if (activeDepartureTime === value) {
      setfilteredData(initialFetchData);
      setActiveDepartureTime("");
    } else {
      switch (value) {
        case "00:00 - 05:59":
          setfilteredData(getFilterData(0, 6));
          setActiveDepartureTime("00:00 - 05:59");
          setActiveArrivalTime("");
          break;
        case "06:00 - 11:59":
          setfilteredData(getFilterData(6, 12));
          setActiveDepartureTime("06:00 - 11:59");
          setActiveArrivalTime("");
          break;
        case "12:00 - 17:59":
          setfilteredData(getFilterData(12, 18));
          setActiveDepartureTime("12:00 - 17:59");
          setActiveArrivalTime("");
          break;
        case "18:00 - 23:59":
          setfilteredData(getFilterData(18, 24));
          setActiveDepartureTime("18:00 - 23:59");
          setActiveArrivalTime("");
          break;
        default:
          break;
      }
    }
  };

  //////  Arrival on change   //////
  const onArrivalTimeChange = (value) => {
    const getFilterData = (startHour, endHour) => {
      return allArrTimes
        ?.filter((airline) => {
          const arrHour = parseInt(airline?.arrTime.split(":")[0], 10);
          const arrMinute = parseInt(airline?.arrTime.split(":")[1], 10);
          return arrHour >= startHour && arrHour < endHour;
        })
        ?.map((airline) => airline?.flight);
    };

    if (activeArrivalTime === value) {
      setfilteredData(initialFetchData);
      setActiveArrivalTime("");
    } else {
      switch (value) {
        case "00:00 - 05:59":
          setfilteredData(getFilterData(0, 6));
          setActiveDepartureTime("");
          setActiveArrivalTime("00:00 - 05:59");
          break;
        case "06:00 - 11:59":
          setfilteredData(getFilterData(6, 12));
          setActiveDepartureTime("");
          setActiveArrivalTime("06:00 - 11:59");
          break;
        case "12:00 - 17:59":
          setfilteredData(getFilterData(12, 18));
          setActiveDepartureTime("");
          setActiveArrivalTime("12:00 - 17:59");
          break;
        case "18:00 - 23:59":
          setfilteredData(getFilterData(18, 24));
          setActiveDepartureTime("");
          setActiveArrivalTime("18:00 - 23:59");
          break;
        default:
          break;
      }
    }
  };

  ////// Layover Time on change    //////
  const onLayoverTimeChange = (value) => {
    const getFilterData = (minDuration, maxDuration) => {
      const filterData = time?.filter(
        (airline) =>
          airline?.duration >= minDuration && airline?.duration <= maxDuration
      );
      setfilteredData(filterData?.map((airline) => airline?.flight));
      setActiveLayoverTime(value);
    };

    if (activeLayoverTime === value) {
      setfilteredData(initialFetchData);
      setActiveLayoverTime(null);
    } else {
      switch (value) {
        case 300:
          getFilterData(0, value);
          break;
        case 600:
          getFilterData(300, value);
          break;
        case 900:
          getFilterData(600, value);
          break;
        case 1200:
          getFilterData(900, 500000);
          break;
        default:
          break;
      }
    }
  };

  ////// Layover Airport on change    //////
  const onLayoverAirportChange = (airport) => {
    const filterData = allAirports.filter((air) => air?.layover === airport);
    if (airport === activeLayoverAirport) {
      setActiveLayoverAirport("");
      setfilteredData(initialFetchData);
    } else {
      setfilteredData(filterData?.map((airline) => airline?.flight));
      setActiveLayoverAirport(airport);
    }
  };

  ////// Baggage on change    //////
  const bagsArr = initialFetchData.map((item) => {
    return {
      bags: item?.PriceBreakDown[0]?.Bag[0]?.Allowance?.weight,
      system: item.system,
    };
  });
  const uniqueBagsArr = bagsArr.filter((item, index, array) => {
    return (
      array.findIndex(
        (obj) => parseFloat(obj.bags) === parseFloat(item.bags)
      ) === index
    );
  });

  const handleBaggageChangle = (bags) => {
    if (baggageValue === bags) {
      setBaggageValue("");
    } else {
      setBaggageValue(bags);
    }
  };

  return (
    <Box
      className="flight-filter1 filter-side-0"
      sx={{
        bgcolor: "white",
        mt: 2,
        height: "100vh",
        "& .MuiAccordionSummary-root": {
          minHeight: "30px !important",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0 !important",
        },
        ".MuiPaper-root": {
          boxShadow: "none",
        },
        pb: 12,
      }}
    >
      {/* Reset Button */}
      {/* {filteredData.length} */}

      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        pt={2}
        pl={2}
      >
        <Typography
          sx={{
            color: "var(--meteBlack)",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          FILTER
        </Typography>
        <Typography
          onClick={() => {
            handleResetBtn();
          }}
          sx={{
            cursor: "pointer",
            color: "var(--meteBlack)",
            borderRadius: "5px",
            fontSize: "12px",
            mr: 2,
          }}
        >
          Reset
        </Typography>
      </Grid>

      {/* Cheapest and fastest */}
      <Box
        sx={{
          p: "15px",
          pb: "0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={handleFilterByCheapest}
          sx={{
            // px: "10px",
            // py: "5px",
            bgcolor: `${sortedBy === "cheapest" ? "var(--secondary-color)" : "#CCC"} `,
            color: "var(--white)",
            fontSize: "12px",
            width: "48%",
            ":hover": {
              bgcolor: "#F7D800",
            },
          }}
        >
          Cheapest
        </Button>
        <Button
          onClick={handleFilterByFastest}
          sx={{
            px: "10px",
            py: "5px",
            bgcolor: `${sortedBy === "fastest" ? "#F7D800" : "#CCC"} `,
            color: "#444542",
            fontSize: "12px",
            width: "48%",
            ":hover": {
              bgcolor: "#F7D800",
            },
          }}
        >
          Fastest
        </Button>
      </Box>

      {/*  Price slider */}
      <Accordion
        style={{ margin: "0" }}
        defaultExpanded={true}
        className="Accordion12"
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="#003566" fontSize="12px" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container justifyContent={"space-between"}>
            <Typography className="fil-title">Price Range</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className="AccordionDetails22"
          sx={{
            ".MuiAccordionDetails .MuiSlider-valueLabelOpen": {
              bgcolor: "red",
            },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Grid item className="price-slider-line" sx={{ px: "10px" }}>
              <Slider
                value={range}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={minPrice}
                max={maxPrice}
              />
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "12px",
                  fontFamily: "Poppins !important",
                }}
              >
                ৳ {minPrice.toLocaleString()}
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "12px",
                  fontFamily: "Poppins !important",
                }}
              >
                ৳ {maxPrice.toLocaleString()}
              </Typography>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Refundable and non refundable */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Fare Type</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <FormGroup className="check-box-text09">
              <FormControlLabel
                value={"refundable"}
                control={<Checkbox className="box-0" />}
                checked={refundable}
                onChange={handleRefundable}
                label={<span style={{ fontSize: "12px" }}>Refundable</span>}
              />
              <FormControlLabel
                value={"NonRefundable"}
                control={<Checkbox className="box-0" />}
                checked={nonRefundable}
                onChange={handleNonRefundable}
                label={<span style={{ fontSize: "12px" }}>Non Refundable</span>}
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Stopage */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px 20px 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Stops</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <FormGroup className="check-box-text09">
              <FormControlLabel
                onChange={handleDirectFlight}
                checked={directFlight}
                control={<Checkbox className="box-0" />}
                label={<span style={{ fontSize: "12px" }}>Non Stop</span>}
              />
              <FormControlLabel
                control={<Checkbox className="box-0" />}
                checked={oneStopFlight}
                onChange={handleOneStopFlight}
                label={<span style={{ fontSize: "12px" }}>One Stops</span>}
              />
              <FormControlLabel
                control={<Checkbox className="box-0" />}
                checked={multiStopFlight}
                onChange={handleMultiStopFlight}
                label={<span style={{ fontSize: "12px" }}>One Plus Stops</span>}
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/*Departure time filter */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px 20px 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Box>
            <Typography sx={{ display: "block" }} className="fil-title">
              Departure Times
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          {times.map((item, i) => (
            <Box
              onClick={() => onDepartureTimeChange(item.value)}
              sx={{
                display: "flex",
                p: "5px",
                my: "5px",
                cursor: "pointer",
                bgcolor: "#CAF9DE",
                border: `${
                  activeDepartureTime === item.value
                    ? "1px solid #2156a6"
                    : "1px solid transparent"
                }`,
              }}
            >
              <img src={item.image} alt="" />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",
                  ml: "12px",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      {/*Arrival time filter */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px 20px 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Arrival Times</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          {times.map((item, i) => (
            <Box
              onClick={() => onArrivalTimeChange(item.value)}
              sx={{
                display: "flex",
                p: "5px",
                my: "5px",
                cursor: "pointer",
                bgcolor: "#CAF9DE",
                border: `${
                  activeArrivalTime === item.value
                    ? "1px solid #2156a6"
                    : "1px solid transparent"
                }`,
              }}
            >
              <img src={item.image} alt="" />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",
                  ml: "12px",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Layover time */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Layover Time</Typography>
        </AccordionSummary>
        <AccordionDetails
          style={{ margin: "0", padding: "0", paddingBottom: "15px" }}
        >
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            {layoverTimes.map((item, i) => (
              <Typography
                onClick={() => onLayoverTimeChange(item.minute)}
                key={i}
                sx={{
                  fontSize: "12px",
                  width: "50%",
                  border:
                    i === 0 || i === 2
                      ? "1px solid #c7c7c7"
                      : "1px solid #c7c7c7",
                  borderLeft: i === 1 || i === 3 ? "none" : "",
                  borderBottom: i === 0 || i === 1 ? "none" : "",
                  textAlign: "center",
                  py: "6px",
                  bgcolor: `${
                    activeLayoverTime === item?.minute ? "#E9F3FF" : ""
                  }`,
                  cursor: "pointer",
                }}
              >
                {item.text}
              </Typography>
            ))}
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Layover airport */}
      <Accordion
        defaultExpanded={true}
        style={{
          margin: "0",
          padding: "0 15px",
          mt: "10px",
        }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Layover Airport</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <FormGroup className="check-box-text09">
            {visibleAirports.map((item, i) => (
              <FormControlLabel
                key={i}
                control={
                  <Checkbox
                    checked={activeLayoverAirport === item}
                    onChange={() => onLayoverAirportChange(item)}
                    name={item?.arrival}
                    className="box-0"
                  />
                }
                label={<span style={{ fontSize: "12px" }}> {item}</span>}
              />
            ))}
            <Typography
              onClick={() => setIsShowAll(!isShowAll)}
              sx={{
                cursor: "pointer",
                color: "var(--primary-color)",
                borderRadius: "5px",
                fontSize: "12px",
                textAlign: "right",
                mb: "15px",
                mt: "8px",
              }}
            >
              Show {isShowAll ? "Less" : "All"}
            </Typography>
          </FormGroup>
        </AccordionDetails>
      </Accordion>

      {/* baggage */}

      <Accordion
        defaultExpanded={true}
        style={{
          margin: "0",
          padding: "0 15px",
          marginTop: "10px",
          // display: `${uniqueFlightData.length === 0 ? "none" : "block"}`,
        }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Baggage</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <FormGroup className="check-box-text09">
            {uniqueBagsArr.map((item, i) => {
              return (
                <Box key={i}>
                  {item.bags && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => handleBaggageChangle(item.bags)}
                          checked={baggageValue === item.bags}
                          name={item}
                          className="box-0"
                        />
                      }
                      label={
                        <span style={{ fontSize: "12px" }}>
                          {parseFloat(item.bags) > 3
                            ? parseFloat(item.bags) + " KG"
                            : parseFloat(item.bags) * 23 + " KG"}
                        </span>
                      }
                    />
                  )}
                </Box>
              );
            })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

// export default SliderFilter;
export default MultiWayFilter;
