import React, { useEffect, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Slider from "@mui/material/Slider";
import { AiFillCaretDown } from "react-icons/ai";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "../../pages/SearchReslut/SearchResult.css";

import midnight from "../../images/svg/midnight.svg";
import morning from "../../images/svg/morning.svg";
import noon from "../../images/svg/noon.svg";
import night from "../../images/svg/night.svg";

import {
  filterByBaggage,
  filterFlightsByLayoverTime,
  filterFlightsByLayoverAirport,
  handleFilterByTime,
} from "./index";

import {
  Box,
  Checkbox,
  Grid,
  Button,
  FormGroup,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import moment from "moment";

const times = [
  {
    image: midnight,
    value: "00:00 - 05:59",
  },
  {
    image: morning,
    value: "06:00 - 11:59",
  },
  {
    image: noon,
    value: "12:00 - 17:59",
  },
  {
    image: night,
    value: "18:00 - 23:59",
  },
];

const layoverTimes = [
  { minute: 300, text: "0h - 5h" },
  { minute: 600, text: "5h - 10h" },
  { minute: 900, text: "10h - 15h" },
  { minute: 901, text: "15h - 20h+" },
];

const OneWayFilter = ({
  filteredData,
  setfilteredData,
  initialFetchData,
  sortedBy,
  setSortedBy,
  selectedAirLines,
  setSelectedAirLines,
}) => {
  const location = useLocation();
  let arr = [];

  let flightprice = initialFetchData;
  flightprice.map((dat) => {
    arr.push(dat.price || dat.clientPrice);
    return arr;
  });

  const maxPrice = Math.max(...arr);
  const minPrice = Math.min(...arr);
  const [range, setRange] = useState([minPrice, maxPrice]);
  const [selectPrice, setSelectPrice] = useState([minPrice, maxPrice]);
  // fare type states
  const [refundable, setRefundable] = useState(false);
  const [nonRefundable, setNonRefundable] = useState(false);

  // stops states
  const [directFlight, setDirectFlight] = useState(false);
  const [oneStopFlight, setOneStopFlight] = useState(false);
  const [multiStopFlight, setMultiStopFlight] = useState(false);

  // departure and arrival time filter states
  const [departureTime, setDepartureTime] = useState("");
  const [arrivalTime, setArrivalTime] = useState("");

  // Layover time filter states
  const [activeLayoverTime, setActiveLayoverTime] = useState("");

  // Layover Airport filter states
  const [isShowAll, setIsShowAll] = useState(false);
  const [activeLayoverAirport, setActiveLayoverAirport] = useState("");

  // Baggage filter states
  const [baggageValue, setBaggageValue] = useState("");

  useEffect(() => {
    const handleFilterData = () => {
      let copyFilteredData = [...initialFetchData];

      if (refundable) {
        const updatedData = copyFilteredData.filter(
          (item) => item.refundable.toLowerCase() === "refundable"
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (nonRefundable) {
        const updatedData = copyFilteredData.filter(
          (item) => item.refundable.toLowerCase() === "nonrefundable"
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (directFlight) {
        const updatedData = copyFilteredData.filter(
          (item) => item.segment === "1"
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (oneStopFlight) {
        const updatedData = copyFilteredData.filter(
          (item) => item.segment === "2"
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (multiStopFlight) {
        const updatedData = copyFilteredData.filter(
          (item) => parseFloat(item.segment) > 2
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (multiStopFlight) {
        const updatedData = copyFilteredData.filter(
          (item) => parseFloat(item.segment) > 2
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (departureTime) {
        const updatedData = handleFilterByTime(
          "departureTime",
          departureTime,
          copyFilteredData
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (arrivalTime) {
        const updatedData = handleFilterByTime(
          "arrivalTime",
          arrivalTime,
          copyFilteredData
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (activeLayoverTime) {
        const updatedData = filterFlightsByLayoverTime(
          activeLayoverTime,
          copyFilteredData
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (activeLayoverAirport) {
        const updatedData = filterFlightsByLayoverAirport(
          activeLayoverAirport,
          copyFilteredData
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }

      if (baggageValue) {
        const updatedData = filterByBaggage(baggageValue, copyFilteredData);
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }


      if (selectedAirLines.length > 0) {
        const updatedData = copyFilteredData.filter((item) =>
          selectedAirLines.includes(item.career)
        );
        setfilteredData(updatedData);
        copyFilteredData = [...updatedData];
      }



      if (
        !refundable &&
        !nonRefundable &&
        !directFlight &&
        !oneStopFlight &&
        !multiStopFlight &&
        !departureTime &&
        !arrivalTime &&
        !activeLayoverTime &&
        !activeLayoverAirport &&
        !baggageValue&&
        selectedAirLines.length < 1
      ) {
        setfilteredData(initialFetchData);
      }
    };

    handleFilterData();
  }, [
    refundable,
    nonRefundable,
    directFlight,
    oneStopFlight,
    multiStopFlight,
    departureTime,
    arrivalTime,
    activeLayoverTime,
    activeLayoverAirport,
    baggageValue,
    selectedAirLines
  ]);

  //////  Reset filter   //////
  const handleResetBtn = () => {
    setSortedBy("cheapest");
    setRange([minPrice, maxPrice]);
    setSelectPrice([minPrice, maxPrice]);
    setRefundable(false);
    setNonRefundable(false);
    setDirectFlight(false);
    setOneStopFlight(false);
    setMultiStopFlight(false);
    setDepartureTime("");
    setArrivalTime("");
    setActiveLayoverTime("");
    setActiveLayoverAirport("");
    setBaggageValue("");
    setfilteredData(initialFetchData);
    setSelectedAirLines([]);
  };

  //////  cheapest filter   //////
  const handleFilterByCheapest = () => {
    const sortedByCheapest = [...filteredData].sort(
      (a, b) => parseFloat(a.price) - parseFloat(b.price)
    );
    setSortedBy("cheapest");
    setfilteredData(sortedByCheapest);
  };

  //////  cheapest filter   //////
  const convertFlightDurationToMinute = (hour, minute) => {
    const hours = parseInt(hour.replace("H", ""), 10);
    const minutes = parseInt(minute.replace("Min", ""), 10);
    const total = hours * 60 + minutes;
    return total;
  };

  const handleFilterByFastest = () => {
    const sortedByFastest = [...filteredData].sort((a, b) => {
      const [aHours, aMinutes] = a.flightduration.split(" ");
      const [bHours, bMinutes] = b.flightduration.split(" ");
      const aTime = convertFlightDurationToMinute(aHours, aMinutes);
      const bTime = convertFlightDurationToMinute(bHours, bMinutes);
      return aTime - bTime;
    });

    setSortedBy("fastest");
    setfilteredData(sortedByFastest);
  };

  //////  price range filter   //////
  const handleChange = (event, newValue) => {
    setRange(newValue);
    const filteredFlights = initialFetchData.filter(
      (flight) => flight.price >= newValue[0] && flight.price <= newValue[1]
    );
    setfilteredData(filteredFlights);
  };

  //////  Refundable on change   //////
  const handleRefundable = (e) => {
    setRefundable(e.target.checked);
    setNonRefundable(false);
  };

  //////  Nonrefundable on change    //////
  const handleNonRefundable = (e) => {
    setRefundable(false);
    setNonRefundable(e.target.checked);
  };

  //////  Non Stop on change    //////
  const handleDirectFlight = (e) => {
    setOneStopFlight(false);
    setMultiStopFlight(false);
    setDirectFlight(e.target.checked);
  };

  //////  One Stop on change    //////
  const handleOneStopFlight = (e) => {
    setDirectFlight(false);
    setOneStopFlight(e.target.checked);
    setMultiStopFlight(false);
  };

  //////  One plus Stop on change    //////
  const handleMultiStopFlight = (e) => {
    setMultiStopFlight(e.target.checked);
    setDirectFlight(false);
    setOneStopFlight(false);
  };

  //////  Departure on change    //////
  const onDepartureTimeChange = (time) => {
    if (departureTime === time) {
      setDepartureTime("");
    } else {
      setDepartureTime(time);
    }
  };

  //////  Arrival on change   //////
  const onArrivalTimeChange = (time) => {
    if (arrivalTime === time) {
      setArrivalTime("");
    } else {
      setArrivalTime(time);
    }
  };

  ////// Layover Time on change    //////
  const onLayoverTimeChange = (value) => {
    if (activeLayoverTime === value) {
      setActiveLayoverTime("");
    } else {
      setActiveLayoverTime(value);
    }
  };

  ////// Layover Ariport on change    //////
  const allSegments = initialFetchData
    .filter((d) => d.segment >= 2)
    .flatMap((ar) => ar.segments?.slice(0, -1));

  const uniqueSegmentData = allSegments.filter((flight, index, arr) => {
    return arr.findIndex((f) => f.arrival === flight.arrival) === index;
  });

  const layoverAirports = isShowAll
    ? uniqueSegmentData
    : uniqueSegmentData.slice(0, 5);

  const onLayoverAirportChange = (airportCode) => {
    if (activeLayoverAirport === airportCode) {
      setActiveLayoverAirport("");
    } else {
      setActiveLayoverAirport(airportCode);
    }
  };

  ////// Baggage on change    //////
  const bagsArr = initialFetchData.map((item) => {
    return {
      bags: item?.bags,
      system: item.system,
    };
  });

  const uniqueBagsArr = bagsArr.filter((item, index, array) => {
    return (
      array.findIndex(
        (obj) => parseFloat(obj.bags) === parseFloat(item.bags)
      ) === index
    );
  });

  const handleBaggageChangle = (bags) => {
    if (baggageValue === bags) {
      setBaggageValue("");
    } else {
      setBaggageValue(bags);
    }
  };

  // console.log(layoverAirports);

  return (
    <Box
      className="flight-filter1 filter-side-0"
      sx={{
        bgcolor: "white",
        mt: 2,
        height: "100vh",
        "& .MuiAccordionSummary-root": {
          minHeight: "30px !important",
        },
        "& .MuiAccordionSummary-content": {
          margin: "0 !important",
        },
        ".MuiPaper-root": {
          boxShadow: "none",
        },
        pb: 12,
      }}
    >
      {/* Reset Button */}
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        pt={2}
        pl={2}
      >
        <Typography
          sx={{
            color: "var(--mateBlack)",
            fontSize: "14px",
            fontWeight: 400,
          }}
        >
          FILTER
        </Typography>
        <Typography
          onClick={handleResetBtn}
          sx={{
            cursor: "pointer",
            color: "var(--mateBlack)",
            borderRadius: "5px",
            fontSize: "12px",
            mr: 2,
          }}
        >
          Reset
        </Typography>
      </Grid>

      {/* Cheapest and fastest */}
      <Box
        sx={{
          p: "15px",
          pb: "0",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button
          onClick={handleFilterByCheapest}
          sx={{
            bgcolor: `${sortedBy === "cheapest" ? "var(--secondary-color)" : "var(--secondary-color)"} `,
            color: "var(--white)",
            fontSize: "12px",
            width: "48%",
            ":hover": {
              bgcolor: "#F7D800",
            },
          }}
        >
          Cheapest
        </Button>
        <Button
          onClick={handleFilterByFastest}
          sx={{
            px: "10px",
            py: "5px",
            bgcolor: `${sortedBy === "fastest" ? "#F7D800" : "#CCC"} `,
            color: "#444542",
            fontSize: "12px",
            width: "48%",
            ":hover": {
              bgcolor: "#F7D800",
            },
          }}
        >
          Fastest
        </Button>
      </Box>

      {/*  Price slider */}
      <Accordion
        sx={{ boxShadow: "none" }}
        defaultExpanded={true}
        className="Accordion12"
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="#003566" fontSize="12px" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container justifyContent={"space-between"}>
            <Typography className="fil-title">Price Range</Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails
          className="AccordionDetails22"
          sx={{
            ".MuiAccordionDetails .MuiSlider-valueLabelOpen": {
              bgcolor: "red",
            },
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Grid item className="price-slider-line" sx={{ px: "10px" }}>
              <Slider
                value={range}
                onChange={handleChange}
                valueLabelDisplay="auto"
                aria-labelledby="range-slider"
                min={minPrice}
                max={maxPrice}
              />
            </Grid>
            <Grid container justifyContent={"space-between"}>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "12px",
                  fontFamily: "Poppins !important",
                }}
              >
                ৳ {minPrice.toLocaleString()}
              </Typography>
              <Typography
                sx={{
                  color: "#000",
                  fontWeight: "500",
                  fontSize: "12px",
                  fontFamily: "Poppins !important",
                }}
              >
                ৳ {maxPrice.toLocaleString()}
              </Typography>
            </Grid>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Refundable and non refundable */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px 20px 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Fare Type</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <FormGroup className="check-box-text09">
              <FormControlLabel
                value={"refundable"}
                control={<Checkbox className="box-0" />}
                checked={refundable}
                onChange={handleRefundable}
                label={<span style={{ fontSize: "12px" }}>Refundable</span>}
              />
              <FormControlLabel
                value={"NonRefundable"}
                control={<Checkbox className="box-0" />}
                checked={nonRefundable}
                onChange={handleNonRefundable}
                label={<span style={{ fontSize: "12px" }}>Non Refundable</span>}
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* Stopage */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px 20px 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Stops</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <FormGroup className="check-box-text09">
              <FormControlLabel
                onChange={handleDirectFlight}
                checked={directFlight}
                control={<Checkbox className="box-0" />}
                label={<span style={{ fontSize: "12px" }}>Non Stop</span>}
              />
              <FormControlLabel
                control={<Checkbox className="box-0" />}
                checked={oneStopFlight}
                onChange={handleOneStopFlight}
                label={<span style={{ fontSize: "12px" }}>One Stops</span>}
              />
              <FormControlLabel
                control={<Checkbox className="box-0" />}
                checked={multiStopFlight}
                onChange={handleMultiStopFlight}
                label={<span style={{ fontSize: "12px" }}>One Plus Stops</span>}
              />
            </FormGroup>
          </Box>
        </AccordionDetails>
      </Accordion>

      {/* airlines */}
      {/* <Accordion defaultExpanded={true} style={{ margin: "0", padding: "0" }}>
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0 15px 0 15px" }}
        >
          <Typography className="fil-title">Airlines</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <Box sx={{ width: "100%" }}>
            <AirlinesNameSlider
              data={initialFetchData}
              data2={data2}
              setData={setData}
              setData2={setData2}
              filteredData={filteredData}
              setfilteredData={setfilteredData}
            />
          </Box>
        </AccordionDetails>
      </Accordion> */}

      {/*Departure time filter */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px 20px 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Box>
            <Typography sx={{ display: "block" }} className="fil-title">
              Departure Times
            </Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#8C8080",
                mt: "10px",
              }}
            >
              From {initialFetchData[0]?.departure}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          {times.map((item, i) => (
            <Box
              onClick={() => onDepartureTimeChange(item.value)}
              sx={{
                display: "flex",
                p: "5px",
                my: "5px",
                cursor: "pointer",
                bgcolor: "#CAF9DE",
                border: `${
                  departureTime === item.value
                    ? "1px solid #2156a6"
                    : "1px solid transparent"
                }`,
              }}
            >
              <img src={item.image} alt="" />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",     
                  ml: "12px",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      {/*Arrival time filter */}
      <Accordion
        defaultExpanded={true}
        style={{ margin: "0", padding: "0 15px 20px 15px" }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Box>
            <Typography className="fil-title">Arrival Times</Typography>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "500",
                color: "#8C8080",
                mt: "10px",
              }}
            >
              To {initialFetchData[0]?.arrival}
            </Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          {times.map((item, i) => (
            <Box
              onClick={() => onArrivalTimeChange(item.value)}
              sx={{
                display: "flex",
                p: "5px",
                my: "5px",
                cursor: "pointer",
                bgcolor: "#CAF9DE",
                border: `${
                  arrivalTime === item.value
                    ? "1px solid #2156a6"
                    : "1px solid transparent"
                }`,
              }}
            >
              <img src={item.image} alt="" />
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: "500",
                  color: "var(--secondary-color)",
                  ml: "12px",
                }}
              >
                {item.value}
              </Typography>
            </Box>
          ))}
        </AccordionDetails>
      </Accordion>

      {/* Layover time */}
      {allSegments.length > 1 && (
        <Accordion
          defaultExpanded={true}
          style={{ margin: "0", padding: "0 15px 20px 15px" }}
        >
          <AccordionSummary
            expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
            id="panel2a-header"
            style={{ margin: "0", padding: "0" }}
          >
            <Typography className="fil-title">Layover Time</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ margin: "0", padding: "0" }}>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {layoverTimes.map((item, i) => (
                <Typography
                  onClick={() => onLayoverTimeChange(item.minute)}
                  key={i}
                  sx={{
                    fontSize: "12px",
                    width: "50%",
                    border:
                      i === 0 || i === 2
                        ? "1px solid #c7c7c7"
                        : "1px solid #c7c7c7",
                    borderLeft: i === 1 || i === 3 ? "none" : "",
                    borderBottom: i === 0 || i === 1 ? "none" : "",
                    textAlign: "center",
                    py: "6px",
                    bgcolor: `${
                      activeLayoverTime === item.minute ? "#E9F3FF" : ""
                    }`,
                    cursor: "pointer",
                  }}
                >
                  {item.text}
                </Typography>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}

      {/* Layover airport */}
      {allSegments.length > 1 && (
        <Accordion
          defaultExpanded={true}
          style={{
            margin: "0",
            padding: "0 15px",
            display: `${uniqueSegmentData.length === 0 ? "none" : "block"}`,
          }}
        >
          <AccordionSummary
            expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
            id="panel2a-header"
            style={{ margin: "0", padding: "0" }}
          >
            <Typography className="fil-title">Layover Airport</Typography>
          </AccordionSummary>
          <AccordionDetails style={{ margin: "0", padding: "0" }}>
            <FormGroup className="check-box-text09">
              {layoverAirports.map((item, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      checked={activeLayoverAirport === item.arrival}
                      onChange={() => onLayoverAirportChange(item.arrival)}
                      name={item?.arrival}
                      className="box-0"
                    />
                  }
                  label={
                    <span style={{ fontSize: "12px" }}>
                      {" "}
                      {item?.arrivalAirport}
                    </span>
                  }
                />
              ))}
              <Typography
                onClick={() => setIsShowAll(!isShowAll)}
                sx={{
                  cursor: "pointer",
                  color: "var(--primary-color)",
                  borderRadius: "5px",
                  fontSize: "12px",
                  textAlign: "right",
                  mb: "15px",
                  mt: "8px",
                }}
              >
                Show {isShowAll ? "Less" : "All"}
              </Typography>
            </FormGroup>
          </AccordionDetails>
        </Accordion>
      )}

      {/* baggage */}
      <Accordion
        defaultExpanded={true}
        style={{
          margin: "0",
          padding: "0 15px",
        }}
      >
        <AccordionSummary
          expandIcon={<AiFillCaretDown color="var(--primary-color)" />}
          id="panel2a-header"
          style={{ margin: "0", padding: "0" }}
        >
          <Typography className="fil-title">Baggage</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ margin: "0", padding: "0" }}>
          <FormGroup className="check-box-text09">
            {uniqueBagsArr.map((item, i) => {
              return (
                <Box key={i}>
                  {item.bags && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={() => handleBaggageChangle(item.bags)}
                          checked={baggageValue === item.bags}
                          name={item}
                          className="box-0"
                        />
                      }
                      label={
                        <span style={{ fontSize: "12px" }}>
                          {parseFloat(item.bags) > 3
                            ? parseFloat(item.bags) + " KG"
                            : parseFloat(item.bags) * 23 + " KG"}
                        </span>
                      }
                    />
                  )}
                </Box>
              );
            })}
          </FormGroup>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

// export default SliderFilter;
export default OneWayFilter;
