import React, { useState } from "react";

import {
  Box,
  Button,
  Grid,
  Typography,
  AppBar,
  Toolbar,
  Menu,
  Tooltip,
  Avatar,
  MenuItem,
  IconButton,
  Container,
} from "@mui/material";
import ReactHtmlParser from "react-html-parser";
import { useEffect } from "react";
import Footer from "../../../components/Footer/Footer";

const Terms = () => {

  const [terms, setTerms] = useState('');

  useEffect(() => {
    fetch(`https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&termsConditions`).then((res) => res.json()).then((data) => setTerms(data?.content))
  }, [])


  return (
    <Box>
    {/* <Header /> */}
    <Container sx={{ my: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", mb: "30px" }}>
        <Typography fontSize="40px" textAlign="center">
         Terms and Conditions
        </Typography>
        <Box sx={{ width: "500px", height: "3px", backgroundColor: "var(--primary-color)" }}>
        </Box>
      </Box>

      <Typography
        sx={{
          marginTop: "10px",
          color: "var(--black) !important",
          textAlign: "justify",
        }}
      >
        {ReactHtmlParser(terms)}
      </Typography>
    </Container>
    <Footer />
  </Box>
  );
};

export default Terms;
