import { Box, Stack, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import Slider from "react-slick";

const MulticitySlider = ({
  initialFetchData,
  setData2,
  selectedAirLines,
  setSelectedAirLines,
}) => {
  console.log(selectedAirLines);

  const allCarrierCodes = initialFetchData.reduce(
    (acc, obj) => acc.concat(obj.carriers),
    []
  );

  const uniqueCarrierCodes = [...new Set(allCarrierCodes)];

  const uniqueAirlines = uniqueCarrierCodes.map((code) => ({
    code,
    count: allCarrierCodes.filter((c) => c === code).length,
  }));

  // const handleAirlineSlider = (res, i) => {
  //   const filteredData = initialFetchData?.filter((airline) =>
  //     airline.carriers?.includes(res)
  //   );

  //   setSelectedAirLines(res);

  //   if (selectedSlide === i) {
  //     setSelectedSlide(null);
  //     setData2(initialFetchData);
  //   } else {
  //     setSelectedSlide(i);
  //     setData2(filteredData);
  //   }
  // };

  const CustomNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div
        onClick={onClick}
        style={{
          backgroundColor: "var(--primary-color)",
          width: "25px",
          position: "absolute",
          height: "100%",
          right: "0",
          top: "0",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            fontSize: "40px",
            margin: "0",
            color: "var(--secondary-color)",
          }}
        >
          &#8250;
        </p>
      </div>
    );
  };

  const CustomPrevArrow = (props) => {
    const { onClick } = props;

    return (
      <div
        onClick={onClick}
        style={{
          backgroundColor: "var(--primary-color)",
          width: "25px",
          position: "absolute",
          height: "100%",
          left: "0",
          top: "0",
          zIndex: 1,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            fontSize: "40px",
            margin: "0",
            color: "var(--secondary-color)",
          }}
        >
          &#8249;
        </p>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow:
      uniqueAirlines.length > 4
        ? 5
        : uniqueAirlines.length > 5
        ? 6
        : uniqueAirlines.length,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 500,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    centerPadding: "60px",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <Slider {...settings}>
      {uniqueAirlines.map((res, i) => (
        <Box
          key={i}
          // onClick={() => handleAirlineSlider(res.code, i)}
          onClick={() => {
            if (selectedAirLines.includes(res.code)) {
              setSelectedAirLines(
                selectedAirLines.filter((item) => item !== res.code)
              );
            } else {
              setSelectedAirLines([...selectedAirLines, res.code]);
            }
          }}
          sx={{
     
            height: {
              xs: "60px",
              sm: "60px",
              md: "48px",
              lg: "48px",
            },
          }}
        >
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={1}
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "90%",
                lg: "90%",
              },
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
                lg: "flex",
              },
              margin: "0 auto",
              bgcolor: selectedAirLines.includes(res.code)
                ? "var(--secondary-color)"
                : "",
              cursor: "pointer",
              ":hover": {
                boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                bgcolor: "rgba(99, 99, 99, 0.2)",
              },
              borderRadius: "5px",
              transition: "all .2s ease-in-out",
              height: "100%",
            }}
          >
            <Box>
              <img
                style={{ width: "30px" }}
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${res.code}.png`}
                alt=""
              />
            </Box>
            <Box
              sx={{
                ".MuiTypography-body1": {
                  fontSize: "13px",
                  color: "var(--dark-grey)",
                },
                ".MuiTypography-body2": {
                  fontSize: {
                    xs: "7px",
                    sm: "8px",
                    md: "13px",
                    lg: "13px",
                  },

                  color: "var(--primary-color)",
                },
              }}
            >
              <Typography variant="body2">{res.code}</Typography>
              <Typography>{res.count} flights</Typography>
            </Box>
          </Stack>
        </Box>
      ))}
    </Slider>
  );
};

export default MulticitySlider;
