import React from "react";
import { Grid, Box, Typography, Container } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import GroupFilterData from "../GroupFilterData/GroupFilterData";
import axios from "axios";
import GroupFare from "./../GroupFare/GroupFare";
import Header from "../Header/Header";
import { Puff } from "react-loader-spinner";
import UserHeader from "./../UserPage/UserHeader/UserHeader";
const GroupFareAllPackages = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [groupData, setGroupData] = useState([]);
  const [filteredData, setfilteredData] = useState([]);
  const navigate = useNavigate();
  const [noData, setNoData] = useState("No Data");

  const [siteConfig, setSiteConfig] = useState({});

  const location = useLocation();

  const { fromSendData, toSendData, departureDate, name } = location.state;
  // console.log(name);

  useEffect(() => {
    let url;
    if (name === "search-by-route") {
      url = `https://api.rtc.flyfarint.com/v1.0/b2b/groupfare/index.php?from=${fromSendData.replace(
        /\s/g,
        ""
      )}&to=${toSendData.replace(/\s/g, "")}&date=${departureDate}`;
    } else {
      url = `https://api.rtc.flyfarint.com/v1.0/admin/groupfare/index.php?all`;
    }
    console.log(url);

    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setIsLoaded(true);
        setGroupData(data?.group_fares);
        setfilteredData(data?.group_fares);
      });
  }, []);

  if (!isLoaded) {
    return (
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "70vh",
          width: "70vw",
          marginInline: "auto",
        }}
      >
        <Box
          style={{
            width: "50%",
            height: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Puff
            height="150"
            width="120"
            radius={1}
            color="var(--secondary-color)"
            ariaLabel="puff-loading"
            wrapperStyle={{}}
            wrapperClass=""
            visible={true}
          />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <UserHeader />
      {/* <UserHeader /> */}
      <Box
        sx={{
          backgroundColor: "var(--white)",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          p: "20px",
          m: "0 auto",
          width: "90%",
          mt: "-10vh",
        }}
      >
        <Grid container columnSpacing={2}>
          <Grid item lg={9.5}>
            {filteredData?.length === 0 ? (
              <Typography
                position="absolute"
                top="50%"
                left="50%"
                fontSize={"50px"}
                textAlign="center"
              >
                No Data Found
              </Typography>
            ) : (
              <>
                {filteredData?.map((data, index) => {
                  return <GroupFare flightData={data} key={index} />;
                })}
              </>
            )}
          </Grid>
          <Grid item lg={2.5}>
            <Box>
              <GroupFilterData
                groupData={groupData}
                setGroupData={setGroupData}
                filteredData={filteredData}
                setfilteredData={setfilteredData}
                noData={noData}
                setNoData={setNoData}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default GroupFareAllPackages;
