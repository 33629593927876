import React from 'react'
import { Document, Page, Text, View, StyleSheet  } from '@react-pdf/renderer';
const styles = StyleSheet.create({
   
});

const VisaPdfPage = () => {
  return (
    <Document>
      <Page size='A4' style={{padding:'27px 20px'}}>
      
        
        <View style={{fontSize:'10px',display:'flex', width:'180px', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start',marginBottom:'10px', lineHeight:'1.5px'}}>
        <Text style={{fontSize:'15px'}}>
        Fly Far International
        </Text>
        <Text>
        Ka-9/A, Hazi Abdul Latif Mantion, Bashundhara Rd, Dhaka 1229
        </Text>
        <Text>
        Email: support@flywayint.com
        </Text>
        <Text>
        Phone: 09606912912
        </Text>

      
       </View>

       <View style={{fontSize:'12px',lineHeight:'2px', margin:'10px 0px'}}>
        <Text style={{color:'#003566', fontSize:'15px', fontWeight:'900'}}>
            Required Documents for Tourist Visa Malaysia
        </Text>
        <Text>
            Visa Type: Sticker
        </Text>
       </View>

       <View>
        <Text style={{color:'#DC143C',fontSize:'15px', margin:'20px 0px'}}>Job Entry, Duration and Cost Details:</Text>
       </View>

       <View style={{display:"flex", flexDirection:'row', fontSize:'10px', lineHeight:'2px',alignItems:'center', justifyContent:'space-between'}}>
        <View style={{display:'flex', flexDirection:'column',backgroundColor:"#d1e9ff",borderRadius:'5px', width:'165px', height:'170px', padding:'10px' }}>
            <Text style={{color:'#003566',fontSize:'14px', fontWeight:800}}>Single Entry</Text>
            <Text>Duration: 3 months</Text>
            <Text>Max Stay: 90 days</Text>
            <Text>Processing Time: 8 days</Text>
            <Text>Interview: Required</Text>
            <View style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'space-between', marginTop:'30px'}}>
                <Text>Embassy Fee</Text>
                <Text>8000 Tk</Text>
                
            
            </View>
            <View  style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'space-between', fontSize:'12px', fontWeight:800}}>
                <Text>Total</Text>
                <Text>19800 Tk</Text>
                
            </View>
        </View>
        <View style={{display:'flex', flexDirection:'column',backgroundColor:"#d1e9ff",borderRadius:'5px', width:'165px', height:'170px', padding:'10px'}}>
            <Text style={{color:'#003566',fontSize:'14px', fontWeight:800}}>Double Entry</Text>
            <Text>Duration: 3 months</Text>
            <Text>Max Stay: 90 days</Text>
            <Text>Processing Time: 8 days</Text>
            <Text>Interview: Required</Text>
            <View style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'space-between', marginTop:'30px'}}>
                <Text>Embassy Fee</Text>
                <Text>8000 Tk</Text>
                
            
            </View>
            <View  style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'space-between', fontSize:'12px', fontWeight:800}}>
                <Text>Total</Text>
                <Text>19800 Tk</Text>
                
            </View>
        </View>
        <View style={{display:'flex', flexDirection:'column',backgroundColor:"#d1e9ff",borderRadius:'5px', width:'165px', height:'170px', padding:'10px'}}>
            <Text style={{color:'#003566',fontSize:'14px', fontWeight:800}}>Multiple Entry
</Text>
            <Text>Duration: 3 months</Text>
            <Text>Max Stay: 90 days</Text>
            <Text>Processing Time: 8 days</Text>
            <Text>Interview: Required</Text>
            <View style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'space-between', marginTop:'30px'}}>
                <Text>Embassy Fee</Text>
                <Text>8000 Tk</Text>
                
            
            </View>
            <View  style={{display:'flex', flexDirection:'row',alignItems:'center', justifyContent:'space-between', fontSize:'12px', fontWeight:800}}>
                <Text>Total</Text>
                <Text>19800 Tk</Text>
                
            </View>
        </View>

       </View>


       <View style={{fontSize:'10px', display:'flex',flexDirection:'column', margin:'35px 0px', lineHeight:'2px'}}>
        <Text style={{color:'#DC143C',fontSize:'15px'}}>Checklist for Job Holder:</Text>
        <Text>1. 07 Months Valid Passport with Old Passpot.(if have)</Text>
        <Text>2. Recent 2 copy photograph taken in last 3 months. (white background only, photo size 35mm*45mm)</Text>
        <Text>3. Visiting card</Text>   
        <Text>4. No Objection certificate.(NOC)</Text>
        <Text>5. Marriage certificate copy.(if spouse name not mentioned in the passaport)</Text>
        <Text>6. Employee id card copy.(One photo copy)</Text>
        <Text>7. Personal abank solvency certificate</Text>
        <Text>8. Persoanal bank statement of last 6 months and minimum balance BDT 1,00,000/- for single applicant & with family visit, Minimum BDT 2,50,000/- with proper seal & sign from bank.</Text>
        <Text>9. Copy of digital COVID-19 certificate</Text>
        <Text>10. Mysejahtera app downloads and provides details information about the travellers and share the screenshot.(Must)</Text>
        <Text>11. Air ticket booking.(Must)</Text>
        <Text>12. Confirm hotel booking.(Must)</Text>
        
        
       </View>

      </Page>
   
   </Document>
  )
}

export default VisaPdfPage