import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";

const MulticityQuotation = ({
  newQuotetionArr,
  setNewQuotetionArr,
  updatedPrice,
  flightData,
}) => {
  const [checked, setChecked] = useState(true);
  const handleBox = (data) => {
    const event = window.event;

    if (newQuotetionArr?.length > 1) {
      setChecked(event.target.checked);
    }
    if (!event.target.checked) {
      const filter = newQuotetionArr?.filter((item) => item?.uId !== data.uId);
      setNewQuotetionArr(filter);
    }
  };

  const calDuration = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let seconds = Math.round((totalMinutes - (hours * 60 + minutes)) * 60);

    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }

    return `${hours.toString().padStart(2, "0")}h ${minutes
      .toString()
      .padStart(2, "0")}min`;
  };

  console.log(flightData);
  const allData = flightData?.segments;
  return (
    <Box
      sx={{
        border: "1px solid re",
        // mt: "15px",
        p: "20px 20px",
        mb: 2,
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        bgcolor: "#EBF0F4",
        borderRadius: "5px",
      }}
    >
      <Grid container>
        <Grid item lg={10} sx={{ pb: "15px" }}>
          {flightData.AllLegs.map((segment, i, arr) => {
            const { segments, DepFrom, ArrTo } = segment;

            return (
              <Grid
                container
                justifyContent={"space-between"}
                bgcolor={"ble"}
                my={2}
                sx={{
                  mb: "18px",
                }}
                rowGap={4}
              >
                <Grid order={{ xs: 1, lg: 1 }} xs={5.8} lg={2}>
                  <img
                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${segments[0]?.marketingcareer}.png`}
                    width="50px"
                    height="50px"
                    alt=""
                  />

                  <Typography
                    variant="body2"
                    sx={{ color: "#2156A6", fontWeight: "500" }}
                  >
                    {segments[0]?.marketingcareerName}
                  </Typography>
                  <>
                    {segments?.map((seg, i) => (
                      <Typography
                        key={i}
                        variant="body2"
                        sx={{ color: "#222", fontWeight: "500", pt: "2px" }}
                      >
                        {i >= 1 && " & "} {seg.marketingcareer}
                        {seg.marketingflight} {i === 0 && ""}
                      </Typography>
                    ))}
                  </>
                </Grid>

                <Grid order={{ xs: 3, lg: 2 }} xs={5.8} lg={3.5}>
                  <Typography variant="body2" sx={{ color: "#8C8080" }}>
                    Departure
                  </Typography>
                  <Typography variant="title-lg">{DepFrom}</Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "var(--primary-color)" }}
                  >
                    {segments[0]?.departureAirport
                      ? `${segments[0]?.departureAirport}`
                      : segments[0]?.departureLocation ||
                        "Airpot Name Not Found"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#222",
                      py: "3px",
                      fontSize: "11px",
                      fontWeight: "500",
                    }}
                  >
                    {moment(segments[0]?.departureTime).format("hh:mm a")}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                    {moment(segments[0]?.departureTime?.slice(0, 19)).format(
                      "ddd, Do MMMM YYYY"
                    )}
                  </Typography>
                </Grid>

                <Grid order={{ xs: 2, lg: 3 }} xs={5.8} lg={2}>
                  <Stack
                    direction={"column"}
                    alignItems={"center"}
                    sx={{
                      bgcolor: "re",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#ACA6A6",
                        textAlign: "center",
                        mb: "10px",
                        fontSize: "12px",
                      }}
                    >
                      {/* {flightduration} */}
                      {calDuration(
                        segments
                          ?.map((item) => Number.parseInt(item?.flightduration))
                          ?.reduce((a, b) => a + b, 0)
                      )}
                    </Typography>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="122"
                      height="12"
                      viewBox="0 0 122 12"
                      fill="none"
                    >
                      <path
                        d="M120.682 11C120.4 11 104.145 11 96.0529 11H0"
                        stroke="#2156A6"
                        strokeWidth="2"
                      />
                      <path
                        d="M96.9551 2L120.682 11L96.9551 10.8623V2Z"
                        fill="#2156A6"
                        stroke="#2156A6"
                        strokeWidth="2"
                      />
                    </svg>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#444542",
                        textAlign: "center",
                        mt: "10px",
                      }}
                    >
                      {segments?.length >= 2
                        ? `${segments?.length - 1} stop${
                            segments?.length - 1 > 1 ? "s" : ""
                          }`
                        : "Non Stop"}
                    </Typography>
                  </Stack>
                </Grid>

                <Grid
                  order={{ xs: 4, lg: 4 }}
                  xs={5.8}
                  lg={3.5}
                  sx={{ pl: "15px" }}
                >
                  <Typography variant="body2" sx={{ color: "#8C8080" }}>
                    Arrival
                  </Typography>
                  <Typography variant="title-lg">{ArrTo}</Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "var(--primary-color)" }}
                  >
                    {segments[segments?.length - 1].arrivalAirport
                      ? `${segments[segments?.length - 1].arrivalAirport}`
                      : segments[segments?.length - 1].arrivalLocation ||
                        "Airpot Name Not Found"}
                  </Typography>
                  <Typography
                    sx={{
                      color: "#222",
                      fontSize: "11px",
                      fontWeight: "500",
                      py: "3px",
                    }}
                  >
                    {moment(segments[segments?.length - 1].arrivalTime).format(
                      "hh:mm a"
                    )}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                    {moment(
                      segments[segments?.length - 1].arrivalTime?.slice(0, 19)
                    ).format("ddd, Do MMMM YYYY")}
                  </Typography>
                </Grid>
              </Grid>
            );
          })}

          <Grid container justifyContent={"space-between"} mt={2} rowGap={4}>
            <Grid order={{ xs: 1, lg: 1 }} xs={5.8} lg={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        p: 0,
                        m: 0,
                        ml: 1,
                        color: "var(--secondary-color)",
                      }}
                      checked={checked}
                      onChange={() => handleBox(flightData)}
                    />
                  }
                />
                <Typography sx={{ fontSize: "12PX", color: "black" }}>
                  {flightData?.Class}
                </Typography>
              </Box>
            </Grid>
            <Grid order={{ xs: 3, lg: 2 }} xs={5.8} lg={3.5}>
              <Typography
                sx={{
                  fontSize: "12PX",
                  color: flightData?.refundable === false ? "red" : "green",
                  // bgcolor: "red",
                }}
              >
                {flightData?.refundable === false
                  ? "Non Refundable"
                  : "Refundable"}
              </Typography>{" "}
            </Grid>
            <Grid order={{ xs: 2, lg: 3 }} xs={5.8} lg={2}>
              <Typography
                sx={{ fontSize: "12PX", color: "black", textAlign: "center" }}
              >
                {
                  flightData?.AllLegs[0]?.segments[0]?.SegmentCode
                    ?.seatsAvailable
                }{" "}
                Seats
              </Typography>
            </Grid>
            <Grid
              order={{ xs: 4, lg: 4 }}
              xs={5.8}
              lg={3.5}
              sx={{ pl: "15px" }}
            >
              <Typography sx={{ fontSize: "12PX", color: "black" }}>
                Baggage:{" "}
                {flightData.PriceBreakDown[0]?.Bag[0]?.Allowance.pieceCount
                  ? flightData.PriceBreakDown[0]?.Bag[0]?.Allowance.pieceCount *
                      23 +
                    " KG"
                  : flightData.PriceBreakDown[0]?.Bag[0]?.Allowance.weight +
                    " " +
                    flightData.PriceBreakDown[0]?.Bag[0]?.Allowance.unit}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          lg={2}
          sx={{
            borderLeft: "5px solid #F0F6FF",
            my: "14px",
          }}
        >
          <Stack
            justifyContent={"space-between"}
            sx={{
              height: "100%",

              flexDirection: {
                xs: "row",
                md: "column",
              },
            }}
          >
            <Box
              sx={{
                textAlign: {
                  xs: "left",
                  md: "right",
                },
              }}
            >
              <Typography variant="title-lg">
                {Number(flightData?.netcost).toLocaleString("en-IN")} ৳
              </Typography>
              <Typography variant="body2" sx={{ color: "#2156A6" }}>
                <del>
                  {Number(flightData?.grosscost).toLocaleString("en-IN")} ৳
                </del>
              </Typography>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MulticityQuotation;
