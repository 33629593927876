import { Box, Grid, Typography } from "@mui/material";
const allBanks = [
  "Select Bank Account",
  "AB Bank Ltd.",
  "Agrani Bank",
  "Al-Arafah Islami Bank Ltd.",
  "Ansar VDP Unnayan Bank",
  "BASIC Bank",
  "BRAC Bank Ltd.",
  "Bangladesh Commerce Bank Ltd.",
  "Bangladesh Development Bank",
  "Bangladesh Krishi Bank",
  "Bank Al-Falah",
  "Bank Asia Ltd.",
  "CITI Bank NA",
  "Commercial Bank of Ceylon",
  "Community Bank Bangladesh Limited",
  "Dhaka Bank Ltd.",
  "Dutch Bangla Bank Ltd.",
  "EXIM Bank Ltd.",
  "Eastern Bank Ltd.",
  "First Security Islami Bank Ltd.",
  "Global Islamic Bank Ltd.",
  "Grameen Bank",
  "HSBC",
  "Habib Bank Ltd.",
  "ICB Islamic Bank",
  "IFIC Bank Ltd.",
  "Islami Bank Bangladesh Ltd.",
  "Jamuna Bank Ltd.",
  "Janata Bank",
  "Jubilee Bank",
  "Karmashangosthan Bank",
  "Meghna Bank Ltd.",
  "Mercantile Bank Ltd.",
  "Midland Bank Ltd.",
  "Modhumoti Bank Ltd.",
  "Mutual Trust Bank Ltd.",
  "NCC Bank Ltd.",
  "NRB Bank Ltd.",
  "NRB Commercial Bank Ltd.",
  "National Bank Ltd.",
  "National Bank of Pakistan",
  "One Bank Ltd.",
  "Padma Bank Ltd.",
  "Palli Sanchay Bank",
  "Premier Bank Ltd.",
  "Prime Bank Ltd.",
];

const ChequeTab = ({ handleInputChange, setAttachment }) => {
  return (
    <Box>
      <Box sx={{ width: "100%" }} className="passengerInput1">
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Typography>Cheque Number</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require="true"
                type="text"
                name="chequeNo"
                placeholder="Check Number"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Bank Name</Typography>
            <Box style={{ marginTop: "5px" }}>
              <select
                required={true}
                name="sender"
                onChange={handleInputChange}
              >
                {allBanks.map((bank, i) => (
                  <option key={i} value={bank}>
                    {bank}
                  </option>
                ))}
              </select>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Cheque Date</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require="true"
                type="date"
                placeholder="Date"
                name="chequeIssueDate"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Amount</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require="true"
                type="text"
                name="amount"
                placeholder="Amount"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Typography>Reference</Typography>
            <Box style={{ marginTop: "5px" }}>
              <input
                require="true"
                type="text"
                name="ref"
                placeholder="Reference"
                onChange={handleInputChange}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={4}>
            <Typography>Choose Passport Copy</Typography>
            <Box style={{ marginTop: "5px" }} className="input-File1">
              <input
                require="true"
                style={{
                  backgroundColor: "#2564B8",
                  color: "#fff",
                  display: "flex",
                  alignItems: "center",
                  padding: "5px 10px",
                  boxSizing: "border-box",
                }}
                name="attachment"
                onChange={(e) => setAttachment(e.target.files[0])}
                className="customFileType"
                type="file"
                title="Choose a Image png and jpg please"
                accept="image/*"
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ChequeTab;
// <CircularProgress sx={{ height: "30px", width: "30px" }} />
