import { Box, Container } from "@mui/material";
import React from "react";

function AdminLedgerPurchase() {
  return (
    <Box>
      <Box className="balance-transaction" marginTop={"20px"}>
        <table>
          <thead>
            <tr>
              <th>Sl No</th>
              <th>Ledger Type</th>
              <th>Transaction ID</th>
              <th>Transaction Dtae</th>
              <th>Transaction Dteails</th>
              <th>Transaction Amount</th>
              <th>Last Balance</th>
            </tr>
          </thead>

          <tbody>

            
          <tr>
            <td data-column="Sl No">01</td>
            <td data-column="Ledger Type">Deposite</td>
            <td data-column="Transaction ID">51151515355</td>
            <td data-column="Transaction Date">27 Nov 2022 11:53 AM</td>
            <td data-column="Transaction Dteails">
              oneway Air Ticket DAC - DXB - Oman Air By Agent
            </td>
            <td data-column="Transaction Amount">12556</td>
            <td data-column="Last Balance">44544</td>
          </tr>

          </tbody>

        </table>
      </Box>
    </Box>
  );
}

export default AdminLedgerPurchase;
