import { Box, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography, Grid } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { json, Link, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserHeader from "../UserPage/UserHeader/UserHeader";
const isMobile = window.innerWidth <= 768;

const AirTicketBooking = () => {
  const users = secureLocalStorage.getItem("user-info");
 
  const [bookingDetails, setBookingDetails] = useState([]);
 

  const navigate = useNavigate();

  useEffect(() => {
    const url = `https://api.rtc.flyfarint.com/v1.0/b2b/air/queues/index.php?all&agentId=${users?.agent_id}`;

    fetch(url)
      .then((res) => res.json())
      .then((data) => {
        setBookingDetails(data?.bookings);
      });
  }, []);

  const styles = {
    root: {
      height: "calc(100vh - 200px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      
    },
    dataGrid: {
      flexGrow: 1,
    },
    "@media (max-width: 600px)": {
      root: {
        height: "calc(100vh - 100px)",
        width: "50%",
      },
    },
    "@media (min-width: 600px) and (max-width: 900px)": {
      root: {
        height: "calc(100vh - 150px)",
      },
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      root: {
        height: "calc(100vh - 175px)",
      },
    },
    "@media (min-width: 1200px) and (max-width: 1536px)": {
      root: {
        height: "calc(100vh - 190px)",
      },
    },
  };

  const columns = [
    {
      field: "booking_id",
      headerName: "reference",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return (
          <div
            onClick={() =>
              navigate("/dashboard/queuesdetails", {
                state: {
                  bookingId: params.row.booking_id,
                  agentId: params.row.agent_id,
                },
              })
            }
            style={{
              backgroundColor: "var(--primary-color)",
              width: "100%",
              cursor: "pointer",
              height: "35px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px",
            }}
          >
            <p style={{ color: "white",fontSize:"10px" }}>{params.row.booking_id}</p>
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "status",
      suppressSizeToFit: true,
      width: 120,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.status}</p>;
      },
    },
    {
      field: "trip_type",
      headerName: "type",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.trip_type}</p>;
      },
    },
    {
      field: "",
      headerName: "route",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{`${params.row.dept_from} - ${params.row.arrive_to}`}</p>;
      },
    },
    {
      field: "pax",
      headerName: "pax",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.pax}</p>;
      },
    },
    {
      field: "travel_date",
      headerName: "flight date",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.travel_date}</p>;
      },
    },
    {
      field: "booked_at",
      headerName: "booked at",
      suppressSizeToFit: true,
      width: 170,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.booked_at}</p>;
      },
    },
    {
      field: "subagent_cost",
      headerName: "net cost",
      suppressSizeToFit: true,
      width: 120,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.subagent_cost} BDT</p>;
      },
    },
    {
      field: "gross_cost",
      headerName: "gross cost",
      suppressSizeToFit: true,
      width: 130,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}}>{params.row.gross_cost} BDT</p>;
      },
    },
    {
      field: "",
      headerName: "profit",
      suppressSizeToFit: true,
      width: 100,
      renderCell: (params) => {
        return <p style={{fontSize:"10px"}} >{params.row.gross_cost - params.row.subagent_cost}</p>;
      },
    },
  ];

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "var(--white)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            p: "20px",
            m: "0 auto",
            width: "90%",
            mt: "-10vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "8px",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "18px !important",
                color: "var(--black)",
                fontFamily: "poppins",
              }}
            >
              Air Ticket Booking
            </Typography>
          </Box>

          <Box
            style={styles.root}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize:"10px"
                
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid var(--input-bgcolor)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color)",
                borderBottom: "none",
                color: "var(--white)",
                fontSize: "10px",
                textTransform: "uppercase",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "var(--body-color)",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
              },
              "& .MuiDataGrid-toolbarContainer": {
                marginLeft: "auto",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "var(--primary-color)",
                background: "var(--secondary-color)",
                fontSize: "10px",
                mb: 2,
              },
              ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
                color: "var(--white)",
              },
              ".MuiTablePagination-selectLabel": {
                color: "var(--white)",
              },
              ".MuiSelect-select": {
                color: "var(--white)",
              },
              ".css-levciy-MuiTablePagination-displayedRows": {
                color: "var(--white)",
              },
              ".MuiDataGrid-cellContent": {
                color: "var(--text-color)",
              },
            }}
          >
            {isMobile ? (
              <>
                <Grid sx={{ mt: "20px" }} container gap={2}>
                  {bookingDetails?.map((sales) => (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={sales?.id}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "flex",
                          md: "flex",
                        },
                        transition: "all .5s ease-in-out",
                        background: "var(--gray)",
                        borderRadius: "5px",
                        width: "100%",
                        borderRadius: "10px",
                        px: "20px",
                        py: "25px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            Reference
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            <div
                              onClick={() =>
                                navigate("/dashboard/queuesdetails", {
                                  state: {
                                    bookingId: sales?.booking_id,
                                    agentId: sales?.agent_id,
                                  },
                                })
                              }
                              style={{
                                backgroundColor: "var(--primary-color)",
                                width: "100%",
                                cursor: "pointer",
                                height: "35px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "5px",
                                padding: "10px",
                              }}
                            >
                              <p style={{ color: "white" }}>
                                {sales?.booking_id}
                              </p>
                            </div>
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "30px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Status
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.status}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Type
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.trip_type}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Profit
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.gross_cost - sales?.subagent_cost}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Pax
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.pax}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Flight Date
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.travel_date}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Booked At
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.booked_at}
                          </Typography>
                        </Box>

                        <Grid
                          item
                          direction={{ xs: "row", sm: "row", md: "row" }}
                          spacing={1}
                          alignItems="flex-start"
                          sx={{
                            width: "100%",
                            height: "100%",
                            // padding: {
                            //   md: "8px",
                            //   xs: "0px",
                            // },
                            mt: "30px",
                            pb: "14px",
                            borderTop: "1px solid var(--primary-color)",
                            // borderTop: {
                            //   xs: "none",
                            //   sm: "1px solid var(--primary-color)",
                            // },
                            pl: { xs: 2, sm: 2 },
                          }}
                        ></Grid>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Net Cost
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales?.subagent_cost}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Gross Cost
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales?.gross_cost}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <DataGrid
                  rows={bookingDetails}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AirTicketBooking;
