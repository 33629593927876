import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";

const AdminAuthentication = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const loginAdmin = (loginData, location, navigate, staff) => {
    setIsLoading(false);

    secureLocalStorage.setItem("state", loginData);
    let body = JSON.stringify({
      email: loginData.email,
      password: loginData.password,
    });

    let url = `https://farhantt.api.flyfarint.com/v.1.0.0/Admin/Auth/login.php`;

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
      
        setIsLoading(true);
        if (data.message === "success") {
          secureLocalStorage.setItem("admin-info", data);
          secureLocalStorage.setItem("commissionData", {
            agentFarePrice: true,
            commissionFarePrice: true,
            defaultCommissionRate: 0,
            defaultCommissionRateAmount: 0,
            customizeRadio: "percent",
            openCustomize: false,
            openDefault: true,
            customerFare: true,
          });
          const destination = location?.state?.from || "/admin/dashboard";
          navigate(destination);
        } else {
          secureLocalStorage.removeItem("admin-info");
          secureLocalStorage.removeItem("commissionData");
        
          setError(data.message);
        }
      })
      .finally(() => setIsLoading(true));
  };

  const loginAdminStaff = (loginData, location, navigate) => {
    setIsLoading(false);
    secureLocalStorage.setItem("state", loginData);
    let body = JSON.stringify({
      email: loginData.email,
      password: loginData.password,
    });
    let url = `https://farhantt.api.flyfarint.com/v.1.0.0/Admin/Staff/index.php?login`;
   

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/json",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        setIsLoading(true);
        if (data.message === "success") {
          secureLocalStorage.setItem("admin-info", data);
          secureLocalStorage.setItem("commissionData", {
            agentFarePrice: true,
            commissionFarePrice: true,
            defaultCommissionRate: 0,
            defaultCommissionRateAmount: 0,
            customizeRadio: "percent",
            openCustomize: false,
            openDefault: true,
            customerFare: true,
          });
          const destination = location?.state?.from || "/admin/dashboard";
          navigate(destination);
        } else {
          secureLocalStorage.removeItem("admin-info");
          secureLocalStorage.removeItem("commissionData");
    
          setError(data.message);
        }
      })
      .finally(() => setIsLoading(true));
  };

  const logout = () => {
    secureLocalStorage.removeItem("admin-info");
    secureLocalStorage.removeItem("commissionData");
    secureLocalStorage.removeItem("state");
    navigate("/");
  };

  return {
    loginAdmin,
    logout,
    isLoading,
    error,
    loginAdminStaff,
  };
};

export default AdminAuthentication;
