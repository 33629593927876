import { Box, Button, Container, Typography } from "@mui/material";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const ForgetPassword = () => {
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);

    const email = e.target.email.value;

    try {
      const url =
        "https://api.rtc.flyfarint.com/v1.0/b2b/auth/password/otp.php";

      if (email) {
        const requestData = {
          email: email,
        };

        await axios
          .post(url, requestData, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((res) => {
            if (res?.data?.status === "success") {
              // Handle successful response, e.g., show a message to the user
              Swal.fire({
                title: "success",
                text: `${res.data.message}`,
                icon: "success",
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "OK",
              });
              navigate("/otpVerification", {
                state: {
                  data: {
                    email,
                    action: "forgetPass",
                  },
                },
              });
            } else {
              // Handle unsuccessful response, e.g., show an error message
              Swal.fire({
                title: "Error",
                text: `${res.data.message}`,
                icon: "error",
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              });
            }
          });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ bgcolor: "var( --primary-color)" }}>
      <Container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <Box
          sx={{
            background: "#ffff",
            width: "450px",
            px: "20px",
            borderRadius: "5px",
            py: "20px",
          }}
        >
          <Box sx={{ textAlign: "center", my: "10px" }}>
            <Typography
              style={{
                color: "var(--primary-color)",
                fontSize: "20px",
                fontWeight: "500",
                height: "22px",
              }}
            >
              RTC{" "}
              <span
                style={{
                  color: "var(--secondary-color)",
                  fontSize: "14px",
                }}
              >
                Tours
              </span>
            </Typography>
            <Typography
              style={{
                color: "var(--primary-color)",
                fontSize: "20px",
                fontWeight: "500",
              }}
            >
              & Travels
            </Typography>
          </Box>
          <Typography
            sx={{
              fontSize: "20px",
              fontWeight: "500",
              color: "var(--profile-boday-color)",
              textAlign: "center",
            }}
          >
            Forgot Password
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              py: "5px",
              color: "var(--profile-boday-color)",
              textAlign: "center",
            }}
          >
            Enter Your Registered email to reset your password
          </Typography>
          <form onSubmit={handleForgetPassword}>
            <Box
              sx={{
                borderBottom: "1px solid #B6B6CC",
              }}
            >
              <Box sx={{ py: "20px" }}>
                <label
                  style={{
                    color: "var( --profile-boday-color)",
                    fontSize: "16px",
                  }}
                >
                  Email
                </label>
              </Box>

              <input
                placeholder="Your Email"
                type="email"
                style={{
                  border: "none",
                  color: "#B6B6CC",
                  outline: "none",
                  width: "100%",
                }}
                name="email"
                value={email}
                onChange={handleEmailChange}
              />
            </Box>

            <Button
              sx={{
                borderRadius: "6px",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
                width: "100%",
                height: "45px",
                textTransform: "capitalize",
                fontSize: "15px",
                mt: "20px",
                ":hover": {
                  backgroundColor: "var(--primary-color)",
                },
              }}
              type="submit"
            >
              {loading ? "Please Wait..... " : "Send OTP"}
            </Button>
          </form>
        </Box>
      </Container>
    </Box>
  );
};

export default ForgetPassword;
