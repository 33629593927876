import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import FlightIcon from "@mui/icons-material/Flight";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import PersonIcon from "@mui/icons-material/Person";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import HeadphonesIcon from "@mui/icons-material/Headphones";
import GridViewIcon from "@mui/icons-material/GridView";

const OurService = () => {
  return (
    <Box mt={8}>
      <Container maxWidth="xl">
        <Box
          style={{
            background: "#F1F2F6",
            padding: "45px 10px",
            borderRadius: "5px",
          }}
        >
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <HorizontalRuleIcon style={{ color: "var(--primary-color)" }} />
            <Typography
              style={{
                color: "var(--mateBlack)",
                textAlign: "center",
                fontSize: "16px",
                fontWeight: "500",
              }}
              mb={1}
            >
              Our Services
            </Typography>
            <HorizontalRuleIcon style={{ color: "var(--primary-color)" }} />
          </Box>
          <Typography
            style={{
              color: "var(--mateBlack)",
              textAlign: "center",
              fontSize: "22px",
              fontFamily: "500",
            }}
            mb={4}
          >
            What we are{" "}
            <span style={{ color: "var(--primary-color)" }}>Provide</span>
          </Typography>
          <Box mt={2}>
            <Grid container spacing={3} display="flex" justifyContent="center">
              <Grid item xs={12} sm={2} md={2}>
                <Box
                  style={{
                    background: "#FFFFFF",
                    padding: "15px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    style={{
                      background: "var(--secondary-color)",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <FlightIcon style={{ color: "var(--white)" }} />
                  </Box>

                  <Box style={{ textAlign: "right" }}>
                    <Typography
                      style={{
                        color: "var(--mateBlack)",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      mt={2}
                    >
                      250+
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      Airlines Ticket
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Box
                  style={{
                    background: "#FFFFFF",
                    padding: "15px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    style={{
                      background: "var(--secondary-color)",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <PersonIcon style={{ color: "var(--white)" }} />
                  </Box>

                  <Box style={{ textAlign: "right" }}>
                    <Typography
                      style={{
                        color: "var(--mateBlack)",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      mt={2}
                    >
                      Unlimited
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      Agent Register
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Box
                  style={{
                    background: "#FFFFFF",
                    padding: "15px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    style={{
                      background: "var(--secondary-color)",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CreditCardIcon style={{ color: "var(--white)" }} />
                  </Box>

                  <Box style={{ textAlign: "right" }}>
                    <Typography
                      style={{
                        color: "var(--mateBlack)",
                        fontSize: "16px",
                        fontWeight: "500",
                      }}
                      mt={2}
                    >
                      All Type
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      Payment option
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Box
                  style={{
                    background: "#FFFFFF",
                    padding: "15px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    style={{
                      background: "var(--secondary-color)",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <GridViewIcon style={{ color: "var(--white)" }} />
                  </Box>

                  <Box style={{ textAlign: "right" }}>
                    <Typography
                      style={{
                        color: "var(--mateBlack)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      mt={2}
                    >
                      Dynamic
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      User Interface
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} sm={2} md={2}>
                <Box
                  style={{
                    background: "#FFFFFF",
                    padding: "15px 10px",
                    borderRadius: "5px",
                  }}
                >
                  <Box
                    style={{
                      background: "var(--secondary-color)",
                      width: "40px",
                      height: "40px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <HeadphonesIcon style={{ color: "var(--white)" }} />
                  </Box>

                  <Box style={{ textAlign: "right" }}>
                    <Typography
                      style={{
                        color: "var(--mateBlack)",
                        fontSize: "15px",
                        fontWeight: "500",
                      }}
                      mt={2}
                    >
                      24 Hr
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                      }}
                    >
                      Client Support
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default OurService;
