import React, { useState } from "react";
import { Box, Container, Typography } from "@mui/material";
import Header from "../../../components/Header/Header";
import Footer from "../../../components/Footer/Footer";
import { useEffect } from "react";
import ReactHtmlParser from "react-html-parser";

const RefundPolicy = () => {
  const [refund, setRefund] = useState('');

  useEffect(() => {
    fetch(`https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&refundPolicy`).then((res) => res.json()).then((data) => setRefund(data?.content))
  }, [])


  return (
    <Box>
    <Header />
    <Container sx={{ my: 5 }}>
      <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", mb: "30px" }}>
        <Typography fontSize="40px" textAlign="center">
         Refund Policy
        </Typography>
        <Box sx={{ width: "350px", height: "3px", backgroundColor: "var(--primary-color)" }}>
        </Box>
      </Box>

      <Typography
        sx={{
          marginTop: "10px",
          color: "var(--black) !important",
          textAlign: "justify",
        }}
      >
        {ReactHtmlParser(refund)}
      </Typography>
    </Container>
    <Footer />
  </Box>
  );
};

export default RefundPolicy;
