import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home/Home";
import AuthProvider from "./components/Contexts/AuthProvider";
import BookingRoute from "./components/Admin/BookingManagement/BookingRoute";
import Traveller from "./pages/Traveller/Traveller";
import AddTraveller from "./components/Traveller/AddTraveller";
import Deposite from "./pages/Deposite/Deposite";
import AddDeposite from "./components/Deposit/AddDeposite";
import BookingDetails from "./components/Admin/BookingDetails/BookingDetails";
import SearchResult from "./pages/SearchReslut/SearchResult";
import FlightInformation from "./pages/FligthInformation/FlightInformation";
import Header from "./components/Header/Header";
import RoundSearchResult from "./pages/SearchReslut/RoundSearchResult";
import RoundFlightUserInfo from "./components/FlightUserinfo/RoundFlightUserInfo";
import BankAccount from "./pages/BankAccount/BankAccount";
import AddBank from "./components/BankAccount/AddBank";
import FlightDetails from "./components/Admin/FlightDetails/FlightDetails";
import GeneralLedgerRoute from "./pages/General Ledger/GeneralLedgerRoute";
import GeneralLedgerReport from "./components/GeneralLedger/GeneralLedgerReport";
import SearchCountParent from "./components/SearchCountComponent/SearchCountParent/SearchCountParent";
// import MyStaff from "./components/Admin/MyAccount/MyStaff";
import Admin from "./pages/Admin/Admin";
import SignUp from "./components/SignUp/SignUp";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import MultiCitySearchResult from "./components/MultiCitySearchResult/MultiCitySearchResult";
import Dashboard from "./components/Dashboard/Dashboard";
import Congratulation from "./components/Congratulation/Congratulation";
import Queues from "./components/Queues/Queues/Queues";
import GroupFareAllPackages from "./components/GroupFareAllPackages/GroupFareAllPackages";
import CountryDetails from "./components/Visa/CountryDetails/CountryDetails";
import TourPackages from "./components/TourPackages/TourPackages";
import NotFound from "./components/NotFound/NotFound";
import AdminLogin from "./components/AdminLogin/AdminLogin";
import SignIn from "./components/SignIn/SignIn";
import { Box } from "@mui/material";
import "./App.css";
import AdminDashboardSideBar from "./components/AdminDashboardSideBar/AdminDashboardSideBar";
import UserDashboardSideBar from "./components/UserDashboardSideBar/UserDashboardSideBar";
import ControlPanel from "./components/ControlPanel/ControlPanel";
import AgentManagement from "./pages/AgentManagement/AgentManagement";
import PaymentManagement from "./pages/PaymentManagement/PaymentManagement";
import Control from "./components/Manage/ControlPage/Control";
import OtherParent from "./components/Manage/Other/OtherParent/OtherParent";
import AddOther from "./components/Manage/Other/Component/AddOther";
import Visa from "./components/Manage/Visa/Visa";
import NewAddVisa from "./components/Manage/Visa/VisaAdd/NewAddVisa";
import AllGroupFares from "./components/Manage/GroupFare/GroupFareComponent/AllGroupFares";
import NonStop from "./components/Manage/GroupFare/GroupFareComponent/NonStop";
import OneStop from "./components/Manage/GroupFare/GroupFareComponent/OneStop";
import SearchHistory from "./components/UserPage/SearchHistory/SearchHistory";
import Markup from "./components/UserPage/Markup/Markup/FlightMarkUp/Markup";
import Account from "./components/UserPage/Account/Account";
import AddStaff from "./components/UserPage/Account/MyAccount/AddStaff";
import AdminAccount from "./components/Admin/AdminAccount/AdminAccount";
import AdminBankAccount from "./components/Admin/AdminBankAccount/AdminBankAccount";
import AdminAddBank from "./components/Admin/AdminBankAccount/AdminAddBank";
import AdminAddStaff from "./components/Admin/AdminAccount/AdminAddStaff";
import DashboardTrack from "./components/Admin/DashboardTrack/DashboardTrack";
import AddGroupFare from "./components/Manage/GroupFare/GroupFareComponent/AddGroupFare";
import AdminStaffLogin from "./components/AdminLogin/AdminStaffLogin";
import SalesReport from "./pages/General Ledger/SalesReport";

import AdminGeneralLedger from "./components/Admin/Report/AdminGeneralLedger";
import AdminSalesReport from "./components/Admin/Report/AdminSalesReport";
import Terms from "./pages/Home/Terms&Condition/Terms";
import Privacy from "./pages/Home/PrivacyPolicy/Privacy";

import RefundPolicy from "./pages/Home/RefundPolicy/RefundPolicy";
import VisaEdit from "./components/Manage/Visa/VisaEdit/VisaEdit";
import Contact from "./pages/Home/Contact/Contact";
import About from "./pages/Home/About/About";
import VendorReport from "./components/Admin/Report/VendorReport";
import SegmentReport from "./components/Admin/Report/SegmentReport";
import Staff from "./components/Manage/Staff/Staff";
import OtherServices from "./components/Others/OtherServices";
import HotelSearchBox from "./components/HotelSearchBox/HotelSearchBox";
import Transactions from "./components/Transactions/Transactions";
import Accounts from "./components/Accounts/Accounts";
import AddAccountBar from "./components/Accounts/AddAccountBar";
import SearchReport from "./pages/General Ledger/SearchReport";
import RefundReport from "./pages/General Ledger/RefundReport";
import ReissueReport from "./pages/General Ledger/ReissueReport";
import AirTicketBooking from "./components/Bookings/AirTicketBooking";
import GroupfareBooking from "./components/Bookings/GroupfareBooking";
import OthersBooking from "./components/Bookings/OthersBooking";
import Testing from "./Testing";
import OnewayFlightInfo from "./components/Oneway/OnewayFlightInfo";
import AirBookingDetails from "./components/Bookings/AirBookingDetails";
import RoundwayQueDetails from "./components/RoundwayQueDetails/RoundwayQueDetails";
import HotelRoom from "./components/HotelSearchBox/HotelRoom";
import HotelResult from "./components/HotelSearchBox/HotelResult";
import HotelDetails from "./components/HotelSearchBox/HotelDetails";
import GroupFareDetails from "./components/GroupFareDetails/GroupFareDetails";
import GroupFareBookingDetails from "./components/Bookings/GroupFareBookingDetails";
import MulticityFlightInfo from "./components/MultiCity/MulticityFlightInfo";
import Notice from "./components/Notice/Notice";
import HotelBooking from "./components/HotelSearchBox/HotelBooking";
import OtpVerification from "./components/OtpVerification/OtpVerification";
import ForgetPassword from "./components/ForgetPassword/ForgetPassword";
import UpdatedPassword from "./components/UpdatePassword/UpdatePassword";
import MakeQuotation from "./components/MakeQuotation/MakeQuotation";
import AllHotelBooking from "./components/HotelSearchBox/AllHotelBooking";
import HotelQueueDetails from "./components/HotelSearchBox/HotelQueueDetails";
import VisaSearch from "./components/VisaSearch/VisaSearch";

function App() {
  return (
    <Box>
      <AuthProvider>
        <BrowserRouter>
          {/* <Header /> */}
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signup" element={<SignUp />} />
            {/* <Route path="/admin" element={<AdminLogin />} /> */}
            <Route path="/adminstaff" element={<AdminStaffLogin />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route path="/refund" element={<RefundPolicy />} />
            <Route path="/otpVerification" element={<OtpVerification />} />
            <Route path="/updatedPassword" element={<UpdatedPassword />} />

            <Route path="/dashboard" element={<UserDashboardSideBar />}>
              <Route
                path="/dashboard/oneway_flight_information"
                element={
                  // <PrivateRoutes>
                  <OnewayFlightInfo />
                  // </PrivateRoutes>
                }
              />
            </Route>

            <Route path="/contact" element={<Contact />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="/aboutus" element={<About />} />

            <Route path="/signin" element={<SignIn />} />
            <Route path="/signin/:email/:password" element={<SignIn />} />
            {/* user dashboard */}
            <Route path="/dashboard" element={<UserDashboardSideBar />}>
              <Route index element={<Dashboard />} />
              <Route path="/dashboard/notice" element={<Notice />} />
              {/* <Route path="/dashboard/searchVisa" element={<VisaSearch />} /> */}
              <Route
                path="/dashboard/searchVisa/:countryName/:visaName"
                element={<CountryDetails />}
              />
              <Route
                path="/dashboard/searchresult"
                element={<SearchResult />}
              />
              <Route
                path="/dashboard/multicityaftersearch"
                element={<MultiCitySearchResult />}
              />
              <Route
                path="/dashboard/multicityflightinfo"
                element={<MulticityFlightInfo />}
              />

              <Route
                path="/dashboard/flightinformation"
                element={<FlightInformation />}
              />
              <Route
                path="/dashboard/roundsearchresult"
                element={<RoundSearchResult />}
              />

              <Route
                path="/dashboard/groupfareresult"
                element={<GroupFareAllPackages />}
              />
              <Route
                path="/dashboard/groupfaredetails"
                element={<GroupFareDetails />}
              />
              <Route
                path="/dashboard/hotelsearchbox"
                element={<HotelSearchBox />}
              />
              <Route
                path="/dashboard/roundflightinformation"
                element={<RoundFlightUserInfo />}
              />
              <Route
                path="/dashboard/searchhistory"
                element={<SearchHistory />}
              />
              <Route
                path="/dashboard/otherservices"
                element={<OtherServices />}
              />
              <Route
                path="/dashboard/queuesdetails"
                element={<Congratulation />}
              />

              <Route path="/dashboard/traveller" element={<Traveller />} />
              <Route
                path="/dashboard/addtraveller"
                element={<AddTraveller />}
              />
              {/* <Route path="/dashboard/account" element={<Account />} /> */}
              <Route path="/dashboard/account" element={<Account />} />

              {/* <Route
                path="/dashboard/transactions"
                element={<Transactions />}
              /> */}
              <Route path="/dashboard/addstaff" element={<AddStaff />} />
              <Route path="/dashboard/deposit" element={<Deposite />} />
              <Route path="/dashboard/adddeposit" element={<AddDeposite />} />
              <Route path="/dashboard/bankaccount" element={<BankAccount />} />
              <Route path="/dashboard/staff" element={<Staff />} />
              <Route path="/dashboard/addaccount" element={<AddAccountBar />} />
              <Route path="/dashboard/hotelroom" element={<HotelRoom />} />
              <Route path="/dashboard/hotelresults" element={<HotelResult />} />
              <Route
                path="/dashboard/hotelDetails"
                element={<HotelDetails />}
              />
              <Route
                path="/dashboard/hotelbooking"
                element={<HotelBooking />}
              />

              {/* booking section here */}
              <Route
                path="/dashboard/airticketbooking"
                element={<AirTicketBooking />}
              />
              <Route
                path="/dashboard/allhotelbooking"
                element={<AllHotelBooking />}
              />
              <Route
                path="/dashboard/hotelqueuedetails"
                element={<HotelQueueDetails />}
              />
              <Route
                path="/dashboard/bookingdetails"
                element={<AirBookingDetails />}
              />
              <Route
                path="/dashboard/groupfarebooking"
                element={<GroupfareBooking />}
              />
              <Route
                path="/dashboard/groupfarebookingdetails"
                element={<GroupFareBookingDetails />}
              />
              {/* <Route
                path="/dashboard/hotelbooking"
                element={<HotelBooking />}
              /> */}
              <Route
                path="/dashboard/othersbooking"
                element={<OthersBooking />}
              />
              {/* <Route path="/dashboard/addbankaccount" element={<AddBank />} /> */}
              <Route
                path="/dashboard/generalledger"
                element={<GeneralLedgerRoute />}
              />
              <Route path="/dashboard/salesReport" element={<SalesReport />} />
              <Route
                path="/dashboard/searchReport"
                element={<SearchReport />}
              />
              <Route
                path="/dashboard/refundreport"
                element={<RefundReport />}
              />
              <Route
                path="/dashboard/reissuereport"
                element={<ReissueReport />}
              />
              <Route
                path="/dashboard/vendorreport"
                element={<VendorReport />}
              />
              <Route
                path="/dashboard/make-quotation"
                element={<MakeQuotation />}
              ></Route>

              <Route path="/dashboard/testing" element={<Testing />} />
            </Route>
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </Box>
  );
}

export default App;
