import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { styled } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { AiOutlineSwap } from "react-icons/ai";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import FlightLandIcon from "@mui/icons-material/FlightLand";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SearchIcon from "@mui/icons-material/Search";
import GroupsIcon from "@mui/icons-material/Groups";
import ServerDown from "../../images/undraw/undraw_server_down_s-4-lk.svg";
import flightData from "../flightData";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import CountryList from "../../CountryList";
import axios from "axios";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: "50%",
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto #003566",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "var(--primary-color)",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&:before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "var(--secondary-color)",
  },
});

// Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      sx={{
        "&:hover": {
          bgcolor: "transparent",
        },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}
const VisaSearch = ({ fvisa, setfvisa, typeVisa, setTypeVisa }) => {
  const data = CountryList; // json data from flight Data

  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const [openFrom, setOpenFrom] = useState(false);
  const [openTo, setOpenTo] = useState(false);

  //For Country api
  const [fromSuggest, setFromSuggest] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [toSuggest, setToSuggest] = useState([]);
  const [toFilteredData, setToFilteredData] = useState([]);

  useEffect(() => {
    const fetchAllData = async () => {
      const resFromSuggest = await axios(
        `https://api.rtc.flyfarint.com/v1.0/b2b/visa/index.php?allcountry`
      );
      setFromSuggest(resFromSuggest.data);
      setFilteredData(resFromSuggest.data);

      resFromSuggest.data.map(async (item, index) => {
        const restoSuggest = await axios(
          `https://api.rtc.flyfarint.com/v1.0/b2b/visa/index.php?country=${item.country.trim()}`
        );

        setToSuggest(restoSuggest.data);
        setToFilteredData(restoSuggest.data);
      });
    };

    fetchAllData();
  }, []);

  //formOnChange Filter
  const formOnChange = (e) => {
    const searchvalue = e.target.value;
    let suggestion = [];
    suggestion = fromSuggest.filter((item) =>
      item.country.toLowerCase().includes(searchvalue.toLowerCase())
    );
    if (suggestion.length !== 0) {
      setFilteredData(suggestion);
    } else if (searchvalue.length > 0 && suggestion.length === 0) {
      setFilteredData(suggestion);
    } else {
      setFilteredData(fromSuggest);
    }
  };

  const fromSuggestedText = async (name) => {
    const restoSuggest = await axios(
      `https://api.rtc.flyfarint.com/v1.0/b2b/visa/index.php?country=${name.trim()}`
    );
    setToSuggest(restoSuggest.data);
    setToFilteredData(restoSuggest.data);
    setfvisa(name);
    setOpenFrom(false);
    setOpenTo(true);
  };
  //ToOnChange filter
  const toOnChange = (e) => {
    const searchvalue = e.target.value;
    let suggestion = [];
    suggestion = toSuggest.filter((item) =>
      item.visatype.toLowerCase().includes(searchvalue.toLowerCase())
    );
    if (suggestion.length !== 0) {
      setToFilteredData(suggestion);
    } else if (searchvalue.length > 0 && suggestion.length === 0) {
      setToFilteredData(suggestion);
    } else {
      setToFilteredData(toSuggest);
    }
  };

  const toSuggestedText = (name) => {
    setTypeVisa(name);
    setOpenTo(false);
  };
  const [click, setClick] = useState(false);
  //FromgetSuggetion
  const fromGetSuggetion = () => {
    return (
      <Box
        style={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "0px" },
          }}
        >
          {filteredData.length !== 0 ? (
            filteredData.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "10px",
                    paddingRight: "0px",
                    background: "var(--white)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      background: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      fromSuggestedText(` ${item.country}`);
                    }} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          display: "block",
                          color: "var(--black)",
                          textAlign: "left",
                        }}
                      >
                        {item.country}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const toGetSuggetion = () => {
    return (
      <Box
        sx={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            maxHeight: "230px",
            overflowY: "auto",
            background: "#fff",
            "&::-webkit-scrollbar": { width: "0px" },
          }}
        >
          {toFilteredData.length !== 0 ? (
            toFilteredData.map((item, index) => {
              return (
                <Box
                  key={index}
                  sx={{
                    paddingLeft: "10px",
                    paddingRight: "0px",
                    background: "var(--white)",
                    color: "var(--white)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      background: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => toSuggestedText(` ${item.visaCategory}`)} //suggest to display name select with multiple data pass parameter
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          color: "var(--black)",
                          display: "block",
                          textAlign: "left",
                        }}
                      >
                        {item.visaCategory}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // SearchingField End
  const handleClickAway = () => {
    setOpenFrom(false);
    setOpenTo(false);
  };
  // Search Flight button click
  let fromaddress = fvisa;
  let toaddress = typeVisa;

  async function handleSearch(e) {
    e.preventDefault();
    secureLocalStorage.setItem("search-data", {
      fromaddress,
      toaddress,
    });
    if (data) {
      navigate(
        `/dashboard/searchVisa/${fromaddress.trim()}/${toaddress.trim()}`,
        {
          state: { fromaddress, toaddress },
        }
      );
    } else {
      Swal.fire({
        // imageUrl: ServerDown,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: "Custom image",
        title: "Please Try Again later",
        confirmButtonColor: "#ffa84d",
        confirmButtonText: "Search Again...",
      }).then(function () {
        navigate("/dashboard");
      });
    }
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Box sx={{ position: "relative", mt: "1vh" }}>
          <form onSubmit={handleSearch}>
            <Grid
              sx={{
                height: "fit-content",
                width: "100%",
              }}
              container
              rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 5 }}
              columnSpacing={0}
            >
              {/* //todo: Country */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  position: "relative",
                  pl: location?.pathname ? "10px" : "0px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenFrom((prev) => !prev);
                    setOpenTo(false);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={0.5}
                      >
                        Country
                      </Typography>
                      {/* <span>{faddress?.split(",")[0]}</span> */}
                      {/* {faddress?.split(",")[0] === toAddress?.split(",")[0] && (
                        <Stack
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          <Alert
                            icon={<ErrorOutlineIcon fontSize="inherit" />}
                            severity="error"
                            sx={{ fontSize: "11px" }}
                          >
                            Can't choose same place!
                          </Alert>
                        </Stack>
                      )} */}
                    </Box>

                    <Box sx={{ width: "90%" }}>
                      <span
                        style={{
                          width: "100%",
                          color: "var(--light-gray)",
                          fontSize: "13px",
                        }}
                      >
                        {fvisa}
                      </span>
                    </Box>
                  </Box>
                </Box>

                {openFrom && (
                  <Box
                    style={{
                      position: "absolute",
                      top: "105%",
                      left: "0",
                      right: "0",
                      width: "100%",
                      backgroundColor: "var(--white)",
                      height: "fit-content",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "5px",
                      zIndex: "999",
                      padding: "5px 5px 0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#003566",
                        zIndex: 10,
                      }}
                      backgroundColor="#fff"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={formOnChange}
                        placeholder="Search a airport..."
                        className="customPlaceholder"
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{fromGetSuggetion()}</Box>
                  </Box>
                )}
              </Grid>

              {/* //todo: Country */}
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={6}
                sx={{
                  position: "relative",
                  pl: "10px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenFrom(false);
                    setOpenTo((prev) => !prev);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={0.5}
                      >
                        Visa Type
                      </Typography>
                      {/* <span>{faddress?.split(",")[0]}</span> */}
                      {/* {faddress?.split(",")[0] === toAddress?.split(",")[0] && (
                        <Stack
                          style={{
                            position: "absolute",
                            top: "100%",
                            left: "0",
                            width: "100%",
                          }}
                        >
                          <Alert
                            icon={<ErrorOutlineIcon fontSize="inherit" />}
                            severity="error"
                            sx={{ fontSize: "11px" }}
                          >
                            Can't choose same place!
                          </Alert>
                        </Stack>
                      )} */}
                    </Box>

                    <Box sx={{ width: "90%" }}>
                      <span
                        style={{
                          width: "100%",
                          color: "var(--light-gray)",
                          fontSize: "13px",
                        }}
                      >
                        {typeVisa}
                      </span>
                    </Box>
                  </Box>
                </Box>

                {openTo && (
                  <Box
                    style={{
                      position: "absolute",
                      top: "105%",
                      left: "0",
                      right: "0",
                      width: "100%",
                      backgroundColor: "var(--white)",
                      height: "fit-content",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "5px",
                      zIndex: "999",
                      padding: "5px 5px 0px",
                    }}
                  >
                    <Box
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#003566",
                        zIndex: 10,
                      }}
                      backgroundColor="#fff"
                    >
                      <input
                        autoComplete="off"
                        autoFocus
                        onChange={formOnChange}
                        placeholder="Search a airport..."
                        className="customPlaceholder"
                        style={{
                          color: "var(--secondary-color)",
                          fontWeight: 500,
                          paddingLeft: "20px",
                          width: "100%",
                          height: "40px",
                          backgroundColor: "transparent",
                          border: "none",
                          outline: "none",
                        }}
                      />
                    </Box>
                    <Box>{toGetSuggetion()}</Box>
                  </Box>
                )}
              </Grid>

              {/* //todo: Search Button */}
              <Grid
                lg={2}
                md={2}
                sm={12}
                xs={12}
                justifyContent="center"
                alignItems={"center"}
              >
                <Box>
                  <Button
                    type="submit"
                    disabled={
                      fvisa === "Select Country" ||
                      typeVisa === "Select Your Visa Type"
                    }
                    className="shine-effect"
                    sx={{
                      fontSize: "14px",

                      height: {
                        lg: "100%",
                        md: "100%",
                        sm: "100%",
                        xs: "100%",
                      },
                      width: {
                        lg: "70%",
                        md: "90%",
                        sm: "100%",
                        xs: "100%",
                      },

                      left: {
                        lg: location?.pathname === "/" ? "1020px" : "1125px",
                        md: "500px",
                        xs: "0",
                      },
                      bottom: {
                        md: "90px",
                        xs: "3px",
                      },

                      mt: { lg: "0px", md: "0px", sm: "10px", xs: "15px" },
                      backgroundColor: "var(--secondary-color)",
                      color: "var(--white)",
                      fontWeight: "500",
                      textTransform: "capitalize",
                      gap: "5px",
                      "&:hover": {
                        backgroundColor: "var(--primary-color)",
                        cursor: "pointer",
                      },
                      display: {
                        lg: "flex",
                        md: "flex",
                        sm: "flex",
                        xs: "flex",
                      },
                      m: "20px 0 0 0",
                      borderRadius: "5px",
                    }}
                  >
                    <Box>{click ? "Wait..." : "Search"}</Box>
                    <AirplanemodeActiveIcon
                      sx={{ fontSize: "23px", transform: "rotate(90deg)" }}
                    />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default VisaSearch;
