import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Stack,
} from "@mui/material";
import React from "react";
import { useState } from "react";
//   import RoundSingleFlightDetails from "../Roundway/RoundwayFlightDetails";
//   import FareDetails from "../SingleFlight/FareDetails";
//   import FarePolicy from "../FarePolicy/FarePolicy";
//   import Baggage from "./Baggage";
import RoundSingleFlightDetails from "./RoundSingleFlightDetails";
import FareDetails from "./FareDetails";
import FarePolicy from "../FarePolicy/FarePolicy";
import Baggage from "../Baggage/Baggage";

const RoundFlightDetails = ({ expanded, roundData, segments }) => {
  const [isActive, setIsActive] = useState(1);
  const { pricebreakdown, uId } = roundData;

  return (
    <Accordion
      expanded={expanded === true}
      sx={{
        boxShadow: "none",
        bgcolor: "white",
        ".MuiButton-root:hover": {
          backgroundColor: "#F7D800",
        },
        ".MuiBox-root": {
          height: "max-content",
        },
      }}
    >
      <AccordionSummary
        sx={{ display: "none" }}
        aria-controls="panel1d-content"
        id="panel1d-header"
      ></AccordionSummary>
      <AccordionDetails
        sx={{
          borderTop: "1px solid #F0F6FF",
          //   padding: "20px 0",
          //   mt: "20px",
        }}
      >
        {/*
              Flight Details Navigation button area Start 
         */}

        <Stack direction="row" spacing={2}>
          <Box>
            <Button
              onClick={() => setIsActive(1)}
              sx={{
                padding: "10px 20px",
                bgcolor: `${isActive === 1 ? "var(--secondary-color)" : ""}`,
                color: `${isActive === 1 ? "var(--white)" : "var(--meteBlack)"}`,
                textTransform:"capitalize",
              }}
            >
              Flight Details
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => setIsActive(2)}
              sx={{
                padding: "10px 20px",
                bgcolor: `${isActive === 2 ? "var(--secondary-color)" : ""}`,
                color: `${isActive === 2 ? "var(--white)" : "var(--meteBlack)"}`,
                textTransform:"capitalize",
              }}
            >
              Fare Details
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => setIsActive(3)}
              sx={{
                padding: "10px 20px",
                bgcolor: `${isActive === 3 ? "var(--secondary-color)" : ""}`,
                color: `${isActive === 3 ? "var(--white)" : "var(--meteBlack)"}`,
                textTransform:"capitalize",
              }}
            >
              Fare Policy
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => setIsActive(4)}
              sx={{
                padding: "10px 20px",
                bgcolor: `${isActive === 4 ? "var(--secondary-color)" : ""}`,
                color: `${isActive === 4 ? "var(--white)" : "var(--meteBlack)"}`,
                textTransform:"capitalize",
              }}
            >
              Baggage
            </Button>
          </Box>
        </Stack>
        {/*
              Flight Details Navigation button area End 
         */}

        {/*
              Flight Details Area area Start 
         */}

        <Box>
          <Box
            sx={{
              display: `${isActive === 1 ? "block" : "none"}`,
            }}
          >
            <RoundSingleFlightDetails
              uId={uId}
              roundData={roundData}
              segments={segments}
            />
          </Box>

          <Box
            sx={{
              display: `${isActive === 2 ? "block" : "none"}`,
            }}
          >
            <FareDetails pricebreakdown={pricebreakdown} />
          </Box>
          <Box
            sx={{
              display: `${isActive === 3 ? "block" : "none"}`,
            }}
          >
            <FarePolicy flightData={roundData} />
          </Box>
          <Box
            sx={{
              display: `${isActive === 4 ? "block" : "none"}`,
            }}
          >
            <Baggage flightData={roundData} pricebreakdown={pricebreakdown} />
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default RoundFlightDetails;
