import React, { useEffect, useState } from "react";
import { Box, Button, ClickAwayListener, Container, Typography } from "@mui/material";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import FreeBreakfastOutlinedIcon from "@mui/icons-material/FreeBreakfastOutlined";
import CheckIcon from "@mui/icons-material/Check";
import { FamilyRestroom } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import CountryList from "../CountryList";
import moment from "moment";
import Swal from "sweetalert2";
import { Triangle } from "react-loader-spinner";
import MoneyIcon from '@mui/icons-material/Money';
import visaCard from '../../images/visacard.jpg';
import mastercard from '../../images/mastercard.jpg'

const styles = {
  label: {
    fontSize: '14px',
  },
  input: {
    border: '1px solid #E7E7E7',
    borderRadius: '3px',
    marginBottom: '10px',
    width: '97%',
    display: "flex",
    height: "30px",
    backgroundColor: "#F4F4F4"
  },
  required: {
    color: 'red',
    fontWeight: '500',
  },
};


const HotelBooking = () => {
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const rateKey = location?.state?.rateKey;
  const searchInfo = location?.state?.searchInfo;
  const [bookingDetails, setBookingDetails] = useState({});
  const [bookingKey, setBookingKey] = useState("")
  const [roomCount, setRoomCount] = useState(searchInfo?.rooms);
  const navigate = useNavigate();
  const startDate = location?.state?.hotelData?.priceInfo?.startDate;
  const endDate = location?.state?.hotelData?.priceInfo?.endDate;
  const adultCount = location?.state?.hotelData?.searchInfo?.adult;
  const childCount = location?.state?.hotelData?.searchInfo?.child;
  const infantCount = location?.state?.hotelData?.searchInfo?.infant;
  const hotelName = location?.state?.hotelData?.name;
  const hotelCode = location?.state?.hotelData?.hotelCode;
  const hotelRating = location?.state?.hotelData?.rating;
  const hotelAddress = `${location?.state?.hotelData?.locationInfo?.addressLine1},${location?.state?.hotelData?.locationInfo?.cityName},${location?.state?.hotelData?.locationInfo?.countryName}`
  const room = location?.state?.room;
  const refundable = room?.ratePlan?.refundable === true ? 'Refundable' : 'Non Refundable';
  const priceInfo = location?.state?.hotelData?.priceInfo;
  const hotelData = location?.state?.hotelData;
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.agent_id && user?.agent_id || user?.staff_id;
  const agentEmail = user?.agent_email && user?.agent_email || user?.staff_email;
  const agentPhone = user?.agent_phone_number && user?.agent_phone_number || user?.staff_phone_number;

  console.log(agentId);
  console.log(user);

  // get booking key
  const url =
    "https://api.rtc.flyfarint.com/v1.0/b2c/hotel/priceCheck.php";

  useEffect(() => {
    const fetchBookingDetails = async () => {
      try {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            Accept: "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ key: rateKey }),
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();

        setBookingKey(data?.HotelPriceCheckRS?.PriceCheckInfo?.BookingKey);
      } catch (error) {
        console.error("Error fetching booking details:", error);
      }
    };

    fetchBookingDetails();
  }, [rateKey]);


  // all guests info body
  const [roomInfo, setRoomInfo] = useState({
    guestInfo: [
      ...[...new Array(adultCount)].map((item, index) => {
        return {
          leadGuest: false,
          type: "ADT",
          fName: "",
          lName: "",
          phone: "",
          email: ""
        };
      }),
      ...[...new Array(childCount)].map((item, index) => {
        return {
          leadGuest: false,
          type: "CNN",
          fName: "",
          lName: "",
          phone: "",
          email: ""
        };
      }),
      // ...[...new Array(infantCount)].map((item, index) => {
      //     return {
      //         leadGuest: false,
      //         type: "INF",
      //         fName: "",
      //         lName: "",
      //         phone: "",
      //         email: ""
      //     };
      // }),
    ],
  });

  // payment info body
  const [paymentInfo, setPaymentInfo] = useState({
    paymentType: "",
    cardCode: "",
    cardNumber: "",
    expiryDate: "",
    holderFName: "",
    holderLName: "",
    holderEmail: "",
    holderPhone: "",
    csc: "",
    address: "",
    cityName: "",
    streetNumber: "",
    stateCode: "",
    cityCode: "",
    postalCode: "",
    countryCode: ""
  })

  // handle room info by guest
  const handleRoomInfo = (e, type, index) => {
    let value = e.target.value;
    let field = e.target.name;

    const tempRoomInfo = [...roomInfo.guestInfo];

    if (index !== -1) {
      if (field === 'leadGuest') {
        tempRoomInfo[index] = {
          ...tempRoomInfo[index],
          leadGuest: true,
        };

        tempRoomInfo.forEach((guest, i) => {
          if (i !== index) {
            tempRoomInfo[i] = {
              ...tempRoomInfo[i],
              leadGuest: false,
            };
          }
        });
      } else {
        tempRoomInfo[index] = {
          ...tempRoomInfo[index],
          [field]: value,
        };
      }
    }

    setRoomInfo({
      ...roomInfo,
      guestInfo: tempRoomInfo,
    });
  };

  // handle paymentinfo by input
  const handlePaymentInfo = (e) => {
    const { name, value } = e.target;

    if (name === "countryCode") {
      setPaymentInfo((prevPaymentInfo) => ({
        ...prevPaymentInfo,
        [name]: value,
        stateCode: value,
        cityCode: value,
      }));
    } else {
      setPaymentInfo((prevPaymentInfo) => ({
        ...prevPaymentInfo,
        [name]: value,
      }));
    }
  };

  // main hotel booking body
  const body = {
    guestInfo: roomInfo?.guestInfo,
    paymentInfo: paymentInfo,
    platform: "B2B",
    refundable: refundable,
    netCost: location?.state?.hotelData?.priceInfo?.amountAfterTax,
    hotelName: hotelName,
    hotelCode: hotelCode,
    guestPassengerName: "",
    agentId: agentId,
    staffId: "",
    subAgentId: "",
    userId: agentId,
    adultCount: adultCount,
    childCount: childCount,
    rooms: 1,
    checkIn: startDate,
    checkOut: endDate,
    email: agentEmail,
    phone:agentPhone,
    bookingKey: bookingKey,
    system: "sabre"
  };

  console.log(body);

  // booking operation 
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(body);
    setLoading(true)
    const url = 'https://api.rtc.flyfarint.com/v1.0/b2c/hotel/booking.php';

    // navigate('/hotelqueuedteails', { state: { priceInfo: priceInfo, hotelData: hotelData, guestInfo: body?.guestInfo, paymentInfo: paymentInfo } });

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Success:', data);
        if (data?.status?.toLowerCase() === 'success') {

          Swal.fire({
            icon: 'success',
            title: 'Success',
            text: 'Hotel booking Successful!',
          }).then(() => {
            setLoading(false)
            // navigate('/hotelqueuedteails', { state: { bookingId: data?.bookingId } });
            navigate('/dashboard/allhotelbooking');
          });
        } else {
          throw new Error("Booking Failed")
        }
      })
      .catch(error => {
        console.error('Error:', error);
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Booking Failed',
        }).then(() => {
          setLoading(false)
          navigate('/dashboard');
        });
      });
  };


  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "80vh",
          width: { xs: "80px" },
          marginInline: "auto",
        }}
      >
        <Triangle
          height="100%"
          width="100%"
          color="var(--primary-color)"
          visible={true}
          ariaLabel="three-circles-rotating"
          outerCircleColor="var(--primary-color)"
          innerCircleColor="var(--secondary-color)"
          middleCircleColor="var(--primary-color)"
        />
      </Box>
    );
  }


  return (
    <>
      {/* <Header /> */}
      <Box className="flightSearchParent"
        mt="-8vh"
        borderRadius="3px"
        px="25px"
        sx={{
          margin: "0 auto",
          bgcolor: "white",
          width: "90%",
        }}>
        <Box sx={{ display: "flex", gap: "10px", mt: "10px", }}>
          {/* left box */}
          <Box sx={{ width: "32%", }}>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                pl: "15px",
                py: "10px",
                bgcolor: "white",
              }}>
              <Typography
                sx={{ color: "black", fontWeight: "500", fontSize: "18px" }}>
                {hotelName}
              </Typography>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "400",
                  fontSize: "14px",
                  mt: "14px",
                }}>
                {hotelAddress}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "7px",
                  mt: "10px",
                }}>
                <Typography
                  sx={{
                    bgcolor: "var(--primary-color)",
                    width: "25px",
                    height: "25px",
                    fontSize: "12px",
                    display: "flex",
                    borderRadius: "2px",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "white",
                  }}>
                  {hotelRating}
                </Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontWeight: "400",
                    fontSize: "13px",
                  }}>
                  {hotelRating === '1.0' ? 'Very Poor' : hotelRating === '2.0' ? 'Poor' : hotelRating === '3.0' ? 'Good' : hotelRating === '4.0' ? 'Very Good' : hotelRating === '5.0' ? 'Excellent' : 'N/A'}
                </Typography>

              </Box>
              <Box sx={{ display: "flex", gap: "25px", mt: "15px" }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <NetworkWifiIcon sx={{ color: "#148F77 " }} />
                  <Typography sx={{ color: "#2C2321", fontSize: "12px" }}>
                    Free WiFi
                  </Typography>
                </Box>

                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <HealthAndSafetyIcon sx={{ color: "#148F77 " }} />
                  <Typography sx={{ color: "#2C2321", fontSize: "12px" }}>
                    Room Service
                  </Typography>
                </Box>

                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
                  <RestaurantMenuIcon sx={{ color: "#148F77 " }} />
                  <Typography sx={{ color: "#2C2321", fontSize: "12px" }}>
                    Restaurant
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                pl: "15px",
                py: "10px",
                pr: "15px",
                mt: "15px",
                bgcolor: "white",
              }}>
              <Typography
                sx={{ color: "black", fontWeight: "500", fontSize: "18px" }}>
                Your Booking Details
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                    }}>
                    Check-in
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "var(--primary-color)",
                      fontWeight: "500",
                      mt: "5px",
                    }}>
                    {moment(startDate).format("dddd, D MMMM")}
                  </Typography>
                </Box>
                <Box sx={{ mt: "10px" }}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: "500",
                    }}>
                    Check-out
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "15px",
                      color: "var(--primary-color)",
                      fontWeight: "500",
                      mt: "5px",
                    }}>
                    {moment(endDate).format("dddd, D MMMM")}
                  </Typography>

                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                display: "flex",
                flexDirection: "column",
                pl: "20px",
                mt: "15px",
                pt: "10px",
                pb: "15px",
                bgcolor: "white",
              }}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "18px",
                  marginBottom: "20px",
                }}>
                Price Details:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  mb: "10px",
                }}>
                <MoneyIcon sx={{ color: "#008234" }} />
                <Typography sx={{ fontSize: "13px" }}>
                  Amount Before Tax : {priceInfo?.amountBeforeTax} {priceInfo?.currencyCode}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                <MoneyIcon sx={{ color: "#008234" }} />
                <Typography sx={{ fontSize: "13px" }}>
                  Amount After Tax : {priceInfo?.amountAfterTax} {priceInfo?.currencyCode}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                display: "flex",
                flexDirection: "column",
                pl: "20px",
                mt: "15px",
                pt: "10px",
                pb: "15px",
                bgcolor: "white",
              }}>
              <Typography
                sx={{
                  color: "black",
                  fontWeight: "500",
                  fontSize: "18px",
                  marginBottom: "20px",
                }}>
                {room?.ratePlan?.ratePlanName?.split('-')[0]}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  mb: "10px",
                }}>
                <FreeBreakfastOutlinedIcon sx={{ color: "#008234" }} />
                <Typography sx={{ fontSize: "13px" }}>
                  {room?.ratePlan && room?.ratePlan?.mealPlanDescription || 'N/A'}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  mt: "5px",
                }}>
                <FamilyRestroom sx={{ color: "black" }} />
                <Typography sx={{ fontSize: "13px", color: "black" }}>
                  {" "}
                  <span style={{ fontWeight: "500" }}>Guests :</span> {location?.state?.adultCount} Adult, {location?.state?.childCount} Child
                </Typography>
              </Box>
            </Box>

          </Box>
          {/* right box */}
          <Box sx={{ width: "67%" }}>
            <Box
              sx={{
                width: "100%",
                height: "80px",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                pl: "20px",
                bgcolor: "white",
              }}>
              <Typography sx={{ color: "black", fontWeight: "500" }}>
                You are signed in
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "var(--primary-color)" }}>
                {user?.staff_email || user?.agent_email}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                display: "flex",
                flexDirection: "column",
                pl: "20px",
                mt: "15px",
                pt: "10px",
                pb: "10px",
                bgcolor: "white",
              }}>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}>
                Passenger Details
              </Typography>
              {/* The form starts */}
              {
                roomInfo?.guestInfo?.map((item, i) =>
                  <Box sx={{ mb: "10px", pb: "10px", }} key={i}>
                    <Typography sx={{
                      bgcolor: "var(--primary-color)",
                      textAlign: "center",
                      borderRadius: "3px",
                      color: "var(--white)",
                      width: "15%",
                      marginLeft: "auto",
                      marginRight: "25px",
                      py: "5px"
                    }}>
                      {item?.type === 'ADT' ? 'Adult' : 'Child'}
                    </Typography>
                    <label style={styles.label}>
                      First Name <span style={styles.required}>*</span>
                      <input type="text" name="fName" style={styles.input} onChange={(e) => handleRoomInfo(e, item.type, i)} placeholder="Enter Passenger's First Name" required />
                    </label>
                    <label style={styles.label}>
                      Last Name <span style={styles.required}>*</span>
                      <input type="text" name="lName" style={styles.input} onChange={(e) => handleRoomInfo(e, item.type, i)} placeholder="Enter Passenger's Last Name" required />
                    </label>
                    <label style={styles.label}>
                      Email <span style={styles.required}>*</span>
                      <input type="email" name="email" style={styles.input} onChange={(e) => handleRoomInfo(e, item.type, i)} placeholder="Enter Passenger's Email" required />
                    </label>

                    <label style={styles.label}>
                      Phone <span style={styles.required}>*</span>
                      <input type="tel" name="phone" style={styles.input} onChange={(e) => handleRoomInfo(e, item.type, i)} required placeholder="Enter Passenger's Phone" />
                    </label>
                    <label style={{ display: "flex", fontSize: "14px", alignItems: "center", gap: "7px" }}>
                      Lead Guest <span style={styles.required}></span>
                      <input
                        type="radio"
                        name="leadGuest"
                        style={{ border: "1px solid red", fontSize: "10px", width: "15px" }}
                        onChange={(e) => handleRoomInfo(e, item.type, i)}
                      />
                    </label>
                    <Box sx={{ height: "2px", width: "97%", border: "1px dashed #148F77", mt: "20px" }}></Box>
                  </Box>
                )
              }
            </Box>

            <Box
              sx={{
                width: "100%",
                height: "auto",
                borderRadius: "10px",
                border: "1px solid #E7E7E7",
                display: "flex",
                flexDirection: "column",
                px: "20px",
                mt: "15px",
                pt: "10px",
                pb: "10px",
                bgcolor: "white",
              }}>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontWeight: "500",
                  fontSize: "20px",
                  marginBottom: "20px",
                }}>
                Payment Info
              </Typography>
              {/* The form starts */}
              <Box sx={{ width: "100%", boxShadow: " rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", pl: "15px", pr: "5px", borderRadius: "5px", }}>
                <Box sx={{ width: "100%", height: "60px", display: "flex", justifyContent: "center", mb: "20px" }}>
                  <img style={{ width: "150px", height: "80px" }} src={visaCard} />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Payment Type</Typography>
                    <select
                      name="paymentType"
                      style={styles.input}
                      // style={{ width: "100%", marginBottom: "10px" }}
                      value={paymentInfo.paymentType}
                      onChange={handlePaymentInfo}
                      required
                    >
                      <option value="">Select Payment Type</option>
                      <option value="CC">CC</option>
                      <option value="BCC">BCC</option>
                    </select>
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Card Code</Typography>
                    <select
                      name="cardCode"
                      style={styles.input}
                      // style={{ width: "100%", marginBottom: "10px" }}
                      value={paymentInfo.cardCode}
                      onChange={handlePaymentInfo}
                      required
                    >
                      <option value="">Select Card Code</option>
                      <option value="IK">IK</option>
                      <option value="VI">VI</option>
                      <option value="MC">MC</option>
                      <option value="VS">VS</option>
                      <option value="CA">Mastercard</option>

                    </select>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", mb: "5px" }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Card Number</Typography>
                    <input type="text" name="cardNumber" style={styles.input}
                      value={paymentInfo.cardNumber}
                      onChange={handlePaymentInfo}
                      placeholder="Enter the Card Number"
                      required />
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Expiry Date</Typography>
                    <input type="date" name="expiryDate" style={styles.input}
                      value={paymentInfo.expiryDate}
                      onChange={handlePaymentInfo}
                      required />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Holder's First Name</Typography>
                    <input type="text" name="holderFName" style={styles.input}
                      value={paymentInfo.holderFName}
                      onChange={handlePaymentInfo}
                      placeholder="Enter Holder Name"
                      required />
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Holder's Last Name</Typography>
                    <input type="text" name="holderLName" style={styles.input}
                      value={paymentInfo.holderLName}
                      onChange={handlePaymentInfo}
                      placeholder="Enter Hoder's Last Name"
                      required />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Holder's Email</Typography>
                    <input type="text" name="holderEmail" style={styles.input}
                      value={paymentInfo.holderEmail}
                      onChange={handlePaymentInfo}
                      placeholder="Enter Holder's Email"
                      required />
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Holder's Phone Number</Typography>
                    <input type="text" name="holderPhone" style={styles.input}
                      value={paymentInfo.holderPhone}
                      onChange={handlePaymentInfo}
                      placeholder="Enter Holder's phone number"
                      required />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>CSC</Typography>
                    <input type="text" name="csc" style={styles.input}
                      value={paymentInfo.csc}
                      onChange={handlePaymentInfo}
                      placeholder="Enter CSC number"
                      required />
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Address</Typography>
                    <input type="text" name="address" style={styles.input}
                      value={paymentInfo.address}
                      onChange={handlePaymentInfo}
                      placeholder="Enter the Address"
                      required />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>City Name</Typography>
                    <input type="text" name="cityName" style={styles.input}
                      value={paymentInfo.cityName}
                      onChange={handlePaymentInfo}
                      placeholder="Enter City Name"
                      required />
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Street Number</Typography>
                    <input type="text" name="streetNumber" style={styles.input}
                      value={paymentInfo.streetNumber}
                      onChange={handlePaymentInfo}
                      placeholder="Enter Street number"
                      required />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Country Code</Typography>
                    <select
                      name="countryCode"
                      style={styles.input}
                      value={paymentInfo.countryCode}
                      onChange={handlePaymentInfo}
                      required                                 >
                      {
                        CountryList.map((country) => (
                          <option key={country?.code} value={country?.code}>{`${country.name} (${country.code})`}</option>
                        ))
                      }
                    </select>
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>State Code</Typography>
                    <input type="text" name="stateCode" style={styles.input}
                      // value={paymentInfo.stateCode}
                      value={paymentInfo.countryCode}
                      onChange={handlePaymentInfo}
                      placeholder="State Code"
                      readOnly />
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between", }}>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>City Code</Typography>
                    <input type="text" name="cityCode" style={styles.input}
                      // value={paymentInfo.cityCode}
                      value={paymentInfo.countryCode}
                      onChange={handlePaymentInfo}
                      placeholder="City Code"
                      readOnly />
                  </Box>
                  <Box sx={{ width: "47%" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: "5px" }}>Postal Code</Typography>
                    <input type="text" name="postalCode" style={styles.input}
                      // value={paymentInfo.postalCode}
                      value={paymentInfo.postalCode}
                      onChange={handlePaymentInfo}
                      placeholder="Enter Postal Code"
                      required />
                  </Box>
                </Box>

              </Box>


            </Box>
            <Button
              disabled={loading}
              sx={{
                bgcolor: "var(--mateBlack)",
                color: "var(--white)",
                width: "100%",
                my: "15px",
                "&:hover": {
                  bgcolor: "var(--primary-color)",
                },
              }}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? "Processing..." : "Book Now"}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default HotelBooking;
