import { Box, Button, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import React from "react";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import FlightCards from "../FlightCards/FlightCards";
import { useLocation } from "react-router-dom";

const AirBookingDetails = () => {

    const location = useLocation()
 
    

  return (
    <Box>
      <UserHeader />
      <Box
        sx={{
          // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
          borderRadius: "3px",
          bgcolor: "white",
          mb: 20,
          width: "90%",
          margin: "0 auto",
          mt: "-8vh",

          ".MuiTypography-body2": {
            fontSize: "13px",
          },
          ".MuiTypography-title-lg": {
            fontSize: "24px",
            color: "#444542",
            fontWeight: "500",
          },
          ".MuiTypography-h6": {
            fontSize: "16px",
            color: "#444542",
            fontWeight: "500",
          },
          ".MuiTypography-caption": {
            fontSize: "11px",
            color: "#ACA6A6",
          },
          ".MuiTypography-subtitle1": {
            fontSize: "11px",
            color: "#2156A6",
          },
        }}
      >
        <Grid
          justifyContent={"space-between"}
          container
          // gap={1}
          sx={{ p: "15px", bgcolor: "re" }}
        >
          <Grid item lg={9}>
            <Typography
              sx={{
                color: "#2156A6",
                p: "10px",
                pl: "25px",
                fontWeight: 500,
                fontSize: "15px",
                bgcolor: "#a3c7ff3b",
                borderRadius: "7px",
                mb: "13px",
              }}
            >
              Flight Information
            </Typography>

            <Grid item container>
              <Box
                bgcolor={"white"}
                sx={{
                  ".MuiTypography-body2": {
                    fontSize: "13px",
                  },
                  ".MuiTypography-title-lg": {
                    fontSize: "24px",
                    color: "#444542",
                    fontWeight: "500",
                  },
                  width: "100%",
                  p: "25px",
                  borderRadius: "7px",
                }}
              >
                <Grid
                  justifyContent={"space-between"}
                  container
                  // sx={{ p: "15px 10px" }}
                >
                  {/* <FlightCards flightData={data?.flightData} /> */}
                </Grid>

                {/* <FlightDetails confimData={data} /> */}
              </Box>
            </Grid>

            <Grid item>
              <Typography
                sx={{
                  color: "#2156A6",
                  p: "10px",
                  fontWeight: 500,
                  fontSize: "15px",
                  bgcolor: "#a3c7ff3b",
                  m: "40px 0 15px 0",
                  // width: "100%",
                }}
              >
                Passenger Information
              </Typography>

              <Box>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead sx={{ minWidth: "100%" }}>
                      <TableRow sx={{ backgroundColor: "#F7D800" }}>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="left"
                        >
                          Name
                        </TableCell>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="left"
                        >
                          Gender
                        </TableCell>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="left"
                        >
                          DOB
                        </TableCell>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="center"
                        >
                          PAX
                        </TableCell>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="center"
                        >
                          Passport No
                        </TableCell>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="center"
                        >
                          Expire Date
                        </TableCell>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="center"
                        >
                          Passport Copy
                        </TableCell>
                        <TableCell
                          sx={{ color: "#2156A6", fontSize: "13px" }}
                          align="center"
                        >
                          Visa Copy
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {/* {data?.passenger?.map((data, i) => {
                      const {
                        fName,
                        lName,
                        dob,
                        type,
                        passNo,
                        passEx,
                        passportCopy,
                        visaCopy,
                        gender,
                      } = data;

                      return (
                        <TableRow
                          key={i}
                          sx={{
                            backgroundColor: `${
                              (i + 1) % 2 !== 0 ? "#DEE8FF" : ""
                            }`,
                          }}
                        >
                          <TableCell
                            sx={{ fontSize: "13px", color: "#444542" }}
                            align="left"
                          >
                            {fName + " " + lName}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "13px", color: "#444542" }}
                            align="left"
                          >
                            {gender}
                          </TableCell>

                          <TableCell
                            sx={{ fontSize: "13px", color: "#444542" }}
                            align="left"
                          >
                            {moment(dob).format("DD MMM YYYY")}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "13px", color: "#444542" }}
                            align="center"
                          >
                            {type}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "13px", color: "#444542" }}
                            align="center"
                          >
                            {passNo}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "13px", color: "#444542" }}
                            align="center"
                          >
                            {moment(passEx).format("DD MMM YYYY")}
                          </TableCell>
                          <TableCell
                            sx={{ fontSize: "13px", color: "#444542" }}
                            align="center"
                          >
                            <a
                              target="_blank"
                              rel="noreferrer"
                              href={passportCopy}
                              style={{ color: "#444542" }}
                            >
                              view
                            </a>
                          </TableCell>
                          <TableCell sx={{ fontSize: "13px" }} align="center">
                            <a
                              style={{ color: "#444542" }}
                              target="_blank"
                              rel="noreferrer"
                              href={visaCopy}
                            >
                              view
                            </a>
                          </TableCell>
                        </TableRow>
                      );
                    })} */}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            lg={2.75}
            bgcolor={"gren"}
            sx={{ height: "max-content" }}
          >
            <Box sx={{ bgcolor: "white", mb: "13px", pl: "18px", p: "20px" }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: "16px",
                    color: "#2156A6",
                    fontWeight: "500",
                  }}
                >
                  {/* {data?.status} <br />
                {data?.bookingId} */}
                </Typography>

                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#444542",
                    fontWeight: "500",
                    mt: "14px",
                  }}
                >
                  Booking Information
                </Typography>
              </Box>
              <Box>
                {/* {bookingInfo.map((info, i) => (
                <Stack
                  key={i}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  my={1}
                >
                  <Typography sx={{ fontSize: "11px", color: "#ACA6A6" }}>
                    {info?.text}
                  </Typography>
                  <Typography sx={{ fontSize: "11px", color: "#2156A6" }}>
                    {info?.value}
                  </Typography>
                </Stack>
              ))} */}
              </Box>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                mt={3}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#444542",
                    fontWeight: "500",
                  }}
                >
                  Time Limit
                </Typography>
                <Typography sx={{ fontSize: "11px", color: "#2156A6" }}>
                  {/* {`${moment(data?.timeLimit).format("DD MMM YYYY")} ${moment(
                    data?.timeLimit,
                    "HH:mm:ss"
                  ).format("hh:mm A")}`} */}
                </Typography>
              </Stack>
            </Box>

            {/* <OnewayPrice roundData={flightData} /> */}
            <Box sx={{ bgcolor: "white", py: "20px", borderRadius: "7px" }}>
              <Box sx={{ padding: "0 0 18px 17px" }}>
                <Typography
                  variant="body2"
                  sx={{ color: "#444542", fontWeight: "500" }}
                >
                  Total Payable
                </Typography>
                <Typography variant="title-lg" sx={{ color: "#444542" }}>
                  {/* ৳ {Number(data?.netCost).toLocaleString("en-IN")} */}
                </Typography>
              </Box>

              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "12px",
                  bgcolor: "#FBEA78",
                  padding: "4px 17px",
                  fontWeight: 500,
                }}
              >
                Price Breakdown
              </Typography>

              {/* {pricebreakdown
              .filter((p) => parseFloat(p.paxCount) !== 0)
              .map((price, i) => {
                const { type, name, paxCount, baseCost, tax } = price;
                return (
                  <Box key={i} sx={{ padding: "20px 17px 0" }}>
                    <Typography variant="h6">
                      {name} x{paxCount}
                    </Typography>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography variant="caption">
                        Base Fare x{paxCount}
                      </Typography>
                      <Typography variant="subtitle1">
                        {Number(baseCost).toLocaleString("en-IN")}
                      </Typography>
                    </Stack>
                    <Stack direction={"row"} justifyContent={"space-between"}>
                      <Typography variant="caption">Tax x{paxCount}</Typography>
                      <Typography variant="subtitle1">
                        {Number(tax).toLocaleString("en-IN")}
                      </Typography>
                    </Stack>
                  </Box>
                );
              })} */}

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ padding: "20px 17px 0" }}
              >
                <Typography variant="caption">Total Base & Tax</Typography>
                <Typography variant="subtitle1">
                  {/* {parseFloat(data?.Tax).toLocaleString("en-IN")} */}
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ padding: "18px 17px 0" }}
              >
                <Typography variant="caption">
                  Customer Invoice Total
                </Typography>
                <Typography variant="subtitle1">
                  {/* {Number(data?.netCost).toLocaleString("en-IN")} */}
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ padding: "18px 17px 0" }}
              >
                <Typography variant="caption">Discount</Typography>
                <Typography variant="subtitle1">
                  {/* {(data?.grossCost - data?.netCost).toLocaleString("en-IN")} */}
                </Typography>
              </Stack>

              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                sx={{ padding: "18px 17px 0" }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "#ACA6A6",
                    fontWeight: "500",
                  }}
                >
                  Grand Total
                </Typography>
                <Typography sx={{ color: "#2156A6", fontSize: "14px" }}>
                  {/* ৳ {Number(data?.netCost).toLocaleString("en-IN")} */}
                </Typography>
              </Stack>
            </Box>

            <Box>
              <Button
                sx={{
                  bgcolor: "white",
                  py: "10px",
                  borderRadius: "7px",
                  width: "100%",
                  color: "#444542",
                  fontSize: "12px",
                  boxShadow: "0px 0px 5px 0px rgba(0, 0, 0, 0.14)",
                  my: "13px",
                }}
              >
                Downloads PDF
              </Button>

              <Box
                sx={{
                  bgcolor: "#0B7102",
                  py: "10px",
                  borderRadius: "3px",
                  width: "100%",
                  color: "#fff",
                  fontSize: "12px",
                  textAlign: "center",
                }}
              >
                Downloads PDF
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AirBookingDetails;
