import React, { useState, useEffect, useRef } from "react";
import { Container } from "@mui/system";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "@mui/material/Modal";
import {
  Box,
  Grid,
  Button,
  Typography,
  Stack,
  Pagination,
  ClickAwayListener,
  CircularProgress,
  Skeleton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Drawer,
} from "@mui/material";
import Swal from "sweetalert2";
import { addDays } from "date-fns/esm";
import { format } from "date-fns";
import Tooltip from "@mui/material/Tooltip";
import secureLocalStorage from "react-secure-storage";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import Search from "../../images/undraw/undraw_web_search_re_efla.svg";
import SingleFlight from "../../components/SingleFlight/SingleFlight";
import InfoIcon from "@mui/icons-material/Info";
import FlightSearchBox from "../../components/FlightSearchBox/FlightSearchBox";
import Commission from "../../components/Commission";
import Preloader from "../../components/Preloader/Preloader";
import SessionTimer from "../../components/Shared/SessionTimer/SessionTimer";
import styled from "@emotion/styled";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import AirlinesNameSlider from "../../components/AirlinesNameSlider/AirlinesNameSlider";
import "../SearchReslut/SearchResult.css";
import Header from "../../components/Header/Header";
import UserHeader from "../../components/UserPage/UserHeader/UserHeader";
import OneWayFilter from "../../components/OnewayFilter/OneWayFilter";
import SingleSkeleton from "./SingleSkeleton";
import AirlinesSlider from "../../components/SingleFlight/AirlinesSlider";
import FilterAltIcon from "@mui/icons-material/FilterAlt";

const modalStyle = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  width: { lg: "70vw", md: "90vw", sm: "90vw", xs: "90vw" },
  height: "500px",
  padding: "20px",
  background: "#fff",
  borderRadius: "10px",
  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
};

const itemsPerPage = 20;

const SearchResult = () => {
  const [expanded, setExpanded] = useState(false);
  const [noData, setNoData] = useState("No Data");
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const subagentId = user?.user?.subagentId;
  const searchData = secureLocalStorage.getItem("search-data");
  const navigate = useNavigate();
  const location = useLocation();
  const requiredSearchData =
    location.state !== null
      ? location.state
      : secureLocalStorage.getItem("search-data");

  const {
    toSendData,
    adultCount,
    childCount,
    departureDate,
    infant,
    tripType,
    faddress,
    toAddress,
    fromSearchText,
    toSearchText,
    fromSendData,
    className,
  } = requiredSearchData;
  const [selectedAirLines, setSelectedAirLines] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  //all states that i have to send to modify search
  //todo: state for retrigger useEffect
  const [changeState, setChangeState] = useState(null);
  //todo: End for retrigger useEffect

  //todo: state for retrigger useEffect
  const [changeStateSession, setChangeStateSession] = useState(null);
  //todo: End for retrigger useEffect

  //todo: state for from date change
  const [changeFrom, setChangeFrom] = useState(false);
  //todo: End state for from date change

  const [type, setType] = React.useState("flight");
  const [value, setValue] = React.useState(tripType);
  const [oneWayFromSearchText, setOneWayFromSearchText] =
    useState(fromSearchText);
  const [oneWayToSearchText, setOneWayToSearchText] = useState(toSearchText);
  const now = useRef(new Date(departureDate));
  const [from, setFrom] = useState(now.current);
  const [to, setTo] = useState(addDays(now.current, 3));
  const [fromSearchDate, setFromSearchDate] = useState(new Date(departureDate));
  const [oneWayFaddress, setOneWayFaddress] = useState(faddress);
  const [oneWayToAddress, setOneWayToAddress] = useState(toAddress);
  const [oneWayFromSendData, setOneWayFromSendData] = useState(fromSendData);
  const [oneWayToSendData, setOneWayToSendData] = useState(toSendData);
  const [oneWayAdultCount, setOneWayAdultCount] = useState(adultCount);
  const [oneWayChildCount, setOneWayChildCount] = useState(childCount);
  const [oneWayInfant, setOneWayInfant] = useState(infant);
  const [result, setResult] = useState(adultCount + childCount + infant);
  const [oneWayClassName, setOneWayClassName] = useState(className);
  const [isPrevClicked, setIsPrevCliked] = useState(false);
  const [isNextClicked, setIsNextCliked] = useState(false);
  const [quotetionArr, setQuotetionArr] = useState([]);

  const [sortedBy, setSortedBy] = useState("cheapest");

  const [multiSearchData, setMultiSearchData] = useState({
    // agentId: users?.user?.agentId || "",
    agentId: "",
    adultCount: adultCount,
    childCount: childCount,
    infantCount: infant,
    CityCount: 2,
    tripType: 3,
    segments: [
      {
        id: 0,
        openFrom: false,
        depFrom: fromSendData.trim(),
        depAddress: faddress,
        depFromText: fromSearchText,
        arrTo: "JED",
        arrAddress: "Jeddah",
        arrToText: "Jeddah Intl (JED)",
        openTo: false,
        Date: new Date(from).toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
      {
        id: 1,
        openFrom: false,
        depFrom: "JED",
        depAddress: "Jeddah",
        depFromText: "Jeddah Intl (JED)",
        arrTo: "MED",
        arrAddress: "Madinah",
        arrToText: "Prince Mohammad Bin Abdulaziz Intl Airport(MED)",
        openTo: false,
        Date: new Date(to).toLocaleDateString("sv"),
        openDate: false,
        open: false,
      },
    ],
  });

  //end
  const [modifyOpen, setModifyOpen] = useState(false);
  const modifyHandleOpen = () => setModifyOpen(true);
  const modifyHandleClose = () => setModifyOpen(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);

  const [initialFetchData, setInitialFetchData] = useState([]);

  const [departureLocation, setDepartureLocation] = useState("");
  const departureLocationCode =
    location?.state?.fromSendData?.slice(1, 5) ||
    searchData.fromSendData?.slice(1, 5);

  const [arrivalLocation, setArrivalLocation] = useState("");

  // todo:next day previous day variables
  let tomorrow = new Date(fromSearchDate);
  tomorrow.setDate(tomorrow.getDate() + 1);
  let yesterday = new Date(fromSearchDate);
  yesterday.setDate(yesterday.getDate() - 1);

  const [pageCount, setPageCount] = useState(0);
  const [page, setPage] = useState(1);
  let size = 30;

  const userInfo = secureLocalStorage.getItem("user-info");
  const [isDrawerOpenMobileFilter, setDrawerOpenMobileFilter] = useState(false);

  const handleFilterIconClick = () => {
    setDrawerOpenMobileFilter(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpenMobileFilter(false);
  };
  // Handle a page change.
  const handlePageChange = (event, value) => {
    setPage(value);
    setData2(data?.slice((value - 1) * size, value * size));
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    fetch(
      `https://farhantt.api.flyfarint.com/v.1.0.0/AirMaterials/airports.php?search=${departureLocationCode}`
    )
      .then((res) => res.json())
      .then((data) => setDepartureLocation(data[0]));
  }, [departureLocationCode]);

  // Send the airport location.
  useEffect(() => {
    fetch(
      `https://farhantt.api.flyfarint.com/v.1.0.0/AirMaterials/airports.php?search=${
        location?.state?.toSendData || searchData.toSendData
      }`
    )
      .then((res) => res.json())
      .then((data) => setArrivalLocation(data[0]));
  }, [location?.state?.toSendData || searchData.toSendData]);

  //todo Searches for flights today's date.
  const [click, setClick] = useState(false);
  useEffect(() => {
    let unSubscribed = false;
    setIsPrevCliked(false);
    setIsNextCliked(false);
    setIsLoaded(false);
    modifyHandleClose();
    setClick(true);
    const url = `https://api.rtc.flyfarint.com/v1.0/b2b/air/search/oneway.php?journeyfrom=${fromSendData?.trim()}&journeyto=${toSendData}&departuredate=${new Date(
      departureDate
    ).toLocaleDateString(
      "sv"
    )}&adult=${adultCount}&child=${childCount}&infant=${infant}&class=${className}&agentId=${
      userInfo?.agent_id
    }#`;

    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (!unSubscribed) {
          if (data.length !== 0) {
            setIsLoaded(true);
            const uniqueData = data;
            setData(uniqueData);
            setData2(uniqueData);
            setInitialFetchData(uniqueData);
            setClick(false);
          } else {
            Swal.fire({
              imageUrl: Search,
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "No Flights Found",
              confirmButtonText: "Search Again...",
              confirmButtonColor: "var(--primary-color)",
            }).then(function () {
              navigate("/");
            });
          }
        }
      })
      .catch(async (err) => {
        //console.log(err.message);
        await Swal.fire({
          imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Flights Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "var(--primary-color)",
        }).then(function () {
          navigate("/dashboard");
        });
      });
    return () => {
      unSubscribed = true;
    };
  }, [
    changeState,
    changeStateSession,
    size,
    departureDate,
    fromSendData,
    toSendData,
    adultCount,
    childCount,
    infant,
    tripType,
    navigate,
  ]);

  const handleFilterByAirLine = (career) => {
    const filterByCareer = data.filter((item) => item.career === career);
    setData2(filterByCareer);
  };

  return (
    <Box sx={{ background: "#F0F2F5" }}>
      <UserHeader />
      {/* <Container> */}
      <Box
        borderRadius="3px"
        sx={{
          width: "90%",
          margin: "0 auto",
        }}
      >
        <Box
          sx={{
            position: "relative",
            mt: {
              xs: "0px",
              sm: "0px",
              md: "-70px",
              lg: "-70px",
            },
          }}
        >
          <Grid container justifyContent="space-between">
            <Grid container item xs={12} sm={12} md={12} lg={12}>
              <Grid
                item
                md={12}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                }}
              >
                <FlightSearchBox
                  setClick={setClick}
                  click={click}
                  type={type}
                  setType={setType}
                  value={value}
                  setValue={setValue}
                  fromSearchText={oneWayFromSearchText}
                  setFromSearchText={setOneWayFromSearchText}
                  toSearchText={oneWayToSearchText}
                  setToSearchText={setOneWayToSearchText}
                  from={from}
                  setFrom={setFrom}
                  to={to}
                  setTo={setTo}
                  faddress={oneWayFaddress}
                  setfaddress={setOneWayFaddress}
                  toAddress={oneWayToAddress}
                  setToAddress={setOneWayToAddress}
                  fromSendData={oneWayFromSendData}
                  setFromSendData={setOneWayFromSendData}
                  toSendData={oneWayToSendData}
                  setToSendData={setOneWayToSendData}
                  adultCount={oneWayAdultCount}
                  setAdultCount={setOneWayAdultCount}
                  childCount={oneWayChildCount}
                  setChildCount={setOneWayChildCount}
                  infant={oneWayInfant}
                  setInfant={setOneWayInfant}
                  result={result}
                  setResult={setResult}
                  className={oneWayClassName}
                  setClassName={setOneWayClassName}
                  changeState={changeState}
                  setChangeState={setChangeState}
                  changeFrom={changeFrom}
                  setChangeFrom={setChangeFrom}
                  multiSearchData={multiSearchData}
                  setMultiSearchData={setMultiSearchData}
                />
              </Grid>

              <Grid
                container
                item={12}
                spacing={2}
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                <Grid item xs={12} md={12}>
                  <Box
                    mt={1}
                    // mx={1.5}
                    sx={{
                      bgcolor: "var(--white)",
                      px: "8px",
                      py: "6px",
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "var(--black)",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "5px",
                        }}
                      >
                        {" "}
                        {fromSendData}
                        <Typography sx={{ pt: "5px" }}>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            viewBox="0 0 116 57"
                            fill="var(--secondary-color)"
                          >
                            <path
                              d="M115.51 14.4039C114.711 12.0948 112.993 10.1857 110.732 9.09641C108.471 8.00716 105.854 7.82702 103.455 8.5956L76.971 17.1L29.6042 0L17.7625 5.7L53.2876 28.5L29.6042 39.9L5.92085 28.5L0 34.2L23.6834 57L109.814 25.9065C112.125 25.0713 114.009 23.4062 115.072 21.2611C116.134 19.1161 116.291 16.6581 115.51 14.4039Z"
                              fill="var(--secondary-color)"
                            />
                          </svg>
                        </Typography>
                        {toSendData}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        <Link to="/dashboard">
                          <Box
                            sx={{
                              bgcolor: "var(--secondary-color)",
                              color: "var(--white)",
                              px: "10px",
                              borderRadius: "3px",
                              display: "flex",
                              alignItems: "center",

                              fontSize: "11px",
                              fontWeight: 600,
                              height: "25px",
                              cursor: "pointer",
                            }}
                          >
                            Modifiy Serch
                          </Box>
                        </Link>
                        <Box
                          sx={{ cursor: "pointer" }}
                          onClick={handleFilterIconClick}
                        >
                          <FilterAltIcon
                            sx={{
                              color: "var(--primary-color)",
                              cursor: "pointer",
                            }}
                          />
                        </Box>{" "}
                      </Box>
                    </Box>
                    <Drawer
                      anchor="right"
                      open={isDrawerOpenMobileFilter}
                      onClose={handleDrawerClose}
                    >
                      <Box
                        sx={{
                          position: "sticky",
                          top: 0,
                          left: 0,
                          bgcolor: "var(--white)=",
                          borderRadius: "5px",

                          mt: 2,
                        }}
                      >
                        {isLoaded ? (
                          <>
                            <Box>
                              <Box
                                sx={{
                                  mt: 3.1,
                                  flexDirection: "column-reverse",
                                  alignItems: "end",
                                  bgcolor: "#fffae6",
                                  border: "1px solid var(--secondary-color)",
                                  mb: "20px",
                                  pb: "12px",
                                  display: ` ${
                                    data2[0]?.triptype?.toLowerCase() ===
                                    "inbound"
                                      ? "none"
                                      : "flex"
                                  }`,
                                  "& .MuiAccordionSummary-content": {
                                    mb: "0",
                                  },
                                }}
                              >
                                <Typography
                                  onClick={() => setExpanded(!expanded)}
                                  sx={{
                                    fontSize: "13px",
                                    color: "#222",
                                    cursor: "pointer",
                                    color: "var(--primary-color)",
                                    p: "12px 12px 0 0",
                                  }}
                                >
                                  Show {expanded ? "less" : "more"}
                                </Typography>
                                <Accordion
                                  expanded={expanded === true}
                                  sx={{
                                    boxShadow: "none",
                                    bgcolor: "#fffae6",
                                  }}
                                >
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    sx={{ marginBottom: "0" }}
                                  >
                                    <Box>
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                        }}
                                      >
                                        <InfoIcon
                                          style={{
                                            fontSize: "13px",
                                            color: "var(--primary-color)",
                                          }}
                                        />{" "}
                                        <Typography
                                          sx={{
                                            fontSize: "13px",
                                            color: "var(--primary-color)",
                                            ml: "5px",
                                            fontWeight: "500",
                                          }}
                                        >
                                          Important
                                        </Typography>
                                      </Box>

                                      <Typography
                                        sx={{
                                          fontSize: "13px",
                                          color: "var(--mattBlack)",
                                          mt: "10px",
                                        }}
                                      >
                                        Please book one-way flight if you have
                                        the{" "}
                                        <span
                                          style={{
                                            fontWeight: "500",
                                            fontSize: "13px",
                                          }}
                                        >
                                          required documents.
                                        </span>
                                      </Typography>
                                    </Box>{" "}
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{ fontSize: "13px", padding: "0 16px" }}
                                  >
                                    <ul
                                      style={{
                                        listStyle: "disc",
                                        paddingLeft: "16px",
                                      }}
                                    >
                                      <li>
                                        {" "}
                                        Passport holder of the arrival
                                        destination
                                      </li>
                                      <li>
                                        {" "}
                                        Student, medical, business or work
                                        permit visa
                                      </li>
                                      <li> PR</li>
                                    </ul>
                                    Else, make sure to book a return ticket
                                    before starting your journey towards the
                                    destination to avoid any immigration related
                                    inconvenience.
                                  </AccordionDetails>
                                </Accordion>
                              </Box>
                              <Button
                                style={{
                                  color: "var(--white)",
                                  display: "flex",
                                  marginTop: "25px",
                                  alignItems: "center",
                                  justifyContent: "space-around",
                                  width: "100%",
                                  backgroundColor: "var(--primary-color)",
                                }}
                              >
                                <SessionTimer />
                              </Button>
                              <Box
                                sx={{
                                  bgcolor: "var(--secondary-color)",
                                  py: "3px",
                                  textAlign: "center",
                                  borderRadius: "3px",
                                  mt: "15px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    color: "var(--white)",
                                    fontWeight: "500",
                                  }}
                                >
                                  Showing {data2?.length} Flight Results
                                </Typography>
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                position: "sticky",
                                top: 0,
                                left: 0,
                                zIndex: 1000,
                              }}
                            >
                              <OneWayFilter
                                selectedAirLines={selectedAirLines}
                                setSelectedAirLines={setSelectedAirLines}
                                sortedBy={sortedBy}
                                setSortedBy={setSortedBy}
                                initialFetchData={initialFetchData}
                                filteredData={data2}
                                setfilteredData={setData2}
                              />
                            </Box>
                          </>
                        ) : (
                          <Box
                            sx={{
                              width: "100%",
                              borderRadius: "5px",
                              overFlow: "hidden",
                              bgcolor: "var(--white)",
                              padding: "10px",
                              mt: "25px",
                            }}
                          >
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              my={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"50%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"50%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"100%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"20%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"70%"}
                                height={"20px"}
                              />
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                              spacing={2}
                              mb={2}
                            >
                              <Skeleton
                                sx={{ borderRadius: "5px" }}
                                variant="rectangular"
                                width={"80%"}
                                height={"20px"}
                              />
                            </Stack>
                          </Box>
                        )}
                      </Box>
                    </Drawer>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={12} spacing={3}>
              <Grid item xs={12} sm={12} md={12} lg={9.6}>
                {/* //todo:airline slider */}
                {/* <Grid
                      my={2}
                      mx={"auto"}
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                    ></Grid> */}

                {/* //todo:main search result */}
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{
                    height: "fit-content",
                    mt: "25px",
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: "white",
                      borderRadius: "5px",
                      mb: "12px",
                    }}
                  >
                    {isLoaded ? (
                      <AirlinesSlider
                        data={data}
                        setData2={setData2}
                        isLoaded={isLoaded}
                        handleFilterByAirLine={handleFilterByAirLine}
                        initialFetchData={initialFetchData}
                        selectedAirLines={selectedAirLines}
                        setSelectedAirLines={setSelectedAirLines}
                      />
                    ) : (
                      [...new Array(1)].map((data, index) => (
                        <Box
                          key={index}
                          style={{
                            width: "100%",
                            height: "50px",
                            // margin: "0 10px 0 0",
                            borderRadius: "5px",
                            overFlow: "hidden",
                            backgroundColor: "var(--white)",
                            width: "100%",
                            borderRadius: "5px",
                            overFlow: "hidden",
                          }}
                        >
                          {/* <Skeleton
                        variant="rectangular"
                        width={"100%"}
                        height={"100%"}
                      /> */}
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{
                              height: "100%",
                              px: "20px",
                            }}
                          >
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                            <Skeleton
                              sx={{ borderRadius: "5px" }}
                              variant="rectangular"
                              width={"50%"}
                              height={"20px"}
                            />
                          </Stack>
                        </Box>
                      ))
                    )}
                  </Box>

                  {/* //todo:main search result */}
                  {isLoaded ? (
                    data2.length !== 0 ? (
                      data2?.slice(startIndex, endIndex)?.map((data, index) => {
                        return (
                          <SingleFlight
                            flightData={data}
                            adultCount={adultCount}
                            childCount={childCount}
                            infant={infant}
                            sortedBy={sortedBy}
                            index={index}
                            key={index}
                            quotetionArr={quotetionArr}
                            setQuotetionArr={setQuotetionArr}
                          />
                        );
                      })
                    ) : (
                      <Typography
                        sx={{
                          textAlign: "center",
                          fontSize: "3rem",
                          fontWeight: "500",
                        }}
                      >
                        No Flight Found
                      </Typography>
                    )
                  ) : (
                    [...new Array(5)].map((data, index) => (
                      <>
                        <SingleSkeleton />
                      </>
                    ))
                  )}
                  <Box
                    sx={{
                      width: "100%",
                      my: 3,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Stack spacing={2}>
                      <Pagination
                        count={Math.ceil(data2.length / itemsPerPage)}
                        page={currentPage}
                        onChange={(event, page) => setCurrentPage(page)}
                      />
                    </Stack>
                  </Box>
                </Grid>
              </Grid>
              <Grid
                item
                lg={2.4}
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                  // ml: "10px"
                }}
              >
                {isLoaded ? (
                  <>
                    <Box>
                      <Box
                        sx={{
                          mt: 3.1,
                          flexDirection: "column-reverse",
                          alignItems: "end",
                          bgcolor: "#fffae6",
                          border: "1px solid var(--secondary-color)",
                          mb: "20px",
                          pb: "12px",
                          display: ` ${
                            data2[0]?.triptype?.toLowerCase() === "inbound"
                              ? "none"
                              : "flex"
                          }`,
                          "& .MuiAccordionSummary-content": {
                            mb: "0",
                          },
                        }}
                      >
                        <Typography
                          onClick={() => setExpanded(!expanded)}
                          sx={{
                            fontSize: "13px",
                            color: "#222",
                            cursor: "pointer",
                            color: "var(--primary-color)",
                            p: "12px 12px 0 0",
                          }}
                        >
                          Show {expanded ? "less" : "more"}
                        </Typography>
                        <Accordion
                          expanded={expanded === true}
                          sx={{
                            boxShadow: "none",
                            bgcolor: "#fffae6",
                          }}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            sx={{ marginBottom: "0" }}
                          >
                            <Box>
                              <Box
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                                <InfoIcon
                                  style={{
                                    fontSize: "13px",
                                    color: "var(--primary-color)",
                                  }}
                                />{" "}
                                <Typography
                                  sx={{
                                    fontSize: "13px",
                                    color: "var(--primary-color)",
                                    ml: "5px",
                                    fontWeight: "500",
                                  }}
                                >
                                  Important
                                </Typography>
                              </Box>

                              <Typography
                                sx={{
                                  fontSize: "13px",
                                  color: "var(--mattBlack)",
                                  mt: "10px",
                                }}
                              >
                                Please book one-way flight if you have the{" "}
                                <span
                                  style={{
                                    fontWeight: "500",
                                    fontSize: "13px",
                                  }}
                                >
                                  required documents.
                                </span>
                              </Typography>
                            </Box>{" "}
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{ fontSize: "13px", padding: "0 16px" }}
                          >
                            <ul
                              style={{ listStyle: "disc", paddingLeft: "16px" }}
                            >
                              <li>
                                {" "}
                                Passport holder of the arrival destination
                              </li>
                              <li>
                                {" "}
                                Student, medical, business or work permit visa
                              </li>
                              <li> PR</li>
                            </ul>
                            Else, make sure to book a return ticket before
                            starting your journey towards the destination to
                            avoid any immigration related inconvenience.
                          </AccordionDetails>
                        </Accordion>
                      </Box>
                      <Button
                        style={{
                          color: "var(--white)",
                          display: "flex",
                          marginTop: "25px",
                          alignItems: "center",
                          justifyContent: "space-around",
                          width: "100%",
                          backgroundColor: "var(--primary-color)",
                        }}
                      >
                        <SessionTimer />
                      </Button>
                      <Box
                        sx={{
                          bgcolor: "var(--secondary-color)",
                          py: "3px",
                          textAlign: "center",
                          borderRadius: "3px",
                          mt: "15px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: "var(--white)",
                            fontWeight: "500",
                          }}
                        >
                          Showing {data2?.length} Flight Results
                        </Typography>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        position: "sticky",
                        top: 0,
                        left: 0,
                        zIndex: 1000,
                      }}
                    >
                      <OneWayFilter
                        selectedAirLines={selectedAirLines}
                        setSelectedAirLines={setSelectedAirLines}
                        sortedBy={sortedBy}
                        setSortedBy={setSortedBy}
                        initialFetchData={initialFetchData}
                        filteredData={data2}
                        setfilteredData={setData2}
                      />
                    </Box>
                  </>
                ) : (
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: "5px",
                      overFlow: "hidden",
                      bgcolor: "var(--white)",
                      padding: "10px",
                      mt: "25px",
                    }}
                  >
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      my={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"50%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"100%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"20%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"70%"}
                        height={"20px"}
                      />
                    </Stack>

                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      spacing={2}
                      mb={2}
                    >
                      <Skeleton
                        sx={{ borderRadius: "5px" }}
                        variant="rectangular"
                        width={"80%"}
                        height={"20px"}
                      />
                    </Stack>
                  </Box>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={12} md={8}>
          {quotetionArr?.length ? (
            <Box
              sx={{
                position: "fixed",
                bottom: "0",
                width: "100%",
                background: "var(--primary-color)",
                padding: "10px 0px",
                boxShadow: "0px -1px 5px 0px rgba(0,0,0,0.75)",
                zIndex: 10000,
              }}
            >
              <Container>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      color: "white",
                      pl: 7,
                    }}
                  >{`${quotetionArr?.length} Flights Selected`}</Typography>
                  <Button
                    onClick={() => {
                      navigate("/dashboard/make-quotation", {
                        state: {
                          quotetionArr,
                          adultCount,
                          childCount,
                          infant,
                          to,
                          from,
                          tripType,
                          toAddress,
                        },
                      });
                    }}
                    style={{
                      background: "var(--secondary-color)",
                      color: "var(--primary-color)",
                      fontSize: "14px",
                      fontWeight: "500",
                      padding: "5px 10px",
                      marginRight: "65px",
                    }}
                  >
                    Make Quotation
                  </Button>
                </Box>
              </Container>
            </Box>
          ) : null}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SearchResult;
