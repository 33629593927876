const InitialData = [
    {
      id: "802",
      code: "DAC",
      name: "Hazrat Shahjalal Intl Airport",
      cityCode: "DAC",
      cityName: "Dhaka",
      countryName: "BANGLADESH",
      countryCode: "BD",
    },
    {
      id: "932",
      code: "DXB",
      name: "Dubai Intl Airport",
      cityCode: "DXB",
      cityName: "Dubai",
      countryName: "UNITED ARAB EMIRATES",
      countryCode: "AE",
    },
    {
      id: "4402",
      code: "CXB",
      name: "COX's Bazar Airport",
      cityCode: "CXB",
      cityName: "Cox's Bazar, Bangladesh",
      countryName: "BANGLADESH",
      countryCode: "BD",
    },
    {
      id: "4404",
      code: "JSR",
      name: "Jashore Airport",
      cityCode: "JSR\r\n",
      cityName: "Jashore",
      countryName: "BANGLADESH",
      countryCode: "BD",
    },
    {
      id: "4403",
      code: "BZL",
      name: "Barishal Airport",
      cityCode: "BZL\r\n",
      cityName: "Barishal",
      countryName: "BANGLADESH",
      countryCode: "BD",
    },
    {
      id: "4405",
      code: "RJH",
      name: "Shah Makhdum Airport",
      cityCode: "RJH",
      cityName: "Rajshahi",
      countryName: "BANGLADESH",
      countryCode: "BD",
    },
    {
      id: "4406",
      code: "SPD",
      name: "Saidpur Airport",
      cityCode: "SPD",
      cityName: "Saidpur",
      countryName: "BANGLADESH",
      countryCode: "BD",
    },
    {
      id: "2142",
      code: "MCT",
      name: "Seeb Intl ",
      cityCode: "MCT",
      cityName: "Muscat",
      countryName: "OMAN",
      countryCode: "OM",
    },
    {
      id: "2014",
      code: "LHR",
      name: "Heathrow",
      cityCode: "LON",
      cityName: "London",
      countryName: "UNITED KINGDOM",
      countryCode: "GB",
    },
    {
      id: "5",
      code: "JFK",
      name: "John F Kennedy Intl ",
      cityCode: "NYC",
      cityName: "New York",
      countryName: "UNITED STATES",
      countryCode: "US",
    },
  ];
  export default InitialData;
  