import { Button, Collapse, Grid, Stack, Tab, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SingleFlight.css";
import GoSearchCard from "./GoSearchCard";
import BackSearchCard from "./BackSearchCard";
import RoundFlightDetails from "../Roundway/RoundFlightDetails";
const isMobile = window.innerWidth <= 768;

const RoundSingleFlight = (props) => {
  const [expanded, setExpanded] = useState(false);
  const navigate = useNavigate();

  const { segments, system, pricebreakdown, price, refundable } =
    props.roundData;
  const quotetionArr = props.quotetionArr;
  const setQuotetionArr = props.setQuotetionArr;
  const roundFlightData = props.roundData;
  const [checked, setChecked] = useState(false);

  console.log(quotetionArr);
  console.log(roundFlightData);
  const clientPrice = Math.round(
    parseInt(
      props.roundData.customerPrice ||
        props.roundData.clientPrice ||
        props.roundData.baseprice
    )
  );
  const clientFare = Math.round(Number.parseInt(props.roundData.clientPrice));
  const agentFare = Math.round(Number.parseInt(props.roundData.price));
  const commission = Math.round(clientFare - agentFare);

  //todo:end of CM AF CM variables
  //todo: booking functional work here
  const RoundTripFlightInfo = () => {
    system === "Sabre"
      ? // ? navigate("/dashboard/roundflightinformation", {
        navigate("/dashboard/flightinformation", {
          state: {
            roundData: props.roundData,
            adultCount: props.adultCount,
            childCount: props.childCount,
            infant: props.infant,
            tripType: props.tripType,
            clientFare,
          },
        })
      : system === "Galileo"
      ? navigate("/dashboard/flightinformation", {
          state: {
            roundData: props.roundData,
            adultCount: props.adultCount,
            childCount: props.childCount,
            infant: props.infant,
            tripType: props.tripType,
            clientFare,
          },
        })
      : navigate("/dashboard/flightinformation", {
          state: {
            flightData: props.roundData,
            roundData: props.roundData,
            adultCount: props.adultCount,
            childCount: props.childCount,
            infant: props.infant,
            tripType: props.tripType,
            clientFare,
            system,
            // flightData,
            // adultCount,
            // childCount,
            // infant,
            // to,
            // from,
            // tripType,
            // fromAddress,
            // toAddress,
            // dDate,
            // clientFare,
            // agentFare,
            // commission,
          },
        });
  };

  // Unique Flight Code filter
  const goMarcketingCareerCode = segments.go
    .map((data) => data?.marketingcareer)
    .filter((value, index, arr) => arr.indexOf(value) === index);
  const backMarcketingCareerCode = segments.back
    .map((data) => data?.marketingcareer)
    .filter((value, index, arr) => arr.indexOf(value) === index);

  const handleBookNow = (data) => {
    system === "Sabre"
      ? // ? navigate("/dashboard/roundflightinformation", {
        navigate("/dashboard/flightinformation", {
          state: {
            roundData: { ...props.roundData },
            adultCount: props.adultCount,
            childCount: props.childCount,
            infant: props.infant,
            tripType: props.tripType,
            clientFare,
          },
        })
      : system === "Galileo"
      ? navigate("/dashboard/flightinformation", {
          state: {
            roundData: { ...props.roundData },
            adultCount: props.adultCount,
            childCount: props.childCount,
            infant: props.infant,
            tripType: props.tripType,
            clientFare,
          },
        })
      : navigate("/dashboard/flightinformation", {
          state: {
            roundData: { ...props.roundData },
            adultCount: props.adultCount,
            childCount: props.childCount,
            infant: props.infant,
            tripType: props.tripType,
            clientFare,
            system,
          },
        });
  };

  const handleBox = (data) => {
    const event = window.event;
    setChecked(event.target.checked);
    if (event.target.checked) {
      setQuotetionArr([...quotetionArr, roundFlightData]);
    } else {
      const filter = quotetionArr?.filter((item) => item?.uId !== data.uId);
      setQuotetionArr(filter);
    }
  };

  return (
    <Box
      sx={{
        borderRadius: "3px",
        backgroundColor: `${system === "Sabre" ? "white" : ""}`,
        mb: 2,
        mt: "10px",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        pl: "5px",
        pt: "5px",
      }}
    >
      {isMobile ? (
        <>
          <Grid
            justifyContent={"space-between"}
            container
            sx={{
              bgcolor: "white",
              p: "15px",
              cursor: "pointer",
            }}
            onClick={handleBookNow}
          >
            {/* part 1 */}

            <GoSearchCard roundFlightData={roundFlightData} />
            {/* part 2 */}
          </Grid>
          <Grid
            justifyContent={"space-between"}
            container
            sx={{
              bgcolor: "white",
              p: "15px",
            }}
          >
            <BackSearchCard
              roundFlightData={roundFlightData}
              quotetionArr={quotetionArr}
              setQuotetionArr={setQuotetionArr}
              handleBox={handleBox}
              checked={checked}
            />
            <Grid order={{ xs: 5, lg: 4 }} mt={3} xs={12}>
              <Box sx={{ borderBottom: "1px solid grey" }}></Box>
            </Grid>
          </Grid>

          <Grid
            justifyContent={"space-between"}
            container
            sx={{
              bgcolor: "white",
              px: "15px",
            }}
          >
            <Grid order={{ xs: 5, lg: 4 }} xs={12}>
              <Box sx={{display:"flex",justifyContent:"space-between"}}>
                <Box
                  // sx={{
                  //   display: "flex",
                  //   alignItems: "center",
                  //   justifyContent: "space-between",
                  // }}
                >
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: refundable === "Refundable" ? "#0B7102" : "red",
                    }}
                  >
                    {refundable}
                  </Typography>
                  {/* <Typography variant="body2" sx={{ color: "#8C8080" }}>
                    Agent fare
                  </Typography> */}
                </Box>

                <Stack
                 
                 
                >
                  <Box sx={{ textAlign: "right",pb:"5px" }}>
                    <Typography
                      variant="body2"
                      sx={{ color: "var(--secondary-color)", textDecoration: "line-through" }}
                    >
                      {Number(clientPrice).toLocaleString("en-IN")}৳
                    </Typography>
                    <Typography sx={{ fontSize: "16px",}}>
                      {Number(price).toLocaleString("en-IN")}৳
                    </Typography>
                  </Box>
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid
            justifyContent={"space-between"}
            container
            sx={{
              bgcolor: "white",
              p: "15px",
            }}
          >
            {/* part 1 */}

            <GoSearchCard roundFlightData={roundFlightData} />
            <Grid order={{ xs: 5, lg: 5 }} xs={6} lg={1.75}>
              <Box sx={{ textAlign: "right", padding: "10px" }}>
                <Typography variant="body2" sx={{ color: "#8C8080" }}>
                  Agent fare
                </Typography>
                <Typography variant="title-lg">
                  {Number(price).toLocaleString("en-IN")}৳
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#2156A6", textDecoration: "line-through" }}
                >
                  {Number(clientPrice).toLocaleString("en-IN")}৳
                </Typography>
              </Box>
            </Grid>

            {/* part 2 */}
          </Grid>
          <Grid
            justifyContent={"space-between"}
            container
            sx={{
              bgcolor: "white",
              p: "15px",
            }}
          >
            <BackSearchCard
              roundFlightData={roundFlightData}
              quotetionArr={quotetionArr}
              setQuotetionArr={setQuotetionArr}
              handleBox={handleBox}
              checked={checked}
            />
            <Grid order={{ xs: 10, lg: 10 }} xs={6} lg={1.75}>
              <Stack
                direction="column"
                justifyContent="end"
                alignItems="end"
                spacing={2}
                sx={{ height: "100%", padding: "10px" }}
              >
                <Button
                  onClick={handleBookNow}
                  sx={{
                    bgcolor: "var(--primary-color)",
                    color: "var(--white)",
                    mb: "10px",
                    fontSize: "12px",
                    px: "15px",
                    fontWeight: "500",
                    ":hover": { bgcolor: "var(--primary-color)" },
                  }}
                >
                  BOOK NOW
                </Button>

                <Typography
                  onClick={() => setExpanded(!expanded)}
                  sx={{
                    fontSize: "12px",
                    color: "#222",
                    cursor: "pointer",
                    textAlign: "end",
                    mt: "16px",
                  }}
                >
                  {expanded ? "Hide " : "View "}
                  details
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}

      <RoundFlightDetails
        pricebreakdown={pricebreakdown}
        segments={segments}
        expanded={expanded}
        roundData={props.roundData}
      />
    </Box>
  );
};

export default RoundSingleFlight;
