import { useState } from "react";
import {
  Button,
  Collapse,
  Grid,
  Stack,
  Tab,
  Typography,
  Box,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import WorkIcon from "@mui/icons-material/Work";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import FlightIcon from "@mui/icons-material/Flight";
import React from "react";
import { format } from "date-fns";
import "./RoundFlightInformationDetails.css";
import RoundTransit from "../SingleFlight/RoundTransit";
import triangle from "../../images/Icon/triangle.png";
import commaNumber from "comma-number";
import AirLineName from "../SingleFlight/AirLineName";
import FarePolicy from "../FarePolicy/FarePolicy";
import Transit from "../SingleFlight/Transit";

const RoundFlightInformationDetails = ({ flightData, location }) => {
  console.log(flightData);

  const clientFare = Math.round(Number.parseInt(flightData?.clientPrice));
  const agentFare = Math.round(Number.parseInt(flightData?.price));
  const commission = Math.round(clientFare - agentFare);

  const [value, setValue] = useState("1");
  const [flightDetails, setFlightDetails] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showFlight, setShowFlight] = useState(true);
  const {
    backarrival,
    backarrivalDate,
    backarrivalTime,
    backdeparture,
    backdepartureDate,
    backdepartureTime,
    backflightduration,
    bags,
    career,
    careerName,
    goarrival,
    goarrivalDate,
    goarrivalTime,
    godeparture,
    godepartureDate,
    godepartureTime,
    goflightduration,
    refundable,
    price,
    Taxes,
    seat,
    segment,
    segments,
    stop,
    system,
    transit,
    bookingcode,
    BasePrice,
    pricebreakdown,
    adultCount,
    childCount,
    infant,
    clientPrice,
  } = flightData;

  // Unique Flight Code filter
  const goMarcketingCareerCode = segments.go
    .map((data) => data?.marketingcareer)
    .filter((value, index, arr) => arr.indexOf(value) === index);
  const backMarcketingCareerCode = segments.back
    .map((data) => data?.marketingcareer)
    .filter((value, index, arr) => arr.indexOf(value) === index);

  const [showGo, setShowGo] = useState(true);
  // console.log(flightData);
  //todo: calculate total flight duration
  const calDuration = (arr) => {
    const convertTime = arr.map(
      (item) =>
        parseInt(item.split(" ")[0]) * 3600 * 1000 +
        parseInt(item.split(" ")[1]) * 60 * 1000
    );
    const milliseconds = convertTime.reduce((cur, acc) => cur + acc, 0);
    let seconds = Math.floor(milliseconds / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    seconds = seconds % 60;
    minutes = seconds >= 30 ? minutes + 1 : minutes;
    minutes = minutes % 60;
    hours = hours % 24;
    return `${hours.toString().padStart(2, 0)}H:${minutes
      .toString()
      .padStart(2, 0)}Min`;
  };

  return (
    <Box
      sx={{
        marginBottom: "15px",
        borderRadius: "5px",
      }}
    >
      {/* //todo:mobile Responsive Section */}

      {/* //todo:for mobile End--------- */}

      {/* //todo: Desktop Design Section */}
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "block", lg: "block" },
        }}
      >
        <Grid container>
          <Grid
            container
            alignItems="center"
            sm={10}
            md={10}
            lg={10}
            sx={{ height: "100%", padding: "15px 5px" }}
            rowGap="5px"
          >
            {/*//todo:Go start*/}
            <Grid container px={2}>
              {/* //todo: one */}
              <Grid item md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  {/* //todo: Image Part */}
                  <Box>
                    {goMarcketingCareerCode.map((data) => (
                      <img
                        src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data}.png`}
                        width="50px"
                        height="50px"
                        className={`${system
                          ?.toLowerCase()
                          ?.split(" ")
                          ?.join("-")}-border`}
                        alt={`${segments.go[0]?.marketingcareer}`}
                      />
                    ))}
                  </Box>
                  {/* //todo: Text Part */}
                  <Box width="90%">
                    <Tooltip
                      title={`${segments.go
                        .map((data) => data?.marketingcareerName)
                        .filter(
                          (value, index, arr) => arr.indexOf(value) === index
                        )
                        .join(", ")}`}
                    >
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "13px",
                            cursor: "pointer",
                          },
                        }}
                        noWrap
                      >{`${segments.go
                        .map((data) => data?.marketingcareerName)
                        .filter(
                          (value, index, arr) => arr.indexOf(value) === index
                        )
                        .join(", ")}`}</Typography>
                    </Tooltip>
                    <Tooltip
                      title={`${segments.go
                        .map(
                          (data) =>
                            `${
                              data.marketingcareer === data.operatingcareer
                                ? `${data.marketingcareer}-${data.marketingflight}`
                                : `${data.operatingcareer}-${data.operatingflight}`
                            }`
                        )
                        .join(", ")}`}
                    >
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "12px",
                            cursor: "pointer",
                          },
                        }}
                        noWrap
                      >
                        {`${segments.go
                          .map(
                            (data) =>
                              `${
                                data.marketingcareer === data.operatingcareer
                                  ? `${data.marketingcareer}-${data.marketingflight}`
                                  : `${data.operatingcareer}-${data.operatingflight}`
                              }`
                          )
                          .join(", ")}`}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3}>
                <Box
                  style={{
                    width: "100%",
                  }}
                >
                  {/* //todo:Left Side */}

                  {/* //todo:Right Side */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      gap: "1px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--third-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "12px",
                        },
                      }}
                    >
                      Depart
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--mateBlack)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "15px",
                          sm: "18px",
                          md: "22px",
                        },
                      }}
                    >
                      {godeparture}
                    </Typography>
                    <Tooltip title={`${segments.go[0]?.departureAirport}`}>
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "13px",
                          },
                          cursor: "pointer",
                          width: "97%",
                        }}
                        noWrap
                      >
                        {segments.go[0]?.departureAirport}
                      </Typography>
                    </Tooltip>
                    <Typography
                      sx={{
                        color: "var(--mateBlack)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                      }}
                    >
                      {godepartureTime?.slice(0, 5)}
                    </Typography>

                    <Typography
                      sx={{
                        color: "var(--third-color)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                      }}
                    >
                      {godepartureDate}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {/* //todo:two */}
              <Grid
                xs={6}
                sm={6}
                md={2.9}
                textAlign={"center"}
                margin={{ xs: "10px 0", md: "0" }}
              >
                <RoundTransit flightData={flightData} goflight={"1"} />
              </Grid>
              {/* //todo:Three */}
              <Grid item md={3}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "1px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--third-color)",
                      fontWeight: 400,

                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "12px",
                      },
                    }}
                  >
                    Arrival
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--mateBlack)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "22px",
                      },
                    }}
                  >
                    {`${goarrival}`}
                  </Typography>
                  <Tooltip
                    title={`${
                      segments.go[segments.go?.length - 1]?.arrivalAirport
                    }`}
                  >
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        textAlign: "right",
                        fontWeight: 400,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                        width: "100%",
                        cursor: "pointer",
                      }}
                      noWrap
                    >
                      {segments.go[segments.go?.length - 1]?.arrivalAirport}
                    </Typography>
                  </Tooltip>

                  <Typography
                    sx={{
                      color: "var(--mateBlack)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                      },
                    }}
                  >
                    {goarrivalTime}
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--third-color)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                      },
                    }}
                  >
                    {goarrivalDate}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/*//todo:Go End*/}

            {/*//todo:Return Start*/}
            <Grid container px={2} pt={2}>
              {/* //todo: one */}
              <Grid item md={3}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "start",
                  }}
                >
                  {/* //todo: Image Part */}
                  <Box>
                    {backMarcketingCareerCode.map((data) => (
                      <img
                        src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${data}.png`}
                        width="70px"
                        height="70px"
                        className={`${system
                          ?.toLowerCase()
                          ?.split(" ")
                          ?.join("-")}-border`}
                        alt={`${segments.back[0]?.marketingcareer}`}
                      />
                    ))}
                  </Box>
                  {/* //todo: Text Part */}
                  <Box width="90%">
                    <Tooltip
                      title={`${segments.back
                        .map((data) => data?.marketingcareerName)
                        .filter(
                          (value, index, arr) => arr.indexOf(value) === index
                        )
                        .join(", ")}`}
                    >
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "13px",
                            cursor: "pointer",
                          },
                        }}
                        noWrap
                      >{`${segments.back
                        .map((data) => data?.marketingcareerName)
                        .filter(
                          (value, index, arr) => arr.indexOf(value) === index
                        )
                        .join(", ")}`}</Typography>
                    </Tooltip>

                    <Tooltip
                      title={`${segments.back
                        .map(
                          (data) =>
                            `${
                              data.marketingcareer === data.operatingcareer
                                ? `${data.marketingcareer}-${data.marketingflight}`
                                : `${data.operatingcareer}-${data.operatingflight}`
                            }`
                        )
                        .join(", ")}`}
                    >
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "12px",
                            cursor: "pointer",
                          },
                        }}
                        noWrap
                      >
                        {`${segments.back
                          .map(
                            (data) =>
                              `${
                                data.marketingcareer === data.operatingcareer
                                  ? `${data.marketingcareer}-${data.marketingflight}`
                                  : `${data.operatingcareer}-${data.operatingflight}`
                              }`
                          )
                          .join(", ")}`}
                      </Typography>
                    </Tooltip>
                  </Box>
                </Box>
              </Grid>

              <Grid item md={3}>
                <Box
                  style={{
                    width: "100%",
                  }}
                >
                  {/* //todo:Left Side */}

                  {/* //todo:Right Side */}
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "start",
                      alignItems: "start",
                      gap: "1px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "var(--third-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "12px",
                        },
                      }}
                    >
                      Depart
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--mateBlack)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "15px",
                          sm: "18px",
                          md: "22px",
                        },
                      }}
                    >
                      {backdeparture}
                    </Typography>
                    <Tooltip title={`${segments.back[0]?.departureAirport}`}>
                      <Typography
                        sx={{
                          color: "var(--primary-color)",
                          fontWeight: 400,
                          fontSize: {
                            xs: "10px",
                            sm: "12px",
                            md: "13px",
                          },
                          cursor: "pointer",
                          width: "97%",
                        }}
                        noWrap
                      >
                        {segments.back[0]?.departureAirport}
                      </Typography>
                    </Tooltip>
                    <Typography
                      sx={{
                        color: "var(--mateBlack)",
                        fontWeight: 500,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                      }}
                    >
                      {backdepartureTime?.slice(0, 5)}
                    </Typography>

                    <Typography
                      sx={{
                        color: "var(--third-color)",
                        fontWeight: 400,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                      }}
                    >
                      {backdepartureDate}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              {/* //todo:two */}
              <Grid item md={2.9}>
                <RoundTransit flightData={flightData} backflight={"1"} />
              </Grid>
              {/* //todo:Three */}
              <Grid item md={3}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "end",
                    alignItems: "end",
                    gap: "1px",
                  }}
                >
                  <Typography
                    sx={{
                      color: "var(--third-color)",
                      fontWeight: 400,

                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "12px",
                      },
                    }}
                  >
                    Arrival
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--mateBlack)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "12px",
                        sm: "13px",
                        md: "22px",
                      },
                    }}
                  >
                    {`${backarrival}`}
                  </Typography>
                  <Tooltip
                    title={`${
                      segments.back[segments.back?.length - 1]?.arrivalAirport
                    }`}
                  >
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        textAlign: "right",
                        fontWeight: 400,
                        fontSize: {
                          xs: "10px",
                          sm: "12px",
                          md: "13px",
                        },
                        width: "100%",
                        cursor: "pointer",
                      }}
                      noWrap
                    >
                      {segments.back[segments.back?.length - 1]?.arrivalAirport}
                    </Typography>
                  </Tooltip>

                  <Typography
                    sx={{
                      color: "var(--mateBlack)",
                      fontWeight: 500,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                      },
                    }}
                  >
                    {backarrivalTime}
                  </Typography>

                  <Typography
                    sx={{
                      color: "var(--third-color)",
                      fontWeight: 400,
                      fontSize: {
                        xs: "10px",
                        sm: "12px",
                        md: "13px",
                      },
                    }}
                  >
                    {backarrivalDate}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            {/*//todo:Return End*/}
          </Grid>
          {/* //todo: price section */}
          <Grid item md={2} pr={2}>
            <Box
              sx={{
                // background: "var(--primary-color)",
                height: "100%",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "space-between",
                // borderLeft: "5px solid var(--input-bgcolor)",
              }}
            >
              <Box
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "end",
                  flexDirection: "column",
                  padding: "10px",
                }}
              >
                <Typography>
                  {(() => {
                    if (refundable === "Refundable") {
                      return (
                        <Typography
                          sx={{
                            color: "var(--green-color)",
                            paddingLeft: "13px",
                            fontWeight: 400,
                            fontSize: {
                              xs: "12px",
                              sm: "13px",
                              md: "14px",
                            },
                          }}
                        >
                          {refundable}
                        </Typography>
                      );
                    } else if (
                      refundable === "Nonrefundable" ||
                      refundable === "Non Refundable"
                    ) {
                      return (
                        <Typography
                          sx={{
                            color: "var(--tomato-color)",
                            fontWeight: 400,
                            paddingLeft: "13px",
                            fontSize: {
                              xs: "12px",
                              sm: "12px",
                              md: "14px",
                              lg: "16px",
                            },
                          }}
                        >
                          Non Refundable
                        </Typography>
                      );
                    }
                  })()}
                </Typography>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontWeight: 400,
                    fontSize: {
                      xs: "12px",
                      sm: "12px",
                      md: "14px",
                      lg: "12px",
                    },
                  }}
                >
                  {bags.length > 6 ? (
                    <>Baggage{bags || 0}</>
                  ) : parseInt(bags) > 4 ? (
                    <>Baggage {bags || 0} Kg</>
                  ) : bags === "2 N" ? (
                    <>2 N</>
                  ) : bags === "2 P" ? (
                    <>2 P</>
                  ) : parseInt(bags) < 4 ? (
                    <>{parseInt(bags)} Piece</>
                  ) : parseInt(bags) === NaN ? (
                    <>{bags}</>
                  ) : (
                    <>  0Kg</>
                  )}
                </Typography>
              </Box>
              <Button
                style={{
                  color: "var(--primary-color)",
                  background: "var(--secondary-color)",
                  fontWeight: 400,
                  textTransform: "capitalize",
                  padding: "0px",
                  fontSize: "12px",
                  width: "90%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginLeft: "8px",
                  marginBottom: "18px",
                }}
                onClick={() => setFlightDetails(!flightDetails)}
              >
                {!flightDetails ? (
                  <Typography
                    style={{
                      color: "var(--mateBlack)",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      fontSize: "13px",
                    }}
                  >
                    View Detail
                    <ArrowDropDownIcon style={{ width: "fit-content" }} />
                  </Typography>
                ) : (
                  <Typography
                    style={{
                      color: "var(--mateBlack)",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                      fontSize: "13px",
                    }}
                  >
                    Hide Detail <ArrowDropUpIcon />
                  </Typography>
                )}
              </Button>
            </Box>
          </Grid>
        </Grid>
        {/* //todo:Flight Details start------------ */}
        <Collapse
          in={flightDetails}
          timeout="auto"
          unmountOnExit
          sx={{ width: "100%", marginBottom: "0px" }}
        >
          <Box sx={{ width: "96%", margin: "15px auto" }}>
            <Box
              sx={{
                width: "100%",
              }}
              my={3}
            >
              <hr />
            </Box>
            <TabContext value={value}>
              <Box
                sx={{
                  width: "100%",
                  height: {
                    lg: "30px",
                    md: "30px",
                    sm: "30px",
                    xs: "30px",
                  },
                  minHeight: "100%",
                  overflow: "hidden",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  opacity: "1",
                  borderRadius: "3px",
                  ".MuiTabs-flexContainer": {
                    flexWrap: "wrap",
                    padding: {
                      lg: "0px",
                      md: "0px",
                      sm: "0px 10px",
                      xs: "0px 10px",
                    },
                  },
                  "& button": {
                    // background: "var(--secondary-color)",
                    color: "var(--primary-color)",
                    opacity: "1",
                    fontWeight: "400",
                    textTransform: "capitalize",
                    fontSize: "12px",
                  },

                  "& button.Mui-selected": {
                    background: "var(--secondary-color)",
                    color: "var(--primary-color)",
                    opacity: "1",
                    fontSize: "12px",
                    fontWeight: 500,
                    borderRadius: "12px",
                    textTransform: "capitalize",
                  },
                }}
              >
                <TabList
                  value={value}
                  onChange={handleChange}
                  TabIndicatorProps={{ style: { display: "none" } }}
                >
                  <Tab label="Flight Details" value="1" />
                  <Tab label="Fare Details" value="2" />
                  <Tab label="Commission & Invoice" value="3" />
                  <Tab label="Fare Policy" value="4" />
                  <Tab label="Baggage" value="5" />
                </TabList>
              </Box>

              <TabPanel className="tabs-details" value="1">
                {/* Flight Details */}
                <Tooltip
                  title={
                    showFlight
                      ? "Click to See Return Flight"
                      : "Click to See Depart Flight"
                  }
                >
                  <Button
                    sx={{
                      width: "150px",
                      background: "var(--input-bgcolor)",
                      color: "var(--primary-color)",
                      fontWeight: 400,
                      gap: 2,
                      "&:hover": {
                        background: "var(--input-bgcolor)",
                        color: "var(--primary-color)",
                        fontWeight: 400,
                      },
                    }}
                    onClick={() => setShowFlight((prev) => !prev)}
                  >
                    {showFlight ? (
                      <>
                        {godeparture}{" "}
                        <FlightIcon
                          sx={{
                            transform: "rotate(90deg)",
                            color: "var(--primary-color)",
                          }}
                        />{" "}
                        {goarrival}
                      </>
                    ) : (
                      <>
                        {backdeparture}{" "}
                        <FlightIcon
                          sx={{
                            transform: "rotate(90deg)",
                            color: "var(--primary-color)",
                          }}
                        />{" "}
                        {backarrival}
                      </>
                    )}
                  </Button>
                </Tooltip>
                {showFlight ? (
                  <>
                    {segments.go.map((data, index, arr) => (
                      <Box>
                        <Box className="single-flight-parent">
                          <Grid
                            className="single-flight-details"
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                            container
                            // spacing={{ xs: 2, md: 3 }}
                            // columns={{ xs: 4, sm: 8, md: 12  }}
                          >
                            <Grid item md={3}>
                              <Box
                                sx={{
                                  // width: "20%",
                                  display: "flex",
                                  flexDirection: "column",

                                  alignItems: "start",
                                  // gap: "10px",
                                }}
                              >
                                {/* //todo: Image Part */}
                                <Box>
                                  <img
                                    width="50px"
                                    height="50px"
                                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${
                                      data.marketingcareer ===
                                      data.operatingcareer
                                        ? data?.marketingcareer
                                        : data?.operatingcareer
                                    }.png`}
                                    alt={`${data.marketingcareer}`}
                                  />
                                </Box>
                                {/* //todo: Text Part */}
                                <Box width="90%">
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                        cursor: "pointer",
                                      },
                                      // width: "80%",
                                    }}
                                  >
                                    {data.marketingcareer ===
                                    data.operatingcareer ? (
                                      data?.marketingcareerName
                                    ) : (
                                      <AirLineName
                                        code={data?.operatingcareer}
                                      />
                                    )}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                    noWrap
                                  >
                                    {data.marketingcareer ===
                                    data.operatingcareer ? (
                                      <>
                                        {data.marketingcareer}&nbsp;
                                        {data.marketingflight}
                                      </>
                                    ) : (
                                      <>
                                        {data.operatingcareer}&nbsp;
                                        {data.operatingflight}
                                      </>
                                    )}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      display: "inline ",
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                      },
                                    }}
                                  >
                                    Operated by:{data?.operatingcareer}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item md={3}>
                              <Box
                                style={{
                                  width: "100%",
                                }}
                              >
                                {/* //todo:Left Side */}

                                {/* //todo:Right Side */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    alignItems: "start",
                                    gap: "1px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "var(--third-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                      },
                                    }}
                                  >
                                    Depart
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "15px",
                                        sm: "18px",
                                        md: "22px",
                                      },
                                    }}
                                  >
                                    {data?.departure}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                      },
                                    }}
                                  >
                                    {data?.departureLocation}
                                  </Typography>

                                  <Tooltip title={data?.departureAirport}>
                                    <Typography
                                      sx={{
                                        color: "var(--primary-color)",
                                        fontWeight: 400,
                                        fontSize: {
                                          xs: "10px",
                                          sm: "12px",
                                          md: "13px",
                                        },
                                        cursor: "pointer",
                                        width: "97%",
                                      }}
                                      noWrap
                                    >
                                      {data?.departureAirport}
                                    </Typography>
                                  </Tooltip>

                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                      },
                                    }}
                                  >
                                    {format(
                                      new Date(data?.departureTime.toString()),
                                      "dd MMM yyyy hh:mm a"
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            {/* //todo:two */}
                            <Grid item md={2.5}>
                              <Transit flightData={data} />
                            </Grid>
                            {/* //todo:Three */}
                            <Grid item md={3}>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "end",
                                  alignItems: "end",
                                  gap: "1px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--third-color)",
                                    fontWeight: 400,

                                    fontSize: {
                                      xs: "11px",
                                      sm: "11px",
                                      md: "11px",
                                    },
                                  }}
                                >
                                  Arrival
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "22px",
                                    },
                                  }}
                                >
                                  {data?.arrival}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "12px",
                                      md: "12px",
                                    },
                                  }}
                                >
                                  {data?.arrivalLocation}
                                </Typography>
                                <Tooltip title={data?.arrivalAirport}>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      textAlign: "right",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "12px",
                                        md: "12px",
                                      },
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                    noWrap
                                  >
                                    {data?.arrivalAirport}
                                  </Typography>
                                </Tooltip>

                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "12px",
                                      md: "12px",
                                    },
                                  }}
                                >
                                  {format(
                                    new Date(data?.arrivalTime.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          style={{
                            display:
                              index === arr.length - 1 ? "none" : "block",
                          }}
                          pt={2}
                        >
                          {index === 0 && (
                            <>
                              <Box
                                sx={{
                                  zIndex: -1,
                                  width: "100%",
                                  borderTop: "1px dashed var(--loan)",
                                  mb: "-23px",
                                }}
                              ></Box>
                              <span
                                style={{
                                  color: "var(--primary-color)",
                                  backgroundColor: "var(--transit-color)",
                                  padding: "2px 20px",
                                  width: "250px",
                                  margin: "10px auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  zIndex: 1,
                                  fontSize: "12px",
                                }}
                              >
                                Transit Time: {transit?.go?.transit1}
                              </span>
                            </>
                          )}
                          {index === 1 && (
                            <>
                              <Box
                                sx={{
                                  zIndex: -1,
                                  width: "100%",
                                  borderTop: "1px dashed var(--loan)",
                                  mb: "-23px",
                                }}
                              ></Box>
                              <span
                                style={{
                                  backgroundColor: "var(--primary-color)",
                                  colro: "var(--transit-color)",
                                  padding: "2px 20px",
                                  width: "200px",
                                  margin: "10px auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  zIndex: 1,
                                  fontSize: "12px",
                                }}
                              >
                                Transit Time: {transit?.go?.transit2}
                              </span>
                            </>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </>
                ) : (
                  <>
                    {segments.back.map((data, index, arr) => (
                      <Box>
                        <Box className="single-flight-parent">
                          <Grid
                            className="single-flight-details"
                            sx={{
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                            }}
                            container
                            // spacing={{ xs: 2, md: 3 }}
                            // columns={{ xs: 4, sm: 8, md: 12  }}
                          >
                            <Grid item md={3}>
                              <Box
                                sx={{
                                  // width: "20%",
                                  display: "flex",
                                  flexDirection: "column",

                                  alignItems: "start",
                                  // gap: "10px",
                                }}
                              >
                                {/* //todo: Image Part */}
                                <Box>
                                  <img
                                    width="50px"
                                    height="50px"
                                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${
                                      data.marketingcareer ===
                                      data.operatingcareer
                                        ? data?.marketingcareer
                                        : data?.operatingcareer
                                    }.png`}
                                    alt={`${data.marketingcareer}`}
                                  />
                                </Box>
                                {/* //todo: Text Part */}
                                <Box width="90%">
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "14px",
                                        md: "14px",
                                        cursor: "pointer",
                                      },
                                      // width: "80%",
                                    }}
                                  >
                                    {data.marketingcareer ===
                                    data.operatingcareer ? (
                                      data?.marketingcareerName
                                    ) : (
                                      <AirLineName
                                        code={data?.operatingcareer}
                                      />
                                    )}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "10px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                    noWrap
                                  >
                                    {data.marketingcareer ===
                                    data.operatingcareer ? (
                                      <>
                                        {data.marketingcareer}&nbsp;
                                        {data.marketingflight}
                                      </>
                                    ) : (
                                      <>
                                        {data.operatingcareer}&nbsp;
                                        {data.operatingflight}
                                      </>
                                    )}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      display: "inline ",
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "13px",
                                        md: "12px",
                                      },
                                    }}
                                  >
                                    Operated by:{data?.operatingcareer}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            <Grid item md={3}>
                              <Box
                                style={{
                                  width: "100%",
                                }}
                              >
                                {/* //todo:Left Side */}

                                {/* //todo:Right Side */}
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "start",
                                    alignItems: "start",
                                    gap: "1px",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      color: "var(--third-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "11px",
                                        sm: "11px",
                                        md: "11px",
                                      },
                                    }}
                                  >
                                    Depart
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "13px",
                                        md: "22px",
                                      },
                                    }}
                                  >
                                    {data?.departure}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "12px",
                                        md: "12px",
                                      },
                                    }}
                                  >
                                    {data?.departureLocation}
                                  </Typography>

                                  <Tooltip title={data?.departureAirport}>
                                    <Typography
                                      sx={{
                                        color: "var(--primary-color)",
                                        fontWeight: 400,
                                        fontSize: {
                                          xs: "12px",
                                          sm: "12px",
                                          md: "12px",
                                        },
                                        cursor: "pointer",
                                        width: "97%",
                                      }}
                                      noWrap
                                    >
                                      {data?.departureAirport}
                                    </Typography>
                                  </Tooltip>

                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "12px",
                                        md: "12px",
                                      },
                                    }}
                                  >
                                    {format(
                                      new Date(data?.departureTime.toString()),
                                      "dd MMM yyyy hh:mm a"
                                    )}
                                  </Typography>
                                </Box>
                              </Box>
                            </Grid>

                            {/* //todo:two */}
                            <Grid item md={2.5}>
                              <Transit flightData={data} />
                            </Grid>

                            {/* //todo:Three */}
                            <Grid item md={3}>
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "end",
                                  alignItems: "end",
                                  gap: "1px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--third-color)",
                                    fontWeight: 400,

                                    fontSize: {
                                      xs: "14px",
                                      sm: "14px",
                                      md: "14px",
                                    },
                                  }}
                                >
                                  Arrival
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "13px",
                                      md: "22px",
                                    },
                                  }}
                                >
                                  {data?.arrival}
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "12px",
                                      md: "12px",
                                    },
                                  }}
                                >
                                  {data?.arrivalLocation}
                                </Typography>
                                <Tooltip title={data?.arrivalAirport}>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      textAlign: "right",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "12px",
                                        sm: "12px",
                                        md: "12px",
                                      },
                                      width: "100%",
                                      cursor: "pointer",
                                    }}
                                    noWrap
                                  >
                                    {data?.arrivalAirport}
                                  </Typography>
                                </Tooltip>

                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "12px",
                                      sm: "12px",
                                      md: "12px",
                                    },
                                  }}
                                >
                                  {format(
                                    new Date(data?.arrivalTime.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )}
                                </Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>

                        <Box
                          style={{
                            display:
                              index === arr.length - 1 ? "none" : "block",
                          }}
                        >
                          {index === 0 && (
                            <>
                              <span
                                style={{
                                  color: "var(--primary-color)",
                                  backgroundColor: "var(--transit-color)",
                                  padding: "2px 20px",
                                  width: "250px",
                                  margin: " auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  zIndex: 1,
                                  fontSize: "12px",
                                }}
                              >
                                Transit Time: {transit?.go?.transit1}
                              </span>
                            </>
                          )}
                          {index === 1 && (
                            <>
                              <Box
                                sx={{
                                  zIndex: -1,
                                  width: "100%",
                                  borderTop: "1px dashed var(--loan)",
                                  mb: "-23px",
                                }}
                              ></Box>
                              <span
                                style={{
                                  color: "var(--primary-color)",
                                  backgroundColor: "var(--transit-color)",
                                  padding: "2px 20px",
                                  width: "200px",
                                  margin: "10px auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  zIndex: 1,
                                  fontSize: "12px",
                                }}
                              >
                                Transit Time: {transit?.go?.transit2}
                              </span>
                            </>
                          )}
                        </Box>
                      </Box>
                    ))}
                  </>
                )}
              </TabPanel>
              <TabPanel value="2" className="tab-class">
                <Box className="tab-table" sx={{ m: "5px 0px" }}>
                  <Box className="flight-search-table">
                    <table>
                      <tr>
                        <th>Pax Type</th>
                        <th>Base Fare</th>
                        <th>Tax</th>
                        <th>Total</th>
                        <th>Pax Count</th>
                        <th>Service Fee</th>
                        <th>Sub Total</th>
                      </tr>

                      {adultCount > 0 && childCount > 0 && infant > 0 ? (
                        <>
                          <tr>
                            <td>Adult</td>
                            <td>{pricebreakdown[0].BaseFare}&nbsp;&#2547;</td>
                            <td>{pricebreakdown[0]?.Tax}&nbsp;&#2547;</td>
                            <td>
                              {parseInt(pricebreakdown[0]?.BaseFare) +
                                parseInt(pricebreakdown[0]?.Tax)}
                              &nbsp;&#2547;
                            </td>
                            <td>{pricebreakdown[0]?.PaxCount}</td>
                            <td>
                              {pricebreakdown[0]?.ServiceFee}&nbsp;&#2547;
                            </td>
                            <td>
                              {(parseInt(pricebreakdown[0]?.BaseFare) +
                                parseInt(pricebreakdown[0]?.Tax) +
                                parseInt(pricebreakdown[0]?.ServiceFee)) *
                                pricebreakdown[0]?.PaxCount}
                              &nbsp;&#2547;
                            </td>
                          </tr>
                          <tr>
                            <td>Child</td>
                            <td>{pricebreakdown[1]?.BaseFare}&nbsp;&#2547;</td>
                            <td>{pricebreakdown[1]?.Tax}&nbsp;&#2547;</td>
                            <td>
                              {parseInt(pricebreakdown[1]?.BaseFare) +
                                parseInt(pricebreakdown[1]?.Tax)}
                              &nbsp;&#2547;
                            </td>
                            <td>{pricebreakdown[1]?.PaxCount}</td>

                            <td>
                              {pricebreakdown[1]?.ServiceFee}&nbsp;&#2547;
                            </td>
                            <td>
                              {(parseInt(pricebreakdown[1]?.BaseFare) +
                                parseInt(pricebreakdown[1]?.Tax) +
                                parseInt(pricebreakdown[1]?.ServiceFee)) *
                                pricebreakdown[1]?.PaxCount}
                              &nbsp;&#2547;
                            </td>
                          </tr>
                          <tr>
                            <td>Infant</td>
                            <td>{pricebreakdown[2]?.BaseFare}&nbsp;&#2547;</td>
                            <td>{pricebreakdown[2]?.Tax}&nbsp;&#2547;</td>
                            <td>
                              {parseInt(pricebreakdown[2]?.BaseFare) +
                                parseInt(pricebreakdown[2]?.Tax)}
                              &nbsp;&#2547;
                            </td>
                            <td>{pricebreakdown[2]?.PaxCount}</td>

                            <td>
                              {pricebreakdown[2]?.ServiceFee}&nbsp;&#2547;
                            </td>
                            <td>
                              {(parseInt(pricebreakdown[2]?.BaseFare) +
                                parseInt(pricebreakdown[2]?.Tax) +
                                parseInt(pricebreakdown[2]?.ServiceFee)) *
                                pricebreakdown[2]?.PaxCount}
                              &nbsp;&#2547;
                            </td>
                          </tr>
                        </>
                      ) : adultCount > 0 && childCount > 0 ? (
                        <>
                          <tr>
                            <td>Adult</td>
                            <td>{pricebreakdown[0].BaseFare}&nbsp;&#2547;</td>
                            <td>{pricebreakdown[0]?.Tax}&nbsp;&#2547;</td>
                            <td>
                              {parseInt(pricebreakdown[0]?.BaseFare) +
                                parseInt(pricebreakdown[0]?.Tax)}
                              &nbsp;&#2547;
                            </td>
                            <td>{pricebreakdown[0]?.PaxCount}</td>
                            <td>
                              {pricebreakdown[0]?.ServiceFee}&nbsp;&#2547;
                            </td>
                            <td>
                              {(parseInt(pricebreakdown[0]?.BaseFare) +
                                parseInt(pricebreakdown[0]?.Tax) +
                                parseInt(pricebreakdown[0]?.ServiceFee)) *
                                pricebreakdown[0]?.PaxCount}
                              &nbsp;&#2547;
                            </td>
                          </tr>

                          <tr>
                            <td>Child</td>
                            <td>{pricebreakdown[1]?.BaseFare}&nbsp;&#2547;</td>
                            <td>{pricebreakdown[1]?.Tax}&nbsp;&#2547;</td>
                            <td>
                              {parseInt(pricebreakdown[1]?.BaseFare) +
                                parseInt(pricebreakdown[1]?.Tax)}
                              &nbsp;&#2547;
                            </td>
                            <td>{pricebreakdown[1]?.PaxCount}</td>

                            <td>
                              {pricebreakdown[1]?.ServiceFee}&nbsp;&#2547;
                            </td>
                            <td>
                              {(parseInt(pricebreakdown[1]?.BaseFare) +
                                parseInt(pricebreakdown[1]?.Tax) +
                                parseInt(pricebreakdown[1]?.ServiceFee)) *
                                pricebreakdown[1]?.PaxCount}
                              &nbsp;&#2547;
                            </td>
                          </tr>
                        </>
                      ) : adultCount > 0 && infant > 0 ? (
                        <>
                          <tr>
                            <td>Adult</td>
                            <td>{pricebreakdown[0].BaseFare}&nbsp;&#2547;</td>
                            <td>{pricebreakdown[0]?.Tax}&nbsp;&#2547;</td>
                            <td>
                              {parseInt(pricebreakdown[0]?.BaseFare) +
                                parseInt(pricebreakdown[0]?.Tax)}
                              &nbsp;&#2547;
                            </td>
                            <td>{pricebreakdown[0]?.PaxCount}</td>
                            <td>
                              {pricebreakdown[0]?.ServiceFee}&nbsp;&#2547;
                            </td>
                            <td>
                              {(parseInt(pricebreakdown[0]?.BaseFare) +
                                parseInt(pricebreakdown[0]?.Tax) +
                                parseInt(pricebreakdown[0]?.ServiceFee)) *
                                pricebreakdown[0]?.PaxCount}
                              &nbsp;&#2547;
                            </td>
                          </tr>
                          <tr>
                            <td>Infant</td>
                            <td>{pricebreakdown[1]?.BaseFare}&nbsp;&#2547;</td>
                            <td>{pricebreakdown[1]?.Tax}&nbsp;&#2547;</td>
                            <td>
                              {parseInt(pricebreakdown[1]?.BaseFare) +
                                parseInt(pricebreakdown[1]?.Tax)}
                              &nbsp;&#2547;
                            </td>
                            <td>{pricebreakdown[1]?.PaxCount}</td>

                            <td>
                              {pricebreakdown[1]?.ServiceFee}&nbsp;&#2547;
                            </td>
                            <td>
                              {(parseInt(pricebreakdown[1]?.BaseFare) +
                                parseInt(pricebreakdown[1]?.Tax) +
                                parseInt(pricebreakdown[1]?.ServiceFee)) *
                                pricebreakdown[1]?.PaxCount}
                              &nbsp;&#2547;
                            </td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td>Adult</td>
                          <td>{pricebreakdown[0].BaseFare}&nbsp;&#2547;</td>
                          <td>{pricebreakdown[0]?.Tax}&nbsp;&#2547;</td>
                          <td>
                            {parseInt(pricebreakdown[0]?.BaseFare) +
                              parseInt(pricebreakdown[0]?.Tax)}
                            &nbsp;&#2547;
                          </td>
                          <td>{pricebreakdown[0]?.PaxCount}</td>
                          <td>{pricebreakdown[0]?.ServiceFee}&nbsp;&#2547;</td>
                          <td>
                            {(parseInt(pricebreakdown[0]?.BaseFare) +
                              parseInt(pricebreakdown[0]?.Tax) +
                              parseInt(pricebreakdown[0]?.ServiceFee)) *
                              pricebreakdown[0]?.PaxCount}
                            &nbsp;&#2547;
                          </td>
                        </tr>
                      )}
                    </table>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value="3" className="tab-class">
                <Box className="tab-table" sx={{ m: "5px 0px" }}>
                  <Box className="flight-search-table">
                    <table>
                      <tr>
                        <th>Customer Invoice</th>
                        <th>Commission</th>
                        <th>Agent Invoice</th>
                        <th>Profit Amount</th>
                      </tr>
                      <tr>
                        <td>{clientFare || 0}&nbsp;&#2547;</td>
                        <td>7%</td>
                        <td>
                          {agentFare || 0}
                          &nbsp;&#2547;
                        </td>
                        <td>
                          {commission || 0}
                          &nbsp;&#2547;
                        </td>
                      </tr>
                    </table>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value="4" className="cancelation-1">
                <FarePolicy />
              </TabPanel>
              <TabPanel value="5" className="tab-class">
                <Box className="tab-table" sx={{ m: "5px 0px" }}>
                  <Box className="flight-search-table">
                    <table>
                      <tr>
                        <th>Baggage</th>
                        <th>Check-In</th>
                        <th>Cabin</th>
                      </tr>

                      <tr>
                        <td>Adult</td>
                        <td>
                          {bags.length > 6 ? (
                            <>{bags || 0}</>
                          ) : parseInt(bags) > 4 ? (
                            <>{bags || 0} Kg</>
                          ) : bags === "2 N" ? (
                            <>2 N</>
                          ) : bags === "2 P" ? (
                            <>2 P</>
                          ) : parseInt(bags) < 4 ? (
                            <>{parseInt(bags)} Piece</>
                          ) : parseInt(bags) === NaN ? (
                            <>{bags}</>
                          ) : (
                            <>0 Kg</>
                          )}
                        </td>
                        <td>7 Kg</td>
                      </tr>
                      {childCount > 0 && (
                        <tr>
                          <td>Child</td>
                          <td>
                            {bags.length > 6 ? (
                              <>{bags || 0}</>
                            ) : parseInt(bags) > 4 ? (
                              <>{bags || 0} Kg</>
                            ) : bags === "2 N" ? (
                              <>2 N</>
                            ) : bags === "2 P" ? (
                              <>2 P</>
                            ) : parseInt(bags) < 4 ? (
                              <>{parseInt(bags)} Piece</>
                            ) : parseInt(bags) === NaN ? (
                              <>{bags}</>
                            ) : (
                              <>0 Kg</>
                            )}
                          </td>
                          <td>7 Kg</td>
                        </tr>
                      )}
                      {infant > 0 && (
                        <tr>
                          <td>Infant</td>
                          <td>0 Kg</td>
                          <td>7 Kg</td>
                        </tr>
                      )}
                    </table>
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Collapse>
      </Box>
      {/* //todo: Desktop Design Section */}
    </Box>
  );
};

export default RoundFlightInformationDetails;
