import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
  Divider,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Stack from "@mui/material/Stack";
import secureLocalStorage from "react-secure-storage";
import ArrowDropDownSharpIcon from "@mui/icons-material/ArrowDropDownSharp";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import HotelIcon from "@mui/icons-material/Hotel";
import flightData from "../flightData";
import Popup from "../Header/Popup";
const isMobile = window.innerWidth <= 768;

const HotelSearchBox = ({
  adultCount,
  setAdultCount,
  childCount,
  setChildCount,
  infant,
  setInfant,
  setResult,
  cInDate,
  cOutDate,
  desAddress,
  desCode,
}) => {
  const initialData = [
    {
      id: "802",
      code: "DAC",
      name: "Hazrat Shahjalal Intl Airport",
      cityCode: "DAC",
      cityName: "Dhaka",
      countryName: "BANGLADESH",
      countryCode: "BD",
      address: "Dhaka,Bangladesh",
    },
    {
      id: "931",
      code: "DXB",
      name: "Dubai Bus Station Intl Airport",
      cityCode: "DXB",
      cityName: "Dubai",
      countryName: "United Arab Emirates",
      countryCode: "AE",
      address: "Dubai,United Arab Emirates",
    },

    {
      id: "4403",
      code: "BZL",
      name: "Barishal Airport",
      cityCode: "BZL\r\n",
      cityName: "Barishal",
      countryName: "BANGLADESH",
      countryCode: "BD",
      address: "Barishal,Bangladesh",
    },
    {
      id: "4404",
      code: "JSR",
      name: "Jashore Airport",
      cityCode: "JSR\r\n",
      cityName: "Jashore",
      countryName: "BANGLADESH",
      countryCode: "BD",
      address: "Jashore,Bangladesh",
    },
    {
      id: "4405",
      code: "RJH",
      name: "Shah Makhdum Airport",
      cityCode: "RJH",
      cityName: "Rajshahi",
      countryName: "BANGLADESH",
      countryCode: "BD",
      address: "Rajshahi,Bangladesh",
    },
    {
      id: "4406",
      code: "SPD",
      name: "Saidpur Airport",
      cityCode: "SPD",
      cityName: "Saidpur",
      countryName: "BANGLADESH",
      countryCode: "BD",
      address: "Saidpur,Bangladesh",
    },
    {
      id: "4402",
      code: "CXB",
      name: "COX's Bazar Airport",
      cityCode: "CXB",
      cityName: "Chittagong",
      countryName: "BANGLADESH",
      countryCode: "BD",
      address: "Cox's Bazar,Bangladesh",
    },
  ];

  const [flightData, setFlightData] = useState([]);
  useEffect(() => {
    fetch(`https://api.flyfarint.com/v.1.0.0/Admin/Airports/index.php?all`)
      .then((res) => res.json())
      .then((data) => {
        setFlightData(data);
      });
  }, []);

  //todo: users section
  const data = flightData;
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.user?.agentId;
  const navigate = useNavigate();
  const location = useLocation();
  const [room, setRoom] = useState(1);
  const [totalGuest, setTotalGuest] = useState(1);
  let [book, setBook] = useState(false);
  const [suggestDestination, setSuggestDestination] = useState(initialData);
  const [destinationAddress, setDestinationAddress] = useState(
    desAddress ? desAddress : "Dhaka,Bangladesh"
  );
  const [destinationCountry, setDestinationCountry] = useState("CXB");
  const [openGuests, setOpenGuests] = useState(false);
  const [openDestination, setOpenDestination] = useState(false);
  const [openCheckIn, setOpenCheckIn] = useState(false);
  const [checkInDate, setCheckInDate] = useState(
    cInDate ? cInDate : new Date()
  );
  const [checkOutDate, setCheckOutDate] = useState(
    cOutDate ? cOutDate : new Date()
  );
  const [destinationCode, setDestinationCode] = useState(
    desCode ? desCode : "DAC"
  );
  const [openCheckOut, setOpenCheckOut] = useState(false);
  const today = new Date();
  const maxDate = new Date();
  maxDate.setFullYear(today.getFullYear() + 1);
  //todo: is Click state
  const [click, setClick] = useState(false);
  //todo: end of click state

  const destinationText = (countryName, code, address) => {
    setDestinationAddress(address);
    setDestinationCountry(countryName);
    setDestinationCode(code);
    setOpenDestination(false);
    setOpenCheckIn(true);
  };

  const destinationBox = () => {
    return (
      <Box
        sx={{
          height: "fit-content",
          position: "relative",
          width: "100%",
          zIndex: "100",
        }}
      >
        <Box
          sx={{
            maxHeight: "230px",
            overflowY: "auto",
            background: "transparent",
            boxShadow:
              "rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
            "&::-webkit-scrollbar": { width: "0px" },
          }}
        >
          {suggestDestination.length !== 0 ? (
            suggestDestination.map((item, index) => {
              return (
                <Box
                  sx={{
                    paddingLeft: "10px",
                    paddingRight: "5px",
                    backgroundColor: "var(--white)",
                    color: "var(--gray)",
                    transition: "all .5s ease-in-out",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      color: "var(--white)",
                    },
                    "&:hover .address": { color: "var(--white)" },
                    "&:hover .name": { color: "var(--white)" },
                    "&:hover .code": { color: "var(--white)" },
                  }}
                >
                  <Box
                    sx={{
                      margin: "0px 0px",
                      padding: "5px 0px",
                      cursor: "pointer",
                      display: "flex",
                      color: "var(--gray)",
                      // width: "100%",
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      destinationText(
                        item?.countryName,
                        item?.code,
                        item?.address
                      );
                    }}
                  >
                    <Box>
                      <Typography
                        className="address"
                        sx={{
                          fontSize: "16px",
                          color: "var(--primary-color)",
                          display: "block",
                          textAlign: "left",
                          fontWeight: 400,
                        }}
                      >
                        {item.address}
                      </Typography>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        className="code"
                        style={{
                          fontSize: "13px",
                          display: "block",
                          textAlign: "left",
                          paddingRight: "5px",
                          fontWeight: 400,
                        }}
                      >
                        {item.code}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box>
              <Typography
                variant="subtitle-2"
                style={{
                  color: "var(--primary-color)",
                  fontWidth: "bold",
                  paddingLeft: "10px",
                }}
              >
                Not found
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  const handleDestinationClick = (e) => {
    setOpenGuests(false);
    const searchvalue = e.target.value;

    if (searchvalue.length > 2) {
      const suggestion = flightData?.filter(
        (item) =>
          item?.cityCode?.toLowerCase()?.includes(searchvalue?.toLowerCase()) ||
          item?.code?.toLowerCase()?.includes(searchvalue?.toLowerCase()) ||
          item?.address?.toLowerCase()?.includes(searchvalue?.toLowerCase())
      );
      setSuggestDestination(suggestion);
    } else {
      setSuggestDestination(initialData);
    }
  };

  // Opens the dialog when the user clicks.
  const handleGuestBox = () => {
    setOpenGuests((prev) => !prev);
    setOpenDestination(false);
    setOpenCheckIn(false);
  };
  // Closes the child process.
  const handleCloseBox = () => {
    setOpenGuests(false);
    setTotalGuest(adultCount + childCount);
    setRoom(room);
  };

  function adultIncrement(e) {
    e.preventDefault();
    if (adultCount + childCount < 4) {
      setAdultCount(adultCount + 1);
    }
  }

  function adultDecrement(e) {
    e.preventDefault();
    if (adultCount > 1) {
      setAdultCount(adultCount - 1);
      if (infant === adultCount) {
        if (infant > 1) {
          setInfant(infant - 1);
        }
      }
    }
  }

  function childIncrement(e) {
    e.preventDefault();
    if (adultCount + childCount < 4) {
      setChildCount(childCount + 1);
    }
  }

  function childDecrement(e) {
    e.preventDefault();
    if (childCount > 0) {
      setChildCount(childCount - 1);
    }
  }

  const handleTest = () => {
    setCheckOutDate(true);
  };

  // Search Flight button click

  async function handleSearch(e) {
    e.preventDefault();

    if (user) {
      const searchData = {
        adultCount,
        childCount,
        room,
        destinationAddress,
        destinationCountry,
        checkInDate,
        checkOutDate,
        destinationCode,
        code: suggestDestination?.debug?.request?.query,
      };
      navigate("/dashboard/hotelresults", { state: searchData });
    } else {
      Swal.fire({
        title: "Please, Log in first",
        text: "Please log in first to see all the flights.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.isConfirmed) {
          setBook(true);
        }
      });
    }
  }

  return (
    <>
      <Box
        sx={{
          bgcolor: "white",
          px: location?.pathname === "/" ? (isMobile ? "10px" : "15px") : "0",
          py: isMobile ? "50px" : "35px",
     
      
          // pb: isMobile ? "60px" : "auto",
          // pt: isMobile ? "20px" : "auto",
          borderRadius: "4px",
        }}
      >
        <Grid
          item
          position="relative"
          sx={{ ml: location?.pathname === "/" ? "10px" : "0px" }}
        >
          <Stack
            direction={{xs:"column",sm:"column",md:"row",lg:"row"}}
            spacing={{ xs: 0, sm: 0, md: 5, lg: 5 }}
            justifyContent="flex-start"
          >
            <Typography
              sx={{
                color: "var(--light-gray)",
                fontWeight: 400,
                cursor: "pointer",
                position: "relative",
                fontSize: 13,
             
              }}
              onClick={handleGuestBox}
            >
              Guests & Rooms
              <ArrowDropDownSharpIcon
                sx={{
                  padding: 0,
                  fontSize: 30,
                  position: "absolute",
                  color: "var(--primary-color)",
                  top: "-6px",
                }}
              />
            </Typography>

            <Typography
              sx={{
                color: "var(--light-gray)",
                fontSize: "13px",
                fontWeight: "400",
          
              }}
            >
              {`${room} Rooms,${totalGuest} Guests`}{" "}
              <span
                style={{ color: "var(--primary-color)", fontWeight: "600" }}
              >
                |
              </span>
              &nbsp;
              {adultCount ? `${adultCount} Adults` : null}{" "}
              {childCount ? `${childCount} Children` : null}
            </Typography>
            <Typography sx={{ color: "gray", fontSize: "13px" }}></Typography>
          </Stack>
          {openGuests && (
            <Box
              sx={{
                position: "absolute",
                top: "100%",
                left: "0",
                background: "var(--white)",
                boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
                padding: "15px",
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "22%",
                  lg: "22%",
                },
                zIndex: "999",
                borderRadius: "5px",
              }}
            >
              <Box
                sx={{
                  my: "10px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                  ".MuiTypography-root": {
                    color: "var(--black)",
                    fontSize: "14px",
                    fontWeight: "500",
                  },
                  svg: {
                    fontSize: "14px",
                    color: "var(--black)",
                  },
                  button: {
                    padding: "7px",
                    borderRadius: "4px",
                  },
                }}
              >
                {/* //TODO: Adult select Section */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Adults</Typography>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      background: "white",
                      borderRadius: "4px",
                    }}
                  >
                    <IconButton onClick={adultDecrement}>
                      <RemoveIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        width: "40%",
                        textAlign: "center",
                        color: "var(--black)",
                        fontWeight: "bold",
                      }}
                    >
                      {adultCount}
                    </Typography>
                    <IconButton onClick={adultIncrement}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                {/* //TODO: Child Select Section */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Children</Typography>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      background: "white",
                      borderRadius: "4px",
                    }}
                  >
                    <IconButton onClick={childDecrement}>
                      <RemoveIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        width: "40%",
                        textAlign: "center",
                        color: "var(--black)",
                        fontWeight: "bold",
                      }}
                    >
                      {childCount}
                    </Typography>
                    <IconButton onClick={childIncrement}>
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
                {/* //TODO: Child age must give text */}
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--black) !important",
                    fontSize: "11px !important",
                    fontWeight: "normal !important",
                    textAlign: "justify !important",
                  }}
                >
                  To Find best deals with best price we must know how old your
                  child will be at check-out
                </Typography>
                {/* //TODO: Hotel Room Select Section */}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography>Room</Typography>
                  <Box
                    sx={{
                      width: "50%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: "5px",
                      background: "white",
                      borderRadius: "4px",
                    }}
                  >
                    <IconButton>
                      <RemoveIcon />
                    </IconButton>
                    <Typography
                      sx={{
                        width: "40%",
                        textAlign: "center",
                        color: "var(--black)",
                        fontWeight: "bold",
                      }}
                    >
                      {room}
                    </Typography>
                    <IconButton>
                      <AddIcon />
                    </IconButton>
                  </Box>
                </Box>
              </Box>
              <Button
                onClick={handleCloseBox}
                style={{
                  width: "100%",
                  background: "var(--black)",
                  color: "white",
                  fontSize: "12px",
                  padding: "4px 0px",
                  textTransform: "capitalize",
                }}
              >
                Done
              </Button>
            </Box>
          )}
        </Grid>
        <Box sx={{ position: "relative", mt: "1vh" }}>
          {/* <form onSubmit={handleSearch}> */}
          <form onSubmit={handleSearch}>
            <Box>
              <Button
                type="submit"
                sx={{
                  fontSize: "15px",
                  height: { lg: "45%", md: "100%", sm: "100%", xs: "20%" },
                  width: {
                    lg: "17%",
                    md: "90%",
                    sm: "100%",
                    xs: "100%",
                  },
                  mt: { lg: "0px", md: "0px", sm: "0px", xs: "0px" },
                  mb: "3vh",
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                  display: {
                    lg: "flex",
                    md: "flex",
                    sm: "none",
                    xs: "none",
                  },
                  position: "absolute",
                  right: location?.pathname === "/" ? "0px" : "45px",
                  bottom: "50px",

                  gap: "5px",
                  "&:hover": {
                    backgroundColor: "var(--primary-color)",
                    cursor: "pointer",
                  },
                }}
              >
                <Box>Search Hotel</Box>
                <HotelIcon sx={{ fontSize: "23px" }} />
              </Button>
            </Box>
            <Grid
              sx={{
                height: "fit-content",
                width: "100%",
              }}
              container
              rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 1 }}
              columnSpacing={0}
            >
              {/* //todo: Departure City section */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3.8}
                lg={3.8}
                sx={{
                  position: "relative",
                  height: "82px",
                  mx: location?.pathname === "/" ? "10px" : "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                  onClick={() => {
                    setOpenDestination((prev) => !prev);
                    // setOpenCheckIn(false);
                    setOpenGuests(false);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        mb={0.5}
                      >
                        Enter Your Destination or City
                      </Typography>

                      <Typography
                        sx={{
                          color: "gray",
                          fontSize: "13px",
                          fontWeight: "400",
                        }}
                      >
                        {destinationAddress}
                      </Typography>
                    </Box>
                    {openDestination && (
                      <Box
                        style={{
                          position: "absolute",
                          top: "100%",
                          left: "0",
                          width: "100%",
                          backgroundColor: "#fff",
                          height: "fit-content",
                          marginTop: "-35px",
                          borderRadius: "5px",
                          zIndex: "10",
                        }}
                        onClick={() => {
                          setOpenDestination((prev) => !prev);
                        }}
                      >
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#003566",
                            zIndex: 10,
                          }}
                          backgroundColor="#fff"
                        >
                          <input
                            autoComplete="off"
                            autoFocus
                            onChange={handleDestinationClick}
                            placeholder="City/Hotel/Street Name..."
                            className="crimsonPlaceholder"
                            style={{
                              color: "var(--primary-color)",
                              fontWeight: 500,
                              paddingLeft: "20px",
                              width: "100%",
                              height: "40px",
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          />
                        </Box>
                        <Box>{destinationBox()}</Box>
                      </Box>
                    )}

                    <Box sx={{ width: "90%" }}>
                      <span
                        style={{
                          width: "100%",
                          color: "var(--light-gray)",
                          fontSize: "13px",
                        }}
                      >
                        {/* {fromSearchText.substring(
                          0,
                          fromSearchText.indexOf("(")
                        )} */}
                      </span>
                    </Box>
                  </Box>
                </Box>

                {/* second cutting code */}
              </Grid>

              {/* //todo:   Check In Date */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3.8}
                lg={3.8}
                sx={{
                  position: "relative",
                  height: "82px",
                  mx: location?.pathname === "/" ? "10px" : "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                  onClick={() => {
                    setOpenCheckIn((prev) => !prev);
                    setOpenDestination(false);
                    setOpenGuests(false);
                    handleTest("asdf");
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        mb={1}
                      >
                        Check In Date
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "var(--light-gray)",
                          }}
                        >{`${format(
                          new Date(checkInDate),
                          "dd MMM yy"
                        )}`}</span>

                        {/* <CalendarMonthIcon
                          sx={{ color: "var(--secondary-color)" }}
                        /> */}
                        <Typography sx={{ color: "gray", fontSize: "12px" }}>
                          {`${format(new Date(checkInDate), "EEEE")}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {openCheckIn && (
                  <Box>
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(checkInDate)}
                      onChange={(date) => {
                        setCheckInDate(date);
                        setOpenCheckIn(false);
                        setOpenCheckOut(true);
                      }}
                      months={1}
                      direction="horizontal"
                      minDate={new Date()}
                      className={"dashboard-calendar"}
                    />
                  </Box>
                )}
              </Grid>

              {/* //todo:Check Out Date */}
              <Grid
                item
                xs={12}
                sm={12}
                md={3.7}
                lg={3.7}
                sx={{
                  position: "relative",
                  height: "82px",
                  mx: location?.pathname === "/" ? "10px" : "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                    borderBottom: "1px solid var(--light-gray)",
                  }}
                  onClick={() => {
                    setOpenCheckOut((prev) => !prev);
                    setOpenCheckIn(false);
                    setOpenDestination(false);
                    setOpenGuests(false);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                          fontWeight: "500",
                        }}
                        mb={1}
                      >
                        Check Out Date
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "var(--light-gray)",
                          }}
                        >{`${format(
                          new Date(checkOutDate),
                          "dd MMM yy"
                        )}`}</span>

                        <Typography sx={{ color: "gray", fontSize: "12px" }}>
                          {`${format(new Date(checkOutDate), "EEEE")}`}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>

                {openCheckOut && (
                  <Box>
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(checkInDate.getTime())}
                      onChange={(date) => {
                        setCheckOutDate(date);
                        setOpenCheckOut(false);
                      }}
                      months={1}
                      minDate={new Date(checkInDate.getTime())}
                      maxDate={maxDate}
                      direction="horizontal"
                      className={"dashboard-calendar"}
                    />
                  </Box>
                )}
              </Grid>
              <Box>
                <Button
                  type="submit"
                  sx={{
                    fontSize: "15px",
                    // height: { lg: "45%", md: "100%", sm: "100%", xs: "20%" },
                    width: {
                      lg: "17%",
                      md: "90%",
                      sm: "100%",
                      xs: "100%",
                    },
                    mt: { lg: "0px", md: "0px", sm: "0px", xs: "0px" },
                    mb: "1vh",
                    backgroundColor: "var(--secondary-color)",
                    color: "var(--white)",
                    textTransform: "capitalize",
                    display: {
                      lg: "none",
                      md: "none",
                      sm: "flex",
                      xs: "flex",
                    },
                    position: "absolute",
                    right: location?.pathname === "/" ? "0px" : "0px",
                    bottom: "-50px",

                    gap: "5px",
                    "&:hover": {
                      backgroundColor: "var(--primary-color)",
                      cursor: "pointer",
                    },
                  }}
                >
                  <Box>Search Hotel</Box>
                  <HotelIcon sx={{ fontSize: "23px" }} />
                </Button>
              </Box>
            </Grid>
          </form>
        </Box>
      </Box>
      {book ? <Popup book={true} setBook={setBook} way={"one"} /> : ""}
    </>
  );
};

export default HotelSearchBox;
