import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import MultiCityQueueDetails from "./MultiCityQueueDetails";
import { MultiCityFlightInfoDetails } from "./MultiCityFlightInfoDetails";
import MultiCityUserInfo from "./MultiCityUserInfo";
import { Triangle } from "react-loader-spinner";
import UserHeader from "../UserPage/UserHeader/UserHeader";
const isMobile = window.innerWidth <= 768;

const MulticityFlightInfo = () => {
  const location = useLocation();
  const [loadData, setLoadData] = useState([]);
  const { adultCount, childCount, infant, flightData } = location.state;
  const [isLoaded, setIsLoaded] = useState(true);
  const navigate = useNavigate();
  //todo: cupon
  const [coupon, setCoupon] = useState("");
  const [couponAppliedMessage, setCouponAppliedMessage] = useState({});
  //todo:end cupon
  //todo: Baggage Information
  const [adultBaggage, setAdultBaggage] = useState(0);
  const [childBaggage, setChildBaggage] = useState(0);
  const [infantBaggage, setInfantBaggage] = useState(0);
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(flightData?.PriceBreakDown);

  let url;
  let body;

  //   if (location.state?.flightData?.system === "Sabre") {
  //     url = "https://api.flyfarint.com/v2/AirPrice/index.php";
  //     body = location?.state?.flightData;
  //   } else if (location.state?.flightData.system === "FlyHub") {
  //     url = "https://api.flyfarint.com/v.1.0.0/FlyHub/AirPrice.php";
  //     body = {
  //       SearchID: location.state?.flightData?.SearchID,
  //       ResultID: location.state?.flightData?.ResultID,
  //     };
  //   } else if (location.state?.flightData.system === "Galileo") {
  //     url = "https://api.flyfarint.com/v2/AirPrice/index.php";
  //     body = location?.state?.flightData;
  //   }

  //   useEffect(() => {
  //     fetch(url, {
  //       method: "POST",
  //       headers: {
  //         Accept: "*/*",
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(body),
  //     })
  //       .then((res) => res.json())
  //       .then((data) => {
  //         //(data !== null);
  //         if (
  //           (data !== null && data?.status !== "error") ||
  //           data?.Error === null
  //         ) {
  //           setLoadData(data);
  //         } else {
  //           throw new Error(data.message);
  //         }
  //       })
  //       .catch((err) => {
  //         //(err.message);
  //         Swal.fire({
  //           // imageUrl: NotFound,
  //           imageWidth: 400,
  //           imageHeight: 200,
  //           imageAlt: "Custom image",
  //           title: "No Data Found",
  //           confirmButtonText: "Search Another Flights...",
  //           confirmButtonColor: "var(--primary-color)",
  //         }).then(function () {
  //           navigate(-1);
  //         });
  //       });
  //   }, []);

  let adultPrice = 0,
    adultTaxPrice = 0,
    childPrice = 0,
    childTaxPrice = 0,
    infTaxPrice = 0,
    infPrice = 0,
    inTotalBaseFare = 0,
    totalTax = 0,
    totalFare = 0,
    totalBaseFare = 0,
    serviceFeeAdult = 0,
    serviceFeeChild = 0,
    serviceFeeInfant = 0,
    discount = 0,
    agentTotal = 0,
    otherCharges = 0,
    limitTime;

  if (Object.keys(loadData).length !== 0) {
    if (adultCount > 0) {
      adultPrice =
        location.state?.flightData?.System === "Sabre"
          ? loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare.passengerInfoList[0].passengerInfo
              .passengerTotalFare.equivalentAmount * location?.state?.adultCount
          : location?.state?.flightData?.System === "Galileo"
          ? Number.parseInt(
              loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo?.attributes?.EquivalentBasePrice?.replace(
                /\D/g,
                ""
              ) ||
                loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo[0]?.attributes?.EquivalentBasePrice?.replace(
                  /\D/g,
                  ""
                ) ||
                0
            ) * location?.state?.adultCount
          : loadData?.Results[0].Fares[0].BaseFare * adultCount;

      adultTaxPrice =
        location.state?.flightData?.System === "Sabre"
          ? loadData.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare.passengerInfoList[0].passengerInfo
              .passengerTotalFare.totalTaxAmount * location.state?.adultCount
          : location.state?.flightData?.System === "Galileo"
          ? Number.parseInt(
              loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo?.attributes?.Taxes?.replace(
                /\D/g,
                ""
              ) ||
                loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo[0]?.attributes?.Taxes?.replace(
                  /\D/g,
                  ""
                ) ||
                0
            ) * location.state?.adultCount
          : loadData?.Results[0].Fares[0].Tax * adultCount;
      serviceFeeAdult =
        location.state?.flightData?.System === "Sabre"
          ? 0
          : location.state?.flightData?.System === "Galileo"
          ? 0
          : loadData?.Results[0]?.Fares[0]?.ServiceFee
          ? loadData?.Results[0]?.Fares[0]?.ServiceFee
          : 0 * location.state?.adultCount;
    }

    if (childCount > 0) {
      childPrice =
        location.state.flightData?.System === "Sabre"
          ? loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare.passengerInfoList[1].passengerInfo
              .passengerTotalFare.equivalentAmount * location.state?.childCount
          : location.state.flightData?.System === "Galileo"
          ? Number.parseInt(
              loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo[1]?.attributes?.EquivalentBasePrice?.replace(
                /\D/g,
                ""
              ) || 0
            ) * location.state?.childCount
          : loadData?.Results[0].Fares[1].BaseFare * childCount;
      childTaxPrice =
        location.state.flightData?.System === "Sabre"
          ? loadData.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare.passengerInfoList[1].passengerInfo
              .passengerTotalFare.totalTaxAmount * location.state?.childCount
          : location.state.flightData?.System === "Galileo"
          ? Number.parseInt(
              loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo[1]?.attributes?.Taxes?.replace(
                /\D/g,
                ""
              ) || 0
            ) * location.state?.childCount
          : loadData?.Results[0]?.Fares[1]?.Tax * location.state?.childCount;
      serviceFeeChild =
        location.state?.flightData?.System === "Sabre"
          ? 0
          : location.state?.flightData?.System === "Galileo"
          ? 0
          : loadData?.Results[0]?.Fares[1]?.ServiceFee
          ? loadData?.Results[0]?.Fares[1]?.ServiceFee
          : 0 * location.state?.childCount;
    }

    if (infant > 0) {
      infPrice =
        location.state.flightData?.System === "Sabre"
          ? loadData?.groupedItineraryResponse?.itineraryGroups[0]
              ?.itineraries[0]?.pricingInformation[0]?.fare.passengerInfoList[2]
              ?.passengerInfo?.passengerTotalFare?.equivalentAmount ||
            loadData?.groupedItineraryResponse?.itineraryGroups[0]
              ?.itineraries[0]?.pricingInformation[0]?.fare.passengerInfoList[1]
              ?.passengerInfo.passengerTotalFare.equivalentAmount *
              location.state?.infant
          : location.state.flightData?.System === "Galileo"
          ? Number.parseInt(
              loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo[2]?.attributes?.EquivalentBasePrice?.replace(
                /\D/g,
                ""
              ) || 0
            ) * location.state?.infant
          : loadData?.Results[0]?.Fares[2]?.BaseFare ||
            loadData?.Results[0]?.Fares[1]?.BaseFare * location.state?.infant;

      infTaxPrice =
        location.state.flightData?.System === "Sabre"
          ? loadData.groupedItineraryResponse?.itineraryGroups[0]
              ?.itineraries[0]?.pricingInformation[0]?.fare
              ?.passengerInfoList[2]?.passengerInfo?.passengerTotalFare
              ?.totalTaxAmount ??
            loadData?.groupedItineraryResponse?.itineraryGroups[0]
              ?.itineraries[0].pricingInformation[1]?.fare?.passengerInfoList[1]
              ?.passengerInfo?.passengerTotalFare?.totalTaxAmount *
              location.state?.infant
          : location.state.flightData?.System === "Galileo"
          ? Number.parseInt(
              loadData?.airAirPriceResult?.airAirPricingSolution?.airAirPricingInfo[2]?.attributes?.Taxes?.replace(
                /\D/g,
                ""
              ) || 0
            ) * location.state?.infant
          : loadData?.Results[0]?.Fares[2]?.Tax ??
            loadData?.Results[0]?.Fares[1]?.Tax * location.state?.infant;
      serviceFeeInfant =
        location.state?.flightData?.System === "Sabre"
          ? 0
          : location.state?.flightData?.System === "Galileo"
          ? 0
          : loadData?.Results[0]?.Fares[2]?.ServiceFee
          ? loadData?.Results[0]?.Fares[2]?.ServiceFee
          : 0 * location.state?.infant;
    }

    totalTax =
      location.state?.flightData?.System === "Sabre"
        ? loadData.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
            .pricingInformation[0].fare.totalFare.totalTaxAmount
        : location.state.flightData?.System === "Galileo"
        ? adultTaxPrice + childTaxPrice + infTaxPrice
        : adultTaxPrice + childTaxPrice + infTaxPrice;
    totalBaseFare =
      location.state.flightData?.System === "Sabre"
        ? adultPrice + childPrice + infPrice
        : location.state.flightData?.System === "Galileo"
        ? adultPrice + childPrice + infPrice
        : adultPrice + childPrice + infPrice;
    totalFare =
      location.state.flightData?.System === "Sabre"
        ? totalBaseFare + totalTax
        : location.state.flightData?.System === "Galileo"
        ? totalBaseFare +
          totalTax +
          serviceFeeAdult +
          serviceFeeChild +
          serviceFeeInfant
        : totalBaseFare +
          totalTax +
          serviceFeeAdult +
          serviceFeeChild +
          serviceFeeInfant;

    agentTotal = Number(location?.state?.flightData?.AgentFare || 0);
    discount = Number(totalFare - agentTotal);

    limitTime =
      location.state.flightData?.System === "Sabre"
        ? new Date()
        : location.state.flightData?.System === "Galileo"
        ? new Date()
        : loadData?.Results
        ? loadData?.Results[0]?.LastTicketDate
        : new Date();
  }

  //todo: calculate total flight duration
  // const calDuration = (arr) => {
  //   const timeArr = arr.map((item) => item.flightduration);
  //   const convertTime = timeArr.map(
  //     (item) =>
  //       parseInt(item.split(" ")[0]) * 3600 * 1000 +
  //       parseInt(item.split(" ")[1]) * 60 * 1000
  //   );
  //   const milliseconds = convertTime.reduce((cur, acc) => cur + acc, 0);
  //   let seconds = Math.floor(milliseconds / 1000);
  //   let minutes = Math.floor(seconds / 60);
  //   let hours = Math.floor(minutes / 60);
  //   seconds = seconds % 60;
  //   minutes = seconds >= 30 ? minutes + 1 : minutes;
  //   minutes = minutes % 60;
  //   hours = hours % 24;
  //   return `${hours.toString().padStart(2, 0)}H:${minutes
  //     .toString()
  //     .padStart(2, 0)}Min`;
  // };

  const calDuration = (totalMinutes) => {
    let hours = Math.floor(totalMinutes / 60);
    let minutes = totalMinutes % 60;
    let seconds = Math.round((totalMinutes - (hours * 60 + minutes)) * 60);

    if (seconds === 60) {
      minutes++;
      seconds = 0;
    }
    if (minutes === 60) {
      hours++;
      minutes = 0;
    }

    return `${hours.toString().padStart(2, "0")}h:${minutes
      .toString()
      .padStart(2, "0")}m`;
  };

  return (
    <Box
      sx={{
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "3px",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
        ".MuiTypography-h6": {
          fontSize: "16px",
          color: "#444542",
          fontWeight: "500",
        },
        ".MuiTypography-caption": {
          fontSize: "11px",
          color: "#ACA6A6",
        },
        ".MuiTypography-subtitle1": {
          fontSize: "11px",
          color: "#2156A6",
        },
      }}
    >
      <UserHeader />
      <Grid
        justifyContent={"space-between"}
        container
        sx={{
          p: {
            xs: "5px",
            sm: "5px",
            md: "15px",
            lg: "15px",
          },
      
          width: "90%",
          margin: "0 auto",
          mt: "-80px",
          bgcolor: "white",
          borderRadius: "5px",
        }}
      >
        {Object.keys(flightData).length !== 0 ? (
          <Box sx={{ p: 0 }}>
            <Grid
              container
              spacing={2}
              sx={{ justifyContent: "space-between" }}
            >
              <Grid item xs={12} sm={12} md={9} lg={9}>
                <Box>
                  <Typography
                    sx={{
                      color: "#2156A6",
                      p: "10px",
                      fontWeight: 500,
                      fontSize: "15px",
                      bgcolor: "#EAF2FF",
                      pl: "20px",
                      borderRadius: "5px",
                    }}
                  >
                    Flight Information
                  </Typography>
                </Box>
                <Box sx={{ p: "10px", bgcolor: "white" }}>
                  <MultiCityQueueDetails
                    flightData={flightData}
                    expanded={true}
                  />
                </Box>

                <Box mt={3}>
                  <MultiCityUserInfo
                    otherCharges={otherCharges}
                    flightData={flightData}
                    userData={location.state}
                    searchResult={loadData}
                    adultPrice={adultPrice}
                    childPrice={childPrice}
                    infPrice={infPrice}
                    adultTaxPrice={adultTaxPrice}
                    childTaxPrice={childTaxPrice}
                    infTaxPrice={infTaxPrice}
                    serviceFeeAdult={serviceFeeAdult}
                    serviceFeeChild={serviceFeeChild}
                    serviceFeeInfant={serviceFeeInfant}
                    inTotalBaseFare={inTotalBaseFare}
                    totalBaseFare={totalBaseFare}
                    totalTax={totalTax}
                    totalFare={totalFare}
                    limitTime={limitTime}
                    isLoaded={isLoaded}
                    setIsLoaded={setIsLoaded}
                    clientFare={location.state.clientFare}
                    coupon={coupon}
                    setCoupon={setCoupon}
                    couponAppliedMessage={couponAppliedMessage}
                    setCouponAppliedMessage={setCouponAppliedMessage}
                    adultBaggage={adultBaggage}
                    setAdultBaggage={setAdultBaggage}
                    childBaggage={childBaggage}
                    setChildBaggage={setChildBaggage}
                    infantBaggage={infantBaggage}
                    setInfantBaggage={setInfantBaggage}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={3}
                md={2.8}
                sx={{
                  bgcolor: "#F6F6FF",
                  borderRadius: "7px",
                  height: "350px",
                  mt: "15px",
                }}
              >
                {/*// todo: price breakdown section */}
                <MultiCityFlightInfoDetails
                  flightData={flightData}
                  pricebreakdown={flightData?.PriceBreakDown}
                />
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "80vh",
              width: { xs: "80px" },
              marginInline: "auto",
            }}
          >
            <Triangle
              height="80"
              width="80"
              color="#2156a6"
              ariaLabel="triangle-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export default MulticityFlightInfo;
