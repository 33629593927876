import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import secureLocalStorage from "react-secure-storage";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const GroupfareBooking = () => {
  const users = secureLocalStorage.getItem("user-info");
  const navigate = useNavigate();
  const [groupFareData, setGroupFareData] = useState([]);

  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/b2b/groupfare/index.php?agentId=${users?.agent_id}`
    )
      .then((res) => res.json())
      .then((data) => setGroupFareData(data?.group_fares));
  }, []);

  const styles = {
    root: {
      height: "calc(100vh - 200px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    dataGrid: {
      flexGrow: 1,
    },
    "@media (max-width: 600px)": {
      root: {
        height: "calc(100vh - 100px)",
        width: "50%",
      },
    },
    "@media (min-width: 600px) and (max-width: 900px)": {
      root: {
        height: "calc(100vh - 150px)",
      },
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      root: {
        height: "calc(100vh - 175px)",
      },
    },
    "@media (min-width: 1200px) and (max-width: 1536px)": {
      root: {
        height: "calc(100vh - 190px)",
      },
    },
  };

  const columns = [
    {
      field: "group_fare_booking_id",
      headerName: "booking id",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return (
          <p
            onClick={() => {
              navigate("/dashboard/groupfarebookingdetails", {
                state: {
                  bookingId: params.row.group_fare_booking_id,
                },
              });
            }}
            style={{
              backgroundColor: "var(--primary-color)",
              padding: "10px 20px",
              color: "white",
              cursor: "pointer",
            }}
          >
            {params.row.group_fare_booking_id}
          </p>
        );
      },
    },
    {
      field: "group_fare_id",
      headerName: "group fare id",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "status",
      headerName: "status",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return <p>{params.row?.status}</p>;
      },
    },

    {
      field: "route",
      headerName: "Route",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return (
          <p>{`${params.row.dept_from_code} - ${params.row.arrive_to_code}`}</p>
        );
      },
    },

    {
      field: "airlines",
      headerName: "airlines",
      suppressSizeToFit: true,
      width: 170,
    },
    {
      field: "travel_date",
      headerName: "Flight Date",
      suppressSizeToFit: true,
      width: 180,
      renderCell: (params) => {
        return (
          <p>
            {moment(params.row?.travel_date).format("DD MMM YYYY")} |{" "}
            {moment(params.row?.travel_date).format("HH:MM A")}
          </p>
        );
      },
    },
    {
      field: "gross_cost",
      headerName: "gross cost",
      suppressSizeToFit: true,
      width: 120,
    },
    {
      field: "pax_count",
      headerName: "pax",
      suppressSizeToFit: true,
      width: 80,
    },
    {
      field: "",
      headerName: "total cost",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return <p>{params.row.gross_cost * params.row.pax_count}</p>;
      },
    },
    {
      field: "booked_at",
      headerName: "booked at",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return (
          <p>
            {moment(params.row?.booked_at).format("DD MMM YYYY")} |{" "}
            {moment(params.row?.booked_at).format("HH:MM A")}
          </p>
        );
      },
    },
  ];

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "90%", margin: "0 auto", mt: "-7vh" }}>
        <Box
          sx={{
            background: "var(--white)",
            py: "10px",
            px: "15px",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "8px",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "18px !important",
                color: "var(--black)",
                fontFamily: "poppins",
              }}
            >
              Group Fare Booking
            </Typography>
          </Box>

          <Box
            style={styles.root}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize:"10px"
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid var(--input-bgcolor)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color)",
                borderBottom: "none",
                color: "var(--white)",
                fontSize: "10px",
                textTransform: "uppercase",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "var(--body-color)",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
              },
              "& .MuiDataGrid-toolbarContainer": {
                marginLeft: "auto",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "var(--primary-color)",
                background: "var(--secondary-color)",
                fontSize: "10px",
                mb: 2,
              },
              ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
                color: "var(--white)",
              },
              ".MuiTablePagination-selectLabel": {
                color: "var(--white)",
              },
              ".MuiSelect-select": {
                color: "var(--white)",
              },
              ".css-levciy-MuiTablePagination-displayedRows": {
                color: "var(--white)",
              },
              ".MuiDataGrid-cellContent": {
                color: "var(--text-color)",
              },
            }}
          >
            <DataGrid
              rows={groupFareData}
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              scrollbarSize={5}
              style={styles.dataGrid}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default GroupfareBooking;
