import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import TimeOut from '../../../images/undraw/undraw_in_no_time_-6-igu.svg';
import { useNavigate } from 'react-router-dom';

const SessionTimer = ({ setChangeState }) => {
  const [timeLeft, setTimeLeft] = useState(900); // Initial time in seconds
  const navigate = useNavigate()
  useEffect(() => {
    const intervalId = setInterval(() => {
      // Decrease the time left every second
      setTimeLeft((prevTime) => prevTime - 1);
    }, 1000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Check if the time has expired
    if (timeLeft === 0) {
      Swal.fire({
        imageUrl: TimeOut,
        imageWidth: 400,
        imageHeight: 200,
        imageAlt: 'Custom image',
        title: 'Your Session Time Is Expired',
        confirmButtonText: 'Search Again...',
        confirmButtonColor: 'var(--primary-color)',
      }
      )
        .then(() => {
          navigate('/dashboard')
          setChangeState((prev) => !prev);
        });
    }
  }, [timeLeft, setChangeState]);

  // Convert seconds to minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <span
      style={{
        fontWeight: 'bold',
        fontSize: '20px',
      }}
    >
      {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
    </span>
  );
};

export default SessionTimer;
