import { Box, Container, Tooltip, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { Button } from "@mui/material";
import DeleteSharpIcon from "@mui/icons-material/DeleteSharp";
import { json, Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import UserHeader from "../../components/UserPage/UserHeader/UserHeader";
import commaNumber from "comma-number";
import moment from "moment/moment";
const isMobile = window.innerWidth <= 768;

const SearchReport = () => {
  const users = secureLocalStorage.getItem("user-info");

  const [ledgerReportData, setLedgerReportData] = useState([]);

  useEffect(() => {
    const url = `https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/ledger.php?agentId=${users.agent_id}`;
    console.log(url);

    fetch(
      `https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/ledger.php?agentId=${users.agent_id}`
    )
      .then((res) => res.json())
      .then((data) => setLedgerReportData(data?.legder_list));
  }, []);

  const styles = {
    root: {
      height: "calc(100vh - 200px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    dataGrid: {
      flexGrow: 1,
    },
    "@media (max-width: 600px)": {
      root: {
        height: "calc(100vh - 100px)",
        width: "50%",
      },
    },
    "@media (min-width: 600px) and (max-width: 900px)": {
      root: {
        height: "calc(100vh - 150px)",
      },
    },
    "@media (min-width: 900px) and (max-width: 1200px)": {
      root: {
        height: "calc(100vh - 175px)",
      },
    },
    "@media (min-width: 1200px) and (max-width: 1536px)": {
      root: {
        height: "calc(100vh - 190px)",
      },
    },
  };

  const columns = [
    {
      field: "refernce",
      headerName: "Reference",
      width: 120,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                cursor: "pointer",
                backgroundColor: "#5D6D7E",
                padding: "5px 10px",
                borderRadius: "3px",
              }}
            >
              <Typography sx={{ fontSize: "13px", color: "white" }}>
                {params.row.reference}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "deposit",
      headerName: "Type",
      width: 140,
      renderCell: (params) => {
        return (
          <Box>
            {params.row?.deposit > "0" ? (
              <span
                style={{
                  backgroundColor: "#177c33",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Deposit
              </span>
            ) : params.row?.purchase > "0" ? (
              <span
                style={{
                  backgroundColor: "#ed1c24",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Purchase
              </span>
            ) : params.row?.returnMoney > "0" ? (
              <span
                style={{
                  backgroundColor: "#ff6464",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Issue Reject
              </span>
            ) : params.row?.loan > "0" ? (
              <span
                style={{
                  backgroundColor: "#ff6464",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Loan
              </span>
            ) : params.row?.void > "0" ? (
              <span
                style={{
                  backgroundColor: "#235f83",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Void
              </span>
            ) : params.row?.refund > "0" ? (
              <span
                style={{
                  backgroundColor: "#7a64ff",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Refund
              </span>
            ) : params.row?.reissue > "0" ? (
              <span
                style={{
                  backgroundColor: "#C0392B",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Reissue
              </span>
            ) : params.row?.others > "0" ? (
              <span
                style={{
                  backgroundColor: "#272323",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Other
              </span>
            ) : params.row?.servicefee > "0" ? (
              <span
                style={{
                  backgroundColor: "#0b8668",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Service Fee
              </span>
            ) : (
              <span
                style={{
                  backgroundColor: "orange",
                  padding: "6px 20px",
                  color: "#fff",
                  fontSize: "11px",
                  borderRadius: "5px",
                }}
              >
                Issue Rejected
              </span>
            )}
          </Box>
        );
      },
    },
    {
      field: "platform",
      headerName: "Platform",
      width: 120,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex" }}>
            <Box
              sx={{
                cursor: "pointer",
                backgroundColor: "#438E94",
                padding: "5px 10px",
                borderRadius: "3px",
              }}
            >
              <Typography sx={{ fontSize: "13px", color: "white" }}>
                {(params.row?.user_id && `B2C ${params?.row?.user_id}`) ||
                  `B2B ${params?.row?.agent_id}`}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: "deptFrom",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex" }}>
            <Box>
              {moment(params?.row?.createdAt).format("DD MMM yy hh:mm a")}
            </Box>
          </Box>
        );
      },
    },

    {
      field: "details",
      headerName: "Remarks",
      description: "This column has a value getter and is not sortable.",
      width: 400,
      renderCell: (params) => {
        return (
          <Tooltip title={params?.row?.details}>
            <p>
              {params?.row?.details?.length > 45
                ? `${params?.row?.details?.substring(0, 45)}...`
                : params?.row?.details}
            </p>
          </Tooltip>
        );
      },
    },

    {
      field: "airlinesPNR",
      headerName: "Amount",
      width: 150,
      renderCell: (params) => {
        return (
          <Box>
            {params.row?.deposit > "0" ? (
              <span>{commaNumber(params.row?.deposit)} ৳</span>
            ) : params.row?.purchase > "0" ? (
              <span>{commaNumber(params.row?.purchase)} ৳</span>
            ) : params.row?.loan > "0" ? (
              <span>{commaNumber(params.row?.loan)} ৳</span>
            ) : params.row?.returnMoney > "0" ? (
              <span>{commaNumber(params.row?.returnMoney)} ৳</span>
            ) : params.row?.void > "0" ? (
              <span>{commaNumber(params.row?.void)} ৳</span>
            ) : params.row?.refund > "0" ? (
              <span>{commaNumber(params.row?.refund)} ৳</span>
            ) : params.row?.reissue > "0" ? (
              <span>{commaNumber(params.row?.reissue)} ৳</span>
            ) : params.row?.others > "0" ? (
              <span>{commaNumber(params.row?.others)} ৳</span>
            ) : params.row?.servicefee > "0" ? (
              <span>{commaNumber(params.row?.servicefee)} ৳</span>
            ) : (
              <span>0</span>
            )}
          </Box>
        );
      },
    },

    {
      field: "last_amount",
      headerName: "Balance",
      width: 100,
      renderCell: (params) => {
        return <p>{commaNumber(params?.row?.last_amount)} ৳</p>;
      },
    },
  ];

  return (
    <Box>
      <UserHeader />
      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            backgroundColor: "var(--white)",
            borderTopLeftRadius: "5px",
            borderTopRightRadius: "5px",
            p: "20px",
            m: "0 auto",
            width: "90%",
            mt: "-10vh",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "8px",
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontWeight: 500,
                fontSize: "18px !important",
                color: "var(--black)",
                fontFamily: "poppins",
              }}
            >
              Ledger Reports
            </Typography>
          </Box>

          <Box
            style={styles.root}
            sx={{
              "& .MuiDataGrid-root": {
                border: "none",
                fontSize:"10px"
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid var(--input-bgcolor)",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color)",
                borderBottom: "none",
                color: "var(--white)",
                fontSize: "10px",
                textTransform: "uppercase",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "var(--body-color)",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
              },
              "& .MuiDataGrid-toolbarContainer": {
                marginLeft: "auto",
              },
              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "var(--primary-color)",
                background: "var(--secondary-color)",
                fontSize: "10px",
                mb: 2,
              },
              ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
                color: "var(--white)",
              },
              ".MuiTablePagination-selectLabel": {
                color: "var(--white)",
              },
              ".MuiSelect-select": {
                color: "var(--white)",
              },
              ".css-levciy-MuiTablePagination-displayedRows": {
                color: "var(--white)",
              },
              ".MuiDataGrid-cellContent": {
                color: "var(--text-color)",
              },
            }}
          >
            {isMobile ? (
              <>
                <Grid sx={{ mt: "20px" }} container gap={2}>
                  {ledgerReportData?.map((sales) => (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={sales?.id}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "flex",
                          md: "flex",
                        },
                        transition: "all .5s ease-in-out",
                        background: "var(--gray)",
                        borderRadius: "5px",
                        width: "100%",
                        borderRadius: "10px",
                        px: "20px",
                        py: "25px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            Reference
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            <Box sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#5D6D7E",
                                  padding: "5px 10px",
                                  borderRadius: "3px",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "13px", color: "white" }}
                                >
                                  {sales?.reference}
                                </Typography>
                              </Box>
                            </Box>
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "30px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Type
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            <Box>
                              {sales?.deposit > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#177c33",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Deposit
                                </span>
                              ) : sales?.purchase > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#ed1c24",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Purchase
                                </span>
                              ) : sales?.returnMoney > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#ff6464",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Issue Reject
                                </span>
                              ) : sales?.loan > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#ff6464",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Loan
                                </span>
                              ) : sales?.void > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#235f83",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Void
                                </span>
                              ) : sales?.refund > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#7a64ff",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Refund
                                </span>
                              ) : sales?.reissue > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#C0392B",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Reissue
                                </span>
                              ) : sales?.others > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#272323",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Other
                                </span>
                              ) : sales?.servicefee > "0" ? (
                                <span
                                  style={{
                                    backgroundColor: "#0b8668",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Service Fee
                                </span>
                              ) : (
                                <span
                                  style={{
                                    backgroundColor: "orange",
                                    padding: "6px 20px",
                                    color: "#fff",
                                    fontSize: "11px",
                                    borderRadius: "5px",
                                  }}
                                >
                                  Issue Rejected
                                </span>
                              )}
                            </Box>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Platform
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            <Box sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  cursor: "pointer",
                                  backgroundColor: "#438E94",
                                  padding: "5px 10px",
                                  borderRadius: "3px",
                                }}
                              >
                                <Typography
                                  sx={{ fontSize: "13px", color: "white" }}
                                >
                                  {(sales?.user_id &&
                                    `B2C ${sales?.user_id}`) ||
                                    `B2B ${sales?.agent_id}`}
                                </Typography>
                              </Box>
                            </Box>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Date
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            <Box sx={{ display: "flex" }}>
                              <Box>
                                {moment(sales?.createdAt).format(
                                  "DD MMM yy hh:mm a"
                                )}
                              </Box>
                            </Box>
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "10px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Remarks
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "10px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            <Tooltip title={sales?.details}>
                              <p>
                                {sales?.details?.length > 45
                                  ? `${sales?.details?.substring(0, 45)}...`
                                  : sales?.details}
                              </p>
                            </Tooltip>
                          </Typography>
                        </Box>

                        <Grid
                          item
                          direction={{ xs: "row", sm: "row", md: "row" }}
                          spacing={1}
                          alignItems="flex-start"
                          sx={{
                            width: "100%",
                            height: "100%",
                            // padding: {
                            //   md: "8px",
                            //   xs: "0px",
                            // },
                            mt: "30px",
                            pb: "14px",
                            borderTop: "1px solid var(--primary-color)",
                            // borderTop: {
                            //   xs: "none",
                            //   sm: "1px solid var(--primary-color)",
                            // },
                            pl: { xs: 2, sm: 2 },
                          }}
                        ></Grid>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Amount
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            <Box>
                              {sales?.deposit > "0" ? (
                                <span>{commaNumber(sales?.deposit)} ৳</span>
                              ) : sales?.purchase > "0" ? (
                                <span>{commaNumber(sales?.purchase)} ৳</span>
                              ) : sales?.loan > "0" ? (
                                <span>{commaNumber(sales?.loan)} ৳</span>
                              ) : sales?.returnMoney > "0" ? (
                                <span>{commaNumber(sales?.returnMoney)} ৳</span>
                              ) : sales?.void > "0" ? (
                                <span>{commaNumber(sales?.void)} ৳</span>
                              ) : sales?.refund > "0" ? (
                                <span>{commaNumber(sales?.refund)} ৳</span>
                              ) : sales?.reissue > "0" ? (
                                <span>{commaNumber(sales?.reissue)} ৳</span>
                              ) : sales?.others > "0" ? (
                                <span>{commaNumber(sales?.others)} ৳</span>
                              ) : sales?.servicefee > "0" ? (
                                <span>{commaNumber(sales?.servicefee)} ৳</span>
                              ) : (
                                <span>0</span>
                              )}
                            </Box>
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Balance
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            <p>{commaNumber(sales?.last_amount)} ৳</p>
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <DataGrid
                  rows={ledgerReportData}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              </>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SearchReport;
