import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";
import aboutimg from "../../../imagesv2/home/about banner.svg";
// import aboutimg from "../../../images/About/about.png";
import ReactHtmlParser from "react-html-parser";
import { useEffect } from "react";
import { useState } from "react";

const AboutUs = () => {
  const [about, setAbout] = useState("");

  useEffect(() => {
    fetch(`https://api.rtc.flyfarint.com/v1.0/admin/cms/index.php?b2b&aboutUs`)
      .then((res) => res.json())
      .then((data) => setAbout(data?.content));
  }, []);

  return (
    <Box mt={8}>
      <Container>
        <Grid container spacing={2} display="flex" alignItems="center">
          <Grid item xs={12} sm={6} md={6}>
            <Box>
              <img style={{ width: "80%" }} src={aboutimg} alt="about" />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <Box style={{ display: "flex" }}>
              <HorizontalRuleIcon style={{ color: "var(--primary-color)" }} />
              <Typography
                style={{
                  color: "var(--mateBlack)",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                About US
              </Typography>
            </Box>
            <Typography
              style={{
                color: "var(--mateBlack)",
                fontSize: "25px",
                fontWeight: "500",
              }}
              mt={1}
            >
              We are concern about our clients on their all type{" "}
              <span style={{ color: "var(--primary-color)" }}>strategic</span>{" "}
              issues
            </Typography>
            <Typography
              mt={3}
              style={{
                fontSize: "14px",
                textAlign: "justify",
              }}
            >
              {/* {ReactHtmlParser(about)} */}
              WorldWideTnt and Travels is your digital gateway to a world of
              travel possibilities. Founded in 2016, they've been a symbol of
              excellence in the industry for over seven years. With a passion
              for exploration and a commitment to exceptional service, they
              offer personalized itineraries and a global network of partners
              for top-notch accommodations and experiences. Their user-friendly
              website makes booking a breeze, and they prioritize safety.
              Explore destinations, create customized itineraries, access travel
              resources, and discover exclusive offers on their platform.
              <Typography sx={{ pt: "30px", textAlign: "justify" }}>
                Their dedicated 24/7 support team is ready to assist with any
                travel issues. Whether you're an experienced globetrotter or a
                first-time traveler, WorldWideTnt and Travels transforms
                wanderlust into unforgettable journeys. Trust them to make your
                travel dreams a reality, one adventure at a time. Welcome to a
                world of possibilities with WorldWideTnt and Travels.
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default AboutUs;
