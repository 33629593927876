import {
  Box,
  ClickAwayListener,
  Grid,
  Typography,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { Calendar } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { format } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import FestivalIcon from "@mui/icons-material/Festival";

const TourSearchBox = ({}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [openDestinationType, setOpenDestinationType] = useState(false);
  const [selectedType, setSelectedType] = useState("domestic");

  const [openTravelDate, setOpenTravelDate] = useState(false);
  const [travelDateValue, setTravelDateValue] = useState(new Date());

  const [openDestination, setOpenDestination] = useState(false);
  const [destination, setDestination] = useState("");

  const handleClickAway = () => {};

  async function handleSearch(e) {
    e.preventDefault();
    navigate("/tourSearchResult");
  }

  const handleSelect = (date) => {
    setOpenTravelDate(false);
    setTravelDateValue(date);
  };
  console.log(travelDateValue);
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Box>
        <Box sx={{ position: "relative", mt: "1vh" }}>
          <form onSubmit={handleSearch}>
            <Grid
              sx={{
                height: "fit-content",
                width: "100%",
              }}
              container
              rowSpacing={{ lg: 0, md: 0, sm: 1, xs: 1 }}
              columnSpacing={0}
            >
              {/* //todo: Destination Type */}
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{
                  position: "relative",
                  pl: location?.pathname === "/" ? "10px" : "0px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenDestinationType(!openDestinationType);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={0.5}
                      >
                        Destination Type
                      </Typography>
                    </Box>

                    <Box sx={{ width: "90%" }}>
                      <Typography
                        sx={{ fontSize: "15px", textTransform: "capitalize" }}
                      >
                        {selectedType}
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {openDestinationType && (
                  <Box
                    style={{
                      position: "absolute",
                      top: "105%",
                      left: "0",
                      right: "0",
                      width: "100%",
                      backgroundColor: "var(--white)",
                      height: "fit-content",
                      border: "1px solid var(--primary-color)",
                      borderRadius: "5px",
                      zIndex: "999",
                      padding: "5px 5px 0px",
                    }}
                  >
                    <Typography
                      onClick={() => {
                        setSelectedType("domestic");
                        setOpenDestinationType(false);
                      }}
                      sx={{
                        fontSize: "15px",
                        p: "10px",
                        pb: "0",
                        mb: "10px",
                        cursor: "pointer",
                      }}
                    >
                      Domestic
                    </Typography>
                    <Typography
                      onClick={() => {
                        setSelectedType("international");
                        setOpenDestinationType(false);
                      }}
                      sx={{
                        fontSize: "15px",
                        p: "10px",
                        pt: "0",
                        cursor: "pointer",
                      }}
                    >
                      International
                    </Typography>
                  </Box>
                )}
              </Grid>

              {/* //todo:Departure Date */}
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{
                  position: "relative",
                  pl: location?.pathname === "/" ? "10px" : "0px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenTravelDate(!openTravelDate);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        style={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={1}
                      >
                        Travel Date
                      </Typography>

                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <span
                          style={{
                            fontSize: "14px",
                            color: "var(--light-gray)",
                          }}
                        >{`${format(
                          new Date(travelDateValue),
                          "dd MMM yy"
                        )}`}</span>

                        <CalendarMonthIcon
                          sx={{ color: "var(--secondary-color)" }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Box>
                {openTravelDate && (
                  <Box>
                    <Calendar
                      color="var(--primary-color)"
                      date={new Date(travelDateValue)}
                      onChange={handleSelect}
                      months={1}
                      direction="horizontal"
                      minDate={new Date()}
                      className={"dashboard-calendar"}
                    />
                  </Box>
                )}
              </Grid>

              {/* //todo: Where */}
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                sx={{
                  position: "relative",
                  pl: location?.pathname === "/" ? "10px" : "0px",
                  height: "82px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    width: "100%",
                    height: "100%",
                  }}
                  onClick={() => {
                    setOpenDestination(!openDestination);
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderBottom: "1px solid var(--light-gray)",
                    }}
                  >
                    <Box style={{ position: "relative" }}>
                      <Typography
                        sx={{
                          color: "var(--mateBlack)",
                          fontSize: "14px",
                        }}
                        mb={0.5}
                      >
                        Where
                      </Typography>
                    </Box>

                    <Box sx={{ width: "90%" }}>
                      <span
                        style={{
                          width: "100%",
                          color: "var(--light-gray)",
                          fontSize: "13px",
                        }}
                      >
                        hghfdf
                      </span>
                    </Box>
                  </Box>
                  {openDestination && (
                    <Box
                      style={{
                        position: "absolute",
                        top: "105%",
                        left: "0",
                        right: "0",
                        width: "100%",
                        backgroundColor: "var(--white)",
                        height: "fit-content",
                        border: "1px solid var(--primary-color)",
                        borderRadius: "5px",
                        zIndex: "999",
                        padding: "5px 5px 0px",
                      }}
                    >
                      <Typography
                        onClick={() => {
                          setSelectedType("domestic");
                          setOpenDestinationType(false);
                        }}
                        sx={{
                          fontSize: "15px",
                          p: "10px",
                          pb: "0",
                          mb: "10px",
                          cursor: "pointer",
                        }}
                      >
                        Domestic
                      </Typography>
                      <Typography
                        onClick={() => {
                          setSelectedType("international");
                          setOpenDestinationType(false);
                        }}
                        sx={{
                          fontSize: "15px",
                          p: "10px",
                          pt: "0",
                          cursor: "pointer",
                        }}
                      >
                        International
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grid>

              {/* //todo: Search Button */}
              <Grid
                lg={2}
                md={2}
                sm={12}
                xs={12}
                justifyContent="center"
                alignItems={"center"}
                sx={{ mt: "10px",       ml: location?.pathname === "/" ? "10px" : "0px", }}
              >
                <Box>
                  <Button
                    type="submit"
                    className="shine-effect"
                    sx={{
                      fontSize: "15px",
                      height: {
                        lg: "100%",
                        md: "100%",
                        sm: "100%",
                        xs: "100%",
                      },
                      width: {
                        lg: "90%",
                        md: "90%",
                        sm: "100%",
                        xs: "100%",
                      },
                      mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },
                      backgroundColor: "var(--secondary-color)",
                      color: "var(--white)",
                      position: "absolute",
                      left:location?.pathname==="/"?"942px":"1085px",
                      bottom: "115px",
                      textTransform: "capitalize",
                      display: {
                        lg: "flex",
                        md: "flex",
                        sm: "none",
                        xs: "none",
                      },
                      gap: "5px",
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "var(--primary-color)",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Box>{"" ? "Wait..." : "Search Tours"}</Box>
                    <FestivalIcon sx={{ fontSize: "23px" }} />
                  </Button>
                  <Button
                    type="submit"
                    className="shine-effect"
                    sx={{
                      fontSize: "15px",
                      height: {
                        lg: "100%",
                        md: "100%",
                        sm: "100%",
                        xs: "100%",
                      },
                      width: {
                        lg: "90%",
                        md: "90%",
                        sm: "100%",
                        xs: "100%",
                      },
                      mt: { lg: "0px", md: "0px", sm: "10px", xs: "10px" },
                      backgroundColor: "var(--secondary-color)",
                      color: "var(--white)",
                      bottom: "10px",
                      textTransform: "capitalize",
                      display: {
                        lg: "none",
                        md: "none",
                        sm: "flex",
                        xs: "flex",
                      },
                      gap: "5px",
                      position: "relative",
                      "&:hover": {
                        backgroundColor: "#FBEA78",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Box>{"" ? "Wait..." : "Search Tours"}</Box>
                    <FestivalIcon sx={{ fontSize: "23px" }} />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    </ClickAwayListener>
  );
};

export default TourSearchBox;
