import React, { useEffect, useState } from "react";
import { Document, Image, Page, Text, View } from "@react-pdf/renderer";
import { format } from "date-fns";
import commaNumber from "comma-number";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { useQuery } from "react-query";
const companyInfo = {
  name: "Fly Far International",
  email: "support@flyfarint.com",
  phone: "09606-912912",
  address:
    "1229, Hazi Abdul Latif Mansion, 2nd Floor, Ka-9, Bashundhara Rd, Dhaka",
};

var converter = require("number-to-words");

const PDFPageDesign = ({
  userData,
  allData,
  adultTotalPrice,
  childTotalPrice,
  infantTotalPrice,
  totalPrice,
  prices,
  copy,
  check,
  companyImage,
  margin,
  // discount,
  discountPrice,
  multiCityAllData,
  markupPrice,
}) => {
   

  const table = {
    display: "table",
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderRightWidth: 0,
    borderBottomWidth: 0,
  };
  const tableRow = {
    margin: "auto",
    flexDirection: "row",
  };

  const tableCol = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  //Passenger Details
  const tableColName = {
    width: "35%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColGender = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColType = {
    width: "20%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColNumber = {
    width: "25%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableColfromto = {
    width: "22%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableColtime = {
    width: "11%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };

  const tableColflight = {
    width: "17%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: "2px",
  };
  const tableCol2 = {
    width: "100%",
    borderStyle: "solid",
    borderWidth: "1px",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    fontWeight: "bold",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  };

  const tableCell = {
    // margin: "auto",
    margin: "3px",
    fontSize: "8px",
  };
  const tableCellInfo = {
    margin: "1px 3px",
    fontSize: "8px",
  };

  const adress = {
    fontSize: "10px",
    color: "#8b8b8b",
  };

  const users = secureLocalStorage.getItem("user-info");

  let agentID = users?.agentId;

  const [companyName, setCompanyName] = useState("");
  const [siteConfig, setSiteConfig] = useState({});
  const gCost = parseInt(allData?.gross_cost);
  const tCost = parseInt(allData?.tax);
  const bCost = parseInt(allData?.base_fare);

  const otherPrice = gCost - (tCost + bCost);
  const baseFareTotal = parseInt(otherPrice) + parseInt(allData?.base_fare);
  // const discount = parseInt(totalPrice) - parseInt(allData?.subagentCost);
  const gross = parseInt(allData?.gross_cost);
  const mark = parseInt(markupPrice);

  let word,capitalize;
  
  if (isNaN(gross) || isNaN(mark)) {
    console.error("Invalid numeric values for grossCost or markupPrice.");
  } else {
    const total = gross + mark;
  
    if (isFinite(total)) {
      word = converter.toWords(total);
      const arr = word.split(" ");
      for (let i = 0; i < arr.length; i++) {
        arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
      capitalize = arr.join(" ");
   
    } else {
      console.error("Total is not a finite number.");
    }
  }

  return (
    <Document>
      <Page size="A4" style={{ padding: "27px 20px" }}>
        <View style={{ padding: "15px" }}>
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {copy === "Agent Invoice" ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <View
                  style={{
                    fontSize: "10px",
                    width: "180px",
                  }}
                >
                  <Text
                    style={{
                      fontSize: "16px",
                      fontWeight: "900",
                      paddingBottom: "10px",
                    }}
                  >
                    {companyName}
                  </Text>
                  <Text style={adress}>Email: {companyInfo?.email}</Text>
                  <Text style={adress}>Phone: {companyInfo?.phone}</Text>
                </View>
              </View>
            ) : (
              <View></View>
            )}

            <View
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              {userData?.agent_comp_img === "" ||
              userData?.agent_comp_img === "undefined" ? null : (
                <Image
                  style={{ height: "40px" }}
                  src={userData?.agent_comp_img}
                />
              )}
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              marginTop: `${margin || 0}`,
            }}
          >
            <View
              style={{
                fontSize: "10px",
                display: "flex",
                width: "200px",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "start",
              }}
            >
              <Text
                style={{
                  fontSize: "14px",
                  fontWeight: 400,
                  paddingBottom: "5px",
                }}
              >
                {userData?.agent_comp_name}
              </Text>

              <Text style={adress}>Email: {userData?.agent_email}</Text>
              <Text style={adress}>Phone: {userData?.agent_phone_number}</Text>
            </View>

            <View
              style={{
                color: "#D3D3D3",
                fontSize: "35px",
                fontWeight: 800,
                fontStyle: "Poppins",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Text
                style={{
                  color: "#003566",
                  opacity: "0.4",
                  fontWeight: "bold",
                }}
              >
                {copy}
              </Text>
            </View>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "row",
              fontSize: "11px",
              alignItems: "center",
              justifyContent: "space-between",
              paddingTop: "10px",
            }}
          >
            <Text>Reference: {allData?.booking_id}</Text>

            {copy === "e - Ticket" ? (
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: "11px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Text>
                  Ticketed:{" "}
                  {allData?.last_updated
                    ? format(
                        new Date(allData?.last_updated?.toString()),
                        "dd MMM yyyy"
                      )
                    : ""}
                </Text>
                <Text style={{ marginLeft: "15px" }}>
                  Airlines PNR: {allData?.airlines_pnr}
                </Text>
              </View>
            ) : (
              <Text>
                Booking:{" "}
                {allData?.booked_at
                  ? format(
                      new Date(allData?.booked_at?.toString()),
                      "dd MMM yyyy"
                    )
                  : ""}
              </Text>
            )}

            <Text>
              {allData?.refundable === "yes" ? (
                <Text style={{ color: "green" }}>Refundable </Text>
              ) : (
                <Text style={{ color: "red" }}>Non Refundable </Text>
              )}
              | {allData?.trip_type?.toUpperCase()}
            </Text>
          </View>

          <View>
            <Text
              style={{
                fontSize: "12px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              PASSENGER DETAILS
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableColName}>
                  <Text style={tableCell}>Passenger Name </Text>
                </View>
                <View style={tableColGender}>
                  <Text style={tableCell}>Gender</Text>
                </View>
                <View style={tableColType}>
                  <Text style={tableCell}>Passenger Type</Text>
                </View>
                <View style={tableColNumber}>
                  <Text style={tableCell}>
                    {copy === "e - Ticket"
                      ? "Ticket Number"
                      : "Passport Number"}
                  </Text>
                </View>
              </View>
              {copy === "e - Ticket" ? (
                <View>
                  {allData?.ticketData?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColName}>
                        <Text style={tableCell}>
                          {data?.prefix} {data?.passenger_name?.toUpperCase()}
                        </Text>
                      </View>
                      <View style={tableColGender}>
                        <Text style={tableCell}>
                          {data?.gender?.toUpperCase()}
                        </Text>
                      </View>
                      <View style={tableColType}>
                        <Text style={tableCell}>
                          {data?.pax_type === "ADT"
                            ? "ADULT"
                            : data.pax_type === "INF"
                            ? "INFANT"
                            : "CHILD"}
                        </Text>
                      </View>
                      <View style={tableColNumber}>
                        <Text style={tableCell}>{data?.ticket_no}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              ) : (
                <View>
                  {allData?.passenger?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColName}>
                        <Text style={tableCell}>
                          {data?.gender === "Male" ? (
                            <Text>
                              {data?.gender === "Male" &&
                              data?.type === "ADT" ? (
                                <Text>
                                  MR {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              ) : (
                                <Text>
                                  MSTR {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              )}
                            </Text>
                          ) : (
                            <Text>
                              {data?.gender === "Female" &&
                              data?.type === "ADT" ? (
                                <Text>
                                  MS {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              ) : (
                                <Text>
                                  MISS {data?.f_name?.toUpperCase()}{" "}
                                  {data?.l_name?.toUpperCase()}
                                </Text>
                              )}
                            </Text>
                          )}
                        </Text>
                      </View>
                      <View style={tableColGender}>
                        <Text style={tableCell}>
                          {data?.gender?.toUpperCase()}
                        </Text>
                      </View>
                      <View style={tableColType}>
                        <Text style={tableCell}>
                          {data?.type === "ADT"
                            ? "ADULT"
                            : data.type === "INF"
                            ? "INFANT"
                            : "CHILD"}
                        </Text>
                      </View>
                      <View style={tableColNumber}>
                        <Text style={tableCell}>{data?.passport_no}</Text>
                      </View>
                    </View>
                  ))}
                </View>
              )}
            </View>
          </View>

          
          <View>
            <Text
              style={{
                fontSize: "12px",
                color: "#03437B",
                padding: "20px 0 10px 0",
              }}
            >
              FLIGHT ITINERARIES
            </Text>
            <View style={table}>
              <View style={{ margin: "auto", flexDirection: "row" }}>
                <View style={tableColflight}>
                  <Text style={tableCell}>Flight </Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Departure From</Text>
                </View>
                <View style={tableColfromto}>
                  <Text style={tableCell}>Arrival To</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Depart At</Text>
                </View>
                <View style={tableColtime}>
                  <Text style={tableCell}>Arrive At</Text>
                </View>
                <View style={tableColflight}>
                  <Text style={tableCell}>Info</Text>
                </View>
              </View>

              {allData?.trip_type === "return" ? (
                <>
                  {allData?.flightData?.Segment_data?.go?.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.goMarketingCareerName}
                        </Text>
                        <Text style={tableCell}>
                          {data?.goOperatingCareer}
                          {" | "}
                          {data?.goMarketingFlight}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.goDeparture}){data?.goDepartureAirport}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.goArrival}) {data?.goArrivalAirport}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.goDepartureTime
                            ? format(
                                new Date(data?.goDepartureTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.goArrivalTime
                            ? format(
                                new Date(data?.goArrivalTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.goBookingCode}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.goFlightDuration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {allData?.adult_bag !== "" && (
                            <>ADT: {allData?.adult_bag?.split("|")[0]}, </>
                          )}
                          {allData?.child_bag !== "" && (
                            <>
                              <>CNN: {allData?.child_bag?.split("|")[0]}, </>
                            </>
                          )}
                          {allData?.infant_bag !== "" && (
                            <>
                              <>INF: {allData?.infant_bag?.split("|")[0]}</>
                            </>
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
                  {allData?.flightData?.Segment_data?.back?.map(
                    (data, index) => (
                      <View style={tableRow} key={index}>
                        <View style={tableColflight}>
                          <Text style={tableCell}>
                            {data?.backMarketingCareerName}
                          </Text>
                          <Text style={tableCell}>
                            {data?.backOperatingCareer}
                            {" | "}
                            {data?.backMarketingFlight}
                          </Text>
                        </View>
                        <View style={tableColfromto}>
                          <Text style={tableCell}>
                            ({data?.backDeparture}){data?.backDepartureAirport}
                          </Text>
                        </View>
                        <View style={tableColfromto}>
                          <Text style={tableCell}>
                            ({data?.backArrival}) {data?.backArrivalAirport}
                          </Text>
                        </View>
                        <View style={tableColtime}>
                          <Text style={tableCell}>
                            {data?.backDepartureTime
                              ? format(
                                  new Date(data?.backDepartureTime?.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )
                              : ""}
                          </Text>
                        </View>
                        <View style={tableColtime}>
                          <Text style={tableCell}>
                            {data?.backArrivalTime
                              ? format(
                                  new Date(data?.backArrivalTime?.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )
                              : ""}
                          </Text>
                        </View>
                        <View style={tableColflight}>
                          <Text style={tableCellInfo}>
                            Cabin: 7Kg, Class: {data?.backBookingCode}
                          </Text>
                          <Text style={tableCellInfo}>
                            Duration: {data?.backFlightDuration}
                          </Text>
                          <Text style={tableCellInfo}>
                            Baggage:{" "}
                            {allData?.adult_bag !== "" && (
                              <>ADT: {allData?.adult_bag?.split("|")[1]}, </>
                            )}
                            {allData?.child_bag !== "" && (
                              <>
                                <>CNN: {allData?.child_bag?.split("|")[1]}, </>
                              </>
                            )}
                            {allData?.infant_bag !== "" && (
                              <>
                                <>INF: {allData?.infant_bag?.split("|")[1]}</>
                              </>
                            )}
                          </Text>
                        </View>
                      </View>
                    )
                  )}
                </>
              ) : allData?.trip_type === "oneway" ? (
                <>
                  {allData?.flightData?.segment.map((data, index) => (
                    <View style={tableRow} key={index}>
                      <View style={tableColflight}>
                        <Text style={tableCell}>
                          {data?.marketingCareerName}
                        </Text>
                        <Text style={tableCell}>
                          {data?.marketingCareer}
                          {" | "}
                          {data?.marketingFlight}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.departure}){data?.departureAirport}
                        </Text>
                      </View>
                      <View style={tableColfromto}>
                        <Text style={tableCell}>
                          ({data?.arrival}) {data?.arrivalAirport}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.departureTime
                            ? format(
                                new Date(data?.departureTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColtime}>
                        <Text style={tableCell}>
                          {data?.arrivalTime
                            ? format(
                                new Date(data?.arrivalTime?.toString()),
                                "dd MMM yyyy hh:mm a"
                              )
                            : ""}
                        </Text>
                      </View>
                      <View style={tableColflight}>
                        <Text style={tableCellInfo}>
                          Cabin: 7Kg, Class: {data?.bookingcode}
                        </Text>
                        <Text style={tableCellInfo}>
                          Duration: {data?.flightDuration}
                        </Text>
                        <Text style={tableCellInfo}>
                          Baggage:{" "}
                          {allData?.adult_bag !== "" && (
                            <>ADT: {allData?.adult_bag?.split("|")[0]}, </>
                          )}
                          {allData?.child_bag !== "" && (
                            <>
                              <>CNN: {allData?.child_bag?.split("|")[0]}, </>
                            </>
                          )}
                          {allData?.infant_bag !== "" && (
                            <>
                              <>INF: {allData?.infant_bag?.split("|")[0]}</>
                            </>
                          )}
                        </Text>
                      </View>
                    </View>
                  ))}
                </>
              ) : (
                <>
                  {multiCityAllData
                    ? multiCityAllData?.map((data, index) => (
                        <View style={tableRow} key={index}>
                          <View style={tableColflight}>
                            <Text style={tableCell}>
                              {data?.Airline?.AirlineName}
                            </Text>
                            <Text style={tableCell}>
                              {data?.Airline?.AirlineCode}
                              {" | "}
                              {data?.Airline?.FlightNumber}
                            </Text>
                          </View>
                          <View style={tableColfromto}>
                            <Text style={tableCell}>
                              ({data?.Origin?.Airport?.AirportCode})
                              {data?.Origin?.Airport?.AirportName}
                            </Text>
                          </View>
                          <View style={tableColfromto}>
                            <Text style={tableCell}>
                              ({data?.Destination?.Airport?.AirportCode}){" "}
                              {data?.Destination?.Airport?.AirportName}
                            </Text>
                          </View>
                          <View style={tableColtime}>
                            <Text style={tableCell}>
                              {data?.Origin?.DepTime
                                ? format(
                                    new Date(data?.Origin?.DepTime?.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )
                                : ""}
                            </Text>
                          </View>
                          <View style={tableColtime}>
                            <Text style={tableCell}>
                              {data?.Destination?.ArrTime
                                ? format(
                                    new Date(
                                      data?.Destination?.ArrTime?.toString()
                                    ),
                                    "dd MMM yyyy hh:mm a"
                                  )
                                : ""}
                            </Text>
                          </View>
                          <View style={tableColflight}>
                            <Text style={tableCellInfo}>
                              Cabin: 7Kg, Class: {data?.Airline?.BookingClass}
                            </Text>
                            <Text style={tableCellInfo}>
                              Duration: {data?.JourneyDuration}
                            </Text>
                            <Text style={tableCellInfo}>
                              Baggage: {data?.Baggage}
                            </Text>
                          </View>
                        </View>
                      ))
                    : null}
                </>
              )}
            </View>
          </View>
          {check === "1" ? (
            <View>
              <View>
                <Text
                  style={{
                    fontSize: "12px",
                    color: "#03437B",
                    padding: "20px 0 10px 0",
                  }}
                >
                  PRICE BREAKDOWN
                </Text>
                <View style={table}>
                  <View style={{ margin: "auto", flexDirection: "row" }}>
                    <View style={tableCol}>
                      <Text style={tableCell}>Passenger</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Base Fare</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Tax</Text>
                    </View>
                    <View style={tableCol}>
                      <Text style={tableCell}>Total Fare</Text>
                    </View>
                  </View>
                  {allData?.adult_count > 0 && (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          Adult X {allData?.adult_count}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.adt_base)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.adt_tax)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(adultTotalPrice)} BDT
                        </Text>
                      </View>
                    </View>
                  )}
                  {allData?.child_count > 0 && (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          Child X {allData?.child_count}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.child_base)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.child_tax)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(childTotalPrice)} BDT
                        </Text>
                      </View>
                    </View>
                  )}
                  {allData?.infant_count > 0 && (
                    <View style={tableRow}>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          Infant X {allData?.infant_count}
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.inf_base)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(allData?.inf_tax)} BDT
                        </Text>
                      </View>
                      <View style={tableCol}>
                        <Text style={tableCell}>
                          {commaNumber(infantTotalPrice)} BDT
                        </Text>
                      </View>
                    </View>
                  )}

                  <View style={tableRow}>
                    <View style={tableCol2}>
                      <Text
                        style={{
                          fontSize: "10px",
                          fontWeight: "bold",
                          margin: "3px",
                        }}
                      >
                        Grand Total: {commaNumber(totalPrice)} BDT
                      </Text>
                    </View>
                  </View>
                </View>
              </View>
              <View
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "70%",
                  fontSize: "10px",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "20px 0px",
                  border: "1px solid gray",
                  padding: "10px",
                }}
              >
                <View>
                  {copy === "Agent Invoice" ? (
                    <View>
                      {/* <Text style={{ marginBottom: "5px" }}>Discount</Text> */}
                      {/* <Text>
                        ---------------------------------------------------------------------------------------
                      </Text> */}
                      <Text>Agent Total Ticket Fare Amount</Text>
                    </View>
                  ) : (
                    <View>
                      <Text style={{ marginBottom: "5px" }}>
                        Base fare total amount
                      </Text>
                      <Text style={{ marginBottom: "5px" }}>Tax</Text>
                      <Text>
                        ---------------------------------------------------------------------------------------
                      </Text>

                      <Text>Customer Total Ticket Fare Amount</Text>
                    </View>
                  )}
                </View>
                <View>
                  {copy === "Agent Invoice" ? (
                    <>
                      {/* <Text style={{ marginBottom: "5px" }}>
                        {commaNumber(discount)} BDT
                      </Text>
                      <Text>------------------</Text> */}
                      <Text>{commaNumber(allData?.net_cost)} BDT</Text>
                    </>
                  ) : (
                    <>
                      <Text style={{ marginBottom: "5px" }}>
                        {commaNumber(baseFareTotal || "")} BDT
                      </Text>
                      <Text style={{ marginBottom: "5px" }}>
                        {commaNumber(allData?.tax)} BDT
                      </Text>
                      <Text>------------------</Text>

                      {/* <Text>{commaNumber(allData?.grossCost)} BDT</Text> */}
                      <Text>
                        {parseInt(allData?.gross_cost) + parseInt('10')}{" "}
                        BDT
                      </Text>
                    </>
                  )}
                </View>
              </View>
              <View>
                <Text
                  style={{
                    fontSize: "11px",
                    color: "#03437B",
                    padding: "10px 0px",
                  }}
                >
                  In Words: {capitalize} Taka Only
                </Text>
              </View>
            </View>
          ) : (
            ""
          )}
        </View>
      </Page>
    </Document>
  );
};

export default PDFPageDesign;
