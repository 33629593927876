import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Button,
  Checkbox,
  CircularProgress,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Modal,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { Link, NavLink, useLocation, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import secureLocalStorage from "react-secure-storage";
import useAuthentication from "../../hooks/useAuthentication";
import "./SignIn.css";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import axios from "axios";
import Swal from "sweetalert2";
const SignIn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = secureLocalStorage.getItem("user-info");
  const agentID = user?.user?.agentId;
  const [signOpen, setSignOpen] = useState(false);
  const { email, password } = useParams();

  const rememberUser = secureLocalStorage.getItem("remember");
  const [loginData, setLoginData] = useState({
    email: email || rememberUser?.email || "",
    password: password || rememberUser?.password || "",
  });

  const { loginUser, isLoading, error, adminLogin } = useAuthentication();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const [checkBox, setCheckBox] = useState(rememberUser?.isChecked);
  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
    if (e.target.checked) {
      secureLocalStorage.setItem("remember", {
        email: loginData.email,
        password: loginData.password,
        isChecked: e.target.checked,
      });
    } else {
      secureLocalStorage.removeItem("remember");
    }
  };

  const handleOnChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const newLoginData = { ...loginData };
    newLoginData[field] = value;
    setLoginData(newLoginData);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    const email = e.target.email.value;
    const password = e.target.password.value;

    try {
      const url = "https://api.rtc.flyfarint.com/v1.0/b2b/auth/index.php";
      const formData = new FormData();
      formData.append("email", email);
      formData.append("password", password);

      if (email && password) {
        await axios
          .post(url, formData, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
          .then((res) => {
            console.log(res);
            if (res.data.status === "success" && res.data?.agent?.agent_id) {
              secureLocalStorage.setItem("user-info", res.data.agent);
              secureLocalStorage.setItem("show-ad", true);
              setSignOpen(false);
              navigate("/dashboard");
            } else if (
              res.data.status === "success" &&
              res.data?.staff?.staff_id
            ) {
              secureLocalStorage.setItem("user-info", res.data.staff);
              secureLocalStorage.setItem("show-ad", true);
              setSignOpen(false);
              navigate("/dashboard");
            } else {
              setSignOpen(false);
              Swal.fire({
                imageWidth: 400,
                imageHeight: 200,
                imageAlt: "Custom image",
                title: `${res.data.status.toUpperCase()}`,
                text: `${res.data.message}`,
                confirmButtonColor: "var(--primary-color)",
                confirmButtonText: "Ok",
              }).then(async function (res) {
                setSignOpen(true);
              });
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Box >
      <Box sx={{mb:"20vh"}}>
        <Container>
          <Header />
          <Box
            sx={{
              width: { md: "50%" },
              margin: "30px auto",
            }}
          >
            <form onSubmit={handleLogin}>
              <Grid container spacing={3}>
                <Grid item md={12} sm={12} xs={12}>
                  <Box
                    my={2}
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "20px",
                        fontWeight: "500",
                        textTransform: "uppercase",
                      }}
                    >
                      Sign In
                    </Typography>
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <input
                    placeholder="Enter Your Email"
                    value={loginData.email}
                    name="email"
                    type="email"
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                    onChange={handleOnChange}
                    style={{
                      height: "40px",
                      width: "calc(100% - 20px)",
                      border: "none",
                      outline: "none",
                      borderRadius: "5px",
                      padding: "0px 10px",
                      color: "var(--primary-color)",
                      backgroundColor: "var(--input-bgcolor)",
                      fontSize: "16px",
                    }}
                  />
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box sx={{ position: "relative" }}>
                    <input
                      placeholder="Enter Your Password"
                      required
                      name="password"
                      value={loginData.password}
                      type={showPassword ? "text" : "password"}
                      onChange={handleOnChange}
                      style={{
                        height: "40px",
                        width: "calc(100% - 20px)",
                        border: "none",
                        outline: "none",
                        borderRadius: "5px",
                        padding: "0px 10px",
                        color: "var(--primary-color)",
                        backgroundColor: "var(--input-bgcolor)",
                        fontSize: "16px",
                      }}
                    />
                    <Box
                      onClick={handleClickShowPassword}
                      sx={{
                        position: "absolute",
                        top: "50%",
                        right: "5px",
                        transform: "translate(0,-50%)",
                      }}
                    >
                      {showPassword ? (
                        <Visibility className="fonticon09" />
                      ) : (
                        <VisibilityOff className="fonticon09" />
                      )}
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <FormGroup sx={{ color: "var(--primary-color)" }}>
                    <FormControlLabel
                      sx={{ color: "var(--primary-color)" }}
                      control={
                        rememberUser?.isChecked === true ? (
                          <Checkbox
                            style={{
                              color: "var(--primary-color)",
                            }}
                            defaultChecked
                            onChange={handleCheckBox}
                          />
                        ) : (
                          <Checkbox
                            style={{
                              color: "var(--secondary-color)",
                            }}
                            onChange={handleCheckBox}
                          />
                        )
                      }
                      label="Remember"
                    />
                  </FormGroup>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Box>
                    <NavLink
                      style={{ textDecoration: "none" }}
                      to="/resetpassword"
                    >
                      <Typography
                        style={{
                          color: "var(--primary-color)",
                          fontSize: "16px",
                          width: "100%",
                          textAlign: "left",
                        }}
                      >
                        Forget Password ?
                      </Typography>
                    </NavLink>
                  </Box>
                </Grid>
                <Grid item md={12} sm={12} xs={12}>
                  <Button
                    type="submit"
                    disabled={!isLoading ? true : false}
                    sx={{
                      width: "100%",
                      height: "40px",
                      background: "var(--primary-color)",
                      color: "var(--white) ",
                      "&:hover": {
                        background: "var(--secondary-color)",
                        color: "var(--primary-color) ",
                      },
                    }}
                  >
                    {isLoading ? (
                      "Log In"
                    ) : (
                      <CircularProgress
                        style={{
                          height: "20px",
                          width: "20px",
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Box mt={1}>
                <Box>
                  <Box>
                    {error && (
                      <Alert
                        severity="error"
                        style={{
                          margin: "0px",
                          padding: "0px",
                        }}
                      >
                        <AlertTitle color="red" m={0} p={0}>
                          Error !
                        </AlertTitle>{" "}
                        <strong textAlign={"center"}>{error} !</strong>
                      </Alert>
                    )}
                  </Box>
                  <Typography style={{ color: "var(--primary-color)" }}>
                    Don't have an account?
                    <Button
                      onClick={() => {
                        navigate("/signup");
                      }}
                      style={{ color: "var(--primary-color)" }}
                    >
                      Register Now
                    </Button>
                  </Typography>
                </Box>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>

      <Footer />
    </Box>
  );
};

export default SignIn;
