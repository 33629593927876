import React from "react";
import img from "../../images/SliderImg/s2.png";
import { Box, Button, Grid, Typography, SwipeableDrawer } from "@mui/material";
import { height } from "@mui/system";
import { hover } from "@testing-library/user-event/dist/hover";
import StarRatings from "react-star-ratings";
import { Link, useNavigate } from "react-router-dom";
import Rating from '@mui/material/Rating';
import commaNumber from "comma-number";
import NetworkWifiIcon from "@mui/icons-material/NetworkWifi";
import SmokeFreeIcon from "@mui/icons-material/SmokeFree";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PoolIcon from "@mui/icons-material/Pool";
import LocalBarIcon from "@mui/icons-material/LocalBar";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import SmokingRoomsIcon from '@mui/icons-material/SmokingRooms';
import AccessibleIcon from '@mui/icons-material/Accessible';
import SpaIcon from '@mui/icons-material/Spa';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import WifiIcon from "@mui/icons-material/Wifi";
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import { makeStyles } from "@material-ui/core/styles";
import { SafetyCheck } from "@mui/icons-material";
import notImage from '../../images/Image_not_available.png';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Slider from "@mui/material/Slider";

const useStyles = makeStyles({
  customRating: {
    // Your custom styles here
    color: "red !important",
  },
});

const SingleHotels = ({ hotelData, hotelSearchData, adultCount, childCount }) => {
  const classes = useStyles();

  const codesArray = hotelData?.amenities.map((amenity) => amenity.code);

  const navigate = useNavigate();

  const [openMap, setOpenMap] = React.useState(false);
  const mapSrc = `https://maps.google.com/maps?q=${hotelData?.locationInfo?.latitude},${hotelData?.locationInfo?.longitude}&hl=en&z=16&output=embed`;

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setOpenMap(open);
  };

  const viewMap = () => (
    <Box
      sx={{ width: "40vw", height: "100%", overflow: "hidden" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <iframe
          title="View Map"
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          id="gmap_canvas"
          src={mapSrc}
        ></iframe>
      </Box>
    </Box>
  );

  const handleHotelDetail = () => {
    navigate("/dashboard/hotelDetails", {
      state: {
        hotelData: hotelData,
        hcode: hotelData?.hotelCode,
        priceInfo: hotelData?.priceInfo,
        searchInfo: hotelData?.searchInfo,
        hotelSearchData: hotelSearchData,
        adultCount: adultCount,
        childCount: childCount
      }
    })
  }


  return (
    <Grid
      container
      spacing={2}
      sx={{
        backgroundColor: "white",
        borderRadius: "5px",
        p: 0,
        mb: 4,
        ml: "1px",
      }}
    >
      <Grid item xs={12} sm={12} md={3}>
        <img
          width="100%"
          height="90%"
          src={
            hotelData?.imageInfo?.imageUrl
              ? hotelData?.imageInfo?.imageUrl
              : hotelData?.logo ? hotelData?.logo : notImage
          }
          style={{ borderRadius: "10px", padding: "5px" }}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={9} px={2}>
        <Grid container spacing={1}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid var(  --third-color)",
              py: 0.5,
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontSize: "18px",
                  fontWeight: 500,
                  color: "var(--mateBlack)",
                }}
              >
                {hotelData?.name}
              </Typography>
              <Typography
                sx={{
                  fontSize: "11px",
                  fontWeight: 500,
                  color: "var(--primary-color)",
                  my: "5px"
                }}
              >
                {hotelData?.locationInfo?.addressLine1}
                {hotelData?.locationInfo?.addressLine2}, {hotelData?.locationInfo?.cityName},{hotelData?.locationInfo?.countryName}
              </Typography>
              <Typography
                sx={{
                  fontSize: "13px",
                  color: "var(--third-color)",
                }}
              >
                <span style={{ color: "#109070", fontWeight: "500" }}>Around {hotelData?.distance} Miles to {hotelData?.direction} from City Center | </span> Source : {hotelData?.priceInfo?.rateSource}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ display: "flex", width: "30px", height: "30px", borderRadius: "50%", justifyContent: "center", alignItems: "center", bgcolor: "#FF9292", }} >
                <LocationOnIcon sx={{ color: "var(--red)", cursor: "pointer", fontSize: "21px" }} onClick={toggleDrawer(true)} />
                <SwipeableDrawer
                  anchor="right"
                  open={openMap}
                  onClose={toggleDrawer(false)}
                  onOpen={toggleDrawer(true)}
                >
                  {viewMap()}
                </SwipeableDrawer>
              </Box>
              <Box
                sx={{
                  bgcolor: "#57A853",
                  p: 2,
                  clipPath:
                    "polygon(25% 25%, 75% 25%, 75% 75%, 50% 66.75%, 25% 75%)",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--white)",
                    fontSize: "12px"
                  }}
                >
                  {hotelData?.rating}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 0.5,
              mt: "10px"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "80%", height: "auto", }} gap={2}>
              <Box sx={{ display: "flex", flexWrap: "wrap" }} gap={1}>
                {hotelData?.amenities?.find((item) => item?.code == "68") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <LocalParkingIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Parking
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == "2005") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <LocalBarIcon sx={{ height: "16px", width: "15px", color: "var(--primary-color)" }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Bar
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == "48") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <FitnessCenterIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Gym
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == "76") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <RestaurantMenuIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Restaurant
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == "77") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <RoomServiceIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Room Service
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == "179") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <CellWifiIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Wifi
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == "84") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <SpaIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Spa
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == "71") ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <PoolIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Pool
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == '2002') ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <HealthAndSafetyIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Safety
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == '269') ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <MeetingRoomIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Conference Room
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == '101') ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <AccessibleIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      WheelChair Access
                    </Typography>
                  </Box>
                ) : null}

                {hotelData?.amenities?.find((item) => item?.code == '101') ? (
                  <Box sx={{ display: "flex", alignItems: "center" }} gap={1}>
                    <SmokingRoomsIcon sx={{ height: "16px", width: "15px", color: 'var(--primary-color)' }} />
                    <Typography sx={{ fontSize: "12px", color: "#977E11", fontWeight: "500" }}>
                      Smoking Room
                    </Typography>
                  </Box>
                ) : null}

              </Box>


            </Box>
            <Box>
              <Typography
                sx={{
                  fontSize: "17px",
                  fontWeight: 500,
                  color: "var( --mateBlack)",
                }}
              >
                {" "}
                {commaNumber(hotelData?.priceInfo?.amountAfterTax)} {hotelData?.priceInfo?.currencyCode}
              </Typography>
              <Typography
                sx={{
                  fontSize: "10px",
                  fontWeight: "500",
                  color: "red",
                }}
              >
                {" "}
                Tax : {hotelData?.priceInfo?.currencyCode} {commaNumber(parseFloat(hotelData?.priceInfo?.amountAfterTax) - parseFloat(hotelData?.priceInfo?.amountBeforeTax))}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              py: 0.5,

            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "30px",
                alignItems: "center",
                my: "10px",
              }}
            >

              <Rating
                name="read-only"
                value={hotelData?.rating}
                precision={0.5}
                className="custom-rating"
                readOnly
                sx={{
                  svg: {
                    color: "#FAAF00"
                  }
                }}
              />

              <div style={{ clipPath: 'polygon(85% 0%, 100% 50%, 85% 100%, 0% 100%, 15% 50%, 0% 0%)', backgroundColor: hotelData?.refundable.toLowerCase() === 'refundable' ? "#078946 " : "#F92D3C", width: "140px", height: "25px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={{ fontSize: "11px", fontWeight: "500", color: "white" }}>{hotelData?.refundable}</Typography>
              </div>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Button
                onClick={handleHotelDetail}
                sx={{
                  height: "30px",
                  width: "140px",
                  backgroundColor: "var(--primary-color)",
                  color: "var(--white)",
                  textTransform: "capitalize",
                  ":hover": {
                    backgroundColor: "var(--primary-color)",
                  },
                }}
              >
                See Availability
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SingleHotels;
