import { Box, Button, Container, Grid, Modal, Pagination, Skeleton, Stack, Typography } from "@mui/material";
import React from "react";
import Header from "../Header/Header";
import HotelSearchBox from "./HotelSearchBox";
import { useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import { addDays, format } from "date-fns";
import { useRef } from "react";
import SingleHotels from "./SingleHotels";
import HotelFilter from "./HotelFilter";
import { useEffect } from "react";
import moment from "moment";
import Swal from "sweetalert2";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import { Triangle } from "react-loader-spinner";
import SingleSkeleton from "../../pages/SearchReslut/SingleSkeleton";
import HotelSkeleton from "../../pages/SearchReslut/HotelSkeleton";
import FolderOffIcon from "@mui/icons-material/FolderOff";



const HotelResult = () => {
  const location = useLocation();
  const {
    checkInDate,
    checkOutDate,
    destinationCountry,
    destinationCode,
    code,
    fromSearchText,
    destinationAddress
  } = location.state;

  const hotelSearchData = location?.state;
  const navigate = useNavigate();
  const [dataFromDb, setDataFromDb] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [pageCount, setPageCount] = useState("");
  const [result, setResult] = useState(1);
  const [faddress, setfaddress] = useState("Dhaka, Bangladesh");
  const [toAddress, setToAddress] = useState("Cox's Bazar,Bangladesh");
  const [adultCount, setAdultCount] = useState(location?.state?.adultCount);
  const [childCount, setChildCount] = useState(location?.state?.childCount);
  const [filteredData, setFilteredData] = useState([]);
  const [infant, setInfant] = useState(0);
  let size = 30;

  useEffect(() => {
    setIsLoading(true)
    let unSubscribed = false;
    let url = `https://api.rtc.flyfarint.com/v1.0/b2c/hotel/search.php?location=${destinationCode?.trim()}&checkin=${moment(
      checkInDate
    ).format("YYYY-MM-DD")}&checkout=${moment(checkOutDate).format(
      "YYYY-MM-DD"
    )}&adult=${adultCount}&child=${childCount}&rooms=1`;

    console.log(url);

    fetch(url)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        if (data?.status === 'error') {
          setDataFromDb([]);
          setIsLoading(false);
          return
        }
        else if (!unSubscribed) {
          if (data.length !== 0) {
            const refundableData = data.filter(item => item?.refundable);
            setDataFromDb(refundableData);
            setFilteredData(refundableData);
            setPageCount(Math.ceil(refundableData?.length / size));
            setIsLoading(false);
          } else {
            throw new Error("Failed To Fetch");
          }
        }
      })
      .catch(async (err) => {
        console.log(err.message);
        await Swal.fire({
          // imageUrl: Search,
          imageWidth: 400,
          imageHeight: 200,
          imageAlt: "Custom image",
          title: "No Hotels Found",
          confirmButtonText: "Search Again...",
          confirmButtonColor: "#dc143c",
        }).then(function () {
          navigate("/");
        });
      });
    return () => {
      unSubscribed = true;
    };
  }, [destinationCode, checkInDate, checkOutDate]);

  console.log('Total Data', dataFromDb);
  console.log('Filtered Data',)



  return (
    <Box sx={{ bgcolor: "#EBF0F4" }}>
      <UserHeader />
      <Box
        className="flightSearchParent"
        mt="-15vh"
        borderRadius="3px"
        px="25px"
        sx={{
          margin: "0 auto",
          width: "90%",
        }}
      >
        <Box sx={{ position: "relative", mt: "-70px" }}>
          <Grid container spacing={2}>
            <Grid item md={12}>
              <HotelSearchBox
                faddress={faddress}
                setfaddress={setfaddress}
                toAddress={toAddress}
                setToAddress={setToAddress}
                adultCount={adultCount}
                setAdultCount={setAdultCount}
                childCount={childCount}
                setChildCount={setChildCount}
                infant={infant}
                setInfant={setInfant}
                result={result}
                setResult={setResult}
                cInDate={checkInDate}
                cOutDate={checkOutDate}
                desAddress={destinationAddress}
                desCode={destinationCode}
              />
            </Grid>
            <Grid className="modify-search-info" item md={12} lg={12}>
              <Box sx={{ width: "100%", height: "50px", bgcolor: "white", my: "5px", borderRadius: "3px", display: "flex", justifyContent: "space-between", alignItems: "center", pr: "10px" }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
                  <Typography sx={{ color: "var(--primary-color)", fontWeight: "500", fontSize: "18px", pl: '12px' }}>{filteredData?.length} Hotels Found</Typography>
                  <Typography sx={{ color: "var(--mateblack)", fontSize: "12px", fontWeight: "500" }}>| Guests : {adultCount} Adult {childCount} Child</Typography>
                  <Typography sx={{ color: "var(--mateblack)", fontSize: "12px", fontWeight: "500" }}>| Rooms : 1</Typography>
                  <Typography sx={{ color: "var(--mateblack)", fontSize: "12px", fontWeight: "500" }}>| {moment(new Date(checkInDate)).format('Do MMMM, YYYY')} To {moment(new Date(checkOutDate)).format('Do MMMM, YYYY')} </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={9} lg={9.2} mt={2}>
              {isLoading ? (
                [...new Array(5)].map((data, index) => (
                  <HotelSkeleton key={index} />
                ))
              ) : (
                dataFromDb && dataFromDb.length > 0 ? (
                  filteredData.map((hotelData, index) => (
                    <SingleHotels
                      hotelSearchData={hotelSearchData} key={index} hotelData={hotelData} adultCount={adultCount} childCount={childCount}
                    />
                  ))
                ) : (
                  <Box
                    sx={{
                      bgcolor: "var(--white)",
                      display: "flex",
                      gap: "10px",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: "4px",
                      height: "300px",
                    }}
                  >
                    <FolderOffIcon
                      sx={{
                        fontSize: { xs: "30px", sm: "50px", md: "60px" },
                        color: "var(--secondary-color)",
                      }}
                    />
                    <Typography
                      sx={{
                        color: "var(--primary-color)",
                        fontSize: { xs: "12px", sm: "16px", md: "20px" },
                        fontWeight: "400",
                      }}
                    >
                      No Booking Found
                    </Typography>
                  </Box>
                )
              )}


            </Grid>
            <Grid item xs={12} sm={3} lg={2.8}>
              {
                isLoading ? (
                  [...new Array(1)].map((data, index) => (
                    <Box
                      sx={{
                        width: "100%",
                        borderRadius: "5px",
                        overFlow: "hidden",
                        bgcolor: "var(--white)",
                        padding: "10px",
                        mt: "15px"
                      }}
                    >
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        my={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"50%"}
                          height={"20px"}
                        />
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"50%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"80%"}
                          height={"20px"}
                        />
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"20%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"100%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"80%"}
                          height={"20px"}
                        />
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"20%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"80%"}
                          height={"20px"}
                        />
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"20%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"80%"}
                          height={"20px"}
                        />
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"20%"}
                          height={"20px"}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"70%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"70%"}
                          height={"20px"}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"80%"}
                          height={"20px"}
                        />
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"20%"}
                          height={"20px"}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"70%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"70%"}
                          height={"20px"}
                        />
                      </Stack>
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"70%"}
                          height={"20px"}
                        />
                      </Stack>

                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        spacing={2}
                        mb={2}
                      >
                        <Skeleton
                          sx={{ borderRadius: "5px" }}
                          variant="rectangular"
                          width={"80%"}
                          height={"20px"}
                        />
                      </Stack>
                    </Box>
                  ))
                ) : <HotelFilter dataFromDb={dataFromDb} filteredData={filteredData} setFilteredData={setFilteredData} />
              }
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
export default HotelResult;
