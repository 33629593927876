import { useState } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, NavLink, useNavigate } from "react-router-dom";
import MuiListItem from "@material-ui/core/ListItem";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StorageIcon from "@mui/icons-material/Storage";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Button, SwipeableDrawer } from "@mui/material";
import { HiMenuAlt1 } from "react-icons/hi";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import "./UserDashboardSideBar.css";
import secureLocalStorage from "react-secure-storage";
import { useContext } from "react";
import { UserInfoContext } from "../Contexts/UserInfoProvider";
import Swal from "sweetalert2";
import Tooltip from "@mui/material/Tooltip";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "var(--wh)",
      color: "grey",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "grey",
      },
    },
    "&$selected:hover": {
      backgroundColor: "var(--white)",
      color: "grey",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "var(--primary-color)",
      },
    },
    "&:hover": {
      backgroundColor: "var(--void)",
      color: "grey",
      width: "90%",
      borderRadius: "0 5px 5px 0",
      "& .MuiListItemIcon-root": {
        color: "red",
      },
    },
  },
  selected: {},
})(MuiListItem);

const SubListItem = withStyles({
  subselected: {},
})(MuiListItem);

// Active color ListItem end

const drawerWidth = 210;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 0px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7)} + 5px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const UserDashBoardSideBar = () => {
  const [open, setOpen] = useState(false);
  const [dashboard, setDashboard] = useState(false);
  const [search, setSearch] = useState(false);
  const [manage, setManage] = useState(false);
  const [booking, setBooking] = useState(false);
  const [account, setAccount] = useState(false);
  const [report, setReport] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState();
  const { userData } = useContext(UserInfoContext);
  const navigate = useNavigate();
  const user = secureLocalStorage.getItem("user-info");

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  // toggle drower
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    if (index === 1) {
      setOpen(true);
      setDashboard(!dashboard);
      setSearch(false);
      setManage(false);
      setBooking(false);
      setAccount(false);
      setReport(false);
    } else if (index === 2) {
      setOpen(true);
      setDashboard(false);
      setSearch(!search);
      setManage(false);
      setBooking(false);
      setAccount(false);
      setReport(false);
    } else if (index === 3) {
      setOpen(true);
      setDashboard(false);
      setSearch(false);
      setManage(!manage);
      setBooking(false);
      setAccount(false);
      setReport(false);
    } else if (index === 4) {
      setOpen(true);
      setDashboard(false);
      setSearch(false);
      setManage(false);
      setBooking(!booking);
      setAccount(false);
      setReport(false);
    } else if (index === 5) {
      setOpen(true);
      setDashboard(false);
      setSearch(false);
      setManage(false);
      setBooking(false);
      setAccount(!account);
      setReport(false);
    } else if (index === 6) {
      setOpen(true);
      setDashboard(false);
      setSearch(false);
      setManage(false);
      setBooking(false);
      setAccount(false);
      setReport(!report);
    }
  };

  const handleLogOut = () => {
    // Display a SweetAlert confirmation before logging out
    Swal.fire({
      title: "Are you sure?",
      text: "You are about to log out.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, log me out!",
    }).then((result) => {
      if (result.isConfirmed) {
        secureLocalStorage.removeItem("user-info");
        Swal.fire({
          title: "Logged Out!",
          text: "You have been successfully logged out.",
          icon: "success",
        });

        navigate("/");
      }
    });
  };

  return (
    <Box
      sx={{
        display: "flex",
        bgcolor: "white",
        "&:MuiTypography-root": { fontSize: "5px" },
      }}
      className="sideBar-user"
    >
      <CssBaseline />

      {/*For Mobile Screen */}
      <Box
        sx={{
          display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          // background:'red',
          position: "absolute",
          right: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
      >
        {["left"].map((anchor) => (
          <Box key={anchor}>
            <Button onClick={toggleDrawer(anchor, true)}>
              <HiMenuAlt1
                style={{
                  color: "var(--white)",
                  fontSize: "35px",
                  marginTop: "20px",
                }}
              />
            </Button>
            <SwipeableDrawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
              onOpen={toggleDrawer(anchor, true)}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "90%",
                  margin: "0 auto",
                  gap: "10px",
                  pt:"15px"
                }}
              >
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <AccountCircleIcon
                    sx={{ fontSize: "50px", color: "grey" }}
                  />
                  <Box>
                    <Typography
                      sx={{ color: "var(--black)", fontSize: "15px" }}
                    >
                      {user?.agent_name || user?.staff_name}
                    </Typography>
                    <Typography
                      sx={{ color: "var(--black)", fontSize: "13px" }}
                    >
                      {user?.agent_comp_name}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                backgroundColor="white"
                height="100vh"
                width="250px"
                sx={{ mt: "20px" }}
              >
                <List>
                  {/* Dashboard section */}
                  <NavLink
                    to="/dashboard"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      color: "grey",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      selected={selectedIndex === 1}
                      onClick={(event) => handleListItemClick(event, 1)}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                          color: selectedIndex === 1 ? "#22222" : "grey",
                        }}
                      >
                        <DashboardIcon sx={{ fontSize: "16px" }} />
                        <ListItemText
                          sx={{ ml: 1, textDecoration: "none", color: "grey" }}
                        >
                          Dashboard
                        </ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>

                  {/* Search section */}

                  <NavLink
                    to="/dashboard/searchhistory"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      selected={selectedIndex === 2}
                      onClick={(event) => handleListItemClick(event, 2)}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                          color: selectedIndex === 2 ? "#22222" : "grey",
                        }}
                      >
                        <ContentPasteSearchIcon sx={{ fontSize: "16px" }} />
                        <ListItemText sx={{ ml: 1 }}>Search Log</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>

                  <NavLink
                    to="/dashboard/staff"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      selected={selectedIndex === 3}
                      onClick={(event) => handleListItemClick(event, 3)}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                          color: selectedIndex === 3 ? "#22222" : "grey",
                        }}
                      >
                        <StorageIcon sx={{ fontSize: "16px" }} />
                        <ListItemText sx={{ ml: 1 }}>Manage</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                  {/* Sub Menu Manage Section start  */}
                  {selectedIndex === 3 && manage && (
                    <Box className="dash-sub-menu sub-trans">
                      <Box
                        sx={{
                          opacity: open ? 1 : 0,
                          display: open === true ? "" : "none",
                        }}
                      >
                        <NavLink
                          to="/dashboard/staff"
                          style={{
                            display: "block",
                            marginTop: "5px",
                            textDecoration: "none",
                          }}
                        >
                          <SubListItem
                            // subselected={subSelectedIndex === 2}
                            // onClick={(event) => handleSubListItemClick(event, 2)}
                            style={{ padding: "0px" }}
                          >
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                p: "0px 3px 0px 18px",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  ml: 1,
                                }}
                              >
                                Staff Manager
                              </ListItemText>
                            </ListItemButton>
                          </SubListItem>
                        </NavLink>
                      </Box>
                    </Box>
                  )}

                  <NavLink
                    to="/dashboard/airticketbooking"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      selected={selectedIndex === 4}
                      onClick={(event) => handleListItemClick(event, 4)}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                          color: selectedIndex === 4 ? "#22222" : "grey",
                        }}
                      >
                        <AssessmentIcon sx={{ fontSize: "16px" }} />
                        <ListItemText sx={{ ml: 1 }}>Bookings</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                  {/* Sub Menu Booking Section end  */}
                  {selectedIndex === 4 && booking && (
                    <Box className="dash-sub-menu sub-trans">
                      <Box
                        sx={{
                          opacity: open ? 1 : 0,
                          display: open === true ? "" : "none",
                        }}
                      >
                        <NavLink
                          to="/dashboard/airticketbooking"
                          style={{ display: "block", textDecoration: "none" }}
                        >
                          <SubListItem style={{ padding: "0px" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                p: "0px 3px 0px 18px",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  ml: 1,
                                }}
                              >
                                Air Ticket
                              </ListItemText>
                            </ListItemButton>
                          </SubListItem>
                        </NavLink>
                        <NavLink
                          to="/dashboard/allhotelbooking"
                          style={{
                            display: "block",
                            marginTop: "5px",
                            textDecoration: "none",
                          }}
                        >
                          <SubListItem style={{ padding: "0px" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                p: "0px 3px 0px 18px",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  ml: 1,
                                }}
                              >
                                Hotel
                              </ListItemText>
                            </ListItemButton>
                          </SubListItem>
                        </NavLink>
                        <NavLink
                          to="/dashboard/groupfarebooking"
                          style={{
                            display: "block",
                            marginTop: "5px",
                            textDecoration: "none",
                          }}
                        >
                          <SubListItem style={{ padding: "0px" }}>
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                p: "0px 3px 0px 18px",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  ml: 1,
                                }}
                              >
                                Group Fare
                              </ListItemText>
                            </ListItemButton>
                          </SubListItem>
                        </NavLink>
                        <NavLink
                          to="/dashboard/othersbooking"
                          style={{ display: "block", textDecoration: "none" }}
                        >
                          <SubListItem
                            style={{ padding: "0px", marginTop: "5px" }}
                          >
                            <ListItemButton
                              sx={{
                                justifyContent: open ? "initial" : "center",
                                p: "0px 3px 0px 18px",
                              }}
                            >
                              <ListItemText
                                sx={{
                                  ml: 1,
                                }}
                              >
                                Others
                              </ListItemText>
                            </ListItemButton>
                          </SubListItem>
                        </NavLink>
                      </Box>
                    </Box>
                  )}

                  {/* Sub Menu Account Section   */}
                  <Box bgcolor={selectedIndex === 5 ? "" : ""}>
                    <NavLink
                      to="/dashboard/account"
                      style={{
                        display: "block",
                        marginBottom: "10px",
                        textDecoration: "none",
                      }}
                    >
                      <ListItem
                        selected={selectedIndex === 5}
                        onClick={(event) => handleListItemClick(event, 5)}
                        style={{ padding: "0px" }}
                      >
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "5px 3px 5px 20px",
                            color: selectedIndex === 5 ? "#22222" : "grey",
                          }}
                        >
                          <AccountBalanceIcon sx={{ fontSize: "16px" }} />
                          <ListItemText sx={{ ml: 1 }}>Account</ListItemText>
                        </ListItemButton>
                      </ListItem>
                    </NavLink>
                    {/* Sub Menu Account Section start  */}

                    {selectedIndex === 5 && account && (
                      <Box className="dash-sub-menu sub-trans">
                        <Box
                          sx={{
                            display: open === true ? "" : "none",
                          }}
                        >
                          <NavLink
                            to="/dashboard/account"
                            style={{ display: "block" }}
                          >
                            <SubListItem style={{ padding: "0px" }}>
                              <ListItemButton
                                sx={{
                                  justifyContent: open ? "initial" : "center",
                                  p: "0px 3px 0px 18px",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    ml: 1,
                                  }}
                                >
                                  Profile
                                </ListItemText>
                              </ListItemButton>
                            </SubListItem>
                          </NavLink>

                          <NavLink
                            to="/dashboard/deposit"
                            style={{ display: "block", textDecoration: "none" }}
                          >
                            <SubListItem
                              style={{ padding: "0px", marginTop: "5px" }}
                            >
                              <ListItemButton
                                sx={{
                                  justifyContent: open ? "initial" : "center",
                                  p: "0px 3px 0px 18px",
                                }}
                              >
                                <ListItemText
                                  sx={{
                                    ml: 1,
                                  }}
                                >
                                  Deposit Request
                                </ListItemText>
                              </ListItemButton>
                            </SubListItem>
                          </NavLink>
                          <NavLink
                            to="/dashboard/account/GeneralLedger"
                            style={{ display: "block" }}
                          ></NavLink>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  {/* Sub Menu Account Section end  */}

                  <NavLink
                    to="/dashboard/searchreport"
                    style={{
                      display: "block",
                      marginBottom: "10px",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      selected={selectedIndex === 6}
                      onClick={(event) => handleListItemClick(event, 6)}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                          color: selectedIndex === 6 ? "#22222" : "grey",
                        }}
                      >
                        <AssessmentIcon sx={{ fontSize: "16px" }} />
                        <ListItemText sx={{ ml: 1 }}>Report</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>

                  {/* Sub Menu report Section end  */}

                  {/* Sub Menu report Section end  */}

                  <NavLink
                    style={{
                      display: "block",
                      marginTop: "30%",
                      backgroundColor: "var(--secondary-color)",
                      color: "white",
                      width: "90%",
                      borderTopRightRadius: "4px",
                      borderBottomRightRadius: "4px",
                      textDecoration: "none",
                    }}
                  >
                    <ListItem
                      // selected={selectedIndex === 6}
                      onClick={handleLogOut}
                      style={{ padding: "0px" }}
                    >
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "5px 3px 5px 20px",
                        }}
                      >
                        <ExitToAppIcon />
                        <ListItemText sx={{ ml: 1 }}>Log Out</ListItemText>
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                </List>
              </Box>
            </SwipeableDrawer>
          </Box>
        ))}
      </Box>
      {/*for screen*/}
      <Box
        sx={{
          display: { xs: "none", sm: "block", md: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
          },
        }}
      >
        <Drawer variant="permanent" open={open}>
          <DrawerHeader
            style={{
              display: "block",

              background: "var(--secondary-color)",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              onClick={() => setOpen(!open)}
            >
              <MenuIcon
                sx={{
                  fontSize: "22px",
                  margin: "20px 15px 10px 15px",
                  cursor: "pointer",
                  color: "var(--primary-color)",
                  bgcolor: "var(--white)",
                  p: "10px",
                  height: "40px",
                  width: "40px",
                  textAlign: "right",
                  borderTopLeftRadius: "4px",
                  position: "absolute",
                  right: "-16px",
                  zIndex: 9999,
                }}
              />

              <Box sx={{ width: "120px", height: "auto", my: "20px" }}>
                {userData?.agent_comp_img ? (
                  <img
                    style={{
                      width: "100%",
                      visibility: `${open === false ? "hidden" : "visible"}`,
                    }}
                    src={`${
                      userData?.agent_comp_img
                    }?t=${new Date().getTime()}`}
                    alt=""
                  />
                ) : (
                  <Tooltip title={userData?.agent_comp_name} arrow>
                    <Typography
                      sx={{
                        color: "var(--white)",
                        pl: "20px",
                        pt: "20px",
                      }}
                    >
                      {userData?.agent_comp_name?.length > 15
                        ? userData?.agent_comp_name.slice(0, 15) + "..."
                        : userData?.agent_comp_name}
                    </Typography>
                  </Tooltip>
                )}
              </Box>
            </Box>
          </DrawerHeader>

          <Box
            sx={{ background: "var(--secondary-color)", pt: "20px" }}
            height="100vh"
          >
            <List>
              {/* Dashboard section */}
              <NavLink
                to="/dashboard"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "var(--white)",
                }}
              >
                <ListItem
                  selected={selectedIndex === 1}
                  onClick={(event) => handleListItemClick(event, 1)}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                      color:
                        selectedIndex === 1
                          ? "#22222"
                          : "var(--primary-black-color)",
                    }}
                  >
                    <DashboardIcon sx={{ fontSize: "16px" }} />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Dashboard
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* Search section */}

              <NavLink
                to="/dashboard/searchhistory"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "var(--white)",
                }}
              >
                <ListItem
                  selected={selectedIndex === 2}
                  onClick={(event) => handleListItemClick(event, 2)}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                      color:
                        selectedIndex === 2
                          ? "#22222"
                          : "var(--primary-black-color)",
                    }}
                  >
                    <ContentPasteSearchIcon sx={{ fontSize: "16px" }} />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Search Log
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              <NavLink
                to="/dashboard/staff"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "var(--white)",
                }}
              >
                <ListItem
                  selected={selectedIndex === 3}
                  onClick={(event) => handleListItemClick(event, 3)}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                      color:
                        selectedIndex === 3
                          ? "#22222"
                          : "var(--primary-black-color)",
                    }}
                  >
                    <StorageIcon sx={{ fontSize: "16px" }} />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Manage
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Sub Menu Manage Section start  */}
              {selectedIndex === 3 && manage && (
                <Box className="dash-sub-menu sub-trans">
                  <Box
                    sx={{
                      opacity: open ? 1 : 0,
                      display: open === true ? "" : "none",
                    }}
                  >
                    <NavLink
                      to="/dashboard/staff"
                      style={{ display: "block", marginTop: "5px" }}
                    >
                      <SubListItem
                        // subselected={subSelectedIndex === 2}
                        // onClick={(event) => handleSubListItemClick(event, 2)}
                        style={{ padding: "0px" }}
                      >
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              opacity: open ? 1 : 0,
                              ml: 1,
                            }}
                          >
                            Staff Manager
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink>
                  </Box>
                </Box>
              )}

              <NavLink
                to="/dashboard/airticketbooking"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "var(--white)",
                }}
              >
                <ListItem
                  selected={selectedIndex === 4}
                  onClick={(event) => handleListItemClick(event, 4)}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                      color:
                        selectedIndex === 4
                          ? "#22222"
                          : "var(--primary-black-color)",
                    }}
                  >
                    <AssessmentIcon sx={{ fontSize: "16px" }} />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Bookings
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
              {/* Sub Menu Booking Section end  */}
              {selectedIndex === 4 && booking && (
                <Box className="dash-sub-menu sub-trans">
                  <Box
                    sx={{
                      opacity: open ? 1 : 0,
                      display: open === true ? "" : "none",
                    }}
                  >
                    <NavLink
                      to="/dashboard/airticketbooking"
                      style={{ display: "block" }}
                    >
                      <SubListItem style={{ padding: "0px" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              opacity: open ? 1 : 0,
                              ml: 1,
                            }}
                          >
                            Air Ticket
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink>
                    <NavLink
                      to="/dashboard/allhotelbooking"
                      style={{ display: "block", marginTop: "5px" }}
                    >
                      <SubListItem style={{ padding: "0px" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              opacity: open ? 1 : 0,
                              ml: 1,
                            }}
                          >
                            Hotel
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink>
                    <NavLink
                      to="/dashboard/groupfarebooking"
                      style={{ display: "block", marginTop: "5px" }}
                    >
                      <SubListItem style={{ padding: "0px" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              opacity: open ? 1 : 0,
                              ml: 1,
                            }}
                          >
                            Group Fare
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink>
                    <NavLink
                      to="/dashboard/othersbooking"
                      style={{ display: "block" }}
                    >
                      <SubListItem style={{ padding: "0px", marginTop: "5px" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              opacity: open ? 1 : 0,
                              ml: 1,
                            }}
                          >
                            Others
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink>
                  </Box>
                </Box>
              )}

              {/* Sub Menu Account Section   */}
              <Box bgcolor={selectedIndex === 5 ? "" : ""}>
                <NavLink
                  to="/dashboard/account"
                  style={{
                    display: "block",
                    marginBottom: "10px",
                    color: "var(--white)",
                  }}
                >
                  <ListItem
                    selected={selectedIndex === 5}
                    onClick={(event) => handleListItemClick(event, 5)}
                    style={{ padding: "0px" }}
                  >
                    <ListItemButton
                      sx={{
                        justifyContent: open ? "initial" : "center",
                        p: "5px 3px 5px 20px",
                        color:
                          selectedIndex === 5
                            ? "#22222"
                            : "var(--primary-black-color)",
                      }}
                    >
                      <AccountBalanceIcon sx={{ fontSize: "16px" }} />
                      <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                        Account
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                </NavLink>
                {/* Sub Menu Account Section start  */}

                {selectedIndex === 5 && account && (
                  <Box className="dash-sub-menu sub-trans">
                    <Box
                      sx={{
                        opacity: open ? 1 : 0,
                        display: open === true ? "" : "none",
                      }}
                    >
                      <NavLink
                        to="/dashboard/account"
                        style={{ display: "block" }}
                      >
                        <SubListItem style={{ padding: "0px" }}>
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              p: "0px 3px 0px 18px",
                            }}
                          >
                            <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                ml: 1,
                              }}
                            >
                              Profile
                            </ListItemText>
                          </ListItemButton>
                        </SubListItem>
                      </NavLink>

                      {/* <NavLink
                      to="/dashboard/transactions"
                      style={{ display: "block" }}
                    >
                      <SubListItem style={{ padding: "0px", marginTop: "5px" }}>
                        <ListItemButton
                          sx={{
                            justifyContent: open ? "initial" : "center",
                            p: "0px 3px 0px 18px",
                          }}
                        >
                          <ListItemText
                            sx={{
                              opacity: open ? 1 : 0,
                              ml: 1,
                            }}
                          >
                            Transactions
                          </ListItemText>
                        </ListItemButton>
                      </SubListItem>
                    </NavLink> */}
                      <NavLink
                        to="/dashboard/deposit"
                        style={{ display: "block" }}
                      >
                        <SubListItem
                          style={{ padding: "0px", marginTop: "5px" }}
                        >
                          <ListItemButton
                            sx={{
                              justifyContent: open ? "initial" : "center",
                              p: "0px 3px 0px 18px",
                            }}
                          >
                            <ListItemText
                              sx={{
                                opacity: open ? 1 : 0,
                                ml: 1,
                              }}
                            >
                              Deposit Request
                            </ListItemText>
                          </ListItemButton>
                        </SubListItem>
                      </NavLink>
                      <NavLink
                        to="/dashboard/account/GeneralLedger"
                        style={{ display: "block" }}
                      ></NavLink>
                    </Box>
                  </Box>
                )}
              </Box>
              {/* Sub Menu Account Section end  */}

              <NavLink
                to="/dashboard/searchreport"
                style={{
                  display: "block",
                  marginBottom: "10px",
                  color: "var(--white)",
                }}
              >
                <ListItem
                  selected={selectedIndex === 6}
                  onClick={(event) => handleListItemClick(event, 6)}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                      color:
                        selectedIndex === 6
                          ? "#22222"
                          : "var(--primary-black-color)",
                    }}
                  >
                    <AssessmentIcon sx={{ fontSize: "16px" }} />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Report
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>

              {/* Sub Menu report Section end  */}
              {/* {selectedIndex === 6 && report && (
              <Box className="dash-sub-menu sub-trans">
                <Box
                  sx={{
                    opacity: open ? 1 : 0,
                    display: open === true ? "" : "none",
                  }}
                >
                  <NavLink
                    to="/dashboard/searchreport"
                    style={{ display: "block" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Search Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink
                    to="/dashboard/generalledger"
                    style={{ display: "block", marginTop: "5px" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Ledger Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink
                    to="/dashboard/salesReport"
                    style={{ display: "block", marginTop: "5px" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Sales Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink
                    to="/dashboard/refundreport"
                    style={{ display: "block", marginTop: "5px" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Refund Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink
                    to="/dashboard/reissuereport"
                    style={{ display: "block", marginTop: "5px" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Reissue Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                  <NavLink
                    to="/dashboard/vendorreport"
                    style={{ display: "block", marginTop: "5px" }}
                  >
                    <SubListItem style={{ padding: "0px" }}>
                      <ListItemButton
                        sx={{
                          justifyContent: open ? "initial" : "center",
                          p: "0px 3px 0px 18px",
                        }}
                      >
                        <ListItemText
                          sx={{
                            opacity: open ? 1 : 0,
                            ml: 1,
                          }}
                        >
                          Vendor Report
                        </ListItemText>
                      </ListItemButton>
                    </SubListItem>
                  </NavLink>
                </Box>
              </Box>
            )} */}
              {/* Sub Menu report Section end  */}

              <NavLink
                style={{
                  display: "block",
                  marginTop: "30%",
                  backgroundColor: "var(--secondary-color)",
                  color: "var(--white)",
                  width: "90%",
                  borderTopRightRadius: "4px",
                  borderBottomRightRadius: "4px",
                }}
              >
                <ListItem
                  // selected={selectedIndex === 6}
                  onClick={handleLogOut}
                  style={{ padding: "0px" }}
                >
                  <ListItemButton
                    sx={{
                      justifyContent: open ? "initial" : "center",
                      p: "5px 3px 5px 20px",
                    }}
                  >
                    <ExitToAppIcon />
                    <ListItemText sx={{ opacity: open ? 1 : 0, ml: 1 }}>
                      Log Out
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              </NavLink>
            </List>
          </Box>
        </Drawer>
      </Box>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          px: 1,
          width: {
            md: `calc(100% - ${drawerWidth}px)`,
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <Outlet></Outlet>
      </Box>
    </Box>
  );
};

export default UserDashBoardSideBar;
