import { Box, Typography } from "@mui/material";
import MailIcon from "@mui/icons-material/Mail";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import secureLocalStorage from "react-secure-storage";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useEffect } from "react";
import { useState } from "react";
import commaNumber from "comma-number";
const isMobile = window.innerWidth <= 768;

const UserHeader = () => {
  const user = secureLocalStorage.getItem("user-info");
  const agentId = user?.agent_id;

  // useEffect(() => {
  //   fetch(
  //     `https://api.rtc.flyfarint.com/v1.0/b2b/accountinfo/index.php?agentId=${agentId}`
  //   )
  //     .then((res) => res.json())
  //     .then((data) => setuser(data?.agents));
  // }, []);

  // console.log(user)

  return (
    <Box
      sx={{
        backgroundColor: "var(--secondary-color)",
        pt: "2vh",
        pb: "15vh",
        zIndex: -999,
      }}
    >
      {/* <Container> */}
      {isMobile ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            margin: "0 auto",
            gap: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <AccountCircleIcon
              sx={{ fontSize: "50px", color: "var(--white)" }}
            />
            <Box>
              <Typography sx={{ color: "var(--white)", fontSize: "15px" }}>
                {user?.agent_name || user?.staff_name}
              </Typography>
              <Typography sx={{ color: "var(--white)", fontSize: "13px" }}>
                {user?.agent_comp_name}
              </Typography>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mx: "10px",
              gap: 1,
            }}
          >
            <MailIcon sx={"color: var(--secondary-color)"} />
            <Typography sx={{ fontSize: "14px", color: "var(--white)" }}>
              {user?.agent_email}
            </Typography>
          </Box> */}
            {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mx: "10px",
              gap: 1,
            }}
          >
            <PermPhoneMsgIcon sx={{ color: "var(--secondary-color)" }} />
            <Typography sx={{ fontSize: "14px", color: "var(--white)" }}>
              {user?.agent_phone_number}
            </Typography>
          </Box> */}

           
            {user?.agent_bonus ? (
              <Box sx={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"100%"}}>
                <Box
                  sx={{
                    background: "var(--secondary-color)",
                    // width: {
                    //   xs: "80%",
                    //   sm: "90%",
                    //   md: "130px",
                    //   lg: "130px",
                    // },
                    borderRadius: "5px",
                       padding: "3px 0px 3px 20px",
                  }}
                >
                  <Typography
                    style={{
                      color: "var(--white)",
                      fontSize: "12px",
                    }}
                  >
                    Bonus
                  </Typography>
                  <Typography
                    style={{
                      color: "var(--white)",
                      fontSize: "14px",
                      fontWeight: "500",
                    }}
                  >
                    {commaNumber(Number(user?.agent_bonus))} BDT
                  </Typography>
                </Box>
                <Box
                  style={{
                    background: "var(--secondary-color)",
                    // width: "130px",
                    borderRadius: "5px",
                    padding: "3px 0px 3px 20px",
                    
                  }}
                >
                  <Typography
                    style={{
                      color: "var(--white)",
                      fontSize: "12px",
                      textAlign:"right"
                    }}
                  >
                    Balance
                  </Typography>
                  <Typography
                    style={{
                      color: "var(--white)",
                      fontSize: "14px",
                      fontWeight: "500",
                      textAlign:"right"
                    }}
                  >
                    {commaNumber(Number(user?.last_amount))} BDT
                  </Typography>
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "90%",
              margin: "0 auto",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <AccountCircleIcon
                sx={{ fontSize: "50px", color: "var(--white)" }}
              />
              <Box>
                <Typography sx={{ color: "var(--white)", fontSize: "15px" }}>
                  {user?.agent_name || user?.staff_name}
                </Typography>
                <Typography sx={{ color: "var(--white)", fontSize: "13px" }}>
                  {user?.agent_comp_name}
                </Typography>
              </Box>
            </Box>
            <Box
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mx: "10px",
              gap: 1,
            }}
          >
            <MailIcon sx={"color: var(--secondary-color)"} />
            <Typography sx={{ fontSize: "14px", color: "var(--white)" }}>
              {user?.agent_email}
            </Typography>
          </Box> */}
              {/* <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mx: "10px",
              gap: 1,
            }}
          >
            <PermPhoneMsgIcon sx={{ color: "var(--secondary-color)" }} />
            <Typography sx={{ fontSize: "14px", color: "var(--white)" }}>
              {user?.agent_phone_number}
            </Typography>
          </Box> */}

              {}
              {user?.agent_bonus ? (
                <>
                  <Box
                    style={{
                      background: "var(--white)",
                      width: "130px",
                      borderRadius: "5px",
                      padding: "3px 0px 3px 20px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "12px",
                      }}
                    >
                      Bonus
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {commaNumber(Number(user?.agent_bonus))} BDT
                    </Typography>
                  </Box>
                  <Box
                    style={{
                      background: "var(--white)",
                      width: "130px",
                      borderRadius: "5px",
                      padding: "3px 0px 3px 20px",
                    }}
                  >
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "12px",
                      }}
                    >
                      Balance
                    </Typography>
                    <Typography
                      style={{
                        color: "var(--primary-color)",
                        fontSize: "14px",
                        fontWeight: "500",
                      }}
                    >
                      {commaNumber(Number(user?.last_amount))} BDT
                    </Typography>
                  </Box>
                </>
              ) : (
                ""
              )}
            </Box>
          </Box>
        </>
      )}

      {/* </Container> */}
    </Box>
  );
};

export default UserHeader;
