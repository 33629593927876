import React, { useEffect, useState } from "react";
import { Box, Button, Container, Typography, Grid } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import UserHeader from "../../components/UserPage/UserHeader/UserHeader";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import secureLocalStorage from "react-secure-storage";
const isMobile = window.innerWidth <= 768;

const Deposite = () => {
  // const navigate = useNavigate();
  const styles = {
    root: {
      height: "calc(100vh - 200px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
    },
    dataGrid: {
      flexGrow: 1,
    },
  };

  const [allDeposite, setAllDeposite] = useState([]);
  const [filteredDepositeData, setFilteredDepositeData] = useState([]);
  const [filter, setFilter] = useState("all");

  const user = secureLocalStorage.getItem("user-info");

  useEffect(() => {
    fetch(
      `https://api.rtc.flyfarint.com/v1.0/admin/deposit/index.php?agentId=${user?.agent_id}`
    )
      .then((res) => res.json())
      .then((data) => {
        setAllDeposite(data?.deposits);
        setFilteredDepositeData(data?.deposits);
      });
  }, []);

  const columns = [
    {
      field: "deposit_id",
      headerName: "reference",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "status",
      headerName: "status",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "payment_type",
      headerName: "type",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "amount",
      headerName: "amount",
      suppressSizeToFit: true,
      width: 150,
    },
    {
      field: "transaction_date",
      headerName: "transaction date",
      suppressSizeToFit: true,
      width: 150,
    },

    {
      field: "attachment",
      headerName: "attachment",
      suppressSizeToFit: true,
      width: 150,
      renderCell: (params) => {
        return (
          <span>
            <a
              href={`${params.row.attachment}`}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer", color: "blue" }}
            >
              view
            </a>
          </span>
        );
      },
    },
    {
      field: "remarks",
      headerName: "rejected reason",
      suppressSizeToFit: true,
      width: 150,
    },
  ];

  const handleFilterByStatus = (e) => {
    const value = e.target.value;
    setFilter(value);

    if (value === "all") {
      setFilteredDepositeData(allDeposite);
    } else {
      const filteredData = allDeposite.filter((data) => {
        return data.status === value;
      });
      setFilteredDepositeData(filteredData);
    }
  };

  return (
    <Box>
      <UserHeader />
      <Box
        sx={{
          backgroundColor: "var(--white)",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          p: "20px",
          m: "0 auto",
          width: "90%",
          mt: "-10vh",
        }}
      >
        <Box
          sx={{
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            variant="span"
            sx={{
              fontWeight: 500,
              fontSize: "18px !important",
              color: "var(--black)",
              fontFamily: "poppins",
            }}
          >
            Deposit
          </Typography>

          <Box sx={{ display: "flex", gap: 5 }}>
            <Link
              to={"/dashboard/adddeposit"}
              style={{
                textDecoration: "none",
                color: "var(--primary-color)",
              }}
            >
              <Button
                sx={{
                  width: "120px",
                  fontSize: "11px",
                  textTransform: "capitalize",
                  // height: "28px",
                  background: "var(--black)",
                  color: "var(--white)",
                  "&:hover": {
                    backgroundColor: "var(--secondary-color)",
                  },
                }}
              >
                Add Deposit
              </Button>
            </Link>
          </Box>
        </Box>
        <Box>
          {/* <DepositeTabs /> */}

          <Box
            style={styles.root}
            sx={{
              position: "relative",
              mt: 2,
              "& .MuiDataGrid-root": {
                border: "none",
                marginTop: "0px !important",
                fontSize:"10px !important"
              },
              "& .MuiDataGrid-cell": {
                borderBottom: "1px solid var(--input-bgcolor)",
                marginTop: "0px !important",
                fontSize: "11px",
              },
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "var(--primary-color)",
                borderBottom: "none",
                color: "var(--white)",
                fontSize: "10px",
                textTransform: "uppercase",
              },
              "& .MuiDataGrid-virtualScroller": {
                backgroundColor: "var(--body-color)",
              },
              "& .MuiDataGrid-footerContainer": {
                borderTop: "none",
                backgroundColor: "var(--primary-color)",
                color: "var(--white)",
              },

              "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
                color: "var(--primary-color)",
                background: "var(--secondary-color)",
                fontSize: "10px",
                mb: 2,
              },
              ".MuiDataGrid-columnHeaderDraggableContainer .MuiSvgIcon-root": {
                color: "var(--white)",
              },
              ".MuiTablePagination-selectLabel": {
                color: "var(--white)",
              },
              ".MuiSelect-select": {
                color: "var(--white)",
              },
              ".css-levciy-MuiTablePagination-displayedRows": {
                color: "var(--white)",
              },
              ".MuiDataGrid-cellContent": {
                color: "var(--text-color)",
              },
            }}
          >
            {isMobile ? (
              <>
                <Grid sx={{ mt: "60px" }} container gap={2}>
                  {filteredDepositeData?.map((sales) => (
                    <Grid
                      container
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      key={sales?.id}
                      sx={{
                        display: {
                          xs: "block",
                          sm: "flex",
                          md: "flex",
                        },
                        transition: "all .5s ease-in-out",
                        background: "var(--gray)",
                        borderRadius: "5px",
                        width: "100%",
                        borderRadius: "10px",
                        px: "20px",
                        py: "25px",
                      }}
                    >
                      <Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: "11px",
                              display: "flex",
                              alignItems: "center",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            Reference
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales?.deposit_id}
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "30px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Status
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.status}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Type
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.payment_type}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Amount
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.amount}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            mt: "10px",
                          }}
                          gap={1}
                        >
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            Transaction Date
                          </Typography>
                          <Typography
                            sx={{
                              color: "var(--mattblack)",
                              fontWeight: 500,
                              fontSize: {
                                xs: "12px",
                                sm: "13px",
                                md: "14px",
                              },
                            }}
                            noWrap
                          >
                            {sales?.transaction_date}
                          </Typography>
                        </Box>

                        <Grid
                          item
                          direction={{ xs: "row", sm: "row", md: "row" }}
                          spacing={1}
                          alignItems="flex-start"
                          sx={{
                            width: "100%",
                            height: "100%",
                            // padding: {
                            //   md: "8px",
                            //   xs: "0px",
                            // },
                            mt: "30px",
                            pb: "14px",
                            borderTop: "1px solid var(--primary-color)",
                            // borderTop: {
                            //   xs: "none",
                            //   sm: "1px solid var(--primary-color)",
                            // },
                            pl: { xs: 2, sm: 2 },
                          }}
                        ></Grid>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Attachment
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            <span>
                              <a
                                href={`${sales?.attachment}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ cursor: "pointer", color: "blue" }}
                              >
                                view
                              </a>
                            </span>
                          </Typography>
                        </Box>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography sx={{ fontSize: "13px" }}>
                            Rejected Reason
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "11px",
                              fontWeight: "bold",
                              color: "var(--primary-color)",
                            }}
                          >
                            {sales?.remarks}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </>
            ) : (
              <>
                <DataGrid
                  rows={filteredDepositeData}
                  columns={columns}
                  components={{ Toolbar: GridToolbar }}
                  scrollbarSize={5}
                  style={styles.dataGrid}
                />
              </>
            )}

            <select
              onChange={handleFilterByStatus}
              style={{
                width: "200px",
                position: "absolute",
                right: "0",
                top: "15px",
              }}
              name=""
              id=""
              value={filter}
            >
              <option value="all">all</option>
              <option value="pending">pending</option>
              <option value="approved">approved</option>
              <option value="rejected">rejected</option>
            </select>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Deposite;
