import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import moment from "moment";

const ReturnQuotation = ({
  newQuotetionArr,
  setNewQuotetionArr,
  updatedPrice,
  flightData,
}) => {
  const [checked, setChecked] = useState(true);
  const handleBox = (data) => {
    const event = window.event;

    if (newQuotetionArr?.length > 1) {
      setChecked(event.target.checked);
    }
    if (!event.target.checked) {
      const filter = newQuotetionArr?.filter((item) => item?.uId !== data.uId);
      setNewQuotetionArr(filter);
    }
  };

  console.log(flightData);
  const allGoData = flightData?.segments?.go;
  const allBackData = flightData?.segments?.back;
  return (
    <Box
      sx={{
        borderRadius: "3px",

        mb: 2,
        // mt: "10px",
        ".MuiTypography-body2": {
          fontSize: "13px",
        },
        ".MuiTypography-title-lg": {
          fontSize: "24px",
          color: "#444542",
          fontWeight: "500",
        },
     
       
      }}
    >
      <Grid
        justifyContent={"space-between"}
        container
        sx={{
          bgcolor: "",
       
        }}
      >
        {/* part 1 */}

        <Grid
          justifyContent={"space-between"}
          container
          sx={{
            p: "20px 20px",
            borderRadius: "4px",
            transition: "all .5s ease-in-out",
            bgcolor: "#EBF0F4",
          }}
        >
          <Grid order={{ xs: 1, lg: 1 }} xs={6} lg={2.5}>
            <Box>
              <img
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${allGoData[0]?.marketingcareer}.png`}
                width="50px"
                height="50px"
                alt={`${allGoData[0]?.marketingcareer}`}
              />

              <Typography
                variant="body2"
                sx={{ color: "#2156A6", fontWeight: "500" }}
              >
                {allGoData[0]?.marketingcareerName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#222", fontWeight: "500" }}
              >
                {allGoData.map(
                  (s, i) =>
                    `${
                      i === 0
                        ? `${s.marketingcareer} ${s.marketingflight}`
                        : ` & ${s.marketingcareer} ${s.marketingflight}`
                    }`
                )}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 3, lg: 2 }} xs={3} lg={2.6}>
            <Box>
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Departure
              </Typography>
              <Typography variant="title-lg">
                {flightData?.godeparture}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "var(--primary-color)" }}
              >
                {allGoData[0].departureAirport}
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "11px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {moment(flightData?.godepartureTime, "HH:mm").format("hh:mm A")}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                {flightData?.godepartureDate}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 4, lg: 3 }} xs={3} lg={1.75}>
            <Box sx={{ mt: "30px" }}>
              <Typography
                sx={{
                  color: "#ACA6A6",
                  textAlign: "center",
                  mr: "10px",
                  fontSize: "10px",
                }}
              >
                {flightData?.goflightduration}
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="122"
                height="12"
                viewBox="0 0 122 12"
                fill="none"
              >
                <path
                  d="M120.682 11C120.4 11 104.145 11 96.0529 11H0"
                  stroke="#2156A6"
                  strokeWidth="2"
                />
                <path
                  d="M96.9551 2L120.682 11L96.9551 10.8623V2Z"
                  fill="#2156A6"
                  stroke="#2156A6"
                  strokeWidth="2"
                />
              </svg>
              <Typography
                sx={{
                  color: "#444542",
                  textAlign: "center",
                  mr: "10px",
                  fontSize: "11px",
                }}
              >
                {flightData?.segment >= 2
                  ? `${flightData?.segment - 1} stop${
                      flightData?.segment - 1 > 1 ? "s" : ""
                    }`
                  : "No stop"}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 5, lg: 4 }} xs={3} lg={2.6}>
            <Box
              sx={{
                bgcolor: "blu",
              }}
            >
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Arrival
              </Typography>
              <Typography variant="title-lg">
                {flightData?.goarrival}
              </Typography>
              <Typography variant="body2" sx={{ color: "#2156A6" }}>
                {allGoData[allGoData.length - 1].arrivalAirport}
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "11px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {moment(flightData?.goarrivalTime, "HH:mm").format("hh:mm A")}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                {flightData?.goarrivalDate}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 2, lg: 5 }} xs={6} lg={1.75}>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="end"
              height="100%"
              width="100%"
              sx={{
                borderLeft: "5px solid #F0F6FF",
                textAlign: "right",
              }}
            >
              <Box>
                <Typography variant="title-lg">
                  {Number(flightData?.price).toLocaleString("en-IN")}৳
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "#2156A6",
                    pb: "10px",
                    textDecoration: "line-through",
                  }}
                >
                  {Number(flightData?.clientPrice).toLocaleString("en-IN")}৳
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>

        {/* part 2 */}
        <Grid
          justifyContent={"space-between"}
          container
          sx={{
            p: "20px 20px",
            borderRadius: "4px",
            transition: "all .5s ease-in-out",
            bgcolor: "#EBF0F4",
          }}
        >
          <Grid order={{ xs: 1, lg: 1 }} xs={6} lg={2.5}>
            <Box>
              <img
                src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${allBackData[0]?.marketingcareer}.png`}
                width="50px"
                height="50px"
                alt={`${allBackData[0]?.marketingcareer}`}
              />

              <Typography
                variant="body2"
                sx={{ color: "#2156A6", fontWeight: "500" }}
              >
                {allBackData[0]?.marketingcareerName}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "#222", fontWeight: "500" }}
              >
                {allBackData.map(
                  (s, i) =>
                    `${
                      i === 0
                        ? `${s.marketingcareer} ${s.marketingflight}`
                        : ` & ${s.marketingcareer} ${s.marketingflight}`
                    }`
                )}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-start",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{
                        p: 0,
                        m: 0,
                        ml: 1,
                        color: "var(--secondary-color)",
                      }}
                      checked={checked}
                      onChange={() => handleBox(flightData)}
                    />
                  }
                />
                <Typography
                  sx={{
                    fontSize: "12px",
                    color: "#222",
                    pt: "50px",
                  }}
                >
                  {flightData?.class}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid order={{ xs: 3, lg: 2 }} xs={3} lg={2.6}>
            <Box>
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Departure
              </Typography>
              <Typography variant="title-lg">
                {flightData?.backdeparture}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "var(--primary-color)" }}
              >
                {allBackData[0].departureAirport}
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "11px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {moment(flightData?.backdepartureTime, "HH:mm").format(
                  "hh:mm A"
                )}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                {flightData?.backdepartureDate}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mt: "28px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "12px",
                    color:
                      flightData?.refundable === "Refundable"
                        ? "#0B7102"
                        : "red",
                  }}
                >
                  {flightData?.refundable}
                </Typography>
                <Typography sx={{ fontSize: "12px", color: "#222", mr: 1 }}>
                  {flightData?.seat} seat{flightData?.seat > 1 ? "s" : ""}
                </Typography>
              </Box>
            </Box>
          </Grid>

          <Grid order={{ xs: 4, lg: 3 }} xs={3} lg={1.75}>
            <Box sx={{ mt: "30px" }}>
              <Typography
                sx={{
                  color: "#ACA6A6",
                  textAlign: "center",
                  mr: "10px",
                  fontSize: "10px",
                }}
              >
                {flightData?.backflightduration}
              </Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="122"
                height="12"
                viewBox="0 0 122 12"
                fill="none"
              >
                <path
                  d="M120.682 11C120.4 11 104.145 11 96.0529 11H0"
                  stroke="#2156A6"
                  strokeWidth="2"
                />
                <path
                  d="M96.9551 2L120.682 11L96.9551 10.8623V2Z"
                  fill="#2156A6"
                  stroke="#2156A6"
                  strokeWidth="2"
                />
              </svg>
              <Typography
                sx={{
                  color: "#444542",
                  textAlign: "center",
                  mr: "10px",
                  fontSize: "11px",
                }}
              >
                {flightData?.segment >= 2
                  ? `${flightData?.segment - 1} stop${
                      flightData?.segment - 1 > 1 ? "s" : ""
                    }`
                  : "No stop"}
              </Typography>
            </Box>
            <Typography
              variant="body2"
              sx={{ color: "#2156A6", textAlign: "center", mt: 7.1 }}
            >
              Baggage{" "}
              {flightData?.bags > 3
                ? `${flightData?.bags} KG`
                : `${flightData?.bags} Bag${flightData?.bags > 1 ? "s" : ""}`}
            </Typography>
          </Grid>

          <Grid order={{ xs: 5, lg: 4 }} xs={3} lg={2.6}>
            <Box
              sx={{
                bgcolor: "blu",
              }}
            >
              <Typography variant="body2" sx={{ color: "#8C8080" }}>
                Arrival
              </Typography>
              <Typography variant="title-lg">
                {flightData?.backarrival}
              </Typography>
              <Typography variant="body2" sx={{ color: "#2156A6" }}>
                {allBackData[allBackData.length - 1].arrivalAirport}
              </Typography>
              <Typography
                sx={{
                  color: "#222",
                  fontSize: "11px",
                  py: "4px",
                  fontWeight: "500",
                }}
              >
                {moment(flightData?.backarrivalTime, "HH:mm").format("hh:mm A")}
              </Typography>
              <Typography sx={{ fontSize: "12px", color: "#8C8080" }}>
                {flightData?.backarrivalDate}
              </Typography>
            </Box>
          </Grid>

          <Grid order={{ xs: 2, lg: 5 }} xs={6} lg={1.75}>
            <Stack
              direction="column"
              justifyContent="space-between"
              alignItems="end"
              height="100%"
              width="100%"
              sx={{
                borderLeft: "5px solid #F0F6FF",
                textAlign: "right",
              }}
            ></Stack>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReturnQuotation;
