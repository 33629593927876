import { useState } from "react";
import {
  Button,
  Collapse,
  Grid,
  Tab,
  Typography,
  Box,
  Stack,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import Tooltip from "@mui/material/Tooltip";
import FlightIcon from "@mui/icons-material/Flight";
import React from "react";
import { format } from "date-fns";
import RoundTransit from "../../components/SingleFlight/RoundTransit";
import AirLineName from "../../components/SingleFlight/AirLineName";
import Transit from "../Transit/Transit";
import FarePolicy from "../../components/FarePolicy/FarePolicy";
import commaNumber from "comma-number";
import ReturnTransit from "./ReturnTransit";
import moment from "moment";
const isMobile = window.innerWidth <= 768;

const ReturnFlightDetails = ({ data }) => {
  // "return Flight Details", data;

  const clientFare = Math.round(Number.parseInt(data?.grossCost));
  const agentFare = Math.round(Number.parseInt(data?.netCost));
  const commission = Math.round(clientFare - agentFare);

  const [value, setValue] = useState("1");
  const [flightDetails, setFlightDetails] = useState(true);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [showFlight, setShowFlight] = useState(true);

  // Unique Flight Code filter
  // const goMarcketingCareerCode = data?.flightData?.Segment_data.go
  //   .map((data) => data?.goMarketingCareer)
  //   .filter((value, index, arr) => arr.indexOf(value) === index);
  // const backMarcketingCareerCode = data?.flightData?.Segment_data.back
  //   .map((data) => data?.goMarketingCareer)
  //   .filter((value, index, arr) => arr.indexOf(value) === index);

  // const [showGo, setShowGo] = useState(true);
  // // (flightData);
  // //todo: calculate total flight duration
  // const calDuration = (arr) => {
  //   const convertTime = arr.map(
  //     (item) =>
  //       parseInt(item.split(" ")[0]) * 3600 * 1000 +
  //       parseInt(item.split(" ")[1]) * 60 * 1000
  //   );
  //   const milliseconds = convertTime.reduce((cur, acc) => cur + acc, 0);
  //   let seconds = Math.floor(milliseconds / 1000);
  //   let minutes = Math.floor(seconds / 60);
  //   let hours = Math.floor(minutes / 60);
  //   seconds = seconds % 60;
  //   minutes = seconds >= 30 ? minutes + 1 : minutes;
  //   minutes = minutes % 60;
  //   hours = hours % 24;
  //   return `${hours.toString().padStart(2, 0)}H:${minutes
  //     .toString()
  //     .padStart(2, 0)}Min`;
  // };
  const adultCount = data?.adultCount;
  const childCount = data?.childCount;
  const infantCount = data?.infantCount;
  const [toogleSegments, setToogleSegments] = useState(1);

  let goDuration = 0,
    backDuration = 0;

  if (data?.segments?.go.length === 1) {
    goDuration = data?.segments?.go[0]?.flight_duration;
  } else {
    goDuration =
      data?.segments?.go[0]?.flight_duration +
      data?.segments?.go[1]?.flight_duration;
  }

  return (
    <Box
      sx={{
        borderRadius: "5px",
      }}
    >
      {/* //todo:mobile Responsive Section */}

      {/* //todo:for mobile End--------- */}

      {/* //todo: Desktop Design Section */}
      <Box
        sx={{
          display: { xs: "block", sm: "block", md: "block", lg: "block" },
        }}
      >
        {/* //todo:Flight Details start------------ */}
        <Collapse
          in={flightDetails}
          timeout="auto"
          unmountOnExit
          sx={{ width: "100%", marginBottom: "0px" }}
        >
          <Box>
            {toogleSegments === 1 ? (
              <>
                {isMobile ? (
                  <>
                    <Grid container sx={{ mt: "20px" }}>
                      <Grid
                        item
                        xs={12}
                        container
                        sx={{
                          bgcolor: "#a3c7ff3b",
                          borderRadius: "4px",
                          px: "13px",
                          py: "10px",
                        }}
                      >
                        {/* Array mapping starts here */}
                        {data?.segments?.go?.map((flight, i, arr) => {
                          const datetime1 = moment(
                            arr[i]?.arrival_time,
                            "YYYY-MM-DD HH:mm:ss"
                          );
                          const datetime2 = moment(
                            arr[i + 1]?.departure_time,
                            "YYYY-MM-DD HH:mm:ss"
                          );

                          const diff = moment.duration(
                            datetime2.diff(datetime1)
                          );

                          const diffHours = diff.hours();
                          const diffMinutes = diff.minutes();

                          return (
                            <Grid item xs={12}>
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  borderLeft:
                                    "1.5px dotted var(--secondary-color)",
                                  mt: "20px",
                                  pl: "15px",
                                  pb: "10px",
                                  position: "relative",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    backgroundColor: "var(--primary-color)",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    left: "-5px",
                                    top: "-5px",
                                  }}
                                ></div>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      pb: "0px",
                                    }}
                                  >
                                    {flight?.departure_code}{" "}
                                    {new Date(
                                      flight?.departure_time
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </span>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                      },
                                      cursor: "pointer",
                                      width: "97%",
                                    }}
                                  >
                                    {flight.departure_location} |{" "}
                                    {flight?.departure_airport}{" "}
                                    {flight?.departure_terminal &&
                                    flight?.departure_terminal.length > 4
                                      ? "|" + flight?.departure_terminal
                                      : flight?.departure_terminal &&
                                        "| TERMINAL " +
                                          flight?.departure_terminal}
                                  </Typography>
                                  <span style={{ fontSize: "12px", pt: "0px" }}>
                                    {moment(flight?.departure_time).format(
                                      "D MMMM YYYY"
                                    )}
                                    {/* {flightData?.departureDate} */}
                                  </span>
                                </Box>
                                <Box
                                  sx={{
                                    mt: "20px",
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <img
                                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.marketing_carier_code}.png`}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                    alt={`${flight?.marketing_carier_name}`}
                                  />
                                  <Box>
                                    <span
                                      style={{ fontSize: "13px", pt: "0px" }}
                                    >
                                      {flight?.marketing_carier_name}
                                    </span>{" "}
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        pt: "0px",
                                        color: "var(--primary-color)",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {flight?.marketing_carier_code}{" "}
                                      {flight?.marketing_flight_number}
                                    </span>{" "}
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        pt: "0px",
                                        color: "var(--primary-color)",
                                      }}
                                    >
                                      {flight?.air_craft_type_name}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        pt: "0px",
                                        color: "var(--primary-color)",
                                      }}
                                    >
                                      Airlines PNR:{flight?.airlines_pnr}
                                    </span>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      pb: "0px",
                                    }}
                                  >
                                    {flight?.arrival_code}{" "}
                                    {new Date(
                                      flight?.arrival_time
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </span>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                      },
                                      cursor: "pointer",
                                      width: "97%",
                                    }}
                                  >
                                    {flight.arrival_location} |{" "}
                                    {flight?.arrival_airport}{" "}
                                    {flight?.arrival_terminal &&
                                    flight?.arrival_terminal.length > 4
                                      ? "| " + flight?.arrival_terminal
                                      : flight?.arrival_terminal &&
                                        "| TERMINAL " +
                                          flight?.arrival_terminal}
                                  </Typography>
                                  <span style={{ fontSize: "12px", pt: "0px" }}>
                                    {moment(flight?.arrival_time).format(
                                      "D MMMM YYYY"
                                    )}
                                    {/* {flightData?.arrivalTime} */}
                                  </span>
                                </Box>
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    backgroundColor: "var(--primary-color)",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    left: "-5px",
                                    bottom: "0px",
                                  }}
                                ></div>
                              </Box>
                            </Grid>
                          );
                        })}
                        {/* Array mapping ends here */}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {data?.segments?.go.map((flight, i, arr) => {
                      const datetime1 = moment(
                        arr[i]?.arrival_time,
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      const datetime2 = moment(
                        arr[i + 1]?.departure_time,
                        "YYYY-MM-DD HH:mm:ss"
                      );

                      const diff = moment.duration(datetime2.diff(datetime1));

                      const diffHours = diff.hours();
                      const diffMinutes = diff.minutes();
                      return (
                        <Grid
                          key={i}
                          container
                          sx={{ mb: `${i === arr.length - 1 ? "" : "20px"}` }}
                        >
                          <Grid item md={3}>
                            <Box>
                              {/* //todo: Image Part */}
                              <Box>
                                <img
                                  src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.marketing_carier_code}.png`}
                                  width="50px"
                                  height="50px"
                                  alt={`${data?.segments[0]?.marketing_carier_code}`}
                                />
                              </Box>

                              {/* //todo: Text Part */}
                              <Box width="90%">
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                        cursor: "pointer",
                                      },
                                    }}
                                    noWrap
                                  >
                                    {flight?.marketing_carier_name}{" "}
                                  </Typography>
                                </Tooltip>
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                    noWrap
                                  >
                                    {flight?.marketing_carier_code} -{" "}
                                    {flight?.marketing_flight_number}
                                  </Typography>
                                </Tooltip>
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    {flight?.air_craft_type_name}
                                  </Typography>
                                </Tooltip>
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    Airlines PNR:{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {flight?.airlines_pnr}
                                    </span>
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Grid>
                          {/* //todo:two */}
                          <Grid item md={3}>
                            <Box
                              style={{
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "start",
                                  alignItems: "start",
                                  gap: "1px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--third-color)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "10px",
                                      sm: "12px",
                                      md: "12px",
                                    },
                                  }}
                                >
                                  Depart
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "15px",
                                      sm: "18px",
                                      md: "22px",
                                    },
                                  }}
                                >
                                  {flight?.departure_code}
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "10px",
                                      sm: "12px",
                                      md: "13px",
                                    },
                                    cursor: "pointer",
                                    width: "97%",
                                  }}
                                >
                                  {flight.departure_location} |{" "}
                                  {flight?.departure_airport}{" "}
                                  {flight?.departure_terminal &&
                                  flight?.departure_terminal.length > 4
                                    ? "|" + flight?.departure_terminal
                                    : flight?.departure_terminal &&
                                      "| TERMINAL " +
                                        flight?.departure_terminal}
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "10px",
                                      sm: "12px",
                                      md: "13px",
                                    },
                                  }}
                                >
                                  {format(
                                    new Date(flight?.departure_time.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          {/* //todo: three */}
                          <Grid item md={3}>
                            <Transit flightData={flight} />
                          </Grid>
                          {/* //todo: four */}
                          <Grid item md={3}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "end",
                                alignItems: "end",
                                gap: "1px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "var(--third-color)",
                                  fontWeight: 400,

                                  fontSize: {
                                    xs: "10px",
                                    sm: "12px",
                                    md: "12px",
                                  },
                                }}
                              >
                                Arrival
                              </Typography>
                              <Typography
                                sx={{
                                  color: "var(--mateBlack)",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "12px",
                                    sm: "13px",
                                    md: "22px",
                                  },
                                }}
                              >
                                {`${flight?.arrival_code}`}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "var(--primary-color)",
                                  fontWeight: 400,
                                  fontSize: {
                                    xs: "10px",
                                    sm: "12px",
                                    md: "13px",
                                  },
                                  cursor: "pointer",
                                  width: "97%",
                                  textAlign: "right",
                                }}
                              >
                                {flight.arrival_location} |{" "}
                                {flight?.arrival_airport}{" "}
                                {flight?.arrival_terminal &&
                                flight?.arrival_terminal.length > 4
                                  ? "| " + flight?.arrival_terminal
                                  : flight?.arrival_terminal &&
                                    "| TERMINAL " + flight?.arrival_terminal}
                              </Typography>

                              <Typography
                                sx={{
                                  color: "var(--mateBlack)",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "10px",
                                    sm: "12px",
                                    md: "13px",
                                  },
                                }}
                              >
                                {format(
                                  new Date(flight?.arrival_time.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={12}>
                            <Typography
                              sx={{
                                display: `${
                                  i === arr.length - 1 ? "none" : "block"
                                }`,
                                borderRadius: "4px",
                                background: "#DEE8FF",
                                width: "85%",
                                color: "var(--primary-color)",
                                padding: "3px 10px",
                                fontSize: "10px",
                                fontWeight: 500,
                                margin: "0 auto",
                                textAlign: "center",
                                mt: "15px",
                                py: "8px",
                              }}
                            >
                              Change planes at{" "}
                              {moment(arr[i + 1]?.departure_time).format(
                                "hh:mm a"
                              )}{" "}
                              | {flight?.arrival_airport} |{" "}
                              {flight?.arrival_airport} | Connecting Time:{" "}
                              {diffHours + "H " + diffMinutes + "Min"}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <>
                {isMobile ? (
                  <>
                    <Grid container sx={{ mt: "20px" }}>
                      <Grid
                        item
                        xs={12}
                        container
                        sx={{
                          bgcolor: "#a3c7ff3b",
                          borderRadius: "4px",
                          px: "13px",
                          py: "10px",
                        }}
                      >
                        {/* Array mapping starts here */}
                        {data?.segments?.back?.map((flight, i, arr) => {
                          const datetime1 = moment(
                            arr[i]?.arrival_time,
                            "YYYY-MM-DD HH:mm:ss"
                          );
                          const datetime2 = moment(
                            arr[i + 1]?.departure_time,
                            "YYYY-MM-DD HH:mm:ss"
                          );

                          const diff = moment.duration(
                            datetime2.diff(datetime1)
                          );

                          const diffHours = diff.hours();
                          const diffMinutes = diff.minutes();

                          return (
                            <Grid item xs={12}>
                              <Box
                                key={i}
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  borderLeft:
                                    "1.5px dotted var(--secondary-color)",
                                  mt: "20px",
                                  pl: "15px",
                                  pb: "10px",
                                  position: "relative",
                                  width: "100%",
                                }}
                              >
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    backgroundColor: "var(--primary-color)",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    left: "-5px",
                                    top: "-5px",
                                  }}
                                ></div>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      pb: "0px",
                                    }}
                                  >
                                    {flight?.departure_code}{" "}
                                    {new Date(
                                      flight?.departure_time
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </span>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                      },
                                      cursor: "pointer",
                                      width: "97%",
                                    }}
                                  >
                                    {flight.departure_location} |{" "}
                                    {flight?.departure_airport}{" "}
                                    {flight?.departure_terminal &&
                                    flight?.departure_terminal.length > 4
                                      ? "|" + flight?.departure_terminal
                                      : flight?.departure_terminal &&
                                        "| TERMINAL " +
                                          flight?.departure_terminal}
                                  </Typography>
                                  <span style={{ fontSize: "12px", pt: "0px" }}>
                                    {moment(flight?.departure_time).format(
                                      "D MMMM YYYY"
                                    )}
                                    {/* {flightData?.departureDate} */}
                                  </span>
                                </Box>
                                <Box
                                  sx={{
                                    mt: "20px",
                                    display: "flex",
                                    gap: "10px",
                                  }}
                                >
                                  <img
                                    src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.marketing_carier_code}.png`}
                                    style={{
                                      width: "40px",
                                      height: "40px",
                                      borderRadius: "50%",
                                    }}
                                    alt={`${flight?.marketing_carier_name}`}
                                  />
                                  <Box>
                                    <span
                                      style={{ fontSize: "13px", pt: "0px" }}
                                    >
                                      {flight?.marketing_carier_name}
                                    </span>{" "}
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        pt: "0px",
                                        color: "var(--primary-color)",
                                        fontWeight: "600",
                                      }}
                                    >
                                      {flight?.marketing_carier_code}{" "}
                                      {flight?.marketing_flight_number}
                                    </span>{" "}
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "10px",
                                        pt: "0px",
                                        color: "var(--primary-color)",
                                      }}
                                    >
                                      {flight?.air_craft_type_name}
                                    </span>
                                    <br />
                                    <span
                                      style={{
                                        fontSize: "12px",
                                        pt: "0px",
                                        color: "var(--primary-color)",
                                      }}
                                    >
                                      Airlines PNR:{flight?.airlines_pnr}
                                    </span>
                                  </Box>
                                </Box>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    mt: "15px",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                      pb: "0px",
                                    }}
                                  >
                                    {flight?.arrival_code}{" "}
                                    {new Date(
                                      flight?.arrival_time
                                    ).toLocaleTimeString([], {
                                      hour: "2-digit",
                                      minute: "2-digit",
                                    })}
                                  </span>

                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                      },
                                      cursor: "pointer",
                                    }}
                                  >
                                    {flight.arrival_location} |{" "}
                                    {flight?.arrival_airport}{" "}
                                    {flight?.arrival_terminal &&
                                    flight?.arrival_terminal.length > 4
                                      ? "| " + flight?.arrival_terminal
                                      : flight?.arrival_terminal &&
                                        "| TERMINAL " +
                                          flight?.arrival_terminal}
                                  </Typography>
                                  <span style={{ fontSize: "12px", pt: "0px" }}>
                                    {moment(flight?.arrival_time).format(
                                      "D MMMM YYYY"
                                    )}
                                    {/* {flightData?.arrivalTime} */}
                                  </span>
                                </Box>
                                <div
                                  style={{
                                    height: "10px",
                                    width: "10px",
                                    backgroundColor: "var(--primary-color)",
                                    borderRadius: "50px",
                                    position: "absolute",
                                    left: "-5px",
                                    bottom: "0px",
                                  }}
                                ></div>
                              </Box>
                            </Grid>
                          );
                        })}
                        {/* Array mapping ends here */}
                      </Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    {data?.segments?.back.map((flight, i, arr) => {
                      const datetime1 = moment(
                        arr[i]?.arrival_time,
                        "YYYY-MM-DD HH:mm:ss"
                      );
                      const datetime2 = moment(
                        arr[i + 1]?.departure_time,
                        "YYYY-MM-DD HH:mm:ss"
                      );

                      const diff = moment.duration(datetime2.diff(datetime1));

                      const diffHours = diff.hours();
                      const diffMinutes = diff.minutes();
                      return (
                        <Grid
                          key={i}
                          container
                          sx={{ mb: `${i === arr.length - 1 ? "" : "20px"}` }}
                        >
                          <Grid item md={3}>
                            <Box>
                              {/* //todo: Image Part */}
                              <Box>
                                <img
                                  src={`https://tbbd-flight.s3.ap-southeast-1.amazonaws.com/airlines-logo/${flight?.marketing_carier_code}.png`}
                                  width="50px"
                                  height="50px"
                                  alt={`${data?.segments[0]?.marketing_carier_code}`}
                                />
                              </Box>

                              {/* //todo: Text Part */}
                              <Box width="90%">
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--primary-color)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "13px",
                                        cursor: "pointer",
                                      },
                                    }}
                                    noWrap
                                  >
                                    {flight?.marketing_carier_name}{" "}
                                  </Typography>
                                </Tooltip>
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                    noWrap
                                  >
                                    {flight?.marketing_carier_code} -{" "}
                                    {flight?.marketing_flight_number}
                                  </Typography>
                                </Tooltip>
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    {flight?.air_craft_type_name}
                                  </Typography>
                                </Tooltip>
                                <Tooltip>
                                  <Typography
                                    sx={{
                                      color: "var(--mateBlack)",
                                      fontWeight: 400,
                                      fontSize: {
                                        xs: "10px",
                                        sm: "12px",
                                        md: "12px",
                                        cursor: "pointer",
                                      },
                                    }}
                                  >
                                    Airlines PNR:{" "}
                                    <span style={{ fontWeight: "500" }}>
                                      {flight?.airlines_pnr}
                                    </span>
                                  </Typography>
                                </Tooltip>
                              </Box>
                            </Box>
                          </Grid>
                          {/* //todo:two */}
                          <Grid item md={3}>
                            <Box
                              style={{
                                width: "100%",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  justifyContent: "start",
                                  alignItems: "start",
                                  gap: "1px",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: "var(--third-color)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "10px",
                                      sm: "12px",
                                      md: "12px",
                                    },
                                  }}
                                >
                                  Depart
                                </Typography>
                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "15px",
                                      sm: "18px",
                                      md: "22px",
                                    },
                                  }}
                                >
                                  {flight?.departure_code}
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "var(--primary-color)",
                                    fontWeight: 400,
                                    fontSize: {
                                      xs: "10px",
                                      sm: "12px",
                                      md: "13px",
                                    },
                                    cursor: "pointer",
                                    width: "97%",
                                  }}
                                >
                                  {flight.departure_location} |{" "}
                                  {flight?.departure_airport}{" "}
                                  {flight?.departure_terminal &&
                                  flight?.departure_terminal.length > 4
                                    ? "|" + flight?.departure_terminal
                                    : flight?.departure_terminal &&
                                      "| TERMINAL " +
                                        flight?.departure_terminal}
                                </Typography>

                                <Typography
                                  sx={{
                                    color: "var(--mateBlack)",
                                    fontWeight: 500,
                                    fontSize: {
                                      xs: "10px",
                                      sm: "12px",
                                      md: "13px",
                                    },
                                  }}
                                >
                                  {format(
                                    new Date(flight?.departure_time.toString()),
                                    "dd MMM yyyy hh:mm a"
                                  )}
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>
                          {/* //todo: three */}
                          <Grid item md={3}>
                            <Transit flightData={flight} />
                          </Grid>
                          {/* //todo: four */}
                          <Grid item md={3}>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "end",
                                alignItems: "end",
                                gap: "1px",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: "var(--third-color)",
                                  fontWeight: 400,

                                  fontSize: {
                                    xs: "10px",
                                    sm: "12px",
                                    md: "12px",
                                  },
                                }}
                              >
                                Arrival
                              </Typography>
                              <Typography
                                sx={{
                                  color: "var(--mateBlack)",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "12px",
                                    sm: "13px",
                                    md: "22px",
                                  },
                                }}
                              >
                                {`${flight?.arrival_code}`}
                              </Typography>
                              <Typography
                                sx={{
                                  color: "var(--primary-color)",
                                  fontWeight: 400,
                                  fontSize: {
                                    xs: "10px",
                                    sm: "12px",
                                    md: "13px",
                                  },
                                  cursor: "pointer",
                                  width: "97%",
                                  textAlign: "right",
                                }}
                              >
                                {flight.arrival_location} |{" "}
                                {flight?.arrival_airport}{" "}
                                {flight?.arrival_terminal &&
                                flight?.arrival_terminal.length > 4
                                  ? "| " + flight?.arrival_terminal
                                  : flight?.arrival_terminal &&
                                    "| TERMINAL " + flight?.arrival_terminal}
                              </Typography>

                              <Typography
                                sx={{
                                  color: "var(--mateBlack)",
                                  fontWeight: 500,
                                  fontSize: {
                                    xs: "10px",
                                    sm: "12px",
                                    md: "13px",
                                  },
                                }}
                              >
                                {format(
                                  new Date(flight?.arrival_time.toString()),
                                  "dd MMM yyyy hh:mm a"
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                          <Grid item md={12}>
                            <Typography
                              sx={{
                                display: `${
                                  i === arr.length - 1 ? "none" : "block"
                                }`,
                                borderRadius: "4px",
                                background: "#DEE8FF",
                                width: "85%",
                                color: "var(--primary-color)",
                                padding: "3px 10px",
                                fontSize: "10px",
                                fontWeight: 500,
                                margin: "0 auto",
                                textAlign: "center",
                                mt: "15px",
                                py: "8px",
                              }}
                            >
                              Change planes at{" "}
                              {moment(arr[i + 1]?.departure_time).format(
                                "hh:mm a"
                              )}{" "}
                              | {flight?.arrival_airport} |{" "}
                              {flight?.arrival_airport} | Connecting Time:{" "}
                              {diffHours + "H " + diffMinutes + "Min"}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </>
                )}
              </>
            )}

            <Stack direction="row" justifyContent="flex-end" mt={1}>
              <Typography
                sx={{
                  padding: "5px 10px",
                  bgcolor: `${toogleSegments === 1 ? "#2156A6" : "#D3E0F0"}`,
                  width: "max-content",
                  color: `${toogleSegments === 1 ? "white" : "#444542"}`,
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                onClick={() => {
                  setToogleSegments(1);
                }}
              >
                {data?.booking?.dept_from} - {data?.booking?.arrive_to}
              </Typography>
              <Typography
                sx={{
                  padding: "5px 10px",
                  bgcolor: `${toogleSegments === 2 ? "#2156A6" : "#D3E0F0"}`,
                  width: "max-content",
                  color: `${toogleSegments === 2 ? "white" : "#444542"}`,
                  cursor: "pointer",
                  fontSize: "14px",
                }}
                onClick={() => {
                  setToogleSegments(2);
                }}
              >
                {data?.booking?.arrive_to} - {data?.booking?.dept_from}
                {/* {roundData.backdeparture} - {roundData.backarrival} */}
              </Typography>
            </Stack>
          </Box>
        </Collapse>
      </Box>
      {/* //todo: Desktop Design Section */}
    </Box>
  );
};

export default ReturnFlightDetails;
