import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Container,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import PropTypes from "prop-types";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PlaceIcon from "@mui/icons-material/Place";
import { useReactToPrint } from "react-to-print";
import secureLocalStorage from "react-secure-storage";
import "./CountryDetails.css";
import UserHeader from "../../UserPage/UserHeader/UserHeader";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfVisa from "./PdfVisa";
import VisaSearch from "../../VisaSearch/VisaSearch";

function TableRowData({ data }) {
  const {
    entryType,
    maximumStay,
    duration,
    processingTime,
    cost,
    interview,
    embassyFee,
    agentFee,
    agencyFee,
    RTCServiceCharge,
    total,
    visaType,
  } = data;
  const arrayData = [data];
  console.log(data);

  const [open, setOpen] = React.useState(false);
  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell
          sx={{
            padding: "6px 10px",
            color: "var(--primary-color)",
          }}
        >
          {entryType}
        </TableCell>
        <TableCell
          sx={{
            padding: "6px 10px",
            color: "var(--primary-color",
          }}
        >
          {duration}&nbsp;Months
        </TableCell>
        <TableCell
          sx={{
            padding: "6px 10px",
            color: "var(--primary-color)",
          }}
        >
          {maximumStay}&nbsp;Days
        </TableCell>
        <TableCell
          sx={{
            padding: "6px 10px",
            color: "var(--primary-color)",
          }}
        >
          {processingTime}&nbsp;Working&nbsp;Days
        </TableCell>
        <TableCell
          sx={{
            padding: "6px 10px",
            color: "var(--primary-color)",
          }}
        >
          {interview}
        </TableCell>
        <TableCell>
          {parseInt(total)}
          &nbsp;BDT
        </TableCell>
        <TableCell
          sx={{
            padding: "6px 10px",
            color: "var(--primary-color)",
          }}
        >
          <Button
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <Typography
                sx={{
                  width: "100px",
                  color: "var(--white)",
                  background: "var(--primary-color)",
                  fontSize: "14px",
                }}
              >
                hide
              </Typography>
            ) : (
              <Typography
                sx={{
                  width: "100px",
                  color: "#fff",
                  bgcolor: "var(--primary-color)",
                  fontSize: "14px",
                  textDecoration: "capitalize",
                }}
              >
                show
              </Typography>
            )}
          </Button>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: "#D1E9FF",
          }}
          colSpan={12}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography
                variant="p"
                color="#1c75bc"
                gutterBottom
                component="div"
              >
                *Depends on embassy
              </Typography>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "18px",
                  fontWeight: 500,
                  mb: "10px",
                }}
              >
                Price Break Down
              </Typography>
              <Table
                size="small"
                aria-label="purchases"
                style={{ border: "none" }}
              >
                <TableHead style={{ backgroundColor: "#88BAEB" }}>
                  <TableRow style={{ border: "none" }}>
                    <TableCell align="center">Embassy&nbsp;Fee</TableCell>
                    <TableCell align="center">Agent&nbsp;Fee</TableCell>
                    <TableCell align="center">Agency&nbsp;Fee</TableCell>
                    <TableCell align="center">
                      RTC&nbsp;Service&nbsp;Charge
                    </TableCell>
                    <TableCell align="center">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {arrayData?.map((price, index) => (
                    <TableRow
                      key={index}
                      style={{
                        backgroundColor: "#D1E9FF",
                        border: "none",
                      }}
                    >
                      <TableCell align="center">
                        {price?.embassyFee}&nbsp;BDT
                      </TableCell>
                      <TableCell align="center">
                        {price?.agentFee}&nbsp;BDT
                      </TableCell>
                      <TableCell align="center">
                        {price?.agencyFee}&nbsp;BDT
                      </TableCell>
                      <TableCell align="center">
                        {price?.RTCServiceCharge}&nbsp;BDT
                      </TableCell>
                      <TableCell align="center">
                        {parseInt(price?.embassyFee) +
                          parseInt(price?.agentFee) +
                          parseInt(price?.agencyFee) +
                          parseInt(price?.RTCServiceCharge)}
                        &nbsp;BDT
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CountryDetails = () => {
  const location = useLocation();
  const componentRefEN = useRef();
  const componentRefBN = useRef();
  const [expandeed, setExpanded] = useState("panel");
  const handleChangeDown = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const companyInfo = {
    name: "Fly Far International",
    email: "support@flyfarint.com",
    phone: "09606-912912",
    address:
      "1229, Hazi Abdul Latif Mansion, 2nd Floor, Ka-9, Bashundhara Rd, Dhaka",
  };

  const [optionValue, setOptionvalue] = useState("JobHolder");
  const [users, setUsers] = useState("");
  const [userData, setUserData] = useState();

  const [modfVisa, setModfVisa] = useState(location.state.fromaddress);
  const [modTypeVisa, setModTypeVisa] = useState(location.state.toaddress);
  useEffect(() => {
    const users = secureLocalStorage.getItem("user-info");
    if (users) {
      setUsers(users);
    }
  }, []);
  let agentID = users?.user?.agentId;
  const requiredSearchData =
    location.state !== null
      ? location.state
      : secureLocalStorage.getItem("search-data");

  const [visaDetails, setVisaDetails] = useState({});
  const [uniqueChecklist, setUniqueChecklist] = useState([]);
  // header added
  useEffect(() => {
    let url = `https://api.rtc.flyfarint.com/v1.0/b2b/visa/index.php?singleVisa`;
    let body = JSON.stringify({
      country: requiredSearchData.fromaddress.trim(),
      category: requiredSearchData.toaddress.trim(),
    });

    fetch(url, {
      method: "POST",
      headers: {
        Accept: "*/*",
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      },
      body: body,
    })
      .then((res) => res.json())
      .then((data) => {
        const checklist =
          data?.checklist?.filter((item, index, array) => {
            // Check if the current "passengertype" value is the first occurrence in the array
            return (
              array.findIndex(
                (obj) => obj.passengertype === item.passengertype
              ) === index
            );
          }) || [];

        setUniqueChecklist(checklist);
        if (checklist.length > 0) {
          setOptionvalue(checklist[0]?.passengertype);
        }
        setVisaDetails(data);
      });
  }, [requiredSearchData.fromaddress, requiredSearchData.toaddress]);

  console.log(visaDetails);
  console.log(uniqueChecklist);
  console.log(optionValue);

  // useEffect(() => {
  //   let url = `https://flyway.api.flyfarint.com/v.1.0.0/Accounts/MyAccount.php?agentId=${agentID}`;
  //   fetch(url)
  //     .then((res) => res.json())
  //     .then((data) => setUserData(data[0]));
  // }, [agentID]);

  const handleToPrintEN = useReactToPrint({
    content: () => componentRefEN.current,
    documentTitle: `${visaDetails?.country}_${visaDetails?.visatype}`,
    pageStyle: "@page { size: 200mm 297mm }",
  });
  const handleToPrintBN = useReactToPrint({
    content: () => componentRefBN.current,
    documentTitle: `${visaDetails?.country}_${visaDetails?.visatype}`,
    pageStyle: "@page { size: 200mm 297mm }",
  });

  return (
    <Box sx={{ background: "#EBF0F4" }}>
      <UserHeader />

      <Box
        sx={{
          p: "20px",
          m: "0 auto",
          width: "90%",
          mt:{
            xs: "-5vh",
            sm: "-5vh",
            md: "-10vh",
            lg: "-10vh"
          },
          // position: "relative",
        }}
      >
        <Box>
          <Box
            mt={3}
            sx={{
              backgroundColor: "var(--white)",
              p: 2,
              borderRadius: "5px",
            }}
          >
            <Box
              sx={{
                display: {
                  xs:"flex",
                  sm:"flex",
                  md:"none",
                  lg:"none"
                },
              
                alignItems: "center",
                justifyContent: "end",
              }}
            >
              <Link to="/dashboard">
                <Box
                  sx={{
                    bgcolor: "var(--secondary-color)",
                    color: "var(--white)",
                    px: "10px",
                    borderRadius: "3px",
                    display: "flex",
                    alignItems: "center",

                    fontSize: "11px",
                    fontWeight: 600,
                    height: "25px",
                    cursor: "pointer",
                  }}
                >
                  Modifiy Serch
                </Box>
              </Link>
            </Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            >
              <VisaSearch
                fvisa={modfVisa}
                setfvisa={setModfVisa}
                typeVisa={modTypeVisa}
                setTypeVisa={setModTypeVisa}
              />
            </Box>
          </Box>
        </Box>
        <Grid
          container
          justifyContent="space-between"
          sx={{
            backgroundColor: "var(--white)",
            p: 2,
            mt: 2,
            borderRadius: "5px",
          }}
        >
          <Grid item xs={12} sm={12} md={9}>
            <Typography
              sx={{
                color: "var(--mattBlack)",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                },
                fontWeight: 500,
                mb: "10px",
              }}
            >
              Required Documents for {requiredSearchData?.toaddress}{" "}
              {requiredSearchData?.fromaddress}
            </Typography>
            <Typography>
              Visa Type:{" "}
              {Object.keys(visaDetails).length !== 0 ? (
                <>{visaDetails?.visainfo[0]?.visaType || ""}</>
              ) : (
                "Loading..."
              )}
            </Typography>
            <Typography
              my={3}
              color={"var(--primary-color)"}
              fontSize="20px"
              fontWeight={500}
            >
              Duration and Cost Details
            </Typography>
            <Box my={3}>
              <TableContainer>
                <Table aria-label="collapsible table">
                  <TableHead>
                    <TableRow
                      sx={{
                        background: "var(--secondary-color)",
                      }}
                    >
                      <TableCell
                        sx={{
                          padding: "6px 10px",
                          color: "var(--mattBlack)",
                        }}
                      >
                        Entry
                      </TableCell>
                      <TableCell
                        // align="right"
                        sx={{
                          padding: "6px 10px",
                          color: "var(--mattBlack)",
                        }}
                      >
                        Duration
                      </TableCell>
                      <TableCell
                        // align="right"
                        sx={{
                          padding: "6px 10px",
                          color: "var(--mattBlack)",
                        }}
                      >
                        Maximum&nbsp;Stay
                      </TableCell>
                      <TableCell
                        // align="right"
                        sx={{
                          padding: "6px 10px",
                          color: "var(--mattBlack)",
                        }}
                      >
                        Processing&nbsp;Time
                      </TableCell>
                      <TableCell
                        // align="right"
                        sx={{
                          padding: "6px 10px",
                          color: "var(--mattBlack)",
                        }}
                      >
                        Interview
                      </TableCell>
                      <TableCell
                        // align="right"
                        sx={{
                          padding: "6px 10px",
                          color: "var(--mattBlack)",
                        }}
                      >
                        Cost
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "6px 10px",
                          color: "var(--mattBlack)",
                        }}
                      >
                        View&nbsp;Details
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {visaDetails?.visainfo?.map((row) => (
                      <TableRowData data={row} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box display={"flex"} alignItems="center" my={2}>
              <Typography
                color="var(--primary-color)"
                fontSize="16px"
                fontWeight={500}
                mr={2}
              >
                Select Your Profession:
              </Typography>
              <select
                style={{
                  border: "none",
                  outline: "none",
                  background: "var(--secondary-color)",
                  color: "var(--primary-color)",
                  padding: "5px 10px",
                  fontSize: "14px",
                  borderRadius: "4px",
                }}
                onChange={(e) => setOptionvalue(e.target.value)}
              >
                {uniqueChecklist.map((item, i) => (
                  <option
                    defaultValue={i === 0 && item.passengertype}
                    key={i}
                    value={item.passengertype}
                  >
                    {item.passengertype.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  </option>
                ))}
              </select>
            </Box>
            <Box>
              <Typography
                sx={{
                  color: "var(--primary-color)",
                  fontSize: "20px",
                  fontWeight: 500,
                  my: "40px",
                }}
              >
                {optionValue === "JobHolder" ? (
                  <>Job Holder</>
                ) : optionValue === "BusinessMan" ? (
                  <>Business Man</>
                ) : optionValue === "GovtJobHolder" ? (
                  <>Govt. Job Holder</>
                ) : optionValue === "Doctor" ? (
                  <>Doctor</>
                ) : optionValue === "AdvocateLawyer" ? (
                  <>Advocate Lawyer</>
                ) : optionValue === "Student" ? (
                  <>Student</>
                ) : optionValue === "NonStudentChild" ? (
                  <>Non Student Child</>
                ) : optionValue === "Housewife" ? (
                  <>House Wife</>
                ) : optionValue === "Unemployed" ? (
                  <>Unemployed</>
                ) : (
                  <></> || "Select Visa Type"
                )}
              </Typography>
              {visaDetails?.checklist?.map((check, index) => (
                <Typography
                  sx={{
                    fontSize: "14px",
                    color: "var(--primary-color)",
                    mb: "10px",
                  }}
                >
                  {optionValue === check?.passengertype ? (
                    <>
                      {"💠 "}
                      {check?.checkList}
                    </>
                  ) : (
                    <></>
                  )}
                </Typography>
              ))}
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={2.7}>
            {/* <Accordion
            style={{ boxShadow: "none" }}
            expanded={expandeed === "panel"}
            onChange={handleChangeDown("panel")}
          >
            <AccordionSummary
              style={{
                padding: "0px 10px",
                margin: "0px 10px",
              }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography fontSize="16px" color="var(--primary-color)" fontWeight={500}>
                Tourist Visa
              </Typography>
            </AccordionSummary> */}
            {/* <AccordionDetails style={{ padding: "10px", margin: "0" }}> */}
            <Box>
              <PDFDownloadLink
                document={
                  <PdfVisa
                    copy="Booking Copy"
                    checkList={visaDetails?.checklist}
                    optionValue={optionValue}
                    tableValue={visaDetails?.visainfo}
                    companyInfo={companyInfo}
                  />
                }
                fileName={"download"}
              >
                {({ blob, url, fileName, loading, error }) =>
                  loading ? (
                    "Loading document..."
                  ) : (
                    <a
                      href={fileName}
                      target="_blank"
                      style={{
                        cursor: "pointer",
                        color: "#fff",
                        background: "var(--primary-color)",
                        fontSize: "14px",
                        fontWeight: 400,
                        padding: "10px",
                        textAlign: "center",
                        display: "block",
                      }}
                      rel="noreferrer"
                    >
                      PDF Download
                    </a>
                  )
                }
              </PDFDownloadLink>
            </Box>
            <br />
            <Paper sx={{ padding: "15px 10px" }}>
              <Typography
                fontSize="16px"
                color="#1c75bc"
                borderBottom="1px solid #000000"
                mb={2}
              >
                Visa Submission Location
              </Typography>
              <Typography display={"flex"}>
                <PlaceIcon style={{ color: "#1c75bc" }} />
                <Typography fontSize="12px" paddingLeft="10px">
                  {companyInfo?.address}
                </Typography>
              </Typography>
            </Paper>
            <br />
            {/* <Box
              sx={{
                color: "var(--primary-color)",
                bgcolor: "var(--secondary-color)",
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "center",
                cursor: "pointer",
                mb: 1,
                p: 1,
              }}
            >
              Apply Visa
            </Box>

            <Box
              sx={{
                color: "#fff",
                bgcolor: "var(--primary-color)",
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "center",
                cursor: "pointer",
                mb: 1,
                p: 1,
              }}
            >
              Notes
            </Box>

            <Box
              sx={{
                color: "#fff",
                bgcolor: "var(--primary-color)",
                fontSize: "14px",
                fontWeight: 500,
                textAlign: "center",
                p: 1,
              }}
            >
              Exception
            </Box> */}
            {/* </AccordionDetails>
          </Accordion> */}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default CountryDetails;
