import { Typography } from "@mui/material";
import { Box } from "@mui/material";
import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CashTab from "./AddDepositTabs/CashTab";
import { Button } from "@mui/material";
import ChequeTab from "./AddDepositTabs/ChequeTab";
import BankTab from "./AddDepositTabs/BankTab";
import MobileTab from "./AddDepositTabs/MobileTab";
import { Container } from "@mui/system";
import UserHeader from "../UserPage/UserHeader/UserHeader";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import commaNumber from "comma-number";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
const isMobile = window.innerWidth <= 768;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AddDeposite = () => {
  const [tabValue, setTabValue] = useState(0);
  const [value, setValue] = React.useState(0);
  // const agentInfo = JSON.parse(secureLocalStorage.getItem("agent-info"));
  // const staffInfo = JSON.parse(secureLocalStorage.getItem("staff-info"));

  const agentInfo = secureLocalStorage.getItem("user-info");
  const staffInfo = secureLocalStorage.getItem("staff-info");

  const depositeObj = {
    agentId: agentInfo
      ? agentInfo?.agent_id
      : staffInfo
      ? staffInfo.agent_id
      : "",
    staffId: staffInfo ? staffInfo?.staff_id : "",
    customerId: "",
    amount: "",
    sender: "",
    receiver: "",
    chequeNo: "",
    chequeIssueDate: "",
    transactionId: "",
    transactionDate: "",
    paymentType: "",
    paymentGateway: "",
    ref: "",
    depositBy: "",
    via: "",
  };

 

  const [attachment, setAttachment] = useState();
  const [depositeDetails, setDepositeDetails] = useState(depositeObj);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    setDepositeDetails(depositeObj);
    setAttachment();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDepositeDetails({
      ...depositeDetails,
      [name]: value,
    });
  };

  const {
    agentId,
    staffId,
    customerId,
    amount,
    sender,
    receiver,
    chequeNo,
    chequeIssueDate,
    transactionId,
    transactionDate,
    paymentType,
    paymentGateway,
    ref,
    depositBy,
  } = depositeDetails;

  const handleSubmit = async (e) => {
    e.preventDefault();

    let type;

    if (tabValue === 0) {
      type = "cash";
    } else if (tabValue === 1) {
      type = "cheque";
    } else if (tabValue === 2) {
      type = "bank";
    }

    const formData = new FormData();

    formData.append("agentId", depositeDetails.agentId);
    formData.append("staffId", staffId);
    formData.append("customerId", customerId);
    formData.append("amount", amount);
    formData.append("sender", sender);
    formData.append("receiver", receiver);
    formData.append("chequeNo", chequeNo);
    formData.append("chequeIssueDate", chequeIssueDate);
    formData.append("transactionId", transactionId);
    formData.append("transactionDate", transactionDate);
    formData.append("paymentType", type);
    formData.append("paymentGateway", paymentGateway);
    formData.append("ref", ref);
    formData.append("depositBy", depositBy);
    formData.append("via", "web");
    formData.append("attachment", attachment);
    formData.append("platform", "B2B");

    try {
      await axios
        .post(
          "https://api.rtc.flyfarint.com/v1.0/b2b/deposit/addrequest.php",
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((res) => {
          console.log(res);

          if (res.data.status === "success") {
            e.target.reset();
            Swal.fire({
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Congratulations",
              text: "Deposite Request Sent Successfully",
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(async function (res) {
              // navigate("/dashboard");
            });
          } else {
            Swal.fire({
              imageWidth: 400,
              imageHeight: 200,
              imageAlt: "Custom image",
              title: "Error",
              text: `${res.data.message}`,
              confirmButtonColor: "var(--primary-color)",
              confirmButtonText: "Ok",
            }).then(async function (res) {
              // navigate("/dashboard");
            });
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleChanges = (event, newValue) => {
    setTabValue(event.target.value);
  };

  return (
    <Box>
      <UserHeader />
      <Box
        sx={{
          backgroundColor: "var(--white)",
          borderTopLeftRadius: "5px",
          borderTopRightRadius: "5px",
          p: "20px",
          m: "0 auto",
          width: "90%",
          mt: "-10vh",
        }}
      >
        <Box
          sx={{
            // marginBottom: "10px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <form
            style={{
              padding: "20px 14px",
              background: "var(--white)",
              borderTopLeftRadius: "5px",
              borderTopRightRadius: "5px",
            }}
            onSubmit={handleSubmit}
          >
            <Box>
              <Typography
                sx={{
                  fontWeight: 400,
                  fontSize: "18px",
                  color: "var(--black)",
                  mb: "20px",
                }}
              >
                Add Deposit
              </Typography>
              {isMobile ? (
                <Box sx={{ width: "100%" }}>
                  <Select
                    sx={{ mt: "10px" }}
                    value={tabValue}
                    onChange={handleChanges}
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value={0}>cash</MenuItem>
                    <MenuItem value={1}> Cheque</MenuItem>
                    <MenuItem value={2}> Bank Transfer</MenuItem>
                  </Select>
                </Box>
              ) : (
                <>
                  {" "}
                  <Box sx={{ borderBottom: 1, borderColor: "transparent" }}>
                    <Tabs
                      value={tabValue}
                      onChange={handleChange}
                      aria-label="basic tabs example"
                    >
                      <Tab
                        sx={{
                          backgroundColor: `${tabValue === 0 && "#F7D800"}`,
                          padding: "5px 35px",
                        }}
                        label={
                          <p
                            style={{
                              margin: "0",
                              color: `${tabValue === 0 && "#2156A6"}`,
                            }}
                          >
                            cash
                          </p>
                        }
                        {...a11yProps(0)}
                      />

                      <Tab
                        sx={{
                          backgroundColor: `${tabValue === 1 && "#F7D800"}`,

                          padding: "5px 35px",
                        }}
                        label={
                          <p
                            style={{
                              margin: "0",
                              color: `${tabValue === 1 && "#2156A6"}`,
                            }}
                          >
                            Cheque
                          </p>
                        }
                        {...a11yProps(1)}
                      />

                      <Tab
                        sx={{
                          backgroundColor: `${tabValue === 2 && "#F7D800"}`,
                          padding: "5px 35px",
                        }}
                        label={
                          <p
                            style={{
                              margin: "0",
                              color: `${tabValue === 2 && "#2156A6"}`,
                            }}
                          >
                            Bank Transfer
                          </p>
                        }
                        {...a11yProps(2)}
                      />
                    </Tabs>
                  </Box>
                </>
              )}

              <TabPanel value={tabValue} index={0}>
                <CashTab
                  setAttachment={setAttachment}
                  handleInputChange={handleInputChange}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <ChequeTab
                  setAttachment={setAttachment}
                  handleInputChange={handleInputChange}
                />
              </TabPanel>
              <TabPanel value={tabValue} index={2}>
                <BankTab
                  setAttachment={setAttachment}
                  handleInputChange={handleInputChange}
                />
              </TabPanel>
              {/* <TabPanel value={tabValue} index={3}>
              <MobileTab
                setAttachment={setAttachment}
                handleInputChange={handleInputChange}
              />
            </TabPanel> */}
            </Box>

            <Box
              sx={{
                textAlign: "right",
                px: {
                  xs: "0px",
                  sm: "0px",
                  md: "22px",
                  lg: "22px",
                },
              }}
            >
              <Button
                type="submit"
                sx={{
                  background: "#222222",
                  color: "#FFFFFF",
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "370px",
                    lg: "370px",
                  },
                  mt: "4rem",
                  ml: "1rem",
                  "&:hover": {
                    backgroundColor: "#2564B8",
                  },
                }}
              >
                Send Deposit Request
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default AddDeposite;
