import { Box, Grid, Stack, Typography } from "@mui/material";
import React from "react";

const data = [
  {
    policyName: "Cancellation",
    policyText: "Refund Amount * Paid Amount - Airline Cancelation Fee",
  },
  {
    policyName: "Void",
    policyText: "Reissue Fee * Airlines Fee + Fare Difference",
  },
  {
    policyName: "Re-issue",
    policyText: "Reissue Fee * Airlines Fee + Fare Difference",
  },
  {
    policyName: "Refund",
    policyText: "Reissue Fee * Airlines Fee + Fare Difference",
  },
];

const FarePolicy = ({ flightData }) => {
  return (
    <Box
      sx={{
        paddingTop: "20px",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "13px" }}>
          Refunds and Date Changes are done as per the following policies.
        </Typography>
        <Typography sx={{ fontSize: "13px" }}>
          Refund Amount = Paid Amount – (Airline’s Cancellation Fee* + Fly Far
          International Service Fee)
        </Typography>
        <Typography sx={{ fontSize: "13px" }}>
          Date Change Amount = Airline’s Date Change Fee* + Fare Difference +
          Fly Far International Service Fee
        </Typography>

        {/* {flightData?.airCancelPenalty > 0 ||
        flightData?.airChangePenalty > 0 ? (
          <>
            <Typography
              sx={{
                color: "var(--primary-color)",
                bgcolor: "#EFF4FF",
                fontSize: "13px",
                p: "5px 10px",
                width: "max-content",
                marginTop: "12px",
              }}
            >
              Airlines Penalty
            </Typography>

            {flightData?.airCancelPenalty > 0 && (
              <Stack direction={"row"} my={1} alignItems={"center"}>
                <Typography
                  sx={{
                    color: "#222222",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  Refund and Cancellation Penalty:
                </Typography>
                <Typography
                  sx={{
                    color: "#222222",
                    fontWeight: "500",
                    fontSize: "15px",
                    ml: "10px",
                  }}
                >
                  {flightData?.airCancelPenalty} BDT
                </Typography>
              </Stack>
            )}

            {flightData?.airChangePenalty > 0 && (
              <Stack direction={"row"} my={1} alignItems={"center"}>
                <Typography
                  sx={{
                    color: "#222222",
                    fontWeight: "500",
                    fontSize: "15px",
                  }}
                >
                  Change Penalty:
                </Typography>
                <Typography
                  sx={{
                    color: "#222222",
                    fontWeight: "500",
                    fontSize: "15px",
                    ml: "10px",
                  }}
                >
                  {flightData?.airChangePenalty} BDT
                </Typography>
              </Stack>
            )}
          </>
        ) : null} */}

        <Typography sx={{ fontSize: "13px" }}>
          Fly Far International Convenience fee is non-refundable. We can not
          guarantee the accuracy of airline refund/date change fees as they are
          subject to change without prior notice.
        </Typography>
      </Box>
    </Box>
  );
};

export default FarePolicy;
