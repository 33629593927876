import { Grid, Typography, Box, Button } from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import commaNumber from "comma-number";
import AddIcon from "@mui/icons-material/Add";
import secureLocalStorage from "react-secure-storage";
import { useEffect } from "react";
import { Container } from "@mui/system";
import RemoveIcon from "@mui/icons-material/Remove";
import "./FlightInfoDetails.css";
import { AiFillCaretDown } from "react-icons/ai";
import { useState } from "react";

const RoundFlightInfoDetails = ({
  loadData,
  searchData,
  adultCount,
  childCount,
  infant,
  adultPrice,
  childPrice,
  infPrice,
  adultTaxPrice,
  childTaxPrice,
  infTaxPrice,
  inTotalBaseFare,
  totalTax,
  totalFare,
  totalBaseFare,
  clientFare,
  serviceFeeAdult,
  serviceFeeChild,
  serviceFeeInfant,
  agentTotal,
  discount,
  coupon,
  setCoupon,
  couponAppliedMessage,
  setCouponAppliedMessage,
  goAdultBagage,
  setGoAdultBagage,
  goChildBagage,
  setGoChildBagage,
  goInfatBagage,
  setGoInfatBagage,
  backAdultBagage,
  setBackAdultBagage,
  backChildBagage,
  setBackChildBagage,
  backInfantBagage,
  setBackInfantBagage,
}) => {
  useEffect(() => {
    if (searchData?.roundData?.system === "FlyHub") {
      const [adultBag = "", childBag = "", infantBag = ""] =
        loadData?.Results[0]?.segments[0]?.baggageDetails;
      setGoAdultBagage(adultBag.Checkin);
      setBackAdultBagage(adultBag.Checkin);
      setGoChildBagage(childBag.Checkin || "");
      setBackChildBagage(childBag.Checkin || "");
      setGoInfatBagage(infantBag.Checkin || "");
      setBackInfantBagage(infantBag.Checkin || "");
    }
    const baggagefunction = () => {
      if (searchData?.roundData?.system === "Sabre") {
        if (adultCount > 0 && childCount > 0 && infant > 0) {
          const goadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[0]?.allowance?.ref || 0;
          const goadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.pieceCount;
          setGoAdultBagage(goadultBag);

          const gochildBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[1]?.passengerInfo
              ?.baggageInformation[0]?.allowance.ref || 0;

          const gochildBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              gochildBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              gochildBagRef - 1
            ]?.pieceCount ||
            0;
          setGoChildBagage(gochildBag);

          const goinfantBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[2]?.passengerInfo
              ?.baggageInformation[0]?.allowance?.ref || 0;

          const goinfantBag =
            loadData?.groupedItineraryResponse?.baggageAllowanceDescs[
              goinfantBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goinfantBagRef - 1
            ]?.pieceCount;
          setGoInfatBagage(goinfantBag);
        } else if (adultCount > 0 && childCount > 0) {
          const goadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[0]?.allowance?.ref || 0;
          const goadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.pieceCount;
          setGoAdultBagage(goadultBag);

          const gochildBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[1]?.passengerInfo
              ?.baggageInformation[0]?.allowance.ref || 0;

          const gochildBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              gochildBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              gochildBagRef - 1
            ]?.pieceCount ||
            0;
          setGoChildBagage(gochildBag);
        } else if (adultCount > 0 && infant > 0) {
          const goadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[0]?.allowance?.ref || 0;
          const goadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.pieceCount;
          setGoAdultBagage(goadultBag);

          const goinfantBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[1]?.passengerInfo
              ?.baggageInformation[0]?.allowance?.ref || 0;

          const goinfantBag =
            loadData?.groupedItineraryResponse?.baggageAllowanceDescs[
              goinfantBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goinfantBagRef - 1
            ]?.pieceCount;
          setGoInfatBagage(goinfantBag);
        } else {
          const goadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[0]?.allowance?.ref || 0;
          const goadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              goadultBagRef - 1
            ]?.pieceCount;
          setGoAdultBagage(goadultBag);
        }

        if (adultCount > 0 && childCount > 0 && infant > 0) {
          const backadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[1]?.allowance?.ref;
          const backadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.pieceCount;
          setBackAdultBagage(backadultBag);
          const backchildBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0]
              ?.itineraries[0].pricingInformation[0]?.fare?.passengerInfoList[1]
              ?.passengerInfo?.baggageInformation[1]?.allowance?.ref;

          const backchildBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backchildBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backchildBagRef - 1
            ]?.pieceCount;
          setBackChildBagage(backchildBag);

          const backinfantBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[2]?.passengerInfo
              ?.baggageInformation[1]?.allowance?.ref;

          const backinfantBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backinfantBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backinfantBagRef - 1
            ]?.pieceCount;
          setBackInfantBagage(backinfantBag);
        } else if (adultCount > 0 && childCount > 0) {
          const backadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[1]?.allowance?.ref;
          const backadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.pieceCount;
          setBackAdultBagage(backadultBag);

          const backchildBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0]
              ?.itineraries[0].pricingInformation[0]?.fare?.passengerInfoList[1]
              ?.passengerInfo?.baggageInformation[1]?.allowance?.ref;

          const backchildBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backchildBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backchildBagRef - 1
            ]?.pieceCount;
          setBackChildBagage(backchildBag);
        } else if (adultCount > 0 && infant > 0) {
          const backadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[1]?.allowance?.ref;
          const backadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.pieceCount;
          setBackAdultBagage(backadultBag);

          const backinfantBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[1]?.passengerInfo
              ?.baggageInformation[1]?.allowance?.ref;

          const backinfantBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backinfantBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backinfantBagRef - 1
            ]?.pieceCount;
          setBackInfantBagage(backinfantBag);
        } else {
          const backadultBagRef =
            loadData?.groupedItineraryResponse.itineraryGroups[0].itineraries[0]
              .pricingInformation[0].fare?.passengerInfoList[0]?.passengerInfo
              .baggageInformation[1]?.allowance?.ref;
          const backadultBag =
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.weight ||
            loadData?.groupedItineraryResponse.baggageAllowanceDescs[
              backadultBagRef - 1
            ]?.pieceCount;
          setBackAdultBagage(backadultBag);
        }
      }
    };
    baggagefunction();
  }, []);

  const [expanded, setExpanded] = useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      sx={{
        // boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
        borderRadius: "5px",
        padding: "15px 0px",
      }}
      className="no-scrollbar"
    >
      <AccordionDetails className="flight-accordian2">
        <Box>
          <Typography
            style={{
              color: "var(--light-blue)",
              fontSize: "14px",
              fontWeight: "400",
            }}
          >
            Total Payable
          </Typography>
          <Typography
            style={{
              color: "var(--light-blue)",
              fontSize: "22px",
              fontWeight: "500",
            }}
          >
            BDT {commaNumber(totalFare)} ৳
          </Typography>
          {/* <Typography
            style={{
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontSize: "12px",
              fontWeight: "500",
              background: "var(--secondary-color)",
              padding: "2px 10px",
              margin: "10px 0px",
            }}
          >
            Price Breakdown
          </Typography> */}
        </Box>

        {adultCount >= 1 && childCount >= 1 && infant >= 1 ? (
          <Box>
            <Box>
              <Typography
                style={{
                  color: "var(--black)",
                  fontFamily: "poppins",
                  fontSize: "22px",
                  fontWeight: "500",
                }}
              >
                Adult x{adultCount}
              </Typography>

              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(-third-color)",
                    fontWeight: "400",
                  }}
                >
                  Base Fare
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(adultPrice)}৳
                </Typography>
              </Grid>

              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontWeight: "400",
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(adultTaxPrice)} ৳
                </Typography>
              </Grid>

              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {serviceFeeAdult}৳
                </Typography>
              </Grid>
            </Box>
            <Box className="eticket-hr-line"></Box>

            <Box>
              <Typography
                style={{
                  color: "var(--light-blue)",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Child x{childCount}
              </Typography>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Base
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(childPrice)}৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(childTaxPrice)}৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {serviceFeeChild} ৳
                </Typography>
              </Grid>
            </Box>
            <Box className="eticket-hr-line"></Box>

            <Box>
              <Typography
                style={{
                  color: "var(--light-blue)",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Infant x{infant}
              </Typography>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Base Fare
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(infPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(infTaxPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {serviceFeeInfant} ৳
                </Typography>
              </Grid>
            </Box>
          </Box>
        ) : adultCount >= 1 && childCount >= 1 ? (
          <Box>
            <Box>
              <Typography
                style={{
                  color: "var(--light-blue)",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Adult x{adultCount}
              </Typography>
              <Grid container justifyContent="space-between">
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Base Fare
                </Typography>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {commaNumber(adultPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {commaNumber(adultTaxPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {serviceFeeAdult} ৳
                </Typography>
              </Grid>
            </Box>
            <Box className="eticket-hr-line"></Box>
            <Box>
              <Typography
                style={{
                  color: "var(--light-blue)",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Child x{childCount}
              </Typography>
              <Grid container justifyContent="space-between">
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Base
                </Typography>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {commaNumber(childPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {commaNumber(childTaxPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  sx={{
                    color: "var(--light-gray)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  sx={{
                    color: "var(--primary-color)",
                    fontSize: "12px",
                    fontWeight: 400,
                  }}
                >
                  {serviceFeeChild} ৳
                </Typography>
              </Grid>
            </Box>
            <Box className="eticket-hr-line"></Box>
          </Box>
        ) : adultCount >= 1 && infant >= 1 ? (
          <Box>
            <Box>
              <Typography
                style={{
                  color: "var(--light-blue)",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Adult x{adultCount}
              </Typography>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Base
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(adultPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(adultTaxPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {serviceFeeAdult} ৳
                </Typography>
              </Grid>
            </Box>
            <Box className="eticket-hr-line"></Box>
            <Box>
              <Typography
                style={{
                  color: "var(--light-blue)",
                  fontFamily: "poppins",
                  fontSize: "15px",
                  fontWeight: "500",
                }}
              >
                Infant x{infant}
              </Typography>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Base
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(infPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {commaNumber(infTaxPrice)} ৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                  }}
                >
                  {serviceFeeInfant} ৳
                </Typography>
              </Grid>
            </Box>
            <Box className="eticket-hr-line"></Box>
          </Box>
        ) : (
          <Box>
            <Box>
              <Box
                style={{
                  // background: "#2564B8",
                  display: "flex",
                  alignItems: "center",
                  margin: "8px 0px",
                }}
              >
                <Typography
                  style={{
                    color: "var(--light-blue)",
                    fontFamily: "poppins",
                    fontSize: "15px",
                    fontWeight: "500",
                  }}
                >
                  Adult x{adultCount}
                </Typography>
              </Box>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    marginBottom: "1px",
                  }}
                >
                  Base Fare
                </Typography>

                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    marginBottom: "1px",
                  }}
                >
                  {commaNumber(adultPrice)}৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    marginBottom: "1px",
                  }}
                >
                  Taxes
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    marginBottom: "1px",
                  }}
                >
                  {commaNumber(adultTaxPrice)}৳
                </Typography>
              </Grid>
              <Grid container justifyContent="space-between">
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--light-gray)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    marginBottom: "1px",
                  }}
                >
                  Service Fee
                </Typography>
                <Typography
                  style={{
                    fontSize: "12px",
                    color: "var(--primary-color)",
                    fontFamily: "poppins",
                    fontWeight: "400",
                    marginBottom: "1px",
                  }}
                >
                  {serviceFeeAdult}৳
                </Typography>
              </Grid>
            </Box>
          </Box>
        )}

        <Box my={2} height="2px" bgcolor="#DEDEDE"></Box>
        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--light-gray)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "8px",
            }}
          >
            Total PAX
          </Typography>

          <Typography
            style={{
              fontSize: "12px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            {adultCount + childCount + infant}&#128100;
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--light-gray)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            Total Base Fare
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            {commaNumber(totalBaseFare)}৳
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--light-gray)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "8px",
            }}
          >
            Total TAX
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            {commaNumber(totalTax)}৳
          </Typography>
        </Grid>

        {couponAppliedMessage.status === "success" && (
          <Grid container justifyContent="space-between">
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--light-gray)",
                fontFamily: "poppins",
                fontWeight: "400",
              }}
            >
              Coupon
            </Typography>
            <Typography
              style={{
                fontSize: "12px",
                color: "var(--primary-color)",
                fontFamily: "poppins",
                fontWeight: "400",
              }}
            >
              {commaNumber(-100)}৳
            </Typography>
          </Grid>
        )}

        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--light-gray)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            Customer Total
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            {commaNumber(totalFare)}৳
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--light-gray)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            Discount
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            {commaNumber(discount)}৳
          </Typography>
        </Grid>
        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--light-gray)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            Agent Total
          </Typography>
          <Typography
            style={{
              fontSize: "12px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontWeight: "400",
              marginBottom: "1px",
            }}
          >
            {couponAppliedMessage.status === "success"
              ? commaNumber(agentTotal - 100)
              : commaNumber(agentTotal)}
            ৳
          </Typography>
        </Grid>

        <Grid container justifyContent="space-between">
          <Typography
            style={{
              fontSize: "14px",
              color: "var(--light-gray)",
              fontFamily: "poppins",
              fontWeight: "500",
              marginTop: "5px",
            }}
          >
            Agent Saving
          </Typography>
          <Typography
            style={{
              fontSize: "14px",
              color: "var(--primary-color)",
              fontFamily: "poppins",
              fontWeight: "500",
              marginBottom: "1px",
              marginTop: "5px",
            }}
          >
            {commaNumber(discount)}৳
          </Typography>
        </Grid>
      </AccordionDetails>
    </Box>
  );
};

export default RoundFlightInfoDetails;
