import { Box, Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

const HotelSkeleton = () => {
  return (
    <Box>
      <Box>
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
            },
            transition: "all .5s ease-in-out",
            borderRadius: "5px",
            overflow: "hidden",
            bgcolor:"white",
            mb:"10px",
            mt:"10px"
          }}
        >
          <Grid item md={10} p={2} >
            {/* for go flight  */}
            <Grid container columnSpacing={1}>
              {/* go 1st */}
              <Grid item xs={12} md={3}>
                <Stack
                  direction="column"
                  spacing={1}
                  sx={{
                    justifyContent: "start",
                    alignItems: "start",
                  }}
                >
                  <Skeleton
                    sx={{ borderRadius: "5px", marginBottom: "5px" }}
                    variant="circular"
                    width={"150px"}
                    height={"140px"}
                  />
                </Stack>
              </Grid>

              {/* go 2nd  */}
              <Grid item xs={12} md={3}>
                <Box>
                  <Skeleton
                    sx={{ borderRadius: "5px", marginBottom: "5px" }}
                    variant="rectangular"
                    width={"30%"}
                    height={"13px"}
                  />
                  <Skeleton
                    sx={{ borderRadius: "5px", marginBottom: "5px" }}
                    variant="rectangular"
                    width={"90%"}
                    height={"13px"}
                  />

                  <Skeleton
                    sx={{ borderRadius: "5px", marginBottom: "5px" }}
                    variant="rectangular"
                    width={"40%"}
                    height={"13px"}
                  />
                  <Skeleton
                    sx={{ borderRadius: "5px", marginBottom: "5px" }}
                    variant="rectangular"
                    width={"50%"}
                    height={"13px"}
                  />
                </Box>
              </Grid>
              {/* 3rd  Animation and duration*/}
              <Grid item xs={4} md={3} py={2}>
                <Box>
                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton
                      sx={{
                        borderRadius: "5px",
                        marginBottom: "5px",
                      }}
                      variant="rectangular"
                      width={"40%"}
                      height={"13px"}
                    />
                  </Box>

                  <Skeleton
                    sx={{
                      borderRadius: "5px",
                      marginBottom: "5px",
                    }}
                    variant="rectangular"
                    width={"100%"}
                    height={"13px"}
                  />

                  <Box
                    sx={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Skeleton
                      sx={{
                        borderRadius: "5px",
                        marginBottom: "5px",
                      }}
                      variant="rectangular"
                      width={"40%"}
                      height={"13px"}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={3} textAlign="end">
                <Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Skeleton
                      sx={{
                        borderRadius: "5px",
                        marginBottom: "5px",
                      }}
                      variant="rectangular"
                      width={"30%"}
                      height={"13px"}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Skeleton
                      sx={{ borderRadius: "5px", marginBottom: "5px" }}
                      variant="rectangular"
                      width={"90%"}
                      height={"13px"}
                    />
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Skeleton
                      sx={{ borderRadius: "5px", marginBottom: "5px" }}
                      variant="rectangular"
                      width={"40%"}
                      height={"13px"}
                    />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Skeleton
                      sx={{ borderRadius: "5px", marginBottom: "5px" }}
                      variant="rectangular"
                      width={"40%"}
                      height={"13px"}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>        
          </Grid>

          {/* //todo: price section and flightDetails */}
          <Grid item md={2} p={2} textAlign="end">
            <Stack
              height="100%"
              direction="column"
              justifyContent="space-between"
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Skeleton
                  sx={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                  }}
                  variant="rectangular"
                  width={"60%"}
                  height={"13px"}
                />
                <Skeleton
                  sx={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                  }}
                  variant="rectangular"
                  width={"80%"}
                  height={"13px"}
                />
              </Box>

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Skeleton
                  sx={{
                    borderRadius: "5px",
                    marginBottom: "5px",
                  }}
                  variant="rectangular"
                  width={"80%"}
                  height={"32px"}
                />
              </Box>
            </Stack>
          </Grid>

          {/* //todo:Flight Details start------------ */}
        </Grid>

        {/* For small Screen  */}
        <Grid
          container
          sx={{
            display: {
              xs: "none",
              sm: "flex",
              md: "none",
            },
            transition: "all .5s ease-in-out",
            borderRadius: "5px",
            overflow: "hidden",
            bgcolor: "var(--white)",
            pb: 4,
          }}
        >
          <Grid item xs={12} md={2.8} p={1}>
            {" "}
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              {/* //todo: Image Part */}

              <Skeleton
                sx={{ borderRadius: "50px", marginBottom: "5px" }}
                variant="circular"
                width={"50px"}
                height={"40px"}
              />
              {/* //todo: Text Part */}
              <Box sx={{}} width="100%">
                <Skeleton
                  sx={{ borderRadius: "5px", marginBottom: "5px" }}
                  variant="rectangular"
                  width={"70%"}
                  height={"13px"}
                />
                <Skeleton
                  sx={{ borderRadius: "5px" }}
                  variant="rectangular"
                  width={"70%"}
                  height={"13px"}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={4} md={2.2} pl={2}>
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
          <Grid item xs={4} md={2.2}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
          <Grid item xs={4} md={2.2}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>

          <Grid item xs={12} md={2.8} p={1}>
            {" "}
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              {/* //todo: Image Part */}

              <Skeleton
                sx={{ borderRadius: "50px", marginBottom: "5px" }}
                variant="circular"
                width={"50px"}
                height={"40px"}
              />
              {/* //todo: Text Part */}
              <Box sx={{}} width="100%">
                <Skeleton
                  sx={{ borderRadius: "5px", marginBottom: "5px" }}
                  variant="rectangular"
                  width={"70%"}
                  height={"13px"}
                />
                <Skeleton
                  sx={{ borderRadius: "5px" }}
                  variant="rectangular"
                  width={"70%"}
                  height={"13px"}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={4} md={2.2} pl={2}>
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
          <Grid item xs={4} md={2.2}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
          <Grid item xs={4} md={2.2}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
        </Grid>

        {/* For mobile screen */}

        <Grid
          container
          sx={{
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
            },
            transition: "all .5s ease-in-out",
            borderRadius: "5px",
            overflow: "hidden",
            bgcolor: "var(--white)",
            pb: 4,
          }}
        >
          <Grid item xs={12} md={2.8} p={1}>
            {" "}
            <Stack
              direction="row"
              spacing={1}
              sx={{
                justifyContent: "start",
                alignItems: "start",
              }}
            >
              {/* //todo: Image Part */}

              <Skeleton
                sx={{ borderRadius: "50px", marginBottom: "5px" }}
                variant="circular"
                width={"50px"}
                height={"40px"}
              />
              {/* //todo: Text Part */}
              <Box sx={{}} width="100%">
                <Skeleton
                  sx={{ borderRadius: "5px", marginBottom: "5px" }}
                  variant="rectangular"
                  width={"70%"}
                  height={"13px"}
                />
                <Skeleton
                  sx={{ borderRadius: "5px" }}
                  variant="rectangular"
                  width={"70%"}
                  height={"13px"}
                />
              </Box>
            </Stack>
          </Grid>

          <Grid item xs={6} md={2.2} pl={2}>
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2.2}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={2.2} pl={2} py={2}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"30%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"20%"}
                height={"13px"}
              />
            </Box>
          </Grid>

          <Grid item xs={6} md={2.2} pl={2}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
          <Grid item xs={6} md={2.2} pt={1}>
            {" "}
            <Box>
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"70%"}
                height={"13px"}
              />
              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"90%"}
                height={"13px"}
              />

              <Skeleton
                sx={{ borderRadius: "5px", marginBottom: "5px" }}
                variant="rectangular"
                width={"40%"}
                height={"13px"}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default HotelSkeleton;
